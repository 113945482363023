import React, { useMemo, useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts, { EChartOption } from "echarts";
import { findOptions, getNormalData } from "../../../../../service/basic";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from '../../../../../lib/useRequestFn';

const color = ['#ee167b', '#dab606'];



interface IProps {
    title: string;
    style?: any;
}
export default function Index ({ title, style }: IProps) {
    // const [data] = useRequest(() => findOptions("chart_teacher_type_columnar_2020"));
    const [data] = useRequest(() => getNormalData < any > ("2b9608c8-f9fc-45b7-9505-bec462cbab97")); //实习企业就业情况

    const opt: any = useMemo(() => {

        if (data)
        {
            return {
                color: color,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    formatter: "{b} : {c} ({d}%)"
                },

                series: [
                    {
                        type: 'pie',
                        radius: '70%',
                        // center: ['50%', '50%'],
                        width: '100%',
                        data: [
                            { value: data.byrs, name: '毕业生人数' },
                            { value: data.gwsxrs, name: '岗位实习人数' }
                        ]
                    }
                ]
            }

        } else
        {
            return {};
        }
    }, [data]);
    return (
        <ReactEcharts theme={"theme"} style={style} option={opt} />
    )
}
Index.defaultProps = {
    title: ''
}
