import React, { useEffect, useState, useMemo } from "react";
import style from "../index.module.scss";
import Header from "../components/Header";
import Block from "../components/Block";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import Chart3 from "./components/Chart3";
import Chart5 from "./components/Chart5";
import Chart6 from "./components/Chart6";
import ChinaMap from "./components/ChinaMap";
import Footer from "../components/Footer";
import { getNormalData, signIn, Report, Report1 } from "../../../service/basic";
import useRequest from "../../../lib/useRequest";
import useDevice from "../../../lib/useDevice";


export default function Wall3 () {

    //实习流向率
    const [flowData] = useRequest(() => getNormalData < any > ('06f44a0b-2214-49be-afce-2c3b8548c394'))

    //实习报告情况
    const [ReportData1] = useRequest(() => Report1())
    const [reData, setReData] = useState < any > (null)
    const { isMobile } = useDevice()
    useEffect(() => {
        if (ReportData1)
        {
            setReData([
                {
                    name: '批改率',
                    value: Number(ReportData1[0]?.pgl || 0).toFixed(2),
                    color: "#448ff4"
                },
                {
                    name: '上传及时率',
                    value: Number(ReportData1[0]?.jsl || 0).toFixed(2),
                    color: '#38ba76',
                },
                {
                    name: '报告优秀率',
                    value: Number(ReportData1[0]?.yxl || 0).toFixed(2),
                    color: '#c7b30d',
                }
            ])
        }
    }, [ReportData1])

    //企业实习学生留用情况
    const [Keep] = useRequest(() => getNormalData < any > ('fb530564-1c82-44f5-bae5-8abea66fe4c7'))
    const [keepData, setKeepData] = useState < any > (null)
    useEffect(() => {
        if (Keep)
        {
            setKeepData(
                [
                    {
                        name: '留用率',
                        value: Number(Keep?.value1).toFixed(1),
                        color: "#f59a8f"
                    },
                    {
                        name: '留用学生对口率',
                        value: Number(Keep?.value2).toFixed(1),
                        color: '#8f3ffd',
                    },
                    {
                        name: '留用学生对企业满意度',
                        value: Number(Keep?.value3).toFixed(1),
                        color: '#57e7ec',
                    }
                ]
            )
        }
    }, [Keep])

    const data = [
        {
            name: '批改率',
            value: 92.5,
            color: "#448ff4"
        },
        {
            name: '上传及时率',
            value: 85.7,
            color: '#38ba76',
        },
        {
            name: '报告优秀率',
            value: 43.1,
            color: '#c7b30d',
        }
    ]
    const data2 = [
        {
            name: '留用率',
            value: 55.6,
            color: "#f59a8f"
        },
        {
            name: '留用学生对口率',
            value: 84.3,
            color: '#8f3ffd',
        },
        {
            name: '留用学生对企业满意度',
            value: 94.1,
            color: '#57e7ec',
        }
    ]


    //学生实习数据情况分布图
    const ChinaMapMemo: any = useMemo(() =>
        <ChinaMap />
        , [])
    return (
        <div className={style.root}>
            <Header />

            <div className={style.wrapper}>
                <div className={style.leftBlock}>
                    <Block>
                        <div className={style.blockTitle}>实习签到率</div>
                        <Chart1 />
                    </Block>

                    <Block>
                        <div className={style.blockTitle}>实习流向率</div>
                        <Chart2 flowData={flowData} />
                    </Block>

                    <Block customStyle={{ height: 260 }}>
                        <div className={style.blockTitle}>实习日志上报率</div>
                        <Chart3 />
                    </Block>
                </div>

                <div className={style.centerBlock}>
                    <Block customStyle={{ height: isMobile ? 'auto' : 744 }}>
                        <div className={style.blockTitle}>学生实习数据情况分布图</div>
                        {ChinaMapMemo}
                    </Block>
                </div>

                <div className={style.rightBlock}>
                    <Block>
                        <div className={style.blockTitle}>实习报告情况</div>
                        <div className={style.wrapperManger} style={{ height: reData ? 'auto' : '200px' }}>
                            {
                                reData?.map((item: any, index: number) => {
                                    return <Chart5 key={index} data={item} />
                                })
                            }
                        </div>
                    </Block>

                    <Block>
                        <div className={style.blockTitle}>企业实习学生留用情况</div>
                        <div className={style.wrapperManger}>
                            {
                                keepData?.map((item: any, index: number) => {
                                    return <Chart5 key={index} data={item} />
                                })
                            }
                        </div>
                    </Block>
                    <Block>
                        <div style={{ height: "250px", overflowY: "hidden", marginBottom: isMobile ? 80 : 0 }}>
                            <div className={style.blockTitle}> 岗位实习行业薪资情况</div>
                            <Chart6 />
                        </div>
                    </Block>
                </div>
            </div>
            <Footer />
        </div>
    )
}
