
import ChartCarousel from "../../../Components/ChartCarousel";
import React, {useState} from "react";
import style from "../../index.module.scss";
import Widget6 from "../../../School/Widget6";
import Widget4 from "../../../School/Widget4";
import doNotReRender from "../../../../../lib/doNotReRender";
import { useHistory } from "react-router";

const PureWidget6 = doNotReRender(Widget6);
const PureWidget4 = doNotReRender(Widget4);

export default function BlockRight2() {

    const [title, setTitle] = useState<React.ReactNode>("");

    const history = useHistory();

    return (
        <div>
            <h1 className={style.blockHeader} style={{width: 300}}>{title}</h1>

            <ChartCarousel
                title={[
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/service")}>产业成果转化金额</div>,
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/service")}>社会服务对接企业成果转化金额分布</div>
                ]}
                hideTitle
                onTitleChange={setTitle}
            >
                <div>
                    <PureWidget6 style={{height: 250, padding: "30px 20px 0 20px"}}/>
                </div>

                <div>
                    <PureWidget4 style={{height: 250}} />
                </div>
            </ChartCarousel>
        </div>
    );
}
