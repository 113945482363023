import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";

export default function Index (props: any) {
    let num = props.data ? (Number(Object.values(props.data)[2]) * 100).toFixed(1) : 0
    const options: any = useMemo(() => {

        return {
            title: {
                text: num + '%',
                textStyle: {
                    color: '#4266fe',
                    fontSize: 35,
                },

                itemGap: 0, // 主副标题距离
                left: 'center',
                top: 'center',
            },
            angleAxis: {
                max: 100, // 满分
                clockwise: false, // 逆时针
                // 隐藏刻度线
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            radiusAxis: {
                type: 'category',
                // 隐藏刻度线
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            polar: {
                center: ['50%', '50%'],
                radius: '140%' //图形大小
            },
            series: [

                {
                    type: 'bar',
                    data: [
                        {
                            name: '',
                            value: num,
                            itemStyle: {
                                normal: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0.1,
                                        y2: 1,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: '#c324fc' // 0% 处的颜色
                                            }, {
                                                offset: 1,
                                                color: '#5683ff' // 100% 处的颜色
                                            }],
                                        global: false // 缺省为 false
                                    }
                                },
                            },
                        },
                    ],
                    coordinateSystem: 'polar',
                    roundCap: true,
                    barWidth: 10,
                    minAngle: 15,//最小角度
                    startAngle: -270, //起始角度
                    barGap: '-100%', // 两环重叠
                    z: 2,
                },
                {
                    // 灰色环
                    type: 'bar',
                    data: [
                        {
                            value: 100,
                            itemStyle: {
                                color: '#e2e2e2',
                                shadowColor: 'rgba (0, 0, 0, 0.2)',
                                shadowBlur: 5,
                                shadowOffsetY: 2,
                            },
                        },
                    ],
                    coordinateSystem: 'polar',
                    roundCap: true,
                    barWidth: 10,
                    barGap: '-100 %', // 两环重叠
                    z: 1,
                },
            ]
        }
    }, [props])

    return (
        <div >
            <ReactEcharts
                style={{ height: 250 }}
                option={options}
            />
        </div>
    );
}
