import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequestFn from "../../../../lib/useRequestFn";
import { getNormalData } from "../../../../service/basic";
import Popup from "./../../../../components/EChartsUuid/components/Popup";
import { Table } from 'antd'
import style from './style.module.scss';
import { ColumnsType } from "antd/lib/table";


export default function Index () {
    const [list, setList] = useState < any > (null)
    const [number, setNumber] = useState < any > (null)
    //获取数据
    const [onData, data] = useRequestFn < any > (getNormalData)
    async function onFn () {
        let res = await onData('cb097d7e-ff86-4022-a120-df7088efece2')
        if (res)
        {
            let arr: any = []
            let num: any = 0
            res.option.series[0].data.map((item: any) => {
                arr.push({
                    name: item.name,
                    value: Number(item.value),
                    unit: item.value + '所'
                })
                num += Number(item.value)
            })
            setList(arr)
            setNumber(num)
        }
    }
    useEffect(() => {
        onFn()
    }, [])


    //获取详情信息
    const [onDataDel, dataDel] = useRequestFn < any > (getNormalData)
    const [tabList, onTabList] = useState([])
    async function delInfo (min: string, max: string) {
        let res = await onDataDel('2c95e663-9923-11ec-ba43-005056958024', { min: min, max: max, })
        onTabList(res)
    }
    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');

    //颜色和模拟数据
    let color = ['#15afff', '#15fbfa', '#ffc600', '#ff7e38'];
    let color1 = ['#0c63ff', '#01c2a5', '#ff8b00', '#ff7e38'];

    let columns: ColumnsType<any> | undefined = [
        {
            title: '学校名称',
            dataIndex: 'schoolName'
        },
        {
            title: '学校属性',
            dataIndex: 'school_sx',
            filters: [
                {
                    text: '民办',
                    value: '民办'
                },
                {
                    text: '省属公办',
                    value: '省属公办'
                },
                {
                    text: '市属公办',
                    value: '市属公办'
                }
            ],
            onFilter: (value: any, record: any) => record.school_sx.indexOf(value) === 0,
        },
        {
            title: '城市名称',
            dataIndex: 'area_code'
        },
        {
            title: '学生数量',
            dataIndex: 'stuCount',
            sorter: {
                compare: (a: any, b: any) => a.stuCount - b.stuCount
            }
        }
    ]
    let dataSource = tabList?.map((item: any) => {
        return {
            area_code: item.area_code,
            schoolName: item.schoolName,
            school_sx: item.school_sx,
            stuCount: item.stuCount
        }
    })


    const options: any = useMemo(() => {
        let arrName = [];
        let arrValue = [];
        let sum = 0;
        let pieSeries: any = [],
            lineYAxis: any = [];

        // 数据处理
        list?.forEach((v: any, i: number) => {
            arrName.push(v.name);
            arrValue.push(v.value);
            sum = sum + v.value;
        })
        // 图表option整理
        list?.forEach((v: any, i: number) => {
            pieSeries.push({
                name: '',
                type: 'pie',
                clockWise: false,
                hoverAnimation: false,
                radius: [80 - i * 18 + '%', 70 - i * 18 + '%'],
                center: ["40%", "50%"],
                tooltip: {
                    trigger: 'item',
                    formatter: "{b} : {c} ({d}%)"
                },
                itemStyle: {
                    emphasis: {
                        barBorderRadius: 7
                    },
                    normal: {
                        barBorderRadius: 12,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0.1,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: color[i] // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: color1[i] // 100% 处的颜色
                                }],
                            global: false // 缺省为 false
                        }
                    },
                },

                label: {
                    show: false
                },
                data: [
                    {
                        value: v.value,
                        name: v.name
                    },
                    {
                        value: sum - v.value,
                        name: '',
                        itemStyle: {
                            color: "rgba(0,0,0,0)"
                        }
                    },
                ]
            });

            pieSeries.push({
                name: '',
                type: 'pie',
                silent: true,
                z: 1,
                clockWise: false, //顺时加载
                hoverAnimation: false, //鼠标移入变大
                radius: [80 - i * 18 + '%', 70 - i * 18 + '%'],
                center: ["40%", "50%"],
                label: {
                    show: false
                },
                data: [{
                    value: 7.5,
                    itemStyle: {
                        color: "#E3F0FF"
                    }
                }, {
                    value: 2.5,
                    name: '',
                    itemStyle: {
                        color: "rgba(0,0,0,0)"
                    }
                }]
            });
            lineYAxis.push({
                value: i,
                textStyle: {
                    rich: {
                        circle: {
                            color: color[i],
                            padding: [0, 10],
                            fontSize: 18
                        }
                    }
                }
            });
        })

        return {
            backgroundColor: '#fff',
            color: color,
            tooltip: {
                trigger: '',
                formatter: "{b} : {c} ({d}%)"
            },
            grid: {
                top: '8%',
                bottom: '55%',
                left: "38%",
            },
            yAxis: [{
                type: 'category',
                inverse: true,
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: function (params: any) {
                        let item = list[params];
                        return '{line|}{circle|●}{name|' + item.name + '}{bd|}{percent|' + item.unit + '}{bd|}{percent|' + ((item.value / number) * 100).toFixed(0) + '}{unit|%}'
                    },
                    interval: 0,
                    inside: true,
                    textStyle: {
                        color: "#000",
                        fontSize: 14,
                        rich: {
                            line: {
                                width: 5,
                                height: 5,
                                backgroundColor: '#fff'
                            },
                            name: {
                                color: '#000',
                                fontSize: 14,
                            },
                            bd: {
                                color: '#000',
                                padding: [0, 5],
                                fontSize: 14,
                            },
                            percent: {
                                color: '#333',
                                fontSize: 14,
                            },
                            value: {
                                color: '#333',
                                fontSize: 14,
                                fontWeight: 500,
                                padding: [0, 0, 0, 20]
                            },
                            unit: {
                                fontSize: 14
                            }
                        }
                    },
                    show: true
                },
                data: lineYAxis
            }],
            xAxis: [{
                show: false
            }],
            series: pieSeries

        }
    }, [data])

    const onEventsObj = {
        'click': (params: any) => {
            if (params.name == '') return
            if (params.name == "3000-6000") { delInfo('3000', '6000') }
            if (params.name == "6000-10000") { delInfo('6000', '10000') }
            if (params.name == "大于10000") { delInfo('10000', '') }
            if (params.name == "小于3000") { delInfo('', '3000') }
            setTableTitle(params.name + '有' + params.data.value + '所')
            setShow(true)

        }
    }
    return (
        <div >
            <ReactEcharts
                style={{ height: 272 }}
                option={options}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                setShow(false);
            }} show={show} width='80vw'>
                <div className={style.tableWrapper}>
                    <div
                        className={style.tableTitle}>辽宁省高职院校在校生人数在{tableTitle}</div>
                    <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                        <Table columns={columns} dataSource={dataSource} pagination={false} />
                    </div>
                </div>

            </Popup >
        </div>
    );
}
