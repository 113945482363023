import ReactEcharts from "echarts-for-react";
import React, { useMemo } from "react";
import { EChartOption } from "echarts";
import useRequest from "../../../../../../../lib/useRequest";
import { Report } from "../../../../../../../service/basic";
import { parseInt } from "lodash";


export default function Chat1 () {

  const dataArr = 76;

  const colorSet = {
    color: 'rgb(110,68,235)'
  };

  const options: EChartOption<any> = {
    angleAxis: {
      show: false,
      max: 100 * 360 / 270, //-45度到225度，二者偏移值是270度除360度
      type: 'value',
      startAngle: 225, //极坐标初始角度
      splitLine: {
        show: false
      }
    },
    radiusAxis: {
      show: false,
      type: 'category',
    },
    //圆环位置和大小
    polar: {
      center: ['50%', '50%'],
      radius: '110'
    },
    series: [
      { //下层圆环，显示最大值
        type: 'bar',
        barMaxWidth: 14, //圆环宽度
        data: [{
          value: 76,
          itemStyle: {
            color: colorSet.color
          }
        }],
        barGap: '-100%',
        coordinateSystem: 'polar',
        roundCap: true,
        z: 10,
        cursor: 'default'
      }, {
        name: "内部进度条",
        type: "gauge",
        radius: '60%',
        axisLine: {
          lineStyle: {
            color: [
              [0 / 100, colorSet.color],
              [1, "#111F42"]
            ],
            width: 8
          }
        },
        axisLabel: { show: false, },
        axisTick: { show: false, },
        splitLine: { show: false, },
        itemStyle: { show: false, },
        detail: {
          formatter: (value: any) => `${parseInt(value).toFixed(2)}%`,
          offsetCenter: [0, 0],
          textStyle: {
            fontSize: 16,
            color: "#fff"
          }
        },
        title: { //标题
          show: true,
          offsetCenter: [0, 70], // x, y，单位px
          textStyle: {
            color: "#fff",
            fontSize: 12, //表盘上的标题文字大小
            fontWeight: 400,
            fontFamily: 'PingFangSC'
          }
        },
        data: [{
          name: "顶岗日志上报率",
          value: 76,
        }],
        pointer: {
          show: false,
        },
      },
      {
        name: '外部刻度',
        type: 'gauge',
        radius: '70%',
        min: 0, //最小刻度
        max: 100, //最大刻度
        splitNumber: 10, //刻度数量
        startAngle: 225,
        endAngle: -45,
        axisLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: [
              [1, 'rgba(0,0,0,0)']
            ]
          }
        }, //仪表盘轴线
        axisTick: {
          show: true,
          splitNumber: 4,
          lineStyle: {
            color: colorSet.color, //用颜色渐变函数不起作用
            width: 1,
          },
          length: -8
        }, //刻度样式
        splitLine: {
          show: false,
        }, //分隔线样式
        detail: {
          show: false
        },
        pointer: {
          show: false
        }
      },
    ]
  };
  const [data] = useRequest(() => Report())
  const opt = useMemo(() => {
    if (data)
    {
      return {
        angleAxis: {
          show: false,
          max: 100 * 360 / 270, //-45度到225度，二者偏移值是270度除360度
          type: 'value',
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          show: false,
          type: 'category',
        },
        //圆环位置和大小
        polar: {
          center: ['50%', '50%'],
          radius: '110'
        },
        series: [
          { //下层圆环，显示最大值
            type: 'bar',
            barMaxWidth: 14, //圆环宽度
            data: [{
              value: data[0].dgrzsbl,
              itemStyle: {
                color: colorSet.color
              }
            }],
            barGap: '-100%',
            coordinateSystem: 'polar',
            roundCap: true,
            z: 10,
            cursor: 'default'
          }, {
            name: "内部进度条",
            type: "gauge",
            radius: '60%',
            axisLine: {
              lineStyle: {
                color: [
                  [0 / 100, colorSet.color],
                  [1, "#111F42"]
                ],
                width: 8
              }
            },
            axisLabel: { show: false, },
            axisTick: { show: false, },
            splitLine: { show: false, },
            itemStyle: { show: false, },
            detail: {
              formatter: (value: any) => `${value}%`,
              offsetCenter: [0, 0],
              textStyle: {
                fontSize: 16,
                color: "#fff"
              }
            },
            title: { //标题
              show: true,
              offsetCenter: [0, 70], // x, y，单位px
              textStyle: {
                color: "#fff",
                fontSize: 12, //表盘上的标题文字大小
                fontWeight: 400,
                fontFamily: 'PingFangSC'
              }
            },
            data: [{
              name: "顶岗日志上报率",
              value: data ? (data[0].dgrzsbl).toFixed(2) : 0,
            }],
            pointer: {
              show: false,
            },
          },
          {
            name: '外部刻度',
            type: 'gauge',
            radius: '70%',
            min: 0, //最小刻度
            max: 100, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: [
                  [1, 'rgba(0,0,0,0)']
                ]
              }
            }, //仪表盘轴线
            axisTick: {
              show: true,
              splitNumber: 4,
              lineStyle: {
                color: colorSet.color, //用颜色渐变函数不起作用
                width: 1,
              },
              length: -8
            }, //刻度样式
            splitLine: {
              show: false,
            }, //分隔线样式
            detail: {
              show: false
            },
            pointer: {
              show: false
            }
          },
        ]
      }
    } else
    {
      return {};
    }
  }, [data]);
  return (
    <ReactEcharts
      style={{ height: 200, cursor: 'default' }}
      option={opt}
    />
  );
}
