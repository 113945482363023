import { Button, Carousel, Select, Table } from "antd";
// import { ColumnsType } from "antd/lib/table/interface";
import useRequest from "../../../../../lib/useRequest";
import { hereIsATable } from "../../../../../service/employment";
import _, { values } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styles from './index.module.scss'
import { Modal, Form } from 'antd';
// import { FilterOutlined } from "@ant-design/icons";
import { getNormalData } from "../../../../../service/basic";
import useRequestFn from "../../../../../lib/useRequestFn";
interface CourseListTableProps {
  hideSchool?: boolean;
}
export default function CourseListTable ({ hideSchool }: CourseListTableProps) {
  // 数据源
  const [detailed, res] = useRequestFn(getNormalData)//高职院校人才就业数据明细
  const [data, setList] = useState < any > (null)
  async function list () {
    let res = await detailed < any > ('ae9a6e43-c10e-11ec-ba43-005056958024')
    setList(res)
  }
  useEffect(() => {
    list()
  }, [])


  //人才对接统计 


  // 对话框显示或者隐藏的标志
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // // 对话框显示或者隐藏的标志
  // const [isModalVisible2, setIsModalVisible2] = useState(false);
  // // 地域筛选
  // const [isModalVisible3, setIsModalVisible3] = useState(false);



  // 企业中间变量,select变化先存起来,只有确定的时候再重新请求
  // const [middleCompanyName, setmiddleCompanyName] = useState('');

  // // 学校中间变量,select变化先存起来,只有确定的时候再重新请求
  // const [middleSchoolName, setmiddleSchoolName] = useState('');
 
  // // 地域中间变量,select变化先存起来,只有确定的时候再重新请求
  // const [middleAddress, setmiddleAddress] = useState('');

  // //用人单位下拉框
  // const [keys, setKeys]: any = useState([]);

  // //学校下拉框
  // const [schoolKeys, setSchoolKeys]: any = useState([]);

  // //地域下拉框
  // const [address, setAddress]: any = useState(['省内', '省外']);
  // 获取下拉框的值
  // const getOptions = () => {
  //   const options: any = [];
  //   select?.forEach((item: any) => {
  //     if (!options.includes(item.company))
  //     {
  //       options.push(item.company)
  //     }
  //   })
  //   setKeys(options);
  // }

  // // 获取学校下拉框的值
  // const getSchoolOptions = () => {
  //   const options: any = [];
  //   select?.forEach((item: any) => {
  //     if (!options.includes(item.school))
  //     {
  //       options.push(item.school)
  //     }
  //   })
  //   setSchoolKeys(options);
  // }

  // // 企业名称
  // const onChangeCompany = (value: string) => {
  //   setmiddleCompanyName(value);
  // }

  // // 学校名称
  // const onChangeSchoolName = (value: string) => {
  //   setmiddleSchoolName(value)
  // }

  // // 地域
  // const onChangeAddress = (value: string) => {
  //   setmiddleAddress(value)
  // }

  // // 确定-筛选企业
  // function submitCompany () {
  //   setIsModalVisible(false);
  //   list(middleSchoolName, middleCompanyName, middleAddress)

  // }

  // // 确定-筛选学校
  // function submitSchool () {
  //   setIsModalVisible2(false);
  //   list(middleSchoolName, middleCompanyName, middleAddress)
  // }

  // // 确定-筛选地域
  // function submitAddress () {
  //   setIsModalVisible3(false);
  //   list(middleSchoolName, middleCompanyName, middleAddress)
  // }
  // // 下拉列表
  // const { Option } = Select;

  // 初始化下拉页表
  // useMemo(() => {
  //   getOptions();
  //   getSchoolOptions();
  // }, [data])
  return (
    <div>
      {/* <Modal title="用人单位检索" visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        footer={
          [
            <Button key="back" onClick={() => {
              setIsModalVisible(false)
            }}>
              返回
            </Button>,
            <Button key="clear" type="primary" onClick={() => {
              submitCompany()
            }}>
              确定
            </Button>
          ]
        }
      >
        <p>
          <Form.Item
            label="单位"
          >
            <Select
              showSearch
              size={"large"}
              placeholder="请输入企业名"
              allowClear
              style={{ width: 400, textAlign: "left" }}
              onChange={onChangeCompany}
            >
              {keys?.map((item: any, index: number) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </p>
      </Modal>

      <Modal title="学校检索" visible={isModalVisible2}
        onCancel={() => {
          setIsModalVisible3(false)
        }}
        footer={
          [
            <Button key="back" onClick={() => {
              setIsModalVisible2(false)
            }}>
              返回
            </Button>,
            <Button key="clear" type="primary" onClick={() => {
              submitSchool()
            }}>
              确定
            </Button>
          ]
        }
      >
        <p>
          <Form.Item
            label="学校"
          >
            <Select
              showSearch
              size={"large"}
              placeholder="请输入学校名"
              allowClear
              style={{ width: 400, textAlign: "left" }}
              onChange={onChangeSchoolName}
            >
              {schoolKeys?.map((item: any, index: number) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </p>
      </Modal>

      <Modal title="地域检索" visible={isModalVisible3}
        onCancel={() => {
          setIsModalVisible3(false)
        }}
        footer={
          [
            <Button key="back" onClick={() => {
              setIsModalVisible3(false)
            }}>
              返回
            </Button>,
            <Button key="clear" type="primary" onClick={() => {
              submitAddress()
            }}>
              确定
            </Button>
          ]
        }
      >
        <p>
          <Form.Item
            label="地域"
          >
            <Select
              showSearch
              size={"large"}
              placeholder="请输入省内或省外"
              allowClear
              style={{ width: 400, textAlign: "left" }}
              onChange={onChangeAddress}
            >
              {address?.map((item: any, index: number) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </p>
      </Modal> */}
{/* 学校名称       项目名称      对标学校     对标专业    服务产业  */}
      {/* <div className={styles.titleheader}>
        <span>高职院校人才就业数据明细</span>
      </div> */}
      <div className={styles.header}>
        <div style={{ width: "15%", textAlign: "center" }}>
          <span>学校名称</span>
          {/* <FilterOutlined onClick={(e) => {
            setIsModalVisible(true)
          }} /> */}
        </div>
        <div style={{ width: "15%", textAlign: "center" }}>
          <span>项目名称</span>
          {/* <FilterOutlined onClick={(e) => {
            setIsModalVisible3(true)
          }} /> */}
        </div>
        {/* <div style={{ width: "15%", textAlign: "center" }}>
          <span>对标学校</span>
          <FilterOutlined onClick={() => {
            setIsModalVisible2(true)
          }} />
        </div> */}
        {/* <div style={{ width: "15%", textAlign: "center" }}>对标专业</div> */}
        <div style={{ width: "15%", textAlign: "center" }}>服务产业</div>
        <div style={{ width: "15%", textAlign: "center" }}>服务五大安全</div>
        <div style={{ width: "20%", textAlign: "center" }}>服务三大篇章</div>
        <div style={{ width: "20%", textAlign: "center" }}>服务数字辽宁智造强省</div>

      </div>
      <div className={styles.table}>
        <Carousel autoplay={true} dots={false} dotPosition="left" autoplaySpeed={2000} slidesToScroll={1}
          slidesToShow={20} pauseOnHover={false}>
          {data?.map((item: any) => {
            return (
              <div key={item.id}>
                <div className={styles.col}>
                  <div style={{ width: "15%" }}>{item.school_name}</div>
                  <div style={{ width: "15%", textAlign: "center"  }}>{item.xmmc}</div>
                  {/* <div style={{ width: "15%" }}>{item.dbxx}</div>
                  <div style={{ width: "15%" }}>{item.dbzy}</div> */}
                  <div style={{ width: "15%", textAlign: "center" }}>{item.wfcy}</div>
                  <div style={{ width: "15%", textAlign: "center" }}>{item.safe_5}</div>
                  <div style={{ width: "20%", textAlign: "center" }}>{item.page_3}</div>
                  <div style={{ width: "20%", textAlign: "center" }}>{item.wisdom_ln}</div>

                </div>
              </div>

            )
          })}

        </Carousel>
      </div>
    </div>
  );
}
