import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import echarts from "echarts"

interface iPops {

  onClick: () => void
}
export default function MakePie (props: any) {
  const count = props.count;
  // 圈中的值
  const [value, setValue] = useState(95)
  // 圈的标题
  const genderTitle = () => {
    if (props.data.title.length > 5)
    {
      return props.data.title.substring(0, 5) + "..."
    } else
    {
      return props.data.title
    }
  }

  const caleColor = () => {
    if (props.data.value >= 80)
    {
      return '#ee167b'
    } else if (props.data.value < 80 && props.data.value > 50)
    {
      return '#e55b26'
    } else if (props.data.value <= 50)
    {
      return '#06da90'
    }
  }

  const [title, setTitle] = useState('')
  useEffect(() => {
    setValue(props.data.value)
    setTitle(props.data.title)
  }, [props.value])


  // 随机颜色生成
  const genderColor = () => {
    if (props.data.value >= 80)
    {
      return 'red'
    } else if (props.data.value < 80 && props.data.value > 50)
    {
      return '#ea6971'
    } else
    {
      return '#52d98e'
    }
  }

  const opt = {
    backgroundColor: '#0e0c4a',
    title: {
      text: `${value}%`,
      subtext: genderTitle(),
      left: 'center',
      top: 'center', //top待调整
      textStyle: {
        color: '#fff',
        fontSize: 20,
        fontFamily: 'DINAlternate-Bold',
      },
      subtextStyle: {
        color: '#ff',
        fontSize: 15,
        fontFamily: 'PingFangSC-Regular',
        top: 'center'
      },
      itemGap: 5 //主副标题间距
    },
    grid: {
      containLabel: true
    },
    tooltip: {
      formatter: function (data: any) {
        return `${title} : ${value}` + "%"
      }
    },
    xAxis: {
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    yAxis: {
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    series: [
      // 内圆
      {
        type: 'pie',
        radius: ['0', '50%'],
        center: ['50%', '50%'],
        z: 0,
        itemStyle: {
          normal: {
            color: genderColor(),
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          },
        },
        label: {
          normal: {
            position: "center",

          }
        },
        data: [100],
      },
      // 进度圈
      {
        type: 'pie',
        clockWise: true,
        radius: ["65%", "60%"],
        data: [{
          value: value,
          itemStyle: {
            normal: {
              borderWidth: 10,
              borderColor: caleColor(),
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
            },
          }
        },
        {
          name: 'gap',
          value: 100 - value,
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
              color: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgba(0, 0, 0, 0)',
              borderWidth: 0,
            }
          },
        }
        ]
      },
      //刻度尺
      {
        // name: "白色圈刻度",
        type: "gauge",
        radius: "90%",
        startAngle: 225, //刻度起始
        endAngle: -134.8, //刻度结束
        z: 4,
        axisTick: {
          show: true,
          lineStyle: {
            width: 1,
            color: 'rgba(1,244,255, 0.9)'
          }
        },
        splitLine: {
          length: 8, //刻度节点线长度
          lineStyle: {
            width: 1,
            color: 'rgba(1,244,255, 0.9)'
          } //刻度节点线
        },
        axisLabel: {
          color: 'rgba(255,255,255,0)',
          fontSize: 12,
        }, //刻度节点文字颜色
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            opacity: 0
          }
        },
        detail: {
          show: false
        },
        data: [{
          value: 0,
        }]
      },
      // 刻度圈
      {
        "type": "pie",
        "radius": ["0%", "93%"],
        "center": ["50%", "50%"],
        "avoidLabelOverlap": false,
        "label": {
          "normal": {
            "show": false,
            "position": "center"
          },
          "emphasis": {
            "show": false,
            "textStyle": {
              "fontWeight": "bold"
            }
          }
        },
        "itemStyle": {
          "normal": {
            "color": {
              "type": "linear",
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "colorStops": [{
                "offset": 0.05,
                "color": "rgba(32,36,107, 0.2)"
              }, {
                "offset": 0.5,
                "color": "rgba(32,36,107,0.3)"
              }, {
                "offset": 0.95,
                "color": "rgba(32,36,107, 0.2)"
              }]
            }
          }
        },
        "labelLine": {
          "normal": {
            "show": false
          }
        },
        "data": [{
          "value": 3235
        }]
      },
      //最外层圈
      {
        "type": "pie",
        "radius": ["0%", "85%"],
        "center": ["50%", "50%"],
        "avoidLabelOverlap": false,
        hoverAnimation: false,
        "label": {
          "normal": {
            "show": false,
            "position": "center"
          },
          "emphasis": {
            "show": false,
            "textStyle": {
              "fontWeight": "bold"
            }
          }
        },
        "itemStyle": {
          "normal": {
            "color": {
              "type": "linear",
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "colorStops": [{
                "offset": 0.05,
                "color": "rgba(21,24,65, 0.2)"
              }, {
                "offset": 0.5,
                "color": "rgba(21,24,65, 0.3)"
              }, {
                "offset": 0.95,
                "color": "rgba(21,24,65, 0.2)"
              }]
            }
          }
        },
        "labelLine": {
          "normal": {
            "show": false
          }
        },
        "data": [{
          "value": 3235,
          name: title
        }]
      }
    ]
  }
  return (

    <ReactEcharts
      option={opt}
      style={{ height: '120px', width: '150px', left: '50px', marginTop: "35px" }}
      onEvents={{
        "click": (e) => {
          if (e.name == "总体")
          {
            return;
          } else if (count === 0 || count === 1)
          {
            props.methos('1', e.name)
          } else if (count === 2)
          {
            props.methos('2', e.name,)
          }
        }

      }}
    />

  );
}
