import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { getNormalData } from "../../../../../service/basic";
let colorList = [
  "#ffd200",
  "#ffad56",
  "#f77070",
  "#e740a6",
  "#7d6cff",
  "#326dce",
  "#468cff",
  "#56cefd",
  "#34ac74",
  "#84cf61",

];
export default function Index (props: any) {
  const [data] = useRequest(() => getNormalData('17f2f658-2298-4fb6-8707-eed425fcf981'))

  const options: any = useMemo(() => {
    return {
      color: colorList,
      tooltip: {
        trigger: 'item',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: "{b} : {c} ({d}%)"
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          data: data ? data : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }
  }, [data])

  return (
    <div >
      <ReactEcharts
        style={{ height: '250px' }}
        option={options}
      />
    </div>
  );
}
