/**
 * 数据请求钩子
 * @author menghao
 * ------------------------------------------------
 * 简单请求
 * const [list] = useRequest(() => Service.Course.getXXX(111));
 *
 * ------------------------------------------------
 * 简单请求 + 请求状态 + 错误状态 + 请求函数
 * const [list, { loading, error }, getList] = useRequest(() => Service.Course.getXXX(111));
 *
 * ------------------------------------------------
 * 不立即执行 + 不显示错误
 * const [list, , getList] = useRequest(() => Service.Course.getXXX(111), { autoStart: false, silence: true });
 *
 * ------------------------------------------------
 * 数据分页
 * const [page, setPage] = useState(1);
 * const [list] = useRequest(() => Service.Course.getXXX(page), {}, [page]);
 */

import { useEffect, useState } from 'react';
import { message } from 'antd';
import {errorMessage} from "./utils";
import _ from "lodash";

interface StatusType { loading: boolean; error: any }

interface RequestConfig {
    autoStart?: boolean; // 默认自动发送请求
    silence?: boolean; // 静默模式, 出错也不弹出提示
}

const ConfigDefault: Partial<RequestConfig> = {
    autoStart: true,
    silence: false,
};

export default function useRequest<S>(callback: () => Promise<S>, config: RequestConfig = {}, deps: any[] = []) {

    Object.assign(config, { ...ConfigDefault, ...config });

    type DataType = S | null;

    let [loading, setLoading] = useState(false);
    let [error, setError] = useState<any>(null);
    let [data, setData] = useState<DataType>(null);

    useEffect(() => {
        if (config.autoStart) loadData();
    }, deps);

    const loadData = async () => {

        setLoading(true);

        try {
            let data = await callback();
            setData(data);
        } catch (e) {
            const msg = errorMessage(e);
            if (!config.silence && msg !== 'Request aborted') showMessage(msg);
            setError(e);
        }

        setLoading(false);
    };

    return [data, { loading, error }, loadData] as [DataType, StatusType, typeof loadData];
}

const showMessage = _.throttle(function (msg: string) {
    // message.error(msg);
}, 5000);
