//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';


export default function Yibiao (props: any) {
  const history = useHistory()
  // 解构对象中的style
  let { style } = props;
  let [ydata, setYdata] = useState([]);


  useMemo(() => {
    setYdata(props.item)
  }, [props.item])
  var color = [
    '#ee167b',
    "#488bf0",
    '#e55b26',
    '#38baa8',
    '#448ff4',
    '#e59d27',
    '#38ba76',
    '#c7b30d',
    '#8f3ffd',
    '#6e3ffd',
    '#5757f2',
    '#4467f4',
    '#488bf0',
  ];

  const Options: EChartOption<EChartOption.Series> = {
    color: color,
    legend: {
      orient: "vartical",
      x: "left",
      top: "20%",
      left: "48%",
      bottom: "0%",
      itemWidth: 8,
      itemHeight: 8,
      itemGap: 12,
      textStyle: {
        color: "#fff",
      },
      selectedMode: false
    },
    grid: {
      left: "55%"
    },
    series: [
      {
        type: "pie",
        clockwise: false, //饼图的扇区是否是顺时针排布
        minAngle: 2, //最小的扇区角度（0 ~ 360）
        radius: ["70%", "40%"],
        center: ["25%", "45%"],
        avoidLabelOverlap: false,
        itemStyle: {
          //图形样式
          // normal: {
          //     borderColor: "#08c",
          //     borderWidth: 6,
          // },
        },
        label: {
          normal: {
            show: false,
            position: "center",
            formatter: "{text|{b}}\n{c}%",
            rich: {
              text: {
                color: "#fff",
                fontSize: 14,
                align: "center",
                verticalAlign: "middle",
                padding: 8,
              },
              value: {
                color: "#fff",
                fontSize: 24,
                align: "center",
                verticalAlign: "middle",
              },
            },
            formatter: function (data) {
              if (data.value > 100)
              {
                return parseInt(Number(data.value).toFixed(2)*100) + "%"
              } else
              {
                return parseInt(Number(data.value).toFixed(2)*100) + "%"
              }
            }
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: 24,
            },
          }

        },
        data: ydata,
      },
    ],
  };

  return <ReactEcharts onEvents={{
    "click": (e) => {
      props.showTable(e);
    }
  }} theme={"theme"}
    style={style} option={Options} />
}
