import React from "react";
import { Row, Col, Divider } from "antd";
import style from "./main.module.scss";
import Shouke from "../../Charts/School/Shouke";
import ChartUuid from "../../../components/ChartUuid";
import Zhongdian from "../../Charts/School/Zhongdian";
import FirstScreen from "../../Charts/Components/FirstScreen";
import StatisticsOne, { Statistics1 } from "../../Charts/Components/Statistics";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import Chart3 from "../../Major/Main/components/Chart3";
import Chart2 from "./Chart2";
import Chart4 from "./Chart4";
import Chart5 from "./Chart5";
import Chart6 from "./Chart6";
import Chart7 from "./Chart7";
import Chart8 from "./Chart8";
import Chart9 from "./Chart9";
import Chart10 from "./Chart10";


import useDevice from "./../../../lib/useDevice";
import EChartsUuid from "../../../components/EChartsUuid";


export default function PageBasicMain () {
    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "基本情况" }]);
    const { isMobile } = useDevice();
    const colWidth = isMobile ? "100%" : "20%";

    return (
        <div className={style.root} style={{ width: isMobile ? "100%" : '' }}>
            <Row justify="center" gutter={[24, 24]}>
                <Col span={isMobile ? 24 : 24} >
                    {/*  头部 */}
                    <Statistics1 />
                    <div className={style.mapBox}>
                        <Row>
                            <Col span={24}>
                                <FirstScreen
                                    showTimeLine={false}
                                    width={isMobile ? 'auto' : 900}
                                    height={isMobile ? 350 : 830}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div >
                <Row justify="space-between">
                    <Col className={`${style.wid420} ${style.mapBox1}`}>
                        <Chart8 />
                    </Col>
                    {/* <Col className={`${style.wid420} ${style.mapBox1}`}>
                        <Chart3 />
                    </Col> */}
                    <Col className={`${style.wid420} ${style.mapBox1}`}>
                        <Chart10 />
                    </Col>
                    <Col className={`${style.wid420} ${style.mapBox1}`} >
                        <Chart9 />
                    </Col>
                    <Col className={`${style.wid420} ${style.mapBox1} ${isMobile ? 'auto' : style.marTopMinus20}`} >
                        <Chart7 />
                    </Col>
                </Row>
            </div>

            {/*
      <Row justify="center" gutter={[24, 24]}>
        <Col span={16} className={style.picShow}>
          <Statistics1 />
          <div className={style.picLeft} style={{ display: "flex" }}>
            <div style={{ flex: 1, width: "100%" }}>
              <FirstScreen
                showTimeLine={false}
                width={700}
                height={650}
              />
            </div>
          </div>
        </Col>
        <Col span={8} className={style.picShow}>
          <div className={style.picRight}>
            <EChartsUuid
              uuid="4e48a1fa-c762-4b00-b937-1cea3bcf765c"
              customOption={(opt) => {
                return {
                  ...opt,
                  color: colorList,
                  series: opt.series?.map((item) => {
                    return {
                      ...item,
                      cursor: "auto",
                      radius: "60%",
                      label: {
                        fontSize: 12,
                      },
                      top: 20
                    };
                  }),
                  title: {
                    text: "高职院校分布图",
                    left: "center",
                    top: 15,
                    textStyle: {
                      color: "#347afc",
                    },
                  },
                  tooltip: {
                    trigger: "item",
                    formatter: function (params: any) {
                      return `${params.seriesName
                        } <br />${params.name}: ${parseInt(
                          params.value
                        )}所 (${params.percent}%)`;
                    },
                  },
                };
              }}
            />
          </div>
          <div className={style.picBottom}>
            <Chart3 />
          </div>
        </Col>
        <Col span={16} className={style.picPillar}>
          <div className={style.picLeft}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Chart4 />
              </div>
              <div style={{ width: "50%" }}>
                <Chart2 />
              </div>
            </div>
          </div>
        </Col>
        <Col span={8} className={style.picShow}>
          <div className={style.picRight}>
            <EChartsUuid
              uuid="cb097d7e-ff86-4022-a120-df7088efece2"
              customOption={(opt) => {
                return {
                  ...opt,
                  series: opt.series?.map((item) => {
                    return {
                      ...item,
                      cursor: "auto",
                      radius: "60%",
                      label: {
                        fontSize: 12,
                      },
                    };
                  }),
                  color: colorList,
                  title: {
                    text: "在校生人数规模分布图",
                    left: "center",
                    top: 15,
                    textStyle: {
                      color: "#347afc",
                    },
                  },
                  tooltip: {
                    trigger: "item",
                    formatter: function (params: any) {
                      return `${params.seriesName
                        } <br />在校生数${params.name
                        }的院校数: ${parseInt(
                          params.value
                        )}所 (${params.percent}%)`;
                    },
                  },
                };
              }}
            />
          </div>
        </Col>
      </Row> */}
        </div>

    );
}
