import { useEffect, useState } from "react";
import { Carousel } from 'antd';
import useRequest from "../../../../lib/useRequest";
import { currentCourseStatus } from "../../../../service/chart";
import { useHistory } from "react-router";
import style from "./index.module.scss";
import { currentTime, ICurrentTime, IPoint } from "../../../../service/chart";
import useRequestFn from "../../../../lib/useRequestFn";
import { getNormalData } from "../../../../service/basic";


export default function Index () {
  const [time, setTime] = useState('');//默认时间
  const [curTime] = useRequest(() => currentTime(time), {}, [time])//通过时间获取必要信息
  const [info, setInfo] = useState < ICurrentTime | null > (null)//保存取必要信息

  //更新info的值
  useEffect(() => {
    setInfo(curTime)
  }, [curTime])

  // const data = [
  //   {num: "1", school_name: "辽宁省交通高等专科学校"},
  //   {num: "2", school_name: "辽宁省交通高等专科学校"},
  //   {num: "3", school_name: "辽宁省交通高等专科学校"},
  // ]
  // const [data] = useRequest(currentCourseStatus)

  const [onData, data1] = useRequestFn(getNormalData)//获取数据
  const [data, setData] = useState < any > (null)

  async function onFn () {
    let res = await onData < any > ('5135eacf-a78f-4539-9591-b27728e7d888', {
      termAbb: info && info.termAbb ? info.termAbb : '',
      zhou: info && info.zhou ? info.zhou : '',
      xingqi: info && info.xingQi ? info.xingQi : ''
    })
    setData(res)
  }

  useEffect(() => {
    if (info)
    {
      onFn()
    }
  }, [info])


  const history = useHistory()

  return (
    <div className={style.root} style={{ height: 280, paddingTop: 30 }}>
      <div className={style.header}>
        <div>学校名称</div>
        <div>开课节数</div>
      </div>

      <Carousel autoplay={true} dots={false} dotPosition="left" slidesToScroll={1} slidesToShow={4} pauseOnHover={false}>

        {data?.map((item: any, index: number) => (
          <div onClick={() => history.push(`/course/main?school=${item.school_name}`)}>
            <div className={style.item} onClick={() => history.push("")} key={`${item.school_name}${index}`} >
              <div style={{ flexBasis: "330px", flexGrow: 0, flexShrink: 0 }}>{item.school_name}</div>
              <div>{item.num}</div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}
