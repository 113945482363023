import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import _ from "lodash";
import { useMemo } from "react";
import useRequest from "../../../../../lib/useRequest";
import { todayCourse } from "../../../../../service/chart";
import { getNormalData } from "../../../../../service/basic";

interface IProps {
    objData: string
}

export default function Index ({ objData }: IProps) {
    // const [data] = useRequest(() => todayCourse(objData), {}, [objData])
    let data: any
    [data] = useRequest(() => getNormalData('d3bacf6c-1f90-4ef9-b433-f29825f6dda1', {}))//课程类型情况
    const options: EChartOption<EChartOption.Series> = useMemo(() => {
        let max: any
        max = _.max(data?.map((item: any) => Number(item.value))) || 5000
        const list = _.sortBy(data?.map((item: any) => ({ value: Number(item.value), name: item.course })), "value")
        return {
            xAxis: {
                type: 'value',
                max: max + 2000,
                show: false
            },
            grid: {
                top: 0,
                left: "20%",
                bottom: 0
            },
            yAxis: {
                type: 'category',
                axisLine: { show: false },
                axisTick: { show: false },
                data: list?.map(item => item.name)
            },
            series: [
                {
                    cursor: 'default',
                    name: '背景色',
                    type: 'bar',
                    label: {
                        position: "right",
                        show: true,
                    },
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    barGap: '-100%',
                    barWidth: 8,
                    z: 0,
                    data: list?.map(item => {
                        return {
                            value: max + 1000,
                            label: {
                                formatter: `${item.value}`,
                                show: true,
                                color: "#666"
                            },
                            itemStyle: {
                                color: "#dfe5f1",
                                barBorderRadius: 8
                            }
                        }
                    })
                },
                {
                    cursor: 'default',
                    name: '数据',
                    type: 'bar',
                    barWidth: 8,
                    data: list?.map(item => {
                        return {
                            value: item.value,
                            itemStyle: {
                                barBorderRadius: 8,
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    x2: (max + 1000) / item.value,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#359ffe'
                                        },
                                        {
                                            offset: 1,
                                            color: '#e31c83'
                                        }
                                    ],
                                }
                            }
                        }
                    })
                },


            ]
        };

    }, [data])

    return <ReactEcharts style={{ height: 225 }} option={options} />;
}
Index.defaultProps = {
    title: "",
};
