import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Empty, Row, Spin } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { CourseGoalType, getCourseTrainingProgram } from "../../../../../service/training";

interface CourseIdProps {
    goalDetail:CourseGoalType[];
}

export default function Goal({goalDetail}: CourseIdProps) {

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.table}>
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{width: 100}}>目标名称</th>
                                    <th style={{width: 100}}>目标类型</th>
                                    <th>目标描述</th>
                                </tr>
                                {goalDetail?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.goal_name}</td>
                                        <td>{item.goal_type}</td>
                                        <td>{item.goal_mark}</td>
                                    </tr>
                                ))}
                                { (!goalDetail || goalDetail.length===0) && <tr><td colSpan={3}><Empty style={{marginTop: "50px"}} description="暂无数据" /></td></tr>}
                            </tbody>
                        </table>

                </div>
            </div>
        </div>
    );
}
