import styles from "../../index.module.scss";
import styles2 from "./index.module.scss";
import classNames from "classnames";
import {SpecialType} from "../../../../../../../../../../service/basic";

interface Props{
    data: SpecialType;
    onClick: (obj: SpecialType) => void;
    modalData: {
        name: string;
        count: string;
    }[];
}

export default function CardInfo({data, onClick, modalData}: Props) {
    function getText(name: string) {
        return modalData?.find((item: any) => item.name === name)?.count || "";
    }

    return (
        <div className={classNames(styles.info, styles2.root)}>
            <div className={styles.title}>
                {data.subject_name}
            </div>

            <div className={styles.footer}>
                <span title={"学校数"} className={styles.info1}>{getText('学校数')}</span>
                <span title={"专业数"} className={styles.info2}>{getText('专业数')}</span>
                <span title={"专业布点数"} className={styles.info2}>{getText('专业布点数')}</span>
                <span title={"学生数"} className={styles.info3}>{getText('学生数')}</span>
                <span className={styles.more} onClick={() => onClick(data)}>详细</span>
            </div>
        </div>
    )
}
