import React, { useEffect, useState } from "react";
import { Card, Row, Select, Space, Col, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import style from "./index.module.scss";
import { PlusOutlined } from '@ant-design/icons';
import Statistic from '../../components/Statistic';
import DragTransfer from "../../components/DragTransfer";
import useRequest from "../../lib/useRequest";
import { deleteReportDesign, findCustomTableList } from "../../service/report";
import FirstScreen from "../Charts/Components/FirstScreen";
import ChartUuid from "../../components/ChartUuid";
import useBreadcrumb from "../../lib/useBreadcrumb";
import SearchScreen from "../Charts/Components/SearchScreen1";
import Statics from "./Components/Statics";
import Config from "../../config";
import EChartsUuid from "../../components/EChartsUuid";
import Chart4 from "./Components/Chart4";
import Chart6 from "./Components/Chart6";
import Chart7 from "./Components/Chart7";
import SchoolType from './Components/SchoolType'
import Chart1 from "./Components/Chart1";
import Chart2 from "./Components/Chart2";

import Banner from "./Components/Banner";
import useDevice from "../../lib/useDevice";

let colorList3 = [
    '#f77070',
    '#ffd200',
    '#ffad56',
    '#ff6c8b',
    '#468cff',
    '#326dce',
    '#7d6cff'
]
let colorList4 = [
    '#326dce',
    '#468cff',
    '#56cefd',
    '#34ac74',
    '#84cf61',
    '#ffd200',
    '#ffad56',
    '#f77070',
    '#7d6cff'
]
let colorList5 = [
    '#dc3037',
    '#f0d366',
    '#46a7a3',
    '#80b477',
    '#84cf61',
    '#ffd200',
    '#ffad56',
    '#f77070',
    '#7d6cff'
]

export default function PageHome () {
    const [reRender, setRender] = useState(1);
    return <Main onChange={() => setRender(reRender + 1)} key={reRender} />;
}

function Main ({ onChange }: { onChange: () => void }) {

    const history = useHistory()

    const [visible, setVisible] = useState(false)

    const [tableList, { loading: tableLoading }, loadTableList] = useRequest(findCustomTableList);

    const [markYear, setMarkYear] = useState(localStorage.getItem("year") || "2");

    let y: any = localStorage.getItem("year") ? localStorage.getItem("year") : '2'
    localStorage.setItem("year", y);


    // 切换标记
    const [flag, setFlag] = useState(false);
    const showTimeLine = false;
    useBreadcrumb([{ name: "工作页", url: "/" }]);

    const handleOnTableRemove = (guid: string) => {
        Modal.confirm({
            title: "是否删除？",
            onOk: () => deleteReportDesign(guid)
                .then(() => {
                    message.success("删除成功")
                    loadTableList()
                })
        })
    }
    const [time, setTime] = useState('');//默认时间

    const handleOnOk = () => {
        setVisible(false)
        loadTableList()
    }

    const { isMobile } = useDevice();
    const colWidth = isMobile ? "100%" : "20%";

    const token = localStorage.getItem('LNDSJ')
    useEffect(() => {
        if (!token)
        {
            // history.push("/auth/login")
        }
    }, [token])
    return (
        <div className={`container ${style.root}`} style={{ marginTop: isMobile ? '-20px' : '' }}>

            <Space size="large" style={{ width: "100%", }} direction="vertical">
                <Card title="基本数据" headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }} extra={
                    <Select
                        defaultValue={markYear}
                        style={{ width: 200 }}
                        onChange={(data: any) => {
                            setMarkYear(data);
                            localStorage.setItem("year", data);
                            onChange();
                        }}
                    >
                        {Config.yearsArr.map(item => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
                    </Select>
                }>
                    <Statics />
                </Card>

                <Card loading={tableLoading} headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                    title="常用统计数据">
                    <Row gutter={[15, 15]}>
                        {tableList?.map((item, index) => {
                            return <Col key={item.guid} style={{ width: colWidth }}><Statistic
                                onClick={() => history.push(`/report/${item.guid}`)}
                                onRemove={() => handleOnTableRemove(item.guid)} text={item.name}
                                color={(index + 1) % 9 as 1} /></Col>
                        })}
                        <Col style={{ width: colWidth }}>
                            <Statistic
                                onClick={() => setVisible(true)} align="center"
                                top={<PlusOutlined size={36} />}
                                text="新建统计" color={0}
                            />
                            <DragTransfer visible={visible} onCancel={() => setVisible(false)} onOk={handleOnOk} />
                        </Col>
                    </Row>
                </Card>

                <Row gutter={[20, 20]} className={style.marBtm20}>
                    <Col span={isMobile ? 24 : 16}>
                        <div className={style.header}>

                            <p onClick={() => {
                                setFlag(false)
                            }} className={`${flag === false ? style.active : ''}`}>辽宁省高职院校地域分布</p>
                            <p>/</p>
                            <p onClick={() => {
                                setFlag(true)
                            }} className={`${flag === true ? style.active : ''}`}>辽宁省高职专业地域分布</p>

                        </div>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                            bodyStyle={{ overflow: "hidden" }} className={style.chartCard}>

                            {
                                flag === true && <SearchScreen showTimeLine={false} width={isMobile ? 'auto' : 660} height={isMobile ? 350 : 667} />
                            }

                            {

                                flag === false && <FirstScreen width={isMobile ? 'auto' : 700} divHeight={isMobile ? 350 : 706} paddingTop={isMobile ? 30 : 97} height={isMobile ? 350 : 706}
                                    showTimeLine={showTimeLine} />
                            }

                        </Card>
                    </Col>
                    <Col span={isMobile ? 24 : 8}>
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                                    title="辽宁省高职院办学类型占比" className={style.chartCard}>
                                    <EChartsUuid uuid="dc302597-e65b-4292-ae4d-dfe97170d29d"
                                        customOption={(opt) => {
                                            return {
                                                ...opt,
                                                color: colorList5,
                                                series: opt.series?.map(item => {
                                                    return {
                                                        ...item,
                                                        //@ts-ignore
                                                        data: item.data?.filter(i => i.name) || [],
                                                        // data: item.data?.filter(item => item.name),
                                                        radius: '65%',
                                                        cursor: 'default',
                                                        label: {
                                                            fontSize: 12
                                                        },
                                                        tooltip: {
                                                            trigger: 'item',
                                                            formatter: function (params: any) {
                                                                return `${params.seriesName} <br />${params.name}: ${parseInt(params.value)}所 (${params.percent}%)`;
                                                            }
                                                        },
                                                    }
                                                })
                                            }
                                        }} />
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                                    title="辽宁省高职院校在校生规模示意图" className={style.chartCard}>
                                    <Chart1 />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col span={24}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }} title="辽宁省高职院校办学类别示意图"
                            className={style.totalMap}>
                            <SchoolType />
                        </Card>
                    </Col> */}
                    {/* <Col span={24}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }} title="辽宁省高职院校经费支出情况"
                            className={style.totalMap}>
                            <Chart4 />
                        </Card>
                    </Col> */}
                    <Col span={isMobile ? 24 : 12}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                            title="辽宁省高职院校经费收入渠道比例示意图" className={style.chartCard}>
                            <Chart2 uuid="36454aef-7303-4b5e-81b7-cddf652b7884" colorList={colorList3} />
                        </Card>
                    </Col>
                    <Col span={isMobile ? 24 : 12}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                            title="辽宁省高职院校经费支出渠道比例示意图" className={style.chartCard}>
                            <Chart2 uuid="a8572a5d-15fe-4679-9d42-87cf9535a2b7" colorList={colorList4} />
                        </Card>
                    </Col>
                    <Col span={isMobile ? 24 : 12}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                            title="扩招生源类别示意图" className={style.chartCard}>
                            <Chart6 />
                        </Card>
                    </Col>
                    <Col span={isMobile ? 24 : 12}>
                        <Card headStyle={{ color: "#347afc", fontWeight: "bold", fontSize: 20 }}
                            title="扩招生源类别年度对比示意图" className={style.chartCard}>
                            <Chart7 />
                        </Card>
                    </Col>
                </Row>
            </Space>

        </div>
    );
}
