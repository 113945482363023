import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";
import Popup from "./../../../../../components/EChartsUuid/components/Popup";
import style from './style.module.scss';

let colorList = [
    "#34ac74",
    "#468cff",
    "#ffd200",
    "#f77070",
    "#ffad56",
    "#e740a6",
    "#7d6cff",
    "#326dce",
    "#56cefd",
    "#84cf61",

];
export default function Index (props: any) {
    const [onData, data] = useRequestFn(getNormalData)//课程类型情况
    const [classType, setClassType] = useState < any > (null)
    async function onFn () {
        let res = await onData('07dc6509-35a6-40c2-983f-39bd39b8b80b', { zhou: props.info?.zhou, term: props.info?.term })
        setClassType(res)
    }
    useEffect(() => {
        if (props.info)
        {
            onFn()
        }
    }, [props.info])

    const options: any = useMemo(() => {
        let datas = classType
        return {
            color: colorList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b} : {c} ({d}%)"
            },

            series: [datas].map(function (item: any, index: number) {
                return {
                    type: 'pie',
                    radius: '70%',
                    // center: ['50%', '50%'],
                    width: '100%',
                    data: item
                };
            })


        }
    }, [classType])


    const [tableTitle, setTableTitle] = useState('');
    const [show, setShow] = useState(false);
    const [schoolList, setSchoolList] = useState < any > ([]);
    const [onList, list] = useRequestFn(getNormalData)
    // async function onFn (name: string) {
    //     let res = await onList < any > ('07dc6509-35a6-40c2-983f-39bd39b8b80b', { areaCode: '', type: '', xz_code: name })
    //     if (res)
    //     {
    //         setSchoolList(res)
    //     }
    // }

    const onEventsObj = {
        'click': (params: any) => {
            // onFn(params.name)
            // setShow(true)
        }
    }

    return (
        <div >
            <ReactEcharts
                style={{ height: '262px', marginTop: '30px' }}
                option={options}
                onEvents={onEventsObj}
            />
            {/* <Popup onClick={() => {
                // onClean?.();
                setShow(false);
            }} show={show} width='80vw'>
                <div className={style.tableWrapper}>
                    <div
                        className={style.tableTitle}>{tableTitle}</div>
                    <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                        <table className={style.table}>
                            <tbody className={style.tableList}>
                                <tr className={style.tableListTitle}>
                                    <th style={{ width: '20vw' }}>学校名称</th>
                                    <th style={{ width: '20vw' }}>学校属性</th>
                                    <th style={{ width: '10vw' }}>在校生数</th>
                                    <th style={{ width: '10vw' }}>占地面积</th>
                                    <th style={{ width: '10vw' }}>建筑面积</th>
                                    <th style={{ width: '10vw' }}>年度经费投入</th>
                                </tr>
                                {schoolList?.map((item: any, index: number) => <tr key={index}>
                                    <td>{item.schoolName}</td>
                                    <td>{item.school_sx}</td>
                                    <td>{item.stuCount}</td>
                                    <td>{item.all_area}</td>
                                    <td>{item.jzmj_zj}</td>
                                    <td>{item.inCase}</td>
                                </tr>)}
                            </tbody>
                        </table>

                    </div>
                </div>

            </Popup> */}
        </div>
    );
}
