import style from "./index.module.scss";
import { Button, Divider, Modal } from "antd";
import { PoweroffOutlined } from '@ant-design/icons';
import UserMenu from "../UserMenu";
import { useHistory } from "react-router-dom";

import logoutImage from "../../img/logout.png";
import React from "react";

export default function HeaderRight () {

    const history = useHistory();

    function onLogout () {
        Modal.confirm({
            title: "提示", content: "确定要退出登录吗?", onOk: () => {
                history.push("/auth/login")
                localStorage.removeItem('LNDSJ')
            }
        });

    }

    return (
        <div className={style.root}>
            {/*<Button className={style.editBtn} ghost shape="round">编辑数据</Button>*/}

            <div>
                <UserMenu />
            </div>
            <Divider type="vertical" />
            <div className={style.logoutWrap}>
                <img onClick={onLogout} className={style.logout} src={logoutImage} alt="" />
            </div>
            {/* <Button type="link" className={style.logout} onClick={onLogout}>
                <PoweroffOutlined />
            </Button> */}
        </div>
    );
}
