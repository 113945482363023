import React from "react";
import style from "./index.module.scss"
import { Button, Col, Form, Input, Row } from 'antd';
import { RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import VocationalRow from "../../../components/VocationalRow";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useRequest from "../../../lib/useRequest";
// import {findSpecialtyList} from "../../../service/major";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import SchoolList from "./components/SchoolList";
import { getNormalData } from "./../../../service/basic";
import useDevice from "./../../../lib/useDevice";

export default function PageMajorList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "专业信息" }]);
  const [form] = Form.useForm();
  const history = useHistory();

  // const [ data ] = useRequest(findSpecialtyList)
  const [data] = useRequest(() => getNormalData < any > ("64ad7c0b-5aa6-4369-bf84-a2411c5b8cbf")); // 按专业查高校

  function onFinish (query: any) {
    history.push(`/major/search?${qs.stringify(query)}`);
  }
  const { isMobile } = useDevice();
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Form onFinish={onFinish} form={form} labelCol={{ span: 4 }}>
            <Row gutter={24}>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="name" label="专业名称" labelCol={{ span: 8 }}>
                  <Col span={22}>
                    <Input placeholder="请输入关键字" />
                  </Col>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="school" label="学校名称" labelCol={{ span: 8 }}>
                  <Col span={22}>
                    <Input placeholder="请输入学校名称" />
                  </Col>
                </Form.Item>
              </Col>

              <Col span={isMobile ? 24 : 6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginRight: "20px" }}>
                  查询
                </Button>
                <Button icon={<RetweetOutlined />} onClick={() => form.resetFields()}>
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className={style.contentList}>
          <div className={style.title}>
            <span>按高校查专业</span>
          </div>
          <SchoolList />
        </div>

        <div className={style.contentList}>
          <div className={style.title}>
            <span>按专业查高校</span>
          </div>
          <p className={style.tip}>格式为：专业代码 专业名称（设置该专业的高校数量），点击专业名称可查看所有开设该专业的高校信息。</p>
          {
            data?.map((item: any) => <VocationalRow key={item.code} category={item.name} child={item.child} />)
          }
        </div>
      </div>

    </div>
  );
}
