import { Table, Button } from "antd";
import Popup from "./Popup";
import TableStyle from "../../../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss";
import React, { useState, useEffect } from "react";
import style from "../../index.module.scss";
import { getNormalData } from "../../../../../service/basic";
import useRequestFn from "../../../../../lib/useRequestFn";
import useDevice from "../../../../../lib/useDevice";

interface MockTable1Props {
    visible: boolean;
    onClose: () => void;
    name: string
}

export default function MockTable1 ({ visible, onClose, name }: MockTable1Props) {

    const [page, setPage] = useState(1);
    const { isMobile } = useDevice()


    //承载人数
    const [onDList, data2] = useRequestFn(getNormalData)
    const [data, setRequestSchoolList] = useState < any > (null)
    const [data1, setRequestSchoolList1] = useState < any > (null)
    async function onList1 () {
        let res: any = await onDList('41eeccb7-aea9-4072-b49c-87d2177373da', { name: name })
        let arr: any = []
        if (res)
        {
            res.map((item: any) => {
                arr.push(
                    { col1: item.name, col2: item.subjectNum, col3: item.dgrs, col4: item.ggrs },
                )
            })
        }
        setRequestSchoolList(arr)
    }
    async function onList2 (name: string) {
        let res: any = await onDList('4869a1dc-b63f-4430-a59d-2b54f4b76473', { name: name })

        if (res && res != null)
        {
            let arr: any = []
            res.map((item: any) => {
                arr.push(
                    { col1: item.subjectName, col2: item.name, col3: item.dgrs + item.ggrs },
                )
            })
            setRequestSchoolList1(arr)
        }
    }
    useEffect(() => {
        onList1()
    }, [name])

    const [name1, setName1] = useState < any > ('')
    let num1 = (val: any) => {
        setName1(val.col1)
        onList2(val.col1)
    }
    useEffect(() => {
        setPage(1)
    }, [onClose])
    return (
        <div>
            <Popup show={visible} onClick={onClose} width={isMobile ? '80vw' : '1200px'}>
                <div className={TableStyle.tableWrapper}>
                    <div className={TableStyle.tableTitle}>实习实训基地列表

                        {
                            page == 2 ? <Button type={"primary"} onClick={() => { setPage(1) }} style={{ float: isMobile ? 'none' : 'right', marginLeft: isMobile ? '10px' : '0' }}>返回</Button> : ''
                        }
                    </div>

                    {
                        page == 1 ?
                            <div style={{ height: 480, overflow: "auto" }} className={style.tablePop}>
                                <Table dataSource={data} pagination={false}>
                                    <Table.Column title="基地名称" key="col1" dataIndex="col1" align="left" width={400} render={(text, record) => (
                                        <a href="javascript:void(0)" onClick={() => {
                                            // setShowTable2(true)
                                            setPage(2)
                                            num1(record)
                                        }}>{text}</a>
                                    )} />

                                    <Table.Column title="专业数量" key="col4" dataIndex="col4" align="center" render={(text) => (
                                        <a href="javascript:void(0)" onClick={() => setPage(2)}>{text}</a>
                                    )} />
                                    <Table.Column title="接收人数" key="col3" dataIndex="col3" align="center" />

                                    {/* <Table.ColumnGroup title="接收人数">
                                        <Table.Column title="顶岗" key="col2" dataIndex="col2" align="center" />
                                        <Table.Column title="跟岗" key="col3" dataIndex="col3" align="center" />
                                    </Table.ColumnGroup> */}
                                </Table>
                            </div> : <div style={{ height: 480, overflow: "auto" }} className={style.tablePop}>
                                <Table className={TableStyle.table} dataSource={data1} pagination={false}>
                                    <Table.Column title="专业名称" key="col1" dataIndex="col1" align="left" width={300} />
                                    <Table.Column title="基地名称" key="col2" dataIndex="col2" align="left" width={400} />
                                    <Table.Column title="接收人数" key="col3" dataIndex="col3" align="center" />

                                    {/* <Table.ColumnGroup title="接收人数">
                                        <Table.Column title="顶岗" key="col3" dataIndex="col3" align="center" />
                                        <Table.Column title="跟岗" key="col4" dataIndex="col4" align="center" />
                                    </Table.ColumnGroup> */}
                                </Table>
                            </div>
                    }

                </div>
            </Popup>
        </div>
    )
}
