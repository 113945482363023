import styles from "../../index.module.scss";
import styles2 from "./index.module.scss";
import classNames from "classnames";
import {SpecialType} from "../../../../../../../../../../service/basic";

interface Props{
    data: SpecialType;
    onClick: (obj: SpecialType) => void;
}

export default function CardInfo({data, onClick}: Props) {

    return (
        <div className={classNames(styles.info, styles2.root)}>
            <div className={styles.title}>
                {data.subject_name}
            </div>

            <div className={styles.footer}>
                <span title={"专业编号"} className={styles.info1}>{data.subject_code}</span>
                <span title={"专业布点数量"} className={styles.info2}>{data.zybds}</span>
                <span className={styles.more} onClick={() => onClick(data)}>详细</span>
            </div>
        </div>
    )
}
