import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { VideoCameraOutlined } from "@ant-design/icons";
import { Carousel, Row, Col } from 'antd';

interface IProps {
    title: string;
    data: string[][];
    colData: string[];
    customStyle?: {}
}

export default function ({ title, data, colData, customStyle }: IProps) {

    const [play, setPlay] = useState(true)
    //根据鼠标移入移除判断是否滚动
    const [num, setNumber] = useState(0)

    useEffect(() => {
        setNumber(data.length)
        setPlay(true)
    }, [data])

    //鼠标移入div
    function onEnter (e: any) {
        setPlay(true)

        setNumber(data.length)
    }

    //鼠标移出div
    function onLeave (e: any) {
        setPlay(false)

        if (play)
        {
            setNumber(9)
        } else
        {
            setNumber(num)
        }
    }

    return <div className={style.root} style={customStyle}>
        {/* <div className={style.title}>{title}</div>
    <div className={style.main}>
      <table className={style.table}>
        <thead className={style.thead}>
          <tr>
            {colData.map((item, index) => <th style={{ width: index === 0 || index === 1 ? "30%" : "auto" }} key={index}>{item}</th>)}
          </tr>
        </thead>
        <tbody className={style.tableList}>
          {data.map((item, index) =>
            <tr key={index} className={style.itemStyle}>
              {item.map((name: any, num) =>
                item.length - 1 == num ? <td><a href={name}><VideoCameraOutlined /></a> </td> : <td key={num}>
                  {name}
                </td>)
              }
            </tr>)
          }
        </tbody>
      </table>
    </div> */}

        <div className={style.main}>
            <div className={style.title}>{title}</div>
            <div className={style.header}>
                <Row>
                    <Col span={8}>学校</Col>
                    <Col span={6}>课程名称</Col>
                    <Col span={3}>教师</Col>
                    <Col span={3}>上课节次</Col>
                    <Col span={4}>课堂链接</Col>
                </Row>
            </div>
            <div className={style.body}
                onMouseEnter={(e) => { onEnter(e) }}
                onMouseLeave={(e) => { onLeave(e) }}
            >
                <Carousel autoplay={true} dots={play} autoplaySpeed={2000} dotPosition="left" slidesToScroll={1} slidesToShow={num}>
                    {data.map((item, index: number) =>
                        <div key={index} className={style.itemBox}>
                            <div style={{ width: '33.3%', color: '#999', padding: '12px 5px', textAlign: "center" }} >{item[0]}</div>
                            <div style={{ width: '25%', color: '#999', padding: '12px 5px', textAlign: "center" }} >{item[1]}</div>
                            <div style={{ width: '12.5%', color: '#999', padding: '12px 5px', textAlign: "center" }}>{item[2]}</div>
                            <div style={{ width: '12.5%', color: '#999', padding: '12px 5px', textAlign: "center" }}>{item[3]}</div>
                            <div style={{ width: '16.6%', color: '#999', padding: '12px 5px', textAlign: "center" }} ><a href={item[4]}><VideoCameraOutlined /></a> </div>
                        </div>)
                    }
                </Carousel>
            </div >
        </div>
    </div >
}
