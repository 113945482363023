import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import useRequestFn from "../../../../lib/useRequestFn";
import { getNormalData } from "../../../../service/basic";
import Popup from "./../../../../components/EChartsUuid/components/Popup";
import style from './style.module.scss';

let colorList = [
    "#ffd200",
    "#ffad56",
    "#f77070",
    "#e740a6",
    "#7d6cff",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",

];
export default function Index (props: any) {
    const [data] = useRequest(() => getNormalData < any > (props.uuid))


    const options: any = useMemo(() => {
        let datas = data?.option.series[0].data
        return {
            color: props.colorList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b} : {c} ({d}%)"
            },

            // series: [
            //     {
            //         name: '',
            //         type: 'pie',
            //         // radius: ['40%', '70%'],
            //         // center: ['50%', '50%'],
            //         avoidLabelOverlap: false,
            //         data: data ? data.option.series[0].data : [],
            //         emphasis: {
            //             itemStyle: {
            //                 shadowBlur: 10,
            //                 shadowOffsetX: 0,
            //                 shadowColor: 'rgba(0, 0, 0, 0.5)'
            //             }
            //         }
            //     }
            // ]
            series: [datas].map(function (item: any, index: number) {
                var top = index * 33.3;
                return {
                    type: 'pie',
                    // radius: ['40%', '70%'],
                    // center: ['50%', '50%'],
                    // avoidLabelOverlap: false,
                    // top: top + '%',
                    // height: '100%',
                    // left: 'center',
                    // width: '100%',
                    // itemStyle: {
                    //     borderColor: '#fff',
                    //     borderWidth: 1
                    // },

                    labelLine: {
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    label: {
                        alignTo: 'edge',
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        rich: {
                            time: {
                                fontSize: 10,
                                color: '#999'
                            }
                        }
                    },

                    data: item
                };
            })


        }
    }, [data])


    const [tableTitle, setTableTitle] = useState('');
    const [show, setShow] = useState(false);
    const [schoolList, setSchoolList] = useState < any > ([]);
    const [onList, list] = useRequestFn(getNormalData)
    async function onFn (name: string) {
        let res = await onList < any > ('55023e76-a089-4619-86c0-e93882dde8c8', { areaCode: '', type: '', xz_code: name })
        if (res)
        {
            setSchoolList(res)
            setTableTitle(`${name}高职院校经费收入${res.length}所`)
        }
    }

    const onEventsObj = {
        'click': (params: any) => {
            // onFn(params.name)
            // setShow(true)
        }
    }

    return (
        <div >
            <ReactEcharts
                style={{ height: '250px' }}
                option={options}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                // onClean?.();
                setShow(false);
            }} show={show} width='80vw'>
                <div className={style.tableWrapper}>
                    <div
                        className={style.tableTitle}>{tableTitle}</div>
                    <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                        <table className={style.table}>
                            <tbody className={style.tableList}>
                                <tr className={style.tableListTitle}>
                                    <th style={{ width: '20vw' }}>学校名称</th>
                                    <th style={{ width: '20vw' }}>学校属性</th>
                                    <th style={{ width: '10vw' }}>在校生数</th>
                                    <th style={{ width: '10vw' }}>占地面积</th>
                                    <th style={{ width: '10vw' }}>建筑面积</th>
                                    <th style={{ width: '10vw' }}>年度经费投入</th>
                                </tr>
                                {schoolList?.map((item: any, index: number) => <tr key={index}>
                                    <td>{item.schoolName}</td>
                                    <td>{item.school_sx}</td>
                                    <td>{item.stuCount}</td>
                                    <td>{item.all_area}</td>
                                    <td>{item.jzmj_zj}</td>
                                    <td>{item.inCase}</td>
                                </tr>)}
                            </tbody>
                        </table>

                    </div>
                </div>

            </Popup>
        </div>
    );
}
