import styles from "../../../Employment/Main/components/Statistics/style.module.scss";
import React from "react";
import { Col, Row } from "antd";
import useRequest from "../../../../lib/useRequest";
import { findCountValue, getNormalData } from "../../../../service/basic";
import { toInt, toNumber } from "../../../../lib/utils";
import useDevice from "../../../../lib/useDevice";
import currentStyle from "./currentStyle.module.scss"
export default function Statics () {

  // const [data1] = useRequest(() => findCountValue("count_major_num_2020"));
  // const [data2] = useRequest(() => findCountValue("count_student_num_2020"));
  // const [data3] = useRequest(() => findCountValue("count_teacher_num_2020"));
  // const [data4] = useRequest(() => findCountValue("count_cost_num_2020"));

  const [collegeNum] = useRequest(() => getNormalData('2ac720f7-9848-4620-ab2b-632903f1a4b5')); // 院校数量
  const [data1] = useRequest(() => getNormalData('79001196-ab23-4952-87ad-34026168df62')); // 开设专业数量
  const [studentNum] = useRequest(() => getNormalData<any>('2ba10854-6a85-4001-8206-7937136ab158')); // 开设专业数量
  const [teacherNum] = useRequest(() => getNormalData('7f40ec3e-6cfc-4fe9-8bff-0e69e9f43428')); // 教师数量
  // const [cost] = useRequest(() => getNormalData('768b0168-33bc-4f75-9aef-4c461c4255da')); // 经费总投入

    const {isMobile} = useDevice();
    const colWidth = isMobile ? "100%" : "25%";

  return (
    <Row gutter={[15, 15]} className={currentStyle.homeRowBlock}>
      <Col style={{ width: colWidth }}>
        <div className={[`${styles.block1}`,currentStyle.newBlock].join(" ")} style={{ marginLeft: 0 }}>
          <div className={styles.label}>院校数量</div>
          <div className={styles.number} style={{ fontSize: 30 }}>{collegeNum || 0}所</div>
          <div className={styles.shadow} />
        </div>
      </Col>

      <Col style={{ width: colWidth }}>
        <div className={[`${styles.block2}`,currentStyle.newBlock].join(" ")} style={{ marginLeft: 0 }}>
          <div className={styles.label}>开设专业数量</div>
          <div className={styles.number} style={{ fontSize: 30 }}>{data1 || 0}个</div>
          <div className={styles.shadow} />
        </div>
      </Col>

      <Col style={{ width: colWidth }}>
        <div className={[`${styles.block3}`,currentStyle.newBlock].join(" ")} style={{ marginLeft: 0 }}>
          <div className={styles.label}>在校学生数量</div>
          <div className={styles.number} style={{ fontSize: 30 }}>{parseInt(studentNum) || 0}人</div>
          <div className={styles.shadow} />
        </div>
      </Col>

      <Col style={{ width: colWidth }}>
        <div className={[`${styles.block4}`,currentStyle.newBlock].join(" ")} style={{ marginLeft: 0 }}>
          <div className={styles.label}>教师数量</div>
          <div className={styles.number} style={{ fontSize: 30 }}>{teacherNum || 0}人</div>
          <div className={styles.shadow} />
        </div>
      </Col>

      {/* <Col style={{ width: colWidth }}>
        <div className={[`${styles.block5}`,currentStyle.newBlock].join(" ")} style={{ marginLeft: 0 }}>
          <div className={styles.label}>经费总投入</div>
          <div className={styles.number} style={{ fontSize: 30 }}>{cost || 0}万元</div>
          <div className={styles.shadow} />
        </div>
      </Col> */}
    </Row>
  );
}
