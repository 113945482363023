import {Button, Modal, Table} from "antd";
import React, {useState} from "react";
import {ColumnsType} from "antd/lib/table/interface";

interface PopCol1Props {
    num: number;
}

export default function PopCol1({num}: PopCol1Props) {

    const [visible, setVisible] = useState(false);

    const columns: ColumnsType<any> = [
        {
            title: "中心名称",
            dataIndex: "col1",
        },
        {
            title: "中心级别",
            dataIndex: "col2",
        },
        {
            title: "基地面积",
            dataIndex: "col3",
        },
        {
            title: "工位数",
            dataIndex: "col4",
        },
        {
            title: "师资人数",
            dataIndex: "col5",
        },
        {
            title: "共享资源项目数",
            dataIndex: "col6",
        },
        {
            title: "创建时间",
            dataIndex: "col7",
        },
    ];

    const dataSource: any[] = [
        {col1: "材料工程系实训基地", col2: "国家级", col3: 3708.00, col4: 242, col5: 11, col6: 5, col7: "2007-03-11"},
        {col1: "电气工程系实训基地", col2: "国家级", col3: 6959.30, col4: 1200, col5: 9, col6: 6, col7: "2004-05-23"},
        {col1: "多功能共享型实训基地", col2: "省级", col3: 5280.23, col4: 512, col5: 3, col6: 2, col7: "2018-04-12"},
        {col1: "师范教育系实训基地", col2: "其他", col3: 3730.00, col4: 386, col5: 6, col6: 5, col7: "1998-09-14"},
        {col1: "船舶工程系实训基地", col2: "国家级", col3: 8060.00, col4: 302, col5: 7, col6: 4, col7: "2007-10-16"},
        {col1: "信息工程系实训基地", col2: "其他", col3: 954.80, col4: 290, col5: 2, col6: 1, col7: "2001-07-02"},
        {col1: "信息技术管理中心实训基地", col2: "其他", col3: 7584.00, col4: 1530, col5: 7, col6: 8, col7: "2002-09-11"},
    ];

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>{num}</Button>

            <Modal visible={visible} onCancel={() => setVisible(false)} title="校内实训基地工位数" width={1000} footer={false}>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Modal>
        </>
    );
}
