import React, {useContext, useEffect} from "react";
import {AdminContext, BreadcrumbItem} from "../AdminContext";

export default function useBreadcrumb(node: BreadcrumbItem[]) {

    const {setBreadcrumb} = useContext(AdminContext);

    useEffect(() => {
        setBreadcrumb(node);
    }, []);
}
