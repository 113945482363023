import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption, EChartsResponsiveOption } from "echarts";
import 'echarts/map/js/province/liaoning.js';
import { Table } from 'antd';
import style from './style.module.scss';
import Popup from "../Popup";
import ThemeData from '../../../../../src/assets/walden.project.json';
import { ILevel, ISchoolInfo } from "../../../../service/chart";
import TableStyle from '../../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss';
import _ from 'lodash';
import useDevice from "../../../../lib/useDevice";

interface ProvinceProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: number;
    height?: number;
    year: string[];
    tableType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList?: ISchoolInfo[]
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: any; schCount: any }[]; // 国家级院校
    schoolListSpecial?: any; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
}

interface DataArrTypes {
    [key: string]: {
        values: number[],
    }
}

// 红点坐标
const pointsRedCoord = {
    '沈阳市': [123.229096, 42.396767],
    '大连市': [121.618622, 39.51459],
    '鞍山市': [122.795632, 40.810626],
    '抚顺市': [124.421109, 41.775956],
    '本溪市': [124.070519, 41.297909],
    '丹东市': [124.383044, 40.524296],
    '锦州市': [121.135742, 41.419269],
    '营口市': [122.535151, 40.497432],
    '阜新市': [121.648962, 42.311796],
    '辽阳市': [123.38152, 41.269402],
    '盘锦市': [122.06957, 41.224484],
    '铁岭市': [124.044279, 42.740585],
    '朝阳市': [120.451176, 41.876758],
    '葫芦岛市': [120.256394, 40.755572],
    '辽宁省': [],
};

const pointsBlueCoord = {
    '沈阳市': [122.929096, 41.796767],
    '大连市': [122.618622, 39.71459],
    '鞍山市': [123.295632, 40.510626],
    '抚顺市': [124.921109, 41.875956],
    '本溪市': [125.070519, 41.297909],
    '丹东市': [124.783044, 40.824296],
    '锦州市': [121.835742, 41.549269],
    '营口市': [122.435151, 40.267432],
    '阜新市': [122.148962, 42.311796],
    '辽阳市': [123.38152, 40.869402],
    '盘锦市': [122.06957, 41.024484],
    '铁岭市': [124.544279, 42.540585],
    '朝阳市': [119.851176, 41.576758],
    '葫芦岛市': [120.056394, 40.255572],
    '辽宁省': [],
};

export default function Province ({
    name, data, className, year,
    changeCityName, schoolList,
    geoColor = '46, 199, 201',
    tableType = 1, width = 1920,
    height = 1000, special = false,
    pointsBlue, pointsRed,
    schoolListSpecial,
    showTimeLine,
    onClean
}: ProvinceProps) {

    const [years, setYears] = useState < string[] > ([]);
    const echartsRef = useRef < any > (null);
    const [cityNameArr, setCityNameArr] = useState < string[] > ([
        '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市',
        '锦州市',
        '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市', '葫芦岛市']);
    const [dataArr, setDataArr] = useState < DataArrTypes > ({});
    const [geoRegions, setGeoRegions] = useState < any[] > ([]);

    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');
    const { isMobile } = useDevice()
    useEffect(() => {
        if (data)
        {
            // 城市名称 年份
            const yearArr: string[] = [];
            const geoRegions: any = []; // 城市颜色

            data.map(item => {
                if (!yearArr.includes(item.year)) yearArr.push(item.year);
            });

            cityNameArr.map((city, index) => {

                geoRegions.push({
                    name: city,
                    itemStyle: {
                        normal: {
                            areaColor: `rgba(${geoColor}, 0.8)`
                        }
                    }
                });
            });

            setYears(yearArr);
            setGeoRegions(geoRegions);

            initDataArr(cityNameArr);

        }
    }, [data]);

    // 按年份 dataArr数据
    function initDataArr (cityNameArr: string[]) {
        const dataArr: DataArrTypes = {};
        year.map(year => {
            dataArr[year] = { values: [] }
            cityNameArr.map(city => {
                data.map(item => {
                    if (item.year === year && item.name === city)
                    {
                        dataArr[year]['values'].push(item.value);
                    }
                })
            });
        });
        setDataArr(dataArr);
    }

    function formatPointData (data: { areaCode: string; schCount: string }[], isRedPoint: boolean = true) {
        const defaultData: { [key: string]: number[] } = isRedPoint ? pointsRedCoord : pointsBlueCoord;
        return data.map(item => {
            const key: string = item.areaCode;
            return {
                name: item.areaCode,
                value: [...defaultData[key], item.schCount],
                visualMap: false
            }
        });
    }

    // 柱形及年份线 位置大小调整
    const barOption = {
        left: showTimeLine ? '10%' : '-100%',
        bottom: '0%',
        width: '80%'
    };


    // timeline 文字大小
    const timelineLabel = special ? {
        label: {
            rotate: '20',
            align: 'center',
            color: '#ffffff',
        }
    } : {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            }
        }
    };

    const timeline = useMemo(() => {
        return {
            title: {
                text: '',
                subtext: ''
            },
            toolbox: {
                show: false,
            },
            geo: {
                map: name,
                // regions: [
                //     ...geoRegions
                // ],
                label: {
                    normal: {
                        show: true,
                        color: '#ffffff'
                    },
                    emphasis: {
                        show: false,
                        color: '#ffffff'
                    }
                },
                itemStyle: {
                    normal: {
                        borderColor: 'rgba(147, 235, 248, 1)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                            }],
                            globalCoord: false // 缺省为 false
                        },
                        shadowColor: 'rgba(128, 217, 248, 1)',
                        shadowOffsetX: -2,
                        shadowOffsetY: 2,
                        shadowBlur: 10
                    },
                    emphasis: {
                        areaColor: `rgba(${geoColor}, 1)`,
                        // areaColor: 'normal' ,
                        borderWidth: 0
                    }
                },
                top: '0',
                left: 'center',
                bottom: '30%'
            },
            animation: true,
            animationDuration: 1000,
            animationEasing: 'cubicInOut',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'cubicInOut',
            timeline: {
                show: showTimeLine ? true : false,
                data: year,
                axisType: 'category',
                autoPlay: showTimeLine ? true : false,
                controlStyle: {
                    showPlayBtn: false,
                    showNextBtn: false,
                    showPrevBtn: false,
                    normal: {
                        color: '#666',
                        borderColor: '#666'
                    },
                    emphasis: {
                        color: '#aaa',
                        borderColor: '#aaa'
                    },
                    symbolSize: 10,
                    lineStyle: {
                        color: '#555'
                    },
                    checkpointStyle: {
                        borderColor: '#777',
                        borderWidth: 2
                    },
                },
                ...timelineLabel,
                // top: '700',
                ...barOption,
                bottom: 0,
                height: '50',
                playInterval: 7000,
            },
            // bar
            grid: {
                // top: '510',
                ...barOption,
                bottom: "100",
                height: '150'
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    color: '#fff',
                    // rotate: 40
                    fontSize: 10
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        width: 100
                    }
                },
                splitLine: {
                    show: false
                },
                data: cityNameArr,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#fff',
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                }
            },
            // bar end
            series: [
                {
                    type: 'bar',
                    data: dataArr[years[0]],
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: ThemeData.color[0]
                    }

                }
            ]
        }
    }, [dataArr, years]);

    const optionsArr = useMemo(() => {
        const optArr: any[] = [];
        years.map((year, index) => {
            optArr.push({
                visualMap: {
                    min: 0,
                    max: _.max(dataArr[year].values),
                    splitNumber: dataArr[year].values.length,
                    color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                    textStyle: {
                        color: '#fff'
                    },
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 10
                    },
                    data: cityNameArr,
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    show: false
                },
                series: [
                    {
                        type: 'map',
                        data: cityNameArr.map((item, index) => {
                            return {
                                name: item,
                                value: dataArr[year]["values"][index]
                            }
                        }),
                        geoIndex: 0,
                        map: name
                    },
                    {
                        data: dataArr[year]["values"],
                        type: 'bar',
                        itemStyle: {
                            color: ThemeData.color[index]
                        },
                        barWidth: 30,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff'
                        },
                        top: 0
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsRed ? formatPointData(pointsRed) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return isMobile ? 40 : 60;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: isMobile ? 14 : 20,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#F62157'
                            }
                        }
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsBlue ? formatPointData(pointsBlue, false) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return 45;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: 20,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#3f44ef'
                                // color: colors[colorIndex][n]
                            }
                        }
                    }
                ]
            })
        });
        return optArr;
    }, [dataArr, years, pointsBlue, pointsRed]);

    const option: any = useMemo(() => {
        return {
            baseOption: timeline,
            options: optionsArr
        }
    }, [timeline, optionsArr]);

    const onEventsObj = {
        'click': (params: any) => {
            const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
            changeCityName?.(params.name, '')
            setPointMark('area');
            setTableTitle(title);
            setShow(true);
            return
            if (params.componentSubType === 'map') changeCityName?.(params.name, '');

            if (cityNameArr.includes(params.name) && params.componentType === "series" && params.componentSubType === "map")
            {
                const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
                setPointMark('area');
                setTableTitle(title);
                setShow(true);
            }

            if (params.componentType === "series" && params.componentSubType === "scatter")
            {
                if (params.componentIndex === 2)
                {
                    changeCityName?.(params.name, '国家级');
                    setPointMark('red');
                    const title = tableType === 1 ? `${params.name}地区双高院校` : (tableType === 2 ? `${params.name}地区双高专业列表` : `${params.name}精品课列表`);
                    setTableTitle(title);
                }
                if (params.componentIndex === 3)
                {
                    changeCityName?.(params.name, '省级')
                    setPointMark('blue');
                    const title = tableType === 1 ? `${params.name}省级示范性高职院校` : (tableType === 2 ? `${params.name}地区“1+x”专业列表` : `${params.name}在线课程列表`);
                    setTableTitle(title);
                }
                setShow(true);
            }
        },
        'timelinechanged': (index: any) => {

        }
    };

    let dataSource = schoolList?.map((item: any, index: number) => {
        return {
            key: index,
            schoolName: item.schoolName,//学校名称
            school_sx: item.school_sx,//学校属性
            stuCount: item.stuCount,//在校生数
            all_area: item.all_area,//占地面积
            jzmj_zj: item.jzmj_zj,//建筑面积
            inCase: item.inCase,//年度经费投入
        }
    })
    const columns = [
        {
            title: '学校名称',
            dataIndex: 'schoolName',
            width: '25%',
        },
        {
            title: '学校属性',
            dataIndex: 'school_sx',
            width: '15%',
            filters: [
                {
                    text: '民办',
                    value: '民办'
                },
                {
                    text: '省属公办',
                    value: '省属公办'
                },
                {
                    text: '市属公办',
                    value: '市属公办'
                }
            ],
            onFilter: (value: any, record: any) => record.school_sx.indexOf(value) === 0,
        },
        {
            title: '在校生数',
            dataIndex: 'stuCount',
            width: '15%',
            sorter: {
                compare: (a: { stuCount: number; }, b: { stuCount: number; }) => a.stuCount - b.stuCount,
            }
        },
        {
            title: '占地面积',
            dataIndex: 'all_area',
            width: '15%',
            sorter: {
                compare: (a: any, b: any) => a.all_area - b.all_area,
            }
        },
        {
            title: '建筑面积',
            dataIndex: 'jzmj_zj',
            width: '15%',
            sorter: {
                compare: (a: any, b: any) => a.jzmj_zj - b.jzmj_zj
            }
        },
        {
            title: '年度经费',
            dataIndex: 'inCase',
            width: '15%',
            sorter: {
                compare: (a: any, b: any) => a.inCase - b.inCase
            }
        }
    ]
    return (
        <div className={`${style.root} ${className}`}>
            <ReactEcharts
                theme={"theme"}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                ref={echartsRef}
                style={{ width, height, margin: '0 auto' }}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                onClean?.();
                setShow(false);
            }} show={show} width='80vw'>
                <div className={TableStyle.tableWrapper}>
                    <div
                        className={TableStyle.tableTitle}>{tableTitle}</div>
                    <div className={style.tabScroll} >
                        {(tableType === 1 && pointMark === 'area') &&
                            <Table columns={columns} dataSource={dataSource} pagination={false} />
                            // <table className={TableStyle.table}>
                            //     <tbody className={style.tableList}>
                            //         <tr className={style.tableListTitle}>
                            //             <th style={{ width: '20vw' }}>学校名称</th>
                            //             <th style={{ width: '20vw' }}>学校属性</th>
                            //             <th style={{ width: '10vw' }}>在校生数</th>
                            //             <th style={{ width: '10vw' }}>占地面积</th>
                            //             <th style={{ width: '10vw' }}>建筑面积</th>
                            //             <th style={{ width: '10vw' }}>年度经费投入</th>
                            //         </tr>
                            //         {schoolList?.map((item, index) => <tr key={index}>
                            //             <td>{item.schoolName}</td>
                            //             <td>{item.school_sx}</td>
                            //             <td>{item.stuCount}</td>
                            //             <td>{item.all_area}</td>
                            //             <td>{item.jzmj_zj}</td>
                            //             <td>{item.inCase}</td>
                            //         </tr>)}
                            //     </tbody>
                            // </table>
                        }

                        {/* 2 某某市高职院校专业情况统计*/}
                        {(tableType === 2 && pointMark === 'area') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th style={{ width: '10%' }}>专业名称</th>
                                    <th style={{ width: '15%' }}>学校名称</th>
                                    <th style={{ width: '12%' }}>在校生数量</th>
                                    <th style={{ width: '12%' }}>专业教师数量</th>
                                    <th style={{ width: '12%' }}>应届毕业生数</th>
                                    <th style={{ width: '12%' }}>双高专业情况</th>
                                    <th style={{ width: '15%' }}>"1+X"证书数量</th>
                                </tr>
                                {schoolList?.filter(item => item.studentCount !== null && item.studentCount !== 0).map(item => <tr>
                                    <td>{item.subjectName}</td>
                                    <td>{item.schoolName}</td>
                                    <td>{item.studentCount}</td>
                                    <td>{item.teaCount}</td>
                                    <td>{item.yjbysCount || 0}</td>
                                    <td>{item.is_sg || '无'}</td>
                                    <td>{item.is_1x || 0}</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/*红点 重点专业列表*/}
                        {(tableType === 2 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody className={style.tableList}>
                                <tr className={style.tableListTitle}>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>级别</th>
                                </tr>
                                {schoolList?.map(item => <tr>
                                    <td>{item.schoolName}</td>
                                    <td>{item.subjectName}</td>
                                    <td>{item.is_sg}</td>
                                </tr>)}
                            </tbody>
                        </table>}
                        {/*蓝点 特殊专业列表*/}

                        {(tableType === 2 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>证书数量</th>
                                </tr>
                                {schoolList?.map(item => <tr>
                                    <td>{item.schoolName}</td>
                                    <td>{item.subjectName}</td>
                                    <td>{item.is_1x}</td>
                                </tr>)}

                            </tbody>
                        </table>}


                        {/* 某某市国家级高级院校列表*/}
                        {(tableType === 1 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>类别</th>
                                    <th>层次</th>
                                </tr>
                                {schoolListSpecial?.map((item: any) => <tr>
                                    <td>{item.name}</td>
                                    <td>{item.xzCode}</td>
                                    <td>省级</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/* 蓝 某某市省级示范性高级院校*/}
                        {(tableType === 1 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>类别</th>
                                </tr>
                                {schoolListSpecial?.map((item: any) => <tr>
                                    <td>{item.name}</td>
                                    <td>{item.xzCode}</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/* 3 某某市课程情况统计*/}
                        {(tableType === 3 && pointMark === 'area') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>课程数量</th>
                                    <th>精品课</th>
                                    <th>在线课</th>
                                    <th>资源共享课</th>
                                    <th>实训课</th>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>3346</td>
                                    <td>19</td>
                                    <td>740</td>
                                    <td>560</td>
                                    <td>1002</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>5486</td>
                                    <td>16</td>
                                    <td>650</td>
                                    <td>430</td>
                                    <td>1209</td>
                                </tr>
                                <tr>
                                    <td>辽宁轨道交通技术学院</td>
                                    <td>4892</td>
                                    <td>19</td>
                                    <td>734</td>
                                    <td>467</td>
                                    <td>980</td>
                                </tr>
                                <tr>
                                    <td>辽宁铁道职业技术学院</td>
                                    <td>5643</td>
                                    <td>15</td>
                                    <td>790</td>
                                    <td>667</td>
                                    <td>1032</td>
                                </tr>
                                <tr>
                                    <td>沈阳北软件职业信息技术学院</td>
                                    <td>7654</td>
                                    <td>19</td>
                                    <td>543</td>
                                    <td>675</td>
                                    <td>1902</td>
                                </tr>

                            </tbody>
                        </table>}
                        {/*红点 某某市精品课列表*/}
                        {(tableType === 3 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>课程</th>
                                    <th>级别</th>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>物联网应用技术</td>
                                    <td>电子商务概论</td>
                                    <td>国家级</td>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>高速铁路客运乘务</td>
                                    <td>中级财务会计</td>
                                    <td>省级</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>环境艺术设计</td>
                                    <td>行政管理学</td>
                                    <td>省级</td>
                                </tr>
                            </tbody>
                        </table>}
                        {/*蓝点 某某市在线课程列表*/}
                        {(tableType === 3 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>课程</th>
                                    <th>签到人数</th>

                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>物联网应用技术</td>
                                    <td>电子商务概论</td>
                                    <td>2356</td>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>高速铁路客运乘务</td>
                                    <td>中级财务会计</td>
                                    <td>5674</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>环境艺术设计</td>
                                    <td>行政管理学</td>
                                    <td>7645</td>
                                </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>

            </Popup>
        </div>
    )
}

Province.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
