import React, { useEffect, useState } from "react";
import Province from "../Province/index";
import {
  constMoney, getDoubleLevelList,
  getNationalLevelCount,
  getProvincialLevelCount,
  getSchoolInfoList, IDataTypes,
  IPoint,
  INewDataTypes, ISchoolInfo, newStudentCount, schoolCount, teacherCount
} from "../../../../../service/chart";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import cityCoordData from "../../../../../assets/cityCoord";
import { getNormalData } from "../../../../../service/basic";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
  paddingTop: number;
  divHeight: number
}

export default function FirstScreen ({ showTimeLine, width, height, paddingTop, divHeight }: IProps) {

  const [cityName, setCityName] = useState(""); // 点击地图返回城市名

  // 第一屏
  const [schoolData] = useRequest(() => schoolCount()); // 学校数量
  const [newStuCountData] = useRequest(() => newStudentCount()); // 招生人数;
  const [teacherData] = useRequest(() => teacherCount()); // 教师数量
  const [constMoneyData] = useRequest(() => constMoney()); // 投入情况
  const [firstData, setFirstData] = useState < INewDataTypes[] > ([]);

  const [schoolList, setSchoolList]: any = useState([]);

  // const [requestSchoolList] = useRequest(() => getSchoolInfoList(cityName), {}, [cityName]);
  const [schoolListSpecialFn, { loading, data: schoolListSpecial }] = useRequestFn(getDoubleLevelList);
  const [blueCountData] = useRequest(() => getProvincialLevelCount());

  // const [redCountData] =useRequest(() => getNationalLevelCount());
  const [onRed, redData] = useRequestFn(() => getNormalData < IPoint[] > ('f27f801a-cd6c-4544-9ca9-1565f3e14f30')) //获取辽宁地图数据信息
  const [redCountData, setRedCountData] = useState < any > ([])

  const [onRedDel, redDataDel] = useRequestFn(() => getNormalData < IPoint[] > ('7eeb84be-64f7-468a-bcbb-12ee75e5aeba', { name: cityName })) //获取辽宁地图数据信息

  async function red () {
    let res = await onRed()
    setRedCountData(res)
  }

  useEffect(() => {
    red()
  }, [])





  // 表格数据
  const [requestSchoolList] = useState([
    // {
    //   schoolName: "机电工程系校外顶岗实习实训基地",
    //   school_sx: "辽宁装备制造职业技术学院",
    //   jzmj_zj: "详细"
    // }
    // {
    //   schoolName: "船舶工程系渤海船舶重工有限责任公司校外教学性实训基地 ",
    //   school_sx: "辽宁省交通高等专科学校",
    //   jzmj_zj: "详细"
    // }, {
    //   schoolName: "动力工程系校外实习实训基地",
    //   school_sx: "辽宁省交通高等专科学校",
    //   jzmj_zj: "详细"
    // }, {
    //   schoolName: "招商局重工（江苏）有限公司实习实训基地",
    //   school_sx: " 辽宁装备制造职业技术学院",
    //   jzmj_zj: "详细"
    // }, {
    //   schoolName: "中船澄西船舶修造有限公司实习实训基地",
    //   school_sx: " 沈阳职业技术学院",
    //   jzmj_zj: "详细"
    // },
  ])


  //红色圆点
  // const [redCountData] = useState([
  //   {
  //         areaCode: "沈阳市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 113,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "大连市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 72,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "抚顺市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 5,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "本溪市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 3,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "锦州市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 24,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "丹东市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 2,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "营口市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 7,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "阜新市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 2,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "辽阳市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 11,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "盘锦市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 3,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "铁岭市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 9,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "朝阳市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 1,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     }, {
  //         areaCode: "葫芦岛市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 2,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     },{
  //         areaCode: "鞍山市",
  //         areaColor: "#f8f8f8",
  //         circleColor: "#f00",
  //         coord: [],
  //         doubleTall: null,
  //         name: null,
  //         schCount: 6,
  //         value: null,
  //         xzCode: null,
  //         year: null,
  //     },
  // ])

  useEffect(() => {
    if (schoolData && newStuCountData && teacherData && constMoneyData)
    {
      const data1 = formatData(schoolData, '学校数量');
      const data2 = formatData(newStuCountData, '招生人数');
      const data3 = formatData(teacherData, '教师人数');
      const data4 = formatData(constMoneyData, '投入情况');
      setFirstData([...data1, ...data2, ...data3, ...data4]);
    }
  }, [schoolData, newStuCountData, teacherData, constMoneyData]);

  function formatData (data: IDataTypes[], name: string) {
    return data.map(item => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseFloat(item.value),
        circleColor: item.circleColor ? item.circleColor : '#f00',
        areaColor: item.areaColor ? item.areaColor : '#f8f8f8',
        year: name
      }
    });
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  //点击获取高等职业院校详情
  const [onDel, del] = useRequestFn(getNormalData)
  async function getSchoolList (val: string, level: string) {
    setCityName(val);
    let res = await onDel('7eeb84be-64f7-468a-bcbb-12ee75e5aeba', { name: val })
    setSchoolList(res)
    if (!level)
    {
      setCityName(val);
    }
    if (level)
    {
      schoolListSpecialFn(val, level);
    }
  }

  function onClean () { // 清除弹出层表单数据
    setSchoolList([]);
  }

  return <div style={{ paddingTop: paddingTop + 'px', height: divHeight }}>
    {firstData &&
      <Province tableType={1} name='辽宁' width={width} height={height} data={firstData}
        year={["学校数量", "招生人数", "教师人数", "投入情况"]}
        geoColor='27, 94, 193'
        schoolList={schoolList}

        changeCityName={(val, levelName) => {
          getSchoolList(val, levelName)
        }}
        pointsBlue={blueCountData?.map(item => {
          return {
            areaCode: item.areaCode,
            schCount: item.schCount
          }
        })}
        pointsRed={redCountData?.map((item: any) => {
          return {
            areaCode: item.areaCode ? item.areaCode : item.name,
            schCount: item.schCount ? item.schCount : item.value
          }
        })}
        schoolListSpecial={schoolListSpecial || []}
        showTimeLine={showTimeLine}
        onClean={onClean}
      />}
  </div>
}

FirstScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800,
  paddingTop: 0,
  divHeight: 0
}
