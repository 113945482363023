import React, { useEffect, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import {
  get1XMS,
  get1xNum,
  getCSXS,
  getSGMX,
  getsgNum,
  ICityCountInfo,
  INewDataTypes,
  ISchoolInfo,
} from "../../../../../service/chart";
import Province from "./map";
import cityCoordData from "../../../../../assets/cityCoord";
import { Select } from "antd";
import {
  countryTable,
  findCityListBySubjectName,
  findFilterSubjectName,
  lnMap, lnTable,
} from "../../../../../service/major";
import { getNormalData } from "../../../../../service/basic";
import useRequestFn from "../../../../../lib/useRequestFn";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
}

export default function SearchScreen ({ showTimeLine, width, height }: IProps) {
  const [subject, setSubject] = useState("");


  // const [lnmapList] = useRequest(lnMap);//查询市就业人数
  const [lnmapList] = useRequest(() => getNormalData < any > ('444cfa30-445f-48c2-b918-04c05d1cd666'))
  const [subjectData] = useRequest(
    () => findCityListBySubjectName(subject),
    {},
    [subject]
  );

  const [data, setData] = useState < INewDataTypes[] > ([]);
  const [typesData, setTypeData] = useState < string[] > (["专业"]);
  const [schoolList, setSchoolList] = useState < any[] > ([]);
  let tempData: INewDataTypes[] = [];

  const [blueCountData] = useRequest(() => get1xNum(subject), {}, [subject]);
  const [redCountData] = useRequest(() => getsgNum(subject), {}, [subject]);


  useEffect(() => {
    if (subjectData)
    {
      setData(formatData(subjectData));
    }
  }, [subjectData]);

  function formatData (data: ICityCountInfo[]) {
    const res = data.map((item) => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseInt(item.value),
        circleColor: "#f00",
        areaColor: "#f8f8f8",
        year: "专业",
      };
    });

    return buchongData(res);
  }

  function buchongData (data: INewDataTypes[]) {
    // 后台不存在的城市要补充上
    const cityArr = data.map((item) => item.name);
    const res = cityCoordData.filter(
      (item) => !cityArr.includes(item.name)
    );
    return [
      ...data,
      ...res.map((item) => {
        return {
          name: item.name,
          coord: cityCoord(item.name),
          value: 0,
          circleColor: "#f00",
          areaColor: "#f8f8f8",
          year: "专业",
        };
      }),
    ];
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter((item) => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  const [onLnTable, lnTable] = useRequestFn(getNormalData)
  async function getSchoolList (level: string, val: string) {
    let res: any = await onLnTable('66d3693e-d613-4f96-a5ef-581f5abf73f2', { name: level })
    setSchoolList(res)
    // lnTable(level).then((data) => {
    //   setSchoolList(data);
    // });

  }

  function onchange (val: string) {
    setSubject(val);
  }

  function onClean () {
    // 清除弹出层表单数据
    setSchoolList([]);
  }



  return (
    <div>
      {data && lnmapList && typesData && (
        <>
          <Province
            name="辽宁"
            tableType={2}
            width={width}
            height={height}
            data={data}
            year={typesData}
            geoColor="0, 167, 237"
            schoolList={schoolList || []}
            showTimeLine={false}
            changeCityName={(levelName, val) =>
              getSchoolList(levelName, val)
            }
            pointsRed={lnmapList?.map((item: any) => {
              return {
                areaCode: item.name,
                schCount: item.value,
              };
            })}
            // pointsRed={redCountData?.map((item) => {
            //     return {
            //         areaCode: item.name,
            //         schCount: item.value,
            //     };
            // })}
            onClean={onClean}
          // schoolListSpecial={schoolListSpecial || []}
          />
        </>
      )}
    </div>
  );
}

SearchScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800,
};
