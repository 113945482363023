import styles from "./index.module.scss";
import classNames from "classnames";
import CardInfo from "./Components/CardInfo";
import {useState} from "react";
import {SpecialType} from "../../../../../../../../service/basic";

interface Props{
    onClick: (obj: SpecialType) => void;
    data: SpecialType;
}

export default function InfoCard({data, onClick}: Props) {

    const [show, setShow] = useState(false);

    return (
        <div className={classNames(styles.root)} onMouseEnter={() => setShow(true)}>
            <div className={styles.text}>
                {data.subject_name}
            </div>

            {show && <CardInfo data={data}  onClick={onClick} />}
        </div>
    )
}
