import styles from "./index.module.scss"
import {Tabs} from "antd";
import {useState} from "react";
import Block1 from "./Components/Block1";
import Block4 from "./Components/Special/Block4";
import ServiceBlock from "./Components/ServiceConstructure/Block";

export default function Chart11() {
    const [tabActiveLiaoNing,setTabActiveLiaoNing] = useState(1);
    const [activeKey, setActiveKey] = useState("1");
    const page_3 = [
        {
            id:1, name:'改造升级“老字号”', code:'A'
        },
        {
            id:2, name:'深度开发“原字号”', code:'B'
        },
        {
            id:3, name:'培育壮大“新字号”', code:'C'
        },
    ]
    const safe_5 = [
        {
            id:1, name:'维护国防安全', code:'A'
        },
        {
            id:2, name:'确保粮食安全', code:'B'
        },
        {
            id:3, name:'巩固生态安全', code:'C'
        },
        {
            id:4, name:'保障能源安全', code:'D'
        },
        {
            id:5, name:'夯实产业安全', code:'E'
        }
    ]
    const other = [
        {
            id:1, name:'创新软件和信息技术服务产业', code:'A'
        },
        {
            id:2, name:'做大做强集成电路产业', code:'B'
        },
        {
            id:3, name:'加快发展人工智能产业', code:'C'
        },
        {
            id:4, name:'布局新一代网络安全产业', code:'D'
        },
        {
            id:5, name:'发展新一代移动通信产业', code:'E'
        },
        {
            id:6, name:'推进区块链技术发展应用', code:'F'
        }
    ]
    const otherActive2 = [
        {
            id:1, name:'推动智慧农业建设', code:'A'
        },
        {
            id:2, name:'推进智能制造发展', code:'B'
        },
        {
            id:3, name:'提升智能交通建设', code:'C'
        },
        {
            id:4, name:'推进智慧物流发展', code:'D'
        },
        {
            id:5, name:'加快数字金融建设', code:'E'
        },
        {
            id:6, name:'加强数字商贸建设', code:'F'
        },
        {
            id:6, name:'加强数字社会建设', code:'G'
        },
        {
            id:6, name:'加快数字政府建设', code:'H'
        },
    ]
    return (
        <div className={styles.root}>
            <Tabs className={styles[`tab${activeKey}`]} activeKey={activeKey} onChange={setActiveKey}>

                <Tabs.TabPane tab="五大安全" key="1">
                    <Block1 titleName={safe_5} file="safe_5" id="block3"/>
                    {/* <Block3 /> */}
                </Tabs.TabPane>

                <Tabs.TabPane tab="服务结构调整“三篇大文章”" key="2">
                    {/*<Block1 titleName={page_3} file="page_3" id="block1"/>*/}
                    <ServiceBlock titleName={page_3} file="page_3" id="block1"/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="数字辽宁、智造强省" key="3">
                    {/* <Block2 /> */}
                    <div className={styles.tabs}>
                        <div onClick={()=>{setTabActiveLiaoNing(1)}} className={tabActiveLiaoNing===1?styles.tabsactive:''}>数字化产业</div>
                        <div onClick={()=>{setTabActiveLiaoNing(2)}} className={tabActiveLiaoNing===2?styles.tabsactive:''}>产业数字化</div>
                    </div>
                    {
                        tabActiveLiaoNing===1?
                            <Block1 titleName={other} file="wisdom_ln" id="block2"/>:
                            <Block1 titleName={otherActive2} file="digitalize_industry" id="blockactive2"/>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane tab="特色理工" key="4">
                    {/* <Block2 /> */}
                    <Block4 titleName={other} file="wisdom_ln" id="block2"/>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}
