import { Col, Row } from "antd";
import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import {IFindCourseSyses} from "../../../../../service/training";
import {Link} from "react-router-dom";

interface courseProps {
    data?:IFindCourseSyses[];
}

export default function Appendix({data}:courseProps) {

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>课程体系</h1>
                </div>

                <div className={style.table}>
                    <table>
                        <tbody>
                            <tr>
                                <th>课程名称</th>
                                <th>是否核心课</th>
                                <th>课程类型</th>
                                <th>课程性质</th>
                                <th>学年</th>
                                <th>学期</th>
                                <th>总学时</th>
                                <th>学分数</th>
                                <th>周学时</th>
                            </tr>
                            {data?.map((item,index) => {
                                return <tr key={index}>
                                    <td><Link to={`/training/CourseDetail?courseId=${item.courseId}`}>{item.courseName}</Link></td>
                                    <td>{item.isCoreName}</td>
                                    <td>{item.courseTypeName}</td>
                                    <td>{item.courseQualityName}</td>
                                    <td>{item.newYear}</td>
                                    <td>{item.term}</td>
                                    <td>{item.allTime}</td>
                                    <td>{item.xfs}</td>
                                    <td>{item.weekTime}</td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
