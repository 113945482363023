import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Select, Row, Col, Image, Table, Space } from 'antd';
import { useHistory } from "react-router-dom";
import useQuery from "../../../lib/useQuery";
import useRequest from "../../../lib/useRequest";
import { findSpecialtyListByName } from "../../../service/major";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import useRequestFn from "../../../lib/useRequestFn";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";
export default function ProfessionSearch () {

    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "专业信息", url: "/major/list" }, { name: "搜索" }]);

    const { name, school } = useQuery < { name: string, school: string } > ()

    const history = useHistory();
    const [form] = Form.useForm();

    // const [list, { loading }] = useRequest(() => findSpecialtyListByName(name, school), {}, [name, school])
    const [onList, lists] = useRequestFn(getNormalData)
    const [list, setList] = useState < any > (null)

    async function onFn (name1: any, school1: any) {
        let res = await onList < any > ('faf4ab72-a38b-4e3c-bce3-f4364c5f42c4', { schoolName: school1, subjectName: name1 })
        if (res)
        {
            setList(res)
        }
    }

    useEffect(() => {
        onFn(name, school)
    }, [])

    useEffect(() => {
        form.setFieldsValue({ name, school })
    }, [name, school])

    function onFinish ({ name, school }: { name?: any, school?: any }) {
        onFn(name, school)
    }

    const { isMobile } = useDevice();


    const columns: any = [
        {
            title: '专业代码',
            dataIndex: 'subjectCode',
            width: 90,
            responsive: ['md'], // 手机分辨率不显示该列

        },
        {
            title: '专业名称',
            dataIndex: 'subjectName',
            fixed: isMobile ? "left" : false,
        },
        {
            title: '学校名称',
            dataIndex: 'schoolName',
        },
        {
            title: '专业方向',
            dataIndex: 'subjectDirection',
        },
        {
            title: '入学要求',
            dataIndex: 'inRequirements',
        },
        {
            title: '学习年限',
            dataIndex: 'restYear',
            sorter: {
                compare: (a: any, b: any) => parseInt(a.restYear) - parseInt(b.restYear),
            },
            render: (year: string) => `${year}年`
        },
        {
            title: '查看详情',
            render: (text: any, { id }: { id: string; }) => <Space size="middle">
                <Button className={style.tableButton} onClick={() => history.push(`/training/special?id=${id}`)}>
                    详细
                </Button>
            </Space>,
        },
    ];

    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.search}>
                    <Form onFinish={onFinish} form={form} labelCol={{ span: 4 }}>
                        <Row gutter={24}>
                            <Col span={isMobile ? 24 : 6}>
                                <div className={style.title}><span>搜索结果</span></div>
                            </Col>
                            <Col span={isMobile ? 24 : 6}>
                                <Col span={22}>
                                    <Form.Item initialValue={name} name="name" label="专业名称" labelCol={{ span: 8 }}>
                                        <Input placeholder="请输入专业名称" />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={isMobile ? 24 : 6}>
                                <Col span={22}>
                                    <Form.Item name="school" initialValue={school} label="学校名称" labelCol={{ span: 8 }}>
                                        <Input placeholder="请输入学校名称" />
                                    </Form.Item>
                                </Col>
                            </Col>

                            <Col span={isMobile ? 24 : 6}>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginRight: "20px" }}>
                                    查询
                                </Button>
                                <Button icon={<RetweetOutlined />} onClick={() => form.resetFields()}>
                                    重置
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {/* <Form onFinish={onFinish} form={form} labelCol={{ span: 4 }}>
                        <Row gutter={24}>
                            <Col span={isMobile ? 24 : 6}>
                                <Form.Item initialValue={name} name="name" label="专业名称" labelCol={{ span: 8 }}>
                                    <Col span={22}>
                                        <Input placeholder="请输入关键字" />
                                    </Col>
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? 24 : 6}>
                                <Form.Item name="school" initialValue={school} label="学校名称" labelCol={{ span: 8 }}>
                                    <Col span={22}>
                                        <Input placeholder="请输入学校名称" />
                                    </Col>
                                </Form.Item>
                            </Col>

                            <Col span={isMobile ? 24 : 6}>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginRight: "20px" }}>
                                    查询
                                </Button>
                                <Button icon={<RetweetOutlined />} onClick={() => form.resetFields()}>
                                    重置
                                </Button>
                            </Col>
                        </Row>
                    </Form> */}
                </div>
                <div className={style.list}>
                    <Table
                        rowKey="id"
                        // loading={loading}
                        className={style.tableContent}
                        columns={columns}
                        scroll={isMobile ? { x: 800 } : undefined}
                        dataSource={list?.data || []}
                    />
                </div>

            </div>

        </div>
    );
}
