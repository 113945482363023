import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import dayjs from "dayjs";
import useDevice from "../../../../lib/useDevice";
export default function () {
  const { isMobile } = useDevice()
  const titleTxt = '辽宁省高等职业院校实习数据管理平台';

  return <div className={style.root}>
    <div className={style.line}>
      <Time />
      <div className={style.wrapper}>
        <div className={style.title} data-text={titleTxt}>
          {isMobile ? '' : titleTxt}
        </div>
      </div>
    </div>
  </div>
}

export function Time () {
  const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const [week, setWeek] = useState('');
  const [monthDay, setMonthDay] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    setInterval(() => {
      setWeek(days[dayjs().day()]);
      setMonthDay(dayjs().format("MM月DD日"));
      setTime(dayjs().format("HH:mm:ss"))
    }, 1000)
  }, [])

  return <div className={style.time}>
    <span>2020 ~ 2021学年 </span>
    <span>{monthDay} </span>
    <span style={{ padding: '0 10px' }}>{week}</span>
    <span>{time}</span>
  </div>
}
