//@ts-nocheck
import ReactEcharts from 'echarts-for-react';
import { EChartOption, graphic } from "echarts";
import { useHistory } from 'react-router-dom';
import { useMemo, useState } from "react";

export default function Index (props: any) {
  let style = { props }

  //  x数据
  let [data, setData] = useState([]);

  //  y数据
  let [seriesData, setSeriesData] = useState([])

  // 类型
  let [type, setType] = useState([1])

  useMemo(() => {
    setData(props.Provinces)
  }, [props.Provinces])

  useMemo(() => {
    setSeriesData(props.lineData)
  }, [props.lineData])


  var color = [
    "#ee167b",
    "#dab606",
    "#e55b26",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
    "#ee167b",
    "#dab606",
    "#06da90",
    "#4467f4",
  ];

  const Options: EChartOption<EChartOption.Series> = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: function (data) {
        return data[0].name + ' : ' + parseInt(data[0].value)
      }
    },
    grid: {
      top: 5,
      height: data ? data.length * 50 : 500,
      containLabel: true
    },
    toolbox: {
      show: true,
    },
    xAxis: {
      type: 'value',
      max: 20000,
      gridIndex: 0,//x 轴所在的 grid 的索引
      show: false
    },
    yAxis: {
      type: 'category',
      data: data,
      inverse: true,
      splitLine: 'none',
      axisTick: 'none',
      axisLine: 'none',
      axisLabel: {
        textStyle: {
          color: '#FFFFFF',
          fontSize: '12',
        }
      }
    },
    series: [
      {
        type: 'bar',
        cursor: 'default',
        label: {
          show: true,
          position: 'right',
          formatter: (params: any) => {
            if (type === 1)
            {
              return `${parseInt(params.value)}`
            } else
            {
              return parseInt(params.value)
            }

          }
        },
        barWidth: 15,
        data: seriesData,
        itemStyle: {
          color: function (params: any) {
            return color[params.dataIndex]
          }
        }
      }
    ]
  };
  return (
    <ReactEcharts onEvents={{
      "click": (e) => {
        // 如果当前不是辽宁
        // 并且还是第一个数据的时候
        if (type == 1 && e.name !== '辽宁')
        {
          // 切割字符串
          let flag = e.name.split(".")[1];
          // 必须是省外
          if (flag.trim() == "省外")
          {
            // 更改数据格式
            setType(1)
            // 让外界传递别的数据
            props.onChange('1');
          }
        }
      }
    }} style={{ height: `${data && data.length ? data.length * 50 : 500}px` }} theme={"theme"} option={Options} />
  )
}
