import styles from "./index.module.scss"
import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import {getNormalData, SpecialType} from "../../../../../../../../service/basic";
import useRequest from "../../../../../../../../lib/useRequest";
import Table from "../Table";
import TitleCount from "../../TitleCount";
interface Block1Props {
    id: string;
    file: string,
    titleName: any;
}

const DataSource: {[key: string]: SpecialType[]} = {
    'A': [
        {
            subject_code: 'A-1',
            subject_name: '汽车产业链',
            zybds: '0'
        },
        {
            subject_code: 'A-2',
            subject_name: '数控机床产业链',
            zybds: '0'
        },
        {
            subject_code: 'A-3',
            subject_name: '输变电装备产业链',
            zybds: '0'
        },
        {
            subject_code: 'A-4',
            subject_name: '燃气轮机产业链',
            zybds: '0'
        },
        {
            subject_code: 'A-5',
            subject_name: '压缩机产业链',
            zybds: '0'
        },
        {
            subject_code: 'A-6',
            subject_name: '船舶与海洋工程装备产业链',
            zybds: '0'
        },
    ],
    'B': [
        {
            subject_code: 'B-1',
            subject_name: '烯烃产业链',
            zybds: '0'
        },
        {
            subject_code: 'B-2',
            subject_name: '芳烃产业链',
            zybds: '0'
        },
        {
            subject_code: 'B-3',
            subject_name: '精细化工产业链',
            zybds: '0'
        },
        {
            subject_code: 'B-4',
            subject_name: '高品质钢铁材料产业链',
            zybds: '0'
        },
        {
            subject_code: 'B-5',
            subject_name: '先进有色金属材料产业链',
            zybds: '0'
        },
        {
            subject_code: 'B-6',
            subject_name: '菱镁产业链',
            zybds: '0'
        },
    ],
    'C': [
        {
            subject_code: 'C-1',
            subject_name: '机器人产业链',
            zybds: '0'
        },
        {
            subject_code: 'C-2',
            subject_name: '航空装备产业链',
            zybds: '0'
        },
        {
            subject_code: 'C-3',
            subject_name: '集成电路产业链',
            zybds: '0'
        },
        {
            subject_code: 'C-4',
            subject_name: '生物医药产业链',
            zybds: '0'
        },
        {
            subject_code: 'C-5',
            subject_name: '先进医疗装备产业链',
            zybds: '0'
        },
        {
            subject_code: 'C-6',
            subject_name: '氢能产业链',
            zybds: '0'
        },
    ]
};

export default function Block({file, titleName, id}: Block1Props) {
    const [code,setCode] = useState('A');
    // const [dataSource, setDataSource] = useState<SpecialType[]>(DataSource['A']);
    const dataSource: SpecialType[] = useMemo(() => {
        return DataSource[code];
    }, [code]);
    // const [keyWord, setKeyword] = useState('');
    // const [data] = useRequest(() => getNormalData < SpecialType[] > ('fe90017d-d1b4-11ec-ba43-005056958024',{"subject_name":"工程"}));



    return (
        <div className={classNames(styles.root, styles[id])}>
            <div className={styles.left}>
                {
                    titleName&&titleName.map((el: any) => {
                        return(
                            <h1 key={el.id} className={classNames(styles.title, {[styles.active]: code === el.code})}
                                onClick={() => setCode(el.code)}>{el.name}</h1>

                        )
                    })
                }
            </div>
            <div className={styles.right} >
                <TitleCount code={code} file={file}/>
                <Table list={dataSource || []} />
            </div>
        </div>
    )
}
