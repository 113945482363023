import React from "react";
import classNames from "classnames";
import style from "./index.module.scss"
import useRequest from "../../../../../lib/useRequest";
import { findCountValue, getNormalData } from "../../../../../service/basic";
import { toInt } from "../../../../../lib/utils";

export default function Statics () {

  // const [school] = useRequest(() => findCountValue("count_college_num_2020"));
  // const [teacher] = useRequest(() => findCountValue("count_teacher_num_2020"));
  // const [student] = useRequest(() => findCountValue("count_student_num_2020"));
  // const [cost] = useRequest(() => findCountValue("count_cost_num_2020"));
  const [collegeNum] = useRequest(() => getNormalData < string > ('2ac720f7-9848-4620-ab2b-632903f1a4b5')); // 院校数量
  const [data1] = useRequest(() => getNormalData < string > ('79001196-ab23-4952-87ad-34026168df62')); // 开设专业数量
  const [studentNum] = useRequest(() => getNormalData < any > ('2ba10854-6a85-4001-8206-7937136ab158')); //在校生数
  const [teacherNum] = useRequest(() => getNormalData < string > ('7f40ec3e-6cfc-4fe9-8bff-0e69e9f43428')); // 教师数量
  // const [cost] = useRequest(() => getNormalData < string > ('768b0168-33bc-4f75-9aef-4c461c4255da')); // 经费总投入
  const [nums] = useRequest(() => getNormalData < any > ('db8df8e5-e4a8-451e-a0a4-cd42b63aedb5'))


  return (
    <div className={style.root}>
      <div className={classNames(style.item, style.icon1)}>
        <div className={style.title}>高职院校数</div>
        <span className={style.num}>{collegeNum || 0}</span>
        <span className={style.unit}>个</span>
      </div>

      <div className={classNames(style.item, style.icon2)}>
        <div className={style.title}>专业布点数</div>
        <span className={style.num}>{  parseInt(nums ? nums[1].value : '0')}</span>
        <span className={style.unit}>个</span>
      </div>

      <div className={classNames(style.item, style.icon3)}>
        <div className={style.title}>在校生数</div>
        <span className={style.num}>{parseInt(studentNum) || 0}</span>
        <span className={style.unit}>人</span>
      </div>

      <div className={classNames(style.item, style.icon4)}>
        <div className={style.title}>教师数量</div>
        <span className={style.num}>{teacherNum || 0}</span>
        <span className={style.unit}>人</span>
      </div>
    </div>
  );
}
