//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import echarts, { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import { getShuangGao } from "../../../../service/chart";
import { getNormalData } from "../../../../service/basic";
import { any } from "underscore";
import Popup from "./../../../../components/EChartsUuid/components/Popup";
import style from './style.module.scss';
import useRequestFn from "../../../../lib/useRequestFn";
import { Table } from 'antd'

let colorList2 = [
    "#f77070",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",
    "#ffd200",
    "#ffad56",
    "#34ac74",
    "#34ac74",
    "#d7504b",
    "#c6e579",
    "#f4e001",
    "#f0805a",
    "#26c0c0"
];

const Options: EChartOption<EChartOption.Series> = {
    title: {
        text: '高职院校类型分布图',
        left: 'left',
        top: 0,
        textStyle: {
            color: '#347afc'
        }
    },
    series: [
        {
            cursor: 'default',
            type: "pie",
            data: [],
            radius: '65%',
            label: {
                fontSize: 12
            }
        },
    ],
    color: colorList2,
    tooltip: {
        formatter: `{b}大类布点数 {c}个`
    }
};


interface IProps {
    customOption?: (
        opt: EChartOption<EChartOption.Series>
    ) => EChartOption<EChartOption.Series>;
    style?: any;
}

export default function Index ({ customOption }: IProps) {
    const [data] = useRequest(() => getNormalData < { sg: string[], subject: string[], '1x': string[], name: string[] } > ("4e48a1fa-c762-4b00-b937-1cea3bcf765c")); // 专业布点分布图

    const [tableTitle, setTableTitle] = useState('');
    const [show, setShow] = useState(false);
    const [schoolList, setSchoolList] = useState < any > ([]);
    const [onList, list] = useRequestFn(getNormalData)
    async function onFn (name: string) {
        let res = await onList < any > ('55023e76-a089-4619-86c0-e93882dde8c8', { areaCode: '', type: '', xz_code: name == '综合院校' ? '综合大学' : name })
        if (res)
        {
            setSchoolList(res)
            setTableTitle(`${name}高职院校${res.length}所`)
        }
    }

    const onEventsObj = {
        'click': (params: any) => {
            onFn(params.name)
            setShow(true)
        }
    }
    const opt = useMemo(() => {

        // 综合大学整体替换成综合院校
        let list = data ? data.option.series[0].data : []
        let arr = list.map((item) => {
            return {
                name: item.name == '综合大学' ? '综合院校' : item.name,
                value: item.value
            }
        })

        return {

            title: {
                text: "高职院校类型分布图",
                left: "left",
                top: 0,
                textStyle: {
                    color: "#347afc",
                }
            },
            color: colorList2,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b} : {c} ({d}%)"
            },
            grid: { //网格大小
                x: 10,
                y: 10,
                x2: 1,
                y2: 10,
                borderWidth: 1,
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: ['30%', '60%'],
                    center: ['50%', '60%'],
                    data: arr,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }, [data]);

    let columns = [
        {
            title: '学校名称',
            dataIndex: 'schoolName',
            width: '25%',

        },
        {
            title: '学校属性',
            dataIndex: 'school_sx',
            width: '10%',
            filters: [
                {
                    text: '民办',
                    value: '民办'
                },
                {
                    text: '省属公办',
                    value: '省属公办'
                },
                {
                    text: '市属公办',
                    value: '市属公办'
                }
            ],
            onFilter: (value: any, record: any) => record.school_sx.indexOf(value) === 0,

        },
        {
            title: '在校生数',
            dataIndex: 'stuCount',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.stuCount - b.stuCount
            }

        },
        {
            title: '占地面积',
            dataIndex: 'all_area',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.all_area - b.all_area
            }

        },
        {
            title: '建筑面积',
            dataIndex: 'jzmj_zj',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.jzmj_zj - b.jzmj_zj
            }

        },
        {
            title: '年度经费投入',
            dataIndex: 'inCase',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.inCase - b.inCase
            }

        },
    ]
    let dataSource = schoolList?.map((item: any) => {
        return {
            schoolName: item.schoolName,
            school_sx: item.school_sx,
            stuCount: item.stuCount,
            all_area: item.all_area,
            jzmj_zj: item.jzmj_zj,
            inCase: item.inCase
        }
    })
    return <div>
        <ReactEcharts
            option={opt}
            onEvents={onEventsObj}
        />
        <Popup onClick={() => {
            setShow(false);
        }} show={show} width='80vw'>
            <div className={style.tableWrapper}>
                <div
                    className={style.tableTitle}>{tableTitle}</div>
                <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                </div>
            </div>

        </Popup>
    </div>
}
