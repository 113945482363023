import ReactEcharts from "echarts-for-react";
import { lowerFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import { getNormalData } from "../../../../service/basic";
let colorList = [
    "#ffd200",
    "#ffad56",
    "#f77070",
    "#e740a6",
    "#7d6cff",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",

];
export default function Index (props: any) {
    //扩招生源类别示意图
    const [data] = useRequest(() => getNormalData < any > ('082a79ca960511ecba43005056958024'))
    const legendData: any = data?.option.series.map((item: any) => {
        return item.name
    })
    const seriesData: any = data?.option.series.map((item: any) => {
        return {
            name: item.name,
            type: item.type,
            stack: item.stack,
            data: item.data
        }


    })

    //新折线图
    const options: any = useMemo(() => {
        return {
            color: colorList,

            legend: {
                data: legendData
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data?.option.xAxis.data

            },
            yAxis: {
                type: 'value'
            },
            series: seriesData,

        }
    }, [data])

    return (
        <div >
            <ReactEcharts
                style={{ height: '250px', padding: '0 20px' }}
                option={options}
            />
        </div>
    );
}
