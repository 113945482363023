import {Button, Modal, Table} from "antd";
import React, {useState} from "react";
import {ColumnsType} from "antd/lib/table/interface";

interface PopCol1Props {
    num: number;
}

export default function PopCol5({num}: PopCol1Props) {

    const [visible, setVisible] = useState(false);

    const columns: ColumnsType<any> = [
        {
            title: "合作单位",
            dataIndex: "col1",
        },
        {
            title: "基地名称",
            dataIndex: "col2",
        },
        {
            title: "创建时间",
            dataIndex: "col3",
        },
        {
            title: "基地类别",
            dataIndex: "col4",
        },
        {
            title: "培养学生人数",
            dataIndex: "col5",
            align:"center",
        },
        {
            title: "年接待实践学生人数",
            dataIndex: "col6",
            align:"center",
        },
        {
            title: "详细",
            dataIndex: "col7",
            width: 100,
            align:"center",
            render:() => <a href="http://www.lnve.net/lnvesjpt/subsiteIndexBase.html?id=1&type=3" target="_blank">详细信息</a>
        },
    ];

    const dataSource: any[] = [
        {col1: "渤海造船厂集团有限公司", col2: "机电工程系校外顶岗实习实训基地", col3: "2016-09-03", col4: "装备制造大类", col5: 49, col6: 34, col7: "详细信息"},
        {col1: "渤海船舶重工有限责任公司", col2: "船舶工程系渤海船舶重工有限责任公司校外教学性实训基地", col3: "2001-06-14", col4: "装备制造大类", col5: 470, col6: 56, col7: "详细信息"},
        {col1: "柳州五菱青岛分公司", col2: "动力工程系校外实习实训基地", col3: "2020-05-23", col4: "装备制造大类", col5: 13, col6: 13, col7: "详细信息"},
        {col1: "招商局重工（江苏）有限公司", col2: "招商局重工（江苏）有限公司实习实训基地", col3: "2018-10-15", col4: "装备制造大类", col5: 53, col6: 15, col7: "详细信息"},
        {col1: "辽宁天意实业股份有限公司", col2: "电气工程系校外顶岗实习实训基地", col3: "2016-12-21", col4: "装备制造大类", col5: 140, col6: 35, col7: "详细信息"},
        {col1: "中船澄西船舶修造有限公司", col2: "中船澄西船舶修造有限公司实习实训基地", col3: "2019-10-09", col4: "装备制造大类", col5: 180, col6: 28, col7: "详细信息"},
        {col1: "中铁宝桥集团有限公司实习实训基地", col2: "中铁宝桥集团有限公司", col3: "2016-12-22", col4: "装备制造大类", col5: 134, col6: 22, col7: "详细信息"},
    ];

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>{num}</Button>

            <Modal visible={visible} onCancel={() => setVisible(false)} title="校外实训基地数量" width={1200} footer={false}>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Modal>
        </>
    );
}
