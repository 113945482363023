import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import useRequest from "../../../../../lib/useRequest";
import _ from "lodash";
import { findOptions, getShuangGao } from "../../../../../service/chart";
import { getNormalData } from "../../../../../service/basic";
import useDevice from "../../../../../lib/useDevice";


export default function Chart5 () {
  const { isMobile } = useDevice();

  // const [ data ] = useRequest(() => getShuangGao());
  const [data] = useRequest(() => getNormalData < { sg: string[], subject: string[], '1x': string[], name: string[] } > ("2a235851-eb8a-4944-a7c6-de508b6bf867")); // 各专业大类专业布点情况
  const options: echarts.EChartOption<echarts.EChartOption.Series> = useMemo(() => {

    return {
      xAxis: {
        type: "category",
        axisLine: { show: false },
        axisTick: { show: false },
        inverse: true,
        data: JSON.stringify(data) !== '{}' && data ? data.name.filter((item: any) => item).map((item: any) => item.split("大")[0]) : [],
        axisLabel: {
          rotate: 30,
          margin: 15
        }
      },
      tooltip: {
        formatter: `专业布点数：{c}`
      },
      grid: {
        left: isMobile ? '10%' : '3%',
        top: "3%",
        right: 0,
        bottom: "20%",
      },
      yAxis: {
        type: 'value',
        axisLine: { show: false },
        max: 100,
        axisTick: { show: false },
      },
      series: [
        {
          cursor: 'default',
          name: '数据',
          type: 'bar',
          // barWidth: 72,
          itemStyle: {
            barBorderRadius: 4,
            color: {
              type: "linear",
              y: 0, y2: 1,
              x: 0, x2: 0,
              colorStops: [
                {
                  color: "#49ac73",
                  offset: 0
                },
                {
                  color: "#1b9ed0",
                  offset: 1
                }
              ]
            }
          },

          data: data?.subject
        }
      ]
    };

  }, [data])

  return (
    <ReactEcharts style={{ height: 356 }} option={options as any} />
  );
}
