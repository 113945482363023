import style from "../../index.module.scss";
import React, {useState} from "react";
import {Modal} from "antd";

interface ShowTextProps {
    text: string;
    max: number;
}

export default function ShowText({text, max}: ShowTextProps) {

    const [showDetail, setShowDetail] = useState(false);

    function getLength(str: string){
        // let strLength=0; // 记录str的总长度
        // const len = str.length;
        // for(let i=0;i<len;i++){
        //     const charCode = str.charCodeAt(i);// 使用charCodeAt返回单个字符的Unicode编码
        //     if(charCode>=0 && charCode<=128){
        //         strLength++; //英文字符加1
        //     }else {
        //         strLength= strLength+2;//中文字符加2
        //     }
        // }
        // 出于性能考虑直接用str.length了
        return str.length;
    }

    function subStrNum(str: string,len: number) {
        const strLen = getLength(str);
        let strCut = '';
        let strLength =0;

        for(let i=0;i<strLen;i++){
            const charStr = str.charAt(i); //使用charAt获取单个字符；
            strLength++
            if(encodeURI(charStr).length>4){ //使用encodeURI获取编码长度
                strLength++;
            }
            strCut = strCut.concat(charStr);//单个字符进行合并
            if(strLength >= len){
                strCut = strCut.concat('...') //大于指定长度后合并'...'并返回此字符串
                return strCut;
            }
        }

        if(strLength < len){
            return str;
        }
    }

    return (
        <div>
            {subStrNum(text, max)}
            {getLength(text) >= 50 && <span className={style.detail} onClick={() => setShowDetail(true)}> {'详细>>'} </span>}

            <Modal visible={showDetail} title="信息详细" centered onCancel={() => setShowDetail(false)} footer={false}>
                <div className={style.content}>
                    <div>
                        {text}
                    </div>
                </div>
            </Modal>
        </div>
    );
}


ShowText.defaultProps = {
    max: 50
}
