//@ts-nocheck
import ReactEcharts from 'echarts-for-react';
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import useDevice from '../../../../../lib/useDevice';

export default function Chart14 (props: any) {
  const { isMobile } = useDevice()
  const [data, setData] = useState(props.item);
  useEffect(() => {
    setData(props.item)
  }, [props.item]);
  const getMaxValue = () => {
    let max = 0;
    data?.forEach((item: any) => {
      if (max < item.value)
      {
        max = item.value;
      }
    })
    return max
  }

  const options: echarts.EChartOption<echarts.EChartOption.Series> = useMemo(() => {

    const list: { name: string, value: string }[] = data as { name: string, value: string }[]
    const sortList = _.sortBy(list, item => Number(item.value)).reverse()

    const maxGreen = 173
    const minGreen = 44
    const Green = maxGreen - minGreen
    const richList = sortList.map((item, index) => {
      return {
        icon: `icon${index}`,
        backgroundColor: `rgb(237, ${minGreen + (Green / sortList.length) * index}, 38)`,
        width: 20,
        height: 20,
        borderRadius: 20,
        align: "center",
        lineHeight: 20,
        color: "#fff",
      }
    })

    const richs = _.zipObject(richList.map(item => item.icon), richList)
    return {
      xAxis: {
        show: false,
        max: getMaxValue() + 5
      },
      grid: {
        top: 0,
        left: isMobile ? 100 : 305,
        right: 0,
        bottom: 0
      },
      yAxis: {
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
        inverse: true,
        data: sortList?.map((item: any) => item.area_code),
        axisLabel: {
          formatter: (value: any, index: number) => {
            return `{icon${index}|${index + 1}} ${value}`
          },
          textStyle: {
            color: '#444',
            align: 'left',
            fontSize: 14
          },
          width: isMobile ? 40 : 300,
          margin: isMobile ? 85 : 350,
          rich: {
            icon: {
              backgroundColor: "#08c",
              width: 20,
              height: 20,
              borderRadius: 20,
              align: "center",
              lineHeight: 20,
              color: "#fff",
            },
            ...richs
          }
        }
      },
      series: [
        {
          name: '数据',
          type: 'bar',
          barWidth: 20,
          showBackground: true,
          backgroundStyle: {
            color: "#dfe5f1",
            barBorderRadius: 3
          },
          label: {
            show: true,
            position: "insideRight",
            color: "#fff"
          },
          data: sortList?.map((item: any, index: any) => {
            return {
              value: item.value,
              itemStyle: {
                barBorderRadius: 3,
                color: `rgb(237, ${minGreen + (Green / sortList.length) * index}, 38)`
              }
            }
          })
        }
      ]
    };

  }, [data])
  return (
    <ReactEcharts style={{ height: 500 }} onEvents={{
      "click": (e) => {
        props.showTable(e.name);
      }
    }} option={options as any} />
  );
}
