import React, { useState } from "react";
import style from './index.module.scss'
import { Button, Col, Empty, Row, Spin } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { findSubjectA, findSubjectB, zyHxzykjs } from "../../../../../service/major";
import PopCol4 from "../../../../Practice/SchoolList/components/PopCol4";
import PopCol5 from "../../../../Practice/SchoolList/components/PopCol5";
import PopCol6 from "../../../../Practice/SchoolList/components/PopCol6";
import { getNormalData } from "../../../../../service/basic";
import useDevice from "../../../../../lib/useDevice";

interface BasicInfoProps {
  subjectId: string;
  data: any;
}


export default function BasicRefresh (props: any) {

  // const [ findDataA, { loading } ] = useRequest(() => findSubjectA(subjectId))
  // const [ findDataB, { loading:findLoading } ] = useRequest(() => findSubjectB(subjectId))
  const [findDataA, { loading }] = useRequest(() => getNormalData < any > ("e6af74db-a805-4bee-ae57-dea77c5e6ee1", { id: props.subjectId })) //基本信息
  const [findDataB, { loading: findLoading }] = useRequest(() => getNormalData < any > ("e193210c-221f-4b37-84e7-da1907e6f8c5", { id: props.subjectId })) //专业核心课等等
  const { isMobile } = useDevice()

  return (
    <div className={style.root}>
      <div className={style.container} style={{ padding: "10px 0" }}>
        <div className={style.headTitle}>
          <div className={style.blueline}></div>
          <h1>基本信息</h1>
        </div>
      </div>
      <div style={{ padding: "0 40px", overflow: isMobile ? "auto" : 'none' }}>
        <table className={style.customers}>
          <tr>
            <td colSpan={2}>
              <div className={style.tableLabel}><span>学校名称</span></div>
              <div className={style.tableContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].schoolName : '--'}</span></div>
            </td>
          </tr>

          <tr className={style.alt}>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>专业名称及代码</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].subjectNameAndCode : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>学制</span></div>
              <div className={style.tableTwoContent}><span>{props.data ? props.data.data.restYear : '--'}</span></div>
            </td>
          </tr>

          <tr>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>专业大类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].subjectBig : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>专业类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].subject : '--'}</span></div>
            </td>
          </tr>


          <tr>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>入学要求</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].inRequirements : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>主要岗位类别（或技术领域）</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].occupationType : '--'}</span></div>
            </td>
          </tr>

          <tr className={style.alt}>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>对应行业门类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].industry1 : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>对应行业大类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].industry2 : '--'}</span></div>
            </td>
          </tr>

          <tr>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>主要职业大类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].occupationType1 : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>主要职业中类</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].occupationType2 : '--'}</span></div>
            </td>
          </tr>

          <tr>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>首次招生时间</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].firstZs : '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>本学期最低录取分数</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].zdlqfs : '--'}</span></div>
            </td>
          </tr>
          {/* <tr className={style.alt}>
            <td colSpan={2}>
              <div className={style.tableLabel}><span>首次招生时间</span></div>
              <div className={style.tableTwoContent}><span>{findDataA && findDataA.length > 0 ? findDataA[0].firstZs : '--'}</span></div>
            </td>
          </tr> */}

          <tr>
            <td style={{ width: "50%", border: "1px solid #e9e9e9" }}>
              <div className={style.tableLabel}><span>在校生数</span></div>
              <div className={style.tableTwoContent}><span>{findDataB?.schoolStudentNum || '--'}</span></div>
            </td>
            <td style={{ width: "50%" }}>
              <div className={style.tableLabel}><span>专业教师数</span></div>
              <div className={style.tableTwoContent}><span>{findDataB?.subjectTeacherNum || '--'}</span></div>
            </td>
          </tr>

          <tr className={style.alt}>
            <td colSpan={2} style={{ padding: "20px 8px" }}>
              <div className={style.tableLabel}><span>课程设置情况</span></div>
              <div className={style.tableTwoContent}>
                <ul style={{ margin: 0, paddingInlineStart: 16 }}>
                  <li className={style.ulLi}>理论课时数：{findDataB?.course1 || '--'}</li>
                  <li className={style.ulLi}>实践课时数：{findDataB?.course2 || '--'}</li>
                  <li className={style.ulLi}>公共课时数：{findDataB?.course3 || '--'}</li>
                  <li className={style.ulLi}>专业课时数：{findDataB?.course4 || '--'}</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ padding: "20px 8px" }}>
              <div className={style.tableLabel}><span>专业教师情况</span></div>
              <div className={style.tableTwoContent}>
                <ul style={{ margin: 0, paddingInlineStart: 16 }}>
                  <li className={style.ulLi}>专任教师数量：{findDataB?.teacher1 || '--'}</li>
                  <li className={style.ulLi}>高级职称教师数量：{findDataB?.teacher2 || '--'}</li>
                  <li className={style.ulLi}>硕士及以上学历教师数量：{findDataB?.teacher3 || '--'}</li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ whiteSpace: "normal" }}>
              <div className={style.tableLabel}><span>专业核心课</span></div>
              <div className={style.tableTotalContent}>
                <table className={style.admission}>
                  <tr>
                    <th style={{ width: "50%" }}>课程名称</th>
                    <th style={{ width: "50%" }}>课程性质描述</th>
                  </tr>
                  {findDataB?.core.map((item: any, index: number) => {
                    return <tr key={index}>
                      <td>{item.name || '--'}</td>
                      <td>{item.description || '--'}</td>
                    </tr>
                  })

                  }
                </table>
              </div>
            </td>
          </tr>

          <tr className={style.alt}>
            <td colSpan={2} style={{ whiteSpace: "normal" }}>
              <div className={style.tableLabel}><span>专业简介</span></div>
              <div className={style.tableTotalContent}>
                <span>
                  {findDataB?.subjectIntroduction || '--'}
                </span>
              </div>
              {/*        <Button type="link"*/}
              {/*                style={{padding:"0 5px",color:"#6c97d2",height:"auto",fontSize:12,paddingLeft:156}}*/}
              {/*                >*/}
              {/*            {'>>点此展开课程简介'}*/}
              {/*        </Button>*/}
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ whiteSpace: "normal" }}>
              <div className={style.tableLabel}><span>专业录取情况</span></div>
              <div className={style.tableTotalContent}>
                <table className={style.admission}>
                  <tr>
                    <th style={{ width: "20%" }}>录取年份</th>
                    <th style={{ width: "20%" }}>计划招生人数</th>
                    <th style={{ width: "20%" }}>实际录取人数</th>
                    <th style={{ width: "20%" }}>本省录取人数</th>
                  </tr>
                  {findDataB?.admissionStatus.map((item: any, index: number) => {
                    return <tr key={index}>
                      <td>{item.admissionYear || '--'}</td>
                      <td>{item.planAdmissionNum || '--'}</td>
                      <td>{item.actualAdmissionNum || '--'}</td>
                      <td>{item.provinceCheckInNum || '--'}</td>
                    </tr>
                  })

                  }

                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
