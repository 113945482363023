import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import Style from "../../index.module.scss"
import useRequest from "../../../../../lib/useRequest";
import { getNormalData, signIn } from "../../../../../service/basic";

export default function Chart1 () {
  // 实习签到率
  const [sin] = useRequest < any > (() => signIn());

  const options: EChartOption<EChartOption.SeriesLine> = {
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any, arg) {
        return params.map((item: any) => `${item.seriesName}: ${item.value}%`).join("<br />");
      }
    },
    legend: {
      icon: 'circle',
      itemWidth: 10,
      // top:10,
      data: ['顶岗实习', '跟岗实习'],
      textStyle: { color: "rgb(179,193,255)" }
    },
    grid: {
      left: '5%',
      right: '10%',
      bottom: '20%',
      top: "20%",
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['2017-18学年', '2018-2019学年', '2019-2020学年', '2020-2021学年'],
      axisLabel: { color: "#fff" }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: "#fff",
        formatter: (val: number) => `${(val).toFixed(2)}%`,
      },
      splitLine: {
        lineStyle: {
          type: "dotted",
          color: "rgb(86,112,162)"
        }
      }
    },
    series: [
      {
        cursor: 'default',
        name: '顶岗实习',
        type: 'line',
        data: [72, 67, 73, 77],
        itemStyle: { color: "rgb(154,68,235)" }
        // lineStyle: {color: "rgb(154,68,235)"}
      },
      {
        cursor: 'default',
        name: '跟岗实习',
        type: 'line',
        data: [57, 53, 63, 70],
        itemStyle: { color: "rgb(47,194,91)" }
      },
    ]
  };
  const opt = useMemo(() => {
    if (sin)
    {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: function (params: any) {
            return params.map((item: any) => `${item.seriesName}: ${(item.value).toFixed(2)}%`).join("<br />");
          }
        },
        ...sin,
        legend: {
          icon: 'circle',
          itemWidth: 10,
          // top:10,
          data: ['顶岗实习', '跟岗实习'],
          textStyle: { color: "rgb(179,193,255)" }
        },
        grid: {
          left: '5%',
          right: '10%',
          bottom: '20%',
          top: "20%",
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: sin?.map((item: any) => item.year_name) || [],
          axisLabel: { color: "#fff" }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: "#fff",
            formatter: (val: number) => `${val}%`,
          },
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "rgb(86,112,162)"
            }
          }
        },
        series: [
          {
            cursor: 'default',
            name: '顶岗实习',
            type: 'line',
            data: sin?.map((item: any) => Number(item.dgqdl)) || [],
            itemStyle: { color: "rgb(154,68,235)" }
            // lineStyle: {color: "rgb(154,68,235)"}
          },
          {
            cursor: 'default',
            name: '跟岗实习',
            type: 'line',
            data: sin?.map((item: any) => Number(item.ggqdl)) || [],
            itemStyle: { color: "rgb(47,194,91)" }
          },
        ]
      }
    } else
    {
      return {};
    }
  }, [sin]);
  return (
    <div className={Style.root}>
      <ReactEcharts
        style={{ height: 180 }}
        option={opt}
      />
    </div>
  );
}
