import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'antd'
import ReactEcharts from 'echarts-for-react';
import 'echarts/map/js/province/liaoning.js';
import style from './index.module.scss';
import Popup from "../../../../Charts/Components/Popup";
import ThemeData from '../../../../../../src/assets/walden.project.json';
import TableStyle from '../../../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss';
import _ from 'lodash';
import { ILevel, ISchoolInfo } from '../../../../../service/chart';
import useRequestFn from '../../../../../lib/useRequestFn';
import { getNormalData } from '../../../../../service/basic';

interface ProvinceProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: number;
    height?: number;
    year: string[];
    tableType?: number;
    currentType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList: any;
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: string; schCount: string }[]; // 国家级院校
    schoolListSpecial?: ILevel[]; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
}

interface DataArrTypes {
    [key: string]: {
        values: number[],
    }
}

// 红点坐标
const pointsRedCoord = {
    '沈阳市': [123.229096, 42.396767],
    '大连市': [121.618622, 39.51459],
    '鞍山市': [122.795632, 40.810626],
    '抚顺市': [124.421109, 41.775956],
    '本溪市': [124.070519, 41.297909],
    '丹东市': [124.383044, 40.524296],
    '锦州市': [121.135742, 41.419269],
    '营口市': [122.535151, 40.497432],
    '阜新市': [121.648962, 42.311796],
    '辽阳市': [123.38152, 41.269402],
    '盘锦市': [122.06957, 41.224484],
    '铁岭市': [124.044279, 42.740585],
    '朝阳市': [120.451176, 41.876758],
    '葫芦岛市': [120.256394, 40.755572]
};

const pointsBlueCoord = {
    '沈阳市': [122.929096, 41.796767],
    '大连市': [122.618622, 39.71459],
    '鞍山市': [123.295632, 40.510626],
    '抚顺市': [124.921109, 41.875956],
    '本溪市': [125.070519, 41.297909],
    '丹东市': [124.783044, 40.824296],
    '锦州市': [121.835742, 41.549269],
    '营口市': [122.435151, 40.267432],
    '阜新市': [122.148962, 42.311796],
    '辽阳市': [123.38152, 40.869402],
    '盘锦市': [122.06957, 41.024484],
    '铁岭市': [124.544279, 42.540585],
    '朝阳市': [119.851176, 41.576758],
    '葫芦岛市': [120.056394, 40.255572]
};

export default function Province ({
    name, data, className, year,
    changeCityName, schoolList,
    geoColor = '46, 199, 201',
    tableType = 1, width = 1920,
    height = 1000, special = false,
    pointsBlue, pointsRed,
    schoolListSpecial,
    showTimeLine,
    onClean
}: ProvinceProps) {
    const [years, setYears] = useState < string[] > ([]);

    // 切换标记
    const [currentType, setCurrentType] = useState(0);

    // 表单数据
    const [tableData, setTable] = useState < any[] > ([
        {
            id: 1,
            name: '辽宁城市建设职业技术学院',                        // 学校名称
            count: '19',                                        //专业数量
            plannedCount: '2621',                              // 计划实习人数
            actualCount: '2109'                                // 实际实习人数
        }
        ,
        {
            id: 2,
            name: '辽宁广告职业学院',                            // 学校名称
            count: '43',                                       //专业数量
            plannedCount: '4311',                             // 计划实习人数
            actualCount: '3981'
        },
        {
            id: 3,
            name: '辽宁轨道交通职业学院',                         // 学校名称
            count: '30',                                      //专业数量
            plannedCount: '2871',                              // 计划实习人数
            actualCount: '2203'
        },
        {
            id: 4,
            name: '辽宁金融职业学院',                              // 学校名称
            count: '36',                                      //专业数量
            plannedCount: '3871',                             // 计划实习人数
            actualCount: '3198'
        },
        {
            id: 5,
            name: '辽宁经济职业技术学院',                         // 学校名称
            count: '60',                                      //专业数量
            plannedCount: '5912',                            // 计划实习人数
            actualCount: '4891'
        },
        {
            id: 6,
            name: '辽宁民族师范高等专科学校',                         // 学校名称
            count: '27',                                      //专业数量
            plannedCount: '2212',                            // 计划实习人数
            actualCount: '1898'
        }
        ,
        {
            id: 7,
            name: '辽宁生态工程职业学院',                         // 学校名称
            count: '98',                                      //专业数量
            plannedCount: '7898',                            // 计划实习人数
            actualCount: '7198'
        },
        {
            id: 8,
            name: '辽宁省交通高等专科学校',                         // 学校名称
            count: '53',                                      //专业数量
            plannedCount: '5124',                            // 计划实习人数
            actualCount: '4763'
        },
        {
            id: 9,
            name: '辽宁特殊教育师范高等专科学校',                         // 学校名称
            count: '23',                                      //专业数量
            plannedCount: '1984',                            // 计划实习人数
            actualCount: '1431'
        },
        {
            id: 10,
            name: '辽宁现代服务职业技术学院',                         // 学校名称
            count: '25',                                      //专业数量
            plannedCount: '2412',                            // 计划实习人数
            actualCount: '1789'
        },
        {
            id: 11,
            name: '辽宁医药职业学院',                         // 学校名称
            count: '40',                                      //专业数量
            plannedCount: '5512',                            // 计划实习人数
            actualCount: '4981'
        },
        {
            id: 12,
            name: '辽宁政法职业学院',                         // 学校名称
            count: '42',                                      //专业数量
            plannedCount: '4712',                            // 计划实习人数
            actualCount: '3977'

        },
        {
            id: 13,
            name: '辽宁装备制造职业技术学院',                         // 学校名称
            count: '60',                                      //专业数量
            plannedCount: '6212',                            // 计划实习人数
            actualCount: '5739'
        },
        {
            id: 14,
            name: '沈阳北软信息职业技术学院',                         // 学校名称
            count: '26',                                      //专业数量
            plannedCount: '1763',                            // 计划实习人数
            actualCount: ' 1532'

        },
        {
            id: 15,
            name: '沈阳职业技术学院',                         // 学校名称
            count: '102',                                      //专业数量
            plannedCount: '8431',                            // 计划实习人数
            actualCount: ' 7812'
        }
    ])
    const [onTabName, aa] = useRequestFn(getNormalData)
    const onSchoolName = async (val: any) => {
        let res = await onTabName < any > ('ba2aea33-dddc-4587-9909-ce6e912e1a84', { schoolName: val })
        setNewTableData(res)
    }

    //列表详情
    const [newTableData, setNewTableData] = useState < any > ([])

    //旧数据
    const [newTableData1, setNewTableData1] = useState([
        {
            name: '建筑工程技术',                               // 学校名称
            plannedCount: '310',                            // 计划实习人数
            actualCount: ' 310'                              // 实际实习人数
        }, {
            name: '土木工程检测技术',                               // 学校名称
            plannedCount: '215',                            // 计划实习人数
            actualCount: ' 203'                              // 实际实习人数
        }, {
            name: '建筑钢结构工程技术',                               // 学校名称
            plannedCount: '344',                            // 计划实习人数
            actualCount: ' 297'                              // 实际实习人数
        }, {
            name: '广告策划与营销',                               // 学校名称
            plannedCount: '129',                            // 计划实习人数
            actualCount: ' 129'                              // 实际实习人数
        }, {
            name: '烹调工艺与营养',                               // 学校名称
            plannedCount: '87',                            // 计划实习人数
            actualCount: ' 66'                              // 实际实习人数
        }, {
            name: '铁道机车',                               // 学校名称
            plannedCount: '87',                            // 计划实习人数
            actualCount: ' 66'                              // 实际实习人数
        }, {
            name: '动车组检修技术',                               // 学校名称
            plannedCount: '80',                            // 计划实习人数
            actualCount: ' 80'                              // 实际实习人数
        }, {
            name: '会计',                               // 学校名称
            plannedCount: '87',                            // 计划实习人数
            actualCount: ' 66'                              // 实际实习人数
        }, {
            name: '烹调工艺与营养',                               // 学校名称
            plannedCount: '290',                            // 计划实习人数
            actualCount: ' 286'                              // 实际实习人数
        },
        {
            name: '财务管理',                               // 学校名称
            plannedCount: '378',                            // 计划实习人数
            actualCount: ' 360'                              // 实际实习人数
        }, {
            name: '国际贸易实务',                               // 学校名称
            plannedCount: '189',                            // 计划实习人数
            actualCount: ' 189'                              // 实际实习人数
        }

    ]);

    const echartsRef = useRef < any > (null);
    const [cityNameArr, setCityNameArr] = useState < string[] > ([
        '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市',
        '锦州市',
        '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市', '葫芦岛市']);
    const [dataArr, setDataArr] = useState < DataArrTypes > ({});
    const [geoRegions, setGeoRegions] = useState < any[] > ([]);

    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');
    useEffect(() => {

        if (data)
        {
            // 城市名称 年份
            const yearArr: string[] = [];
            const geoRegions: any = []; // 城市颜色

            data.map(item => {
                if (!yearArr.includes(item.year)) yearArr.push(item.year);
            });

            cityNameArr.map((city, index) => {

                geoRegions.push({
                    name: city,
                    itemStyle: {
                        normal: {
                            areaColor: `rgba(${geoColor}, 0.8)`
                        }
                    }
                });
            });

            setYears(yearArr);
            setGeoRegions(geoRegions);
            initDataArr(cityNameArr);

        }
    }, [data]);

    // 按年份 dataArr数据
    function initDataArr (cityNameArr: string[]) {
        const dataArr: DataArrTypes = {};
        year.map(year => {
            dataArr[year] = { values: [] }
            cityNameArr.map(city => {
                data.map(item => {
                    if (item.year === year && item.name === city)
                    {
                        dataArr[year]['values'].push(item.value);
                    }
                })
            });
        });
        setDataArr(dataArr);
    }

    function formatPointData (data: { areaCode: string; schCount: string }[], isRedPoint: boolean = true) {
        const defaultData: { [key: string]: number[] } = isRedPoint ? pointsRedCoord : pointsBlueCoord;
        return data.map(item => {
            const key: string = item.areaCode;
            return {
                name: item.areaCode,
                value: [...defaultData[key], item.schCount],
                visualMap: false
            }
        });
    }

    // 柱形及年份线 位置大小调整
    const barOption = {
        left: showTimeLine ? '10%' : '-100%',
        bottom: '0%',
        width: '80%'
    };


    // timeline 文字大小
    const timelineLabel = special ? {
        label: {
            rotate: '20',
            align: 'center',
            color: '#ffffff',
        }
    } : {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            }
        }
    };

    const timeline = useMemo(() => {
        return {
            // title: {
            //     text: '辽宁省高职院校2020年毕业生省内就业分布图',
            //     left: "center"
            // },
            toolbox: {
                show: false,
            },
            geo: {
                top: 0,
                bottom: 0,
                map: name,
                // regions: [
                //     ...geoRegions
                // ],
                label: {
                    normal: {
                        show: true,
                        color: '#ffffff'
                    },
                    emphasis: {
                        show: false,
                        color: '#ffffff'
                    }
                },
                zoom: 0.90,
                itemStyle: {
                    normal: {
                        borderColor: 'rgba(147, 235, 248, 1)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                            }],
                            globalCoord: false // 缺省为 false
                        },
                        shadowColor: 'rgba(128, 217, 248, 1)',
                        shadowOffsetX: -2,
                        shadowOffsetY: 2,
                        shadowBlur: 10
                    },
                    emphasis: {
                        areaColor: `rgba(${geoColor}, 1)`,
                        // areaColor: 'normal' ,
                        borderWidth: 0
                    }
                },
            },
            animation: true,
            animationDuration: 1000,
            animationEasing: 'cubicInOut',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'cubicInOut',
            timeline: {
                show: showTimeLine ? true : false,
                data: year,
                axisType: 'category',
                autoPlay: showTimeLine ? true : false,
                controlStyle: {
                    showPlayBtn: false,
                    showNextBtn: false,
                    showPrevBtn: false,
                    normal: {
                        color: '#666',
                        borderColor: '#666'
                    },
                    emphasis: {
                        color: '#aaa',
                        borderColor: '#aaa'
                    },
                    symbolSize: 10,
                    lineStyle: {
                        color: '#555'
                    },
                    checkpointStyle: {
                        borderColor: '#777',
                        borderWidth: 2
                    },
                },
                ...timelineLabel,
                // top: '700',
                ...barOption,
                bottom: 0,
                height: '50',
                playInterval: 7000,
            },
            // bar
            grid: {
                top: '10%',
                ...barOption,
                bottom: "100",
                height: '150'
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    color: '#fff',
                    // rotate: 40
                    fontSize: 10
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        width: 100
                    }
                },
                splitLine: {
                    show: false
                },
                data: cityNameArr,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#fff',
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                }
            },
            // bar end
            series: [
                {
                    type: 'bar',
                    data: dataArr[years[0]],
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: ThemeData.color[0]
                    }

                }
            ]
        }
    }, [dataArr, years]);

    const optionsArr = useMemo(() => {
        const optArr: any[] = [];
        years.map((year, index) => {
            optArr.push({
                visualMap: {
                    min: 0,
                    max: _.max(dataArr[year].values),
                    splitNumber: dataArr[year].values.length,
                    color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                    textStyle: {
                        color: '#fff'
                    },
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 10
                    },
                    data: cityNameArr,
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    show: false
                },
                series: [
                    {
                        type: 'map',
                        data: cityNameArr.map((item, index) => {
                            return {
                                name: item,
                                value: dataArr[year]["values"][index]
                            }
                        }),
                        geoIndex: 0,
                        map: name
                    },
                    {
                        data: dataArr[year]["values"],
                        type: 'bar',
                        itemStyle: {
                            color: ThemeData.color[index]
                        },
                        barWidth: 30,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff'
                        },
                        top: 0
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsRed ? formatPointData(pointsRed) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return 40;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: 15,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#F62157'
                            }
                        }
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsBlue ? formatPointData(pointsBlue, false) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return 40;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: 15,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#3f44ef'
                                // color: colors[colorIndex][n]
                            }
                        }
                    }
                ]
            })
        });
        return optArr;
    }, [dataArr, years, pointsBlue, pointsRed]);

    const option: any = useMemo(() => {
        return {
            baseOption: timeline,
            options: optionsArr
        }
    }, [timeline, optionsArr]);

    //储存详情列表Title名称
    const [titleName, setTitle] = useState < any > (null)


    const onEventsObj = {
        'click': (params: any) => {
            const title = tableType === 1 ? `${params.name}地区院校实习列表` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
            changeCityName?.(params.name, '')
            setPointMark('area');
            setTableTitle(title);
            setTitle(title)
            setShow(true);
            return
            if (params.componentSubType === 'map') changeCityName?.(params.name, '');

            if (cityNameArr.includes(params.name) && params.componentType === "series" && params.componentSubType === "map")
            {
                const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
                setPointMark('area');
                setTableTitle(title);
                setShow(true);
            }

            if (params.componentType === "series" && params.componentSubType === "scatter")
            {
                if (params.componentIndex === 2)
                {
                    changeCityName?.(params.name, '国家级');
                    setPointMark('red');
                    const title = tableType === 1 ? `${params.name}地区双高院校` : (tableType === 2 ? `${params.name}地区双高专业列表` : `${params.name}精品课列表`);
                    setTableTitle(title);
                }
                if (params.componentIndex === 3)
                {
                    changeCityName?.(params.name, '省级')
                    setPointMark('blue');
                    const title = tableType === 1 ? `${params.name}省级示范性高职院校` : (tableType === 2 ? `${params.name}地区“1+x”专业列表` : `${params.name}在线课程列表`);
                    setTableTitle(title);
                }
                setShow(true);
            }
        },
        'timelinechanged': (index: any) => {

        }
    };


    let columns = [
        {
            title: '学校名称',
            dataIndex: 'schoolName'
        },
        {
            title: "专业数量",
            dataIndex: 'subjectNum',
            sorter: {
                compare: (a: any, b: any) => a.subjectNum - b.subjectNum
            }
        },
        {
            title: "计划实习人数",
            dataIndex: "planStuNum",
            sorter: {
                compare: (a: any, b: any) => a.planStuNum - b.planStuNum
            },
            render: (text: any, record: any) => {
                return <div style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }} onClick={() => {
                    // 后期接入数据
                    setCurrentType(1);
                    //  setNewTableData()
                    setTableTitle(record.schoolName)
                    onSchoolName(record.schoolName)

                }}>{record.subjectNum}</div>
            },

        },
        {
            title: "实际实习人数",
            dataIndex: 'realStuNum',
            sorter: {
                compare: (a: any, b: any) => a.realStuNum - b.realStuNum
            }
        }
    ]



    let columns1 = [
        {
            title: '专业名称',
            dataIndex: 'subjectName'
        },
        {
            title: "计划实习人数",
            dataIndex: 'planStuNum',
            sorter: {
                compare: (a: any, b: any) => a.planStuNum - b.planStuNum
            }
        },
        {
            title: "实际实习人数",
            dataIndex: 'realStuNum',
            sorter: {
                compare: (a: any, b: any) => a.realStuNum - b.realStuNum
            }
        }
    ]

    let dataSource = schoolList?.map((item: any) => {
        return {
            schoolName: item.schoolName,
            subjectNum: item.subjectNum,
            planStuNum: item.planStuNum,
            realStuNum: item.realStuNum,
        }
    })
    let dataSource1 = newTableData?.map((item: any) => {
        return {
            subjectName: item.subjectName,
            planStuNum: item.planStuNum,
            realStuNum: item.realStuNum,
        }
    })
    return (
        <div className={`${style.root} ${className}`}>
            <ReactEcharts
                theme={"theme"}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                ref={echartsRef}
                style={{ width, height, margin: '0 auto' }}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                setCurrentType(0);
                setShow(false);
            }} show={show} width='80vw'>
                <div className={TableStyle.tableWrapper}>
                    {/*<div className={TableStyle.tableTitle}>{tableTitle}</div>*/}
                    <div className={TableStyle.title}>
                        <div className={TableStyle.tableTitle} >{tableTitle}</div>
                        {
                            currentType !== 0 && <button className={TableStyle.buttonStyle} style={{ marginRight: '50px' }} onClick={() => {
                                setCurrentType(0);
                                setTableTitle(titleName);
                            }
                            }>返回</button>
                        }
                    </div>
                    <div style={{ height: '460px', overflow: 'auto' }}>
                        {
                            (tableType === 1 && pointMark === 'area') && <div>
                                {
                                    schoolList?.length !== 0 && currentType === 0 ?
                                        <Table columns={columns} dataSource={dataSource} pagination={false} /> :
                                        <Table columns={columns1} dataSource={dataSource1} pagination={false} />
                                }
                            </div>

                        }
                        {/* {(tableType === 1 && pointMark === 'area') && <table className={TableStyle.table}>
                            <tbody className={style.tableList}>
                                {
                                    schoolList?.length !== 0 && currentType === 0 ?
                                        <tr>
                                            <th>学校名称</th>
                                            <th>专业数量</th>
                                            <th>计划实习人数</th>
                                            <th>实际实习人数</th>
                                        </tr> :
                                        <tr>
                                            <td> 专业名称</td>
                                            <td> 计划实习人数</td>
                                            <td> 实际实习人数</td>
                                        </tr>
                                }
                                {
                                    schoolList?.length !== 0 && currentType === 0 && schoolList?.map((item: any, index: any) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.schoolName}</td>
                                                {
                                                    <td style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }} onClick={() => {
                                                        // 后期接入数据
                                                        setCurrentType(1);
                                                        //  setNewTableData()
                                                        setTableTitle(item.schoolName)
                                                        onSchoolName(item.schoolName)


                                                    }}>{item.subjectNum}</td>
                                                }
                                                <td>{parseInt(item.planStuNum)}</td>
                                                <td>{item.realStuNum}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    currentType === 1 && newTableData.map((item: any, index: number) => {
                                        return (
                                            <tr key={index} >
                                                <td>{item.subjectName}</td>
                                                <td>{parseInt(item.planStuNum)}</td>
                                                <td>{item.realStuNum}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>} */}
                    </div>
                    {/* 2 某某市高职院校专业情况统计*/}
                    {(tableType === 2 && pointMark === 'area') && <table className={TableStyle.table}>
                        <tbody className={style.tableList}>
                            <tr className={style.tableListTitle}>
                                <th style={{ width: '20%' }}>企业</th>
                                <th style={{ width: '20%' }}>学校</th>
                                <th style={{ width: '20%' }}>专业</th>
                                <th style={{ width: '20%' }}>人数</th>
                            </tr>
                            {schoolList?.filter((item: any) => item.studentCount !== null && item.studentCount !== 0).map((item: any) =>
                                <tr>
                                    <td>{item.company}</td>
                                    <td>{item.school}</td>
                                    <td>{item.subject}</td>
                                    <td>{item.count}</td>
                                </tr>)}
                        </tbody>
                    </table>}

                    {/*红点 重点专业列表*/}
                    {(tableType === 2 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody className={style.tableList}>
                            <tr className={style.tableListTitle}>
                                <th>学校</th>
                                <th>专业</th>
                                <th>级别</th>
                            </tr>
                            {schoolList?.map((item: any) => <tr>
                                <td>{item.schoolName}</td>
                                <td>{item.subjectName}</td>
                                <td>{item.is_sg}</td>
                            </tr>)}
                        </tbody>
                    </table>}
                    {/*蓝点 特殊专业列表*/}

                    {(tableType === 2 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>证书数量</th>
                            </tr>
                            {schoolList?.map((item: any) => <tr>
                                <td>{item.schoolName}</td>
                                <td>{item.subjectName}</td>
                                <td>{item.is_1x}</td>
                            </tr>)}

                        </tbody>
                    </table>}


                    {/* 某某市国家级高级院校列表*/}
                    {(tableType === 1 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                                <th>层次</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                                <td>省级</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 蓝 某某市省级示范性高级院校*/}
                    {(tableType === 1 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 3 某某市课程情况统计*/}
                    {(tableType === 3 && pointMark === 'area') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>课程数量</th>
                                <th>精品课</th>
                                <th>在线课</th>
                                <th>资源共享课</th>
                                <th>实训课</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>3346</td>
                                <td>19</td>
                                <td>740</td>
                                <td>560</td>
                                <td>1002</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>5486</td>
                                <td>16</td>
                                <td>650</td>
                                <td>430</td>
                                <td>1209</td>
                            </tr>
                            <tr>
                                <td>辽宁轨道交通技术学院</td>
                                <td>4892</td>
                                <td>19</td>
                                <td>734</td>
                                <td>467</td>
                                <td>980</td>
                            </tr>
                            <tr>
                                <td>辽宁铁道职业技术学院</td>
                                <td>5643</td>
                                <td>15</td>
                                <td>790</td>
                                <td>667</td>
                                <td>1032</td>
                            </tr>
                            <tr>
                                <td>沈阳北软件职业信息技术学院</td>
                                <td>7654</td>
                                <td>19</td>
                                <td>543</td>
                                <td>675</td>
                                <td>1902</td>
                            </tr>

                        </tbody>
                    </table>}
                    {/*红点 某某市精品课列表*/}
                    {(tableType === 3 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>级别</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>国家级</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>省级</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>省级</td>
                            </tr>
                        </tbody>
                    </table>}
                    {/*蓝点 某某市在线课程列表*/}
                    {(tableType === 3 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>签到人数</th>

                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>2356</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>5674</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>7645</td>
                            </tr>
                        </tbody>
                    </table>}


                </div>
            </Popup>
        </div>
    )
}

Province.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
