import styles from "./index.module.scss";
import classNames from "classnames";
import CardInfo from "./Components/CardInfo";
import {useState} from "react";


export default function InfoCard({code,file,ffcy, onClick}: any) {

    const [show, setShow] = useState(false);

    return (
        <div className={classNames(styles.root)} onMouseEnter={() => setShow(true)}>
            <div className={styles.text}>
                {ffcy}
            </div>

            {show && <CardInfo code={code} file={file} ffcy={ffcy} onClick={onClick} />}
        </div>
    )
}
