import React from "react";
import GuestNav from "./components/GuestNav";
import classNames from "classnames/bind";

const style = classNames.bind(require("./index.module.scss"));

interface GuestLayoutProps {
    children: React.ReactNode;
}

export default function GuestLayout({children}: GuestLayoutProps) {

    return (
        <div className={style("root")}>
            <GuestNav />

            <div className="container">
                {children}
            </div>
        </div>
    );
}
