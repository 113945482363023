//@ts-nocheck
import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import useRequest from "../../../../lib/useRequest";
import { getNormalData } from "../../../../service/basic";


let colorList2 = [
    "#f77070",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",
    "#ffd200",
    "#ffad56",
    "#34ac74",
    "#34ac74",
    "#d7504b",
    "#c6e579",
    "#f4e001",
    "#f0805a",
    "#26c0c0"
];
interface IProps {
    customOption?: (
        opt: EChartOption<EChartOption.Series>
    ) => EChartOption<EChartOption.Series>;
    style?: any;
}

export default function Index ({ customOption }: IProps) {
    const [data] = useRequest(() => getNormalData ("00b558d2-c07f-11ec-ba43-005056958024")); // 专业布点分布图
    const opt = useMemo(() => {
        // 综合大学整体替换成综合院校
        let list = data ?  data : []
        let arr = list.map((item) => {
            return {
                name: item.name,
                value: item.count
            }
        })

        return {

            title: {
                text: "各指标达标院校数量",
                left: "left",
                top: 0,
                textStyle: {
                    color: "#347afc",
                }
            },
            color: colorList2,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b} : {c} ({d}%)"
            },
            grid: { //网格大小
                x: 10,
                y: 10,
                x2: 1,
                y2: 10,
                borderWidth: 1,
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: ['30%', '60%'],
                    center: ['50%', '60%'],
                    data: arr,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }, [data]);
    return <div>
        <ReactEcharts
            option={opt}
        />
    </div>
}
