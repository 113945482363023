import React, {useEffect, useState} from "react";
import {Form, Input, Button, Checkbox, Select, Row, Col} from 'antd';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";

import style from "./index.module.scss"

interface VocationalSchool {
    countValue: string;
    subjectCode: string;
    subjectName: string;
}
interface Vocational {
    code: string;
    name: string;
    child: VocationalSchool[]
}
interface VocationalRowProps{
    category?: string;
    child?: Vocational[];
    showProfessional?: boolean;
    toDetail?: (subjectCode: string, name: string) => void;
}

export default function VocationalRow({category, child, showProfessional, toDetail}: VocationalRowProps) {
    const history = useHistory();

    function gotoPath(subjectCode: string, name: string) {
        !showProfessional && history.push(`/major/SchoolList?subjectCode=${subjectCode}&name=${name}`);
        showProfessional && toDetail?.(subjectCode, name);
    }

    return (
        <div className={style.vocationRow}>
            <div className={style.rowTitle}>
                <span>专业大类：</span>
                <span>{category}</span>
            </div>
            <div className={style.sortList}>
                {child?.map(item => {
                    return (
                        <div key={item.code} className={style.sortRow}>
                            <div className={style.sortRowTitle}><span>{item.name}</span></div>
                            <div className={style.sortRowList}>
                                {item.child.map(item => (
                                    <div key={item.subjectCode} className={style.sortRowListRow}>
                                        <span className={style.sortNum}>{item.subjectCode}</span>
                                        <Button type="link" className={style.antButton} onClick={() => gotoPath(item.subjectCode, item.subjectName)}>{item.subjectName}</Button>
                                        <span className={style.sortNum}>({item.countValue})</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

VocationalRow.defaultProps = {
    showProfessional: false
}
