import { RetweetOutlined, SearchOutlined } from "@ant-design/icons";
import {Button, Col, Empty, Form, Input, Row, Select} from "antd";
import qs from "qs";
import React from "react";
import { useHistory } from "react-router-dom";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import ProcessListTable from "./components/ProcessListTable";
import style from "./index.module.scss"

export default function PagePracticeProcess() {

    useBreadcrumb([{name: "工作页", url: "/"}, {name: "实习实训"}]);
    const [form] = Form.useForm();
    const history = useHistory();


    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.contentList}>
                    <div className={style.title} style={{marginBottom:"10px"}}>
                        <span>专业列表</span>
                    </div>
                    <ProcessListTable />
                </div>
            </div>
        </div>
    );
}
