import React, {useEffect, useState} from "react";
import style from "./index.module.scss"
import ProfessionInfoTable from "../../../components/ProfessionInfoTable";
import {useHistory} from "react-router-dom";
import useRequest from "../../../lib/useRequest";
import { findSpecialtyInfoById } from "../../../service/major";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";

export default function SchoolDetail() {

    useBreadcrumb([{name: "工作页", url: "/"}, {name: "专业信息", url: "/major/list"}, {name: "详细"}]);
    const { id } = useQuery<{id: string}>()

    const history = useHistory();
    const [hover,setHover] = useState(false);

    const [ data ] = useRequest(() => findSpecialtyInfoById(id));


    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.contentHeader}>
                    <div><span className={style.headerSmallTitle}>{data?.schoolName}({data?.schoolCode})</span></div>
                    <div><span className={style.headerBigTitle}>{data?.subjectName}({data?.subjectCode})</span></div>
                </div>
                <ProfessionInfoTable/>
            </div>
        </div>
    );
}
