import React from "react";
import style from "./index.module.scss"
import {Table} from 'antd';
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import PopCol4 from "./components/PopCol4";
import PopCol5 from "./components/PopCol5";
import PopCol6 from "./components/PopCol6";

export default function PagePracticeSchoolList() {

    useBreadcrumb([{name: "工作页", url: "/"}, {name: "专业信息", url: "/major/list"}, {name: "学校列表"}]);
    const { subjectCode, name } = useQuery<{subjectCode: string, name: string}>()

    const dataSource: any[] = [
        {col1: "辽宁工程职业学院", col2: 355, col3: 300, col4: 3, col5: 2, col6: 132, col7: 3, col8: 272, col9: 2},
        {col1: "辽宁轨道交通职业学院", col2: 591, col3: 432, col4: 5, col5: 1, col6: 279, col7: 6, col8: 396, col9: 2},
        {col1: "辽宁建筑职业学院", col2: 431, col3: 552, col4: 2, col5: 3, col6: 466, col7: 4, col8: 211, col9: 2},
        {col1: "沈阳职业技术学院", col2: 227, col3: 303, col4: 2, col5: 1, col6: 208, col7: 1, col8: 98, col9: 2},
        {col1: "大连职业技术学院", col2: 195, col3: 205, col4: 1, col5: 2, col6: 95, col7: 1, col8: 56, col9: 2},
        {col1: "辽宁建筑职业学院", col2: 299, col3: 345, col4: 3, col5: 0, col6: 113, col7: 3, col8: 187, col9: 2},
    ];

    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.contentHeader}>
                    <span>{name}（{subjectCode}）</span>
                </div>

                <div className={style.container}>
                    <Table dataSource={dataSource} pagination={false}>
                        <Table.Column title="学校名称" dataIndex="col1" />
                        <Table.Column title="专业在校生人数" dataIndex="col2" />

                        <Table.ColumnGroup title="校内实训基地">
                            <Table.Column title="工位数" dataIndex="col3" render={(num: number) => <PopCol4 num={num} />} />
                            <Table.Column title="设备台套数" dataIndex="col6" render={(num: number) => <PopCol4 num={num} />} />
                            <Table.Column title="成果转化项目数" dataIndex="col7" render={(num: number) => <PopCol4 num={num} />} />
                        </Table.ColumnGroup>

                        <Table.ColumnGroup title="校外实训基地">
                            <Table.Column title="基地数" dataIndex="col3" render={(num: number) => <PopCol5 num={num} />} />
                            <Table.Column title="培养学生人数" dataIndex="col8" render={(num: number) => <PopCol5 num={num} />} />
                            <Table.Column title="缺口" dataIndex="col9" render={(num: number, col: any) => <PopCol5 num={col.col2 - col.col8} />} />
                        </Table.ColumnGroup>

                        <Table.Column title="虚拟仿真实验教学项目数" dataIndex="col5" render={(num: number) => <PopCol6 num={num} />} />
                    </Table>
                </div>
            </div>
        </div>
    );
}
