//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import echarts, { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../../lib/useRequest";
import { getShuangGao } from "../../../../../service/chart";
import Style from "./index.module.scss"
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";

var ydata1 = [
  { name: "装备制造", value: 35929 },
  { name: "财经商贸", value: 29936 },
  { name: "电子信息", value: 25568 },
  { name: "教育与体育", value: 19583 },
  { name: '交通运输', value: 15219 },
  { name: '土木建筑', value: 12242 },
  { name: '旅游', value: 8011 },
  { name: '农林牧渔', value: 7709 },
  { name: '文化艺术', value: 7639 },
  { name: '医药卫生', value: 6412 },
  { name: '资源环境与安全', value: 5121 },
  { name: '生物与化工', value: 4404 },
  { name: '食品药品与粮食', value: 4235 },
  { name: '公共管理与服务', value: 3591 },
  { name: '能源动力与材料', value: 2182 },
  { name: '公安与司法', value: 1537 },
  { name: '轻工纺织', value: 1216 },
  { name: '新闻传播', value: 1073 },
  { name: '水利', value: 823 },
];


interface IProps {
  customOption?: (
    opt: EChartOption<EChartOption.Series>
  ) => EChartOption<EChartOption.Series>;
  style?: any;
  yyData: any;
}

export default function Index ({ yyData, customOption, style }: IProps) {
  const history = useHistory()
  var color = [
    '#8f3ffd',
    '#6e3ffd',
    '#5757f2',
    '#4467f4',
    '#488bf0',
    '#448ff4',
    '#38baa8',
    '#38ba76',
    '#c7b30d',
    '#e59d27',
    '#e55b26',
    '#ee167b',
  ];
  var xdata = [
    "财务管理决策实训",
    "商品流通业实训",
    "暖心陪伴（津乐园20cm定制蛋糕）",
    "嘉果荟萃（津乐园20cm定制蛋糕）",
    "优雅圆舞曲（津乐园20cm）",
    "巧克力之夏（津乐园20cm定制蛋糕）",
    "财税宝4G",
    "成本会计",
    "纳税会计与筹划",
    "金融担保业实训",
  ];

  const Options: EChartOption<EChartOption.Series> = {
    color: color,
    legend: {
      type: 'scroll',
      orient: "vartical",
      x: "left",
      top: "center",
      left: "40%",
      bottom: "0%",
      data: xdata,
      itemWidth: 8,
      cursor: 'de',
      itemHeight: 8,
      itemGap: 8,
      textStyle: {
        color: "#fff",
      },
      formatter: function (name) {
        return "" + name;
      },
    },
    series: [
      {
        cursor: 'auto',
        type: "pie",
        clockwise: false, //饼图的扇区是否是顺时针排布
        minAngle: 2, //最小的扇区角度（0 ~ 360）
        radius: ["60%", "40%"],
        center: ["20%", "50%"],
        avoidLabelOverlap: false,
        itemStyle: {
          //图形样式
          // normal: {
          //     borderColor: "#08c",
          //     borderWidth: 6,
          // },
        },
        label: {
          normal: {
            show: false,
            position: "center",
            formatter: "{text|{b}}\n{c}",
            rich: {
              text: {
                color: "#fff",
                fontSize: 14,
                align: "center",
                verticalAlign: "middle",
                padding: 8,
              },
              value: {
                color: "#fff",
                fontSize: 24,
                align: "center",
                verticalAlign: "middle",
              },
            },
          },
          emphasis: {
            type: 'scroll',
            show: true,
            textStyle: {
              fontSize: 14,
            },
          },
        },
        data: yyData,
      },
    ],
  };
  const opt = useMemo(() => {
    return {
      ...Options,
      legend: {
        ...Options.legend, data: yyData?.map(item => {
          const str = item.name
          return str
        })
      },
      textStyle: {
        color: '#ffffff'
     }
    };
  }, [yyData]);
  return (
    <div className={Style.root}>
      <ReactEcharts onEvents={{ "click": () => history.push("/major/main") }} style={style} theme={"theme"}
        option={opt} />
    </div>
  )
}
