import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import {
    findCityListBySubjectBigCode,
    findSubjectBigList,
    get1XMS,
    get1xNum,
    getCountrySgNum,
    getCSXS,
    getDoubleLevelList,
    getSGMX,
    getsgNum,
    getSubjectKindNum, getXdxtzsdCountNum,
    ICityCountInfo,
    IDataTypes,
    INewDataTypes,
    ISchoolInfo,
    ISubjectItem,
} from "../../../../service/chart";
import cityDatasPage2 from "../../../../assets/datas/cityDatas2";
import Province from "../Province/ProviceSearch";
import { message } from "antd";
import { errorMessage } from "../../../../lib/utils";
import cityCoordData from "../../../../assets/cityCoord";
import useRequestFn from "../../../../lib/useRequestFn";
import { Select } from "antd";
import {
    findCityListBySubjectName,
    findFilterSubjectName,
} from "../../../../service/major";
import SecondScreen from "../SecondScreen";
import { getSubjectCountNum } from "../../../../service/report";
import { getNormalData } from "../../../../service/basic";
import useDevice from "../../../../lib/useDevice";

interface IProps {
    show: boolean;
    showTimeLine: boolean;
    width: any;
    height: number;
    type: number;
    changeType: (type: number) => void;
}

export default function SearchScreen ({ showTimeLine, width, height, type, show, changeType }: IProps) {
    const [subject, setSubject] = useState("");
    const { isMobile } = useDevice();
    const [subjectsList] = useRequest(() => findFilterSubjectName(""));
    const [subjectData] = useRequest(
        () => findCityListBySubjectName(subject),
        {},
        [subject]
    );

    const [data, setData] = useState < INewDataTypes[] > ([]);
    const [typesData, setTypeData] = useState < string[] > (["专业"]);
    const [schoolList, setSchoolList] = useState < ISchoolInfo[] > ([]);

    const [blueCountData] = useRequest(() => getNormalData < any > ("0a3632ed-ad28-4e0a-b4ca-0ba6d4c0983f", { subjectName: subject }), {}, [subject]); // 辽宁省地图-专业布点数-地图
    const [redCountData] = useRequest(() => getNormalData < any > ("d8e6cd74-b0a8-4c84-8570-f6105ff6d978", { subjectName: subject }), {}, [subject]); // 辽宁省地图-省级双高-地图
    const [first] = useRequest(() => getNormalData < any > ("5968d9d8-eab9-494c-b282-23ad0ebdb197", { subjectName: subject }), {}, [subject]); // 辽宁省地图-专业种类数-地图
    const [four] = useRequest(() => getNormalData < any > ("dbaf0e72-753c-462e-aa53-022227654f7b", { subjectName: subject }), {}, [subject]); // 辽宁省地图-国家级双高-地图
    const [five] = useRequest(() => getNormalData < any > ("e5b46f0b-c15a-4e21-ab0b-3a8b59174f8a", { level: '国家级' }), {}, ['国家级']); // 辽宁省地图-国家级双高-地图
    const [six] = useRequest(() => getNormalData < any > ("e5b46f0b-c15a-4e21-ab0b-3a8b59174f8a", { level: '省级' }), {}, ['省级']);; // 辽宁省地图-国家级双高-地图
    const { Option } = Select;
    // const [five] = useRequest(() => getXdxtzsdCountNum('国家级', subject), {}, [subject])
    // const [six] = useRequest(() => getXdxtzsdCountNum('省级', subject), {}, [subject])

    const showList = useMemo(() => {
        if (type === 1) return first
        if (type === 2) return blueCountData
        if (type === 3) return redCountData
        if (type === 4) return four
        if (type === 5) return five
        if (type === 6) return six
    }, [blueCountData, first, four, redCountData, type])

    useEffect(() => {
        if (subjectData)
        {
            setData(formatData(subjectData));
        }
    }, [subjectData]);

    function formatData (data: ICityCountInfo[]) {
        const res = data.map((item) => {
            return {
                name: item.name,
                coord: cityCoord(item.name),
                value: parseInt(item.value),
                circleColor: "#f00",
                areaColor: "#f8f8f8",
                year: "专业",
            };
        });

        return buchongData(res);
    }

    function buchongData (data: INewDataTypes[]) {
        // 后台不存在的城市要补充上
        const cityArr = data.map((item) => item.name);
        const res = cityCoordData.filter(
            (item) => !cityArr.includes(item.name)
        );
        return [
            ...data,
            ...res.map((item) => {
                return {
                    name: item.name,
                    coord: cityCoord(item.name),
                    value: 0,
                    circleColor: "#f00",
                    areaColor: "#f8f8f8",
                    year: "专业",
                };
            }),
        ];
    }

    function cityCoord (cityName: string) {
        const cityInfo = cityCoordData.filter((item) => item.name === cityName);
        if (cityInfo.length === 0) return [];
        return cityInfo[0].coord;
    }


    //点击地图查看列表
    function getSchoolList (val: string, level: string) {
        if (!level)
        {
            if (type == 1)
            {
                getNormalData < any > ("6588e56a-9a9e-11ec-ba43-005056958024", { areaCode: val, subjectName: subject, sg: '' }) // 辽宁省地图-国家级双高-弹出列表
                    .then(data => {
                        setSchoolList(data);
                    });
                return
            }
            if (type == 2)
            {
                getNormalData < any > ("a088ee6b-9a97-11ec-ba43-005056958024", { areaCode: val, subjectName: subject, sg: '' }) // 辽宁省地图-国家级双高-弹出列表
                    .then(data => {
                        setSchoolList(data);
                    });
                return
            }
            if (type >= 5)
            {
                getNormalData < any > ("eabfcd76-8290-4f81-9008-02f976bdb3c6", { areaCode: val, level: type == 6 ? '省级' : '国家级' }) // 辽宁省地图-国家级双高-弹出列表
                    .then(data => {
                        setSchoolList(data);
                    });
            } else
            {
                getNormalData < any > ("7b6ea100-d26c-4fbe-b9f2-e72ba74a2017", { areaCode: val, subjectName: subject, sg: type == 3 ? '省级' : type == 4 ? '国家级' : '' }) // 辽宁省地图-国家级双高-弹出列表
                    .then(data => {
                        setSchoolList(data);
                    });
            }

        }
        if (level)
        {
            if (level === "国家级")
            {
                getSGMX(val, subject).then((data) => {
                    setSchoolList(data);
                });
            }

            if (level === "省级")
            {
                get1XMS(val, subject).then((data) => {
                    setSchoolList(data);
                });
            }
        }
    }

    function onchange (val: string) {
        changeType(2)
        setSubject(val);
    }

    function onClean () {
        // 清除弹出层表单数据
        setSchoolList([]);
    }

    return (
        <div style={{ position: "relative" }}>
            {data && typesData && (
                <>
                    <div style={{ textAlign: "center", marginLeft: isMobile ? 0 : 150, position: isMobile ? 'relative' : "absolute", zIndex: 2 }}>
                        {
                            show ? <Select
                                showSearch
                                size={"large"}
                                placeholder="请输入专业"
                                style={{ width: isMobile ? '100%' : 300, textAlign: "left" }}
                                onChange={onchange}
                                allowClear
                            >
                                {subjectsList?.map((item, index) => {
                                    return (
                                        <Option key={index} value={(item === '全部专业') ? '' : item}>
                                            {item}
                                        </Option>
                                    );
                                })}
                            </Select> : ''
                        }
                    </div>
                    {/* <SecondScreen showTimeLine={false} width={600} height={700} /> */}
                    <Province
                        name="辽宁"
                        tableType={2}
                        width={width}
                        height={height}
                        data={data}
                        year={typesData}
                        type={type}
                        geoColor="0, 167, 237"
                        schoolList={schoolList || []}
                        showTimeLine={showTimeLine}
                        changeCityName={(val, levelName) =>
                            getSchoolList(val, levelName)
                        }
                        pointsBlue={blueCountData?.map((item: any) => {
                            return {
                                areaCode: item.name,
                                schCount: item.value,
                            };
                        })}
                        pointsRed={showList?.map((item: any) => {
                            return {
                                areaCode: item.name,
                                schCount: item.value,
                            };
                        })}
                        onClean={onClean}
                    // schoolListSpecial={schoolListSpecial || []}
                    />
                </>
            )}
        </div>
    );
}

SearchScreen.defaultProps = {
    showTimeLine: true,
    width: 960,
    height: 800,
};
