import React from "react";
import classNames from "classnames";
import style from "./index.module.scss";
import Statics from "./Components/Statics";
import BlockLeft1 from "./Components/BlockLeft1";
import Zhongdian from "../School/Zhongdian";
import BlockLeft3 from "./Components/BlockLeft3";
import MiddleBlock2 from "./Components/MiddleBlock2";
import BlockRight1 from "./Components/BlockRight1";
import BlockRight2 from "./Components/BlockRight2";
import BlockRight3 from "./Components/BlockRight3";
import MiddleBlock1 from "./Components/MiddleBlock1";
import { useHistory } from "react-router";

export default function PageChats() {

    const history = useHistory()

    return (
        <div className={style.root}>
            <div className={style.container}>

                <div className={style.header}>

                    <div className={style.buttons}>
                        <button className={classNames(style.btn, style.btnActive)}><span>高职部分</span></button>
                        <button className={classNames(style.btn)}><span>中职部分(建设中)</span></button>
                    </div>
                </div>

                <div className={style.main}>
                    <div className={style.left}>
                        <div className={classNames(style.leftBlock1, style.noBorder, style.noCorner)}>
                            <div className={style.blockInner} style={{height: 300}}>
                                <BlockLeft1 />
                            </div>
                        </div>

                        <div className={style.leftBlock2}>
                            <div className={style.blockInner} style={{height: 250}}>
                                <h1 onClick={() => history.push("/major/main")} className={style.blockHeader}>专业建设情况</h1>
                                <Zhongdian style={{height: "200px", marginTop: 30}}/>
                            </div>
                        </div>

                        <div className={style.leftBlock3}>
                            <div className={style.blockInner} style={{height: 260}}>
                                <BlockLeft3 />
                            </div>
                        </div>
                    </div>

                    <div className={style.middle}>
                        <div className={classNames(style.middleBlock1, style.noBorder)}>
                            <div className={style.blockInner} style={{height: 554}}>
                                <Statics />
                                <MiddleBlock1 />
                            </div>
                        </div>

                        <div className={style.middleBlock2}>
                            <div className={style.blockInner} style={{height: 260}}>
                                <h1 className={style.blockHeader}>按城市查看</h1>
                                <MiddleBlock2 />
                            </div>
                        </div>
                    </div>

                    <div className={style.right}>
                        <div className={classNames(style.rightBlock1, style.noBorder, style.noCorner)}>
                            <div className={style.blockInner} style={{height: 300, padding: "10px 20px"}}>
                                <BlockRight1 />
                            </div>
                        </div>

                        <div className={classNames(style.rightBlock2, style.noBorder)}>
                            <div className={style.blockInner} style={{height: 250}}>
                                <BlockRight2 />
                            </div>
                        </div>

                        <div className={style.rightBlock3}>
                            <div className={style.blockInner} style={{height: 260}}>
                                <BlockRight3 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
