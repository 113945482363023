import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import Img from "./images/img.png";
import plusImg from "./images/plus.png";
import closeImg from "./images/close.png";
import { Button, Modal } from "antd";
import FangAnSelector from "./components/FangAnSelector";
import { Link, useHistory } from "react-router-dom";
import ShowText from "../CoursesCompare/components/ShowText";
import useRequestFn from "../../../lib/useRequestFn";
import { getCourseTrainingProgram, PyfaPKoneRet, pyfaPKone } from "../../../service/training";
import useQuery from "../../../lib/useQuery";
import classnames from "classnames/bind";
import { toInt, toNumber } from "../../../lib/utils";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import { any } from "underscore";
import { add, assign } from "lodash";
import { Spin } from 'antd';

const cNames = classnames.bind(style);

interface IDataType extends PyfaPKoneRet {
  other: any;
}

export default function PeiyangfanganCompare () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "培养方案对比" }]);

  const { subjectId, school } = useQuery();
  const [data, setData] = useState < (IDataType | null)[] > ([null, null, null, null]);
  const [index, setIndex] = useState(-1);
  const history = useHistory();
  // const [getData] = useRequestFn(pyfaPKone);
  const [getData] = useRequestFn(getNormalData)//PK 培养方案对比(pyfaPKone)

  useEffect(() => {
    if (subjectId)
    {
      getData('d15bcf80-6d8c-406d-b61d-5c488e08335c', { subject_id: subjectId })
      onAdd({ subjectId, school }, 0);
    }

  }, [])

  function onClose (index: number) {
    if (data.length === 4)
    {
      data[index] = null;
      setData([...data]);
    } else
    {
      data.splice(index, 1);
      setData([...data]);
    }
  }

  function queueIndex () {
    return data.findIndex((item, index) => {
      return item === null;
    });
  }



  const [getData1, pyfaPKone] = useRequestFn(getNormalData)
  const [getData2, findMajorAbilityPro] = useRequestFn(getNormalData)
  const [getData3, vocationLevelCertsPro] = useRequestFn(getNormalData)
  const [getData4, ohourseScorehzDataPro] = useRequestFn(getNormalData)
  const [getData5, subjectCoreCoursesPro] = useRequestFn(getNormalData)
  const [loading, setLoading] = useState(false)
  async function onAdd (select: any, index: number) {
    setLoading(true)
    let res = await getData1 < any > ('d15bcf80-6d8c-406d-b61d-5c488e08335c', { subject_id: select.subjectId })
    let findMajorAbilityPro = await getData2 < any > ('ef94bae8-5ceb-450d-8d6e-cdaf70899a00', { subjectId: select.subjectId })
    let vocationLevelCertsPro = await getData3 < any > ('81892e2f-8bfc-477d-b108-b418d8263b2d', { subjectId: select.subjectId })
    let ohourseScorehzDataPro = await getData5 < any > ('238f6a8c-468a-4fc4-a5f3-c94305957dbd', { subjectId: select.subjectId })
    let subjectCoreCoursesPro = await getData5 < any > ('ec897d64-7b7a-4df5-880a-02796a1aabb7', { subjectId: select.subjectId })
    if (res)
    {
      setLoading(false)
      data[index] = {
        ...res,
        other: select,
        subjectId: subjectId,
        majorAbility: findMajorAbilityPro.majorAbilitys.length,
        vocationLevelCerts: vocationLevelCertsPro ? vocationLevelCertsPro.vocationLevelCerts.length : null,
        ohourseScorehzData: ohourseScorehzDataPro ? ohourseScorehzDataPro.jjsjk : null,
        subjectCoreCoursesNum: subjectCoreCoursesPro ? subjectCoreCoursesPro.subjectCoreCourses.length : null,
      }
    } else
    {
      setLoading(true)
    }
    // const res = await getData('d15bcf80-6d8c-406d-b61d-5c488e08335c', { subject_id: select.subjectId })
    // data[index] = Object.assign(res, select);

    setData([...data]);
  }


  const [showPop, setShowPop] = useState(false);

  function onClick (index?: any) {

    if (typeof index === "number")
    {
      setIndex(index);
    } else
    {
      const res = queueIndex();
      if (res === -1)
      {
        setIndex(data.length);
        setData([...data, null]);
      } else
      {
        setIndex(res);
      }
    }
    setShowPop(true);
  }

  return <div className={style.root}>
    <div className={style.main}>
      <div className={style.wrapper} style={{ width: `${(data.length + 1) * 221}px` }}>
        <div className={`${style.item} ${style.itemSpecial}`}>
          <div className={style.header}>专业培养方案</div>
          <div className={style.description}>对比信息</div>
        </div>
        {data.map((item: any, index: number) => (
          <div key={index} className={`${style.item} ${item ? '' : style.noInfo}`}>
            {item !== null && (
              <>
                <div className={style.school}>
                  {item.school}
                </div>

                <div className={style.major}>
                  <Link to={`/training/trainPlan?subjectId=${item.subjectId}`}>
                    {item?.subject_name} {item?.subject_direction && <span>({item?.subject_direction})</span>}
                  </Link>
                </div>
              </>
            )}

            {item === null && <img src={Img} alt="" />}
            <div className={style.btnWrapper}>
              <Button className={style.selectBtn} onClick={() => onClick(index)}>选择专业</Button>
            </div>
            {item !== null && <div className={style.closeBtn} onClick={() => onClose(index)}>
              <img src={closeImg} alt="" />
            </div>}
          </div>))}
      </div>

      {/* 基本情况 */}
      <Spin tip="Loading..." spinning={loading}>
        <table className={style.table} style={{ width: `${(data.length + 1) * 221}px` }}>
          <tbody>
            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  基本规范
                </div>
              </td>
            </tr>
            <tr>
              <td className={`${style.TName}`}>专业名称</td>
              {data.map((item, index) => (
                <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item?.subject_name} {item?.subject_direction && <span>({item?.subject_direction})</span>}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${style.TName}`}>专业代码</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.subject_code}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>入学要求</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.in_requirements || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>修业年限</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.rest_year ? `${item?.rest_year}年` : ""}</td>
              })}
            </tr>

            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  专业人才需求分析
                </div>
              </td>
            </tr>

            <tr>
              <td className={`${style.TName}`}>行业背景分析</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.background || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业人才需求分析</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.zyrcxq || ""} max={50} />
                </td>
              })}
            </tr>

            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  培养目标和职业面向
                </div>
              </td>
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业大类</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.subject_big}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>专业类</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.subject}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>对应行业</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.ndustry}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>主要职业类别</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item !== null && <div>
                    {item?.occupation_type}
                  </div>}
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>主要岗位类别（或技术领域）</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item !== null && <div>
                    {item?.occupation_type}
                  </div>}
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>"1+x"证书、职业资格证书或技能等级证书举例</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.onex_certificate || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>岗位能力数量</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item?.majorAbility}
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>职业技能等级能力证书数量</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item?.vocationLevelCerts}
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>培养目标</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.train_target !== item?.train_target)
                return <td key={index} className={`${style.TItem} `} style={{ color: notMatch ? "#ff0000" : "" }}>
                  <ShowText text={item?.train_target || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>培养规格-素质</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.pygg_sz || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>培养规格-知识</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.pygg_zs || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>培养规格-能力</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.pygg_nl || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>人才培养模式</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.train_model !== item?.train_model)
                return <td key={index} className={`${style.TItem}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                  <ShowText text={item?.train_model || ""} max={50} />
                </td>
              })}
            </tr>

            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  课程体系
                </div>
              </td>
            </tr>

            <tr>
              <td className={`${style.TName}`}>课程体系结构</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.course_system !== item?.course_system)
                return <td key={index} className={`${style.TItem}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                  <ShowText text={item?.course_system || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>教学进程表</td>
              {data.map((item, index) => (
                <td key={index} className={`${style.TItem} ${style.fontStyle}`}>
                  {item?.progress && <a href={item?.progress} target="_blank" rel="noreferrer">[下载]</a>}
                </td>
              ))}
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业教学计划</td>
              {data.map((item, index) => {
                return <td key={index} className={cNames("TItem", "fontStyle")}>
                  {item?.subjectId && (
                    <div>
                      共: {item?.courseName.length}门课程  点击 <Link to={`/training/trainPlan?subjectId=${item?.subjectId}&tab=4`} target="_blank">查看详细</Link>
                    </div>
                  )}
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>公共基础必修课学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.ggjcbx.ggjcbx_xf !== item?.ohourseScorehzData?.ggjcbx.ggjcbx_xf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                    {item?.ohourseScorehzData?.ggjcbx.ggjcbx_xf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业必修课学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.zybx.zybx_xf !== item?.ohourseScorehzData?.zybx.zybx_xf)
                return (<td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                  {item?.ohourseScorehzData?.zybx.zybx_xf}
                </td>)
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业限选课学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.zyxx.zyxx_xf !== item?.ohourseScorehzData?.zyxx.zyxx_xf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                    {item?.ohourseScorehzData?.zyxx.zyxx_xf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>公共任选课学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.ggrx.ggrx_xf !== item?.ohourseScorehzData?.ggrx.ggrx_xf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                    {item?.ohourseScorehzData?.ggrx.ggrx_xf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>理论课程学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.llk.llkxf !== item?.ohourseScorehzData?.llk.llkxf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#ff0000" : "" }}>
                    {item?.ohourseScorehzData?.llk.llkxf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>理论+实践课程学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.llksjk.llksjkxf !== item?.ohourseScorehzData?.llksjk.llksjkxf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#f00" : "" }}>
                    {item?.ohourseScorehzData?.llksjk.llksjkxf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>集中实践性课程学分数</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.jjsjk.jjsjkxf !== item?.ohourseScorehzData?.jjsjk.jjsjkxf)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#f00" : "" }}>
                    {item?.ohourseScorehzData?.jjsjk.jjsjkxf}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>理论学时: 实践学时</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.ohourseScorehzData?.lljxxs_sjjxxs_rate !== item?.ohourseScorehzData?.lljxxs_sjjxxs_rate)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#f00" : "" }}>
                    {item?.subjectId && <span>1: {item?.ohourseScorehzData?.lljxxs_sjjxxs_rate}</span>}
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>专业核心课程数量</td>
              {data.map((item, index) => {
                const notMatch = data.filter(some => some).some(some => some?.subjectCoreCoursesNum !== item?.subjectCoreCoursesNum)
                return (
                  <td key={index} className={`${style.TItem} ${style.fontStyle}`} style={{ color: notMatch ? "#f00" : "" }}>
                    {item?.subjectCoreCoursesNum}
                  </td>
                )
              })}
            </tr>

            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  实施保障
                </div>
              </td>
            </tr>

            <tr>
              <td className={`${style.TName}`}>双师占比</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.sszb}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>专职教师占比</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.zzjjzb}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>校内实训室数量和</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.jjslh}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>校内实训室工位数</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{toInt(item?.sxsgws) || ""}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>校外实训基地个数</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.xwsxjdgs}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>教学资源</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.resource || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>教学方法</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.teach_method || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>学习评价</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.evaluate || ""} max={50} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>质量管理</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.quality_manager || ""} max={50} />
                </td>
              })}
            </tr>

            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  毕业要求
                </div>
              </td>
            </tr>

            <tr>
              <td className={`${style.TName}`}>毕业要求</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.by_req || ""} max={50} />
                </td>
              })}
            </tr>
          </tbody>
        </table>
      </Spin>
      {/* end 基本情况 */}


    </div>

    <div className={style.addBtn} onClick={() => onClick()} >
      <img src={plusImg} alt="" />添加专业
    </div>

    <FangAnSelector visible={showPop} onSelect={(val) => onAdd(val, index)} onClose={() => setShowPop(false)} />
  </div>
}
