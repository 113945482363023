import ReactEcharts from "echarts-for-react";
import React, { useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { Modal } from "antd";
import DetailChart from "./detail";
import { sankeyDiagramIndustryToSubjectClass, SankeyValues } from "../../../../../service/major";

import doNotRerender from '../../../../../lib/doNotReRender'
import { getNormalData } from "../../../../../service/basic";
import { SingleAxisChart } from "../../../../../service/chart";
import useRequestFn from "../../../../../lib/useRequestFn";
const PureReactEcharts = doNotRerender(ReactEcharts)

const colors = [
  '#78A3CE',
  '#FBC2EB',
  '#A18CD1',
  '#C2E9FB',
  '#A1C4FD',
  '#D4FC79',
  '#96E6A1',
  '#FCC687',
  '#F28D86',
  '#F286A0',
  '#33876A',
  '#B5BF6E',
  '#2979F1',
  '#F08F1B',
  '#57B956',
  '#ABA5EA',
  '#9BC46C',
  '#30E0E0',
  '#F286CA',
  '#A6F286',
  '#D66161',
  '#8FF379',
  '#1CBDB4',
  '#EEF2F3',
  '#8E9EAB',
  '#BB9BF1',
  '#887BF2',
  '#7FFED8',
  '#09BDFE',
  '#F2E786',
]

export default function Chart1 () {
  // const [ data1 ] = useRequest(sankeyDiagramIndustryToSubjectClass);
  const [data] = useRequest(() => getNormalData < SankeyValues > ("af8ddc61-7dbd-4b69-aa8e-c88981969d5f")); // 产业在校生供给情况
  const [modalId, setModalId] = useState(null)
  const [type, setType] = useState < "1" | "2" > ("1")

  const option: any = {
    // grid: {
    //     top: 0,
    //     bottom: 0
    // },
    backgroundColor: '#FFFFFF',
    series: [
      {
        top: 20,
        left: 0,
        bottom: 20,
        type: 'sankey',
        focusNodeAdjacency: 'allEdges',
        data: data?.data.map((item, index) => ({
          ...item,
          itemStyle: {
            color: colors[index % 20]
          }
        })) || [],
        links: data?.links.map(item => ({
          ...item,
          source: item.target,
          target: item.source,
          lineStyle: {
            normal: { opacity: 0.05 }
          }
        })) || [],
        lineStyle: {
          color: 'source',
          curveness: 0.5
        },
        // label: {
        //     color: 'rgba(0,0,0,0.7)',
        //     fontFamily: 'Arial',
        //     fontSize: 10
        // }
      }],

    tooltip: {
      formatter: (params: any) => {
        return `专业布点数：${params.data.value || params.value}<br/>年供给人数：${params.data.people}`
      }
    },
  };

  //服务业行业对应
  const [Subject1, sub1] = useRequestFn(getNormalData)
  const [Subject2, sub2] = useRequestFn(getNormalData)
  const [options1, setOption1] = useState < any[] > ([])
  const [options2, setOption2] = useState < any[] > ([])
  async function sub (val: any) {
    let res: any = val.data.deep === "2" ? await Subject1("d14ca243-747c-4fd7-90a0-e12ab6d4e2f3", { name: val.name }) : await Subject2("b647ac35-99a5-4d7c-9ab9-d8df7a925e92", { name: val.name })
    let arr: any = []
    let arr1: any = []
    res.data.map((item: any, index: number) => {
      arr.push({
        ...item,
        itemStyle: {
          color: colors[index % 20]
        }
      })
    })
    res.links.map((item: any, index: number) => {
      arr1.push({
        ...item,
        source: item.target,
        target: item.source,
        lineStyle: { normal: { opacity: 0.05 } }
      })
    })

    setOption1(arr)
    setOption2(arr1)

  }
  const handleClick = (event: any) => {

    if (event.data.deep === "2")
    {
      setModalId(event.data.name)
      setType("1")
      sub(event)
    }
    if (event.data.deep === "1")
    {
      setModalId(event.data.name)
      setType("2")
      sub(event)
    }
  }

  return (
    <div >
      <Modal destroyOnClose width={1200} onCancel={() => setModalId(null)} footer={null} visible={!!modalId} title={`${modalId}行业对应一览表`}>
        <DetailChart type={type} name={modalId || ""} datas={options1} links={options2} />
      </Modal>
      {data && <PureReactEcharts onEvents={{ "click": handleClick }} option={option} notMerge={true} lazyUpdate={true} style={{ height: 2600, cursor: 'default' }} />}
    </div>
  );
}
