import { Col, Row } from "antd";
import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { ILevelCerts, IMajorAbility, ISubjectTrainBaseInfo } from "../../../../../service/training";
import useDevice from "../../../../../lib/useDevice";

interface BaseProps {
  data?: ISubjectTrainBaseInfo;
  levelCertsList?: ILevelCerts[];
  abilityList?: IMajorAbility[];
}

export default function Educate ({ data, levelCertsList, abilityList }: any) {
  const { isMobile } = useDevice()
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline}></div>
          <h1>1.职业面向</h1>
        </div>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>所属专业大类</div>
              <div className={style.content}>{data?.subjectBigName}</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>所属专业类</div>
              <div className={style.content}>{data?.subjectTypeName}</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>对应行业</div>
              <div className={style.content}>
                {data?.industry1Name}/
                {data?.industry2Name}/
                {data?.industry3Name}
              </div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>主要职业类别</div>
              <div className={style.content}>
                {data?.occupationType1Name}/{data?.occupationType2Name}/{data?.occupationType3Name}
              </div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>主要岗位类别(或技术领域)</div>
              <div className={style.content}>
                {data?.mainGwType || 0}
              </div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>"1+x"证书、职业资格证书或技能等级证书举例</div>
              <div className={`${style.content} ${style.largecontent}`}>{data?.["1xCertificate"]}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline}></div>
          <h1>2.岗位能力分析</h1>
        </div>
        <div className={style.table}>
          <table>
            <tbody>
              <tr>
                <th className={style.tablewid}>岗位名称</th>
                <th className={style.tablewid}>岗位类别</th>
                <th>岗位描述</th>
                <th>职业能力要求</th>
              </tr>
              {
                abilityList?.map((item: any, index: number) => {
                  return <tr key={index}>
                    <td>{item.postName}</td>
                    <td>{item.postTypeName}</td>
                    <td>{item.postInfo}</td>
                    <td>{item.majorAbility}</td>
                  </tr>
                })
              }

            </tbody>
          </table>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline} />
          <h1>3.职业技能等级证书</h1>
        </div>
        <div className={style.table}>
          <table>
            <tbody>
              <tr>
                <th>“1+x”证书或职业技能等级证书</th>
                <th>颁证单位</th>
                <th>等级</th>
                <th>备注</th>
              </tr>
              {
                levelCertsList?.map((item: any, index: number) => {
                  return <tr key={index}>
                    <td>{item.certificate}</td>
                    <td>{item.company}</td>
                    <td>{item.levelName}</td>
                    <td>{item.info}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline} />
          <h1>4.培养目标</h1>
        </div>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={24}>
              <div className={`${style.content} ${style.largecontent}`}>{data?.trainTarget}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline} />
          <h1>5.培养规格</h1>
        </div>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>5-1 规格</div>
              <div title={data?.pyggGg} className={`${style.content} ${style.largecontent}`}>{data?.pyggGg}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>5-1 素质</div>
              <div title={data?.pyggsz} className={`${style.content} ${style.largecontent}`}>{data?.pyggsz}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>5-3 知识</div>
              <div title={data?.pyggzs} className={`${style.content} ${style.largecontent}`}>{data?.pyggzs}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>5-4 能力</div>
              <div title={data?.pyggnl} className={`${style.content} ${style.largecontent}`}>{data?.pyggnl}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline} />
          <h1>6.专业人才培养模式</h1>
        </div>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={24}>
              <div title={data?.trainModel} className={`${style.content} ${style.largecontent}`}>{data?.trainModel}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
