import styles from "./index.module.scss"
import {useMemo, useState,useEffect} from "react";
import classNames from "classnames";
import Bar from "../Bar";
import useRequestFn from "../../../../../../../lib/useRequestFn";
import {getNormalData} from "../../../../../../../service/basic";
import useRequest from "../../../../../../../lib/useRequest";
import {toNumber} from "../../../../../../../lib/utils";
import Table3 from "../Table3"
import TitleCount from "../TitleCount"
interface Block1Props {
    id: string;
    file: string,
    titleName: any;
}


export default function Block1(props: Block1Props) {
    const {file, titleName, id} = props
    console.log(props)
    const [code,setCode] = useState('A')
    // const [file,setFile] = useState('page_3')
    const [data] = useRequest(() => getNormalData < any > ('99f077e1-c1f1-11ec-ba43-005056958024',{code,file}), {}, [file,code])//产业人才对接统计表

    useEffect(()=>{
        setCode('A')
    },[file])

    return (
        <div className={classNames(styles.root, styles[id])}>

            <div className={styles.left}>
                {
                    titleName&&titleName.map((el: any) => {
                        return(
                            <h1 key={el.id} className={classNames(styles.title, {[styles.active]: code === el.code})}
                                onClick={() => setCode(el.code)}>{el.name}</h1>

                        )
                    })
                }
                {/* <h1 className={classNames(styles.title, {[styles.active]: code === 'A'})} onClick={() => setCode('A')}>改造升级“老字号”</h1>
                <h1 className={classNames(styles.title, {[styles.active]: code === 'B'})} onClick={() => setCode('B')}>深度开发“原字号”</h1>
                <h1 className={classNames(styles.title, {[styles.active]: code === 'C'})} onClick={() => setCode('C')}>培育壮大“新字号”</h1> */}
            </div>

            <div className={styles.right} >
                <TitleCount code={code} file={file}/>
                <Table3 list={data} code={code} file={file}/>
            </div>
                {/* <div className={styles.middle}>
                {current === 31 && (
                    <div>汽车产业链、数控机床产业链、输变电装备产业链、燃气轮机产业链、压缩机产业链、船舶与海洋工程装备产业链</div>
                )}

                {current === 32 && (
                    <div>烯烃产业链、芳烃产业链、精细化工产业链、高品质钢铁材料产业链、先进有色金属材料产业链、菱镁产业链</div>
                )}

                {current === 33 && (
                    <div>培育壮大“新字号”：机器人产业链、航空装备产业链、集成电路产业链、生物医药产业链、先进医疗装备产业链、氢能产业链</div>
                )}
            </div>

            <div className={styles.right}>
                {(list || []).map((item: any) => <Bar title={item['专业名称']} count={`${item['毕业生数']}人`} position={toNumber(item['毕业生数']) / maxNumber} />)}
            </div> */}
        </div>
    )
}
