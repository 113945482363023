import style from "./index.module.scss";
import {Modal, Table, Popover} from "antd";
import React, {useRef, useState} from "react";
import useRequest from "../../../../../../../../lib/useRequest";
import {getNormalData, SpecialType} from "../../../../../../../../service/basic";
import InfoCard from "../InfoCard";

interface Props {
    list: SpecialType[];
}

export default function Table1({list}: Props) {
    const [show, setShow] = useState(false);
    const [subjectCode, setSubjectCode] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalData, {loading}] = useRequest(() => getNormalData<any>('2615664f-d1db-11ec-ba43-005056958024', {subject_code: subjectCode}), {}, [subjectCode]);

    const columns = [
        {
            title: '学校名称',
            dataIndex: 'school_name'
        },
        {
            title: '学校编码',
            dataIndex: 'school_code'
        },
    ];

    const okHandler = () => {
        setShow(false);
    };

    const cancelHandler = () => {
        setShow(false);
    };

    const clickHandler = (obj: SpecialType) => {
        setSubjectCode(obj.subject_code);
        setModalTitle(obj.subject_name);
        setShow(true);
    };

    return (
        <div className={`${style.table3}`}>
            <Modal width={700} title={modalTitle} visible={show} onOk={okHandler} onCancel={cancelHandler}>
                <Table className={style.table2} rowKey="ffcy" columns={columns} pagination={false}
                       dataSource={modalData || []} loading={loading}/>
            </Modal>

            {list?.map((item) => <InfoCard key={item.subject_code} data={item} onClick={clickHandler}/>)}
        </div>
    );
}
