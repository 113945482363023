import ChartUuid from "../../../../../components/ChartUuid";
import Widget5 from "../../../School/Widget5";
import ChartCarousel from "../../../Components/ChartCarousel";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import style from "../../index.module.scss";
import Widget7 from "../../../School/Widget7";
import Widget8 from "../../../School/Widget8";
import Widget9 from "../../../School/Widget9";
import doNotReRender from "../../../../../lib/doNotReRender";

// 防止轮播切换是图表闪动
const PureWidget7 = doNotReRender(Widget7);
const PureWidget8 = doNotReRender(Widget8);
const PureWidget9 = doNotReRender(Widget9);

export default function BlockLeft3() {

    const history = useHistory()
    const [title, setTitle] = useState<React.ReactNode>("");

    return (
        <div>
            <h1 className={style.blockHeader}>{title}</h1>

            <ChartCarousel
                title={[
                    <div onClick={() => history.push("/basic/main")}>教师性质分布</div>,
                    <div onClick={() => history.push("/basic/main")}>教师学历分布</div>,
                    <div onClick={() => history.push("/basic/main")}>教师职称分布</div>
                ]}
                hideTitle onTitleChange={setTitle}
            >
                <div>
                    <PureWidget7 style={{height: 260}} />
                </div>

                <div>
                    <PureWidget8 style={{height: 260}} />
                </div>

                <div>
                    <PureWidget9 style={{height: 260}} />
                </div>
            </ChartCarousel>
        </div>
    );
}
