import React, { useEffect, useMemo, useState } from "react";
import style from "../index.module.scss";
import Header from "../components/Header";
import Block from "../components/Block";
import Round1 from "./components/Round1";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import Footer from "../components/Footer";
import Yibiao from "./components/yibiaopan";
import MakePie from "./components/Makeapie";
import Pictograph from "./components/Pictograph/Pictograph";
import Table3 from "./components/Table3"
import Table4 from "./components/Table4"
import you from "../Wall2/image/you.png"
import { getNormalData, Instructor, InstructorDel, NumberPeople } from "../../../service/basic";
import useRequest from "../../../lib/useRequest";
import useRequestFn from "../../../lib/useRequestFn";
import useDevice from "../../../lib/useDevice";

export default function () {
    const { isMobile } = useDevice()
    const [number, setNumber] = useState("0");

    function tabChange (num: string) {
        setNumber(num);
    }

    const [toCount, setToCount] = useState(1);
    const [lastCount, setLastToCount] = useState(1);


    const [type, setType] = useState(true);


    const [data] = useState([
        1426, 1023, 2218, 761, 405
    ])

    const [data2] = useState([
        1226, 823, 1718, 561, 205
    ])
    //获取雷达数据（顶岗实习岗位供给率）
    const [supply] = useRequest(() => getNormalData < any > ('933cb7a8-7927-4cbd-bcb8-cb8d521bd4ca'))

    useEffect(() => {
        if (supply)
        {
            let arr: any = []
            supply.map((item: any) => {
                arr.push(
                    {
                        title: item.name,
                        value: item.value
                    },
                )
            })
            setPieData(arr)
        }
    }, [supply])

    //  传递给雷达图的数据

    let [pieData, setPieData] = useState < any > ([])

    let [pieData1, setPieData1] = useState([
        {
            title: '总体',
            value: '53'
        }, {
            title: '装备制造',
            value: '44'
        }, {
            title: '财经商贸',
            value: '53'
        }, {
            title: '电子信息 ',
            value: '57'
        }, {
            title: '教育与体育',
            value: '45'
        }, {
            title: '交通运输',
            value: '49'
        }, {
            title: '土木建筑',
            value: '39'
        }, {
            title: '旅游',
            value: '30'
        }, {
            title: '农林牧渔 ',
            value: '33'
        }, {
            title: '文化艺术',
            value: '29'
        }, {
            title: '医药卫',
            value: '35'
        }, {
            title: '资源环境与安全',
            value: '44'
        }, {
            title: '生物与化工',
            value: '47'
        }, {
            title: '食品药品与粮食',
            value: '41'
        }, {
            title: '公共管理与服务',
            value: '48'
        }, {
            title: '能源动力与材料',
            value: '51'
        }, {
            title: '公安与司法',
            value: '41'
        }, {
            title: '轻工纺织',
            value: '43'
        }, {
            title: '新闻传播',
            value: '50'
        }, {
            title: '水利',
            value: '110'
        },
    ])

    //  雷达数据2
    let [pieData2, setPieData2] = useState < any > (null)

    let [pieData22] = useState([
        {
            title: '供用电技术',
            value: '14'
        }
        ,
        {
            title: '电力系统自动化技术',
            value: '82'
        },
        {
            title: '分布式发电与微电网技术',
            value: '188'
        },
        {
            title: '电厂热能动力装置',
            value: '23'
        },
        {
            title: '风力发电工程技术',
            value: '21'
        },
        {
            title: '黑色冶金技术',
            value: '24'
        },
        {
            title: '轧钢工程技术',
            value: '31'
        },
        {
            title: '材料工程技术',
            value: '39'
        },
        {
            title: '高分子材料工程技术',
            value: '48'
        },
        {
            title: '光伏材料制备技术',
            value: '48'
        },
        {
            title: '建筑材料检测技术',
            value: '19'
        }
    ])
    //  雷达数据3
    let [pieDel, setPieDel] = useState < any > ([])
    // 顶岗实习需求 start-----------
    // 组件切换标记
    const [flag, setFlag] = useState(false);

    const [middleData, setMiddleData]: any = useState([])

    const [workData] = useState([
        // {
        //   name: '总量',
        //   type: 'line',
        //   data: [86789, 88712, 90176, 96541],
        //   itemStyle: { color: "#fff" }
        // },
        // {
        //   name: '装备制造',
        //   type: 'line',
        //   data: [4471, 4562, 5124, 5678],
        //   itemStyle: { color: "red" }
        // },
        // {
        //   name: '财经商贸 ',
        //   type: 'line',
        //   data: [5321, 5012, 5678, 6631],
        //   itemStyle: { color: "yellow" }
        // }, {
        //   name: '电子信息',
        //   type: 'line',
        //   data: [5721, 5896, 5123, 7890],
        //   itemStyle: { color: "blue" }
        // }, {
        //   name: '教育与体育',
        //   type: 'line',
        //   data: [4531, 4123, 4321, 4712],
        //   itemStyle: { color: "yellowgreen" }
        // }, {
        //   name: '交通运输',
        //   type: 'line',
        //   data: [4912, 4561, 4781, 4912],
        //   itemStyle: { color: "#fff" }
        // }, {
        //   name: '土木建筑',
        //   type: 'line',
        //   data: [3943, 4561, 4123, 4234],
        //   itemStyle: { color: '#ee167b' }
        // }, {
        //   name: '旅游 ',
        //   type: 'line',
        //   data: [3012, 2981, 2612, 2861],
        //   itemStyle: { color: '#666' }
        // }, {
        //   name: '农林牧渔',
        //   type: 'line',
        //   data: [3398, 3211, 2812, 2689],
        //   itemStyle: { color: '#8f3ffd' }
        // }, {
        //   name: '文化艺术 ',
        //   type: 'line',
        //   data: [2981, 3190, 3121, 3412],
        //   itemStyle: { color: '#38baa8' }
        // }, {
        //   name: '医药卫生',
        //   type: 'line',
        //   data: [3598, 3424, 3789, 3712],
        //   itemStyle: { color: '#e55b26' }
        // }, {
        //   name: '资源环境与安全',
        //   type: 'line',
        //   data: [4498, 4512, 5123, 5789],
        //   itemStyle: { color: 'skyblue' }
        // }, {
        //   name: '生物与化工',
        //   type: 'line',
        //   data: [4743, 4512, 4912, 5041],
        //   itemStyle: { color: '#06da90' }
        // }, {
        //   name: '食品药品与粮食 ',
        //   type: 'line',
        //   data: [4198, 4012, 3912, 4123],
        //   itemStyle: { color: '#06da4d' }
        // }, {
        //   name: '公共管理与服务 ',
        //   type: 'line',
        //   data: [4876, 4312, 4912, 5212],
        //   itemStyle: { color: '#daac06' }
        // }, {
        //   name: '能源动力与材料 ',
        //   type: 'line',
        //   data: [5032, 5345, 5561, 5797],
        //   itemStyle: { color: '#da6906' }
        // }, {
        //   name: '公安与司法 ',
        //   type: 'line',
        //   data: [4198, 4005, 3912, 3712],
        //   itemStyle: { color: '#da0606' }
        // }, {
        //   name: '轻工纺织  ',
        //   type: 'line',
        //   data: [4316, 4321, 4567, 4120],
        //   itemStyle: { color: 'rgba(218,6,6,0.27)' }
        // }, {
        //   name: '新闻传播',
        //   type: 'line',
        //   data: [3018, 3198, 3218, 3356],
        //   itemStyle: { color: 'rgba(172,218,6,0.27)' }
        // }, {
        //   name: '水利',
        //   type: 'line',
        //   data: [2987, 3016, 3210, 3013],
        //   itemStyle: { color: 'rgba(6,186,218,0.27)' }
        // }
    ])

    const [workData2] = useState([
        // {
        //   name: '总体',
        //   type: 'line',
        //   data: [4471, 4562, 5124, 5678],
        //   itemStyle: { color: "red" }
        // },
        // {
        //   name: '供用电技术 ',
        //   type: 'line',
        //   data: [1471, 1562, 1124, 1678],
        //   itemStyle: { color: "yellow" }
        // }, {
        //   name: '电力系统自动化技术 ',
        //   type: 'line',
        //   data: [821, 812, 878, 931],
        //   itemStyle: { color: "#daac06" }
        // }, {
        //   name: '分布式发电与微电网技术 ',
        //   type: 'line',
        //   data: [821, 889, 823, 790],
        //   itemStyle: { color: "#daac06" }
        // }, {
        //   name: '电厂热能动力装置 ',
        //   type: 'line',
        //   data: [231, 223, 221, 212],
        //   itemStyle: { color: "yellowgreen" }
        // }, {
        //   name: '风力发电工程技术  ',
        //   type: 'line',
        //   data: [212, 261, 281, 312],
        //   itemStyle: { color: "#da06d6" }
        // }, {
        //   name: '黑色冶金技术  ',
        //   type: 'line',
        //   data: [243, 36, 323, 334],
        //   itemStyle: { color: "rgba(6,52,218,0.79)" }
        // }, {
        //   name: '轧钢工程技术  ',
        //   type: 'line',
        //   data: [312, 281, 242, 261],
        //   itemStyle: { color: "rgba(6,218,119,0.79)" }
        // }, {
        //   name: '材料工程技术  ',
        //   type: 'line',
        //   data: [398, 211, 212, 289],
        //   itemStyle: { color: "rgba(6,211,218,0.79)" }
        // }, {
        //   name: '高分子材料工程技术  ',
        //   type: 'line',
        //   data: [481, 490, 521, 572],
        //   itemStyle: { color: "rgba(6,151,218,0.79)" }
        // }, {
        //   name: '光伏材料制备技术  ',
        //   type: 'line',
        //   data: [398, 424, 489, 492],
        //   itemStyle: { color: "rgba(119,218,6,0.79)" }
        // }, {
        //   name: '建筑材料检测技术  ',
        //   type: 'line',
        //   data: [198, 212, 143, 289],
        //   itemStyle: { color: "rgba(119,218,6,0.79)" }
        // },
    ])



    const setWorkData = (item: any) => {
        setFlag(true);
        setMiddleData(workData2s);
    }


    // 顶岗实习需求 end----------

    const [onSupplyData, supplyData] = useRequestFn(getNormalData)
    const [onSupplyData1, supplyData1] = useRequestFn(getNormalData)
    const [name1, setName1] = useState < any > (null)///保存第一层名称

    //顶岗实习岗位供给率 这里是第二层和第三层数据
    async function onSupply (it: any, val: any) {

        if (val == "总体")
        {
            return
        }
        if (it == 1)
        {
            let res = await onSupplyData < any > ('7a9e7e8a-af1f-4ea5-8f7a-a64cecad08a9', { name: val })
            if (res)
            {

                let arr: any = []
                res.map((item: any) => {
                    arr.push(
                        {
                            title: item.name,
                            value: item.value,
                        }
                    )
                })

                setName1(val)
                setPieData2(arr)
                setCount(2)
            }
        }
        if (it == 2)
        {
            let res = await onSupplyData1 < any > ('2bf9e985-797b-42f3-849c-2e4d10dbb53c', { name: val })
            if (res)
            {
                setPieDel(res)
                setCount(3)
            }
        }
    }

    // 雷达图start ----------
    // 组件切换标记  1 2 3 三次切换对应三次图标
    let [count, setCount]: any = useState(1);
    const changeValue = (item: any, name: string) => {

    }
    // 雷达图end-------------




    // 顶岗实习供给情况 ---- start ----------------

    // 要传递给chart2的数据
    const [sendChartData, setChartData]: any = useState([]);

    // 按钮切换的标记, 如果是false 那么按钮不显示
    const [chartToFlag, setChartToFlag] = useState(false)

    // 数据2
    const [chartData2] = useState([
        {
            name: '总体',
            type: 'line',
            data: [471, 562, 3124, 4678],
            itemStyle: { color: "red" }
        },
        {
            name: '铁路运输 ',
            type: 'line',
            data: [71, 62, 524, 1678],
            itemStyle: { color: "yellow" }
        }, {
            name: '道路运输 ',
            type: 'line',
            data: [21, 42, 378, 931],
            itemStyle: { color: "#daac06" }
        }, {
            name: '城市公共交通 ',
            type: 'line',
            data: [34, 46, 223, 790],
            itemStyle: { color: "#daac06" }
        }, {
            name: '水上运输 ',
            type: 'line',
            data: [43, 53, 121, 212],
            itemStyle: { color: "yellowgreen" }
        }, {
            name: '航空运输  ',
            type: 'line',
            data: [0, 0, 81, 112],
            itemStyle: { color: "#da06d6" }
        }, {
            name: '管道运输  ',
            type: 'line',
            data: [0, 0, 93, 234],
            itemStyle: { color: "rgba(6,52,218,0.79)" }
        }, {
            name: ' 装卸搬运和其他运输  ',
            type: 'line',
            data: [32, 31, 142, 261],
            itemStyle: { color: "rgba(6,218,119,0.79)" }
        }, {
            name: '装卸搬运   ',
            type: 'line',
            data: [42, 51, 112, 189],
            itemStyle: { color: "rgba(6,211,218,0.79)" }
        }, {
            name: ' 运输代理服务  ',
            type: 'line',
            data: [23, 30, 221, 572],
            itemStyle: { color: "rgba(6,151,218,0.79)" }
        }, {
            name: '仓储业  ',
            type: 'line',
            data: [118, 136, 189, 192],
            itemStyle: { color: "rgba(119,218,6,0.79)" }
        }, {
            name: '邮政业  ',
            type: 'line',
            data: [107, 118, 243, 289],
            itemStyle: { color: "rgba(119,218,6,0.79)" }
        }
    ])

    // 数据1
    const [chartData] = useState([
        {
            name: '总体',
            type: 'line',
            data: [36789, 48712, 70176, 86541],
            itemStyle: { color: "red" }
        },
        {
            name: '交通运输、仓储和邮政业 ',
            type: 'line',
            data: [471, 562, 3124, 4678],
            itemStyle: { color: "red" }
        },
        {
            name: '住宿和餐饮业 ',
            type: 'line',
            data: [1321, 1012, 2678, 3631],
            itemStyle: { color: "yellow" }
        }, {
            name: '公共管理',
            type: 'line',
            data: [721, 896, 2123, 3890],
            itemStyle: { color: "blue" }
        }, {
            name: '农、林、牧、渔业 ',
            type: 'line',
            data: [1531, 1123, 1321, 2712],
            itemStyle: { color: "yellowgreen" }
        }, {
            name: '制造业',
            type: 'line',
            data: [4912, 4561, 4781, 4912],
            itemStyle: { color: "#fff" }
        }, {
            name: '土木建筑',
            type: 'line',
            data: [912, 1561, 3781, 4912],
            itemStyle: { color: '#ee167b' }
        }, {
            name: '卫生和社会工作 ',
            type: 'line',
            data: [943, 1561, 2123, 3234],
            itemStyle: { color: '#666' }
        }, {
            name: '居民服务',
            type: 'line',
            data: [1012, 1981, 1612, 2861],
            itemStyle: { color: '#8f3ffd' }
        }, {
            name: ' 建筑业 ',
            type: 'line',
            data: [1398, 2211, 2812, 2689],
            itemStyle: { color: '#38baa8' }
        }, {
            name: '房地产业',
            type: 'line',
            data: [981, 1190, 2121, 2412],
            itemStyle: { color: '#e55b26' }
        }, {
            name: '批发和零售业',
            type: 'line',
            data: [1598, 1424, 2789, 3712],
            itemStyle: { color: 'skyblue' }
        }, {
            name: '教育',
            type: 'line',
            data: [1498, 1512, 3123, 4789],
            itemStyle: { color: '#06da90' }
        }, {
            name: ' 文化、体育和娱乐业   ',
            type: 'line',
            data: [743, 512, 1912, 2041],
            itemStyle: { color: '#06da4d' }
        }, {
            name: '信息技术服务业 ',
            type: 'line',
            data: [198, 512, 2912, 3323],
            itemStyle: { color: '#daac06' }
        }, {
            name: '公共设施管理业  ',
            type: 'line',
            data: [876, 1312, 1912, 2212],
            itemStyle: { color: '#da6906' }
        }, {
            name: ' 技术服务业 ',
            type: 'line',
            data: [1032, 1345, 3561, 4797],
            itemStyle: { color: '#da0606' }
        }, {
            name: '采矿业    ',
            type: 'line',
            data: [198, 405, 512, 1712],
            itemStyle: { color: 'rgba(218,6,6,0.27)' }
        }, {
            name: '租赁和商业服务业 ',
            type: 'line',
            data: [316, 321, 1567, 1120],
            itemStyle: { color: 'rgba(172,218,6,0.27)' }
        }, {
            name: '金融业',
            type: 'line',
            data: [718, 998, 2518, 3356],
            itemStyle: { color: 'rgba(6,186,218,0.27)' }
        }
    ])

    //顶岗实习供给情况
    const [workDatas, setWorkDatas] = useState < any > ([]) //内容
    const [workData2s, setWorkData2s] = useState < any > ([])//标题
    const [year, setYear] = useState < any > ([])

    //顶岗实习需求情况
    const [dgSupply] = useRequestFn(getNormalData)
    async function onDG (val: any) {
        let res = val == '' ? await dgSupply < any > ('2426539b-3550-4f87-bc49-250277efd0d8') : await dgSupply < any > ('997cf5c8-e7c6-4286-866d-8bbe96eb4036', { name: val })
        if (res)
        {
            setWorkDatas(res)
            setMiddleData(res)
            setWorkData2s(res)
            setYear(res[0].year)

            if (val != '')
            {
                setMiddleData(res)
                setFlag(true)
                setWorkData2s(res)
                setToCount(2);
            }
        }

    }

    //顶岗实习供给情况
    const [chartDatas, setChartDatas] = useState < any > ([])//内容
    const [chartData2s, setChartData2s] = useState < any > ([])//标题
    const [year1, setYear1] = useState < any > ([])

    const [gjSupply] = useRequestFn(getNormalData)
    async function onGJ (val: any) {

        let res = val == '' ? await gjSupply < any > ('997cf5c8-e7c6-4286-866d-8bbe96eb4036') : await gjSupply < any > ('b5b0083a-8935-4402-b304-53088418c212', { name: val })
        if (res)
        {
            setChartDatas(res)
            setChartData(res);
            setChartData2s(res)
            setYear1(res[0].year)
            if (val != '')
            {
                setChartToFlag(true);
                setChartData(res);
                setChartData2s(res)
                setLastToCount(2);
            }
        }

    }

    useMemo(() => {
        onGJ('')
        onDG('')
    }, [])

    // 按钮显示,并且切换数据
    const changeChartData = () => {
        setChartToFlag(true);
        setChartData(chartData2s);
    }
    //顶岗实习供给情况 -- end----------------------

    //-------------------------------  总体实习信息化率

    const [pie] = useRequest(() => getNormalData < any > ('f761fed8-be52-423b-a58c-3f3caec5093d'))
    const [pie1] = useRequest(() => getNormalData < any > ('cedcb57f-86a3-4d9e-ad10-b57d3e5cfaff'))//未实现
    const [pie2] = useRequest(() => getNormalData < any > ('f28fc8db-f695-42d9-80c2-bec01f8fb723'))//实现
    const [pieData3, setPie] = useState < any > (null)
    const [pieData4, setPie1] = useState < any > (null)
    const [pieData5, setPie2] = useState < any > (null)

    useEffect(() => {
        //未实现总体实习信息化率
        let info: any = []
        pie1?.map((item: any) => {
            info.push({
                table: [item.school_name, item.school_sx, item.student_num, item.all_area, item.all_create_area, item.money]
            })
        })
        setPie1(info)
    }, [pie1])

    useEffect(() => {
        //实现总体实习信息化率
        let info: any = []
        pie2?.map((item: any) => {
            info.push({
                table: [item.school_name, item.school_sx, item.student_num, item.all_area, item.all_create_area, item.money]
            })
        })
        setPie2(info)
    }, [pie2])


    useEffect(() => {
        //总体实习信息化率
        setPie([
            {
                name: `实现实习信息化院校${pie?.num}所`,
                value: pie ? Number(pie.num) / Number(pie.total) : 0,
            },
            {
                name: `未实现实习信息化院校${pie ? pie.total - pie.num : 0}所`,
                value: pie ? Number(pie.total - pie.num) / Number(pie.total) : 0,
            },
        ])
    }, [pie])



    // 头部的标题
    const [title, setTitle] = useState('');
    // 中间传递的变量
    const [thMiddleWare, setThMiddleWare]: any = useState([]);
    //th的数据
    const [thData] = useState(['学校名称', '学校属性', '在校生数', '占地面积', '建筑面积', '年度经费投入'])
    // 表格的数据
    const [tableData] = useState([
        {
            table: ['锦州师范高等专科学校', '市属公办', 9001, 190643, 227499, 12595]
        }, {

            table: ['辽宁理工职业大学', '市属公办', 5120, 490495, 492416, 6163]
        }, {

            table: ['辽宁石化职业技术学院', '市属公办', 5449, 345717, 393928, 10702]
        }, {

            table: ['辽宁铁道职业技术学院', '省属公办', 8459, 402396, 457165, 13711]
        }, {

            table: ['渤海船舶职业学院', '省属公办', 9090, 556979, 484918, 17416]
        }

    ])

    // 中间数据
    const [middleWareData, setMiddleWareData]: any = useState([]);
    let [tableType, setTableType] = useState(false);

    // 图标2的切换标记
    let [tableType2, setTableType2] = useState(false);

    // 获取图标名字的方法
    const showTable = (val: any) => {
        setTitle(val.name);
        setTableType(true);
        setThMiddleWare(thData)
        if (val.dataIndex == 0)
        {
            setMiddleWareData(pieData5)
        } else
        {
            setMiddleWareData(pieData4)
        }

    }

    // 隐藏图表
    const hiddenTable = () => {
        setTableType(false);
    }

    // 隐藏图表
    const hiddenTable2 = () => {
        setTableType2(false);
    }

    const hiddenTable3 = () => {
        setType3(false)
    }

    const [thData2] = useState(['学校', '计划实习课程数', '实际实习课程数', '计划实习人数', '实际实习人数', '指导教师数'])


    //实习计划开出情况(弹出列表)
    const [GGData] = useRequest(() => getNormalData < any > ('c6c0cfc7-6e28-4389-88f3-3fae12d79310'))


    //跟岗数据
    const [followData] = useState([
        {
            table: ['朝阳师范高等专科学院', 13, 12, 375, 296, 32]
        }, {

            table: ['阜新高等专科学校', 22, 22, 751, 743, 55]
        }, {

            table: ['锦州师范高等专科学校', 10, 10, 282, 282, 17]
        }, {

            table: ['辽宁城市建设职业技术学校', 35, 32, 968, 824, 74]
        }, {
            table: ['辽宁地质工程职业学院', 23, 21, 521, 498, 35]
        }, {
            table: ['辽宁工程职业学院', 18, 18, 772, 710, 29]
        }, {
            table: ['辽宁广告职业学院', 8, 8, 134, 132, 19]
        }, {
            table: ['辽宁轨道交通职业学院', 72, 67, 1032, 973, 103]
        }, {
            table: ['辽宁机电职业技术学院', 23, 21, 521, 498, 35]
        }, {
            table: ['辽宁建筑职业学院', 10, 10, 282, 282, 17]
        },
    ])

    const [thData3] = useState(['学校', '计划实习人数', '实际实习人数', '指导教师数'])

    //跟岗数据
    const [followData3] = useState([
        {
            table: ['朝阳师范高等专科学校', 375, 296, 32]
        }, {

            table: ['阜新高等专科学校', 753, 741, 55]
        }, {

            table: ['锦州师范高等专科学校', 282, 282, 17]
        }, {

            table: ['辽宁城市建设职业技术学校', 968, 824, 74]
        }, {
            table: ['辽宁地质工程职业学院', 521, 498, 35]
        }, {
            table: ['辽宁工程职业学院', 772, 710, 29]
        }, {
            table: ['辽宁广告职业学院', 134, 132, 19]
        }, {
            table: ['辽宁轨道交通职业学院', 1032, 973, 103]
        }, {
            table: ['辽宁机电职业技术学院', 521, 498, 35]
        }, {
            table: ['辽宁建筑职业学院', 282, 282, 17]
        },
    ])

    //中间变量
    const [middleWareData2, setMiddleWareData2]: any = useState([]);
    const [middleTh2, setMiddleTh2]: any = useState([]);

    //实习计划开出情况(弹出列表)
    const [onPlabKC, planKC] = useRequestFn(getNormalData)
    async function onFn (val: any) {
        let res = await onPlabKC < any > ('c6c0cfc7-6e28-4389-88f3-3fae12d79310', { type: val == '定岗实习' ? '1' : '2' })
        let arr: any = []

        res?.map((item: any) => {
            arr.push(
                val.trim() === '跟岗实习' ?
                    {
                        table: [item.school_name, item.jhxss, item.sjxss, item.jhkcs, item.sjkcs, item.zdjss]
                    } : {
                        table: [item.school_name, item.jhkcs, item.sjkcs, item.zdjss]
                    }
            )
        })
        setMiddleWareData2(arr);
    }

    // 拿到当前饼状图的名字,并且显示table
    const sendName = (name: string) => {
        setTitle(name)
        setTableType2(true)
        if (name.trim() === '跟岗实习')
        {
            onFn(name)
            setMiddleTh2(thData2)

        } else if (name.trim() === '顶岗实习')
        {
            onFn(name)
            setMiddleTh2(thData3)

        }
    }

    // 指导教师情况 --start
    const [middleWareData3, setMiddleWareData3]: any = useState([]);
    const [middleTh3, setMiddleTh3]: any = useState([]);
    const [ins] = useRequest(() => Instructor()) //获取指导教师数据
    const [insDel] = useRequest(() => InstructorDel())//获取指导教师详情
    const [np] = useRequest(() => NumberPeople())//计划实习人数 / 实际实习人数 / 指导教师数量

    // table切换的标志
    const [type3, setType3] = useState(false);

    // 指导教师的th
    const [thData4] = useState(['学校名称', '学校代码', '学生数', '派出教师数', '生师比'])

    // 指导教师的数据
    const [teacherData] = useState([
        {
            name: '辽宁城市建设职业技术学院',
            code: 14288,
            student: 7654,
            teacher: 912,
        }, {
            name: '辽宁广告职业学院',
            code: 12897,
            student: 5215,
            teacher: 416,
        }, {
            name: '辽宁轨道交通职业学院',
            code: 12896,
            student: 7432,
            teacher: 624,
        }, {
            name: '辽宁金融职业学院',
            code: 12895,
            student: 3216,
            teacher: 192,
        }, {
            name: '辽宁经济职业技术学院',
            code: 12899,
            student: 4721,
            teacher: 448,
        }, {
            name: '辽宁民族师范高等专科学校',
            code: 14464,
            student: 1472,
            teacher: 103,
        }, {
            name: '辽宁生态工程职业学院',
            code: 12593,
            student: 2941,
            teacher: 193,
        }, {
            name: '辽宁省交通高等专科学校',
            code: 11500,
            student: 5521,
            teacher: 493,
        }, {
            name: '辽宁特殊教育师范高等专科学校',
            code: 14526,
            student: 1531,
            teacher: 421,
        }, {
            name: '辽宁现代服务职业技术学院',
            code: 14240,
            student: 3291,
            teacher: 218,
        }, {
            name: '辽宁医药职业学院',
            code: 14289,
            student: 4398,
            teacher: 631,
        }, {
            name: '辽宁政法职业学院',
            code: 14398,
            student: 3394,
            teacher: 331,
        }, {
            name: '辽宁装备制造职业技术学院',
            code: 14076,
            student: 8318,
            teacher: 791,
        }, {
            name: '沈阳北软信息职业技术学院',
            code: 14385,
            student: 1529,
            teacher: 93,
        }, {
            name: '沈阳职业技术学院',
            code: 12594,
            student: 15217,
            teacher: 1310,
        },
    ])
    // 指导教师情况 --end

    // 点击详情获取第一层数据
    let oneLIst = (val: any) => {
    }

    //总体实习信息化率
    const YibiaoMemo: any = useMemo(() =>
        <Yibiao style={{ height: "200px", width: isMobile ? 'auto' : '500px' }} item={pieData3} showTable={showTable} />
        , [pieData3])


    //实习计划开出情况
    const Round1Memo: any = useMemo(() =>
        <Round1 methods={sendName} />
        , [])


    //顶岗实习岗位供给率第1层
    const DGMemo: any = useMemo(() => {
        return <div className={style.bottom}>
            {
                pieData?.map((item: any, index: any) => {
                    return <div key={index} onClick={() => { onSupply(1, item.title) }}><MakePie key={index} data={pieData[index]} methos={changeValue} count={count} one={oneLIst} /> </div>
                })
            }
        </div>
    }
        , [pieData])


    //顶岗实习岗位供给率第2层
    const DGMemo1: any = useMemo(() => {
        return <div className={style.bottom}>
            {
                pieData2?.map((item: any, index: any) => {
                    return <div key={index} onClick={() => { onSupply(2, item.title) }}> <MakePie key={index} data={pieData2[index]} methos={changeValue} count={count} one={oneLIst} /> </div>
                })
            }
        </div>
    }
        , [pieData2])


    //顶岗实习需求情况
    const Chart1Memo: any = useMemo(() =>
        <Chart1 data={middleData} methods={setWorkData} year={year} />
        , [year])


    //顶岗实习供给情况
    const Chart2Memo: any = useMemo(() =>
        <Chart2 data={sendChartData} methods={changeChartData} year={year1} />
        , [year1])


    return <div className={style.root}>
        <Header />
        <div className={style.wrapper}>
            <div className={style.leftBlock}>
                <Block>
                    <div>
                        <div className={style.blockTitle2}>
                            <p>总体实习信息化率</p>
                        </div>
                        <div>
                            {YibiaoMemo}
                        </div>
                    </div>
                </Block>

                {
                    tableType &&
                    <Table3 tableData={middleWareData} title={title} hiddenTable={hiddenTable} thData={thMiddleWare} />
                }

                <Block customStyle={{ width: isMobile ? 'auto' : '500px' }}>
                    <div style={{ height: isMobile ? 'auto' : '300px' }}>
                        <div className={style.top}>
                            <p className={style.blockTitle}> 实习计划开出情况</p>
                        </div>
                        <div>
                            {Round1Memo}
                        </div>
                    </div>
                </Block>
                {
                    tableType2 &&
                    <Table3 tableData={middleWareData2} title={title} hiddenTable={hiddenTable2} thData={middleTh2} />
                }
                <Block customStyle={{ marginBottom: 0, width: isMobile ? 'none' : '500px' }}>
                    <div style={{ height: '200px', overflowY: 'scroll', width: isMobile ? '100%' : '500px' }} className={style.bottom}>
                        <div className={style.top2}>
                            <p className={style.blockTitle}>
                                指导教师情况
                            </p>
                            <p onClick={() => {
                                setType3(true)
                                setMiddleWareData3(insDel)
                                setMiddleTh3(thData4)
                            }
                            }>
                                <span>详细信息</span>
                                <img src={you} style={{ width: '18px', height: '18px', paddingBottom: "3px" }} alt="" />
                            </p>
                        </div>
                        <div className={style.bottom2}>
                            <div>
                                <span className={style.one}>学生数</span>
                                <p className={style.studentCount}>
                                    <span>{ins && ins.length !== [] && ins[0] != null ? ins[0].stuNum : 0}</span>
                                    <span className={style.btn1}>人</span>
                                </p>
                            </div>
                            <div>
                                <span className={style.one}>派出教师数</span>
                                <p className={style.teacherCount}>
                                    <span>{ins && ins.length !== [] && ins[0] != null ? ins[0].teaNum : 0}</span>
                                    <span className={style.btn2}>人</span>
                                </p>
                            </div>
                            <div>
                                <span className={style.one}>全省平均生师比</span>
                                <p className={style.price}>
                                    <span>{ins && ins.length !== [] && ins[0] != null ? (ins[0].ssb).toFixed(2) : 0}</span>
                                    <span className={style.btn3}>%</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Block>
                {
                    type3 &&
                    <Table4 tableData={middleWareData3} title={'详细信息'} hiddenTable={hiddenTable3} thData={thData4} />
                }
            </div>
            <div className={style.centerBlock}>
                <Block>
                    <div className={style.centerContent}>
                        <div className={style.centerSubContent}>
                            <div className={style.cSubTitle}>计划实习人数</div>
                            <div className={style.cSubResult}>
                                <div className={style.cSubFont}>
                                    {np && np.length > 0 ? np[0].planStuNum : 0}
                                    <i className={style.unit}>人</i>
                                </div>
                            </div>
                        </div>
                        <div className={style.centerSubContent}>
                            <div className={style.cSubTitle}>实际实习人数</div>
                            <div className={style.cSubResult}>
                                <div className={style.cSubFont}>
                                    {np && np.length > 0 ? np[0].stuNum : 0}
                                    <i className={style.unit}>人</i>
                                </div>
                            </div>
                        </div>
                        <div className={style.centerSubContent}>
                            <div className={style.cSubTitle}>指导教师数量</div>
                            <div className={style.cSubResult}>
                                <div className={style.cSubFont}>
                                    {np && np.length > 0 ? np[0].teaNum : 0}
                                    <i className={style.unit}>人</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Block>
                <Block>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "30px" }}>
                        <div className={style.blockTitle}>
                            岗位实习岗位供给率
                        </div>
                        {
                            count === 2 && <div className={style.button1} onClick={() => {
                                setCount(1)
                            }}>
                                <span className={style.l}></span>
                                <span className={style.m}>返回</span>
                                <span className={style.r}></span>
                            </div>
                        }
                        {
                            count === 3 && <div className={style.button1} onClick={() => {
                                setCount(2)
                                onSupply(1, name1)
                            }}>
                                <span className={style.l}></span>
                                <span className={style.m}>返回</span>
                                <span className={style.r}></span>
                            </div>
                        }
                    </div>

                    <div className={style.bottom}>
                        {
                            count === 1 ? DGMemo : count === 2 ? DGMemo1 : <Pictograph pieDel={pieDel} />
                        }

                    </div>
                </Block>
            </div>
            <div className={style.rightBlock}>
                <Block>
                    <div className={style.data}>
                        <div className={style.blockTitle}>岗位实习需求情况</div>
                        {
                            flag && <div className={style.button} onClick={() => {
                                setFlag(false)
                                onDG('')
                                setMiddleData([]);
                                setMiddleData(workData[0]);
                                setToCount(1)
                            }}>
                                <span className={style.l}></span>
                                <span className={style.m}>返回</span>
                                <span className={style.r}></span>
                            </div>
                        }
                    </div>
                    <div style={{ height: isMobile ? 'auto' : "325px" }}>
                        <div className={style.data}>
                            {
                                toCount === 1 && workDatas.length !== 0 && workDatas.map((item: any, index: number) => {
                                    return (
                                        <div className={style.middle} key={index} onClick={() => {
                                            if (item.name == '总量') { return }
                                            onDG(item.name)
                                        }} onMouseEnter={(e) => {
                                            e.nativeEvent.stopImmediatePropagation()
                                            setMiddleData(item)
                                        }}>
                                            <p style={{ background: item.itemStyle.color }}></p>
                                            <p style={{ color: '#fff' }}>{item.name}</p>
                                        </div>
                                    )
                                })
                            }
                            {
                                toCount === 2 && workData2s.length !== 0 && workData2s.map((item: any, index: number) => {
                                    return (
                                        <div className={style.middle} key={index}>
                                            <p style={{ background: item.itemStyle.color }}></p>
                                            <p style={{ color: '#fff' }}>{item.name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {Chart1Memo}
                    </div>
                </Block>

                <Block customStyle={{ marginBottom: isMobile ? 50 : 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={style.blockTitle}>
                            岗位实习供给情况
                        </div>
                        {
                            // 返回点击
                            chartToFlag && <div className={style.button} onClick={() => {
                                setChartToFlag(false);
                                onGJ('')
                                setChartData([])
                                // 显示原来的数据里面的第一条
                                setChartData(chartDatas[0])
                                // 原来的表格显示
                                setLastToCount(1)
                            }}>
                                <span className={style.l}></span>
                                <span className={style.m}>返回</span>
                                <span className={style.r}></span>
                            </div>
                        }
                    </div>

                    <div style={{ height: "325px" }}>
                        <div className={style.data}>
                            {
                                lastCount === 1 && chartDatas.length !== 0 && chartDatas.map((item: any, index: number) => {
                                    return (
                                        <div className={style.middle} key={index}
                                            onClick={() => {
                                                if (item.name == '总量') { return }
                                                onGJ(item.name)
                                            }}
                                            onMouseOver={(e) => {
                                                setChartData(item)
                                            }}

                                        >
                                            <p style={{ background: item.itemStyle.color }}></p>
                                            <p style={{ color: '#fff' }}>{item.name}</p>
                                        </div>
                                    )
                                })
                            }
                            {
                                lastCount === 2 && chartData2s.length !== 0 && chartData2s.map((item: any, index: number) => {
                                    return (
                                        <div className={style.middle} key={index}>
                                            <p style={{ background: item.itemStyle.color }}></p>
                                            <p style={{ color: '#fff' }}>{item.name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {Chart2Memo}
                    </div>
                </Block>
                <div className={style.space} />
            </div>
        </div>
        <Footer />
    </div>
}
