import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import useDevice from "../../../../../lib/useDevice";

interface IProps {
  year: any;
}

export default function Chart1 (props: any) {
  const { isMobile } = useDevice()
  const opt: EChartOption<EChartOption.SeriesLine> = useMemo(() => {
    if (props)
    {
      return {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          icon: 'circle',
          itemWidth: 10,
          textStyle: { color: "rgb(179,193,255)" },
          show: false
        },

        grid: {
          left: '5%',
          right: '10%',
          top: "10%",
          bottom: "0%",
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.year,
          axisLabel: { color: "rgb(86,112,162)" },

        },
        yAxis: {
          type: 'value',

          axisLabel: {
            color: "rgb(86,112,162)",
            // formatter: (val: number) => `${val}%`,
          },
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "rgb(86,112,162)"
            }
          }
        },
        series: props.data
      };
    } else
    {
      return {};
    }
  }, [props])


  return (
    <ReactEcharts
      option={opt}
      style={{ width: isMobile ? 'auto' : '580px', height: "200px" }}
      onEvents={{
        "legendselectchanged": (e) => {
          props.methods()
        }
      }}
    />
  );
}
