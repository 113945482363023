import useRequest from "../../lib/useRequest";
import { findOptions } from "../../service/basic";
import ReactEcharts from "echarts-for-react";
import React, { useMemo } from "react";
import { EChartOption } from "echarts";

interface StaticNumProps {
  uuid: string;
  customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
  style?: any;
  theme?: string;
  onEvents?: any;
}

export default function ChartUuid ({ uuid, theme, customOption, style, onEvents }: StaticNumProps) {

  const [data] = useRequest(() => findOptions(uuid));
  const opt = useMemo(() => {
    if (data && data.option)
    {
      if (customOption)
      {
        return customOption({ ...data.option, legend: { show: false } });
      } else
      {
        return { ...data.option, legend: { show: false } };
      }
    } else
    {
      return {};
    }
  }, [data])


  return (
    <ReactEcharts style={{ cursor: 'default' }}
      onEvents={onEvents}
      option={opt}
      notMerge={true}
      lazyUpdate={true}
      theme={theme}
    />
  );
}
