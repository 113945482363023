import ReactEcharts from "echarts-for-react";
import useRequest from "../../../../../lib/useRequest";
import { sankeyDiagramC2T2S } from "../../../../../service/major";

const colors = [
    '#78A3CE',
    '#FBC2EB',
    '#A18CD1',
    '#C2E9FB',
    '#A1C4FD',
    '#D4FC79',
    '#96E6A1',
    '#FCC687',
    '#F28D86',
    '#F286A0',
    '#33876A',
    '#B5BF6E',
    '#2979F1',
    '#F08F1B',
    '#57B956',
    '#ABA5EA',
    '#9BC46C',
    '#30E0E0',
    '#F286CA',
    '#A6F286',
    '#D66161',
    '#8FF379',
    '#1CBDB4',
    '#EEF2F3',
    '#8E9EAB',
    '#BB9BF1',
    '#887BF2',
    '#7FFED8',
    '#09BDFE',
    '#F2E786',
]

export default function DetailChart({name}: {name: string}) {
    const [options] = useRequest(() => sankeyDiagramC2T2S(name));

    const option: any = {
        backgroundColor: '#FFFFFF',
        series: [
            {
                type: 'sankey',
                left: 50.0,
                top: 40.0,
                right: 150.0,
                bottom: 25.0,
                data: options?.data.map((item, index) =>({
                    ...item,
                    itemStyle: {
                        color: colors[index%20]
                    }
                })) || [],
                links: options?.links.map(item => ({
                    ...item,
                    lineStyle: {normal: {opacity: 0.05}}
                })) || [],
                lineStyle: {
                    curveness: 0.5
                },
                label: {
                    color: 'rgba(0,0,0,0.7)',
                    fontFamily: 'Arial',
                    fontSize: 10
                }
            }],

        tooltip: {
            formatter: (params: any) => {
                if(params.dataType==="node"){
                    return `${params.data.name}: ${params.value}人`
                }else{
                    return `${params.data.source}对接${params.data.target}人数${params.data.value}人`
                }
            }
        },
    };

    return (
        <div >
            <ReactEcharts option={option} notMerge={true} lazyUpdate={true} style={{height: (options?.data.length||0)>50?(options?.data.length||0)>100?2500:1200:500}}/>
        </div>
    );
}
