import React, { useEffect, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import {
  findCityListBySubjectBigCode,
  findSubjectBigList, get1XMS, get1xNum, getCSXS, getDoubleLevelList, getSGMX, getsgNum, ICityCountInfo,
  INewDataTypes, ISchoolInfo,
  ISubjectItem
} from "../../../../service/chart";
import cityDatasPage2 from "../../../../assets/datas/cityDatas2";
import Province from "../Province/index";
import { message } from "antd";
import { errorMessage } from "../../../../lib/utils";
import cityCoordData from "../../../../assets/cityCoord";
import useRequestFn from "../../../../lib/useRequestFn";
import { getShfwxm, IBusiness, IServiceType, shfwxmDetail } from "../../../../service/service";
import { getNormalData } from "../../../../service/basic";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
  style?: any;
}

export default function ServiceScreen ({ showTimeLine, width, height, style }: IProps) {
  // const [temp] = useRequest(() => getShfwxm());
  const [temp] = useRequest(() => getNormalData < any > ("00a89f79-ddef-4b5a-8296-fe0ee544900a")); // 地图
  const [data, setData] = useState < INewDataTypes[] > ([]);
  const [typesData, setTypeData] = useState < string[] > (["企业数量"]);
  const [schoolList, setSchoolList] = useState < IBusiness[] > ([]);

  let tempData: INewDataTypes[] = [];
  useEffect(() => {
    if (temp)
    {
      setData(formatData(temp ? temp : []));
    }
  }, [temp])

  function formatData (data: IServiceType[]) {
    const res = data.map((item: any) => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseInt(item.qysl),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: "企业数量"
      }
    });

    return buchongData(res);
  }

  function buchongData (data: INewDataTypes[]) { // 后台不存在的城市要补充上
    const cityArr = data.map(item => item.name);
    const res = cityCoordData.filter(item => !cityArr.includes(item.name));
    return [...data, ...res.map(item => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: 0,
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '企业数量'
      };
    })];
  }
  //社会服务地图明细
  const [onData, datas] = useRequestFn(getNormalData); // 详情

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }
  //异步加载社会服务地图明细
  async function list (val: any) {
    let res: any = await onData("e9685db4-2e40-4afc-8a62-23928d321e76", { area: val.name, qy_name: '', school_name: '' })
    setSchoolList(res)
  }


  function getSchoolList (val: string, level: string) {
    list(val)
    // if (!level)
    // {
    //   list(val)
    //   shfwxmDetail(val).then((data) => {
    //     setSchoolList(data);
    //   });


    // }
    /*        if(level) {
                if(level === "国家级") {
                    getSGMX(val).then((data) => {
                        setSchoolList(data);
                    });
                }

            if(level === "省级") {
                get1XMS(val).then((data) => {
                    setSchoolList(data);
                });
            }

        }*/
  }

  function onClean () { // 清除弹出层表单数据
    setSchoolList([]);
  }

  return <div style={style}>
    {data && typesData && <Province name='辽宁' tableType={1} width={width} height={height} data={data}
      year={typesData}
      geoColor='0, 167, 237'
      schoolList={schoolList || []}
      showTimeLine={showTimeLine}
      changeCityName={(val, levelName) => getSchoolList(val, levelName)}
      pointsBlue={[]}
      pointsRed={temp?.map((item: any) => {
        return {
          areaCode: item.name,
          schCount: item.qysl
        }
      }) || []}
      onClean={onClean}
      pos={['100', '100']}
    // schoolListSpecial={schoolListSpecial || []}
    />}
  </div>
}

ServiceScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800
}
