import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import { IMajorAbility, ISubjectTrainBaseInfo } from "../../../../../service/training";

interface AnalysisProps {
  data?: ISubjectTrainBaseInfo;
  abilityList?: IMajorAbility[];
}

export default function DemandAnalysis ({ data, abilityList }: AnalysisProps) {

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>行业背景分析</div>
              <div className={`${style.content} ${style.largecontent}`}>{data?.background}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>专业人才需求分析</div>
              <div className={`${style.content} ${style.largecontent}`}>{data?.zyrcxq}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
