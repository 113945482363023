import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import { getCourseTrainingProgram } from "../../../../../service/training";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from '../../../../../service/basic'
interface CourseIdProps {
  datas: any
}

export default function CourseAppraise ({ datas }: CourseIdProps) {


  const dataDetail = datas

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={8}>
              {/* 基本情况考核方式 */}
              <div className={style.title}>考核评价方法</div>
              <div className={style.content}>{dataDetail?.courseBase?.check_style}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              {/* 基本情况评价标准 */}
              <div className={style.title}>评价标准</div>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.assess_target}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
