import axios from "axios";
import qs from "qs";
import { message } from "antd";





/**
 * 登录
 */
export interface IServiceType {
    message: any;
    code: string,
    key: any,
}


export function login(loginName?: string, password?: string, verifykey?: string, verifycode?: string) {
    return axios.post<SuccessData<IServiceType[]>>(`/lndc/login`, { loginName: loginName, password: password, verifykey: verifykey, verifycode: verifycode })
        .then(res => res.data.message);
}

/**
 * 验证码
 */
export function getVerifyCode() {
    return axios.get<SuccessData<IServiceType[]>>(`/lndc/getVerifyCode`)
        .then(res => res.data);
}

/**
 *请求拦截器
 */
axios.defaults.baseURL = ''
//设置请求头
axios.interceptors.request.use(config => {
    // 给请求头加token的字段,值为token
    config.headers.token = localStorage.getItem('LNDSJ')
    return config
})

// 相应拦截
axios.interceptors.response.use(response => {
    // 对响应成功
    if (response.status == 200) {
        return Promise.resolve(response)
    }

    return response;
}, error => {
    // 对响应错误
    setTimeout(() => {
        window.location.href = '/auth/login';
    }, 1500);
    message.error(error.message);
    return Promise.reject(error);
});

