import React, { useEffect, useState } from "react";
import Province from "../LiaoNingCity";
import {
  constMoney, getDoubleLevelList,
  getNationalLevelCount,
  getProvincialLevelCount,
  getSchoolInfoList, IDataTypes,
  INewDataTypes, ISchoolInfo, newStudentCount, schoolCount, teacherCount
} from "../../../../../service/chart";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import cityCoordData from "../../../../../assets/cityCoord";
import { getNormalData } from "../../../../../service/basic";

interface IProps {
  showTimeLine: boolean;
  width: number;
  height: number;
}

export default function MiddleBlock2 ({ showTimeLine, width, height }: IProps) {

  const [cityName, setCityName] = useState(""); // 点击地图返回城市名

  // 第一屏
  const [schoolData] = useRequest(() => getNormalData < IDataTypes[] > ('4c9c742e-ac0d-4efc-b3c5-b0a4270d7a20')); // 按城市查看-学校数量
  const [newStuCountData] = useRequest(() => getNormalData < IDataTypes[] > ('04d59a1b-affd-4b5c-b207-b49cbfb2ed7d')); // 按城市查看-招生人数
  const [teacherData] = useRequest(() => getNormalData < IDataTypes[] > ('441564ae-cb97-4c15-92a5-b84c98a3716c')); // 按城市查看-教师人数
  const [constMoneyData] = useRequest(() => getNormalData < IDataTypes[] > ('476bf7e7-bf81-43fe-89e1-9dcd7eafc106')); // 按城市查看-投入情况
  const [firstData, setFirstData] = useState < INewDataTypes[] > ([]);

  const [schoolList, setSchoolList] = useState < ISchoolInfo[] > ([]);
  const [requestSchoolList] = useRequest(() => getSchoolInfoList(cityName), {}, [cityName]);
  const [schoolListSpecialFn, { loading, data: schoolListSpecial }] = useRequestFn(getDoubleLevelList);
  const [blueCountData] = useRequest(() => getProvincialLevelCount());
  // const [redCountData] = useRequest(() => getNationalLevelCount());
  const [redCountData] = useRequest(() => getNormalData < any > ('670247c9-c134-4471-bb0a-bec5fb1e9748'));


  useEffect(() => {
    if (requestSchoolList)
    {
      setSchoolList(requestSchoolList);
    }
  }, [requestSchoolList]);

  useEffect(() => {
    if (schoolData && newStuCountData && teacherData && constMoneyData)
    {
      const data1 = formatData(schoolData, '学校数量');
      const data2 = formatData(newStuCountData, '招生人数');
      const data3 = formatData(teacherData, '教师人数');
      const data4 = formatData(constMoneyData, '投入情况');
      setFirstData([...data1, ...data2, ...data3, ...data4]);
    }
  }, [schoolData, newStuCountData, teacherData, constMoneyData]);

  function formatData (data: IDataTypes[], name: string) {
    return data.map(item => {

      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseFloat(item.value),
        circleColor: item.circleColor ? item.circleColor : '#f00',
        areaColor: item.areaColor ? item.areaColor : '#f8f8f8',
        year: name
      }
    });
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  function getSchoolList (val: string, level: string) {
    if (!level) setCityName(val);
    if (level)
    {
      schoolListSpecialFn(val, level);
    }
  }

  function onClean () { // 清除弹出层表单数据
    setSchoolList([]);
  }

  return <div>
    {firstData &&
      <Province tableType={1} name='辽宁' width={width} height={height} data={firstData}
        year={["学校数量", "招生人数", "教师人数", "投入情况"]}
        geoColor='27, 94, 193'
        schoolList={schoolList}
        changeCityName={(val, levelName) => {
          getSchoolList(val, levelName)
        }}
        pointsBlue={blueCountData?.map(item => {
          return {
            areaCode: item.areaCode,
            schCount: item.schCount
          }
        })}
        pointsRed={redCountData?.map((item: any) => {
          return {
            areaCode: item.areaCode,
            schCount: item.schCount
          }
        })}
        schoolListSpecial={schoolListSpecial || []}
        showTimeLine={showTimeLine}
        onClean={onClean}
      />}
  </div>
}

MiddleBlock2.defaultProps = {
  showTimeLine: true,
  width: 800,
  height: 260
}
