import axios from "axios";
import qs from "qs";
import Config from "../config";
import { jbqkschoolDetail } from "./basic";

// interface ListItem {
//     title: string;
//     num: number;
// }
//
// /**
//  * 测试请求
//  */
// export function testQuery() {
//     return axios.post<SuccessData<ListItem[]>>(`${Config.BaseUrl}/ajb/kw/fhkw/14-15/1_10.html`, qs.stringify({name: "kenny"}))
//         .then(res => res.data.data);
// }


export interface SearchParams {
  courseName?: string; //课程名称
  schoolName?: string; //学校名称
  year?: string; //学年（固定2020）
}

interface CourseItem {
  subjectId: string;
  courseId: string;
  courseName: string;
  schoolName: string;
  year: string;
  subjectName: string;
  inRequirements: string;
  restYear: string;
  schoolId: string;
  is_core: string;
  course_type: string;
  course_quality: string;
  all_time: string;
  xxmb: string;
}

/**
 * 课程检索
 */
export function getSchAndSubByCourse(params: SearchParams) {
  return axios.post<SuccessData<CourseItem[]>>(`/lndc/getSchAndSubByCourse`, qs.stringify(params))
    .then(res => res.data.data);
}

/**
 * 课程检索
 */
export function getSchAndSubByCoursepk(params: SearchParams) {
  return axios.post<SuccessData<CourseItem[]>>(`/lndc/getSchAndSubByCoursepk`, qs.stringify(params))
    .then(res => res.data.data);
}


interface SchoolItem {
  schoolName: string;
  schoolId: string;
  code: string;
  area_code: string
}

/**
 * 所在城市列表
 */
export function getSchoolList() {
  return axios.post<SuccessData<SchoolItem[]>>(`/lndc/getSchoolList`)
    .then(res => res.data.data);
}

export interface ProjectSearchParams {
  school_id?: string;
  subject_name?: string;
  in_requirements?: string;
  year?: string
}

export interface ZhuanyeItem {
  subject_name: string;
  subject_code: string;
  zyfx: ZyfxItem[];
}

interface ZyfxItem {
  id: string;
  subject_direction: string;
  in_requirements: string;
  rest_year: string;
}

interface ProjectItem {
  school_name: string;
  school_code: string;
  zhuanye: ZhuanyeItem[];
}

/**
 * 专业培养方案-专业列表查询
 */
export function pyfaZYGXselect(params: ProjectSearchParams) {
  return axios.post<ProjectItem>(`/lndc/pyfaZYGXselect`, qs.stringify(params))
    .then(res => res.data);
}

export interface CourseBaseType {
  is_core: string;
  course_name: string;
  course_type: string;
  course_design: string;
  course_idea: string;
  course_quality: string;
  other_content: string;
  kcxz_ms: string;
  new_year: string;
  term: string;
  all_time: string;
  xfs: string;
  zynlyq: string;
  xxmb: string;
  xxnr: string;
  zyhxkcqt: string;
  couse_flag: string;
  teach_way: string;
  base_time: string;
  cost_time: string;
  course_info: string;
  teach_condition: string;
  teach_reference: string;
  course_open_advice: string;
  assess_target: string;
  check_style: string;
  week_time: string;
  subject_name: string;
  subject_code: string;
  subject_direction: string;
  school_name: string;
  school_code: string;
}

export interface TeacherType {
  teacher_type: string;
  person_num: string;
  major_tech: string;
  vocation: string;
  major_field: string;
  task: string;
}

export interface CourseGoalType {
  goal_name: string;
  goal_type: string;
  goal_mark: string;
}

export interface CoursePlanType {
  model_name: string;
  teach_content: string;
  way: string;
  address: string;
  theory: string;
  practice: string;
  study_goal: string;
  study_content: string;
  content_import: string;
  content_difficult: string;
  teach_method: string;
  activity_design: string;
  condition: string;
  checkStyle: string;
  check_weight: string;
  main_check_know: string;
  main_check_tech: string;
  main_check_attitude: string;
  check_other_info: string;
}

export interface CourseDetailItem {
  coursePlan: CoursePlanType[];
  courseBase: CourseBaseType;
  teacher: TeacherType[];
  courseGoal: CourseGoalType[];
}

/**
 * 培养方案-课程检索-课程明细
 */
export function getCourseTrainingProgram(courseId: string) {
  return axios.post<SuccessData<CourseDetailItem>>(`/lndc/getCourseTrainingProgram`, qs.stringify({ courseId }))
    .then(res => res.data.data);
}

interface CoursePlanType2 {
  model_name_count: number;
  theory_count: number;
  practice_count: number;
}

export interface CourseBaseType2 {
  is_core: string;
  course_name: string;
  course_type: string;
  course_design: string;
  course_idea: string;
  course_quality: string;
  other_content: string;
  kcxz_ms: string;
  new_year: string;
  term: string;
  all_time: string;
  xfs: string;
  zynlyq: string;
  xxmb: string;
  xxnr: string;
  zyhxkcqt: string;
  couse_flag: string;
  teach_way: string;
  base_time: string;
  cost_time: string;
  course_info: string;
  teach_condition: string;
  teach_reference: string;
  course_open_advice: string;
  assess_target: string;
  check_style: string;
  week_time: string;
}

interface TeacherType2 {
  zjjs: string[];
  wpjs: string[];
  teacherCount: number;
}

export interface CourseGoalType2 {
  ztmb?: {
    goal_name: string;
    goal_type: string;
    goal_mark: string;
  };
  jtmb: string[];
}

export interface CourseDetailItem2 {
  coursePlan: CoursePlanType2;
  courseBase: CourseBaseType2;
  teacher: TeacherType2;
  courseGoal: CourseGoalType2;
}

export interface CourseTrainingProgram2Ret extends CourseDetailItem2 {
  courseId: string;
}
/**
 * 培养方案-课程检索-课程明细
 */
export function getCourseTrainingProgram2(courseId: string) {
  return axios.post<SuccessData<CourseDetailItem2>>(`/lndc/getCourseTrainingProgram2`, qs.stringify({ courseId }))
    .then(res => ({ ...res.data.data, courseId }));
}

interface HotKeyItem {
  name: string;
}

/**
 * 热门关键字
 */
export function getAllHotMainkey() {
  return axios.post<SuccessData<HotKeyItem[]>>(`/lndc/getAllHotMainkey`)
    .then(res => res.data.data);
}

interface IBaseInfo {
  subjectTrainBaseInfo: ISubjectTrainBaseInfo;
}

export interface ISubjectTrainBaseInfo {
  subjectBigName: string;      //专业大类
  subjectTypeName: string;     //专业类
  schoolName: string;          //开设院校
  subjectCode: string;         //专业代码
  subectName: string;          //专业名称
  schoolCode: string;          //专业名称
  subjectDirection: string;          //专业方向
  industry1Name: string;       //对应行业-门类
  industry2Name: string;       //对应行业-大类
  industry3Name: string;       //industry3Name
  inRequirements: string;      //入学要求
  occupationType1Name: string;      //主要职业类别（或技术领域）-大类
  occupationType2Name: string;      //主要职业类别（或技术领域）-中类
  occupationType3Name: string;      //主要职业类别（或技术领域）-小类
  progress: string;            //教学进程表
  "1xCertificate": string;     //"1+x"证书
  background: string;          //行业背景分析
  zyrcxq: string;              //专业人才需求分析
  trainTarget: string;         //培养目标
  pyggsz: string;              //培养规格-素质
  pyggzs: string;              //培养规格-知识
  pyggnl: string;              //培养规格-能力
  trainModel: string;          //人才培养模式
  courseSystem: string;        //课程体系结构
  resource: string;            //教学资源
  teachMethod: string;         //教学方法
  evaluate: string;            //学习评价
  qualityManager: string;      //质量管理
  byReq: string;               //毕业要求
  restYear: string;           //修业年限
}

/**
 * @name 基本信息
 * @description http://yapi.dufe.tech/project/88/interface/api/5058
 * @param subjectId
 */
export function findSubjectTrainBaseInfo(subjectId: string) {
  return axios.post<SuccessData<IBaseInfo>>(`/lndc/findSubjectTrainBaseInfo`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}

interface IAbility {
  majorAbilitys: IMajorAbility[];
}

export interface IMajorAbility {
  postName: string;        //岗位名称
  postTypeName: string;    //岗位类别
  postInfo: string;        //岗位描述
  majorAbility: string;    //职业能力要求
}

/**
 * @name 专业岗位能力分析
 * @description http://yapi.dufe.tech/project/88/interface/api/5175
 * @param subjectId
 */
export function findMajorAbility(subjectId: string) {
  return axios.post<SuccessData<IAbility>>(`/lndc/findMajorAbility`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}

interface ICerts {
  vocationLevelCerts: ILevelCerts[];
}

export interface ILevelCerts {
  certificate: string;         //“1+x”证书或职业技能等级证书
  company: string;             //颁证单位
  levelName: string;           //等级
  info: string;                //备注
}

/**
 * @name 职业技能等级证书
 * @description http://yapi.dufe.tech/project/88/interface/api/5184
 * @param subjectId
 */
export function findVocationLevelCerts(subjectId: string) {
  return axios.post<SuccessData<ICerts>>(`/lndc/findVocationLevelCerts`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}

interface PyfaZYItem {
  id: string;
  subject_name: string;
  school_name: string;
  subject_direction: string;
  in_requirements: string;
  rest_year: string;
}

/**
 * 培养方案列表
 */
export function pyfaZYselect(params: ProjectSearchParams) {
  return axios.post<PyfaZYItem[]>(`/lndc/pyfaZYselect`, qs.stringify(params))
    .then(res => res.data);
}


interface ITeams {
  teacherTeams: ITeacherTeams[];
}

export interface ITeacherTeams {
  name: string;            //姓名
  education: string;       //学历学位
  professional: string;    //职称
  isDoubleName: string;    //是否双师
  experience: string;      //企业经验
  officeInfoName: string;  //任职情况
}

/**
 * @name 师资队伍
 * @description http://yapi.dufe.tech/project/88/interface/api/5193
 * @param subjectId
 */
export function findTeacherTeams(subjectId: string) {
  return axios.post<SuccessData<ITeams>>(`/lndc/findTeacherTeams`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}

interface ITrains {
  schoolInTrains: ISchoolInTrains[];
}

export interface ISchoolInTrains {
  houseName: string;       //实训室名称
  area: string;            //面积
  mainTool: string;        //主要设备及工具
  mainSoft: string;        //主要软件
  function: string;        //功能
  workstations: string;    //工位数
  price: string;           //价格（万元）
}


/**
 * @name 校内实训条件
 * @description http://yapi.dufe.tech/project/88/interface/api/5202
 * @param subjectId
 */
export function schoolInTrains(subjectId: string) {
  return axios.post<SuccessData<ITrains>>(`/lndc/schoolInTrains`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}

interface IOutTrains {
  schoolOutTrains: ISchoolOutTrains[];
}

export interface ISchoolOutTrains {
  houseName: string;     //校外实习基地名称
  company: string;       //合作企业名称
  useWay: string;        //用途
  workDemand: string;    //合作深度要求
}

/**
 * @name 校外实训条件
 * @description http://yapi.dufe.tech/project/88/interface/api/5211
 * @param subjectId
 */
export function schoolOutTrains(subjectId: string) {
  return axios.post<SuccessData<IOutTrains>>(`/lndc/schoolOutTrains`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}


interface ISyses {
  courseSys: IFindCourseSyses[];
}

export interface IFindCourseSyses {
  courseId: string;            //课程id
  courseName: string;          //课程名称
  isCoreName: string;          //是否核心课
  courseTypeName: string;      //课程类型
  courseQualityName: string;   //课程性质
  newYear: string;             //学年
  term: string;                //学期
  allTime: string;             //总学时
  xfs: string;                 //学分数
  weekTime: string;            //周学时
}

/**
 * @name 课程体系
 * @description http://yapi.dufe.tech/project/88/interface/api/5274
 * @param subjectId
 */
export function findCourseSyses(subjectId: string) {
  return axios.post<SuccessData<ISyses>>(`/lndc/findCourseSyses`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}



export interface PyfaPKItem {
  subject_big: string;
  subject: string;
  subject_code: string;
  subject_name: string;
  subject_direction: string;
  rest_year: string;
  in_requirements: string;
  ndustry: string;
  occupation_type: string;
  onex_certificate: string;
  background: string;
  zyrcxq: string;
  train_target: string;
  pygg_sz: string;
  pygg_zs: string;
  pygg_nl: string;
  train_model: string;
  course_system: string;
  progress: string;
  resource: string;
  teach_method: string;
  evaluate: string;
  quality_manager: string;
  by_req: string;
  nlfx_10: string;
  onex_zs: string;
  sszb: string;
  zzjjzb: string;
  jjslh: string;
  sxsgws: string;
  xwsxjdgs: string;
  courseName: {
    courseId: string;
    course_name: string;
  }[];
}

export interface PyfaPKoneRet extends PyfaPKItem {
  subjectId: string;
  majorAbility: number;
  vocationLevelCerts: number;
  subjectCoreCoursesNum: number;
  ohourseScorehzData: HourSelectItem;
}

/**
 * 培养方案列表
 */
export async function pyfaPKone(subject_id: string) {
  const majorAbilityPro = findMajorAbility(subject_id);
  const vocationLevelCertsPro = findVocationLevelCerts(subject_id);
  const ohourseScorehzDataPro = ohourseScorehz(subject_id);
  const subjectCoreCoursesPro = findSubjectCoreCourses(subject_id);

  const majorAbility = await majorAbilityPro;
  const vocationLevelCerts = await vocationLevelCertsPro;
  const ohourseScorehzData = await ohourseScorehzDataPro;
  const subjectCoreCoursesData = await subjectCoreCoursesPro;

  return axios.post<PyfaPKItem>(`/lndc/pyfaPKone`, qs.stringify({ subject_id }))
    .then(res => {
      return {
        ...res.data,
        subjectId: subject_id,
        majorAbility: majorAbility.majorAbilitys.length,
        vocationLevelCerts: vocationLevelCerts.vocationLevelCerts.length,
        ohourseScorehzData: ohourseScorehzData.data,
        subjectCoreCoursesNum: subjectCoreCoursesData.subjectCoreCourses.length,
      }
    });
}


interface CourseContrastSubject {
  subjectId: string;
  subject_name: string;
  subject_direction: string;
  courseId: string;
}

interface CourseContrast {
  schoolId: string;
  schoolName: string;
  courseId: string;
  subjectList: CourseContrastSubject[];
}

/**
 * 培养方案列表
 */
export function courseContrast(courseName: string) {
  return axios.post<SuccessData<CourseContrast[]>>(`/lndc/courseContrast`, qs.stringify({ courseName, year: 2020 }))
    .then(res => res.data.data);
}

interface FapkItem {
  id: number;
  subject_name: string;
}
/**
 * pk培养方案-查询专业
 */
export function pyfapksearch(subject_name: string) {
  return axios.post<FapkItem[]>(`/lndc/pyfapksearch`, qs.stringify({ subject_name }))
    .then(res => res.data);
}



interface PyfapknextItem {
  school_name: string;
  school_code: string;
  zhuanye: PyfapknextZyItem[];
}

interface PyfapknextZyItem {
  subject_name: string;
  subject_code: string;
  id: string;
}

/**
 * pk培养方案-查询下一步-学校专业方向
 */
export function pyfapknext(subject_name: string) {
  return axios.post<PyfapknextItem[]>(`/lndc/pyfapknext`, qs.stringify({ subject_name }))
    .then(res => res.data);
}


interface CoreCourseItem {
  subjectCoreCourses: subjectCoreCourses[];
}

export interface subjectCoreCourses {
  courseName: string;    //课程名称
  term: string;          //学期
  allTime: string;       //学时
  zynlyq: string;        //职业能力要求
  xxmb: string;          //学习目标
  xxnr: string;          //学习内容
}

/**
 * @name 专业核心课程基本信息
 * @param subjectId
 */
export function findSubjectCoreCourses(subjectId: string) {
  return axios.post<SuccessData<CoreCourseItem>>(`/lndc/findSubjectCoreCourses`, qs.stringify({ subjectId }))
    .then(res => res.data.data);
}


export interface HourSelectItem {
  jjsjk: Jjsjk; //集中实践课
  zyxx: Zyxx;   //专业限选课
  ggrx: Ggrx;   //公共任选课
  llksjk: Llksjk;//理论实践课
  llk: Llk;     //理论课
  zybx: Zybx;   //专业必修课程
  ggjcbx: Ggjcbx;//公共基础必修课
  lljxxs_sjjxxs_rate: string;//理论教学学生与实践教学学时的比例
}

interface Jjsjk {
  jzsjk_xf_bfb: string;
  jzsjk_xs_bfb: string;
  jjsjk_llxs: number;
  jjsjk_sjxs: number;
  jjsjkxf: number;
}

interface Zyxx {
  zyxx_xs_bfb: string;
  zyxx_llxs: number;
  zyxx_xf_bfb: string;
  zyxx_xf: number;
  zyxx_sjxs: number;
}

interface Ggrx {
  ggrx_xf_bfb: string;
  ggrx_xf: number;
  ggrx_sjxs: number;
  ggrx_xs_bfb: string;
  ggrx_llxs: number;
}

interface Llksjk {
  llksjk_xs_bfb: string;
  llksjk_llxs: number;
  llksjk_xf_bfb: string;
  llksjk_llksjkxf: number;
  llksjkxf: number;
}

interface Llk {
  llk_xs_bfb: string;
  llk_llxs: number;
  llk_xf_bfb: string;
  llkxf: number;
  llk_sjxs: number;
}

interface Zybx {
  zybx_xs_bfb: string;
  zybx_llxs: number;
  zybx_xf_bfb: string;
  zybx_xf: number;
  zybx_sjxs: number;
}

interface Ggjcbx {
  ggbx_xs_bfb: string;
  ggjcbx_llxs: number;
  ggbx_xf_bfb: string;
  ggjcbx_xf: number;
  ggjcbx_sjxs: number;
}

/**
 * @name 学时学分汇总统计
 * @param
 */
export function ohourseScorehz(subjectId: string) {
  return axios.post<HourSelectItem>(`/lndc/ohourseScorehz`, qs.stringify({ subjectId }))
    .then(res => res);
}
