import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';
import { pieChartIndustryDockingCompany } from "../../../../../service/employment";
import useRequest from "../../../../../lib/useRequest";
import { getNormalData } from "../../../../../service/basic";

import _ from "lodash";


export default function Chart6 () {

  // const [ data ] = useRequest(pieChartIndustryDockingCompany);
  const [data] = useRequest(() => getNormalData < any > ('0015a33b-b6fd-4d22-a3b6-c4962eff03a0'))//查询产业对接企业数量分布图

  const options: echarts.EChartOption<echarts.EChartOption.Series> = useMemo(() => {

    const max: any = _.max(data?.map((item: any) => Number(item.value))) || 5000
    const list = _.sortBy(data?.map((item: any) => ({ value: Number(item.value), name: item.name })), "value")

    return {
      xAxis: {
        type: 'value',
        max: max + 1000,
        show: false
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 0,
        left: "32%",
        bottom: 0
      },
      yAxis: {
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
        data: list?.map(item => item.name)
      },
      series: [
        {
          cursor: 'default',
          name: '背景色',
          type: 'bar',
          label: {
            position: "right",
            show: true,
          },
          xAxisIndex: 0,
          yAxisIndex: 0,
          barGap: '-100%',
          barWidth: 8,
          z: 0,
          data: list?.map(item => {
            return {
              value: max + 1000,
              label: {
                formatter: `${item.value}`,
                show: true,
                color: "#666"
              },
              itemStyle: {
                color: "#dfe5f1",
                barBorderRadius: 8
              }
            }
          })
        },
        {
          cursor: 'default',
          name: '数据',
          type: 'bar',
          barWidth: 8,
          data: list?.map(item => {
            return {
              value: item.value,
              itemStyle: {
                barBorderRadius: 8,
                color: {
                  type: 'linear',
                  x: 0,
                  x2: (max + 1000) / item.value,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#359ffe'
                    },
                    {
                      offset: 1,
                      color: '#e31c83'
                    }
                  ],
                }
              }
            }
          })
        }
      ]
    };

  }, [data])

  return (
    <ReactEcharts style={{ height: 800 }} option={options as any} />
  );
}
