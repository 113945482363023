import VocationalRow from "../../../../../components/VocationalRow";
import React from "react";
import useRequest from "../../../../../lib/useRequest";
import {findSpecialtyList} from "../../../../../service/major";
import {useHistory} from "react-router-dom";


export default function ProcessListTable() {

    const history = useHistory();

    const [ data ] = useRequest(findSpecialtyList);

    function toDetail(subjectCode: string, name: string) {
        history.push(`/practice/DetailList`);
    }

    return (
        <div>
            {data?.map(item => (
                <VocationalRow key={item.code} category={item.name} child={item.child} showProfessional toDetail={(code, name) => toDetail(code, name)} />
            ))}
        </div>
        
    );
}
