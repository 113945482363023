import React, { useMemo, useState } from "react";
import { Table } from 'antd'
import style from "./index.module.scss";

interface iPops {
    tableData: any,
    title: string,
    thData: any,
    hiddenTable: () => void
}


export default function TabTable4 (props: iPops) {
    const [data, setData] = useState([]);
    const [tableTitle, setTableTitle] = useState('');
    const [thData, setThData] = useState([]);
    useMemo(() => {
        setTableTitle(props.title)
    }, [props.title])

    useMemo(() => {
        setData(props.tableData)

    }, [props.tableData])

    useMemo(() => {
        setThData(props.thData);
    }, [props.thData])

    const columns = [
        {
            title: '学校名称',
            dataIndex: 'school_name',
        },
        {
            title: '学校代码',
            dataIndex: 'school_code',
        },
        {
            title: '学生数',
            dataIndex: 'stuNum',
            sorter: {
                compare: (a: any, b: any) => a.stuNum - b.stuNum
            }
        },
        {
            title: '派出教师数',
            dataIndex: 'teaNum',
            sorter: {
                compare: (a: any, b: any) => a.teaNum - b.teaNum
            }
        },
        {
            title: '生师比',
            dataIndex: 'ssb',
            sorter: {
                compare: (a: any, b: any) => a.teaNum - b.teaNum
            }
        }
    ]
    const dataSource = data?.map((item: any) => {
        return {
            school_name: item.school_name,
            school_code: item.school_code,
            stuNum: item.stuNum,
            teaNum: item.teaNum,
            ssb: Number(item.ssb).toFixed(2)
        }
    })
    return (
        <div className={style.list}>
            <div className={style.title}>
                <div className={style.tableTitle}>{tableTitle}</div>
                <div className={style.closeButton} onClick={() => {
                    props.hiddenTable();
                }
                }></div>
            </div>
            <div style={{ height: '450px', overflowY: 'auto' }}>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </div>
        </div>
    )
}
