import { Spin } from "antd";
import React, { useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { findSjBaseList } from "../../../../../service/major";
import style from '../basic/index.module.scss'

export default function ConditionInfo({subjectId}: {subjectId: string}) {

    const [ data, { loading } ] = useRequest(() => findSjBaseList(subjectId))

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>校外实践基地情况</h1>
                </div>
                <div className={style.table}>
                    <Spin spinning={loading}>
                        <table>
                            <tr>
                                <th>基地名称</th>
                                <th>建立时间</th>
                                <th>依托企业(单位)</th>
                                <th>实习学生人次数</th>
                            </tr>
                            {data?.map((item, index) => (
                                <tr key={`PracticeBase-${index}`}>
                                    <td>{item.baseName}</td>
                                    <td>{item.createTime}</td>
                                    <td>{item.relyonEnterprise}</td>
                                    <td>{item.perStusxCount}</td>
                                </tr>
                            ))}
                        </table>
                    </Spin>
                </div>
            </div>
        </div>
    );
}
