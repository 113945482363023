import React, {useEffect, useState} from "react";
import useRequest from "../../../../lib/useRequest";
import {
    findCityListBySubjectBigCode,
    findSubjectBigList, get1XMS, get1xNum, getCSXS, getDoubleLevelList, getSGMX, getsgNum, ICityCountInfo,
    INewDataTypes, ISchoolInfo,
    ISubjectItem
} from "../../../../service/chart";
import cityDatasPage2 from "../../../../assets/datas/cityDatas2";
import Province from "../Province/Provice";
import {message} from "antd";
import {errorMessage} from "../../../../lib/utils";
import cityCoordData from "../../../../assets/cityCoord";
import useRequestFn from "../../../../lib/useRequestFn";

interface IProps{
    showTimeLine: boolean;
    width: number;
    height: number;
}

export default function SecondScreen({showTimeLine, width, height}: IProps) {
    const [subjectList] = useRequest(() => findSubjectBigList());
    const [data, setData] = useState<INewDataTypes[]>([]);
    const [typesData, setTypeData] = useState<string[]>([]);
    const [schoolList, setSchoolList] = useState<ISchoolInfo[]>([]);
    let tempData: INewDataTypes[] = [];

    const [blueCountData] = useRequest(() => get1xNum());
    const [redCountData] =useRequest(() => getsgNum());

    useEffect(() => {
        if(subjectList) {
            getInfo(subjectList);
            const tempTypesData = subjectList.map(item => item.name);
            setTypeData(tempTypesData);
        }
    }, [subjectList]);

    function getInfo(subjectList: ISubjectItem[]) {

        try {
            const promiseArr = subjectList.map(item => {
                return getCitiesInfo(item);
            });

            Promise.all(promiseArr).then(() => {
                setData(tempData);
            });


        } catch (e) {
            message.error(errorMessage(e));
        }
    }
    async function getCitiesInfo(obj: ISubjectItem) {
        const res = await findCityListBySubjectBigCode(obj.code);
        const data = formatData(res, obj);
        tempData = [...tempData, ...data];
    }

    function formatData(data: ICityCountInfo[], obj: ISubjectItem) {
        return data.map(item => {
            return {
                name: item.name,
                coord: cityCoord(item.name),
                value: parseInt(item.value),
                circleColor: '#f00',
                areaColor: '#f8f8f8',
                year: obj.name
            }
        });
    }

    function cityCoord(cityName: string) {
        const cityInfo = cityCoordData.filter(item => item.name === cityName);
        if (cityInfo.length === 0) return [];
        return cityInfo[0].coord;
    }

    function getSchoolList(val: string, level: string) {
        if(!level){
            getCSXS(val).then((data) => {
                setSchoolList(data);
            });
        }
        if(level) {
            if(level === "国家级") {
                getSGMX(val).then((data) => {
                    setSchoolList(data);
                });
            }

            if(level === "省级") {
                get1XMS(val).then((data) => {
                    setSchoolList(data);
                });
            }

        }
    }

    function onClean() { // 清除弹出层表单数据
        setSchoolList([]);
    }

    return <div>
        {data && typesData && <Province special={true} name='辽宁' tableType={2} width={width} height={height} data={data}
                        year={typesData}
                        // geoColor='27, 109, 14'
                        geoColor='230, 182, 0'
                        schoolList={schoolList || []}
                        showTimeLine = {showTimeLine}
                        changeCityName={(val, levelName) => getSchoolList(val, levelName)}
                        pointsBlue={blueCountData?.map(item => {
                            return {
                                areaCode: item.name,
                                schCount: item.value
                            }
                        })}
                        pointsRed={redCountData?.map(item => {
                            return {
                            areaCode: item.name,
                            schCount: item.value
                            }
                        })}
                        onClean={onClean}
                        // schoolListSpecial={schoolListSpecial || []}
                />}
    </div>
}

SecondScreen.defaultProps = {
    showTimeLine: true,
    width: 960,
    height: 800
}
