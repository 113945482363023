import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Select, Steps, Tag } from "antd";
import style from "./index.module.scss";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";

const { Step } = Steps;
const { CheckableTag } = Tag;
interface FangAnSelectorProps {
  visible: boolean;
  onSelect: (value: any) => void;
  onClose: () => void;
}

export default function FangAnSelector ({ visible, onSelect, onClose }: FangAnSelectorProps) {

  const [current, setCurrent] = React.useState(0);
  const [value, setValue] = useState < any > ({});

  function onNext () {
    if (!value.subjectName && current === 0)
    {
      message.warn("请选择专业");
      return;
    }

    if (!value.subjectName && current === 1)
    {
      message.warn("请选择方案");
      return;
    }
    if (current === 0)
    {
      setCurrent(current + 1);
      return;
    }
    onSelect(value);
    onBeforeClose();

  }

  function onBeforeClose () {
    setCurrent(0);
    // setValue({});
    onClose();
  }

  return (
    <Modal visible={visible} destroyOnClose onCancel={onBeforeClose} title="选择培养方案" footer={[
      <Button key="1" disabled={current === 0} onClick={() => setCurrent(current - 1)}>上一步</Button>,
      <Button key="2" type="primary" onClick={onNext}>{current !== 1 ? `下一步` : '确定'}</Button>
    ]}>
      <Selector current={current} value={value} onChange={setValue} />
    </Modal>
  );
}


interface SelectorProps {
  current: number;
  value: any;
  onChange: (val: any) => void;
}

function Selector ({ current, value, onChange }: SelectorProps) {

  // const [searchText, setSearchText] = useState("物流管理");
  // const [getMajorList, pyfapksearchState] = useRequestFn(pyfapksearch);
  // const [getPyfapknext, pyfapknextState] = useRequestFn(pyfapknext);
  const [searchText, setSearchText] = useState('');
  const [searchRes, setSearchRes] = useState(value.subjectName)
  // const [getMajorList, pyfapksearchState] = useRequestFn(pyfapksearch);
  // const [getPyfapknext, pyfapknextState] = useRequestFn(() => getNormalData < any > ('debc04b1-48b5-447a-8c8e-60ebea3e5731', { subject_name: value.subjectName }));
  // const [getMajorList, pyfapksearchState] = useRequestFn(() => getNormalData < any > ('68ecebef-3202-49c2-b278-b21828b737b2', { subject_name: searchText }))
  const [getMajorList, pyfapksearchState] = useRequestFn(() => getNormalData < any > ('2c5577df-9c31-479f-bed5-d4e9634074ab', { courseName: searchText }))//搜获课程
  const [getPyfapknext, pyfapknextState] = useRequestFn(() => getNormalData < any > ('5be2eec2-21ff-404a-931d-4fcff24f6562', { courseName: value.subjectName }));




  useEffect(() => {
    if (!value.subjectName) return;
    getPyfapknext();
  }, [value.subjectName]);

  async function onSearch () {
    if (searchText == '')
    {
      message.warn("请输入课程名称");
      return
    }
    getMajorList();
  }

  function getTrainingList () {
    if (!pyfapknextState.data) return [];
    return pyfapknextState.data.find((item: any) => item.schoolName === value.school)?.subjectList || [];
  }

  function getZyCount () {
    let num = 0;
    for (let item of pyfapknextState.data || [])
    {
      num += item.subjectList.length
    }
    return num;
  }

  return (
    <div>
      <Steps current={current} size="small" style={{ paddingLeft: 100, paddingRight: 100 }}>
        <Step key={1} title="选择课程名称" />
        <Step key={2} title="选择院校" />
      </Steps>

      <div className={style.wrapper}>
        {current === 0 && (
          <div>
            <Input placeholder="请输入课程名称" style={{ width: 200 }} value={searchText} onChange={evt => setSearchText(evt.target.value)} />&nbsp;
            <Button type="primary" onClick={onSearch}>搜索</Button>

            <div>
              <h1 className={style.title}>检索结果</h1>

              {pyfapksearchState.data?.map((item: any, index: number) => (
                <div key={index} className={style.searchResult}>
                  <CheckableTag
                    checked={value.subjectName === item.course_name}
                    onClick={() => onChange({ ...value, subjectName: item.course_name })}>
                    {item.course_name}
                  </CheckableTag>
                </div>
              ))}
            </div>
          </div>
        )}

        {current === 1 && (
          <div>
            <div>
              <p>您搜索的 <span style={{ color: "#3897D9" }}>“{value.subjectName}”</span>课程，
                <span style={{ color: "#E65043" }}>{pyfapknextState.data?.length}</span>所院校、
                <span style={{ color: "#E65043" }}>{getZyCount()}</span>个培养方案
              </p>

              <div style={{ textAlign: "center", width: 200, marginLeft: "auto", marginRight: "auto" }}>
                <p style={{ textAlign: "left", color: "#F0552F" }}>必须选择一所院校</p>
                <Select placeholder="选择院校" style={{ width: "100%", textAlign: "left" }} onChange={(val) => onChange({ ...value, school: val })}>
                  {pyfapknextState.data?.map((item: any, index: number) => <Select.Option key={index} value={item.schoolName}>{item.schoolName}</Select.Option>)}
                </Select>
              </div>

              <div style={{ textAlign: "center", width: 200, marginLeft: "auto", marginRight: "auto" }}>
                <p style={{ textAlign: "left", color: "#F0552F" }}>必须选择一个培养方案</p>
                <Select placeholder="选择培养方案" style={{ width: "100%", textAlign: "left" }} onChange={(val) => onChange({ ...value, subjectId: val })}>
                  {getTrainingList().map((item: any) => <Select.Option key={item} value={item.courseId}>{item.subject_name}</Select.Option>)}
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
