import {Button, Modal, Table} from "antd";
import React, {useState} from "react";
import {ColumnsType} from "antd/lib/table/interface";

interface PopCol1Props {
    num: number;
}

export default function PopCol6({num}: PopCol1Props) {

    const [visible, setVisible] = useState(false);

    const columns: ColumnsType<any> = [
        {
            title: "项目名称",
            dataIndex: "col1",
        },
        {
            title: "项目团队总人数",
            dataIndex: "col2",
            align:"center",
        },
        {
            title: "项目占用课时数",
            dataIndex: "col3",
            align:"center",
        },
        {
            title: "已服务人数",
            dataIndex: "col4",
            align:"center",
        },
        {
            title: "详细",
            dataIndex: "col5",
            render:() => <a href="http://www.lnve.net/gxpt/projectDetail.html?id=1" target="_blank">详细信息</a>
        },
        
    ];

    const dataSource: any[] = [
        {col1: "物流系统仿真实训", col2: "5", col3: 18, col4: 106, col5:"详细信息"},
        {col1: "仓储管理综合模拟实验", col2: "8", col3: 20, col4: 228, col5:"详细信息"},
        {col1: "保税物流实训", col2: "7", col3: 20, col4: 174, col5:"详细信息"},
        {col1: "供应链运营管理", col2: "12", col3: 30, col4: 334, col5:"详细信息"},
    ];

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>{num}</Button>

            <Modal visible={visible} onCancel={() => setVisible(false)} title="虚拟仿真实验教学项目数" width={800} footer={false}>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Modal>
        </>
    );
}
