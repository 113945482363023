import React from "react";

export default function <T = any>(Component: React.FC<T> | React.ComponentClass<T>) {

    return class C extends React.Component<any, any> {
        shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
            return false;
        }

        render() {
            return React.createElement(Component, this.props);
        }
    };
}
