import { useHistory } from "react-router-dom";
import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import { getNormalData } from "../../../../../service/basic";
import useRequest from "../../../../../lib/useRequest";

var color = [
  "#8d7fec",
  "#5085f2",
  "#e75fc3",
  "#f87be2",
  "#f2719a",
  "#fca4bb",
  "#f59a8f",
  "#fdb301",
  "#57e7ec",
  "#cf9ef1",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#ee167b",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#8d7fec",
  "#5085f2",
  "#e75fc3",
  "#f87be2",
  "#f2719a",
  "#fca4bb",
  "#f59a8f",
  "#fdb301",
  "#57e7ec",
  "#cf9ef1",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#ee167b",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#8d7fec",
  "#5085f2",
  "#e75fc3",
  "#f87be2",
  "#f2719a",
  "#fca4bb",
  "#f59a8f",
  "#fdb301",
  "#57e7ec",
  "#cf9ef1",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#ee167b",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#8d7fec",
  "#5085f2",
  "#e75fc3",
  "#f87be2",
  "#f2719a",
  "#fca4bb",
  "#f59a8f",
  "#fdb301",
  "#57e7ec",
  "#cf9ef1",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#ee167b",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#8d7fec",
  "#5085f2",
  "#e75fc3",
  "#f87be2",
  "#f2719a",
  "#fca4bb",
  "#f59a8f",
  "#fdb301",
  "#57e7ec",
  "#cf9ef1",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#ee167b",
  "#dab606",
  "#06da90",
  "#4467f4",
  "#dab606",
  "#06da90",
];
let num = 5
const random = () => {
  return Math.random() * (66 - 4) + (num + 25);
}



interface IProps {
  customOption?: (
    opt: EChartOption<EChartOption.Series>
  ) => EChartOption<EChartOption.Series>;
  style?: any;
}

export default function Index ({ customOption, style }: IProps) {
  const history = useHistory()
  //毕业实习行业流向
  const [data] = useRequest(() => getNormalData < any > ("1b0bfb2e-62c9-4676-bd4a-0a8851bff985"));



  const opt = useMemo(() => {
    if (data)
    {
      let arr: any = []
      let num1: number = -1
      let num2: number = Math.floor(data.length / 6) - (data.length / 6)
      data?.map((item: any) => {
        let n1: number = num1 < 6 ? num1 += 1 : num1 = 0
        let n2: number = num1 == 6 ? num2-- : num2
        let n3: number = Math.floor(Math.random() * (64 - 40 + 1) + 40);
        let label: string = item.label && item.label.length > 3 ? item.label.substring(0, 3) + "..." : item.label || ''
        arr.push([n1, n2, n3, item.label, item.value, label],)
      })
      return {
        ...data.option,
        xAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          min: 1,
          show: false
        },
        grid: {
          top: 30,
          containLabel: false
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          scale: false,
          show: false
        },
        tooltip: {
          show: true,
          formatter: (params: any) => {
            return `${params.data[4]}<br />${params.data[3]}`
          }
        },
        axisLabel: {
          color: "#000",
          interval: 0,
          formatter: function (value: string) {
            if (value.length > 5)
            {
              return value.substring(0, 5) + "...";
            } else
            {
              return value;
            }
          }
        },
        series: [{
          cursor: 'default',
          symbolSize: function (data: number[]) {
            return data[2]

          },
          lineStyle: {
            type: 'solid'
          },
          data: arr,
          type: 'scatter',
          label: {
            normal: {
              formatter: (params: any) => {
                return '{title|' + params.data[4] + ' }' + '\n' + '{title|' + params.data[5] + '}';
              },
              color: '#fff',
              fontSize: 12,
              show: true,
              rich: {
                title: {
                  color: "#ffffff",
                  width: 100,
                  align: 'center'
                }
              }
            },
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            color: function (params: any) {
              return color[params.dataIndex]
            }
          }
        }]
      }
    } else
    {
      return {};
    }
  }, [data]);

  return <ReactEcharts
    style={{ width: '540px', height: `${data && data.length ? Math.ceil(data.length / 6) * 95 : 300}px`, left: '20px', cursor: 'default' }} theme={"theme"}
    option={opt} />
}
