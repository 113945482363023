import {CourseGoalType, CourseGoalType2} from "../../../../../service/training";
import ShowText from "../ShowText";

interface ShowItemsProps {
    data: string[];
}

export default function ShowItems({data}: ShowItemsProps) {

    return (
        <div>
            {data.map(item => <ShowText max={15} text={item} />)}
        </div>
    );
}
