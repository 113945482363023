import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import {
  findCityListBySubjectBigCode,
  findSubjectBigList,
  get1XMS,
  get1xNum,
  getCSXS,
  getDoubleLevelList,
  getSGMX,
  getsgNum,
  ICityCountInfo,
  IDataTypes,
  INewDataTypes, IPoint,
  ISchoolInfo,
  ISubjectItem,
} from "../../../../service/chart";
import cityDatasPage2 from "../../../../assets/datas/cityDatas2";
import Province from "../Province/ProviceSearch1";
import { message } from "antd";
import { errorMessage } from "../../../../lib/utils";
import cityCoordData from "../../../../assets/cityCoord";
import useRequestFn from "../../../../lib/useRequestFn";
import { Select } from "antd";
import {
  findCityListBySubjectName,
  findFilterSubjectName,
} from "../../../../service/major";
import SecondScreen from "../SecondScreen";
import { getSubjectCountNum } from "../../../../service/report";
import { getNormalData } from "../../../../service/basic";
import useDevice from "../../../../lib/useDevice";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
}

export default function SearchScreen ({ showTimeLine, width, height }: IProps) {
  const [subject, setSubject] = useState("");
  // const [subjectsList] = useRequest(() => findFilterSubjectName(""));
  const [subjectsList] = useRequest(() => getNormalData < any > ("8726e718-ad8a-4765-9e3a-56879ad72608"));

  const [subjectData] = useRequest(
    () => findCityListBySubjectName(subject),
    {},
    [subject]
  );

  const [data, setData] = useState < INewDataTypes[] > ([]);
  const [typesData, setTypeData] = useState < string[] > (["专业"]);
  const [schoolList, setSchoolList] = useState < ISchoolInfo[] > ([]);

  let [valueName, setValueName] = useState('');
  let [subValueName, setSubValueName] = useState('')

  let tempData: INewDataTypes[] = [];

  const [blueCountData] = useRequest(() => get1xNum(subject), {}, [subject]);
  // const [redCountData] = useRequest(() => getsgNum(subject), {}, [subject]);
  const [SubjectCountNum] = useRequest(() => getNormalData < IPoint[] > ('7f40c288-db5c-41f3-ade7-c9320593879a', { subjectName: subject }), {}, [subject]); //
  // const [ SubjectCountNum ] = useRequest(() => getSubjectCountNum(subject), {}, [subject])

  const [keys, setKeys] = useState([]);

  const { isMobile } = useDevice()
  const setOptions = () => {
    const res: any = [];
    subjectsList?.forEach((item: any, index: number) => {
      if (!res.includes(item))
      {
        res.push(item);
      }
    })
    setKeys(res);
  }

  useMemo(() => {
    setOptions();
  }, [subjectsList])

  const { Option } = Select;

  useEffect(() => {
    if (subjectData)
    {
      setData(formatData(subjectData));
    }
  }, [subjectData]);

  function formatData (data: ICityCountInfo[]) {
    const res = data.map((item) => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseInt(item.value),
        circleColor: "#f00",
        areaColor: "#f8f8f8",
        year: "专业",
      };
    });

    return buchongData(res);
  }

  function buchongData (data: INewDataTypes[]) {
    // 后台不存在的城市要补充上
    const cityArr = data.map((item) => item.name);
    const res = cityCoordData.filter(
      (item) => !cityArr.includes(item.name)
    );
    return [
      ...data,
      ...res.map((item) => {
        return {
          name: item.name,
          coord: cityCoord(item.name),
          value: 0,
          circleColor: "#f00",
          areaColor: "#f8f8f8",
          year: "专业",
        };
      }),
    ];
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter((item) => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  function getSchoolList (val: string, level: string) {
    /*        getCSXS(val, subject).then((data) => {
                // 当前的选择的城市
                setValueName(val);
                setSubValueName(subject);
                // 设置数据
                setSchoolList(data);
            });*/
    getNormalData < {
      bds: string;
      schoolName: string;
      subjectCode: string;
      subjectName: string;
    }[] > ('6ca90ec6-438b-4f3c-8723-29d510b91047', { 'areaCode': val, 'subjectName': subject })
      .then(data => {
        // 当前的选择的城市
        setValueName(val);
        setSubValueName(subject);
        // 设置数据
        // @ts-ignore
        setSchoolList(data.map(item => ({
          is_1x: "2",
          is_sg: "无",
          schoolName: item.schoolName,
          studentCount: item.subjectCode,
          subjectName: item.subjectName,
          teaCount: item.bds,
          yjbysCount: null
        })));
      });

    return;

    if (!level)
    {
    }
    if (level)
    {
      if (level === "国家级")
      {
        getSGMX(val, subject).then((data) => {
          setSchoolList(data);
        });
      }
      if (level === "省级")
      {
        get1XMS(val, subject).then((data) => {
          setSchoolList(data);
        });
      }
    }
  }
  //  添加查询方法
  function searchData (value: string) {
    // valueName：沈阳市  value：当前下拉框的值
    // getCSXS(valueName, value).then((data) => {
    //   setTimeout(() => {
    //     setSchoolList(data);
    //   }, 200)
    // });

    getNormalData < {
      bds: string;
      schoolName: string;
      subjectCode: string;
      subjectName: string;
    }[] > ('6ca90ec6-438b-4f3c-8723-29d510b91047', { 'areaCode': valueName, 'subjectName': value })
      .then(data => {
        // 当前的选择的城市
        setValueName(valueName);
        setSubValueName(subject);
        // 设置数据
        // @ts-ignore
        setSchoolList(data.map(item => ({
          is_1x: "2",
          is_sg: "无",
          schoolName: item.schoolName,
          studentCount: item.subjectCode,
          subjectName: item.subjectName,
          teaCount: item.bds,
          yjbysCount: null
        })));
      });

  }
  function onchange (val: string) {
    setSubject(val);
  }

  function onClean () {
    // 清除弹出层表单数据
    setSchoolList([]);
  }



  return (
    <div style={{ position: "relative" }}>
      {data && typesData && (
        <>
          <div style={{ textAlign: "left", zIndex: 2, marginLeft: "105px" }}>
            <Select
              showSearch
              size={"large"}
              placeholder="请输入专业"
              style={{ width: isMobile ? '' : 300, textAlign: "left" }}
              onChange={onchange}
              allowClear
            >
              {keys?.map((item: any, index) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </div>
          {/* <SecondScreen showTimeLine={false} width={600} height={700} /> */}
          <Province
            name="辽宁"
            tableType={2}
            width={width}
            height={height}
            data={data}
            year={typesData}
            geoColor="0, 167, 237"
            schoolList={schoolList || []}
            showTimeLine={showTimeLine}
            changeCityName={(val, levelName) =>
              getSchoolList(val, levelName)
            }
            pointsBlue={blueCountData?.map((item: any) => {
              return {
                areaCode: item.name,
                schCount: item.value,
              };
            })}
            pointsRed={SubjectCountNum?.map((item: any) => {
              return {
                areaCode: item.name,
                schCount: item.value,
              };
            })}

            onSearch={searchData}
            onClean={onClean}
          // schoolListSpecial={schoolListSpecial || []}
          />
        </>
      )}
    </div>
  );
}

SearchScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800,
};
