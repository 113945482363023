import ReactEcharts from 'echarts-for-react';
import {EChartOption} from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";
import _ from "underscore";

const Options: EChartOption<EChartOption.Series> = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
    },
    grid: {
        left: '4%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: "#ffffff"
        }
    },
    xAxis: {
        type: 'category',
        data: ["总体情况", "大学", "硕士研究生",  "其他"],
        axisLabel: {
            rotate: 30,
            color: "#ffffff"
        }
    },
    series: [
        {
            stack: "总量",
            data: [18863, 11631, 5571, 1652],
            name: "总数",
            label: { show: true, position: "insideRight", color: "#ffffff" },
            type: "bar",
        },
        {
            stack: "总量",
            data: [4541, 2106, 2019, 13+408],
            name: "35岁及以下",
            label: { show: true, position: "insideRight" },
            type: "bar",
        },
        {
            stack: "总量",
            data: [7338, 4221, 2729, 141+247],
            name: "36－45岁",
            label: { show: true, position: "insideRight" },
            type: "bar",
        },
        {
            stack: "总量",
            data: [6140, 4877, 751, 101+411],
            name: "46－60岁",
            label: { show: true, position: "insideRight" },
            type: "bar",
        },

    ]
};


interface IProps{
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    style?: any
}

export default function Index({customOption, style}: IProps) {
    const history = useHistory();
    const [data] = useRequest(() => getNormalData<{[key: string]: string[]}>('3396366e-0321-4172-910d-f5e224f9363c')); // 教师学历分布图

    return (
        <>
            {data && (
                <ReactEcharts
                    onEvents={{"click": () => history.push("/basic/main")}}
                    style={style}
                    theme={"theme"}
                    option={{
                        ...Options,
                        series: _.keys(data).map(item => ({
                            stack: "总量",
                            data: data[item],
                            name: item,
                            label: { show: true },
                            type: "bar",
                        }))
                    }}
                />
            )}
        </>
    );
}
