import {Table} from "antd"
import useRequest from "../../../lib/useRequest";
import { ColumnsType } from "antd/lib/table/interface";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";
import style  from "./reach.module.scss"
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
export default function ReachStandard(){
    const [data, { loading }] = useRequest(() => getNormalData < any > ('47274817-a36c-11ec-ba43-005056958024'))             
    const { isMobile } = useDevice();
    const [sortData,setData] = useState(data)
    // 达标标准数组
    // const [standardArr, setArr] =  useState<any>()
    // 渲染上下箭头
    const renderArrows = ( value :string,num : string)=>{
        return <div >
                    <span >{value}&nbsp;</span>
                    {
                    parseFloatFun(num,value)
                    ?<CaretUpOutlined style={{ color:"green" }} />
                    :<CaretDownOutlined style={{ color:"red" }} />
                    }
                </div>
    }
    // 数值对比
    const parseFloatFun =(reachValue : string, realityValue : string) => {
        return  parseFloat(realityValue)- parseFloat(reachValue) >0 ? true : false
    }
    // 检测是否达标
    const isReach = ( value :any )=>{
        let { zrjs_scale, jyyjsxwjs_scale, sjjxxzyf_area, sjjxkyyqsb_cost, ts_sj_count, 
            hg_ssb, hg_yjsxwjs_scale, hg_sjjxxzyf_area, hg_sjjxkyyqsbz_cost, hg_sjts_count } = value
        return (parseFloatFun(hg_ssb, zrjs_scale)&&
                parseFloatFun(hg_yjsxwjs_scale, jyyjsxwjs_scale)&&
                parseFloatFun(hg_sjjxxzyf_area, sjjxxzyf_area)&&
                parseFloatFun(hg_sjjxkyyqsbz_cost, sjjxkyyqsb_cost)&&
                parseFloatFun(hg_sjts_count, ts_sj_count))
                ? true
                : false
    }
    // 渲染达标文本
    const renderIsReach = ( value : any ) => {
        return <div >
                    {
                        isReach(value)
                        ? <span style={{color:'green'}}>已达标</span>
                        : <span style={{color: 'red'}}>未达标</span>
                    }
                </div>
    }

    // 监听实际标准数据获取标准数组
    useEffect(()=>{
        // const xxlb = 'xxlb'
        // let arr:any = []
        if(data && data.length>0){
        //     data.forEach((element:any) => {
        //         if(!arr.some((item: any) => item[xxlb]==element[xxlb])){
        //                 arr.push(element)
        //         }
        //     });
        // setArr(arr.sort((a:any,b:any) => parseFloat(a.xxlb) - parseFloat(b.xxlb)))
        setData(data.sort((a:any,b:any)=> parseFloat(a.xxlb) - parseFloat(b.xxlb)))
        }
    },[data])
    const columns: ColumnsType<any> = [
        {
            title: '学校名称',
            dataIndex: 'school_name',
            width:200,
            ellipsis:true,
            fixed: isMobile ? "left" : false,
            align:'center'
        },
        // {
        //     title: '学校类别',
        //     dataIndex: 'xxlb',
        //     responsive: ['md'], // 手机分辨率不显示该列
        //     align:'center',
        //     width:150,
        //     filters: [
        //         { text: '综合大学', value: '01综合大学' },
        //         { text: '理工院校', value: '02理工院校' },
        //         { text: '农业院校', value: '03农业院校' },
        //         { text: '林业院校', value: '04林业院校' },
        //         { text: '医药院校', value: '05医药院校' },
        //         { text: '师范院校', value: '06师范院校' },
        //         { text: '财经院校', value: '08财经院校' },
        //         { text: '政法院校', value: '09政法院校' },
        //         { text: '艺术院校', value: '11艺术院校' },
        //         { text: '民族院校', value: '12民族院校' },

        //     ],
        //     onFilter: (value: any, record: any) => record.xxlb === value, 
        // },
        {
            title: '生师比',
            align:'center',
            children:[
                {
                    title:'( 百分比 )',
                    dataIndex: 'zrjs_scale',
                    align:'center',
                    width:150,

                    render: ((text,record)=> renderArrows(text,record.hg_ssb))
                }
            ]
        },
        {
            title: '具有研究生学位教师占专任教师的比例',
            align:'center',

            children:[
                {
                    title:'( 百分比 )',
                    dataIndex: 'jyyjsxwjs_scale',
                    align:'center',
                    render: ((text,record)=> renderArrows(text,record.hg_yjsxwjs_scale))
                }
            ]
        },
        {
            title: '生均教学行政用房合格指标',
            align:'center',
            children:[
                {
                    title:'( 平方米 / 生 )',
                    dataIndex: 'sjjxxzyf_area',
                    align:'center',
                    render: ((text,record)=> renderArrows(text,record.hg_sjjxxzyf_area))
                }
            ]
        },
        {
            title: '生均教学科研仪器设置值',
            align:'center',
            children:[
                {
                    title:'( 元 / 生 )',
                    dataIndex: 'sjjxkyyqsb_cost',
                    align:'center',
                    render: ((text,record)=> renderArrows(text,record.hg_sjjxkyyqsbz_cost))
                }
            ]
        },
        {
            title: '生均图书',
            align:'center',
            children:[
                {
                    title:'( 册 / 生 )',
                    dataIndex: 'ts_sj_count',
                    align:'center',
                    render: ((text,record)=> renderArrows(text,record.hg_sjts_count))
                }
            ]
        },
        {
            title: '是否达标',
            align:'center' ,
            render: ((text,record)=> renderIsReach(record)),
            filters: [
                { text: '已达标', value: '已达标' },
                { text: '未达标', value: '未达标' },
            ],
            onFilter: (value: any, record: any) =>{
                return (isReach(record)&&value=='已达标') || (!isReach(record)&&value=='未达标')
            } ,

        },
    ];
    const columns2: ColumnsType<any> = [
        {
            title: '学校类别',
            dataIndex: 'xxlb',
            responsive: ['md'], // 手机分辨率不显示该列
            align:'center'
        },
        {
            title: '高职专科',
            align:'center',
            children:[
                {
                    title:'生师比(%)',
                    align:'center',
                    dataIndex:'ssb'
                },
                {
                    title: '具有研究生学位教师占专任教师的比例(%)',
                    align:'center',
                    dataIndex: 'yjs',
                },
                {
                    title: '生均教学行政用房合格指标(平方米/生)',
                    align:'center',
                    dataIndex: 'sjjxxzyf',
                },
                {
                    title: '生均教学科研仪器设备值(元/生)',
                    align:'center',
                    dataIndex: 'sjjxkyq',
                },
                {
                    title: '生均图书(册/生)',
                    align:'center',
                    dataIndex:'sjts'
                }
            ]
        }
    ];
    const standardArr: any = [
        {
            id:1,
            xxlb:'综合、师范、民族院校',
            ssb:18,
            yjs:15,
            sjjxxzyf:14,
            sjjxkyq:4000,
            sjts:80
        },
        {
            id:2,
            xxlb:'工科、农、林院校',
            ssb:18,
            yjs:15,
            sjjxxzyf:16,
            sjjxkyq:4000,
            sjts:60
        },
        {
            id:3,
            xxlb:'医学院校',
            ssb:16,
            yjs:15,
            sjjxxzyf:16,
            sjjxkyq:4000,
            sjts:60
        },
        {
            id:4,
            xxlb:'语文 财经 政法院校',
            ssb:18,
            yjs:15,
            sjjxxzyf:9,
            sjjxkyq:3000,
            sjts:80
        },
        {
            id:5,
            xxlb:'体育院校',
            ssb:13,
            yjs:15,
            sjjxxzyf:22,
            sjjxkyq:3000,
            sjts:50
        },
        {
            id:6,
            xxlb:'艺术院校',
            ssb:13,
            yjs:15,
            sjjxxzyf:18,
            sjjxkyq:3000,
            sjts:60
        },
    ]
    return (<div className={style.root}>
            <div className={style.tableContent2}>
                <div className={style.tableTitle}>
                    <h1>各类高职院校基本办学条件指标</h1>
                </div>
                <Table
                    bordered={true}
                    loading={loading}
                    className={style.reachTable}
                    columns={columns2}
                    dataSource={standardArr || []}
                    pagination={false}
                    scroll={isMobile ? { x: 800 } : undefined}
                />
            </div>
            <div className={style.tableContent}>
                <div className={style.tableTitle}>
                    <h1>达标情况列表</h1>
                </div>
                <Table
                    loading={loading}
                    className={style.table}
                    columns={columns}
                    dataSource={sortData || []}
                    pagination={false}
                    scroll={isMobile ? { x: 800 } : undefined}
                    rowClassName={(record, index) => {
                        let className = index % 2 ? style.shallow_gray : style.deep_gray;
                        return className
                    }}
                />
            </div>

    </div>)
    
    
}