import useRequest from "../../../../lib/useRequest";
import { findCountValue, getNormalData } from "../../../../service/basic";
import style from "../../style.module.scss";
import { Col, Row } from "antd";

import React from "react";
import {
  countDockingAgriculture,
  countDockingWaiter,
  countGraduateStudent,
  countIndustry
} from "../../../../service/employment";
import styles from "../../../Employment/Main/components/Statistics/style.module.scss";
import {
  countCourse002,
  countCourse006,
  countCurrentSchool,
  countCurrentStudent,
  countCurrentTeacher
} from "../../../../service/course";
import useDevice from "../../../../lib/useDevice";

interface IProps {
  type?: string;
}

export default function StatisticsOne ({ type }: IProps) {
  const [school] = useRequest(() => findCountValue("count_college_num_2020"));
  const [teacher] = useRequest(() => findCountValue("count_teacher_num_2020"));
  const [student] = useRequest(() => findCountValue("count_student_num_2020"));
  const [cost] = useRequest(() => findCountValue("count_cost_num_2020"));


  return (
    <div className={`${type === 'basic' ? style.basic : style.statistics}`}>
      <div className={style.item}>
        <div className={style.label}>高职院校数</div>
        <div className={style.number}>{parseInt(school?.countValue || '0')}所</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>在校生数</div>
        <div className={style.number}>{parseInt(student?.countValue || '0')}人</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>教师数量</div>
        <div className={style.number}>{parseInt(teacher?.countValue || '0')}人</div>
      </div>
      <div className={style.item} style={type === 'basic' ? { flex: "0 0 230px" } : {}}>
        <div className={style.label}>资金投入</div>
        <div className={style.number}>{parseInt(cost?.countValue || '0')}<span>(万元)</span></div>
      </div>
    </div>
  )
}

export function Statistics1 () {
  const [collegeNum] = useRequest(() => getNormalData < string > ('2ac720f7-9848-4620-ab2b-632903f1a4b5')); // 院校数量
  const [studentNum] = useRequest(() => getNormalData < string > ('2ba10854-6a85-4001-8206-7937136ab158')); //
  const [teacherNum] = useRequest(() => getNormalData < string > ('7f40ec3e-6cfc-4fe9-8bff-0e69e9f43428')); // 教师数量
  // const [cost] = useRequest(() => getNormalData < string > ('768b0168-33bc-4f75-9aef-4c461c4255da')); // 经费总投入
  const { isMobile } = useDevice();
  const colWidth = isMobile ? "100%" : "20%";
  return (
    <div className={isMobile ? style.wrapMob : styles.wrap}>
      <div className={`${styles.block1} ${isMobile ? styles.blockMob : ''}`}>
        <div className={styles.label}>高职院校数</div>
        <div className={style.number}> <span className={style.txtNum}>{parseInt(collegeNum || '0')}</span> 所</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block2}`}>
        <div className={styles.label}>在校生数</div>
        <div className={style.number}> <span className={style.txtNum}>{parseInt(studentNum || '0')}</span>人</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block3}`}>
        <div className={styles.label}>教师数量</div>
        <div className={style.number}><span className={style.txtNum}>{parseInt(teacherNum || '0')}</span>人</div>
        <div className={styles.shadow} />
      </div>
      {/* <div className={`${styles.block4}`}>
        <div className={styles.label}>资金投入</div>
        <div className={style.number}><span className={style.txtNum}>{cost ? cost : '0'}</span><span>(万元)</span></div>
        <div className={styles.shadow} />
      </div> */}
    </div>
  )
}

export function StatisticsTwo ({ type }: IProps) {
  const [num] = useRequest(() => findCountValue("count_major_num_2020")); //count_major_num_2020
  const [avg] = useRequest(() => findCountValue("count_major_avg_num_2020")); // 专业均数：count_major_avg_num_2020
  const [sgNum] = useRequest(() => findCountValue("count_major_sg_num_2020")); // 校均双高专业数：count_major_sg_num_2020
  const [stuAvg] = useRequest(() => findCountValue("count_major_student_avg_num_2020")); // 专业平均人数：count_major_student_avg_num_2020
  const [major] = useRequest(() => findCountValue("count_subject_num")); // 专业数：count_subject_num


  return (
    <div className={`${type === 'major' ? style.major : style.statistics}`}>
      <div className={style.item}>
        <div className={style.label}>专业种类数</div>
        <div className={style.number}>{parseInt(major?.countValue || '0')}</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>专业布点数</div>
        <div className={style.number}>{parseInt(num?.countValue || '0')}</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>省级“双高”专业数（布点数）</div>
        <div className={style.number}>{538}</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>国家级“双高”专业数（布点数）</div>
        <div className={style.number}>{86}</div>
      </div>
      {/* <div className={style.item}>
                <div className={style.label}>专业生均数</div>
                <div className={style.number}>{parseInt(stuAvg?.countValue || '0')}<span>(人)</span></div>
            </div> */}
    </div>
  )
}

export function StatisticsThree ({ type }: IProps) {
  return (
    <div className={`${type === 'major' ? style.major : style.statistics}`}>
      <div className={style.item}>
        <div className={style.label}>运行课程数</div>
        <div className={style.number}>58096</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>视频数量</div>
        <div className={style.number}>25920</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>教学课件数量</div>
        <div className={style.number}>31.96(万件)</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>题库数量</div>
        <div className={style.number}>3295(万题)</div>
      </div>
    </div>
  )
}

export function StatisticsService ({ type }: IProps) {
  // const [project] = useRequest(() => findCountValue("count_shehui_project_num_2020")); //count_shehui_project_num_2020 社会服务-服务项目数
  const [project] = useRequest(() => getNormalData < any > ('92b8b3b5-3d1a-4e2b-93f7-23fd03face10')); //count_shehui_project_num_2020 社会服务-服务项目数

  // const [companyNum] = useRequest(() => findCountValue("count_shehui_company_num_2020")); //count_shehui_project_num_2020 社会服务-服务企业数
  const [companyNum] = useRequest(() => getNormalData < any > ('d44a463f-c944-4760-838b-2ab8ceee54ee')); //count_shehui_project_num_2020 社会服务-服务企业数

  // const [order] = useRequest(() => findCountValue("count_shehui_order_train_num_2020")); //count_shehui_order_train_num_2020 社会服务-订单定制培养人数
  const [order] = useRequest(() => getNormalData < any > ('5246f3b3-2b55-4863-9f3d-f9b831bb7aff')); //count_shehui_project_num_2020 社会服务-订单定制培养人数

  // const [person] = useRequest(() => findCountValue("count_shehui_person_train_num_2020")); //count_shehui_project_num_2020 社会服务-员工培训人数
  const [person] = useRequest(() => getNormalData < any > ('b3779f30-ba1a-4230-b32e-bed9031f77e7')); //count_shehui_project_num_2020 社会服务-员工培训人数

  // const [num] = useRequest(() => findCountValue("count_shehui_chengguozhuanhua_num_2020")); //count_shehui_chengguozhuanhua_num_2020 社会服务-成果转化金额
  const [num] = useRequest(() => getNormalData < any > ('2e4a0a0f-ef76-4d1f-b2cd-4dfa44fe063b')); //count_shehui_project_num_2020 社会服务-成果转化金额

  return (
    <div className={`${type === 'major' ? style.major : style.statistics}`}>
      <div className={styles.block1}>
        <div className={styles.label}>服务项目数</div>
        <div className={styles.number}>{parseInt(project?.countValue || '0')}</div>
        <div className={styles.shadow} />
      </div>
      <div className={styles.block2}>
        <div className={styles.label}>服务企业数</div>
        <div className={styles.number}>{parseInt(companyNum?.countValue || '0')}</div>
        <div className={styles.shadow} />
      </div>
      <div className={styles.block3}>
        <div className={styles.label}>订单定制培养人数</div>
        <div className={styles.number}>{parseInt(order?.countValue || '0')}</div>
        <div className={styles.shadow} />
      </div>
      <div className={styles.block4}>
        <div className={styles.label}>员工培训人数</div>
        <div className={styles.number}>{parseInt(person?.countValue || '0')}</div>
        <div className={styles.shadow} />
      </div>
      <div className={styles.block5}>
        <div className={styles.label}>成果转化金额</div>
        <div className={styles.number}>{parseInt(num?.countValue || '0')}<span>(万元)</span></div>
        <div className={styles.shadow} />
      </div>
    </div>
  )
}


export function StatisticsEmployment ({ type }: IProps) {
  const [num] = useRequest(() => findCountValue("count_jiuye_num_2020")); //count_jiuye_num_2020 就业人数
  const [lv] = useRequest(() => findCountValue("count_jiuyelv_num_2020")); // 就业率：count_jiuyelv_num_2020
  const [avg] = useRequest(() => findCountValue("count_jiuye_avg_money_num_2020")); // 平均起薪：count_jiuye_avg_money_num_2020
  const [dkLv] = useRequest(() => findCountValue("count_jiuye_duikou_num_2020")); // 对口就业率：count_jiuye_duikou_num_2020

  return (
    <div className={`${type === 'major' ? style.major : style.statistics}`}>
      <div className={style.item}>
        <div className={style.label}>就业人数</div>
        <div className={style.number}>{parseInt(num?.countValue || '0')}</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>就业率</div>
        <div className={style.number}>{parseFloat(lv?.countValue || '0')}%</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>平均起薪</div>
        <div className={style.number}>{parseInt(avg?.countValue || '0')}</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>对口率</div>
        <div className={style.number}>{parseFloat(dkLv?.countValue || '0')}%</div>
      </div>
    </div>
  )
}


export function StatisticsPractice ({ type }: IProps) {
  return (
    <div className={`${type === 'major' ? style.major : style.statistics}`}>
      <div className={style.item}>
        <div className={style.label}>校内基地工位数</div>
        <div className={style.number}>14.76<span>(万)</span></div>
      </div>
      <div className={style.item}>
        <div className={style.label}>校外基地数</div>
        <div className={style.number}>5723</div>
      </div>
      <div className={style.item}>
        <div className={style.label}>虚拟仿真项目数</div>
        <div className={style.number}>3950</div>
      </div>
    </div>
  )
}


export function StatisticsEmply () {
  // const [count1] = useRequest(() => getNormalData<string>('60f6f450-bae2-46d7-846f-743364482dd5'));  //2021届毕业生生就业人数
  const [count5] = useRequest(() => getNormalData<string>('835dab82-a10b-11ec-ba43-005056958024')); //就业总人数
  const { isMobile } = useDevice();
  const colWidth = isMobile ? "100%" : "48%";
  return (
    <div className={isMobile ? style.wrapMob : styles.wrap}>
      <div className={`${styles.block1} ${isMobile ? styles.blockMob : ''}`} style={{width:colWidth}} >
        <div className={styles.label}>2021届毕业生就业人数</div>
        <div className={style.number}> <span className={style.txtNum}>92378</span>人</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block2}`} style={{width:colWidth}} >
        <div className={styles.label}>就业单位总数</div>
        <div className={style.number}> <span className={style.txtNum}>{parseInt(count5 || '0')}</span>所</div>
        <div className={styles.shadow} />
      </div>
    </div>
  )
}
