import useRequest from "../../lib/useRequest";
import { findOptions, getNormalData } from "../../service/basic";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import { useHistory } from "react-router-dom";
import Color from "color";
interface StaticNumProps {
    uuid: string;
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    theme?: string;
    onEvents?: any;
    schoolList?: any;

}
const colorList = ["#991f1f", "#8f1f99", "#1f2799", "#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"]

export default function EChartsUuid ({ uuid, theme, customOption, onEvents }: StaticNumProps) {
    const history = useHistory();
    const [data] = useRequest(() => getNormalData < any > (uuid));
    const opt = useMemo(() => {
        if (data && data.option)
        {
            if (customOption)
            {
                return customOption({ ...data.option, legend: { show: false } });
            } else
            {
                return { ...data.option, legend: { show: false } };
            }
        } else
        {
            return {};
        }
    }, [data])

    //点击每一项
    const onEventsObj = {
        'click': () => {
            history.push("/basic/main")
        }
    }

    const options: any = useMemo(() => {
        return {
            color: colorList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b}{c}所 : {d}%"
            },
            grid: { //网格大小
                x: 150,
                y: 15,
                x2: 1,
                y2: 20,
                borderWidth: 1,
            },
            labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
            },
            label: {
                alignTo: 'edge',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#999'
                    }
                }
            },
            series: opt.series?.map((item: any) => {
                return {
                    ...item,
                    data: item.data.map((item: any, index: number) => ({
                        ...{
                            name: item.name == "综合大学" ? '综合院校' : item.name,
                            value: item.value
                        }, label: { color: Color(colorList[index]).lighten(1).hex() }
                    })),
                    top: 20,
                    itemStyle: {
                        color: function (params: any) {
                            const color = Color(colorList[params.dataIndex]);
                            return {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: color.lighten(1).hex() // 0% 处的颜色
                                }, {
                                    offset: 1, color: color.hex() // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    radius: '70%',
                    center: ['50%', '50%'],
                }
            })
        }
    }, [data])
    return (
        <div>
            <ReactEcharts style={{ cursor: 'default' }}
                onEvents={onEventsObj}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                theme={theme}
            />

        </div>


    );
}
