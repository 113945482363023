import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { ColumnType } from "antd/es/table/interface";
import { useParams } from "react-router-dom";
import useRequest from "../../lib/useRequest";


import { exportReport, findReportData, ReportDataField } from "../../service/report";

import styles from './index.module.scss';
import Button from "antd/es/button";
import useBreadcrumb from "../../lib/useBreadcrumb";


const ReportDetail = () => {

  useBreadcrumb([{ name: "工作页" }]);

  const { guid } = useParams < { guid: string } > ()

  const [data, { loading }] = useRequest(() => findReportData(guid))

  const renderRowHead = (rowIndex: number, colIndex: number, rows: ReportDataField[][]) => {
    const item = rows[colIndex][rowIndex]
    if (item)
    {
      return { children: item.title, props: { colSpan: item.colSpan, rowSpan: item.rowSpan } }
    } else
    {
      return { children: null, props: { colSpan: 0, rowSpan: 0 } }
    }
  }

  const setCell = (column: ReportDataField, rows: ReportDataField[], rowIndex?: number) => {
    column.children = column.children.map((item, index) => setCell(item, rows))
    return column
  }

  const [tableData, columns] = useMemo(() => {
    if (!data) return [undefined, undefined]

    //  处理Columns
    const columns = data.columns.map((item, index) => {
      let column: ColumnType<object> = { ...item }

      if (item.isHead)
      {
        column.className = styles.head
        column.fixed = "left"
        column.width = 100
        //@ts-ignore
        column.render = (_, __, colIndex) => renderRowHead(index, colIndex, data.rows)
      } else
      {
        column = setCell(item, data.rows, index)
      }

      return column
    })

    return [data?.data, columns]
  }, [data])





  return (
    <div >
      <Table
        title={
          () => <Button onClick={() => exportReport(guid)}>导出</Button>
        }
        scroll={{ x: (columns?.length || 1) * 130 }}
        loading={loading}
        tableLayout="fixed"
        pagination={false} columns={columns} dataSource={tableData} bordered />
    </div>
  )

}

export default ReportDetail;
