import React from "react";
import style from "./index.module.scss";
import { Carousel } from 'antd';
import classNames from "classnames";

interface BannerProps {
    className?: string;
}

export default function Banner ({className}: BannerProps) {

  return (
    <div className={classNames(style.root, className)}>
      <div className={style.wrapper}>
        <Carousel autoplay autoplaySpeed={5000}>
          <div className={style.wrapper}>
            <img src={require("./img/banner3.png").default} />
          </div>
          <div className={style.wrapper}>
            <img src={require("./img/banner4.png").default} />
          </div>
        </Carousel>
      </div>
    </div>
  );
}
