import {useMedia} from "react-use";


export default function useDevice() {

    const isPC = useMedia('(min-width: 480px)');
    const isMobile = !isPC;

    return {isPC, isMobile};
}
