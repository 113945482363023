import React, {useState} from 'react';
import echarts from 'echarts';
import '../../assets/common.scss';
import style from './style.module.scss';
import Leibie from './School/Leibie';
import Hangye from './School/Hangye';
import Zhongdian from './School/Zhongdian';
import KechengShezhi from './School/KechengShezhi';
import Shouke from './School/Shouke';
import Widget2 from './School/Widget2';

import MyTheme from '../../assets/myTheme.json';
import NavItem from "./Components/Nav/NavIndex";
import {StatisticsThree} from "./page3";
import SecondScreen from "./Components/SecondScreen";
import FirstScreen from "./Components/FirstScreen";
import StatisticsOne, {StatisticsTwo} from "./Components/Statistics";
import ChartUuid from "../../components/ChartUuid";
import RadarChart from "./Components/RadarChart";
import ChartCarousel from "./Components/ChartCarousel";
import {Card} from "antd";
import Radar from '../Employment/List/components/Radar/Radar';
import Widget4 from './School/Widget4';
import Widget5 from './School/Widget5';
import Widget6 from './School/Widget6';
import Widget7 from './School/Widget7';
import Widget8 from './School/Widget8';
import Widget9 from './School/Widget9';
import Widget10 from './School/Widget10';
import Widget11 from './School/Widget11';
import Widget12 from './School/Widget12';
import { useHistory } from 'react-router-dom';

export default function PageChats() {

    echarts.registerTheme('theme', MyTheme);
    const [tab, setTab] = useState(1);
    const [provinceType, setProvinceType] = useState(1);

    const history = useHistory()


    function onClickNav(provinceType: number) {
        setProvinceType(provinceType)
    }

    return (
        <div className={style.root}>
            <div className={style.wrapper}>
                <div className={style.titleWrapper}>
                    <h1 className={style.title}>
                        <div className={style.NavWrapper}>
                            <NavItem className={style.Nav} type={provinceType} onClick={onClickNav}/>
                        </div>
                    </h1>
                </div>
                <div className={style.main}>

                    <div className={style.left}>
                        <div className={style.box1} style={{height: '280px'}}>
                            <ChartCarousel title={[<div onClick={() => history.push("/basic/main")}>院校办学性质分布图</div>, <div onClick={() => history.push("/basic/main")}>院校办学情况分布图</div>]}>
                                {/* <Hangye title="" /> */}

                                <ChartUuid onEvents={{"click": () => history.push("/basic/main")}} uuid="chart_make_college_type_pie_2020" theme="theme" customOption={(opt) => {
                                    const series = opt.series?.map((item:any) => {
                                        return {
                                            ...item,
                                            radius: '50%',
                                            center: ['50%', '50%'],
                                        }
                                    });

                                    return {
                                        ...opt,
                                        legend: {show: false},
                                        series,
                                        tooltip: {
                                            trigger: 'item',
                                                formatter: function(params:any){
                                                return `${params.seriesName} <br />${params.name}: ${parseInt(params.value)}所 (${params.percent}%)`;
                                            }
                                        }
                                    };
                                }}/>
                                <Widget5 />
                                {/* <Widget6 /> */}
                            </ChartCarousel>
                            {/*<ChartUuid uuid="chart_teacher_type_columnar_2020" />*/}
                        </div>
                        <div className={style.box1}>
                            <Zhongdian style={{height: "250px"}}/>
                        </div>
                        <div className={style.box1}>
                            <ChartCarousel title={["教师性质分布", "教师学历分布", "教师职称分布"]}>
                                <Widget7 />
                                <Widget8 />
                                <Widget9 />
                                {/* <Widget2 style={{height: '250px'}}  title="" />
                                <ChartUuid  style={{height: '250px'}} theme="theme" uuid="chart_jiuye_jieyequxiang_columnar_2020" customOption={(opt) => {
                                    return {
                                        ...opt,
                                        series: opt.series?.map(item => {
                                            return {
                                                ...item,
                                                radius: '55%',
                                                label: {
                                                    fontSize: 12
                                                }
                                            }
                                        }),
                                        grid: {
                                            ...opt.grid,
                                            left: '15%'
                                        },
                                        xAxis: {
                                            ...opt.xAxis,
                                            ...{
                                                axisLabel: {
                                                    rotate: 30
                                                }
                                            }
                                        },
                                        ...{series: opt.series?.map((item:any) => {
                                                return  {
                                                    ...item,
                                                    itemStyle: {
                                                        normal: {
                                                            color: function(params:any) {
                                                                var colorList = ["#d13269", "#90ed7d", "#0098d9", "#61a0a8", "#f7a35c", "#91c7ae", "#c12e34", "#2b821d", "#3398db", "#61a0a8"];
                                                                return colorList[params.dataIndex]
                                                            }
                                                        }
                                                    }
                                                }
                                        })}
                                        ,
                                        tooltip: {
                                            trigger: 'item',
                                                formatter: function(params:any){
                                                return `${params.seriesName} <br />${params.marker}${params.name}: ${parseInt(params.value)}人`;
                                        }
                                    }
                                    }
                                }} /> */}
                            </ChartCarousel>

                        </div>
                    </div>

                    <div className={style.center} style={{position: "relative"}}>

                        {provinceType === 1 && <StatisticsOne/>}
                        {provinceType === 2 && <StatisticsTwo/>}
                        {provinceType === 3 && <StatisticsThree/>}
                        <div className={style.map}>
                            {provinceType === 1 && <FirstScreen />}


                            {provinceType === 2 && <SecondScreen />}

                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex"}}>
                            <div className={style.right}>
                                <div className={style.box1} style={{height: "auto"}}>
                                    <ChartCarousel speed={5000} title={["辽宁省高职院校授课情况", "实训数据分析图"]}>
                                        <Widget11 />
                                        <Widget12 />
                                        {/* <Widget10 /> */}
                                        {/* <Shouke title='' style={{height: "240px"}} /> */}
                                        {/* <ChartUuid uuid="chart_major_gz_all_count_2020" customOption={(opt) => {
                                            return {
                                                ...opt,
                                                series: opt.series?.map(item => {
                                                    return {
                                                        ...item,
                                                        radius: '55%',
                                                        label: {
                                                            fontSize: 8
                                                        }
                                                    }
                                                }),
                                                tooltip: {
                                                    trigger: 'item',
                                                    formatter: function(params:any){
                                                        return `${params.seriesName} <br />${params.name}院校支出总额(万元): ${parseInt(params.value)} (${params.percent}%)`;
                                                    },
                                                    position: [0, 0]
                                                }
                                            }
                                        }} /> */}
                                    </ChartCarousel>

                                    <ChartCarousel title={["产业成果转化金额", "社会服务对接企业成果转化金额分布"]}>
                                        <Widget6 />
                                        <Widget4 />
                                    </ChartCarousel>

                                    {/* <Leibie title="院校办学类别示意图" style={{height: "260px"}}/> */}
                                </div>

                                <div className={style.box1} style={{height: '300px'}}>
                                    <ChartCarousel title={["辽宁省高职院校在校生数规模示意图", "高职院校毕业生就业质量和职业前景情况"]}>
                                        <Radar  onEvents={{"click": () => history.push("/service")}} title="" customOption={(opt) => {
                                            return {
                                                ...opt,
                                                radar: {
                                                    ...opt.radar,
                                                    axisLabel: {
                                                        show: true,
                                                    },
                                                    splitArea: {
                                                        areaStyle: {
                                                            color: [
                                                                'rgba(0,96,208, 0.1)', 'rgba(0,96,208, 0.2)',
                                                                'rgba(0,96,208, 0.4)', 'rgba(0,96,208, 0.6)',
                                                                'rgba(0,96,208, 0.8)', 'rgba(0,96,208, 1)'
                                                            ].reverse()
                                                        }
                                                    },
                                                    axisLine: {
                                                        show: true,
                                                        lineStyle: {
                                                            color: "transparent"
                                                        }
                                                    },
                                                    splitLine: {
                                                        show: true,
                                                        lineStyle: {
                                                            color: "transparent"
                                                        }
                                                    },
                                                    name:{
                                                        textStyle: {
                                                            color: "#fff"
                                                        }
                                                    }
                                                },
                                                series: opt.series?.map(item => ({
                                                    type: "radar",
                                                    symbol: "circle",
                                                    symbolSize: 7,
                                                    tooltip: {
                                                        position: [100, 100],
                                                        formatter: (item: any) => {
                                                            return (opt.radar as any).indicator?.map((ra: any, idx: number) => `${ra.name}: ${item.data.value[idx]}`).join("<br/>");
                                                        }
                                                    },
                                                    lineStyle: {
                                                        color: "rgba(247,156,25, 0.5)"
                                                    },
                                                    areaStyle: {
                                                        color: "rgba(247,156,25, 0.5)"
                                                    },
                                                    itemStyle: {
                                                        color: 'rgba(247,156,25, 0.5)',
                                                        borderColor: 'rgba(247,156,25, 1)',
                                                        borderWidth: 1,
                                                    },
                                                    data: item.data
                                                })),

                                            } as any
                                        }} />

                                        <RadarChart />

                                    </ChartCarousel>
                                  </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
