import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { get1xNum, getsgNum, ICityCountInfo, INewDataTypes, } from "../../../../../service/chart";
import Province from "./map";
import cityCoordData from "../../../../../assets/cityCoord";
import { Select } from "antd";
import { countryMap, countryTable, findCityListBySubjectName, } from "../../../../../service/major";
import { toNumber } from "../../../../../lib/utils";
import { getNormalData } from "../../../../../service/basic";
import useRequestFn from "../../../../../lib/useRequestFn";
import useDevice from "../../../../../lib/useDevice";
interface IProps {
  width: number;
  height: number;
}

export default function SearchScreen ({ width, height }: IProps) {

  // 获取学生实习数据情况分布图
  const [stuInfo] = useRequest(() => getNormalData < any > ('843e6132-7938-4cd0-90e1-714da5978fa0'))
  const [companyNum, setCompanyNum] = useState < any > (null)
  const [stuNum, setStuNum] = useState < any > (null)
  useEffect(() => {

    if (stuInfo)
    {
      let arr: any = []
      let arr1: any = []
      stuInfo.map((item: any) => {
        arr.push({
          name: item.province,
          value: item.companyNum
        })
        arr1.push({
          name: item.province,
          value: item.stuNum
        })
      })
      setCompanyNum(arr)
      setStuNum(arr1)
    }
  }, [stuInfo])


  const [subject] = useState("");
  const [countryMapList] = useRequest(countryMap);

  const [subjectData] = useRequest(
    () => findCityListBySubjectName(subject),
    {},
    [subject]
  );
  const [data, setData] = useState < INewDataTypes[] > ([]);
  const [typesData] = useState < string[] > (["专业"]);
  const [schoolList, setSchoolList] = useState < any[] > ([]);


  useEffect(() => {
    if (subjectData)
    {
      setData(formatData(subjectData));
    }
  }, [subjectData]);


  function formatData (data: ICityCountInfo[]) {
    const res = data.map((item) => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseInt(item.value),
        circleColor: "#f00",
        areaColor: "#f8f8f8",
        year: "专业",
      };
    });

    return buchongData(res);
  }

  function buchongData (data: INewDataTypes[]) {
    // 后台不存在的城市要补充上
    const cityArr = data.map((item) => item.name);
    const res = cityCoordData.filter(
      (item) => !cityArr.includes(item.name)
    );
    return [
      ...data,
      ...res.map((item) => {
        return {
          name: item.name,
          coord: cityCoord(item.name),
          value: 0,
          circleColor: "#f00",
          areaColor: "#f8f8f8",
          year: "专业",
        };
      }),
    ];
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter((item) => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }


  //默认点击列表(实习数据统计)
  const [schList, list] = useRequestFn(getNormalData)
  const [practice, setPractice] = useState < any > (null)
  const [city, setCity] = useState < any > (null)
  async function onList (val: any) {
    let res = await schList < any > ('667efc3a-3d20-41a2-b27d-a5f9ce769c91', { province: val })
    let arr: any = []
    if (res)
    {
      res.map((item: any) => {
        arr.push(
          {
            name: item.school_name,
            count: item.stuNum,
            people: item.subjectNum,
            majors: item.companyNum,
          }
        )
      })
      setPractice(arr)
    }

  }

  function getSchoolList (level: string, val: string) {
    onList(level)//点击获取地名
    setCity(level)//储存地名

    // countryTable(level).then((data) => {
    //   setSchoolList(data);
    // });
  }

  function onClean () {
    // 清除弹出层表单数据
    setSchoolList([]);
  }

  const mapData = useMemo(() => {
    return countryMapList?.map(item => ({ ...item, value: Math.ceil(toNumber(item.value) / 4).toString() })) || []
  }, [countryMapList]);


  const blueData = [
    {
      name: '辽宁',
      value: "531"
    },
    {
      name: '黑龙江',
      value: "1"
    }, {
      name: '天津',
      value: "3"
    }, {
      name: '山西',
      value: "1"
    }, {
      name: '陕西',
      value: "1"
    }, {
      name: '内蒙古',
      value: "2"
    }, {
      name: '上海',
      value: "2"
    }, {
      name: '浙江',
      value: "1"
    }, {
      name: '湖南',
      value: "1"
    }, {
      name: '广东',
      value: "4"
    }, {
      name: '重庆',
      value: "1"
    }, {
      name: '贵州',
      value: "1"
    }, {
      name: '广西',
      value: "1"
    }, {
      name: '甘肃',
      value: "1"
    }, {
      name: '新疆',
      value: "1"
    }, {
      name: '吉林',
      value: "2"
    }, {
      name: '北京',
      value: "9"
    }, {
      name: '河北',
      value: "1"
    }, {
      name: '河南',
      value: "1"
    }, {
      name: '山东',
      value: "4"
    }, {
      name: '安徽',
      value: "2"
    }, {
      name: '江苏',
      value: "1"
    }, {
      name: '湖北',
      value: "2"
    }, {
      name: '福建',
      value: "1"
    }, {
      name: '江西',
      value: "1"
    }, {
      name: '四川',
      value: "3"
    }, {
      name: '云南',
      value: "1"
    }, {
      name: '宁夏',
      value: "1"
    }, {
      name: '青海',
      value: "1"
    }, {
      name: '西藏',
      value: "1"
    },
  ]
  const { isMobile } = useDevice()
  return (
    <div>
      {data && typesData && (
        <>
          <Province
            name="辽宁"
            tableType={2}
            width={isMobile ? 'auto' : width}
            height={isMobile ? 350 : height}
            data={data}
            year={typesData}
            geoColor="0, 167, 237"
            schoolList={schoolList || []}
            showTimeLine={false}
            changeCityName={(levelName, val) =>
              getSchoolList(levelName, val)
            }
            // points={mapData}
            // blueData={blueData}
            points={stuNum}
            blueData={companyNum}
            onClean={onClean}
            practice={practice}
            city={city}
          />
        </>
      )}
    </div>
  );
}

SearchScreen.defaultProps = {
  showTimeLine: true,
  width: 680,
  height: 728,
};
