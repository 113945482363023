import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { HourSelectItem, IFindCourseSyses, ISubjectTrainBaseInfo, ITeacherTeams, subjectCoreCourses } from "../../../../../service/training";
import Item from "antd/lib/list/Item";

interface TeamProps {
    data?: ISubjectTrainBaseInfo;
    TeacherTeamList?: ITeacherTeams[];
    courseSysList?: IFindCourseSyses[];
    coreCourseList?: subjectCoreCourses[];
    hourCollectList?: HourSelectItem;
}

export default function Course ({ data, TeacherTeamList, courseSysList, coreCourseList, hourCollectList }: TeamProps) {
    const [xfhj, setXfhj] = useState(0);
    const [llxshj, setLlxsshj] = useState(0);
    const [sjxshj, setSjxsshj] = useState(0);
    const [xsbfb, setXsbfb] = useState(0)
    const [xfbfb, setXfbfb] = useState(0)

    const [xfhj1, setXfhj1] = useState(0);
    const [llxshj1, setLlxsshj1] = useState(0);
    const [sjxshj1, setSjxsshj1] = useState(0);
    const [xsbfb1, setXsbfb1] = useState(0)
    const [xfbfb1, setXfbfb1] = useState(0)
    useEffect(() => {
        if (hourCollectList)
        {
            setXfhj(Number(hourCollectList.ggrx?.ggrx_xf) + Number(hourCollectList?.zyxx.zyxx_xf) + Number(hourCollectList?.zybx.zybx_xf) + Number(hourCollectList?.ggjcbx.ggjcbx_xf));
            setLlxsshj(Number(hourCollectList.ggrx?.ggrx_llxs) + Number(hourCollectList?.zyxx.zyxx_llxs) + Number(hourCollectList?.zybx.zybx_llxs) + Number(hourCollectList?.ggjcbx.ggjcbx_llxs));
            setSjxsshj(Number(hourCollectList.ggrx?.ggrx_sjxs) + Number(hourCollectList?.zyxx.zyxx_sjxs) + Number(hourCollectList?.zybx.zybx_sjxs) + Number(hourCollectList?.ggjcbx.ggjcbx_sjxs));
            setXsbfb(Number(hourCollectList?.ggjcbx.ggbx_xs_bfb) + Number(hourCollectList?.zybx.zybx_xs_bfb) + Number(hourCollectList?.zyxx.zyxx_xs_bfb) + Number(hourCollectList?.ggrx.ggrx_xs_bfb))
            setXfbfb(Number(hourCollectList?.ggjcbx.ggbx_xf_bfb) + Number(hourCollectList?.zybx.zybx_xf_bfb) + Number(hourCollectList?.zyxx.zyxx_xf_bfb) + Number(hourCollectList?.ggrx.ggrx_xf_bfb))

            setXfhj1(Number(hourCollectList.llk?.llkxf) + Number(hourCollectList?.llksjk.llksjkxf) + Number(hourCollectList?.jjsjk.jjsjkxf));
            setLlxsshj1(Number(hourCollectList.llk?.llk_llxs) + Number(hourCollectList?.llksjk.llksjk_llxs) + Number(hourCollectList?.jjsjk.jjsjk_llxs));
            setSjxsshj1(Number(hourCollectList.llk?.llk_sjxs) + Number(hourCollectList?.llksjk.llksjkxf) + Number(hourCollectList?.jjsjk.jjsjk_sjxs));
            setXsbfb1(Number(hourCollectList?.llk.llk_xs_bfb) + Number(hourCollectList?.llksjk.llksjk_xs_bfb) + Number(hourCollectList?.jjsjk.jzsjk_xs_bfb));
            setXfbfb1(Number(hourCollectList?.llk.llk_xf_bfb) + Number(hourCollectList?.llksjk.llksjk_xf_bfb) + Number(hourCollectList?.jjsjk.jzsjk_xf_bfb));

        }
    }, [hourCollectList]);

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>1.课程体系结构</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div title={data?.courseSystem} className={`${style.content} ${style.largecontent}`}>{data?.courseSystem}</div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>2.教学进程表</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div><a target="_blank" href={data?.progress}>[下载教学进程表]</a></div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>3.专业教学计划</h1>
                </div>
                <div className={style.table}>
                    <table>
                        <tbody>
                            <tr>
                                <th>课程名称</th>
                                <th>是否核心课</th>
                                <th>课程类型</th>
                                <th>课程性质</th>
                                <th>学年</th>
                                <th>学期</th>
                                <th>总学时</th>
                                <th>学分数</th>
                                <th>周学时</th>
                            </tr>
                            {courseSysList?.map((item, index) => {
                                return <tr key={index} style={{ backgroundColor: (!item.courseTypeName || item.courseTypeName === "") ? "#f9f9f9" : "" }}>
                                    <td><a href={`/training/CourseDetail?courseId=${item.courseId}`}>{item.courseName}</a></td>
                                    <td>{item.isCoreName}</td>
                                    <td>{item.courseTypeName}</td>
                                    <td>{item.courseQualityName}</td>
                                    <td>{item.newYear}</td>
                                    <td>{item.term}</td>
                                    <td>{item.allTime}</td>
                                    <td>{item.xfs}</td>
                                    <td>{item.weekTime}</td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>4.学时汇总及分配比例</h1>
                </div>
                {/* todo新表    */}
                <div className={style.table}>
                    <table style={{ width: "100%" }} className={style.table}>
                        <thead>
                            <tr>
                                <th colSpan={2}>项目</th>
                                <th>学分数</th>
                                <th>学时数(理论)</th>
                                <th>学时数(实践)</th>
                                <th>学时百分比(%)</th>
                                <th>学分百分比(%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4} className={`${style.gray}`}>课程体系</td>
                                <td>公共基础必修课</td>
                                <td>{hourCollectList?.ggjcbx.ggjcbx_xf}</td>
                                <td>{hourCollectList?.ggjcbx.ggjcbx_llxs}</td>
                                <td>{hourCollectList?.ggjcbx.ggjcbx_sjxs}</td>
                                <td>{hourCollectList?.ggjcbx.ggbx_xs_bfb}</td>
                                <td>{hourCollectList?.ggjcbx.ggbx_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td>专业必修课</td>
                                <td>{hourCollectList?.zybx.zybx_xf}</td>
                                <td>{hourCollectList?.zybx.zybx_llxs}</td>
                                <td>{hourCollectList?.zybx.zybx_sjxs}</td>
                                <td>{hourCollectList?.zybx.zybx_xs_bfb}</td>
                                <td>{hourCollectList?.zybx.zybx_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td>专业限选课</td>
                                <td>{hourCollectList?.zyxx.zyxx_xf}</td>
                                <td>{hourCollectList?.zyxx.zyxx_llxs}</td>
                                <td>{hourCollectList?.zyxx.zyxx_sjxs}</td>
                                <td>{hourCollectList?.zyxx.zyxx_xs_bfb}</td>
                                <td>{hourCollectList?.zyxx.zyxx_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td>公共任选课</td>
                                <td>{hourCollectList?.ggrx.ggrx_xf}</td>
                                <td>{hourCollectList?.ggrx.ggrx_llxs}</td>
                                <td>{hourCollectList?.ggrx.ggrx_sjxs}</td>
                                <td>{hourCollectList?.ggrx.ggrx_xs_bfb}</td>
                                <td>{hourCollectList?.ggrx.ggrx_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>合计</td>
                                <td>{(xfhj).toFixed(1)}</td>
                                <td>{(llxshj).toFixed(1)}</td>
                                <td>{(sjxshj).toFixed(1)}</td>
                                <td>{(xsbfb).toFixed(1)}</td>
                                <td>{(xfbfb).toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td rowSpan={3} className={`${style.gray}`}>理论与实践课程体系</td>
                                <td>理论课程</td>
                                <td>{hourCollectList?.llk.llkxf}</td>
                                <td>{hourCollectList?.llk.llk_llxs}</td>
                                <td>{hourCollectList?.llk.llk_sjxs}</td>
                                <td>{hourCollectList?.llk.llk_xs_bfb}</td>
                                <td>{hourCollectList?.llk.llk_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td>理论+实践课程</td>
                                <td>{hourCollectList?.llksjk.llksjkxf}</td>
                                <td>{hourCollectList?.llksjk.llksjk_llxs}</td>
                                <td>{hourCollectList?.llksjk.llksjkxf}</td>
                                <td>{hourCollectList?.llksjk.llksjk_xs_bfb}</td>
                                <td>{hourCollectList?.llksjk.llksjk_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td>集中实践性课程</td>
                                <td>{hourCollectList?.jjsjk.jjsjkxf}</td>
                                <td>{hourCollectList?.jjsjk.jjsjk_llxs}</td>
                                <td>{hourCollectList?.jjsjk.jjsjk_sjxs}</td>
                                <td>{hourCollectList?.jjsjk.jzsjk_xs_bfb}</td>
                                <td>{hourCollectList?.jjsjk.jzsjk_xf_bfb}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>合计</td>
                                <td>{(xfhj1).toFixed(1)}</td>
                                <td>{(llxshj1).toFixed(1)}</td>
                                <td>{(sjxshj1).toFixed(1)}</td>
                                <td>{(xsbfb1).toFixed(1)}</td>
                                <td>{(xfbfb1).toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>理论教学学时与实践教学学时的比例</td>
                                <td colSpan={5}>理论学时：实践学时=1:{hourCollectList?.lljxxs_sjjxxs_rate}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>5.专业核心课程基本要求</h1>
                </div>
                {coreCourseList?.map((item, index) => (
                    <div className={style.table}>
                        <div className={style.title}>课程{index + 1}-{item.courseName}</div>
                        <table className={style.table}>
                            <tr>
                                <td className={style.tablewid} style={{ fontWeight: 700 }}>课程名称</td>
                                <td>{item.courseName}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 700 }}>学期</td>
                                <td>{item.term}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 700 }}>学时</td>
                                <td>{item.allTime}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 700 }}>职业能力要求</td>
                                <td>{item.zynlyq}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 700 }}>学习目标</td>
                                <td>{item.xxmb}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 700 }}>学习内容</td>
                                <td>{item.xxnr}</td>
                            </tr>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    );
}
