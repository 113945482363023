import React, { useEffect, useState } from "react";
import Province from "../Province/Provice";
import {
  constMoney, getDoubleLevelList,
  getNationalLevelCount,
  getProvincialLevelCount,
  getSchoolInfoList, IDataTypes,
  INewDataTypes, IPoint, ISchoolInfo, newStudentCount, schoolCount, teacherCount
} from "../../../../service/chart";
import useRequest from "../../../../lib/useRequest";
import useRequestFn from "../../../../lib/useRequestFn";
import cityCoordData from "../../../../assets/cityCoord";
import { getNormalData } from "../../../../service/basic";
import useDevice from "../../../../lib/useDevice";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
  paddingTop: number;
  divHeight: number
}

export default function FirstScreen ({ showTimeLine, width, height, paddingTop, divHeight }: IProps) {
  const { isMobile } = useDevice()
  const [cityName, setCityName] = useState(""); // 点击地图返回城市名

  // 第一屏
  const [schoolData] = useRequest(() => schoolCount()); // 学校数量
  const [newStuCountData] = useRequest(() => newStudentCount()); // 招生人数;
  const [teacherData] = useRequest(() => teacherCount()); // 教师数量
  const [constMoneyData] = useRequest(() => constMoney()); // 投入情况
  const [firstData, setFirstData] = useState < INewDataTypes[] > ([]);
  //
  const [schoolList, setSchoolList] = useState < ISchoolInfo[] > ([]);
  // const [requestSchoolList] = useRequest(() => getSchoolInfoList(cityName), {}, [cityName]);
  const [requestSchoolList] = useRequest(() => getNormalData < ISchoolInfo[] > ('55023e76-a089-4619-86c0-e93882dde8c8', { areaCode: cityName }), {}, [cityName]); // 点击区域弹出框中的数据
  const [schoolListSpecialFn, { loading, data: schoolListSpecial }] = useRequestFn(getDoubleLevelList);

  //辽宁省高职院校院校地域分布

  const [blueCountData] = useRequest(() => getProvincialLevelCount());
  // const [redCountData] =useRequest(() => getNationalLevelCount());
  const [redCountData] = useRequest(() => getNormalData < IPoint[] > ('670247c9-c134-4471-bb0a-bec5fb1e9748')); // new 各地区的国家级高职院校院数(红点数)


  useEffect(() => {
    if (requestSchoolList)
    {
      setSchoolList([]);
      setSchoolList(requestSchoolList);
    }
  }, [requestSchoolList]);

  useEffect(() => {
    if (schoolData && newStuCountData && teacherData && constMoneyData)
    {
      const data1 = formatData(schoolData, '学校数量');
      const data2 = formatData(newStuCountData, '招生人数');
      const data3 = formatData(teacherData, '教师人数');
      const data4 = formatData(constMoneyData, '投入情况');
      setFirstData([...data1, ...data2, ...data3, ...data4]);
    }
  }, [schoolData, newStuCountData, teacherData, constMoneyData]);

  function formatData (data: IDataTypes[], name: string) {
    return data.map(item => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseFloat(item.value),
        circleColor: item.circleColor ? item.circleColor : '#f00',
        areaColor: item.areaColor ? item.areaColor : '#f8f8f8',
        year: name
      }
    });
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  function getSchoolList (val: string, level: string) {
    if (!level)
    {
      setCityName(val);
    }

    if (level)
    {
      schoolListSpecialFn(val, level);
    }
  }

  function onClean () { // 清除弹出层表单数据
    // setSchoolList([]);
  }

  return <div style={{ paddingTop: paddingTop + 'px', height: divHeight }}>

    {firstData &&
      <Province tableType={1} name='辽宁' width={width} height={height} data={firstData}
        year={["学校数量", "招生人数", "教师人数", "投入情况"]}
        geoColor='27, 94, 193'
        schoolList={schoolList || []}

        changeCityName={(val, levelName) => {
          getSchoolList(val, levelName)
        }}
        pointsBlue={blueCountData?.map(item => {
          return {
            areaCode: item.areaCode,
            schCount: item.schCount
          }
        })}
        pointsRed={redCountData?.map(item => {
          return {
            areaCode: item.areaCode,
            schCount: item.schCount
          }
        })}
        schoolListSpecial={schoolListSpecial ? schoolListSpecial : []}
        showTimeLine={showTimeLine}
        onClean={onClean}
      />}
  </div>
}

FirstScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800,
  paddingTop: 0,
  divHeight: 0
}
