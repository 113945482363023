import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import EmploymentListTable from "./components/EmploymentListTable";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import bysImg from './image/byswj.png';
import qyImg from './image/qywj.png';
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import Bar from "../../Charts/Components/Bar/Bar";
import Pie from "../../Charts/Components/Pie";
import ChartUuid from "../../../components/ChartUuid";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import Chart3 from "./components/Chart3";
import RadarChart from "../../Charts/Components/RadarChart";
import Radar from "./components/Radar/Radar";
import useRequest from "../../../lib/useRequest";
import { getNormalData } from "../../../service/basic";
import useRequestFn from "../../../lib/useRequestFn";
import { get } from "sortablejs";
import Chart4 from "./components/Chart4";
import Chart5 from "./components/Chart5";
import useDevice from "../../../lib/useDevice";
export default function PageEmploymentList () {

  const [form] = Form.useForm();
  const history = useHistory();
  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "毕业生跟踪调查" }]);
  //二维码信息
  const [QRCode] = useRequest(() => getNormalData < any > ('5ca640ce-8da0-4688-b67b-ceeefa75660b'))

  //网址信息
  const [website] = useRequest(() => getNormalData < any > ('2ca858b7-86f1-4ac8-9a47-8886c37c9cd6'))

  //调查报告
  const [report] = useRequest(() => getNormalData < any > ('16a89ac6-cde8-4934-b450-6f36fed0c622'))

  //就业去向
  const [jyqxOptions, setJyqxOptions] = useState < any > (null)
  const [jydyOptions, setJydyOptions] = useState < any > (null)


  //就业地域
  const [infernal] = useRequest(() => getNormalData < any > ('8dea7cf6-f74a-40b8-af97-33efcc078cc5'))

  //就职岗位类型
  const [post] = useRequest(() => getNormalData < any > ('9801db19-f288-495d-85a4-0033abc9f663'))

  //高职院校毕业生就业素质和职业竞争力情况
  const [compete] = useRequest(() => getNormalData < any > ('e60e05be-51fc-4c41-9dda-7896ef99627d'))

  //高职院校毕业生就业质量和职业前景情况
  const [prospect] = useRequest(() => getNormalData < any > ('50f799c4-4507-474a-81d4-5bf1196000a1'))

  //招聘企业的行业领域
  const [field] = useRequest(() => getNormalData < any > ('ad2bd0b4-b56c-4e5e-80d6-215d1b2665ca'))

  //招聘专业需求
  const [demand] = useRequest(() => getNormalData < any > ('17f2f658-2298-4fb6-8707-eed425fcf981'))

  function onFinish (query: any) {
    history.push(`/employment/search?${qs.stringify(query)}`);
  }


  //招聘企业的行业领域
  const [zpqyOptions, setZpqyOptions] = useState < any > (null)
  useEffect(() => {
    if (prospect)
    {
      setZpqyOptions({
        xAxisData: Object.keys(prospect),
        data: Object.values(prospect),
        text: "招聘企业的行业领域"
      })
    }
  }, [prospect])

  //招聘企业的行业领域
  const [zpxqOptions, setZpxqOptions] = useState < any > (null)
  useEffect(() => {
    if (demand)
    {
      setZpxqOptions({
        xAxisData: demand.map((item: any) => item.name),
        data: demand.map((item: any) => item.value),
        text: "招聘专业的需求"
      })
    }
  }, [demand])

  const { isMobile } = useDevice()

  // const jyqxOptions = {
  //   xAxisData: [],
  //   data: [59.2, 40.8],
  //   text: "就业去向"
  // }

  // const jygwOptions = {
  //   xAxisData: ["以一线技术岗、一线营销岗为主", "以其他岗位为辅"],
  //   data: [59.2, 40.8],
  //   text: "就职岗位类型"
  // }

  // const jzlOptions = {
  //   xAxisData: ["参加过专业相关的实习实践", "受到过职业技能训练", "持有体现职业能力的职业技能证书"],
  //   data: [82.13, 57.8, 72.55],
  //   text: "高职院校毕业生就业素质和职业竞争力情况"
  // }

  // const zpqyOptions = {
  //   xAxisData: ["教育业", "制造业", "农林牧渔业", "建筑业", "信息技术服务业", "金融业"],
  //   data: [16.74, 11.49, 10.09, 8.53, 7.37, 6.2],
  //   text: "招聘企业的行业领域"
  // }

  // const zpxqOptions = {
  //   xAxisData: ["市场营销专业", "会计专业", "学前教育专业", "计算机专业", "金融专业", "电子商务专业", "酒店管理专业", "旅游管理专业", "小学教育专业", "机械专业", ""],
  //   data: [33.25, 24.89, 23.49, 12.75, 10.94, 9.75, 8.55, 7.02, 6.04, 4.92],
  //   text: "招聘专业的需求"
  // }

  const getOption = (opt: any, data: any) => {
    return {
      ...opt,
      title: {
        text: data.text,
        left: 'center',
        top: 10,
        textStyle: {
          color: '#347afc'
        }
      },
      grid: {
        ...opt.grid,
        left: '15%'
      },
      xAxis: {
        ...opt.xAxis,
        ...{
          axisLabel: {
            formatter: function (value: any, index: any) {
              const n = 8;
              if (value.length > 8)
              {
                for (var i = 0, l = value.length; i < l / n; i++)
                {
                  var a = value.slice(0, n);
                  var b = value.slice(n, l);
                }
                const dmms = a + '   \n' + b;
                return dmms;
              } else
              {
                return value
              }
            },
            rotate: 30
          }
        },
        data: data.xAxisData
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: '{value} (%)'
          }
        }
      ],
      series: [{
        cursor: 'default',
        data: data.data,
        type: 'bar',
        itemStyle: {
          normal: {
            color: function (params: any) {
              var colorList = ["#3398db", "#c23531", "#90ed7d", "#f7a35c", "#61a0a8", "#434348", "#91c7ae", "#2f4554", "#61a0a8"];
              return colorList[params.dataIndex]
            }
          }
        },
      }],

      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}%'
      },
    }
  }

  const option = {
    title: {
      text: '高职院校毕业生就业质量和职业前景情况',
      left: 'center',
      top: 10,
      textStyle: {
        color: '#347afc'
      }
    },
    tooltip: {},
    radar: {
      name: {
        textStyle: {
          color: '#333',
          borderRadius: 3,
          padding: [3, 5]
        },
        formatter: function (value: any) {
          let list = value.split("");
          let result = "";
          for (let i = 1; i <= list.length; i++)
          {
            if (!(i % 10) && list[i] != undefined)
            {
              result += list[i - 1] + '\n';
            } else
            {
              result += list[i - 1];
            }
          }
          return result;
        },
      },
      indicator: [
        { name: '我省高职院校毕业生年终就业率(%)', max: 100 },
        { name: '毕业生专业相关就业率(%)', max: 100 },
        { name: '毕业生平均月薪(元)', max: 5000 },
        { name: '毕业生认为收入低于预期比例(%)', max: 100 },
        { name: '高职院校毕业生在入职三年内获得加薪或晋升比例(%)', max: 100 },
      ],
      center: ['50%', '60%'],
      radius: 90,
    },
    series: [{
      name: '',
      cursor: 'auto',
      type: 'radar',
      data: [
        {
          value: [90.03, 62, 3837, 39.72, 75.68],
          name: ''
        },
      ],
      label: {
        show: true,
        formatter: function (params: any) {
          return params.value;
        }
      },
    }],
  };
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.contentList}>
          <div>
            <div className={style.flex}>
              <div className={style.titleNew}>调查方式</div>
              <div className={style.txt}>本次调查将采用线上填报问卷的形式，调查对象可通过网络或使用手机扫描二维码进行填报。</div>
            </div>
            <Row>
              <Col span={isMobile ? 24 : 12}>
                <div className={style.titleBlue}>二维码</div>
                <div className={style.ewmNew}>
                  <img src={bysImg}></img>
                </div>
                <div className={style.ewmNew}>
                  <img src={qyImg}></img>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <div className={style.titleBlue}>网址</div>
                <div className={style.flex}>
                  <Col span={isMobile ? 24 : 2} className={style.wzTitle}>
                    <p>毕业生:</p>
                  </Col>
                  <a style={{ fontWeight: "normal" }} href="http://101.200.47.111/#/sbndfsds" target="_blank">http://101.200.47.111/#/sbndfsds</a>
                </div>
                <div className={style.flex}>
                  <Col span={isMobile ? 24 : 2} className={style.wzTitle}>
                    <p>企业:</p>
                  </Col>
                  <a style={{ fontWeight: "normal" }} href="http://101.200.47.111/#/lksklsas" target="_blank">http://101.200.47.111/#/lksklsas</a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className={style.content}>
        <div className={style.contentList}>
          <div>
            <div className={style.flex}>
              <div className={style.titleNew}>调查报告</div>
              <Button className={style.downBtn}>报告下载</Button>
            </div>
            <div className={style.showBox}>
              <Row>
                <Col span={isMobile ? 24 : 12}>
                  <div className={style.titleBlue}>高职院校毕业生就业流向情况</div>
                </Col>
              </Row>
              <Row justify="space-around">
                <Col span={isMobile ? 24 : 8}>
                  <Col span={24} className={style.txtCer}>就业去向</Col>
                  <Col span={24} className={style.item1}>
                    <div >
                      <div className={style.bigTxt1}>{report ? (Number(Object.values(report)[1]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div >留辽就业</div>
                    </div>
                    <div className={style.itemChild2}></div>
                    <div >
                      <div className={style.bigTxt1}>{report ? (Number(Object.values(report)[0]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div className={style.bigTxt2}>外地就业</div>
                    </div>
                  </Col>
                </Col>
                <Col span={isMobile ? 24 : 8} >
                  <Col span={24} className={style.txtCer}>就业地域</Col>
                  <Col span={24} className={style.item2}>
                    <div >
                      <div className={style.bigTxt1}>{infernal ? (Number(Object.values(infernal)[1]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div > <p className={style.marAut}>以省内小型</p> <p className={style.marAut}>城市为辅</p></div>
                    </div>
                    <div className={style.itemChild2}></div>
                    <div >
                      <div className={style.bigTxt1}>{infernal ? (Number(Object.values(infernal)[0]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div className={style.bigTxt2}><p className={style.marAut}>以省内大型</p> <p className={style.marAut}>城市为主</p></div>
                    </div>
                  </Col>
                </Col>
                <Col span={isMobile ? 24 : 8}>
                  <Col span={24} className={style.txtCer}>就职岗位类型</Col>
                  <Col span={24} className={style.item3}>
                    <div >
                      <div className={style.bigTxt1}>{post ? (Number(Object.values(post)[0]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div ><p className={style.marAut}>以一线技术岗、</p>
                        <p className={style.marAut}>一线营销岗为主</p></div>
                    </div>
                    <div className={style.itemChild2}></div>
                    <div >
                      <div className={style.bigTxt1}>{post ? (Number(Object.values(post)[1]) * 100).toFixed(1) + '%' : 0 + '%'}</div>
                      <div className={style.bigTxt2}>以其他岗位为辅</div>
                    </div>
                  </Col>
                </Col>
              </Row>
              <Row className={style.marTp60}>
                <Col span={isMobile ? 24 : 12}>
                  <Col span={24} className={style.txtCer1}>高职院校毕业生就业素质和职业竞争力情况</Col>
                  <Row justify="space-around">
                    <Col span={isMobile ? 24 : 8} >
                      <Chart1 data={compete} />
                      <p className={style.abs80}>参加过专业相关的实习实践</p>
                    </Col>
                    <Col span={isMobile ? 24 : 8} >
                      <Chart2 data={compete} />
                      <p className={style.abs80}>受到过职业技能训练</p>
                    </Col>
                    <Col span={isMobile ? 24 : 8} >
                      <Chart3 data={compete} />
                      <p className={style.abs80}>持有体现职业能力的职业技能证书</p>
                    </Col>
                  </Row>
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <Col span={24} className={style.txtCer1}>高职院校毕业生就业素质和职业竞争力情况</Col>
                  <Col span={24}>
                    <Radar />
                  </Col>
                </Col>
              </Row>
            </div>


            <div className={style.showBox}>
              <Row>
                <Col span={isMobile ? 24 : 12}>
                  <div className={style.titleBlue}>企业吸纳毕业生情况</div>
                </Col>
              </Row>
              <Row>
                <Col span={isMobile ? 24 : 12}>
                  <Col span={24} className={style.txtCer1}>招聘企业的行业领域</Col>
                  <Row justify="center">
                    <Col span={24}  >
                      <Chart4 />
                    </Col>
                  </Row>
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <Col span={24} className={style.txtCer1}>招聘专业的需求</Col>
                  <Col span={24}>
                    <Chart5 />
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
