import React, {useEffect, useState} from "react";
import style from "./index.module.scss"
import {Form, Input, Button, Checkbox, Select, Row, Col} from 'antd';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";

interface ProfessionInfoTableProps{
    showProfessional?: boolean;
}

export default function ProfessionInfoTable({showProfessional} : ProfessionInfoTableProps) {

    const history = useHistory();
    const [hover,setHover] = useState(false);

    function gotoPath () {
        history.push("/");
    }

    return (
        <div className={showProfessional ? style.basicContainer : style.container }>
            <table className={style.customers}>
                <tr>
                    <td colSpan={2}>
                        <div className={style.tableLabel}><span>高校名称及代码</span></div>
                        <div className={style.tableContent}><span>辽宁大学(10140)</span></div>
                    </td>
                </tr>

                <tr className={style.alt}>
                    <td style={{width:"50%",border:"1px solid #e9e9e9"}}>
                        <div className={style.tableLabel}><span>专业名称及代码</span></div>
                        <div className={style.tableTwoContent}><span>哲学（010101）</span></div>
                    </td>
                    <td style={{width:"50%"}}>
                        <div className={style.tableLabel}><span>专业英文名称</span></div>
                        <div className={style.tableTwoContent}><span>Philosophy</span></div>
                    </td>
                </tr>

                <tr>
                    <td colSpan={2} style={{padding:"20px 8px"}}>
                        <div className={style.tableLabel}><span>高层次教师情况</span></div>
                        <div className={style.tableTwoContent}>
                            <ul style={{margin:0,paddingInlineStart:16}}>
                                <li className={style.ulLi}>省百千万人才工程计划（百人层次）：1人</li>
                                <li className={style.ulLi}>省百千万人才工程计划（百人层次）：1人</li>
                                <li className={style.ulLi}>省百千万人才工程计划（百人层次）：1人</li>
                                <li className={style.ulLi}>省百千万人才工程计划（百人层次）：1人</li>
                            </ul>
                        </div>
                    </td>
                </tr>

                <tr className={style.alt}>
                    <td colSpan={2} style={{whiteSpace:"normal"}}>
                        <div className={style.tableLabel}><span>专业主干课程及简介</span></div>
                        <div className={style.tableTotalContent}>
                            <span>
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                            </span>
                        </div>
                        <Button type="link"
                                style={{padding:"0 5px",color:"#6c97d2",height:"auto",fontSize:12,paddingLeft:156}}
                                onClick={() => gotoPath()}>
                            {'>>点此展开课程简介'}
                        </Button>
                    </td>
                </tr>

                <tr>
                    <td colSpan={2} style={{whiteSpace:"normal"}}>
                        <div className={style.tableLabel}><span>专业在辽宁录取情况</span></div>
                        <div className={style.tableTotalContent}>
                            <table className={style.admission}>
                                <tr>
                                    <th>录取年份</th>
                                    <th>专业代码</th>
                                    <th>专业名称</th>
                                    <th>录取批次</th>
                                    <th>最高分</th>
                                    <th>最低分</th>
                                    <th>科类名称</th>
                                </tr>

                                <tr>
                                    <td>2010</td>
                                    <td>010101</td>
                                    <td>哲学</td>
                                    <td>一批</td>
                                    <td>551</td>
                                    <td>538</td>
                                    <td>理工</td>
                                </tr>

                                <tr className={style.alt}>
                                    <td>2010</td>
                                    <td>010101</td>
                                    <td>哲学</td>
                                    <td>一批</td>
                                    <td>551</td>
                                    <td>538</td>
                                    <td>理工</td>
                                </tr>

                                <tr>
                                    <td>2010</td>
                                    <td>010101</td>
                                    <td>哲学</td>
                                    <td>一批</td>
                                    <td>551</td>
                                    <td>538</td>
                                    <td>理工</td>
                                </tr>

                            </table>
                        </div>
                    </td>
                </tr>

                <tr className={style.alt}>
                    <td colSpan={2} style={{whiteSpace:"normal"}}>
                        <div className={style.tableLabel}><span>专业简介</span></div>
                        <div className={style.tableTotalContent}>
                            <span>
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                                爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒爱上了福利卡撒地方了开始考虑地方撒
                            </span>
                        </div>
                    </td>
                </tr>

            </table>
        </div>
    );
}
