import {Button, Modal, Table} from "antd";
import React, {useState} from "react";
import {ColumnsType} from "antd/lib/table/interface";

interface PopColProps {
    num: number;
}

export default function PopCol7({num}: PopColProps) {

    const [visible, setVisible] = useState(false);

    const columns: ColumnsType<any> = [
        {
            title: "中心编号",
            dataIndex: "col1",
        },
        {
            title: "中心名称",
            dataIndex: "col2",
        },
        {
            title: "中心级别",
            dataIndex: "col3",
        },
        {
            title: "创建时间",
            dataIndex: "col4",
        },
        {
            title: "工位数",
            dataIndex: "col5",
            align:"center",
        },
        {
            title: "师资人数",
            dataIndex: "col6",
            align:"center",
        },
        {
            title: "设备台套数",
            dataIndex: "col7",
            align:"center",
        },
        {
            title: "成果转化项目数",
            dataIndex: "col8",
            align:"center",
        },
        {
            title: "详细",
            width: 100,
            dataIndex: "col9",
            align:"center",
            render:() => <a href="http://www.lnve.net/lnvesjpt/subsiteIndexCentre.html?id=3&type=1" target="_blank">详细信息</a>
            
        },
        
    ];

    const dataSource: any[] = [
        {col1: "1014220210102001", col2: "材料工程系实训基地", col3: "国家级", col4: "2007-03-11", col5: 242, col6: 11, col7: 144,col8: 2,col9: "详细信息"},
        {col1: "1014220210102002", col2: "电气工程系实训基地", col3: "国家级", col4: "2004-05-23", col5: 1200, col6: 9, col7: 543,col8: 3,col9: "详细信息"},
        {col1: "1014220210102003", col2: "多功能共享型实训基地", col3: "省级", col4: "2018-04-12", col5: 512, col6: 3, col7: 241,col8: 1,col9: "详细信息"},
        {col1: "1014220210102004", col2: "师范教育系实训基地", col3: "其他", col4: "1998-09-14", col5: 386, col6: 6, col7: 167,col8: 3,col9: "详细信息"},
        {col1: "1014220210102005", col2: "船舶工程系实训基地", col3: "国家级", col4: "2007-10-16", col5: 302, col6: 7, col7: 109,col8: 2,col9: "详细信息"},
        {col1: "1014220210102006", col2: "信息工程系实训基地", col3: "其他", col4: "2001-07-02", col5: 290, col6: 2, col7: 143,col8: 1,col9: "详细信息"},
        {col1: "1014220210102007", col2: "信息技术管理中心实训基地", col3: "其他", col4: "2002-09-11", col5: 1530, col6: 7, col7: 877,col8: 3,col9: "详细信息"},
    ];

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>{num}</Button>

            <Modal visible={visible} onCancel={() => setVisible(false)} title="校内实训明细" width={1000} footer={false}>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Modal>
        </>
    );
}
