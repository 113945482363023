import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { Form, Button, Select, Row, Col, Input, message } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import useRequestFn from "../../../lib/useRequestFn";
import { jbqkschool } from "../../../service/basic";
import useRequest from "../../../lib/useRequest";
import qs from "qs";
import { getAllHotMainkey, getSchoolList } from "../../../service/training";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";
export default function PageTrainingMain () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案" }]);

  const [planform] = Form.useForm();
  const [courseform] = Form.useForm();
  const [filters, setFilters] = useState < any > ({});

  // const [getJbqkschool, schoolData] = useRequestFn(jbqkschool);
  const [getJbqkschool, schoolData] = useRequestFn(() => getNormalData < any > ("b99a81e4-1e09-4aba-9a93-318798b9e4fb")); // 按高校查专业


  const [hotKey] = useRequest(getAllHotMainkey);
  // const [hotKey] = useRequest(()=>getNormalData<any>(''))

  const [planPk, setPlanPk] = useState();
  const [coursePk, setCoursePk] = useState();

  useState(() => {
    getJbqkschool()
  })

  const history = useHistory();

  function gotoPath (id: string, name: string, code: string) {
    history.push(`/training/SchoolMajorList?school_id=${id}&name=${name}&code=${code}`);
  }

  /**
   * 设置PK跳转值
   * @param val
   */
  const setPlanValue = (key: string, val: any) => {
    setPlanPk(Object.assign({}, planPk, { [key]: val }));;
  }

  const setCourseValue = (key: string, val: any) => {
    setCoursePk(Object.assign({}, coursePk, { [key]: val }));;
  }

  /**
   * 跳转
   * @param values
   */
  const onFinish = (values: any) => {

    const subject_name = values.subject_name ? values.subject_name : "";
    const in_requirements = values.in_requirements ? values.in_requirements : "";
    history.push(`/training/ProfessionList?subject_name=${subject_name}&in_requirements=${in_requirements}`);
  };

  const finish = (values: any) => {

    const courseName = values.courseName ? values.courseName : "";
    const schoolName = values.schoolName ? values.schoolName : "";
    if (courseName == '')
    {
      message.warning('请输入课程名称关键词 !');
      return
    }
    history.push(`/training/CourseList?courseName=${courseName}&schoolName=${schoolName}`);
  };

  const jumpTo = (name: string) => {

    history.push(`/training/CourseList?courseName=${name}`);
  };

  const { isMobile } = useDevice()

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Form onFinish={onFinish} form={planform} labelCol={{ span: 4 }}>
            <Row gutter={12}>
              <Col span={isMobile ? 24 : 5}>
                {/* <div className={style.searchTitle}>培养方案检索</div> */}
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="subject_name" labelCol={{ span: 8 }} >
                  <Input className={style.searchType} onChange={(event) => setPlanValue("subject_name", event.target.value)} placeholder="请输入专业关键词" />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="in_requirements" label="" labelCol={{ span: 8 }}>
                  <Select placeholder="选择入学类型" size={'large'} className={style.searchType} onChange={(val) => setPlanValue("in_requirements", val)}>
                    <Select.Option value="">全部</Select.Option>
                    <Select.Option value="普通高考">普通高考</Select.Option>
                    <Select.Option value="对口升学">对口升学</Select.Option>
                    <Select.Option value="自主招生">自主招生</Select.Option>
                    <Select.Option value="“三二分段”学生">“三二分段”学生</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 7}>
                <Button type="primary" className={style.searchBtn} htmlType="submit">
                  搜索
                </Button>
                <Button className={style.pkBtn} onClick={() => history.push(`/training/pyfadb`)}>
                  <span>PK</span>培养方案对比
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
          <div className={style.listTitle}><span>按学校查专业</span></div>
          <div className={style.schoolList}>
            {(schoolData?.data || []).map((item: any) => (
              <div className={style.schoolRow} key={item.id}>
                <span className={style.sortNum}>{item.code}</span>
                <Button title={item.name} type="link" className={style.antButton} onClick={() => gotoPath(item.id, item.name, item.code)}>{item.name}</Button>
                {/* <span title={item.stu_count} className={style.sortNum}>({item.stu_count})</span> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.courseSearch}>
        <div className={style.search}>
          <Form onFinish={finish} form={courseform} labelCol={{ span: 4 }}>
            <Row gutter={12}>
              <Col span={isMobile ? 24 : 5}>
                <div className={style.searchTitle}>课程检索</div>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="courseName" labelCol={{ span: 8 }} >
                  <Input className={style.searchType} placeholder="请输入课程名称关键词" onChange={(event) => setCourseValue("courseName", event.target.value)} />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="schoolName" label="" labelCol={{ span: 8 }}>
                  <Select placeholder="选择院校" size={'large'} className={style.searchType} onChange={(val) => setCourseValue("schoolName", val)}>
                    <Select.Option value="">全部</Select.Option>
                    {(schoolData?.data || []).map((item: any, index: number) => (
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 7}>
                <Button type="primary" className={style.searchBtn} htmlType="submit">
                  搜索
                </Button>
                <Button className={style.pkBtn} onClick={() => history.push(`/training/CoursesCompare?${qs.stringify(coursePk)}`)}>
                  <span>PK</span>课程对比
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
          <div className={style.listTitle}><span>热门搜索关键字</span></div>
          <div className={style.keyList}>
            <Row gutter={[24, 24]} className={style.hotSearch}>
              {hotKey?.map((item: any) => (
                <Col span={isMobile ? 12 : 5}><div onClick={() => jumpTo(item.name)} className={`${style.keyWords} ${style[`color${Math.floor(Math.random() * (5 - 1 + 1)) + 1}`]}`}>{item.name}</div></Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

