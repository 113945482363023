import useRequest from "../../../../../../lib/useRequest";
import { findOptions, getNormalData } from "../../../../../../service/basic";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import { useHistory } from "react-router-dom";
import Color from "color";
interface StaticNumProps {
    uuid: string;
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    theme?: string;
    onEvents?: any;
    schoolList?: any;

}
const colorList = ["#991f1f", "#8f1f99", "#1f2799", "#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"]

export default function EChartsUuid ({ uuid, theme, customOption, onEvents }: StaticNumProps) {
    const history = useHistory();
    const [data] = useRequest(() => getNormalData < any > (uuid));
    //点击每一项
    const onEventsObj = {
        'click': () => {
            history.push("/basic/main")
        }
    }

    const options: any = useMemo(() => {
        return {
            color: colorList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b}{c}所 : {d}%"
            },
            grid: { //网格大小
                x: 150,
                y: 15,
                x2: 1,
                y2: 20,
                borderWidth: 1,
            },
            labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
            },
            label: {
                alignTo: 'edge',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#999'
                    }
                }
            },
            series:[
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '70%',
                    data: data&&data.map((item:any)=>{
                        return {name:item.name,value:item.count}
                    }),
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
            ]
        }
    }, [data])
    return (
        <div>
            <ReactEcharts style={{ cursor: 'default' }}
                onEvents={onEventsObj}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                theme={theme}
            />

        </div>


    );
}