import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";

interface IProps {
  title: string;
  onEvents?: any;
  style?: any;
  customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>;
}

export default function Index ({ title, style, customOption, onEvents }: IProps) {
  const option = {
    color: ['#347afc'],
    title: {
      text: title,
      left: 'center',
      top: 10,
      textStyle: {
        color: '#347afc'
      }
    },
    tooltip: {},

    radar: {
      name: {
        textStyle: {
          color: '#333',
          borderRadius: 3,
          padding: [3, 5]
        },
        formatter: function (value: any) {
          let list = value.split("");
          let result = "";
          for (let i = 1; i <= list.length; i++)
          {
            if (!(i % 10) && list[i] != undefined)
            {
              result += list[i - 1] + '\n';
            } else
            {
              result += list[i - 1];
            }
          }
          return result;
        },
      },
      indicator: [
        { name: '我省高职院校毕业生年终就业率(%)', max: 100 },
        { name: '毕业生专业相关就业率(%)', max: 100 },
        { name: '毕业生平均月薪(元)', max: 5000 },
        { name: '毕业生认为收入低于预期比例(%)', max: 100 },
        { name: '高职院校毕业生在入职三年内获得加薪或晋升比例(%)', max: 100 },
      ],
      center: ['50%', '60%'],
      radius: 90,

    },
    splitLine: {
      show: true,
      lineStyle: {
        width: 1,
        color: ['blue', 'red']
        // 图表背景网格线的颜色
      }
    },
    series: [{
      name: '',
      type: 'radar',
      data: [
        {
          value: [90.03, 62, 3837, 39.72, 75.68],
          name: '',
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0.1,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#ccdefe',
                }],
              global: false
            }
          }
        },
      ],
      label: {
        show: true,
        formatter: function (params: any) {
          return params.value;
        }
      },
    }],
  };

  const opt = useMemo(() => {
    if (option)
    {
      return customOption?.(option) || option;
    } else
    {
      return option;
    }
  }, [option]);

  return <ReactEcharts style={{ transform: 'translateY(-30px)' }} onEvents={onEvents} theme={"theme"} option={opt} />;
}

Index.defaultProps = {
  // title: "高职院校毕业生就业质量和职业前景情况"
  title: ''
}
