import style from "./index.module.scss";
import LoginForm from "./components/LoginForm";
import LoginLinks from "./components/LoginLinks";
import {FaServer} from "react-icons/fa";

export default function PageLogin() {

    return (
        <div className={style.root}>
            <div className={style.container}>
                <h1 className={style.title}>
                    <FaServer size={60} style={{verticalAlign: -15, marginRight: 20}} />
                    辽宁省职业教育大数据平台
                </h1>

                <div className={style.main}>
                    <LoginLinks />
                    <LoginForm />
                </div>
            </div>
        </div>
    );
}
