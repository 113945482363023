import {Button} from "antd";
import React from "react";
import {MenuOutlined} from "@ant-design/icons";
import style from "./index.module.scss";
import Banner from "../Banner";

interface HeaderMobileProps {
    onMenu: () => void;
}

export default function HeaderMobile({onMenu}: HeaderMobileProps) {

    return (
        <div className={style.root}>
            <Banner className={style.banner} />

            <div className={style.nav}>
                <div className={style.logo}>
                    辽宁省职业教育大数据平台
                </div>

                <Button className={style.button} onClick={onMenu}><MenuOutlined /></Button>
            </div>
        </div>
    )
}
