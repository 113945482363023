const cityDatas = [{
    name: "沈阳市",
    coord: [123.429096,
        41.796767],
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
}, {
    name: "大连市",
    coord: [121.618622,
        38.91459],
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
}, {
    name: "铁岭市",
    coord: [
        123.844279,
        42.290585
    ],
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
}, {
    coord: [
        123.921109,
        41.875956
    ],
    name: "抚顺市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
}, {
    coord: [
        123.770519,
        41.297909
    ],
    "name": "本溪市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        124.383044,
        40.124296
        ],
    "name": "丹东市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "鞍山市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "辽阳市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "营口市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "盘锦市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "锦州市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "阜新市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "朝阳市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},{
    coord: [
        123.770519,
        41.297909
    ],
    "name": "葫芦岛市",
    value: Math.floor(50+ Math.random() * 50),
    circleColor: '#f00',
    areaColor: '#f8f8f8',
    year: '精品课'
},
    {
        name: "沈阳市",
        coord: [123.429096,
            41.796767],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    }, {
        name: "大连市",
        coord: [121.618622,
            38.91459],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    }, {
        name: "铁岭市",
        coord: [
            123.844279,
            42.290585
        ],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    }, {
        coord: [
            123.921109,
            41.875956
        ],
        name: "抚顺市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    }, {
        coord: [
            123.770519,
            41.297909
        ],
        "name": "本溪市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            124.383044,
            40.124296
        ],
        "name": "丹东市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "鞍山市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "辽阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "营口市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "盘锦市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "锦州市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "阜新市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "朝阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "葫芦岛市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '在线课程'
    },
    {
        name: "沈阳市",
        coord: [123.429096,
            41.796767],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    }, {
        name: "大连市",
        coord: [121.618622,
            38.91459],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    }, {
        name: "铁岭市",
        coord: [
            123.844279,
            42.290585
        ],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    }, {
        coord: [
            123.921109,
            41.875956
        ],
        name: "抚顺市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    }, {
        coord: [
            123.770519,
            41.297909
        ],
        "name": "本溪市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            124.383044,
            40.124296
        ],
        "name": "丹东市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "鞍山市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "辽阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "营口市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "盘锦市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "锦州市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "阜新市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "朝阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "葫芦岛市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '资源共享'
    },
    {
        name: "沈阳市",
        coord: [123.429096,
            41.796767],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    }, {
        name: "大连市",
        coord: [121.618622,
            38.91459],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    }, {
        name: "铁岭市",
        coord: [
            123.844279,
            42.290585
        ],
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    }, {
        coord: [
            123.921109,
            41.875956
        ],
        name: "抚顺市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    }, {
        coord: [
            123.770519,
            41.297909
        ],
        "name": "本溪市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            124.383044,
            40.124296
        ],
        "name": "丹东市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "鞍山市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "辽阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "营口市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "盘锦市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "锦州市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "阜新市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "朝阳市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    },{
        coord: [
            123.770519,
            41.297909
        ],
        "name": "葫芦岛市",
        value: Math.floor(50+ Math.random() * 50),
        circleColor: '#f00',
        areaColor: '#f8f8f8',
        year: '实训课'
    }
]

export default cityDatas;
