import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../lib/useRequest";
import { getNormalData } from "../../../../service/basic";
let colorList = [
    "#ffd200",
    "#ffad56",
    "#f77070",
    "#e740a6",
    "#7d6cff",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",

];
export default function Index(props: any) {
    //扩招生源类别示意图
    const [data] = useRequest(() => getNormalData<any>('da49431b-c421-489d-a072-960562eb83cf'))
    let list = data?.option.series[0].data.map((item: any) => {
        return {
            name: item.student_type,
            value: item.value
        }
    })

    // ['新型职业农民','退役军人','农民工','下岗失业人员','企业员工','其他']
    const options: any = useMemo(() => {
        return {
            color: colorList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b}{c}人 : {d}%"
            },
            grid: { //网格大小
                x: 150,
                y: 15,
                x2: 1,
                y2: 20,
                borderWidth: 1,
            },
            labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
            },
            label: {
                alignTo: 'edge',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#999'
                    }
                }
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    //   radius: ['40%', '70%'],
                    radius: '75%',
                    // center: ['50%', '50%'],
                    avoidLabelOverlap: false,
                    data: list,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
    }, [data])

    return (
        <div >
            <ReactEcharts
                style={{ height: '250px' }}
                option={options}
            />
        </div>
    );
}
