import { Spin } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { findSubjectTeacherList } from "../../../../../service/major";
import style from '../basic/index.module.scss'

export default function TeamInfo({subjectId}: {subjectId: string;}) {

    const [ data, { loading } ] = useRequest(() => findSubjectTeacherList(subjectId))

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>职业技能等级证书</h1>
                </div>
                <div className={style.table}>
                    <Spin spinning={loading}>
                        <table>
                            <tr>
                                <th>姓名</th>
                                <th>出生年月</th>
                                <th>专业技术职务</th>
                                <th>任职情况</th>
                                <th>最终学位</th>
                                <th>行业企业经历年限</th>
                                <th>双师证名称</th>
                            </tr>
                            {
                                data?.map((item, index) => (
                                    <tr key={`teacher-${index}`}>
                                        <td>{item.tname}</td>
                                        <td>{item.tbirth}</td>
                                        <td>{item.tprofessional}</td>
                                        <td>{item.rzqkName}</td>
                                        <td>{item.tdegree}</td>
                                        <td>{parseInt(item.hyqyjlYears)}</td>
                                        <td>{item.ssProve}</td>
                                    </tr>
                                ))
                            }
                        </table>
                    </Spin>
                </div>
            </div>
        </div>
    );
}
