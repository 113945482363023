import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { Form, Input, Button, message, Select, Row, Col, Image, Table, Space } from 'antd';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';
import VocationalRow from "../../../components/VocationalRow";
import { useHistory } from "react-router-dom";
import useRequestFn from "../../../lib/useRequestFn";
import { getSchAndSubByCourse, getSchoolList } from "../../../service/training";
import useRequest from "../../../lib/useRequest";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";
const { Option } = Select;
export default function CourseList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "课程搜索" }]);

  const history = useHistory();

  const { courseName } = useQuery();
  const { schoolName } = useQuery();

  const [cours, setName] = useState(courseName)
  const [school, setSchool] = useState(schoolName)

  const [getData, searchResult] = useRequestFn(() => getNormalData < any > ('a13a91a8-cde2-4770-8b0a-a9ed94b8a8c6', { courseName: cours, schoolName: school }))
  let [schoolList, schoolListData] = useRequestFn(() => getNormalData < any > ('99c6b5bb-b7b2-4916-a25a-2ab965b68522'))
  const { isMobile } = useDevice()
  useEffect(() => {
    getData()
    schoolList()
  }, [])

  //点击搜索执行搜索功能
  let onSearch = () => {
    if (cours == '')
    {
      message.warning('请输入课程名称关键词 !');
      return
    }
    getData()
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: '',
      width: 70,
      render: (text: any, record: any, index: any) => `${index + 1}`,
      responsive: ['md'], // 手机分辨率不显示该列
    },
    {
      title: '课程名称',
      width: 100,
      dataIndex: 'courseName',
      fixed: isMobile ? "left" : false,
    },
    {
      title: '开设院校',
      width: 110,
      dataIndex: 'schoolName',
    },
    {
      title: '开设专业',
      width: 110,
      dataIndex: 'subjectName',
    },
    {
      title: '课程性质',
      width: 100,
      dataIndex: 'course_quality',
    },
    {
      title: '总学时',
      width: 80,
      dataIndex: 'all_time',
    },
    {
      title: '学习目标',
      dataIndex: 'xxmb',
    },
    {
      title: '查看详情',
      render: (text: any, data: any) => <Space size="middle">
        <Button className={style.tableButton} onClick={() => history.push(`/training/CourseDetail?courseId=${data.courseId}`)}>
          详细
        </Button>
      </Space>,
    },
  ];
  function onChange (pagination: any, filters: any, sorter: any, extra: any) {
  }
  // 获取学校列表信息
  let data = schoolListData.data
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Row gutter={[16, 16]}>
            <Col span={isMobile ? 24 : 6}>
              <div className={style.title}><span>课程检索</span></div>
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <Input placeholder="请输入课程名称关键词" defaultValue={courseName} onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <Select placeholder={"选择院校"} style={{ width: "100%" }} onChange={(value) => { setSchool(value) }} defaultValue={school ? school : ''}>
                <Option value={''}>全部</Option>
                {data ? data.map((item: any, index: number) => {
                  return (
                    <Option key={index} value={item.schoolName}>{item.schoolName}</Option >
                  )
                }) : []
                }
              </Select>

            </Col>
            <Col span={isMobile ? 24 : 6}>
              <Button type="primary" style={{ marginRight: "20px" }} htmlType="submit" onClick={onSearch}>
                搜索
              </Button>
              <Button className={style.blueButton} onClick={() => history.push("/training/CoursesCompare")}>
                PK 课程对比
              </Button>
            </Col>
          </Row>
        </div>
        <div className={style.list}>
          <Table
            className={style.tableContent}
            columns={columns}
            dataSource={searchResult.data ? searchResult.data : []}
            onChange={onChange}
            scroll={isMobile ? { x: 800 } : undefined}
          />
        </div>
      </div>
    </div>
  );
}
