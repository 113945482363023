import styles from "../../index.module.scss";
import useRequest from "../../../../../../../../../lib/useRequest";
import {getNormalData} from "../../../../../../../../../service/basic";
import styles2 from "./index.module.scss";
import classNames from "classnames";


export default function CardInfo({code,file,ffcy, onClick}: any) {

    const [modalData] = useRequest(() => getNormalData < any > ('15d3f21d-c06d-11ec-ba43-005056958024',{code,file,ffcy}),{},[ffcy])//产业人才对接统计表

    function getText(name: string) {
        return modalData?.find((item: any) => item.name === name)?.count || "";
    }

    return (
        <div className={classNames(styles.info, styles2.root)}>
            <div className={styles.title}>
                {ffcy}
            </div>

            <div className={styles.footer}>
                <span title={"学校数"} className={styles.info1}>{getText('学校数')}</span>
                <span title={"专业数"} className={styles.info2}>{getText('专业数')}</span>
                <span title={"专业布点数"} className={styles.info3}>{getText('专业布点数')}</span>
                <span title={"学生数"} className={styles.info4}>{getText('学生数')}</span>
                <span className={styles.more} onClick={onClick}>详细 <img src="../../img/right.png" alt=""/></span>
            </div>
        </div>
    )
}
