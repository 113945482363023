import React, { useMemo, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption } from "echarts";
import { toNumber } from "../../../../../lib/utils";
import useRequest from '../../../../../lib/useRequest';
import { getNormalData } from '../../../../../service/basic';
import useDevice from "../../../../../lib/useDevice";




const colors = [
  "#488bf0",
  "#dab606",
  "#ee167b",
  "#06da90",
  "#ee167b",
  "#06da90",
]

// @ts-ignore
const Options: EChartOption<EChartOption.Series> = {
  title: {
    text: '某站点用户访问来源',
    subtext: '纯属虚构',
    left: 'center',
    show: false
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    right: 165,
    top: 45,
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 12,
    textStyle: { color: "rgb(179,193,255)" },
  },
  grid: {
  },
  color: colors,
  series: [
    {
      name: '访问来源',
      type: 'pie',
      radius: '55%',
      center: ['35%', '38%'],
      data: [
        { value: 52897, name: '顶岗实习' },
        { value: 28721, name: '跟岗实习' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]

};


export default function Round1 (props: any) {
  //实习计划开出情况
  const [data] = useRequest(() => getNormalData < any > ('e66dffd8-06c8-4f4e-80b3-379829b7502f'))
  const { isMobile } = useDevice()

  const opt = useMemo(() => {
    if (data)
    {
      return {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: isMobile ? 100 : 165,
          top: 45,
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 12,
          textStyle: { color: "rgb(179,193,255)" },
          selectedMode: false
        },
        ...data,
        grid: {
        },
        color: colors,
        series: [
          {
            name: '',
            type: 'pie',
            radius: '55%',
            center: ['35%', '38%'],
            data: [
              { value: data?.dgrs, name: '顶岗实习' },
              { value: data?.ggrs, name: '跟岗实习' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    } else
    {
      return {};
    }
  }, [data])

  return (
    <ReactEcharts theme={"theme"} option={opt} onEvents={{
      "click": (e) => {
        props.methods(e.name);
      }
    }} style={{ height: "250px", width: '' }} />
  )
}
Round1.defaultProps = {
  title: ''
}
