//@ts-nocheck
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import _ from "lodash";
import { useHistory } from 'react-router-dom';
import Color from "color";
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";

const colorList = ["#991f1f", "#8f1f99", "#1f2799", "#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"];

const Options: EChartOption<EChartOption.Series> = {

    // @todo 改标签颜色
    series: [
        {
            data: [
                {
                    name: "校内实训",
                    value: "15.34",
                    label: {
                        normal: {
                            formatter: (params: any) => {
                                return "{icon|●}{name|" + params.name + "}";
                            },
                            padding: [0, -50, 25, -60],
                            rich: {
                                icon: {
                                    fontSize: 16,
                                    color: Color("#991f1f").lighten(1).hex()
                                },
                                name: {
                                    fontSize: 14,
                                    padding: [0, 5, 0, 5],
                                    color: Color("#991f1f").lighten(1).hex()
                                },
                                percent: {
                                    padding: [0, 5, 0, 0],
                                    color: Color("#991f1f").lighten(1).hex()
                                },
                            },
                        },
                    },
                },
                {
                    name: "顶岗实习",
                    value: "10.22",
                    label: {
                        normal: {
                            formatter: (params: any) => {
                                return "{icon|●}{name|" + params.name + "}";
                            },
                            padding: [0, -50, 25, -60],
                            rich: {
                                icon: {
                                    fontSize: 16,
                                    color: Color("#8f1f99").lighten(1).hex()
                                },
                                name: {
                                    fontSize: 14,
                                    padding: [0, 5, 0, 5],
                                    color: Color("#8f1f99").lighten(1).hex()
                                },
                                percent: {
                                    padding: [0, 5, 0, 0],
                                    color: Color("#8f1f99").lighten(1).hex()
                                },
                            },
                        },
                    },
                },
                {
                    name: "虚拟仿真",
                    value: "2.43",
                    label: {
                        normal: {
                            formatter: (params: any) => {
                                return "{icon|●}{name|" + params.name + "}";
                            },
                            padding: [0, -50, 25, -60],
                            rich: {
                                icon: {
                                    fontSize: 16,
                                    color: Color("#1f2799").lighten(1).hex()
                                },
                                name: {
                                    fontSize: 14,
                                    padding: [0, 5, 0, 5],
                                    color: Color("#1f2799").lighten(1).hex()
                                },
                                percent: {
                                    padding: [0, 5, 0, 0],
                                    color: Color("#1f2799").lighten(1).hex()
                                },
                            },
                        },
                    },
                },
            ],

            type: "pie",
            radius: ["70%", "40%"],
            labelLine: {
                normal: {
                    length: 20,
                    length2: 70,
                },
            },
            itemStyle: {
                color: function (params: any) {
                    const color = Color(colorList[params.dataIndex]);

                    return {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: color.lighten(1).hex() // 0% 处的颜色
                        }, {
                            offset: 1, color: color.hex() // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
                }
            },
        },
    ],
    tooltip: {
        formatter: (params) => {
            if (_.isArray(params)) {
                return "123";
            }
            if (params.dataIndex === 0) {
                return `校内实训基地<br/>工位数: ${params.value} 万个`;
            }
            if (params.dataIndex === 1) {
                return `顶岗实习基地<br/>服务人数: ${params.value} 万人`;
            }
            if (params.dataIndex === 2) {
                return `虚拟仿真实验教学<br/>服务人数: ${params.value} 万人`;
            } else {
                return "123";
            }
        },
    },
};

interface IProps {
    title: string;
    style?: any;
}
export default function Index({ style }: IProps) {
    const history = useHistory();
    const [data] = useRequest(() => getNormalData<{name: string; value: string}[]>("fb39b991-4c02-4eec-a7a9-85c91230f02e")); // 实训数据分析图
    return (
        <>
            {data &&
                <ReactEcharts
                    onEvents={{"click": () => history.push("/practice/list")}}
                    theme={"theme"} style={style}
                    option={{
                        ...Options,
                        series: [
                            {
                                ...Options.series[0],
                                data: data.map((item, index) => ({
                                    label: Options.series[0].data[index].label,
                                    ...item
                                }))
                            }
                        ]
                    }}
                />}
        </>
    );
}
Index.defaultProps = {
    title: "",
};
