import React, { useState } from "react";
import { Button } from 'antd';
import style from './index.module.scss'
import BasicRefresh from "./components/basicRefresh";
import TeamInfo from "./components/team";
import ConditionInfo from "./components/condition";
import useRequest from "../../../lib/useRequest";
import { findSpecialtyInfoById } from "../../../service/major";
import useQuery from "../../../lib/useQuery";
import { useHistory } from "react-router-dom";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";


export default function PageTrainPlan () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "详细" }]);

  const { id } = useQuery()

  const history = useHistory();

  const [current, setCurrent] = useState('1');
  // const [ data ] = useRequest(() => findSpecialtyInfoById(id));
  const [data] = useRequest(() => getNormalData < any > ("f83d94ed-9e14-42c6-aad4-24d1a64efae9", { id: id })) //专业详情页
  return (
    <div className={style.root}>
      <div className={style.container} style={{ height: "auto", paddingBottom: 20 }}>
        <div className={style.title}>
          <div className={style.schooltitle}>{data?.data.schoolName}({data?.data.schoolCode})</div>
          <div className={style.professionaltitle}>{data?.data.subjectName}({data?.data.subjectCode})</div>
          <div className={style.titlebutton} onClick={() => history.push(`/training/trainPlan?subjectId=${id}`)}>
            <Button>培养方案</Button>
          </div>
        </div>
      </div>

      <div className={style.tabContent}>
        {current === '1' && data && <BasicRefresh subjectId={id} data={data} />}
        {/*之前的样式不要了*/}
        {/*{current === '1' && data && <Basic subjectId={id} data={data} />}*/}
        {current === '2' && <TeamInfo subjectId={id} />}
        {current === '3' && <ConditionInfo subjectId={id} />}
      </div>
    </div>
  );
}
