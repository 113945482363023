import { Carousel } from 'antd';
import { DotPosition } from 'antd/lib/carousel';
import React, {useEffect, useState} from "react";

interface ChartCarouselProps {
    title: React.ReactNode[];
    children: React.ReactNode;
    speed?: number;
    hideTitle?: boolean;
    dotPosition?: DotPosition;
    onTitleChange?: (title: React.ReactNode) => void;
}

export default function ChartCarousel({title, children, dotPosition, hideTitle = false, speed=3000, onTitleChange}: ChartCarouselProps) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        afterChangeHandler(0);
    }, []);

    function afterChangeHandler(current: number) {
        setIndex(current);
        onTitleChange?.(title[current]);
    }

    return (
        <div>
            {!hideTitle && <div style={{fontSize: 16, color: "#2ec7c9", textAlign: "center", fontWeight: "bold"}}>{title[index]}</div>}

            <Carousel dotPosition={dotPosition} autoplaySpeed={speed} autoplay dots={false} afterChange={afterChangeHandler}>
                {children}
            </Carousel>
        </div>
    );
}
