/**
 * 特色理工
 */
import styles from "./index.module.scss"
import {useEffect, useState} from "react";
import classNames from "classnames";
import {getNormalData, SpecialType} from "../../../../../../../../service/basic";
import useRequest from "../../../../../../../../lib/useRequest";
import TitleCount from "../TitleCount2";
import Table from "../Table";
interface Block1Props {
    id: string;
    file: string,
    titleName: any;
}

export default function BlockSpecial(props: Block1Props) {
    const [dataSource, setDataSource] = useState<SpecialType[]>([]);
    const [keyWord, setKeyword] = useState('');
    const [data] = useRequest(() => getNormalData < SpecialType[] > ('fe90017d-d1b4-11ec-ba43-005056958024',{"subject_name":"工程"}));

    useEffect(() => {
        if(data){
            setDataSource(data);
        }

        if(keyWord) {
            const res = dataSource.filter(item => !!~item.subject_name.indexOf(keyWord)).map(item => ({...item, key: item.subject_code}));
            setDataSource(res);
        }
    }, [data, keyWord]);

    return (
        <div className={classNames(styles.root)}>
            <div className={styles.right} >
                <TitleCount onClick={setKeyword} />
                <Table list={dataSource || []} />
            </div>
        </div>
    )
}
