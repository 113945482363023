import {Link} from "react-router-dom";
import classNames from "classnames/bind";
import {FaServer} from "react-icons/fa";
import React from "react";
import {useHistory, withRouter} from "react-router-dom";

const style = classNames.bind(require("./index.module.scss"));

export default withRouter(function GuestNav() {

    const history = useHistory();
    const cat = history.location.pathname.split("/")[1];

    return (
        <div className={style("root")}>

            <div className="container">
                <div className={style("logo")}>
                    <FaServer size={24} style={{verticalAlign: -4, marginRight: 5}} /> 辽宁省职业教育大数据平台
                </div>
            </div>

            <div className={style("nav")}>
                <div className="container">
                    <div className={style("wrapper")}>
                        <Link className={style("link", {current: cat === "main"})} to="/main">工作页</Link>
                        <Link className={style("link", {current: cat === "basic"})} to="/basic/list">基本情况</Link>
                        <Link className={style("link", {current: cat === "major"})} to="/major/list">专业信息</Link>
                        <Link className={style("link", {current: cat === "training"})} to="/training">培养方案</Link>
                        <Link className={style("link", {current: cat === "course"})} to="/course/list">课程信息</Link>
                        <Link className={style("link", {current: cat === "practice"})} to="/practice/list">实习实训</Link>
                        <Link className={style("link", {current: cat === "employment"})} to="/employment/list">就业跟踪</Link>
                        <Link className={style("link", {current: cat === "service"})} to="/service/list">社会服务</Link>
                    </div>
                </div>
            </div>
        </div>
    );
})
