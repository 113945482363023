import ReactEcharts from 'echarts-for-react';
import {EChartOption} from "echarts";
import { useHistory } from 'react-router-dom';
import Color from "color";
import {useEffect} from "react";
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";
//
// const colorList = ["#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"];


var colorList = [
    "#8d7fec",
    "#5085f2",
    "#e75fc3",
    "#f87be2",
    "#f2719a",
    "#fca4bb",
    "#f59a8f",
    "#fdb301",
    "#57e7ec",
    "#cf9ef1",
];

const Options: EChartOption<EChartOption.Series> = {

    tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
    },
    grid: {
        left: '4%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: "#ffffff"
        }
    },
    xAxis: {
        type: 'category',
        //data: ["总体情况", "初级", "中级", "高级", "其他" ],
        data: [ "初级", "中级", "高级", "其他" ],
        axisLabel: {
            rotate: 30,
            color: "#ffffff"
        }
    },
    series: [
        {
            stack: "总数",
            data: [ 1277, 6937, 6474, 4175 ],
            name: "总数",
            label: { show: false, position: "insideRight" },
            type: "bar",
            barWidth: 50,
            itemStyle: {
                color: function (params: any) {
                    return colorList[params.dataIndex];
                }
            },
        },
        // {
        //     stack: "总数",
        //     //data: [4541, 841, 1572, 72, 2056],
        //     data: [4541],
        //     name: "35岁及以下",
        //     label: { show: true, position: "insideRight" },
        //     type: "bar",
        // },
        // {
        //     stack: "总数",
        //     //data: [7338, 341, 4181, 1589, 1227],
        //     data: [7338],
        //     name: "36－45岁",
        //     label: { show: true, position: "insideRight" },
        //     type: "bar",
        // },
        // {
        //     stack: "总数",
        //     //data: [6140, 90, 1111, 4345, 594],
        //     data: [6140],
        //     name: "46－60岁",
        //     label: { show: true, position: "insideRight" },
        //     type: "bar",
        // },
    ]
};


interface IProps{
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    style?: any
}

export default function Index({customOption, style}: IProps) {
    const history = useHistory()
    const [data] = useRequest(() => getNormalData<string[]>('9cec2bec-0668-48a8-a1ec-199a39de80d1')); // 教师职称分布

    return (
        <>
            {data && (
                <ReactEcharts
                    onEvents={{"click": () => history.push("/basic/main")}}
                    style={style}
                    theme={"theme"}
                    option={{
                        ...Options,
                        series: Options.series?.map(item => ({
                            ...item,
                            data: data
                        }))
                    }}
                />
            )}
        </>
    )
}
