import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import { ILevelCerts, IMajorAbility, ISubjectTrainBaseInfo } from "../../../../../service/training";
import useDevice from "../../../../../lib/useDevice";

interface BaseProps {
  data?: ISubjectTrainBaseInfo;
  abilityList?: IMajorAbility[];
  levelCertsList?: ILevelCerts[];
}

export default function BasicRule ({ data, abilityList, levelCertsList }: BaseProps) {
  const { isMobile } = useDevice()

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline}></div>
          <h1>1.基本信息</h1>
        </div>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>专业代码</div>
              <div className={style.content}>{data?.subjectCode}</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>专业名称</div>
              <div className={style.content}>{data?.subectName}</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>入学要求</div>
              <div className={style.content}>{data?.inRequirements}</div>
            </Col>
            {/* todo */}
            <Col className="gutter-row" span={isMobile ? 24 : 8}>
              <div className={style.title}>修业年限</div>
              <div className={style.content}>
                {data?.restYear}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
