// 社会服务用
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Button, Table } from "antd";
import { EChartOption, EChartsResponsiveOption } from "echarts";
import 'echarts/map/js/province/liaoning.js';
import useDevice from "../../../../../../../lib/useDevice";
import style from './style.module.scss';
import Popup from "../Popup";
import ThemeData from '../../../../../../../assets/walden.project.json';
import { IAreaCourse, ILevel, ISchoolInfo } from "../../../../../../../service/chart";
import _ from 'lodash';
import { IBusiness } from "../../../../../../../service/service";
import { VideoCameraOutlined } from "@ant-design/icons";
import { render } from '@testing-library/react';
interface ProvinceProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: number;
    height?: number;
    year: string[];
    tableType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList?: any;
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: string; schCount: string }[]; // 国家级院校
    schoolListSpecial?: ILevel[]; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
    getTable?: (title: string, school: string) => void;
    areaCourseListData?: IAreaCourse[];
    listData?: any;
    onTableData: (val: string) => void
}

interface DataArrTypes {
    [key: string]: {
        values: number[],
    }
}

// 红点坐标
const pointsRedCoord = {
    '沈阳市': [123.229096, 42.396767],
    '大连市': [121.618622, 39.51459],
    '鞍山市': [122.795632, 40.810626],
    '抚顺市': [124.421109, 41.775956],
    '本溪市': [124.070519, 41.297909],
    '丹东市': [124.383044, 40.524296],
    '锦州市': [121.135742, 41.419269],
    '营口市': [122.535151, 40.497432],
    '阜新市': [121.648962, 42.311796],
    '辽阳市': [123.38152, 41.269402],
    '盘锦市': [122.06957, 41.224484],
    '铁岭市': [124.044279, 42.740585],
    '朝阳市': [120.451176, 41.876758],
    '葫芦岛市': [120.256394, 40.755572]
};

const pointsBlueCoord = {
    '沈阳市': [122.929096, 41.796767],
    '大连市': [122.618622, 39.71459],
    '鞍山市': [123.295632, 40.510626],
    '抚顺市': [124.921109, 41.875956],
    '本溪市': [125.070519, 41.297909],
    '丹东市': [124.783044, 40.824296],
    '锦州市': [121.835742, 41.549269],
    '营口市': [122.435151, 40.267432],
    '阜新市': [122.148962, 42.311796],
    '辽阳市': [123.38152, 40.869402],
    '盘锦市': [122.06957, 41.024484],
    '铁岭市': [124.544279, 42.540585],
    '朝阳市': [119.851176, 41.576758],
    '葫芦岛市': [120.056394, 40.255572]
};

// 点击地区后的表数据
const levelColData = ['学校', '门数', '专业数', '学生数', '教师数'];
// const levelColData = ['学校', '专业总数',  '学生总数', '企业总数', '岗位总数'];
const level1DataList: (string | number)[][] = [
    ['辽宁城市建设职业技术学院', 19, 6240, 11, 132],
    ['辽宁广告职业学院', 43, 6638, 3, 21],
    ['辽宁轨道交通职业学院', 30, 4702, 14, 178],
    ['辽宁金融职业学院', 36, 8748, 10, 108],
    ['辽宁经济职业技术学院', 60, 9567, 7, 32],
    ['辽宁民族师范高等专科学校', 27, 3974, 2, 18],
    ['辽宁生态工程职业学院', 98, 12604, 10, 22],
    ['辽宁省交通高等专科学校', 53, 12726, 15, 46],
    ['辽宁特殊教育师范高等专科学校', 23, 1854, 2, 16],
    ['辽宁现代服务职业技术学院', 25, 10750, 9, 31],
    ['辽宁医药职业学院', 40, 8581, 7, 65],
    ['辽宁政法职业学院', 42, 2713, 4, 13],
    ['辽宁装备制造职业技术学院', 60, 14954, 21, 41],
    ['沈阳北软信息职业技术学院', 26, 3242, 2, 7],
    ['沈阳职业技术学院', 102, 15426, 24, 31],
];

// 点击专业总数后的表
const countColData = ['课程名', '专业', '班级', '时间', '地点', '人数', '教师'];
// const countColData = ['专业名称','课程数量','上课教师数','上课学生数'];
const countDataList = [
    ['虚拟现实应用技术', 25, 25, 582, '大连', 300, 500],
    ['物联网应用技术', 32, 34, 654, '大连', 300, 500],
    ['计算机应用技术', 21, 21, 522, '大连', 300, 500],
    ['市场营销', 18, 18, 391, '大连', 300, 500],
    ['旅游管理', 5, 5, 154, '大连', 300, 500],
    ['环境工程技术', 7, 7, 207, '大连', 300, 500],
    ['商务管理', 9, 9, 332, '大连', 300, 500],
    ['数控设备应用与维护', 12, 15, 298, '大连', 300, 500],
    ['音乐教育', 2, 2, 54, '大连', 300, 500],
    ['动漫制作技术', 4, 4, 107, '大连', 300, 500],
];

// 点击企业总数后的表
const businessColData = ['企业名称', '专业名称', '课程数量', '上课教师数', '上课学生数'];

export default function Province ({
    name, data, className, year,
    changeCityName, schoolList,
    geoColor = '46, 199, 201',
    tableType = 1, width = 1920,
    height = 1000, special = false,
    pointsBlue, pointsRed,
    schoolListSpecial,
    showTimeLine,
    onClean, getTable, listData,
    areaCourseListData,
    onTableData
}: ProvinceProps) {



    const [years, setYears] = useState < string[] > ([]);
    const echartsRef = useRef < any > (null);
    const [cityNameArr, setCityNameArr] = useState < string[] > ([
        '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市',
        '锦州市',
        '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市', '葫芦岛市']);
    const [dataArr, setDataArr] = useState < DataArrTypes > ({});
    const [geoRegions, setGeoRegions] = useState < any[] > ([]);
    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');
    const { isMobile } = useDevice()

    // table第一个表格的数据
    const [tabelDataList, setTableDataList] = useState < { col: string[], data: (string | number)[][] } > ({ col: levelColData, data: level1DataList });

    // table 第二个数据
    const [list, setList]: any = useState([]);
    useMemo(() => {
        setList([])
    }, [listData])

    // 将schoolList的值放入tableBody 然后循环
    const [tableBody, setTableBody]: any = useState([]);
    useMemo(() => {
        setTableBody(schoolList);
    }, [schoolList])

    //
    const [newTitle, setNewTitle] = useState('');

    // 切换标记
    const [tableCode, setTableCode] = useState < number > (1);
    const [historyTableCode, setHistoryTableCode] = useState < number[] > ([]);
    useEffect(() => {
        if (data)
        {
            // 城市名称 年份
            const yearArr: string[] = [];
            const geoRegions: any = []; // 城市颜色

            data.map(item => {
                if (!yearArr.includes(item.year)) yearArr.push(item.year);
            });

            cityNameArr.map((city, index) => {

                geoRegions.push({
                    name: city,
                    itemStyle: {
                        normal: {
                            areaColor: `rgba(${geoColor}, 0.8)`
                        }
                    }
                });
            });

            setYears(yearArr);
            setGeoRegions(geoRegions);
            initDataArr(cityNameArr);

        }
    }, [data]);

    // 按年份 dataArr数据
    function initDataArr (cityNameArr: string[]) {
        const dataArr: DataArrTypes = {};
        year.map(year => {
            dataArr[year] = { values: [] }
            cityNameArr.map(city => {
                data.map(item => {
                    if (item.year === year && item.name === city)
                    {
                        dataArr[year]['values'].push(item.value);
                    }
                })
            });
        });
        setDataArr(dataArr);
    }

    function formatPointData (data: { areaCode: string; schCount: string }[], isRedPoint: boolean = true) {
        const defaultData: { [key: string]: number[] } = isRedPoint ? pointsRedCoord : pointsBlueCoord;
        if (data.length > 0)
        {
            return data.map((item: any) => {
                const key: string = item.areaCode;
                return {
                    name: item.areaCode,
                    value: [...defaultData[key], item.schCount],
                    visualMap: false
                }
            });
        }

    }

    // 柱形及年份线 位置大小调整
    const barOption = {
        left: showTimeLine ? '10%' : '-100%',
        bottom: '0%',
        width: '80%'
    };


    // timeline 文字大小
    const timelineLabel = special ? {
        label: {
            rotate: '40',
            align: 'left',
            color: '#ffffff'
        }
    } : {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            }
        }
    };

    const timeline = useMemo(() => {
        return {
            title: {
                text: '',
                subtext: ''
            },
            toolbox: {
                show: false,
            },
            geo: {
                map: name,
                // regions: [
                //     ...geoRegions
                // ],
                label: {
                    normal: {
                        show: true,
                        color: '#ffffff'
                    },
                    emphasis: {
                        show: false,
                        color: '#ffffff'
                    }
                },
                itemStyle: {
                    normal: {
                        borderColor: 'rgba(147, 235, 248, 1)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                            }],
                            globalCoord: false // 缺省为 false
                        },
                        shadowColor: 'rgba(128, 217, 248, 1)',
                        shadowOffsetX: -2,
                        shadowOffsetY: 2,
                        shadowBlur: 10
                    },
                    emphasis: {
                        areaColor: `rgba(${geoColor}, 1)`,
                        // areaColor: 'normal' ,
                        borderWidth: 0
                    }
                },
                top: '0',
                left: 'center',
                bottom: '30%'
            },
            animation: true,
            animationDuration: 1000,
            animationEasing: 'cubicInOut',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'cubicInOut',
            timeline: {
                show: showTimeLine ? true : false,
                data: year,
                axisType: 'category',
                autoPlay: showTimeLine ? true : false,
                controlStyle: {
                    showPlayBtn: false,
                    showNextBtn: false,
                    showPrevBtn: false,
                    normal: {
                        color: '#666',
                        borderColor: '#666'
                    },
                    emphasis: {
                        color: '#aaa',
                        borderColor: '#aaa'
                    },
                    symbolSize: 10,
                    lineStyle: {
                        color: '#555'
                    },
                    checkpointStyle: {
                        borderColor: '#777',
                        borderWidth: 2
                    },
                },
                ...timelineLabel,
                // top: '700',
                ...barOption,
                bottom: 0,
                height: '50',
                playInterval: 7000,
            },
            // bar
            grid: {
                // top: '510',
                ...barOption,
                bottom: "100",
                height: '150'
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    color: '#fff',
                    // rotate: 40
                    fontSize: 10
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        width: 100
                    }
                },
                splitLine: {
                    show: false
                },
                data: cityNameArr,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#fff',
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                }
            },
            // bar end
            series: [
                {
                    type: 'bar',
                    data: dataArr[years[0]],
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: ThemeData.color[0]
                    }

                }
            ]
        }
    }, [dataArr, years]);

    const optionsArr = useMemo(() => {
        const optArr: any[] = [];
        years.map((year, index) => {
            optArr.push({
                visualMap: {
                    min: 0,
                    max: _.max(dataArr[year].values),
                    splitNumber: dataArr[year].values.length,
                    color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                    textStyle: {
                        color: '#fff'
                    },
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 10
                    },
                    data: cityNameArr,
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    show: false
                },
                series: [
                    {
                        type: 'map',
                        data: cityNameArr.map((item, index) => {
                            return {
                                name: item,
                                value: dataArr[year]["values"][index]
                            }
                        }),
                        geoIndex: 0,
                        map: name
                    },
                    {
                        data: dataArr[year]["values"],
                        type: 'bar',
                        itemStyle: {
                            color: ThemeData.color[index]
                        },
                        barWidth: 30,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff'
                        },
                        top: 0
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsRed ? formatPointData(pointsRed) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return isMobile ? 40 : 60;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: isMobile ? 14 : 20,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#F62157'
                            }
                        }
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: pointsBlue ? formatPointData(pointsBlue, false) : [],
                        // symbol: 'pin', //气泡
                        symbolSize: function (val: number) {
                            return 45;
                        },
                        label: {
                            normal: {
                                formatter: '{@[2]}',
                                color: '#fff',
                                fontSize: 20,
                                show: true
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#3f44ef'
                                // color: colors[colorIndex][n]
                            }
                        }
                    }
                ]
            })
        });
        return optArr;
    }, [dataArr, years, pointsRed]);

    const option: any = useMemo(() => {
        return {
            baseOption: timeline,
            options: optionsArr
        }
    }, [timeline, optionsArr]);

    const onEventsObj = {
        'click': (params: any) => {
            // 点击地图区域时重置表数据
            setTableCode(1);
            setHistoryTableCode([]);

            changeCityName?.(params.name, '');

            if (cityNameArr.includes(params.name) && params.componentType === "series" && params.componentSubType === "map")
            {
                const title = `${params.name}地区实时开课列表`;
                setPointMark('area');
                setTableTitle(title);
                setShow(true);
            }

            if (params.componentType === "series" && params.componentSubType === "scatter")
            {
                if (params.componentIndex === 2)
                {
                    setNewTitle(params.name)
                    const title = `${params.name}地区实时开课列表`;
                    setPointMark('area');
                    setTableTitle(title);
                    setShow(true);
                }
            }
        },
        'timelinechanged': (index: any) => {

        }
    };

    let columns = [
        {
            title: '学校',
            dataIndex: 'school_name',
            width: '30%'
        },
        {
            title: '门数',
            dataIndex: 'courseNum',
            render: (text: any, record: any, index: any) => {
                return <div onClick={() => {
                    setTableCode(2)
                    if (getTable)
                    {
                        // 将title 和 学校返回 返回之后请求接口, 重新给第二个表格赋值
                        getTable(newTitle, record.school_name ? record.school_name : '');
                        onTableData(record)
                    }
                }} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} >{text}</div>
            },
            sorter: {
                compare: (a: any, b: any) => a.courseNum - b.courseNum
            }

        },
        {
            title: '专业数',
            dataIndex: 'subjectNum',
            sorter: {
                compare: (a: any, b: any) => a.subjectNum - b.subjectNum
            }

        },
        {
            title: '学生数',
            dataIndex: 'studentNum',
            sorter: {
                compare: (a: any, b: any) => a.studentNum - b.studentNum
            }
        },
        {
            title: '教师数',
            dataIndex: 'teacherNum',
            sorter: {
                compare: (a: any, b: any) => a.teacherNum - b.teteacherNum
            }
        }
    ]
    let columns1 = [
        {
            title: '课程名',
            dataIndex: 'course_class',
        },
        {
            title: '专业',
            dataIndex: 'subject_name',
        },
        {
            title: '班级',
            dataIndex: 'course_class',
            sorter: {
                compare: (a: any, b: any) => a.course_class - b.course_class
            },
        },
        {
            title: '时间',
            dataIndex: 'time',
            sorter: {
                compare: (a: any, b: any) => a.time - b.time
            }
        },
        {
            title: '地点',
            dataIndex: 'course_place'
        },
        {
            title: '人数',
            dataIndex: 'stu_nums',
            sorter: {
                compare: (a: any, b: any) => a.stu_nums - b.stu_nums
            }
        },
        {
            title: '教师',
            dataIndex: 'teacher_name'
        },
        {
            title: '摄像头',
            dataIndex: 'url',
            render: (text: any) => {
                return <a href={text}><VideoCameraOutlined /></a>
            }
        }
    ]


    let dataSource = tableBody?.map((item: any, index: number) => {
        return {
            school_name: item.school_name,
            courseNum: item.courseNum,
            subjectNum: item.subjectNum,
            studentNum: item.studentNum,
            teacherNum: item.teacherNum,
            schoolId: item.schoolId
        }
    })
    let dataSource1 = listData?.map((item: any, index: number) => {
        return {
            course_name: item.course_name,
            subject_name: item.subject_name,
            course_class: item.course_class,
            time: item.time,
            course_place: item.course_place,
            stu_nums: item.stu_nums,
            teacher_name: item.teacher_name,
            url: item.url

        }
    })


    return (
        <div className={`${style.root} ${className}`}>
            <ReactEcharts
                theme={"theme"}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                ref={echartsRef}
                style={{ width, height, margin: '0 auto' }}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                onClean?.();
                setShow(false);
            }} show={show} width={isMobile ? '' : '80vw'}>
                <div className={`${style.tableWrapper} ${tableCode === 3 ? style.tableWrapperStyle2 : ''}`}>
                    <div className={style.tableTitle}>{tableTitle}
                        {historyTableCode.length > 0 && <Button type={"primary"} onClick={() => {
                            const code = historyTableCode.pop();
                            setHistoryTableCode(historyTableCode);
                            setTableCode(code || 1);

                        }} style={{ float: "right" }}>返回</Button>}

                        {tableCode === 2 && <Button type={"primary"} onClick={() => {
                            setTableCode(1);
                        }} style={{ float: "right" }}>返回</Button>}
                    </div>
                    {(tableType === 1 && pointMark === 'area') &&
                        <div className={style.tabScroll}>
                            {
                                tableCode !== 2 &&
                                <Table columns={columns} dataSource={dataSource} pagination={false} />
                            }
                            {tableCode === 2 &&
                                <Table columns={columns1} dataSource={dataSource1} pagination={false} />}
                            {/* <table className={style.table}>
                                <tbody className={style.tableList}>
                                    {tableCode === 1 && <>

                                        <tr className={style.tableListTitle}>
                                            {tabelDataList.col.map((item, index) => <th
                                                style={{ width: index === 2 ? "10%" : (index === 3 ? "12%" : "8%") }}>
                                                {item}
                                            </th>)}
                                        </tr>
                                        {
                                            tableBody?.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.school_name}</td>
                                                        <td onClick={() => {
                                                            setTableCode(2)
                                                            if (getTable)
                                                            {
                                                                // 将title 和 学校返回 返回之后请求接口, 重新给第二个表格赋值
                                                                getTable(newTitle, item.school_name ? item.school_name : '');
                                                                onTableData(item)
                                                            }
                                                        }} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} >{item.courseNum}</td>
                                                        <td>{item.subjectNum}</td>
                                                        <td>{item.studentNum}</td>
                                                        <td>{item.teacherNum}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>}
                                    {tableCode === 2 &&
                                       <div></div>

                                        // <>
                                        //     <tr className={style.tableListTitle}>
                                        //         {countColData.map((item: any, index: number) => <th
                                        //             style={{ width: index === 2 ? "10%" : (index === 3 ? "12%" : "8%") }}>
                                        //             {item}
                                        //         </th>)}
                                        //         <th style={{ width: '8%' }} >摄像头</th>
                                        //     </tr>
                                        //     {
                                        //         listData?.map((item: any, index: number) => (
                                        //             <tr key={index}>
                                        //                 <td>{item.course_name}</td>
                                        //                 <td>{item.subject_name}</td>
                                        //                 <td>{item.course_class}</td>
                                        //                 <td>{item.time}</td>
                                        //                 <td>{item.course_place}</td>
                                        //                 <td>{item.stu_nums}</td>
                                        //                 <td>{item.teacher_name}</td>
                                        //                 <td><a href={item.url}><VideoCameraOutlined /></a> </td>
                                        //             </tr>
                                        //         )) || []
                                        //     }
                                        // </>

                                    }

                                    {tableCode === 3 && <>
                                        <tr>
                                            <th style={{ width: "8%" }}>企业名称</th>
                                            <th style={{ width: "8%" }}>专业名称</th>
                                            <th style={{ width: "8%" }}>课程数量</th>
                                            <th style={{ width: "8%" }}>上课教师数</th>
                                            <th style={{ width: "8%" }}>上课学生数</th>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2}>中国铁路沈阳局集团有限公司</td>
                                            <td>铁道供电技术</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => {
                                                setHistoryTableCode([...historyTableCode, tableCode]);
                                                setTableCode(4);
                                            }}>3</span></td>
                                            <td>5</td>
                                            <td>102</td>
                                        </tr>
                                        <tr>
                                            <td>高速铁道工程技术</td>
                                            <td>2</td>
                                            <td>2</td>
                                            <td>78</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3}>沈阳地铁集团有限公司运营分公司</td>
                                            <td>城市轨道交通车辆技术</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setTableCode(4)}>4</span></td>
                                            <td>6</td>
                                            <td>97</td>
                                        </tr>
                                        <tr>
                                            <td>城市轨道交通工程技术</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setTableCode(4)}>2</span></td>
                                            <td>2</td>
                                            <td>52</td>
                                        </tr>
                                        <tr>
                                            <td>城市轨道交通通信新号技术</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setTableCode(4)}>1</span></td>
                                            <td>1</td>
                                            <td>23</td>
                                        </tr>
                                        <tr>
                                            <td>沈阳全程德邦物流有限公司</td>
                                            <td>物流信息技术</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setTableCode(4)}>1</span></td>
                                            <td>1</td>
                                            <td>32</td>
                                        </tr>
                                        <tr>
                                            <td>华洋海事中心有限公司</td>
                                            <td>船舶检验</td>
                                            <td><span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setTableCode(4)}>1</span></td>
                                            <td>1</td>
                                            <td>28</td>
                                        </tr>
                                    </>}
                                    {tableCode === 4 && <>
                                        <tr>
                                            <th style={{ width: "12%" }}>学校名称</th>
                                            <th style={{ width: "8%" }}>专业名称</th>
                                            <th style={{ width: "8%" }}>课程名称</th>
                                            <th style={{ width: "8%" }}>任课教师</th>
                                            <th style={{ width: "8%" }}>上课学生数</th>
                                            <th style={{ width: "8%" }}>摄像头</th>
                                        </tr>
                                        {areaCourseListData?.map((item: any, index) => <tr>
                                            <td>{item.school}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.course}</td>
                                            <td>{item.teacher}</td>
                                            <td>{item.studentNum}</td>
                                            <td><a href={item.url}><VideoCameraOutlined /></a> </td>
                                        </tr>)}
                                    </>}
                                </tbody>
                            </table> */}
                        </div>
                    }

                    {/* 2 某某市高职院校专业情况统计*/}
                    {(tableType === 2 && pointMark === 'area') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业总数</th>
                                <th>双高专业数</th>
                                <th>“1+x”专业数</th>
                            </tr>
                            {/*                        {schoolList?.map(item => <tr>
                            <td>{item.schoolName}</td>
                            <td>{item.subjectCount}</td>
                            <td>{item.is_sg}</td>
                            <td>{item.is_1x}</td>
                        </tr>)}*/}
                        </tbody>
                    </table>}

                    {/*红点 重点专业列表*/}
                    {(tableType === 2 && pointMark === 'red') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>级别</th>
                            </tr>
                            {/*                        {schoolList?.map(item => <tr>
                            <td>{item.schoolName}</td>
                            <td>{item.subjectName}</td>
                            <td>{item.is_sg}</td>
                        </tr>)}*/}
                        </tbody>
                    </table>}
                    {/*蓝点 特殊专业列表*/}

                    {(tableType === 2 && pointMark === 'blue') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>证书数量</th>
                            </tr>
                            {/* {schoolList?.map((item:any) => <tr>
                            <td>{item.schoolName}</td>
                            <td>{item.subjectName}</td>
                            <td>{item.is_1x}</td>
                        </tr>)} */}

                        </tbody>
                    </table>}


                    {/* 某某市国家级高级院校列表*/}
                    {(tableType === 1 && pointMark === 'red') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                                <th>层次</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                                <td>{item.doubleTall}</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 蓝 某某市省级示范性高级院校*/}
                    {(tableType === 1 && pointMark === 'blue') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 3 某某市课程情况统计*/}
                    {(tableType === 3 && pointMark === 'area') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>课程数量</th>
                                <th>精品课</th>
                                <th>在线课</th>
                                <th>资源共享课</th>
                                <th>实训课</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>3346</td>
                                <td>19</td>
                                <td>740</td>
                                <td>560</td>
                                <td>1002</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>5486</td>
                                <td>16</td>
                                <td>650</td>
                                <td>430</td>
                                <td>1209</td>
                            </tr>
                            <tr>
                                <td>辽宁轨道交通技术学院</td>
                                <td>4892</td>
                                <td>19</td>
                                <td>734</td>
                                <td>467</td>
                                <td>980</td>
                            </tr>
                            <tr>
                                <td>辽宁铁道职业技术学院</td>
                                <td>5643</td>
                                <td>15</td>
                                <td>790</td>
                                <td>667</td>
                                <td>1032</td>
                            </tr>
                            <tr>
                                <td>沈阳北软件职业信息技术学院</td>
                                <td>7654</td>
                                <td>19</td>
                                <td>543</td>
                                <td>675</td>
                                <td>1902</td>
                            </tr>

                        </tbody>
                    </table>}
                    {/*红点 某某市精品课列表*/}
                    {(tableType === 3 && pointMark === 'red') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>级别</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>国家级</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>省级</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>省级</td>
                            </tr>
                        </tbody>
                    </table>}
                    {/*蓝点 某某市在线课程列表*/}
                    {(tableType === 3 && pointMark === 'blue') && <table className={style.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>签到人数</th>

                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>2356</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>5674</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>7645</td>
                            </tr>
                        </tbody>
                    </table>}
                </div>

            </Popup>
        </div>
    )
}

Province.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
