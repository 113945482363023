import axios from "axios";
import qs from "qs";
import Config from "../config";

// interface ListItem {
//     title: string;
//     num: number;
// }
//
// /**
//  * 测试请求
//  */
// export function testQuery() {
//     return axios.post<SuccessData<ListItem[]>>(`${Config.BaseUrl}/ajb/kw/fhkw/14-15/1_10.html`, qs.stringify({name: "kenny"}))
//         .then(res => res.data.data);
// }




/**
 * 图表接口
 * @description http://yapi.dufe.tech/project/88/interface/api/4779
 */
export function findOptions(guid: string) {
    return axios.post<SuccessData<{option: echarts.EChartOption<echarts.EChartOption.Series>}>>(`/olap/dynamicsChart/findOptions`, qs.stringify({guid}))
        .then(res => res.data.data);
}



// 大屏 省市地图 第一屏 柱状图

/**
 * 学校数量
 */
export interface IDataTypes{
    name: string;
    coord: number[];
    value: string;
    circleColor: string;
    areaColor: string;
    year: string;
}

export interface INewDataTypes  extends Omit<IDataTypes, 'value'> {
    value: number
}

export function schoolCount() {
    return axios.post<IDataTypes[]>(`/lndc/dpzt0101`)
        .then(res => res.data);
}

/**
 * 招生人数
 */
export function newStudentCount() {
    return axios.post<IDataTypes[]>(`/lndc/dpzt0102`)
        .then(res => res.data);
}

/**
 * 教师数量
 */
export function teacherCount() {
    return axios.post<IDataTypes[]>(`/lndc/dpzt0103`)
        .then(res => res.data);
}

/**
 * 投入情况
 */
export function constMoney() {
    return axios.post<IDataTypes[]>(`/lndc/dpzt0104`)
        .then(res => res.data);
}

// end 大屏 省市地图 第一屏 柱状图

/**
 * 地区的具体学校明细列表
 * @param year
 * @param areaCode
 */
export interface ISchoolInfo{
    schoolName: string;
    stuCount: number;
    inCase: number;
    school_sx: string;
    all_area: string;
    jzmj_zj: string;
}

export function getSchoolInfoList(areaCode: string, year?: number) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/schoolInfoList`, qs.stringify({year, areaCode}))
        .then(res => res.data.data);
}

/**
 * 实时开课分布图-辽宁地图 弹出列表
 * @param name
 */
export function areaCourseList(name: string) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/areaCourseList`, qs.stringify({name}))
        .then(res => res.data.data);
}

/**
 * 实时开课分布图-辽宁地图 弹出列表
 * @param name
 */
export function teacherAreaHistogram(time?:string) {
    return axios.post<SuccessData<any>>(`/lndc/teacherAreaHistogram`,qs.stringify({time}))
        .then(res => res.data.data);
}



/**
 *  各地区的国家级高职院校院数(红点数)
 *  @param year
 */
export interface IPoint {
    data:object;
    schCount: string;
    areaCode: string;
}

export function getNationalLevelCount(year?: number) {
    return axios.post<SuccessData<IPoint[]>>(`/lndc/getNationalLevelCount`, qs.stringify({year}))
        .then(res => res.data.data);
}



/**
 * 实时开课分布图-辽宁省地图
 */
export function courseAreaMap(time? :string) {
    return axios.post<SuccessData<any>>(`/lndc/courseAreaMap`,qs.stringify({time}))
        .then(res => res.data.data)
        .then(data => data.map((item: any) => ({areaCode: item.name, schCount: item.value})));
}


/**
 * 各地区的省级高职院校院数(蓝点)
 * @param year
 */
export function getProvincialLevelCount(year?: number) {
    return axios.post<SuccessData<IPoint[]>>(`/lndc/getProvincialLevelCount`, qs.stringify({year}))
        .then(res => res.data.data);
}


/**
 * 双高查询详细列表  (红蓝点)
 */
export interface ILevel{
    name: string; // 学校名
    xzCode: string; // 性质代码
    doubleTall: string; // 级别加性质
}

export function getDoubleLevelList(areaCode: string, doubleTall: string, year?: number) {
    return axios.post<SuccessData<ILevel[]>>(`/lndc/getDoubleLevelList`, qs.stringify({areaCode, doubleTall, year}))
        .then(res => res.data.data);
}

/**
 * 大屏-柱状图-各地区专业/双高、1+x
 * @param guid
 */
export function getShuangGao() {
    return axios.post<{series: any, xAxis: string[]}>(`/lndc/dpzy0105`)
        .then(res => res.data);
}

/**
 * 首页双环
 */
export interface ICourseType{
    kcsx: string; // 课程类型
    kcsxCount: string; // 课程数量
    kcsxBfb: string;    // 百分比
    kclx: string;
    kclxCount: string;
}

export function getCourseType() {
    return axios.post<SuccessData<ICourseType[]>>(`/lndc/getCourseType`)
        .then(res => res.data.data);
}

export function getCourseProperty() {
    return axios.post<SuccessData<ICourseType[]>>(`/lndc/getCourseProperty`)
        .then(res => res.data.data);
}

// 第二屏
export interface ISubjectItem{
    code: string; // 编码
    name: string; // 名称
    value: string; // 数量
}
export interface ISubject{
    data: ISubjectItem[]
}

/**
 * 大屏--取得专业大类(前八)
 */
export function findSubjectBigList() {
    return axios.post<SuccessData<ISubject>>(`/specialty/findSubjectBigList`)
        .then(res => res.data.data)
        .then(res => res.data);
}

export interface ICityCountInfo{
    name: string; // 城市名称
    value: string; // 个数
}

export function findCityListBySubjectBigCode(code: string) {
    return axios.post<SuccessData<{data: ICityCountInfo[]}>>(`/specialty/findCityListBySubjectBigCode`, qs.stringify({code}))
        .then(res => res.data.data)
        .then(res => res.data);
}

/**
 * 大屏-专业布局-红点数 双高数
 */
interface IPointZG {
    name: string;
    value: string;
}

export function getsgNum(subjectName: string = '') {
    return axios.post<SuccessData<IPointZG[]>>(`/lndc/getsgNum`, qs.stringify({subjectName}))
        .then(res => res.data.data);
}

/**
 * 大屏-专业布局-蓝点数 1x数
 */
export function get1xNum(subjectName: string = '') {
    return axios.post<SuccessData<IPointZG[]>>(`/lndc/get1xNum`, qs.stringify({subjectName}))
        .then(res => res.data.data);
}

export function getSubjectKindNum() {
    return axios.post<SuccessData<IPointZG[]>>(`/lndc/getSubjectKindNum`)
        .then(res => res.data.data);
}

export function getCountrySgNum() {
    return axios.post<SuccessData<IPointZG[]>>(`/lndc/getCountrySgNum`)
        .then(res => res.data.data);
}

export function getXdxtzsdCountNum(level: string, subject: string) {
    return axios.post<SuccessData<IPointZG[]>>(`/lndc/getXdxtzsdCountNum?level=${encodeURIComponent(level)}&subjectName=${encodeURIComponent(subject)}`)
        .then(res => res.data.data);
}

export interface ISchoolInfo{
    is_sg: string;  // 双高个数
    is_1x: string;  // 1x个数
    subjectCount: string; // 专业个数
    schoolName: string; // 学校名称
    subjectName: string; // 专业名
    yjbysCount: number;
    studentCount: number;
    teaCount: number;

}

export interface ISchoolInfoCommon extends Omit<ISchoolInfo, 'subjectCount'>{
    yjbysCount: number;
    studentCount: number;
    teaCount: number;
    subjectName: string;
}

/**
 * 大屏—专业布局-区域明细
 * @param areaCode
 * @param year
 */
export function getCSXS(areaCode: string, subjectName?: string, year?: number) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/getCSXS`, qs.stringify({year, subjectName, areaCode}))
        .then(res => res.data.data);
}

/**
 * 大屏-专业布局-双高院校明细 （红色点）
 * @param areaCode
 * @param year
 */
export function getSGMX(areaCode: string, subjectName?: string, year?: number) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/getSGMX`, qs.stringify({year, subjectName, areaCode}))
        .then(res => res.data.data);
}

/**
 * 大屏-专业布局-是1x的院校明细(蓝色点)
 * @param areaCode
 * @param year
 */
export function get1XMS(areaCode: string, subjectName?: string, year?: number) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/get1XMS`, qs.stringify({year, subjectName, areaCode}))
        .then(res => res.data.data);
}

/**
 * 大屏-专业布局-是1x的院校明细(蓝色点)
 * @param areaCode
 * @param subjectName
 * @param level
 */
export function getCSXS2(areaCode: string, subjectName: string, level: string) {
    return axios.post<SuccessData<ISchoolInfo[]>>(`/lndc/getCSXS2`, qs.stringify({level, subjectName, areaCode}))
        .then(res => res.data.data);
}

/**
 * 就业地图-就业人数-城市专业明细
 * @param cityname
 */
export interface ISubjectType{
    subject_code: string;
    subject_name: string;
    jyrs: string;
    pjqx: string;
}

export function getZY(cityname: string) {
    return axios.post<ISubjectType[]>(`/lndc/jydtrsmx`, qs.stringify({cityname}))
        .then(res => res.data);
}


interface ShfwsankeyItem {
    data: any[];
    links: any[];
}

export function shfwsankey() {
    return axios.post<ShfwsankeyItem>(`/lndc/shfwsankey`)
        .then(res => res.data);
}

export interface SingleAxisChart {
    columns: any[];
    dataList: any[];
    rows: any[];
}

/**
 * 散点图数据局
 */
export function findSingleAxisChart() {
    return axios.post<SuccessData<{data: SingleAxisChart}>>(`/specialty/findSingleAxisChart`)
        .then(res => res.data.data.data);
}

interface TodayCourse {
    name: string;
    value: string;
}

/**
 * 今日课程统计
 */
 export function todayCourse(time?:string) {
    return axios.post<SuccessData<TodayCourse[]>>(`/lndc/todayCourse`,qs.stringify({time}))
        .then(res => res.data.data);
}

/**
 * 当前上课情况
 */
 export function currentCourseStatus() {
    return axios.post<SuccessData<TodayCourse[]>>(`/lndc/currentCourseStatus`)
        .then(res => res.data.data);
}


/**
 * [饼图] 专业类上课饼图
 */
 export function subjectClassCoursePieChart() {
    return axios.post<SuccessData<TodayCourse[]>>(`/lndc/subjectClassCoursePieChart`)
        .then(res => res.data.data);
}

interface schoolCourseHourProportionData {
    xAxis: {
        data: string[]
    },
    series: {
        data: number[]
        name: string
        stack: string
        type: string
    }[]
}

/**
 * [柱状图] 学校课时比例
 */
 export function schoolCourseHourProportion() {
    return axios.post<SuccessData<schoolCourseHourProportionData>>(`/lndc/schoolCourseHourProportion`)
        .then(res => res.data.data);
}

/**
 *  实时开课分布图-辽宁地图 弹出列表(学校参数)
 */
export interface IAreaCourse{
    teacher: string;
    school: string;
    subject: string;
    course: string;
    studentNum: string;
}
export function areaCourseListBySchool() {
     return axios.post<SuccessData<IAreaCourse[]>>(`/lndc/areaCourseListBySchool`)
         .then(res => res.data.data)
}

/**
 *  2020-2021学年第二学期职业技术职务授课情况统计
 */

export   function technicalStatistics(name?:string){
    return axios.post<SuccessData<IAreaCourse[]>>(`/lndc/cityHistogramTable`,qs.stringify({name}))
        .then(res => res.data.data)
}

export interface ICurrentTime {
  termAbb: string;
  date: string;
  jie: string;
  term: string;
  time: string;
  xingQi: string;
  xueQi: string;
  zhou: string;

}
// 获取默认时间 无时间默认当前时间 有时间 传年月日时分秒
export function currentTime(time?: string) {
  return axios.post<SuccessData<ICurrentTime>>(`/lndc/currentTime`, qs.stringify({ time }))
    .then(data => data.data.data);
}
export interface table {
  teacher: string;
  school: string;
  subject: string;
  course: string;
  studentNum: string;
  time: string;
  class: string,
}
/**
 *
 * 实时开课地图 第二个表的数据
 *
 */
export   function   secondTable(areaName?:string,schoolName?:string,time?:string){
    return axios.post<SuccessData<table[]>>(`/lndc/areaCourseListSubList`,qs.stringify({areaName,schoolName,time}))
        .then(res => res.data.data)
}
