import KechengShezhi from "../../School/KechengShezhi";
import React from "react";
import ReactEcharts from "echarts-for-react";
import useRequest from "../../../../lib/useRequest";
import { findOptions, getNormalData } from "../../../../service/basic";
import { useHistory } from "react-router-dom";

interface RadarChartProps {
  style?: any;
}

export default function RadarChart ({ style }: RadarChartProps) {
  const [data] = useRequest(() => getNormalData < { option: any } > ('cb097d7e-ff86-4022-a120-df7088efece2')); // 辽宁省高职院校在校生数规模示意图

  const dataRadar = data?.option.series[0].data || [];

  const dataname = dataRadar.map((item: any) => item.name);
  const datamax = [20, 20, 20, 20]
  const datavaule = dataRadar.map((item: any) => item.value);

  const indicator = []

  for (var i = 0; i < dataname.length; i++)
  {
    indicator.push({
      name: dataname[i],
      max: datamax[i],
    })
  }

  function contains (arrays: any, obj: any) {
    var i = arrays.length;
    while (i--)
    {
      if (arrays[i] === obj)
      {
        return i;
      }
    }
    return false;
  }

  const option: any = {

    tooltip: {
      show: false,
      trigger: "item",
    },
    radar: {
      center: ["50%", "50%"],
      radius: "65%",
      startAngle: 240,
      splitNumber: 5,
      splitArea: {
        areaStyle: {
          color: [
            'rgba(0,96,208, 0.1)', 'rgba(0,96,208, 0.2)',
            'rgba(0,96,208, 0.4)', 'rgba(0,96,208, 0.6)',
            'rgba(0,96,208, 0.8)', 'rgba(0,96,208, 1)'
          ].reverse()
        }
      },
      axisLabel: {
        show: true,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "transparent"
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "transparent"
        }
      },
      name: {
        formatter: function (value: any) {
          return '{b|' + value + '}'
        },
        textStyle: {
          rich: {
            a: {
              color: '#FFFFFF',
              fontSize: 15,
              padding: [0, 0],
              lineHeight: 20,
            },
            b: {
              color: '#CAEEFF',
              fontSize: 15,
              padding: [0, 0],
              lineHeight: 20,
            }
          },
        },
      },
      indicator: indicator
    },

    series: [{
      type: "radar",
      symbol: "circle",
      symbolSize: 7,
      tooltip: {
        trigger: 'item'
      },
      lineStyle: {
        color: "rgba(247,156,25, 0.5)"
      },
      areaStyle: {
        color: "rgba(247,156,25, 0.5)"
      },
      itemStyle: {
        color: 'rgba(247,156,25, 0.5)',
        borderColor: 'rgba(247,156,25, 1)',
        borderWidth: 1,
      },
      data: [datavaule]
    }]
  };

  const history = useHistory()
  return (
    data && <ReactEcharts onEvents={{ "click": () => history.push("employment/list") }} theme={"theme"} option={option} style={style} />
  );
}
