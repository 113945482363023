import {Button, Dropdown, Menu} from "antd";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import style from "./index.module.scss";

import smallIcon from '../../img/small.png';

export default function UserMenu() {

    const menu = (
        <Menu>
            <Menu.Item>
                <a href="#">修改密码</a>
            </Menu.Item>

            <Menu.Item>
                <a href="#">基本信息</a>
            </Menu.Item>
        </Menu>
    );


    return (
        <div className={style.btn}>
            <img className={style.avatar} src={smallIcon} />
            Admin
        </div>
    );
}
