
// 从错误对象中获取错误消息
import React from "react";

export function errorMessage(e: any, defaultMessage: string = "发生未知错误") {
    if (!e) return defaultMessage;
    const errCodes = [400, 401];

    if (!errCodes.includes(e.statusCode)) return "数据请求失败";
    if (e.message === "Network Error") return "网络异常";
    if (errCodes.includes(e.statusCode) && e.data && e.data.message) return e.data.message;
    if (e instanceof Error && e.message) return e.message;
    return defaultMessage;
}

// 判断空值
export function isEmpty(value: any) {
    return value === null || value === undefined;
}


export async function Try<T extends Promise<any>>(promise: T) {
    let error, data;

    try {
        data = await promise;
    } catch (e) {
        error = e;
    }

    return [data, error] as [ResolveType<T>, any];
}

interface MenuProps {
    name?: string;
    path: string;
    component?: React.FC;
    routes?: MenuProps[];
}

export function flattenRoute(menus: MenuProps[], basePath: string = "") {
    const res: Omit<MenuProps, "routes">[] = [];

    for (let menu of menus) {
        const path = basePath + menu.path;
        res.push({name: menu.name, path, component: menu.component});

        if (menu.routes) {
            const subMenu = flattenRoute(menu.routes, path);
            res.push.apply(res, subMenu);
        }
    }

    return res;
}

export function flattenGuestRoute() {

}


// 将各种类型的值转成数值类型
export function toNumber(value: number | string | null | undefined) {
    if (!value) return 0;
    return Number(value) || 0;
}

// 将各种类型的值转成数值类型
export function toInt(value: number | string | null | undefined) {
    return parseInt(toNumber(value).toString());
}
