import ReactEcharts from "echarts-for-react";
import React from "react";
import {EChartOption} from "echarts";


export default function Chat5(props: any) {
    let dataArr = props.data.value;
    let name = props.data.name
    const colorSet = {
        color: props.data.color
    };
    const options: EChartOption<any> = {
        angleAxis: {
            show: false,
            max: 100 * 360 / 270, //-45度到225度，二者偏移值是270度除360度
            type: 'value',
            startAngle: 225, //极坐标初始角度
            splitLine: {
                show: false
            }
        },

        radiusAxis: {
            show: false,
            type: 'category',
        },
        //圆环位置和大小
        polar: {
            center: ['50%', '50%'],
            radius: '110'
        },
        series: [{ //下层圆环，显示最大值
            type: 'bar',
            barMaxWidth: 14, //圆环宽度
            cursor: 'default',
            data: [{
                value: dataArr,
                itemStyle: {
                    color: colorSet.color
                }
            }],
            barGap: '-100%',
            coordinateSystem: 'polar',
            roundCap: true,
            z: 10
        }, {
            name: "内部进度条",
            type: "gauge",
            radius: '60%',
            axisLine: {
                lineStyle: {
                    color: [
                        [0 / 100, colorSet.color],
                        [1, "#111F42"]
                    ],
                    width: 8
                }
            },
            axisLabel: {show: false,},
            axisTick: {show: false,},
            splitLine: {show: false,},
            itemStyle: {show: false,},
            detail: {
                formatter: (value: any) => `${value}%`,
                offsetCenter: [0, 0],
                textStyle: {
                    fontSize: 16,
                    color: "#fff"
                }
            },
            title: { //标题
                show: true,
                offsetCenter: [0, 70], // x, y，单位px
                textStyle: {
                    color: "#fff",
                    fontSize: 12, //表盘上的标题文字大小
                    fontWeight: 400,
                    fontFamily: 'PingFangSC'
                }
            },
            data: [{
                name: name,
                value: dataArr,
            }],
            pointer: {
                show: false,
            },
        },
            {
                name: '外部刻度',
                type: 'gauge',
                radius: '70%',
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 10, //刻度数量
                startAngle: 225,
                endAngle: -45,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 1,
                        color: [
                            [1, 'rgba(0,0,0,0)']
                        ]
                    }
                }, //仪表盘轴线
                axisTick: {
                    show: true,
                    splitNumber: 4,
                    lineStyle: {
                        color: colorSet.color, //用颜色渐变函数不起作用
                        width: 1,
                    },
                    length: -8
                }, //刻度样式
                splitLine: {
                    show: false,
                }, //分隔线样式
                detail: {
                    show: false
                },
                pointer: {
                    show: false
                }
            },
        ]
    };

    return (
        <ReactEcharts
            style={{height: 200, width: 200}}
            option={options}
        />
    );
}
