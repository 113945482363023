import {Button} from "antd";
import { useHistory } from "react-router";
import style from "./index.module.scss";

export default function ToggleBtn() {

    const history = useHistory()

    return (
        <div className={style.root}>
            <Button className={style.left}>普通版</Button>
            <Button className={style.right} onClick={() => history.push("/charts")}>数字大屏</Button>
        </div>
    );
}
