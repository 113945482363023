import React from "react";
import Chat1 from "./components/Chat1";
import Chat2 from "./components/Chat2";
import {Col, Row} from "antd";

export default function Chart3() {
    return (
        <Row  >
            <Col span={12}>
                <Chat1 />
            </Col>
            <Col span={12}>
                <Chat2 />
            </Col>
        </Row>
    );
}
