import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { CoursePlanType } from "../../../../../service/training";
import ContentStyle from "./index.module.scss";
import {Pagination} from "antd";

interface CourseIdProps {
    contentDetail:CoursePlanType[];
}


export default function Content({contentDetail}: CourseIdProps) {

    const [data, setData] = useState<CoursePlanType>();
    const [title, setTitle] = useState("1")

    useEffect(() => {
        setData(contentDetail[0]);
    }, []);

    function itemRender(current: number, type: string, originalElement: any) {
        if (type === 'prev') {
            return <a>上一单元</a>;
        }

        if (type === 'next') {
            return <a>下一单元</a>;
        }

        return originalElement;
    }

    function onChange (page:any,pageSize:any){
        setData(contentDetail[page - 1]);
        setTitle(page);
    }
    return (
        <div className={style.root}>

            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>第{title}单元</h1>
                </div>

                <div className={style.table}>
                    <table className={ContentStyle.table}>
                        <tr>
                            <td>模块（项目/单元）名称</td>
                            <td>{data?.model_name}</td>
                        </tr>
                        <tr>
                            <td>教学内容</td>
                            <td>{data?.teach_content}</td>
                        </tr>
                        <tr>
                            <td>教学方法</td>
                            <td>{data?.way}</td>
                        </tr>
                        <tr>
                            <td>教学场所</td>
                            <td>{data?.address}</td>
                        </tr>
                        <tr>
                            <td>参考学时-理论</td>
                            <td>{data?.theory}</td>
                        </tr>
                        <tr>
                            <td>参考学时-实践</td>
                            <td>{data?.practice}</td>
                        </tr>
                    </table>
                </div>

                <div style={{textAlign: "center", paddingTop: 30}}>
                    <Pagination defaultCurrent={1} total={contentDetail.length * 10} itemRender={itemRender} onChange={onChange} showSizeChanger={false} />
                </div>
            </div>
        </div>
    );
}
