import React, { useEffect, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import {
  get1XMS,
  get1xNum,
  getCSXS,
  getSGMX,
  getsgNum,
  ICityCountInfo,
  INewDataTypes,
  ISchoolInfo,
} from "../../../../../service/chart";
import Province from "./map";
import cityCoordData from "../../../../../assets/cityCoord";
import { Select } from "antd";
import {
  countryMap, countryTable,
  findCityListBySubjectName,
  findFilterSubjectName,
} from "../../../../../service/major";
import { getNormalData } from "../../../../../service/basic";
import useRequestFn from "../../../../../lib/useRequestFn";
import useDevice from "../../../../../lib/useDevice";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
}

export default function SearchScreen ({ showTimeLine, width, height }: IProps) {
  const [subject, setSubject] = useState("");
  const [countryMapList] = useRequest(countryMap);
  const { isMobile } = useDevice()

  const [subjectData] = useRequest(
    () => findCityListBySubjectName(subject),
    {},
    [subject]
  );

  const [totalArray, setTotalArray] = useState([]);
  const [data, setData] = useState < INewDataTypes[] > ([]);
  const [typesData, setTypeData] = useState < string[] > (["专业"]);
  const [schoolList, setSchoolList] = useState < any[] > ([]);
  let tempData: INewDataTypes[] = [];

  // const [blueCountData] = useRequest(() => get1xNum(subject), {}, [subject]);

  const blueCountData = [
    {
      areaCode: null,
      areaColor: "#f8f8f8",
      circleColor: "#f00",
      coord: "[]",
      doubleTall: null,
      name: "抚顺市",
      schCount: null,
      value: "11",
      xzCode: null,
      year: null,
    }
  ]


  // const [redCountData] = useRequest(() => getsgNum(subject), {}, [subject]);//查询各省份就业人数
  const [redCountData] = useRequest(() => getNormalData < any > ('40b5e148-8409-4901-ad5b-b5ab464e7dc8'))



  const [name, setName] = useState('');

  const { Option } = Select;

  useEffect(() => {
    if (subjectData)
    {
      setData(formatData(subjectData));
    }
  }, [subjectData]);

  function formatData (data: ICityCountInfo[]) {
    const res = data.map((item) => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseInt(item.value),
        circleColor: "#f00",
        areaColor: "#f8f8f8",
        year: "专业",
      };
    });

    return buchongData(res);
  }

  function buchongData (data: INewDataTypes[]) {
    // 后台不存在的城市要补充上
    const cityArr = data.map((item) => item.name);
    const res = cityCoordData.filter(
      (item) => !cityArr.includes(item.name)
    );
    return [
      ...data,
      ...res.map((item) => {
        return {
          name: item.name,
          coord: cityCoord(item.name),
          value: 0,
          circleColor: "#f00",
          areaColor: "#f8f8f8",
          year: "专业",
        };
      }),
    ];
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter((item) => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  const [list, listData] = useRequestFn(() => getNormalData < any > (''))

  function getSchoolList (level: string, val: string) {
    // 表单数据,根据名字，请求不同数据 -- start
    countryTable(level).then((data) => {
      // // 循环原来的数据,拿到总和数据,拼接到一起
      const arr: any = [];
      countryMapList?.forEach((item) => {
        // 如果当前离开的名字 和原来数组中某个名字相同
        if (item.name === level)
        {
          // 做个新对象,和table的数据原,相同
          let obj = {
            company: item.company,
            school: item.school,
            subject: item.subject,
            count: item.value
          }
          // 放入新数组中
          arr.push(obj)
        }
      })
      // 将这个数据发到子组件中
      // 下面这这个table数据原不包括这里的
      setTotalArray(arr);

      //设置table数据
      setSchoolList(data);
      //--end
    });
    // 不知道要不要先隐藏（应该是没用了）
    // if (level) {
    //     if (level === "国家级") {
    //         getSGMX(val, subject).then((data) => {
    //             setSchoolList(data);
    //         });
    //     }
    //
    //     if (level === "省级") {
    //         get1XMS(val, subject).then((data) => {
    //             setSchoolList(data);
    //         });
    //     }
    // }
  }

  function onchange (val: string) {
    setSubject(val);
  }

  function onClean () {
    // 清除弹出层表单数据
    setSchoolList([]);
  }

  return (
    <div>
      {data && countryMapList && typesData && (
        <>
          <Province
            name="辽宁"
            tableType={2}
            width={width}
            height={height}
            data={data}
            year={typesData}
            geoColor="0, 167, 237"
            schoolList={schoolList || []}
            showTimeLine={false}
            changeCityName={(levelName, val) =>
              // 变化的时候,调用方法
              getSchoolList(levelName, val)
            }
            points={
              countryMapList
            }
            totalData={totalArray}
            pointsBlue={countryMapList?.map((item) => {
              return {
                areaCode: item.name,
                schCount: item.value,
              };
            })}

            pointsRed={redCountData?.map((item: any) => {
              return {
                areaCode: item.name,
                schCount: item.value,
              };
            })}
            onClean={onClean}
          // schoolListSpecial={schoolListSpecial || []}
          />
        </>
      )}
    </div>
  );
}

SearchScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800,
};
