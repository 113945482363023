import useRequest from "../../lib/useRequest";
import { findOptions, getNormalData } from "../../service/basic";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { EChartOption } from "echarts";
import Popup from "./components/Popup";
import style from './style.module.scss';
import useRequestFn from "../../lib/useRequestFn";
import { Table } from 'antd'
interface StaticNumProps {
    uuid: string;
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    theme?: string;
    onEvents?: any;
    schoolList?: any;

}

export default function EChartsUuid ({ uuid, theme, customOption, onEvents }: StaticNumProps) {

    const [data] = useRequest(() => getNormalData < any > (uuid));
    const opt = useMemo(() => {
        if (data && data.option)
        {
            if (customOption)
            {
                return customOption({ ...data.option, legend: { show: false } });
            } else
            {
                return { ...data.option, legend: { show: false } };
            }
        } else
        {
            return {};
        }
    }, [data])


    const [tableTitle, setTableTitle] = useState('');
    const [show, setShow] = useState(false);
    const [schoolList, setSchoolList] = useState < any > ([]);

    //获取辽宁省高职院校学校属性所占比例信息
    const [onList, list] = useRequestFn(getNormalData)
    async function onFn (name: string) {
        let res = await onList < any > ('55023e76-a089-4619-86c0-e93882dde8c8', { type: name, xz_code: name })
        if (res)
        {
            setSchoolList(res)
            setTableTitle(`${name}高职院校${res.length}所`)
        }
    }

    //点击每一项
    const onEventsObj = {
        'click': (params: any) => {
            onFn(params.name)
            setShow(true)
        }
    }
    let columns = [
        {
            title: '学校名称',
            dataIndex: 'schoolName',
            width: '25%',

        },
        {
            title: '学校属性',
            dataIndex: 'school_sx',
            width: '10%',
            filters: [
                {
                    text: '民办',
                    value: '民办'
                },
                {
                    text: '市属公办',
                    value: '市属公办'
                },
                {
                    text: '省属公办',
                    value: '省属公办'
                },
            ],
            onFilter: (value: any, record: any) => record.school_sx.indexOf(value) === 0,
        },
        {
            title: '在校生数',
            dataIndex: 'stuCount',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.teaCount - b.teaCount
            }

        },
        {
            title: '占地面积',
            dataIndex: 'all_area',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.all_area - b.all_area
            }

        },
        {
            title: '建筑面积',
            dataIndex: 'jzmj_zj',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.jzmj_zj - b.jzmj_zj
            }

        },
        {
            title: '年度经费投入',
            dataIndex: 'inCase',
            width: '10%',
            sorter: {
                compare: (a: any, b: any) => a.inCase - b.inCase
            }

        },
    ]
    let dataSource = schoolList?.map((item: any) => {
        return {
            schoolName: item.schoolName,
            school_sx: item.school_sx,
            stuCount: item.stuCount,
            all_area: item.all_area,
            jzmj_zj: item.jzmj_zj,
            inCase: item.inCase
        }
    })

    return (
        <div>
            <ReactEcharts style={{ cursor: 'default' }}
                onEvents={onEventsObj}
                option={opt}
                notMerge={true}
                lazyUpdate={true}
                theme={theme}
            />
            <Popup onClick={() => {
                // onClean?.();
                setShow(false);
            }} show={show} width='80vw'>
                <div className={style.tableWrapper}>
                    <div
                        className={style.tableTitle}>{tableTitle}</div>
                    <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                        <Table columns={columns} dataSource={dataSource} pagination={false} />

                        {/* <table className={style.table}>
              <tbody className={style.tableList}>
                <tr className={style.tableListTitle}>
                  <th style={{ width: '20vw' }}>学校名称</th>
                  <th style={{ width: '20vw' }}>学校属性</th>
                  <th style={{ width: '10vw' }}>在校生数</th>
                  <th style={{ width: '10vw' }}>占地面积</th>
                  <th style={{ width: '10vw' }}>建筑面积</th>
                  <th style={{ width: '10vw' }}>年度经费投入</th>
                </tr>
                {schoolList?.map((item: any, index: number) => <tr key={index}>
                  <td>{item.schoolName}</td>
                  <td>{item.school_sx}</td>
                  <td>{item.stuCount}</td>
                  <td>{item.all_area}</td>
                  <td>{item.jzmj_zj}</td>
                  <td>{item.inCase}</td>
                </tr>)}
              </tbody> */}
                        {/* </table> */}

                    </div>
                </div>

            </Popup >
        </div >


    );
}
