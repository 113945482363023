import React, {CSSProperties} from "react";
import classes from "./index.module.scss";
import classNames from "classnames";

interface BlockProps {
    title?: string;
    children: React.ReactNode;
    style?: CSSProperties;
    className?: string;
}

export default function Block({title, children, style, className}: BlockProps) {

    return (
        <div className={classNames(classes.root, className)} style={style}>
            <h1 className={classes.title}>{title}</h1>

            <div className={classes.body}>
                {children}
            </div>
        </div>
    );
}
