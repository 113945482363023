import ReactEcharts from 'echarts-for-react';
import {EChartOption} from "echarts";
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";
import {useEffect} from "react";



function getOption(data: any) {
    // let data = {
    //     male: {
    //         name: '总数',
    //         data: [{
    //             value: 18863,
    //             label: '总数'
    //         }, {
    //             value: 12854,
    //             label: '校内专任'
    //         }, {
    //             value: 1745,
    //             label: '校内兼课'
    //         }, {
    //             value: 3179,
    //             label: '校外兼职'
    //         }, {
    //             value: 1085,
    //             label: '校外兼课'
    //         }]
    //     },
    //     female: {
    //         name: '双高',
    //         data: [{
    //             value: 8184,
    //             label: '总数'
    //         }, {
    //             value: 7459,
    //             label: '校内专任'
    //         }, {
    //             value: 725,
    //             label: '校内兼课'
    //         }, {
    //             value: 0,
    //             label: '校外兼职'
    //         }, {
    //             value: 0,
    //             label: '校外兼课'
    //         }]
    //     }
    // };
    let yAxisData = new Set();
    let legendData: any[] = [];
    _.forEach(data, (d) => {
        legendData.push(d.name);
        _.forEach(d.data, (item) => {
            yAxisData.add(item.label);
        });
    });

    let top = 60;
    let bottom = 60;

//@ts-ignore
    yAxisData = [...yAxisData];
//@ts-ignore
    const Options: EChartOption<EChartOption.Series> = {
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            left: 'center',
            bottom: 24,
            itemWidth: 15,
            itemHeight: 11,
            itemGap: 20,
            borderRadius: 4,
            textStyle: {
                color: '#fff',
                fontSize: 14
            },
            data: legendData
        },
        grid: [{
            left: '12%',
            width: '28%',
            containLabel: true,
            bottom
        }, {
            left: '52%',
            width: '0%',
            bottom: bottom + 16
        }, {
            right: '12%',
            width: '28%',
            containLabel: true,
            bottom
        }].map(item => _.merge({
            top
        }, item)),
        //@ts-ignore
        xAxis: [{
            type: 'value',
            inverse: true,
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        }, {
            gridIndex: 1,
            show: true
        }, {
            gridIndex: 2,
            type: 'value',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        }].map((item) => _.merge(item, {
            axisLabel: {
                color: '#949AA8',
                margin: 0
            },
            splitLine: {
                lineStyle: {
                    color: '#E0E0E0',
                    type: 'dashed'
                }
            },
        })),
        //@ts-ignore
        yAxis: [{
            position: 'right',
            axisLabel: {
                show: false
            },
            axisLine: {
                show: true
            }
        },
            {
                gridIndex: 1,
                position: 'center',
                axisLabel: {
                    align: 'center',
                    padding: [8, 0, 0, 0],
                    fontSize: 12,
                    color: `#fff`
                },
                axisLine: {
                    show: false
                }
            },
            {
                gridIndex: 2,
                position: 'left',
                axisLabel: {
                    show: false
                },
                axisLine: {
                    show: true
                }
            }
        ].map((item) => {
            return _.merge(item, {
                type: 'category',
                inverse: false,
                axisLine: {
                    lineStyle: {
                        color: '#E0E0E0'
                    }
                },
                axisTick: {
                    show: false
                },
                data: yAxisData
            });
        }),
        series: [{
            name: _.get(data, 'male.name'),
            label: {
                position: 'left'
            },
            itemStyle: {
                color: '#01C5B2',
                barBorderRadius: [4, 0, 0, 4]
            },
            data: _.map(_.get(data, 'male.data'), d => d.value)
        },
            {
                xAxisIndex: 2,
                yAxisIndex: 2,
                name: _.get(data, 'female.name'),
                label: {

                    position: 'right'
                },
                itemStyle: {
                    color: '#FB6F6C',
                    barBorderRadius: [0, 4, 4, 0]
                },
                data: _.map(_.get(data, 'female.data'), d => d.value)
            }
        ].map(item => _.merge(item, {
            type: 'bar',
            barWidth: 11,
            label: {
                show: true,
                fontFamily: 'Rubik-Medium',
                fontSize: 14,
                distance: 10
            }
        }))
    };

    return Options;
}

interface IProps{
    customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
    style?: any
}

export default function Index({customOption, style}: IProps) {
    const history = useHistory();
    const [data] = useRequest(() => getNormalData('045b25d2-4491-4eaf-b69f-1bc620566069')); // 教师性质分布图

    return (
        <>
            {data && (
                <ReactEcharts
                    onEvents={{"click": () => history.push("/basic/main")}}
                    style={style} theme={"theme"}
                    option={getOption(data)}
                />
            )}
        </>
    )
}
