//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';
import Style from "../Shixi/index.module.scss"

export default function Index (props) {
    const history = useHistory()
    // 解构对象中的style
    let { style } = props;
    let [ydata, setYdata] = useState([]);


    useMemo(() => {
        setYdata(props.item)
    }, [props.item])

    var color = [
        '#448ff4',
        '#38baa8',
        '#38ba76',
        '#c7b30d',
        '#8f3ffd',
        '#6e3ffd',
        '#5757f2',
        '#4467f4',
        '#488bf0',
        '#e59d27',
        '#e55b26',
        '#ee167b',
    ];

    const Options: EChartOption<EChartOption.Series> = {
        color: color,
        legend: {
            orient: "vartical",
            cursor: 'default',
            x: "left",
            top: "3%",
            left: "60%",
            bottom: "0%",
            // data: xdata,
            itemWidth: 8,
            itemHeight: 8,
            itemGap: 12,
            textStyle: {
                color: "#fff",
            },
            selectedMode: false
        },
        grid: {
            bottom: "100px"
        },
        series: [
            {
                cursor: 'default',
                type: "pie",
                clockwise: false, //饼图的扇区是否是顺时针排布
                minAngle: 2, //最小的扇区角度（0 ~ 360）
                radius: ["70%", "40%"],
                center: ["30%", "40%"],
                avoidLabelOverlap: false,
                itemStyle: {
                    //图形样式
                    // normal: {
                    //     borderColor: "#08c",
                    //     borderWidth: 6,
                    // },
                },
                label: {
                    normal: {
                        show: false,
                        position: "center",
                        formatter: "{text|{b}}\n{c}%",
                        rich: {
                            text: {
                                color: "#fff",
                                fontSize: 14,
                                align: "center",
                                verticalAlign: "middle",
                                padding: 8,
                            },
                            value: {
                                color: "#fff",
                                fontSize: 24,
                                align: "center",
                                verticalAlign: "middle",
                            },
                        },
                        formatter: function (data) {
                            if (data.value > 100)
                            {
                                return data.value + '人'
                            } else
                            {
                                return data.value + '人'
                            }
                        }
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: 24,
                        },
                    }

                },
                data: ydata,
            },
        ],
    };

    return (
        <div className={Style.root}>
            <ReactEcharts onEvents={{
                "click": (e) => {
                }
            }} theme={"theme"}
                style={style} option={Options} />
        </div>

    )
}
