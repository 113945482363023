import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Empty, Row } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { getCourseTrainingProgram, TeacherType } from "../../../../../service/training";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";
interface CourseIdProps {
  datas: any;
}

export default function CoursePropose ({ datas }: CourseIdProps) {

  const dataDetail = datas

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.headTitle}>
          <div className={style.blueline} />
          <h1>1.师资条件要求</h1>
        </div>
        <div className={style.table}>
          <table>
            <tbody>
              <tr>
                <th>教师</th>
                <th>人数条件</th>
                <th>专业技术职务条件</th>
                <th>职业资格条件</th>
                <th>专业领域</th>
                <th>在课程教学中承担的任务</th>
              </tr>
              {dataDetail?.teacher?.map((item: any, index: number) => (
                <tr key={item.major_field}>
                  <td>{item.major_tech}</td>
                  <td>{item.person_num}</td>
                  <td>{item.major_tech}</td>
                  <td>{item.vocation}</td>
                  <td>{item.major_field}</td>
                  <td>{item.task}</td>
                </tr>
              ))}
              {(!dataDetail?.teacher || dataDetail?.teacher.length === 0) && <tr><td colSpan={6}><Empty style={{ marginTop: "50px" }} description="暂无数据" /></td></tr>}
            </tbody>
          </table>
        </div>
        <div className={style.container}>
          <div className={style.headTitle}>
            <div className={style.blueline} />
            <h1>2.教学条件要求</h1>
          </div>
          <div className={style.main_jy}>
            <Col className="gutter-row" span={24}>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.teach_condition}</div>
            </Col>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.headTitle}>
            <div className={style.blueline} />
            <h1>3.教学方法与教学手段建议</h1>
          </div>
          <div className={style.main_jy}>
            <Col className="gutter-row" span={24}>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.teach_way}</div>
            </Col>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.headTitle}>
            <div className={style.blueline} />
            <h1>4.教材与参考资料</h1>
          </div>
          <div className={style.main_jy}>
            <Col className="gutter-row" span={24}>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.teach_reference}</div>
            </Col>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.headTitle}>
            <div className={style.blueline} />
            <h1>5.课程资源开发与应用建议</h1>
          </div>
          <div className={style.main_jy}>
            <Col className="gutter-row" span={24}>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.course_open_advice}</div>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}
