import axios from "axios";
import qs from "qs";
import Config from "../config";
interface Specialty {
    code: string;
    name: string;
    child: {
        code: string;
        name: string;
        child: {
            countValue: string;
            subjectCode: string;
            subjectName: string;
        }[]
    }[]
}

interface School {
    schoolName: string;
    schoolCode: string;
    child: {
        id: string;
        subjectDirection: string;
        restYear: string;
        xjpgResult: string;
        inRequirementsName: string;
    }[]
}

interface SpecialtyInfo {
    subjectBigName: string;
    subjectClassName: string;
    subjectCode: string;
    subjectName: string;
    subject: string;
    subjectDirection: string;
    enrollType: string;
    schoolName: string;
    schoolCode: string;
    restYear: string;
    industryName1?: string;
    industryName2?: string;
    industryName3?: string;
    occupationTypeName1?: string;
    occupationTypeName2?: string;
    occupationTypeName3?: string;
    is1x?: string;
    isSg?: string;
    trainTarget?: string;
    trainModel?: string;
    courseSystem?: string;
    teachMethod?: string;
    byReq?: string;
    firstZs?: string;
    zyjsAdmin?: string;
    zyjsAdminTel?: string;
    zyjsAdminEmail?: string;
    finishCount: string;
    subjectStuCount: {
        gz1Count: string;
        gz2Count: string;
        gz3Count: string;
    }
}

interface Teacher {
    tname: string;
    tbirth: string;
    tprofessional: string;
    rzqkName: string;
    tdegree: string;
    hyqyjlYears: string;
    ssProve: string;
}

interface PracticeBase {
    baseName: string;
    createTime: string;
    relyonEnterprise: string;
    yearStusxCount: string;
    perStusxCount: string;
}

interface Hxzyk {
    course_name: string;
    course_type: string;
    course_quality: string;
    study_goal_info: string;
    study_content_info: string;
    is_good_open: string;
    check_style: string;
}

interface FindSubject {
    occupationType:string;
    enrollType: string;
    industry1: string;
    eduType: string;
    subjectNameAndCode: string;
    subject: string;
    industry2: string;
    serve_direction: string;
    occupationType2: string;
    occupationType1: string;
    firstZs: string;
    zyssyx: string;
    inRequirements: string;
    subjectBig: string;
    schoolName: string;
}

interface FindSubjectB {
    course4: string;
    course3: string;
    core: {
        name: string;
        description: string;
    }[];
    subjectIntroduction: string;
    admissionStatus: {
        actualAdmissionNum:string;
        actualCheckInNum:string;
        admissionYear:string;
        planAdmissionNum:string;
        provinceCheckInNum:string;
    }[];
    subjectTeacherNum: string;
    teacher3: string;
    teacher2: string;
    teacher1: string;
    schoolStudentNum: string;
    course2: string;
    course1: string;
}

interface SpecialtySearch {
    id: string;
    subjectCode: string;
    subjectName: string;
    subjectDirection: string;
    restYear: string;
    inRequirements: string;
    schoolName: string;
}

/**
 * @name 按专业查高校
 * @description http://yapi.dufe.tech/project/88/interface/api/5058
 */
export function findSpecialtyList() {
    return axios.post<SuccessData<{data: Specialty[]}>>(`/specialty/findSpecialtyList`)
        .then(res => res.data.data.data);
}

/**
 * @name 根据专业编码查询专业学校列表
 * @param subjectCode
 * @description http://yapi.dufe.tech/project/88/interface/api/5085
 */
export function findSchoolListBySpecialtyCode(subjectCode: string) {
    return axios.post<SuccessData<{data: School[]}>>(`/specialty/findSchoolListBySpecialtyCode`, qs.stringify({subjectCode}))
        .then(res => res.data.data.data);
}

/**
 * @name 根据专业ID查询专业详细
 * @param id
 * @description http://yapi.dufe.tech/project/88/interface/api/5139
 */
export function findSpecialtyInfoById(id: string) {
    return axios.post<SuccessData<{data: SpecialtyInfo}>>(`/specialty/findSpecialtyInfoById`, qs.stringify({id}))
        .then(res => res.data.data.data);
}

/**
 * @name 根据专业ID取得专业教师基本情况
 * @param subjectId 专业ID
 * @description http://yapi.dufe.tech/project/88/interface/api/5229
 */
export function findSubjectTeacherList(subjectId: string) {
    return axios.post<SuccessData<{data: Teacher[]}>>(`/specialty/findSubjectTeacherList`, qs.stringify({subjectId}))
        .then(res => res.data.data.data);
}

/**
 * @name 根据专业ID取得校外实践基地情况
 * @param subjectId 专业ID
 * @description http://yapi.dufe.tech/project/88/interface/api/5247
 */
export function findSjBaseList(subjectId: string) {
    return axios.post<SuccessData<{data: PracticeBase[]}>>(`/specialty/findSjBaseList`, qs.stringify({subjectId}))
        .then(res => res.data.data.data);
}

/**
 * @name 核心专业课介绍
 * @param subjectId 专业ID
 * @description http://yapi.dufe.tech/project/88/interface/api/5238
 */
export function zyHxzykjs(subjectId: string) {
    return axios.post<SuccessData<Hxzyk[]>>(`/lndc/zyHxzykjs`, qs.stringify({subject_id: subjectId}))
        .then(res => res.data.data);
}

/**
 * @name 专业信息查看详情
 * @param id 专业ID
 * @description http://yapi.dufe.tech/mock/88/specialty/findSubjectA
 */
export function findSubjectA(id: string) {
    return axios.post<SuccessData<FindSubject>>(`/specialty/findSubjectA`, qs.stringify({id: id}))
        .then(res => res.data.data);
}

/**
 * @name 专业信息查看详情B
 * @param id 专业ID
 * @description http://yapi.dufe.tech/mock/88/specialty/findSubjectB
 */
export function findSubjectB(id: string) {
    return axios.post<SuccessData<FindSubjectB>>(`/specialty/findSubjectB`, qs.stringify({id: id}))
        .then(res => res.data.data);
}

/**
 * @name 按名称查询专业列表
 * @param subjectName 专业名称
 * @param schoolName 学校名称
 * @description http://yapi.dufe.tech/project/88/interface/api/5076
 */
export function findSpecialtyListByName(subjectName?: string, schoolName?: string) {
    return axios.post<SuccessData<{data: SpecialtySearch[]}>>(`/specialty/findSpecialtyListByName`, qs.stringify({schoolName, subjectName}))
        .then(res=>res.data.data.data)
}

export interface SpecialtyListChildItem {
    id: string;
    subjectBig: string;
    subject: string;
    subjectCode: string;
    subjectName: string;
    subjectDirection: string;
    enrollType: string;
    schoolId: string;
    schoolCode: string;
    schoolName: string;
    schoolCount: string;
    countValue: string;
    restYear: string;
    inRequirements: string;
    inRequirementsName: string;
    xjpgResult: string;
}

export interface SpecialtyListItem {
    subjectCode: string;
    subjectName: string;
    child: SpecialtyListChildItem[];
}

export function findSpecialtyListBySchoolId(schoolId: string) {
    return axios.post<SuccessData<{data: SpecialtyListItem[]}>>(`/specialty/findSpecialtyListBySchoolId`, qs.stringify({schoolId}))
        .then(res => res.data.data.data);
}

/**
 * 根据专业名称查询专业名称列表
 * @param name
 */
export function findFilterSubjectName(name: string) {
    return axios.post<SuccessData<{data: string[]}>>(`/specialty/findFilterSubjectName`, qs.stringify({name}))
        .then(res => res.data.data.data)
        .then(res => ['全部专业', ...res]);
}

/**
 * 根据专业名称取得城市列表
 * @param name
 */

export function findCityListBySubjectName(name: string) {
    return axios.post<SuccessData<{data: {name: string, value: string}[]}>>(`/specialty/findCityListBySubjectName`, qs.stringify({name}))
        .then(res => res.data.data.data);
}

export function lnMap() {
    return axios.post<SuccessData<{name: string, value: string}[]>>(`/lndc/lnMap`)
        .then(res => res.data.data);
}

export function countryMap() {
    return axios.post<SuccessData<{name: string, value: string,company:string,school:string,subject:string,count:number}[]>>(`/lndc/countryMap`)
        .then(res => res.data.data);
}


export interface SankeyValues {
    data: {
        depth: string;
        name: string;
    }[];
    links: {
        source: string;
        target: string;
        value: string;
        people: string;
    }[]
}


export function sankeyDiagramIndustryToSubjectClass() {
    return axios.post<SuccessData<SankeyValues>>(`/lndc/sankeyDiagramIndustryToSubjectClass`)
        .then(res => res.data.data);
}

export function sankeyDiagramIndustryToSubject(name: string) {
    return axios.post<SuccessData<SankeyValues>>(`/lndc/sankeyDiagramIndustryToSubject`, qs.stringify({name}))
        .then(res => res.data.data);
}

export function sankeyDiagramC2T2S(name: string) {
    return axios.post<SuccessData<SankeyValues>>(`/lndc/sankeyDiagramC2T2S`, qs.stringify({name}))
        .then(res => res.data.data);
}

export function sankeyDiagramIndustryToSubject2(name: string) {
    return axios.post<SuccessData<SankeyValues>>(`/lndc/sankeyDiagramIndustryToSubject2`, qs.stringify({name}))
        .then(res => res.data.data);
}


export interface countryTableValue {
    school:string;
    subject:string;
    count:string;
    company:string;
}

/**
 * 全国地图弹出表格
 * @param name
 */

export function countryTable(name: string) {
    return axios.post<SuccessData<countryTableValue[]>>(`/lndc/countryTable`, qs.stringify({name}))
        .then(res => res.data.data);
}

/**
 * 辽宁地图弹出表格列表
 * @param name
 */

export function lnTable(name: string) {
    return axios.post<SuccessData<countryTableValue[]>>(`/lndc/lnTable`, qs.stringify({name}))
        .then(res => res.data.data);
}
