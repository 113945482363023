import React, { useState } from "react";
import style from './school.module.scss'
import BasicTable from "./components/BasicTable";
import ProfessionalInfo from "./components/ProfessionalInfo";
import TrainingList from "./components/TrainingList";
import useQuery from "../../../lib/useQuery";
import CourseListTable from "../../Course/List/components/CourseListTable";
import PracticeListTable from "../../Practice/List/components/PracticeListTable";
import EmploymentListTable from "../../Employment/List/components/EmploymentListTable";
import ServiceListTable from "../../Service/List/components/ServiceListTable";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { Typography } from "antd";
import useRequest from "../../../lib/useRequest";
import { getNormalData, schoolDetail } from "../../../service/basic";
import Table from "./components/Table";
import useDevice from "../../../lib/useDevice";
export default function PageSchoolDetail () {

  const { id, tab = "1" } = useQuery();
  const { name } = useQuery();
  const [schoolData, { loading }] = useRequest(() => getNormalData < { table1: any; table2: any; introduction: string; video: string } > ('becd588e-7129-4d79-a981-4f4806ff764d', { id }), {}, [id]);

  const [current, setCurrent] = useState(tab);

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "基本情况", url: "/basic/list" }, { name: "详细" }]);

  const { isMobile } = useDevice()
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.title}>
          <div className={style.header}>
            <h1>{name}</h1>
          </div>
        </div>
        <div>
          <div style={{ padding: 0 }} className={style.title}>
            <h2>一、学校简介</h2>
          </div>
          <div className={style.schoolDetail}>
            <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '展开' }}>
              {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辽宁广告职业学院是隶属省教育厅的普通高等职业学院，为国内广告专业学院之首创，占地面积425499平方米，建筑面积127887平方米。教职工总数419名，在校学生5000余名。馆藏图书36.41万册，学院在沈阳北郊，与辽宁大学（文科校区）、沈阳师范大学、沈阳航空学院、沈阳工程学院等十余所高校共同营造沈阳北部高校文化圈。*/}
              {/*<div className={style.detailRow}>*/}
              {/*    辽宁广告职业学院建于1993年，是一所以广告专业为主的大专院校。设有专业：市场营销（营销与策划）、广告营销与策划、广告经营管理、商务管理、商务经济与代理、广告设计与制作、视觉传达艺术设计、服装工艺技术、旅游管理、艺术设计、编导等22个专业，18个培养方向。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    学院专建8000平米实训大楼，设置模拟广告公司、多媒体实训教室、模拟会务中心、形体活动演示厅、录音室、实训声像反馈厅、雕塑场、工艺车间、成果展厅、摄影棚、图像绘制场、图文印制场、服装成制场。有多种智能实训设施：电脑设施、动漫教学设施、线型编辑非线型编辑设施、音像录制设施、电脑雕刻设施、电脑喷绘设施、铜艺加工设施、不饱和树脂成型设施等。多年来，学院已经走出一条智能实训的教学之路，学院于2010年创建了九鼎视像文化传播有限公司，为学生实习与实训提供新的平台。并于2011年3月成为中国移动集团辽宁首家创业孵化基地。近年来，学院与多家知名企业签订订单教育协议，2014年新生将实现全额订单培养。理论与实践相结合的教学模式为我院毕业生的就业打下坚实的基础，我校的就业率连续十余年达到95%以上，在辽宁省所有高校中名列前茅，并荣获沈阳市创业就业先进单位。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    高凯征教授，曾任辽宁大学文化传播学院院长，现任任辽宁广告职业学院院长，辽宁大学学科带头人，硕士生导师，博士生导师。国内著名美学家教育家，荣获沈阳市优秀专家称号、五一劳动奖章、国务院专家津贴获得者、第五届鲁迅文学奖获得者、全国模范教师称号。兼任中国中外文艺理论研究会副会长、辽宁省文学会理事长，第十二届全运会评审专家组成员。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    学院采用“二元互导”的管理模式，即以老师为主导的管理者为一元，以学生为主导的被管理者为一元。让学生加入到学院的管理工作中，两者相互合作，积极参与学院民主管理，行使民主权利。根据我院学生群体的实际特点，为了更好地听取广大同学们的意见和建议，开展“尊师、敬学、重教”听证会，这是辽宁广告职业学院丰富的学生活动中的一项，是“二元互导”的具体体现，到目前为止已经举办了五十期，收到了广大师生的一致好评，解决了同学们生活和学习中的许多问题。同时为更好的建设学院提供了智力支持。辽宁广告职业学院校园听证会曾被评为辽宁省大学生思想政治教育精品活动。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    为了贯彻“三点一线”的指导思想，突出智能实训的教学模式，调动学生学习的积极性、主动性，学院一直把教学改革做为教学工作的重点来抓。2009年度学院首先进行了考试改革，改变了原有的单一笔答考试模式，综合使用研讨式、谈话式和笔答式的考试方式。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    其次进行了教学方法的改革，教学方法改革包括案例教学和创意教学两部分。案例教学从六个方面进行了研讨，分别是案例的实训功能、案例与文化知识讲授的关系、案例教学的方法、案例的收集、案例教学的目的性及案例教学的整体连贯性；创意教学从五个方面进行了研讨，分别是联想机制、联想实训设计的原则、联想元素的收集与开发、非视觉课程的创意教学思路、创意案例的分析的使用。教改过程采取的是研讨会和观摩课相结合的方式，边研讨边实施，使案例教学和创意教学在实践中不断完善。*/}
              {/*</div>*/}
              {/*<div className={style.detailRow}>*/}
              {/*    接下来学院将进一步进行艺术类专业的工作室建设和各专业知识体系结构的调整，用市场需要的人才标准培养学生，把市场引入学校。我院也将一如既往地贯彻“面向市场，开发潜能，学以致用，自强自立”十六字方针。扎扎实实工作，兢兢业业教学，勤勤恳恳钻研，为成为国内一流院校而继续努力！*/}
              {/*</div>*/}
              {schoolData?.introduction}
            </Typography.Paragraph>
          </div>
          <div style={{ padding: 0, marginBottom: "20px" }} className={style.title}>
            <h2>二、宣传视频</h2>
            {
              !schoolData?.video && <div className={style.build}>(正在建设中)</div>
            }

          </div>
          {
            schoolData?.video && <div style={{ paddingLeft: isMobile ? "0" : "250px", background: "#F7F8FC" }}>
              <video controls autoPlay width={isMobile ? '100%' : '600'} height={isMobile ? '100%' : '400'}>
                <source src="http://www.ggxy.com/news_video/zhaosheng_video/2019%E6%8B%9B%E7%94%9F%E8%A7%86%E9%A2%91%E7%9F%AD%E7%89%87%E5%B0%81%E9%9D%A2.png" type="video/ogg" />
                <source src={schoolData?.video} type="video/mp4" />
              </video>
            </div>
          }

        </div>
      </div>
      <div style={{ padding: 0 }} className={style.title}>
        <h2>三、学校基本情况</h2>
      </div>
      <div className={style.tabContent} style={{ paddingRight: isMobile ? 0 : 60 }}>
        {/*表格介绍学生情况又不要了*/}
        {/*<BasicTable id={id} />*/}
        {
          schoolData && <Table table1={schoolData?.table1 || []} table2={schoolData?.table2 || []} video="" introduction="" />
        }

      </div>
    </div>
  );
}
