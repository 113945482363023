// import Widget5 from "../../../School/Widget5";
// import Widget13 from "../../../School/Widget13";

import ChartCarousel from "../../../Components/ChartCarousel";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import style from "../../index.module.scss";
import doNotReRender from "../../../../../lib/doNotReRender";
import Color from "color";
import ECharts from "../../../../../components/ECharts";
import ECharts2 from "./ECharts2";


// 防止轮播切换是图表闪动
const PureChartUuid = doNotReRender(ECharts);
const PureChartUuid2 = doNotReRender(ECharts2);

const colorList = ["#991f1f", "#8f1f99", "#1f2799", "#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"]

export default function BlockLeft1 () {

    const history = useHistory();
    const [title, setTitle] = useState < React.ReactNode > ("");

    function ChartUuidOptions (opt: any) {

        const series = opt.series?.map((item: any) => {
            return {
                ...item,
                data: item.data.map((item: any, index: number) => ({ ...item, label: { color: Color(colorList[index]).lighten(1).hex() } })),
                top: 20,
                itemStyle: {
                    color: function (params: any) {

                        const color = Color(colorList[params.dataIndex]);
                        return {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: color.lighten(1).hex() // 0% 处的颜色
                            }, {
                                offset: 1, color: color.hex() // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    }
                },
                radius: '70%',
                center: ['50%', '50%'],
            }
        });

        return {
            ...opt,
            legend: { show: false },
            series,
            tooltip: {
                trigger: 'item',
                formatter: function (params: any) {
                    return `${params.seriesName} <br />${params.name}: ${parseInt(params.value)}所 (${params.percent}%)`;
                }
            }
        };
    }
    function ChartUuidOptions2 (opt: any) {

        const series = opt.series?.map((item: any) => {
            return {
                ...item,
                data: item.data.map((item: any, index: number) => ({ ...item, label: { color: Color(colorList[index]).lighten(1).hex() } })),
                top: 20,
                itemStyle: {
                    color: function (params: any) {

                        const color = Color(colorList[params.dataIndex]);
                        return {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: color.lighten(1).hex() // 0% 处的颜色
                            }, {
                                offset: 1, color: color.hex() // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    }
                },
                radius: '70%',
                center: ['50%', '50%'],
            }
        });

        return {
            ...opt,
            legend: { show: false },
            series,
            tooltip: {
                trigger: 'item',
                formatter: function (params: any) {
                    return `${params.seriesName} <br />${params.name}: ${parseInt(params.value)}所 (${params.percent}%)`;
                }
            }
        };
    }

    return (
        <div>
            <h1 onClick={() => history.push("/basic/main")} className={style.blockHeader}>{title}</h1>

            <ChartCarousel
                title={["院校办学性质分布图", "服务结构调整“三篇大文章”各产业链院校数量"]}
                hideTitle
                onTitleChange={setTitle}
            >
                <div>
                    <PureChartUuid
                        onEvents={{ "click": () => history.push("/basic/main") }}
                        style={{ paddingTop: 20 }}
                        uuid="4e48a1fa-c762-4b00-b937-1cea3bcf765c" theme="theme"
                        customOption={ChartUuidOptions}
                    />
                </div>

                <div>
                <PureChartUuid2
                        onEvents={{ "click": () => history.push("/basic/main") }}
                        style={{ paddingTop: 20 }}
                        uuid="bf102325-a409-11ec-ba43-005056958024" theme="theme"
                    />
                </div>
            </ChartCarousel>
        </div>
    );
}
