import { Col, Row } from "antd";
import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import {ISchoolInTrains, ISchoolOutTrains, ISubjectTrainBaseInfo, ITeacherTeams} from "../../../../../service/training";

interface trainProps {
    data?:ISubjectTrainBaseInfo;
    TrainsList?:ISchoolInTrains[];
    TeacherTeamList?:ITeacherTeams[];
    TrainsOutList?:ISchoolOutTrains[];
}

export default function Ensure({data,TrainsList,TeacherTeamList,TrainsOutList}:trainProps) {

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>1.师资队伍</h1>
                </div>
                <div className={style.table}>
                    <table>
                        <tbody>
                        <tr>
                            <th>姓名</th>
                            <th>学历学位</th>
                            <th>职称</th>
                            <th>是否双师</th>
                            <th>企业经验</th>
                            <th>任职情况</th>
                        </tr>
                        {
                            TeacherTeamList?.map((item,index) => {
                                return <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.education}</td>
                                    <td>{item.professional}</td>
                                    <td>{item.isDoubleName}</td>
                                    <td>{item.experience}</td>
                                    <td>{item.officeInfoName}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>2.教学设施</h1>
                </div>              
                <div className={style.table}>
                    <div className={style.title}>2.1 校内实训条件</div>
                    <table>
                        <tbody>
                            <tr>
                                <th style={{width:"100px"}}>实训室名称</th>
                                <th>面积</th>
                                <th >主要设备及工具</th>
                                <th>主要软件</th>
                                <th>功能</th>
                                <th className={style.tablewid}>工位数</th>
                                <th className={style.tablewid}>价格(万元)</th>
                            </tr>
                            {
                                TrainsList?.map((item,index) => {
                                    return <tr key={index}>
                                        <td>{item.houseName}</td>
                                        <td>{item.area}</td>
                                        <td>{item.mainTool}</td>
                                        <td>{item.mainSoft}</td>
                                        <td>{item.function}</td>
                                        <td>{item.workstations}</td>
                                        <td>{item.price}</td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <div className={style.table}>
                    <div className={style.title}>2-2 校外实训条件</div>
                    <table>
                        <tbody>
                        <tr>
                            <th>校外实习基地名称</th>
                            <th>合作企业名称</th>
                            <th>用途</th>
                            <th>合作深度要求</th>
                        </tr>
                        {
                            TrainsOutList?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.houseName}</td>
                                    <td>{item.company}</td>
                                    <td>{item.useWay}</td>
                                    <td>{item.workDemand}</td>
                                </tr>
                            })
                        }

                        </tbody>
                    </table>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>3.教学资源</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div className={`${style.content} ${style.largecontent}`}>{data?.resource}</div>
                        </Col>
                    </Row>
                </div> 
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>4.教学方法</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div className={`${style.content} ${style.largecontent}`}>{data?.teachMethod}</div>
                        </Col>
                    </Row>
                </div> 
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>5.学习评价</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div className={`${style.content} ${style.largecontent}`}>{data?.evaluate}</div>
                        </Col>
                    </Row>
                </div> 
            </div>
            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>6.质量管理</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div className={`${style.content} ${style.largecontent}`}>{data?.qualityManager}</div>
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>
    );
}
