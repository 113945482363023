//@ts-nocheck
import React, { useEffect, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import echarts, { EChartOption } from "echarts";
import useRequest from "../../../../lib/useRequest";
import { getShuangGao } from "../../../../service/chart";
import { useHistory } from 'react-router-dom';
import {getNormalData} from "../../../../service/basic";

var ydata = [
    {
        name: "财务管理决策实训",
        value: 18,
    },
    {
        name: "商品流通业实训",
        value: 16,
    },
    {
        name: "暖心陪伴（津乐园20cm定制蛋糕）",
        value: 15,
    },
    {
        name: "嘉果荟萃（津乐园20cm定制蛋糕）",
        value: 14,
    },
    {
        name: "优雅圆舞曲（津乐园20cm）",
        value: 10,
    },
    {
        name: "巧克力之夏（津乐园20cm定制蛋糕）",
        value: 7.9,
    },
    {
        name: "财税宝4G",
        value: 6.7,
    },
    {
        name: "成本会计",
        value: 6,
    },
    {
        name: "纳税会计与筹划",
        value: 4.5,
    },
    {
        name: "金融担保业实训",
        value: 3,
    },
];
var color = [
    "#8d7fec",
    "#5085f2",
    "#e75fc3",
    "#f87be2",
    "#f2719a",
    "#fca4bb",
    "#f59a8f",
    "#fdb301",
    "#57e7ec",
    "#cf9ef1",
];
var xdata = [
    "财务管理决策实训",
    "商品流通业实训",
    "暖心陪伴（津乐园20cm定制蛋糕）",
    "嘉果荟萃（津乐园20cm定制蛋糕）",
    "优雅圆舞曲（津乐园20cm）",
    "巧克力之夏（津乐园20cm定制蛋糕）",
    "财税宝4G",
    "成本会计",
    "纳税会计与筹划",
    "金融担保业实训",
];

const Options: EChartOption<EChartOption.Series> = {
    color: color,
    legend: {
        orient: "vartical",
        x: "left",
        top: "center",
        left: "45%",
        bottom: "0%",
        data: xdata,
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 12,
        textStyle: {
            color: "#fff",
        },


        formatter: function (name) {
            return "" + name;
        },
    },
    series: [
        {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["60%", "40%"],
            center: ["20%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
                //图形样式
                // normal: {
                //     borderColor: "#08c",
                //     borderWidth: 6,
                // },
            },
            label: {
                normal: {
                    show: false,
                    position: "center",
                    formatter: "{text|{b}}\n{c}",
                    rich: {
                        text: {
                            color: "#fff",
                            fontSize: 14,
                            align: "center",
                            verticalAlign: "middle",
                            padding: 8,
                        },
                        value: {
                            color: "#fff",
                            fontSize: 24,
                            align: "center",
                            verticalAlign: "middle",
                        },
                    },
                },
                emphasis: {
                    show: true,
                    textStyle: {
                        fontSize: 24,
                    },
                },
            },
            data: ydata,
        },
    ],
};

// setTimeout(function () {
//     myChart.on("mouseover", function (params) {
//         if (params.name == ydata[0].name) {
//             myChart.dispatchAction({
//                 type: "highlight",
//                 seriesIndex: 0,
//                 dataIndex: 0,
//             });
//         } else {
//             myChart.dispatchAction({
//                 type: "downplay",
//                 seriesIndex: 0,
//                 dataIndex: 0,
//             });
//         }
//     });

//     myChart.on("mouseout", function (params) {
//         myChart.dispatchAction({
//             type: "highlight",
//             seriesIndex: 0,
//             dataIndex: 0,
//         });
//     });
//     myChart.dispatchAction({
//         type: "highlight",
//         seriesIndex: 0,
//         dataIndex: 0,
//     });
// }, 1000);

// const Options: EChartOption<EChartOption.Series> = {
//     title: {
//         text: "专业建设情况",
//         left: "center",
//     },
//     series: [
//         {
//             data: [],
//             name: "学校性质码",
//             type: "pie",
//         },
//     ],
//     tooltip: { formatter: "{a} <br/>{b}: {c} ({d}%)" },
// };

interface IProps {
    customOption?: (
        opt: EChartOption<EChartOption.Series>
    ) => EChartOption<EChartOption.Series>;
    style?: any;
}

export default function Index({ customOption, style }: IProps) {
    const history = useHistory()
    const [data] = useRequest(() => getNormalData<{sg: string[], subject: string[], '1x': string[], name: string[]}>("2a235851-eb8a-4944-a7c6-de508b6bf867")); // 专业建设情况

    const opt = useMemo(() => {
        if (!data) return {};
        return {
            ...Options,
            series: Options.series?.map((item) => {
                item.data = data?.name.filter(item=>item).map((name, index) => ({
                    name: name.split("大类")[0],
                    value: data?.subject[index],
                }));
                return item;
            }),
            legend: {...Options.legend, data: data.name.filter(item=>item).map(item => {
                const str = item.split("大类")[0]
                return str
            })}
        };
    }, [data]);

    return <ReactEcharts onEvents={{"click": () => history.push("/major/main")}}  style={style} theme={"theme"} option={opt} />
}
