// import {jbqkschool} from "../../../../../service/basic";
import useRequest from "../../../../../lib/useRequest";
import style from "../../../../Training/Main/index.module.scss";
import {Button} from "antd";
import React from "react";
import {useHistory} from "react-router-dom";
import {getNormalData} from "../../../../../service/basic";

export default function SchoolList() {

    const history = useHistory();

    // const [school] = useRequest(() => jbqkschool({year: "2020"}));
    const [school] = useRequest(()=>getNormalData<any>("b99a81e4-1e09-4aba-9a93-318798b9e4fb")); // 按高校查专业

    function gotoPath(id:string, name:string, code:string) {
        history.push(`/major/detail?id=${id}&name=${name}&tab=2`);
    }

    return (
        <div className={style.schoolList}>
            {school?.map((item:any) => (
                <div className={style.schoolRow} key={item.id}>
                    <span className={style.sortNum}>{item.code}</span>
                    <Button title={item.name} type="link" className={style.antButton} onClick={() => gotoPath(item.id,item.name,item.code)}>{item.name}</Button>
                    <span>({item.subject_count})</span>
                </div>
            ))}
        </div>
    );
}
