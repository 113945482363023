import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from "antd";
import "./components/Style";
import "antd/es/style/index.css";
import BaseLayout from "./components/BaseLayout";
import ScrollToTop from "./components/ScrollToTop";
import { flattenRoute } from "./lib/utils";
import Config from "./config";
import PageChats from "./pages/Charts/PageCharts";
import PageChats2 from "./pages/Charts";
import PageLogin from "./pages/Auth/Login";
import { AdminContextProvider } from "./AdminContext";
import Guest from "./pages/Guest";
import DataWall1 from "./pages/DataWall/Index";
import DataWall2 from "./pages/DataWall/Wall2";
import DataWall3 from "./pages/DataWall/Wall3";
import DataWall4 from "./pages/DataWall/Wall4";

function App () {

  const routes = flattenRoute(Config.Menu);

  return (
    <ConfigProvider locale={zhCN}>
      <AdminContextProvider>
        <BrowserRouter>
          <Route path={Config.Menu.map(item => item.path)}>
            <BaseLayout>
              <Switch>
                {routes.map((route) => (
                  <Route key={route.path} {...route} exact />
                ))}
              </Switch>
            </BaseLayout>
          </Route>

          {/*数据大屏*/}
          <Route path="/charts">
            <Route path="/charts" exact component={PageChats} />
            <Route path="/charts/2" exact component={PageChats2} />
          </Route>

          {/*登录*/}
          <Route path="/auth/login" exact component={PageLogin} />

          <Route path="/" exact>
            <Redirect to="/main" />
          </Route>

          <Route path="/guest">
            <Guest />
          </Route>

          <Route path="/wall" >
            <Route path="/wall/1" exact component={DataWall1} />
            <Route path="/wall/2" exact component={DataWall2} />
            <Route path="/wall/3" exact component={DataWall3} />
            <Route path="/wall/4" exact component={DataWall4} />
          </Route>

          <ScrollToTop />

        </BrowserRouter>

      </AdminContextProvider>
    </ConfigProvider>
  );
}

export default App;
