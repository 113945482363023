import React, { useMemo, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { registerMap } from "echarts";
import 'echarts/map/js/province/liaoning.js';
import { Button, Table } from "antd"
import style from '../../../../Charts/Components/Province/style.module.scss';
import Popup from "../../../../Charts/Components/Popup";
import TableStyle from '../../../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss';
import { ILevel } from '../../../../../service/chart';
import mapJson from './map.json';
import useRequestFn from '../../../../../lib/useRequestFn';
import { getNormalData } from '../../../../../service/basic';

interface ProvinceProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: any;
    height?: number;
    year: string[];
    tableType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList?: any[]
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: string; schCount: string }[]; // 国家级院校
    schoolListSpecial?: ILevel[]; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
    points: {
        name: string;
        value: string;
    }[]
    blueData?: any;
    practice?: any;
    city?: any;
}

interface DataArrTypes {
    practice: any,
    city: any,
    [key: string]: {
        values: number[],
    }
}


const geoBlueMap = {
    '黑龙江': [129.9688, 46.368],
    "天津": [117.4219, 38.4189],
    "山西": [112.3352, 35.9413],
    "辽宁": [123.1238, 40.1216],
    "陕西": [109.1162, 33.2004],
    '内蒙古': [109.3467, 40.4899],
    '上海': [120.4648, 30.2891],
    "浙江": [118.5313, 28.8773],
    "湖南": [113.0823, 27.2568],
    "广东": [113.12244, 22.009505],
    "重庆": [108.384366, 29.439702],
    "贵州": [106.6992, 25.7682],
    "广西": [108.479, 22.1152],
    "甘肃": [103.5901, 35.3043],
    "新疆": [86.9236, 42.5883],
    "吉林": [128.8154, 42.2584],
    '北京': [116.4551, 40.2539],
    "河北": [114.4995, 37.1006],
    "河南": [113.4668, 33.6234],
    "山东": [117.1582, 35.8701],
    "安徽": [116.29, 31.0581],
    "江苏": [118.8059, 30.9208],
    "湖北": [114.3896, 28.6628],
    "福建": [118.4543, 24.9222],
    "江西": [115.0046, 27.6633],
    "四川": [103.9400, 29.7617],
    "云南": [101.9199, 24.4663],
    "宁夏": [105.3586, 37.1775],
    "青海": [100.4038, 35.8207],
    "西藏": [90.11, 28.97],
}

const geoCoordMap = {
    '台湾': [121.5135, 25.0308],
    '黑龙江': [127.9688, 45.368],
    '内蒙古': [110.3467, 41.4899],
    "吉林": [125.8154, 44.2584],
    '北京': [116.4551, 40.2539],
    "辽宁": [123.1238, 42.1216],
    "河北": [114.4995, 38.1006],
    "天津": [117.4219, 39.4189],
    "山西": [112.3352, 37.9413],
    "陕西": [109.1162, 34.2004],
    "甘肃": [103.5901, 36.3043],
    "宁夏": [106.3586, 38.1775],
    "青海": [101.4038, 36.8207],
    "新疆": [87.9236, 43.5883],
    "西藏": [91.11, 29.97],
    "四川": [103.9526, 30.7617],
    "重庆": [108.384366, 30.439702],
    "山东": [117.1582, 36.8701],
    "河南": [113.4668, 34.6234],
    "江苏": [118.8062, 31.9208],
    "安徽": [117.29, 32.0581],
    "湖北": [114.3896, 30.6628],
    "浙江": [119.5313, 29.8773],
    "福建": [119.4543, 25.9222],
    "江西": [116.0046, 28.6633],
    "湖南": [113.0823, 28.2568],
    "贵州": [106.6992, 26.7682],
    "云南": [102.9199, 25.4663],
    "广东": [113.12244, 23.009505],
    "广西": [108.479, 23.1152],
    "海南": [110.3893, 19.8516],
    '上海': [121.4648, 31.2891],
};


export default function Province ({
    practice, city,
    name, data, className, year,
    changeCityName, schoolList,
    geoColor = '46, 199, 201',
    tableType = 1, width = 1920,
    height = 1000, special = false,
    pointsBlue, pointsRed,
    schoolListSpecial,
    showTimeLine,
    onClean, points,
    blueData
}: ProvinceProps) {
    const echartsRef = useRef < any > (null);
    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');

    // 红色圆点
    var convertData = function (data: any) {
        // let res :any= [];
        // data?.map((item: any) => {
        //   res.push({
        //     name: item.name,
        //     value: item.value
        //   })
        // })
        if (data)
        {
            var res = [];
            for (var i = 0; i < data.length; i++)
            {
                // @ts-ignore
                var geoCoord = geoCoordMap[data[i].name];
                if (geoCoord)
                {
                    res.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].value)
                    });
                }
            }
        }

        return res;
    };

    //蓝色圆点
    let converBlueData = function (data: any) {
        let res: any = [];
        // data?.map((item: any) => {
        //   res.push({
        //     name: item.name,
        //     value: item.value
        //   })
        // })
        if (data)
        {
            for (let i = 0; i < data.length; i++)
            {
                // @ts-ignore
                let geoCoord = geoBlueMap[data[i].name];
                if (geoCoord)
                {
                    res.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].value)
                    });
                }
            }
        }
        return res;
    }


    const option: any = useMemo(() => {

        registerMap("china", mapJson)

        return {
            // title: {
            //     text: '辽宁省高职院校2020毕业生就业去向全国分布图',
            //     // left: 'center'
            // },
            visualMap: {
                min: 0,
                max: 50000,
                splitNumber: 500,
                color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                textStyle: {
                    color: '#fff'
                },
                show: false,
                seriesIndex: [0],
                pieces: [{
                    lt: 100,
                    color: `rgba(${geoColor}, 0.2)`
                },
                {
                    lt: 500,
                    gte: 100,
                    color: `rgba(${geoColor}, 0.5)`
                },
                {
                    lt: 3000,
                    gte: 300,
                    color: `rgba(${geoColor}, 0.8)`
                },
                {
                    lt: 1000000000000000000000000000000000,
                    gte: 3000,
                    color: `#08c`
                }]
            },
            geo: {
                map: "china",
                label: {
                    normal: {
                        show: false,
                    },
                    emphasis: {
                        show: false,
                    },
                },
                left: 0,
                right: 0,
                // top: 0,
                // bottom: 0,
                itemStyle: {
                    normal: {
                        borderColor: 'rgba(147, 235, 248, 1)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                            }],
                            globalCoord: false // 缺省为 false
                        },
                        shadowColor: 'rgba(128, 217, 248, 1)',
                        shadowOffsetX: -2,
                        shadowOffsetY: 2,
                        shadowBlur: 10
                    },
                    emphasis: {
                        areaColor: `rgba(${geoColor}, 1)`,
                        // areaColor: 'normal' ,
                        borderWidth: 0
                    }
                },
            },
            series: [
                {
                    type: 'map',
                    data: points,
                    geoIndex: 0,
                    map: "china"
                },
                {
                    symbolSize: 5,
                    label: {
                        normal: {
                            formatter: "{b}",
                            position: "right",
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            }
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#fff",
                        },
                    },
                    name: "light",
                    type: "scatter",
                    coordinateSystem: "geo",
                    data: convertData(points),
                },
                {
                    name: "point",
                    type: "scatter",
                    coordinateSystem: "geo",
                    symbol: "pin",
                    symbolSize: (val: any) => {
                        if (parseInt(val[2]) > 10000)
                        {
                            return 90
                        } else
                        {
                            return 35
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 10,
                            },
                            formatter (value: any) {
                                return value.data.value[2];
                            },
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#ff0000", //标志颜色
                        },
                    },
                    data: convertData(points),
                    zlevel: 100,
                },
                {
                    name: "point",
                    type: "scatter",
                    coordinateSystem: "geo",
                    symbol: "pin",
                    symbolSize: (val: any) => {
                        if (parseInt(val[2]) > 500)
                        {
                            return 60
                        } else
                        {
                            return 35
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 10,
                            },
                            formatter (value: any) {
                                return value.data.value[2];
                            },
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#3c4ae1", //标志颜色
                        },
                    },
                    data: converBlueData(blueData),
                    zlevel: 100,
                },
            ],
        };
    }, [points]);
    const onEventsObj = {
        'click': (params: any) => {
            const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区学生实习数据统计` : `${params.name}课程情况统计`);
            changeCityName?.(params.name, '')
            setPointMark('area');
            setTableTitle(title);
            setShow(true);
            return
        },
    };

    //  中间地图数据
    const [middleData] = useState([
        {
            name: "辽宁城市建设职业技术学院",
            count: 7654,
            people: 42,
            majors: 3,
        }, {
            name: "辽宁广告职业学院",
            count: 5215,
            people: 67,
            majors: 2,
        }, {
            name: "辽宁轨道交通职业学院",
            count: 7432,
            people: 55,
            majors: 18,
        }, {
            name: "辽宁金融职业学院",
            count: 3216,
            people: 32,
            majors: 8,
        }, {
            name: "辽宁经济职业技术学院",
            count: 4721,
            people: 41,
            majors: 6,
        }, {
            name: "辽宁民族师范高等专科学校",
            count: 1472,
            people: 26,
            majors: 2,
        }, {
            name: "辽宁生态工程职业学院",
            count: 2941,
            people: 18,
            majors: 3,
        }, {
            name: "辽宁省交通高等专科学校",
            count: 5521,
            people: 69,
            majors: 14,
        }, {
            name: "辽宁特殊教育师范高等专科学校",
            count: 1531,
            people: 27,
            majors: 2,
        }, {
            name: "辽宁现代服务职业技术学院",
            count: 3291,
            people: 39,
            majors: 2,
        }, {
            name: "辽宁医药职业学院",
            count: 4398,
            people: 62,
            majors: 3,
        }, {
            name: "辽宁政法职业学院",
            count: 3394,
            people: 19,
            majors: 2,
        }, {
            name: "辽宁装备制造职业技术学院",
            count: 8318,
            people: 103,
            majors: 19,
        }, {
            name: "沈阳北软信息职业技术学院",
            count: 1529,
            people: 31,
            majors: 2,
        }, {
            name: "沈阳职业技术学院",
            count: 15217,
            people: 152,
            majors: 22,
        },
    ])

    const [type, setType] = useState(0)

    const [thData] = useState([
        '学校名称', '实习人数', '专业数', '企业数'
    ])


    const [thData2] = useState([
        '企业名称', '企业个数', '人数'
    ])

    const [middleData2] = useState([
        {
            name: '中船澄西船舶修造有限公司',
            count: 5,
            value: 331
        }, {
            name: '招商局重工（江苏）有限公司',
            count: 6,
            value: 421
        }, {
            name: '英特尔半导体（大连）有限公司',
            count: 3,
            value: 227
        }, {
            name: '天津三安光电有限公司',
            count: 2,
            value: 218
        }, {
            name: '南京东方颐年养老服务有限公司（银诚康养）',
            count: 3,
            value: 104
        }, {
            name: '大连辽渔建设集团有限公司',
            count: 8,
            value: 588
        }, {
            name: '大连市中级人民法院',
            count: 2,
            value: 43
        }, {
            name: '中国人寿保险股份有限公司大连分公司西岗支公司',
            count: 2,
            value: 31
        },
    ])


    const [thData3] = useState([
        '专业名称', '专业数', '人数'
    ])

    const [middleData3] = useState([
        {
            name: '供用电技术',
            count: 1,
            value: 55
        }, {
            name: '电力系统自动化技术',
            count: 2,
            value: 121
        }, {
            name: '分布式发电与微电网技术',
            count: 1,
            value: 42
        }, {
            name: '电厂热能动力装置',
            count: 3,
            value: 228
        }, {
            name: '风力发电工程技术',
            count: 1,
            value: 110
        }, {
            name: '黑色冶金技术',
            count: 1,
            value: 78
        }, {
            name: '轧钢工程技术',
            count: 2,
            value: 91
        }, {
            name: '材料工程技术',
            count: 1,
            value: 59
        }, {
            name: '高分子材料工程技术',
            count: 3,
            value: 162
        }, {
            name: '光伏材料制备技术',
            count: 1,
            value: 183
        }, {
            name: '建筑材料检测技术',
            count: 1,
            value: 131
        },
    ])
    //点击专业
    const [onMaj, major] = useRequestFn(getNormalData)
    //专业列表
    const [majorList, setMajorList] = useState < any > ([])
    let onMajor = async (item: any) => {
        let res = await onMaj < any > ('638455ca-3dc4-4158-8abf-010d4e4726f7', { province: city, schoolName: item.name })
        if (res)
        {
            let arr: any = []
            res.map((item: any) => {
                arr.push(
                    {
                        name: item.name,
                        count: item.companyNum,
                        value: item.stuNum
                    },
                )
            })
            setMajorList(arr)
        }
    }

    //点击企业
    //企业列表
    const [comList, setComList] = useState < any > ([])
    const [onCom, company] = useRequestFn(getNormalData)
    let onCompany = async (item: any) => {
        let res = await onCom < any > ('cce2f41b-c991-40e2-b14f-0788777386eb', { province: city, schoolName: item.name })
        if (res)
        {
            let arr: any = []
            res.map((item: any) => {
                arr.push(
                    {
                        name: item.company_name,
                        count: item.subjectNum,
                        value: item.stuNum
                    },
                )
            })
            setComList(arr)
        }

    }

    const columns: any = [
        {
            title: '学校名称',
            dataIndex: 'name'
        },
        {
            title: '实习人数',
            dataIndex: 'count',
            sorter: {
                compare: (a: any, b: any) => a.count - b.count
            }
        },
        {
            title: '专业数',
            dataIndex: 'people',
            sorter: {
                compare: (a: any, b: any) => a.people - b.people
            },
            render: (text: any, record: any, index: any) => {
                return <div className={style.active} onClick={() => {
                    onMajor(record)
                    setType(2)
                }}>{record.people}</div>
            },

        },
        {
            title: '企业数',
            dataIndex: 'majors',
            sorter: {
                compare: (a: any, b: any) => a.majors - b.majors
            },
            render: (text: any, record: any, index: any) => {
                return <div onClick={() => {
                    onCompany(record)
                    setType(1)
                }} className={style.active}>{text}</div>
            }
        }
    ]
    const columns1 = [
        {
            title: '企业名称',
            dataIndex: 'name'
        },
        {
            title: '企业个数',
            dataIndex: 'count',
            sorter: {
                compare: (a: any, b: any) => a.count - b.count
            }
        },
        {
            title: '人数',
            dataIndex: 'value',
            sorter: {
                compare: (a: any, b: any) => a.value - b.value
            }
        }
    ]
    const columns2 = [
        {
            title: '专业名称',
            dataIndex: 'name'
        },
        {
            title: '专业数',
            dataIndex: 'count',
            sorter: {
                compare: (a: any, b: any) => a.count - b.count
            }
        },
        {
            title: '人数',
            dataIndex: 'value',
            sorter: {
                compare: (a: any, b: any) => a.value - b.value
            }
        }
    ]

    const dataSource = practice?.map((item: any) => {
        return {
            name: item.name,
            count: item.count,
            people: item.people,
            majors: item.majors,
        }
    })
    const dataSource1 = comList?.map((item: any) => {
        return {
            name: item.name,
            count: item.count,
            value: item.value,
        }
    })
    const dataSource2 = majorList?.map((item: any) => {
        return {
            name: item.name,
            count: item.count,
            value: item.value,
        }
    })

    return (
        <div className={`${style.root} ${className}`}>
            <ReactEcharts
                theme={"theme"}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                ref={echartsRef}
                style={{ width, height, margin: '0 auto' }}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                setType(0)
                setShow(false);
            }} show={show} width='80vw'>

                <div className={TableStyle.tableWrapper}>
                    <div className={TableStyle.tableTitle}>
                        {tableTitle}
                        {(tableType === 1 && pointMark === 'area') && schoolList && schoolList.length > 0 ? `${schoolList.length}所` : ((tableType === 1 && pointMark === 'red') && schoolListSpecial && schoolListSpecial.length > 0 ? `${schoolListSpecial.length}所` : '')}</div>
                    {(tableType === 1 && pointMark === 'area')
                        &&
                        <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校名称</th>
                                    <th>学校属性</th>
                                    <th>在校生数</th>
                                    <th>占地面积</th>
                                    <th>建筑面积</th>
                                    <th>年度经费投入</th>
                                </tr>
                                {schoolList?.map((item, index) => <tr key={index}>
                                    <td>{item.schoolName}</td>
                                    <td>{item.school_sx}</td>
                                    <td>{item.stuCount}</td>
                                    <td>{item.all_area}</td>
                                    <td>{item.jzmj_zj}</td>
                                    <td>{item.inCase}</td>
                                </tr>)}
                            </tbody>
                        </table>}
                    {
                        type === 1 && (
                            <Button className={style.btn} onClick={() => {
                                setType(0)
                            }}>返回</Button>
                        )
                    }
                    {
                        type === 2 && (
                            <Button className={style.btn} onClick={() => {
                                setType(0)
                            }}>返回</Button>
                        )
                    }
                    {/* 2 点击全国地图某一区域列表*/}
                    {(tableType === 2 && pointMark === 'area') &&
                        <div style={{ height: "calc(600px - 117px)", overflow: "auto" }}>
                            {
                                type === 0 && <Table columns={columns} dataSource={dataSource} pagination={false} />
                            }
                            {
                                type === 1 && <Table columns={columns1} dataSource={dataSource1} pagination={false} />
                            }
                            {
                                type === 2 && <Table columns={columns2} dataSource={dataSource2} pagination={false} />
                            }

                            {/* <table className={TableStyle.table}>
                                <tbody>
                                    <tr className={style.tableListTitle}>
                                        {
                                            type === 0 && thData.map((item: any, index: number) => {
                                                return (
                                                    <th key={index}>{item}</th>
                                                )
                                            })
                                        }
                                        {
                                            type === 1 && thData2.map((item: any, index: number) => {
                                                return (
                                                    <th key={index}>{item}</th>
                                                )
                                            })
                                        }
                                        {
                                            type === 2 && thData3.map((item: any, index: number) => {
                                                return (
                                                    <th key={index}>{item}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                    {
                                        type === 0 && practice?.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.count}</td>
                                                    <td className={style.active} onClick={() => {
                                                        onMajor(item)
                                                        setType(2)
                                                    }}>{item.people}</td>
                                                    <td onClick={() => {
                                                        onCompany(item)
                                                        setType(1)
                                                    }} className={style.active}>{item.majors}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        type === 1 && comList.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.count}</td>
                                                    <td>{item.value}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        type === 2 && majorList.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.count}</td>
                                                    <td>{item.value}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> */}
                        </div>}

                    {/*红点 重点专业列表*/}
                    {(tableType === 2 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody className={style.tableList}>
                            <tr className={style.tableListTitle}>
                                <th>学校</th>
                                <th>专业</th>
                                <th>级别</th>
                            </tr>
                            {schoolList?.map(item => <tr>
                                <td>{item.schoolName}</td>
                                <td>{item.subjectName}</td>
                                <td>{item.is_sg}</td>
                            </tr>)}
                        </tbody>
                    </table>}
                    {/*蓝点 特殊专业列表*/}

                    {(tableType === 2 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>证书数量</th>
                            </tr>
                            {schoolList?.map(item => <tr>
                                <td>{item.schoolName}</td>
                                <td>{item.subjectName}</td>
                                <td>{item.is_1x}</td>
                            </tr>)}

                        </tbody>
                    </table>}


                    {/* 某某市国家级高级院校列表*/}
                    {(tableType === 1 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                                <th>层次</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                                <td>省级</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 蓝 某某市省级示范性高级院校*/}
                    {(tableType === 1 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>类别</th>
                            </tr>
                            {schoolListSpecial?.map(item => <tr>
                                <td>{item.name}</td>
                                <td>{item.xzCode}</td>
                            </tr>)}
                        </tbody>
                    </table>}

                    {/* 3 某某市课程情况统计*/}
                    {(tableType === 3 && pointMark === 'area') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>课程数量</th>
                                <th>精品课</th>
                                <th>在线课</th>
                                <th>资源共享课</th>
                                <th>实训课</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>3346</td>
                                <td>19</td>
                                <td>740</td>
                                <td>560</td>
                                <td>1002</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>5486</td>
                                <td>16</td>
                                <td>650</td>
                                <td>430</td>
                                <td>1209</td>
                            </tr>
                            <tr>
                                <td>辽宁轨道交通技术学院</td>
                                <td>4892</td>
                                <td>19</td>
                                <td>734</td>
                                <td>467</td>
                                <td>980</td>
                            </tr>
                            <tr>
                                <td>辽宁铁道职业技术学院</td>
                                <td>5643</td>
                                <td>15</td>
                                <td>790</td>
                                <td>667</td>
                                <td>1032</td>
                            </tr>
                            <tr>
                                <td>沈阳北软件职业信息技术学院</td>
                                <td>7654</td>
                                <td>19</td>
                                <td>543</td>
                                <td>675</td>
                                <td>1902</td>
                            </tr>

                        </tbody>
                    </table>}
                    {/*红点 某某市精品课列表*/}
                    {(tableType === 3 && pointMark === 'red') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>级别</th>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>国家级</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>省级</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>省级</td>
                            </tr>
                        </tbody>
                    </table>}
                    {/*蓝点 某某市在线课程列表*/}
                    {(tableType === 3 && pointMark === 'blue') && <table className={TableStyle.table}>
                        <tbody>
                            <tr>
                                <th>学校</th>
                                <th>专业</th>
                                <th>课程</th>
                                <th>签到人数</th>

                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>物联网应用技术</td>
                                <td>电子商务概论</td>
                                <td>2356</td>
                            </tr>
                            <tr>
                                <td>辽宁省交通高等专科学校</td>
                                <td>高速铁路客运乘务</td>
                                <td>中级财务会计</td>
                                <td>5674</td>
                            </tr>
                            <tr>
                                <td>沈阳职业技术学院</td>
                                <td>环境艺术设计</td>
                                <td>行政管理学</td>
                                <td>7645</td>
                            </tr>
                        </tbody>
                    </table>}
                </div>

            </Popup>
        </div>
    )
}

Province.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
