import React, { useState, useEffect } from "react";
import style from "./index.module.scss"
import MyTheme from '../../../assets/myTheme.json';
import echarts from "echarts";
import useBreadcrumb from "../../../lib/useBreadcrumb";
// D:\project\frontend\src\pages\Charts\Components\Statistics\index.tsx
import { StatisticsEmply } from "../../../pages/Charts/Components/Statistics";
import CourseListTable from "./components/CourseListTable";
import Table1 from "./components/Table1";
import LiaoNingMap from './components/LiaoNingMap';
import ChinaMap from './components/ChinaMap';
import Chart7 from "./components/Chart7";
import Chart8 from "./components/Chart8";
import Chart6 from "./components/Chart6";
import Chart5 from "./components/Chart5";
import Chart9 from "./components/Chart9";
import Chart10 from "./components/Chart10";
import useRequest from "../../../lib/useRequest";
import { getNormalData } from "../../../service/basic";

import useDevice from "../../../lib/useDevice";
import Chart11 from "./components/Chart11";

export default function PageEmploymentMain () {
    const { isMobile } = useDevice()
    echarts.registerTheme('theme', MyTheme);
    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "产业人才对接数据平台" }]);
    const [num1] = useRequest(() => getNormalData < any > ('c1dd2f76-b14f-11ec-ba43-005056958024'))
    const [num2] = useRequest(() => getNormalData < any > ('d7949690-b14f-11ec-ba43-005056958024'))

    const [n1, setN1] = useState(0)
    const [n2, setN2] = useState(0)
    useEffect(() => {
        setN1(num1)
        setN2(num2)
    }, [num1, num2])
    return (
        <div className={style.root}>
            <div className={style.content}>



                <StatisticsEmply />

                <div style={{ display: isMobile ? 'block' : "flex", margin: isMobile ? '0' : "0 -21px" }}>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10 }}>
                        <div className={style.title}>辽宁省高职院校毕业生全国就业分布图</div>
                        <ChinaMap width={isMobile ? 'auto' : 600} height={isMobile ? 300 : 460} />
                    </div>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10 }}>
                        <div className={style.title}>辽宁省高职院校毕业生省内就业分布图</div>
                        <LiaoNingMap width={isMobile ? 'auto' : 600} height={isMobile ? 260 : 460} />
                    </div>
                </div>

                <div style={{ display: isMobile ? 'block' : "flex", margin: isMobile ? '0' : "0 -21px" }}>
                    <Chart11 />
                </div>


                {/*<div style={{ display: isMobile ? 'block' : "flex", margin: isMobile ? '0' : "0 -21px" }}>*/}
                {/*    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>*/}
                {/*        <div className={style.title}>服务结构调整三篇大文章</div>*/}
                {/*        <Chart7 />*/}
                {/*    </div>*/}
                {/*    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>*/}
                {/*        <div className={style.title}>维护国家五大安全</div>*/}
                {/*        <div className={style.bar}>*/}
                {/*            <Chart8 />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div style={{ display: isMobile ? 'block' : "flex", margin: isMobile ? '0' : "0 -21px" }}>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>
                        <div className={style.title}>专业对接产业学生规模分布-国内</div>
                        <Chart5 />
                    </div>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>
                        <div className={style.title}>专业对接产业学生规模分布-国内</div>
                        <div className={style.bar}>
                            <Chart6 />
                        </div>
                    </div>
                </div>
                <div style={{ display: isMobile ? 'block' : "flex", margin: isMobile ? '0' : "0 -21px" }}>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>
                        <div className={style.title}>专业对接产业学生规模分布-省内</div>
                        <Chart9 />
                    </div>
                    <div style={{ width: isMobile ? '100%' : '50%', background: "#fff", padding: 20, margin: isMobile ? 0 : 10, height: 372 }}>
                        <div className={style.title}>专业对接产业学生规模分布-省内</div>
                        <div className={style.bar}>
                            <Chart10 />
                        </div>
                    </div>
                </div>

                <div className={style.chart3}>
                    <Table1 />
                </div>

                <div className={style.list}>
                    <CourseListTable />
                </div>
            </div>
        </div>
    );
}
