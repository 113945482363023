import style from "./index.module.scss";
import { FaBeer, FaLaptopCode, FaUserGraduate, FaBook, FaServicestack, FaBookOpen } from 'react-icons/fa';
import { ImBooks } from 'react-icons/im';
import { GiHouse } from 'react-icons/gi';
import img1 from './../../img/01.png'
import img2 from './../../img/02.png'
import img3 from './../../img/03.png'
import img4 from './../../img/04.png'
import img5 from './../../img/05.png'
import img6 from './../../img/06.png'
import img7 from './../../img/07.png'

export default function LoginLinks () {

    return (
        <div className={style.root}>
            <div className={style.top}>
                <div className={style.item}>
                    {/* <GiHouse />基本情况 */}
                    <img className={style.iconItem} src={img1} alt="" />
                    院校状态
                </div>

                <div className={style.item}>
                    {/* <ImBooks />专业信息 */}
                    <img className={style.iconItem} src={img2} alt="" />
                    专业状态
                </div>

                <div className={style.item}>
                    {/* <FaBook /> */}
                    <img className={style.iconItem} src={img3} alt="" />
                    人才培养方案
                </div>
            </div>

            <div className={style.item}>
                {/* <FaLaptopCode /> */}
                <img className={style.iconItem} src={img4} alt="" />
                课程管理
            </div>

            <div className={style.item}>
                {/* <FaUserGraduate /> */}
                <img className={style.iconItem} src={img5} alt="" />
                实习实训
            </div>

            <div className={style.item}>
                {/* <FaServicestack /> */}
                <img className={style.iconItem} src={img6} alt="" />
                产教结合
            </div>
            <div className={style.item}>
                {/* <FaServicestack /> */}
                <img className={style.iconItem} src={img7} alt="" />
                社会服务
            </div>
        </div>
    );
}
