import React, { useEffect, useMemo, useState } from "react";
import style from "../index.module.scss";
import Header from "../components/Header";
import Block from "../components/Block";
import Jiuyye from "./components/Jiuye";
import Shixi from "./components/Shixi";
import Map from './components/Map';
import Xinzi from "./components/Xinzi";
import Hangye from "./components/Hangye";
import Liuxiang from "./components/Liuxiang";
import Jihua from "./components/Jihua";
import Footer from "../components/Footer";
import useRequest from "../../../lib/useRequest";
import { getNormalData } from "../../../service/basic";
import useRequestFn from "../../../lib/useRequestFn";
import useDevice from "../../../lib/useDevice";


export default function () {
    const [value, setValue] = useState({
        name: '',
        value: ''
    });
    const [type2, setType2] = useState(true);
    const [flag, setFlag] = useState(false);
    const { isMobile } = useDevice()


    const [list, setList] = useState([
        {
            name: '装备制造',
            value: 4471,
            color: '#8f3ffd',
            data: [
                {
                    name: "0-1500元",
                    value: 11,
                },
                {
                    name: "1501-3000元",
                    value: 12,
                },
                {
                    name: "3001-5000元",
                    value: 16.98,
                },
                {
                    name: "5001-8000元",
                    value: 17.66
                },
                { name: '8001-10000元', value: 18.88 },
                { name: '> 10000元', value: 10.29 },
            ]
        },
        {
            name: '财经商贸',
            value: 5321,
            color: '#6e3ffd',
            data: [
                {
                    name: "0-1500元",
                    value: 21,
                },
                {
                    name: "1501-3000元",
                    value: 27,
                },
                {
                    name: "3001-5000元",
                    value: 33,
                },
                {
                    name: "5001-8000元",
                    value: 35
                },
                { name: '8001-10000元', value: 16.88 },
                { name: '> 10000元', value: 17.94 },
            ]
        },
        {
            name: '电子信息',
            value: 5721,
            color: '#5757f2',
            data: [
                {
                    name: "0-1500元",
                    value: 56,
                },
                {
                    name: "1501-3000元",
                    value: 13,
                },
                {
                    name: "3001-5000元",
                    value: 0.96,
                },
                {
                    name: "5001-8000元",
                    value: 33.2
                },
                { name: '8001-10000元', value: 0.88 },
                { name: '> 10000元', value: 1.94 },
            ]
        },
        {
            name: '教育与体育',
            value: 4531,
            color: '#4467f4',
            data: [
                {
                    name: "0-1500元",
                    value: 12.6,
                },
                {
                    name: "1501-3000元",
                    value: 13.77,
                },
                {
                    name: "3001-5000元",
                    value: 15,
                },
                {
                    name: "5001-8000元",
                    value: 19.6
                },
                { name: '8001-10000元', value: 17.13 },
                { name: '> 10000元', value: 12.94 },
            ]
        },
        {
            name: '交通运输',
            value: 4912,
            color: '#488bf0',
            data: [
                {
                    name: "0-1500元",
                    value: 86,
                },
                {
                    name: "1501-3000元",
                    value: 13,
                },
                {
                    name: "3001-5000元",
                    value: 0.2,
                },
                {
                    name: "5001-8000元",
                    value: 0.3
                },
                { name: '8001-10000元', value: 1.13 },
                { name: '> 10000元', value: 1.4 },
            ]
        }, {
            name: '土木建筑',
            value: 3943,
            color: '#448ff4',
            data: [
                {
                    name: "0-1500元",
                    value: 12.666,
                },
                {
                    name: "1501-3000元",
                    value: 16.77,
                },
                {
                    name: "3001-5000元",
                    value: 11.2,
                },
                {
                    name: "5001-8000元",
                    value: 26.33
                },
                { name: '8001-10000元', value: 31.13 },
                { name: '> 10000元', value: 21.4 },
            ]
        }, {
            name: '旅游',
            value: 3012,
            color: '#38baa8',
            data: [
                {
                    name: "0-1500元",
                    value: 16.26,
                },
                {
                    name: "1501-3000元",
                    value: 14,
                },
                {
                    name: "3001-5000元",
                    value: 23,
                },
                {
                    name: "5001-8000元",
                    value: 50
                },
                { name: '8001-10000元', value: 30.13 },
                { name: '> 10000元', value: 29.4 },
            ]
        }, {
            name: '农林牧渔',
            value: 3398,
            color: '#38baa8',
            data: [
                {
                    name: "0-1500元",
                    value: 66.26,
                },
                {
                    name: "1501-3000元",
                    value: 12.11,
                },
                {
                    name: "3001-5000元",
                    value: 11,
                },
                {
                    name: "5001-8000元",
                    value: 0.88
                },
                { name: '8001-10000元', value: 2.13 },
                { name: '> 10000元', value: 3.4 },
            ]
        }, {
            name: '文化艺术',
            value: 2981,
            color: '#38ba76',
            data: [
                {
                    name: "0-1500元",
                    value: 33.26,
                },
                {
                    name: "1501-3000元",
                    value: 22,
                },
                {
                    name: "3001-5000元",
                    value: 11,
                },
                {
                    name: "5001-8000元",
                    value: 13
                },
                { name: '8001-10000元', value: 16.13 },
                { name: '> 10000元', value: 18.4 },
            ]
        }, {
            name: '文化艺术',
            value: 2981,
            color: '#c7b30d',
            data: [
                {
                    name: "0-1500元",
                    value: 11.256,
                },
                {
                    name: "1501-3000元",
                    value: 21.66,
                },
                {
                    name: "3001-5000元",
                    value: 38,
                },
                {
                    name: "5001-8000元",
                    value: 36.887
                },
                { name: '8001-10000元', value: 33.13 },
                { name: '> 10000元', value: 15.74 },
            ]
        }, {
            name: '医药卫生',
            value: 3598,
            color: '#e59d27',
            data: [
                {
                    name: "0-1500元",
                    value: 78.256,
                },
                {
                    name: "1501-3000元",
                    value: 0.66,
                },
                {
                    name: "3001-5000元",
                    value: 0.99,
                },
                {
                    name: "5001-8000元",
                    value: 0.1
                },
                { name: '8001-10000元', value: 1.13 },
                { name: '> 10000元', value: 1.74 },
            ]
        }, {
            name: '资源环境与安全',
            value: 4498,
            color: '#e55b26',
            data: [
                {
                    name: "0-1500元",
                    value: 22.256,
                },
                {
                    name: "1501-3000元",
                    value: 11.66,
                },
                {
                    name: "3001-5000元",
                    value: 30.99,
                },
                {
                    name: "5001-8000元",
                    value: 3.13
                },
                { name: '8001-10000元', value: 8.13 },
                { name: '> 10000元', value: 6.74 },
            ]
        }, {
            name: '生物与化工',
            value: 4743,
            color: '#ee167b',
            data: [
                {
                    name: "0-1500元",
                    value: 13.256,
                },
                {
                    name: "1501-3000元",
                    value: 77.66,
                },
                {
                    name: "3001-5000元",
                    value: 3.99,
                },
                {
                    name: "5001-8000元",
                    value: 1.13
                },
                { name: '8001-10000元', value: 0.13 },
                { name: '> 10000元', value: 0.74 },
            ]
        }, {
            name: '食品药品与粮食',
            value: 4198,
            color: 'rgba(238,22,209,0.42)',
            data: [
                {
                    name: "0-1500元",
                    value: 10.256,
                },
                {
                    name: "1501-3000元",
                    value: 66.66,
                },
                {
                    name: "3001-5000元",
                    value: 5.99,
                },
                {
                    name: "5001-8000元",
                    value: 7.13
                },
                { name: '8001-10000元', value: 22.13 },
                { name: '> 10000元', value: 5.74 },
            ]
        }, {
            name: '公共管理与服务',
            value: 4876,
            color: '#eed116',
            data: [
                {
                    name: "0-1500元",
                    value: 16.6,
                },
                {
                    name: "1501-3000元",
                    value: 26.67,
                },
                {
                    name: "3001-5000元",
                    value: 33.00,
                },
                {
                    name: "5001-8000元",
                    value: 5.13
                },
                { name: '8001-10000元', value: 10.15 },
                { name: '> 10000元', value: 15.74 },
            ]
        }, {
            name: '能源动力与材料',
            value: 5032,
            color: '#16eeb1',
            data: [
                {
                    name: "0-1500元",
                    value: 33.66,
                },
                {
                    name: "1501-3000元",
                    value: 11.65,
                },
                {
                    name: "3001-5000元",
                    value: 22.81,
                },
                {
                    name: "5001-8000元",
                    value: 15.156
                },
                { name: '8001-10000元', value: 12.15 },
                { name: '> 10000元', value: 17.74 },
            ]
        }, {
            name: '公安与司法',
            value: 4198,
            color: 'rgba(22,195,238,0.58)',
            data: [
                {
                    name: "0-1500元",
                    value: 40.6,
                },
                {
                    name: "1501-3000元",
                    value: 11.67,
                },
                {
                    name: "3001-5000元",
                    value: 11.00,
                },
                {
                    name: "5001-8000元",
                    value: 3.53
                },
                { name: '8001-10000元', value: 2.15 },
                { name: '> 10000元', value: 5.74 },
            ]
        }, {
            name: '轻工纺织',
            value: 4316,
            color: '#ee3616',
            data: [
                {
                    name: "0-1500元",
                    value: 17.56,
                },
                {
                    name: "1501-3000元",
                    value: 12.6,
                },
                {
                    name: "3001-5000元",
                    value: 11.30,
                },
                {
                    name: "5001-8000元",
                    value: 25.13
                },
                { name: '8001-10000元', value: 17.111 },
                { name: '> 10000元', value: 33.74 },
            ]
        }, {
            name: '新闻传播',
            value: 5018,
            color: '#eeb816',
            data: [
                {
                    name: "0-1500元",
                    value: 22.56,
                },
                {
                    name: "1501-3000元",
                    value: 33.6,
                },
                {
                    name: "3001-5000元",
                    value: 10.30,
                },
                {
                    name: "5001-8000元",
                    value: 15.13
                },
                { name: '8001-10000元', value: 15 },
                { name: '> 10000元', value: 13 },
            ]
        }, {
            name: '水利',
            value: 3987,
            color: '#45ee16',
            data: [
                {
                    name: "0-1500元",
                    value: 32.56,
                },
                {
                    name: "1501-3000元",
                    value: 13.6,
                },
                {
                    name: "3001-5000元",
                    value: 17.30,
                },
                {
                    name: "5001-8000元",
                    value: 6.13
                },
                { name: '8001-10000元', value: 4 },
                { name: '> 10000元', value: 6 },
            ]
        },
    ])

    const [pieData, setPieData] = useState([
        {
            name: "0-1500元",
            value: 24.5,
        },
        {
            name: "1501-3000元",
            value: 54.53,
        },
        {
            name: "3001-5000元",
            value: 16.87,
        },
        {
            name: "5001-8000元",
            value: 2.93
        },
        { name: '8001-10000元', value: 0.88 },
        { name: '> 10000元', value: 0.29 },
    ]);

    //省内城市过滤
    const city = [
        '沈阳',
        '大连',
        '营口',
        '锦州',
        '盘锦',
        '本溪',
        '鞍山',
        '朝阳',
        '辽阳',
        '铁岭',
        '葫芦岛',
        '丹东',
        '阜新',
        '抚顺',
    ]
    //获取省内和省外数据
    const [SN] = useRequest(() => getNormalData < any > ('70135651-1095-4735-8f83-bc00d39ec084'))
    const [SN1] = useRequest(() => getNormalData < any > ('15540441-6fa3-4bf8-8837-b08205ceb531'))

    //假数据使用（省内和省外数据）
    // const [lineData, setLineData] = useState < any > (null)
    // const [Provinces, setProvinces] = useState < any > (null)

    useEffect(() => {
        let arr: any = []
        SN?.map((item: any, index: number) => {
            if (city.indexOf(item.label) != -1)
            {
                arr.push(`${index + 1}. ${item.label}`)
            }
        })
        setMiddleWare(SN?.map((item: any) => Number(item.value)));
        setProMiddleWare(arr);
    }, [SN])

    // const [lineData, setLineData] = useState([
    //   35.27, 14.42, 12.33, 6.07, 4.39, 3.44, 3.31, 3.31, 3.26, 3.16, 2.99, 2.24, 2.18, 1.87, 1.77
    // ])

    // const [Provinces, setProvinces] = useState([
    //   '1. 大连市', '2. 沈阳市', '3. 鞍山市', '4. 锦州市', '5. 朝阳市', '6. 营口市',
    //   '7. 丹东市', '8. 盘锦市', '9. 铁岭市', '10. 葫芦岛市', '11. 抚顺市', '12. 阜新市', '13. 辽阳市', '14. 本溪市'
    // ])


    // const [newLineData, setNewLineData] = useState([
    //   17.2, 11.1, 7.3, 7.1, 6.8, 6.4, 5.8, 5.6, 5.1, 4.9, 3.5, 3.2, 2.9, 2.6, 2.4, 2.2, 2.1, 1.9, 1.7, 1.7, 1.7, 1.5, 1.3, 0.9, 0.8, 0.7, 0.4, 0.3,
    //   0.2, 0.1
    // ])

    // const [newProvinces, setNewProvinces] = useState([
    //   '北京 ', '山东 ', '江苏', '广东 ', '天津 ', '内蒙古', '广西 ', '上海', '云南 ', '吉林 ', '四川 ', '宁夏 ', '安徽 ', '山西 ',
    //   '新疆', '江西 ', '河北 ', '河南', '浙江 ', '海南', '湖北 ', '湖南', '甘肃 ', '福建 ', '西藏', '贵州 ', '重庆',
    //   '陕西 ', '青海 ', '黑龙江'
    // ])

    const [middleWare, setMiddleWare]: any = useState([])
    const [proMiddleWare, setProMiddleWare]: any = useState([])


    //判断点击的是省内还是省外
    function test (item: any) {
        if (item === '1')
        {
            setFlag(true)
            setMiddleWare(SN1?.map((it: any) => Number(it.value)));
            setProMiddleWare(SN1?.map((it: any, index: any) => `${index + 1}. ${it.province}`));
        }
    }
    //实习就业率
    const [internship] = useRequest(() => getNormalData < any > ('d48377c6-3a68-4b5c-a3d4-b44abfeb9dce'))
    const [internshipData, setInternship] = useState < any > (internship)

    //专业对口率
    const [major] = useRequest(() => getNormalData < any > ('2509dd38-710b-4968-863b-bdef5e8d6455'))
    const [majorData, setMajor] = useState < any > (major)

    //起薪水平
    const [payLevel] = useRequest(() => getNormalData('55676f8c-c30a-4050-aea9-f00ca89c380c'))
    const [paylevelData, setPaylevel] = useState < any > (payLevel)

    //企业顶岗实习评价
    const [postPractice] = useRequest(() => getNormalData < any > ('0132c883-5647-48af-b002-99d63ca1fa10'))
    const [postPracticeData, setPostPracticeData] = useState < any > (postPractice)

    //各专业实习人数占比
    const [ydata] = useRequest(() => getNormalData('2bfee459-b826-4337-822b-13e4a3866446'))
    const [yyData, setYydata] = useState < any > (ydata)


    //学生毕业实习薪资情况
    const [onSxxz, sxxz] = useRequestFn(getNormalData)
    const [stuXZ, setStuXZ] = useState < any > (null)
    const [newItem, setNewItem] = useState < any > ([{ name: '', value: '' }])

    //点击行业获取平均薪资
    async function onFn (val: any) {
        let res: any = await onSxxz("fdfe0cb1-aae8-4c93-a90d-fcce25fa7c63", { name: val })
        if (res)
        {
            setNewItem(res)
            setStuXZ(res)
            setPieData(res.data)

            for (let key in res)
            {
                if (key === 'color')
                {
                    // @ts-ignore
                    delete res[key]
                }
            }
            // @ts-ignore
            setValue(res);
            const newArray = [];
            newArray.push(res);
            // @ts-ignore
            setPieData(res.data);

        }
    }

    useMemo(() => {
        onFn('装备制造')
        setInternship(Number(internship ? internship : 0).toFixed(2) + "%")
        setMajor(Number(major ? major : 0).toFixed(2) + "%")
        setPaylevel(Number(payLevel ? payLevel : 0))
        setPostPracticeData(Number(postPracticeData ? postPracticeData : 0))
        setYydata(ydata)

    }, [ydata])

    //各专业实习人数占比
    const SXRSZB: any = useMemo(() =>
        <Shixi style={{ height: 200 }} yyData={ydata ? ydata : []} />
        , [ydata])

    //全省实习情况
    const QSSXQK: any = useMemo(() =>
        <Map showTimeLine={false} width={isMobile ? 'auto' : 630} height={isMobile ? 300 : 600} />
        , [ydata])

    // 学生毕业实习薪资
    const SXXZ: any = useMemo(() =>
        <Xinzi style={{ height: "200px", width: '300px', cursor: 'default' }} item={pieData} />
        , [pieData])

    //城市排行
    const CSPH: any = useMemo(() =>
        <Liuxiang style={{ overflow: 'auto' }} lineData={middleWare} Provinces={proMiddleWare}
            onChange={test} />
        , [proMiddleWare])

    return <div className={`${style.root} ${style.firstWall}`}>
        <Header />
        <div className={style.wrapper}>
            <div className={style.leftBlock}>
                <Block>
                    <div className={style.title}>专业大类岗位实习规模</div>
                    {SXRSZB}
                </Block>
                <Block>
                    <div className={style.title}>岗位实习企业接收毕业生就业数</div>
                    <div className={style.label}>
                        <p>
                            <span className={style.len}></span>
                            <span> 毕业生人数 </span>
                        </p>
                        <p>
                            <span className={style.len2}></span>
                            <span>岗位实习人数</span>
                        </p>
                    </div>
                    <Jiuyye style={{ height: 220 }} />
                </Block>
                <Block customStyle={{ marginBottom: 0 }}>
                    <div className={style.title}>实习计划执行情况</div>
                    <div className={style.label2}>
                        <p>
                            <span className={style.len}></span>
                            <span>计划人数</span>
                        </p>
                        <p>
                            <span className={style.len2}></span>
                            <span>实际执行人数</span>
                        </p>
                    </div>
                    <Jihua style={{ height: 210 }} />
                </Block>
            </div>
            <div className={style.centerBlock}>
                <Block>
                    <div style={{ position: "relative" }}>
                        <div className={style.centerTitle}>
                            <div className={style.centerTitleCenter}>
                                <span className={style.l}></span>
                                <span className={style.m}>全省职业院校实习情况</span>
                                <span className={style.r}></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        {QSSXQK}
                    </div>
                </Block>
                <Block showBorder={false} customStyle={{ background: 'transparent' }}>
                    <div className={style.middleBlocks}>
                        <div className={style.item}>
                            <div className={style.heading}>
                                实习就业率
                            </div>
                            <div className={style.value}>
                                {internship ? Number(internship ? internship : 0).toFixed(2) + '%' : 0 + '%'}
                            </div>
                            <div className={style.tl} />
                            <div className={style.tr} />
                            <div className={style.bl} />
                            <div className={style.br} />
                        </div>
                        <div className={style.item}>
                            <div className={style.heading}>
                                专业对口率
                            </div>
                            <div className={style.value}>
                                {major ? Number(major ? major : 0).toFixed(2) + '%' : 0 + '%'}
                            </div>
                            <div className={style.tl} />
                            <div className={style.tr} />
                            <div className={style.bl} />
                            <div className={style.br} />
                        </div>
                        <div className={style.item}>
                            <div className={style.heading}>
                                起薪水平
                            </div>
                            <div className={style.value}>
                                {payLevel ? Number(payLevel).toFixed(2) : 0}元
                            </div>
                            <div className={style.tl} />
                            <div className={style.tr} />
                            <div className={style.bl} />
                            <div className={style.br} />
                        </div>
                        <div className={style.item}>
                            <div className={style.heading}>
                                企业顶岗实习评价
                            </div>
                            <div className={style.value}>
                                {postPractice ? Number(postPractice).toFixed(2) : 0}分 <span>满分5分</span>
                            </div>
                            <div className={style.tl} />
                            <div className={style.tr} />
                            <div className={style.bl} />
                            <div className={style.br} />
                        </div>
                    </div>
                </Block>
            </div>
            <div className={style.rightBlock}>
                <Block>
                    <div className={style.title}>学生毕业实习薪资情况</div>
                    <div style={{ display: isMobile ? 'block' : 'flex', height: isMobile ? 'auto' : '210px' }}>
                        <div
                            style={{ color: '#fff', display: 'flex', flexWrap: 'wrap', width: isMobile ? '100%' : '298px', height: isMobile ? 'auto' : '200px' }}>
                            {
                                list.map((item, index) => {
                                    return (
                                        <div key={index} style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '10px',
                                            cursor: 'pointer'
                                        }} onClick={() => {
                                            onFn(item.name)
                                        }
                                        }>
                                            <p style={{
                                                background: item.color,
                                                width: '8px',
                                                height: '8px',
                                                paddingBottom: '0px',
                                                marginRight: '5px',
                                            }} />
                                            <p style={{
                                                width: 'auto',
                                                height: '20px',
                                                fontSize: '12px'
                                            }}> {item.name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ paddingBottom: isMobile ? '0' : '50px' }}>
                            {
                                value.name ? <p style={{
                                    color: '#fff',
                                    marginLeft: '25px',
                                    fontSize: '19px'
                                }}>{value.name + '平均薪资:' + parseInt(value.value) + '元'} </p> : <p style={{
                                    color: '#fff',
                                    marginLeft: '25px',
                                    fontSize: '19px'
                                }}>平均薪资:{stuXZ ? parseInt(stuXZ.value) + '元' : 0 + '元'} </p>
                            }
                            {SXXZ}
                        </div>
                    </div>
                </Block>
                <Block>
                    <div style={{ width: isMobile ? '100%' : '600px', height: '300px', overflowY: 'scroll' }}>
                        <div className={style.title}>毕业实习行业流向</div>
                        <Hangye style={{ height: 150, width: 300 }} />
                    </div>
                </Block>
                <Block customStyle={{ marginBottom: isMobile ? 80 : 0 }}>
                    <div style={{ height: '230px', overflowY: 'auto' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={style.title}>岗位实习流向{type2 ? '省内' : '省外'}城市排行</div>
                            {
                                // table的相互切换
                                <div className={style.buttons}>
                                    {/* 省内 */}
                                    <button className={`${style.btn} ${type2 === true ? style.btnActive : ''}`}
                                        onClick={() => {
                                            let arr: any = []
                                            SN?.map((item: any, index: number) => {
                                                if (city.indexOf(item.label) != -1)
                                                {
                                                    arr.push(`${index + 1}. ${item.label}`)
                                                }
                                            })
                                            setType2(true)    //切换样式
                                            setMiddleWare(SN?.map((item: any) => Number(item.value)));      // 数据
                                            setProMiddleWare(arr);     // 名字
                                        }}><span>省内</span></button>

                                    {/*  省外  /*/}
                                    <button onClick={() => {
                                        setType2(false)
                                        setMiddleWare(SN1?.map((it: any) => Number(it.value)));
                                        setProMiddleWare(SN1?.map((it: any, index: any) => `${index + 1}. ${it.province}`));
                                    }} className={`${style.btn}  ${type2 === false ? style.btnActive : ''} `}    >
                                        <span>省外</span>
                                    </button>
                                </div>
                            }
                        </div>
                        {CSPH}
                    </div>
                </Block>
            </div>
        </div>

        <Footer />
    </div>
}
