import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import SearchScreen from "../../Charts/Components/SearchScreen";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import icon1 from './img/icon-1.png';
import icon2 from './img/icon-2.png';
// import icon3 from './img/icon-3.png';
import CourseListTable from "./components/CourseListTable";
import icon4 from './img/icon-4.png';
import icon5 from './img/icon-5.png';
import icon6 from './img/icon-6.png';
import Go from './img/GO.png';
import useRequest from "../../../lib/useRequest";
import { findCountValue, getSg2Num } from "../../../service/report";
import Chart4 from "./components/Chart4";
import Chart5 from "./components/Chart5";
import Chart7 from "./components/Chart7";
import useDevice from "./../../../lib/useDevice";
import { getNormalData } from "../../../service/basic";
import { request } from "https";


var colorList = [
    "#c1232b",
    "#27727b",
    "#fcce10",
    "#e87c25",
    "#b5c334",
    "#fe8463",
    "#9bca63",
    "#fad860",
    "#f3a43b",
    "#60c0dd",
    "#d7504b",
    "#c6e579",
    "#f4e001",
    "#f0805a",
    "#26c0c0"
];
const colorList2 = [
    '#78A3CE',
    '#FBC2EB',
    '#A18CD1',
    '#C2E9FB',
    '#A1C4FD',
    '#D4FC79',
    '#96E6A1',
    '#FCC687',
    '#F28D86',
    '#F286A0',
    '#33876A',
    '#B5BF6E',
    '#2979F1',
    '#F08F1B',
    '#57B956',
    '#ABA5EA',
    '#9BC46C',
    '#30E0E0',
    '#F286CA',
    '#A6F286',
    '#D66161',
    '#8FF379',
    '#1CBDB4',
    '#EEF2F3',
    '#8E9EAB',
    '#BB9BF1',
    '#887BF2',
    '#7FFED8',
    '#09BDFE',
    '#F2E786',
]

export default function PageMajorMain () {
    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "专业信息" }]);
    const [major] = useRequest(() => findCountValue("count_subject_num")); // 专业数：count_subject_num
    const [num] = useRequest(() => findCountValue("count_major_num_2020")); //count_major_num_2020
    const [sg] = useRequest(getSg2Num)
    const [nums] = useRequest(() => getNormalData < any > ('db8df8e5-e4a8-451e-a0a4-cd42b63aedb5'))
    const [type, setType] = useState < number > (1)
    const { isMobile } = useDevice();
    const [show, setShow] = useState < boolean > (false)

    useEffect(() => {

        if (type == 1)
        {
            setShow(true)
        }
        else if (type == 2)
        {
            setShow(true)
        } else
        {
            setShow(false)
        }


    }, [type])


    const colWidth = isMobile ? "100%" : "20%";

    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.summaryMap}>
                    <div className={style.statistics}>
                        <div className={style.item} onClick={() => setType(1)}>
                            <div className={style.one}>
                                <img className={style.icon} src={icon1} alt="" />
                                <span className={style.txt}>专业种数</span>
                                <span>{parseInt(nums ? nums[0].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                        <div className={style.item} onClick={() => setType(2)}>
                            <div className={style.two}>
                                <img className={style.icon} src={icon2} alt="" />
                                <span className={style.txt}>专业布点数</span>
                                <span>{parseInt(nums ? nums[1].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                        <div className={style.item} onClick={() => setType(4)}>
                            <div className={style.four}>
                                <img className={style.icon} src={icon4} alt="" />
                                <span className={style.txt}>中国特色高水平高职专业群</span>
                                <span>{parseInt(nums ? nums[3].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                        <div className={style.item} onClick={() => setType(3)}>
                            <div className={style.three}>
                                <img className={style.icon} src={icon4} alt="" />
                                <span className={style.txt}>兴辽卓越专业群</span>
                                <span>{parseInt(nums ? nums[2].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                        <div className={style.item} onClick={() => setType(5)}>
                            <div className={style.six}>
                                <img className={style.icon} src={icon5} alt="" />
                                <span className={style.txt}>国家级现代学徒制试点专业</span>
                                <span>{parseInt(nums ? nums[4].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                        <div className={style.item} onClick={() => setType(6)}>
                            <div className={style.five}>
                                <img className={style.icon} src={icon6} alt="" />
                                <span className={style.txt}>省级现代学徒制示范专业</span>
                                <span>{parseInt(nums ? nums[5].value : '0')}</span>
                                <img className={style.Go} src={Go} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className={style.map}>
                        <SearchScreen show={show} changeType={setType} type={type} showTimeLine={false} width={isMobile ? 'auto' : 756} height={isMobile ? 260 : 500} />
                    </div>
                </div>

                <div className={style.totalMap}>

                    <div style={{ marginTop: 30 }} className={style.title}>专业大类布点情况</div>
                    <Chart5 />

                    <div className={style.title}>一校一案信息一览</div>
                    {/* <Chart4 /> */}
                    <CourseListTable/>

                </div>
                <div className={style.totalMap}>
                    <div className={style.title}>专业大类院校分布情况</div>
                    <Chart2 />
                </div>
                {/*
                <div className={style.totalMap}>
                    <Chart7 />
                </div> */}

                <div className={style.totalMap}>
                    <div className={style.title}>专业——产业在校生供给情况</div>
                    <Chart1 />
                </div>
            </div>

        </div>
    );
}
