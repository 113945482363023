import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import style from '../ProfessionalInfo/index.module.scss'
import baseStyle from "./index.module.scss";
import useRequest from "../../../../../lib/useRequest";
import { jbqkCompare, jbqkschoolDetail, SchoolDetail } from "../../../../../service/basic";
import ReactEcharts from "echarts-for-react";
import echarts, { EChartOption } from "echarts";
import useRequestFn from "../../../../../lib/useRequestFn";
import { toInt } from "../../../../../lib/utils";
import useDevice from "../../../../../lib/useDevice";


export default function Table ({ table1, table2 }: SchoolDetail) {
    const { isMobile } = useDevice()

    return (
        <div className={style.table}>
            <table style={{ width: isMobile ? '1150px' : '100%', marginBottom: "30px" }} className={baseStyle.table}>
                <tbody>
                    <tr>
                        <td className={style.blodFont} rowSpan={4}>基本信息</td>
                        <td className={style.blodFont}>学校名称</td>
                        <td>{table1.学校名称 || "--"}</td>
                        <td className={style.blodFont}>所在地区</td>
                        <td>{table1.所在地区 || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>建校时间</td>
                        <td>{table1.建校时间 || "--"}</td>
                        <td className={style.blodFont}>院校性质</td>
                        <td>{table1.院校性质 || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>举办单位类型</td>
                        <td>{table1.举办单位类型 || "--"}</td>
                        <td className={style.blodFont}>学校地址</td>
                        <td>{table1.学校网址 || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>通讯地址</td>
                        <td>{table1.通讯地址 || "--"}</td>
                        <td className={style.blodFont}>邮编</td>
                        <td>{table1.邮编 || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont} rowSpan={19}>基本状态</td>
                        <td className={style.blodFont}>学校占地面积（㎡）</td>
                        <td>{table1["学校占地面积（㎡）"] || "--"}</td>
                        <td className={style.blodFont}>校舍总建筑面积（㎡）</td>
                        <td>{table1["校舍总建筑面积（㎡）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>生均教学科研及辅助用房面积（㎡/生）</td>
                        <td>{table1["生均教学科研及辅助用房面积（㎡/生）"] || "--"}</td>
                        <td className={style.blodFont}>生均实验室、实习场所面积（㎡/生）</td>
                        <td>{table1["生均实验室、实习场所面积（㎡/生）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>生均学生宿舍（公寓）面积（㎡/生）</td>
                        <td>{table1["生均学生宿舍（公寓）面积（㎡/生）"] || "--"}</td>
                        <td className={style.blodFont}>生均纸质图书册数（册/生）</td>
                        <td>{table1["生均纸质图书册数（册/生）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学校固定资产总值（万元）</td>
                        <td>{table1["学校固定资产总值（万元）"] || "--"}</td>
                        <td className={style.blodFont}>教学、科研仪器设备总值（万元）</td>
                        <td>{table1["教学、科研仪器设备总值（万元）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>生均教学、科研仪器设备值（元/生）</td>
                        <td>{table1["生均教学、科研仪器设备值（元/生）"] || "--"}</td>
                        <td className={style.blodFont}>接入互联网出口带宽（Mbps）</td>
                        <td>{table1["接入互联网出口带宽（Mbps）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学校教职工总数（人）</td>
                        <td>{table1["学校教职工总数（人）"] || "--"}</td>
                        <td className={style.blodFont}>校内专任教师数（人）</td>
                        <td>{table1["校内专任教师数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>双师素质专任教师比例（%）</td>
                        <td>{table1["双师素质专任教师比例（%）"] || "--"}</td>
                        <td className={style.blodFont}>学年兼职教师总数（人）</td>
                        <td>{table1["学年兼职教师总数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年兼职教师授课课时数（学时）</td>
                        <td>{table1["学年兼职教师授课课时数（学时）"] || "--"}</td>
                        <td className={style.blodFont}>学年专业课时总数（学时）</td>
                        <td>{table1["学年专业课时总数（学时）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年兼职教师授课课时数占专业课时总数的比例（%）</td>
                        <td>{table1["学年兼职教师授课课时数占专业课时总数的比例（%）"] || "--"}</td>
                        <td className={style.blodFont}>非学历培训规模（人日）</td>
                        <td>{table1["非学历培训规模（人日）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>全日制普通高职学历教育在校生数（人）</td>
                        <td>{table1["全日制普通高职学历教育在校生数（人）"] || "--"}</td>
                        <td className={style.blodFont}>其中：普通高中起点在校生数（人）</td>
                        <td>{table1["其中：普通高中起点在校生数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>其中：中职起点在校生数（人）</td>
                        <td>{table1["其中：中职起点在校生数（人）"] || "--"}</td>
                        <td className={style.blodFont}>其中：五年制高职后两年在校生数（人）</td>
                        <td>{table1["其中：五年制高职后两年在校生数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>其中：国（境）外留学生数（人）</td>
                        <td>{table1["其中：国（境）外留学生数（人）"] || "--"}</td>
                        <td className={style.blodFont}>其中：其他在校生数（人）</td>
                        <td>{table1["其中：其他在校生数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>其中：扩招在校生数（人）</td>
                        <td>{table1["其中：扩招在校生数（人）"] || "--"}</td>
                        <td className={style.blodFont}>生师比（x︰1）</td>
                        <td>{table1["生师比（x︰1）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年合作企业订单培养数（人）</td>
                        <td>{table1["学年合作企业订单培养数（人）"] || "--"}</td>
                        <td className={style.blodFont}>学年合作企业支持学校兼职教师数（人）</td>
                        <td>{table1["学年合作企业支持学校兼职教师数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年合作企业与学校共同开发课程数（门）</td>
                        <td>{table1["学年合作企业与学校共同开发课程数（门）"] || "--"}</td>
                        <td className={style.blodFont}>学年合作企业与学校共同开发教材数（种）</td>
                        <td>{table1["学年合作企业与学校共同开发教材数（种）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年合作企业接受顶岗实习学生数（人）</td>
                        <td>{table1["学年合作企业接受顶岗实习学生数（人）"] || "--"}</td>
                        <td className={style.blodFont}>合作企业接受当年毕业生就业数（人）</td>
                        <td>{table1["合作企业接受当年毕业生就业数（人）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>合作企业接受当年毕业生就业数占应届毕业生的比例（%）</td>
                        <td>{table1["合作企业接受当年毕业生就业数占应届毕业生的比例（%）"] || "--"}</td>
                        <td className={style.blodFont}>学年合作企业对学校捐赠设备总值（万元）</td>
                        <td>{table1["学年合作企业对学校捐赠设备总值（万元）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年合作企业对学校准捐赠设备总值（万元）</td>
                        <td>{table1["学年合作企业对学校准捐赠设备总值（万元）"] || "--"}</td>
                        <td className={style.blodFont}>学校为企业年培训员工（人天）</td>
                        <td>{table1["学校为企业年培训员工（人天）"] || "--"}</td>
                    </tr>
                    <tr>
                        <td className={style.blodFont}>学年学校为企业技术服务年收入（万元）</td>
                        <td colSpan={3}>{table1["学年学校为企业技术服务年收入（万元）"] || "--"}</td>
                    </tr>
                </tbody>
            </table>
            <table style={{ width: "100%" }} className={baseStyle.table}>
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>全日制高职招生就业数据</th>
                        <th style={{ width: "15%" }}>2017年</th>
                        <th style={{ width: "15%" }}>2018年</th>
                        <th style={{ width: "15%" }}>2019年</th>
                        <th style={{ width: "15%" }}>2020年</th>
                        <th style={{ width: "15%" }}>2021年</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        table2?.map((item, index) => {
                            return <tr key={index}>
                                <td className={style.blodFont}>{item["name"] || "--"}</td>
                                <td style={{ textAlign: 'center' }}>{item["2017-2018学年"] || "--"}</td>
                                <td style={{ textAlign: 'center' }}>{item["2018-2019学年"] || "--"}</td>
                                <td style={{ textAlign: 'center' }}>{item["2019-2020学年"] || "--"}</td>
                                <td style={{ textAlign: 'center' }}>{item["2020-2021学年"] || "--"}</td>
                                <td style={{ textAlign: 'center' }}>{item["2021-2022学年"] || "--"}</td>
                            </tr>
                        })
                    }

                </tbody>
            </table>
        </div>
    );
}
