import axios from "axios";
import qs from "qs";
import Config from "../config";

interface ListItem {
    title: string;
    num: number;
}

/**
 * 测试请求
 */
export function testQuery() {
    return axios.post<SuccessData<ListItem[]>>(`/ajb/kw/fhkw/14-15/1_10.html`, qs.stringify({ name: "kenny" }))
        .then(res => res.data.data);
}

/**
 * 统计项接口
 */
export function findCountValue(guid: string) {
    return axios.post<SuccessData<{ countValue: string }>>(`/olap/dynamicsCount/findCountValue`, qs.stringify({ guid }))
        .then(res => res.data.data);
}

/**
 * 图表接口
 */
export function findOptions(guid: string) {
    return axios.post<SuccessData<{ option: any }>>(`/olap/dynamicsChart/findOptions`, qs.stringify({ guid }))
        .then(res => res.data.data);
}


/**
 * 今日登录
 */


export interface DlTodayTypes {
    name: string;
    value: number;
}

/**
 * 昨日登录
 */


export interface DlYesterdayTypes {
    name: string;
    value: number;
}

/**
 * 热门点播课
 */


export interface HotCourseTypes {
    content: string;
    tea: string;
    dep: string;
    value: string;
}

/**
 * 直播预告
 */


export interface LiveRepItem {
    dep: string;
    name: string;
    secs: string;
    status: string;
    tea: string;
    url: string;
}

/** 直播时长 */


export interface LiveDurationTypes {
    name: string,
    value: string
}

/**
 * 获取省市地理位置坐标
 */

export interface AreaTypes {
    [key: string]: [number, number, number]
}


/**
 * 获取飞线数据
 */
export interface CityTypes {
    name: string;
}


/**
 * 点播时长
 */

export interface TimesTypes {
    name: string;
    value: string;
}



/**
 * 饼图1
 */
export interface IPieData {
    name: string;
    nums: string;
}



/**
 * 饼图2
 */
export interface IPieData {
    name: string;
    nums: string;
}

export interface SearchParams {
    name?: string; //学校名称
    code?: string; //学校编码
    area_code?: string; //所在城市
    year?: string; //申报年度（固定2020）
}

interface SchoolItem {
    name: string;
    id: string;
    code: string;
    area_code: string;
    master_name: string;
    tel: string;
    create_date: string;
    stu_count: string;
    subject_count: string;
    xxsx: string;
    all_area: string;
    jzmj: string;
    xxzzc: string;
    submit_year: string;
}

/**
 * 取得学校列表
 */
export function jbqkschool(params: SearchParams) {
    return axios.post<SchoolItem[]>(`/lndc/jbqkschool`, qs.stringify(params))
        .then(res => res.data);
}


interface CityItem {
    code: string;
    label: string;
}

/**
 * 所在城市列表
 */
export function getAllUrban() {
    return axios.post<SuccessData<CityItem[]>>(`/lndc/getAllUrban`)
        .then(res => res.data.data);
}

interface PlanItem {
    code: string;
    label: string;
}

/**
* 实习计划
*/
const year = localStorage.getItem('year');
export function inPlan() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/practicePlanExecuteStatusList?yearid=${year}`)
        .then(res => res.data.data);
}
export interface SchoolDetailItem {
    code: string;
    name: string;
    teachCount: string;
    stu_count: string;
    base_gw_count: string;
    qrz_all: string;
    qrz_xljy: string;
    qrz_xljy_gzqd: string;
    qrz_xljy_zzqd: string;
    qrz_xljy_other: string;
    ts_rate: string;
    in_base_area: string;
    out_base_count: string;
    gj_base_area: string;
    temporary_count: string;
    cooperate_count: string;
    g_sg_subject_count: string;
    sb_sg_subject_count: string;
    onex_certificate_count: string;
    yls_sit_count: string;
    jsj_all_count: string;
    js_all_count: string;
    sjzh_book_incount: string;
    famous_teacher: string;
    submit_year: string;
    qzgzqdbl: string;
    qzzzqdbl: string;
    qzqtbl: string;
    xx_income: string;
    xf_income: string;
    czjcxbz_income: string;
    zydfczzxtr_income: string;
    shjk_income: string;
    qtzsr_income: string;
    xxzzc: string;
    zd: string;
    jcssjs: string;
    sbcg_all: string;
    jxggjyj_hj: string;
    szjs_hj: string;
    book_buy: string;
    rcjx_all: string;
    rcjx_syshc: string;
    rcjx_sxzx: string;
    rcjx_pqjzjsjf: string;
    rcjx_tywcf: string;
    rcjx_qt: string;
    other_all: string;
    hdje: string;
    qnjss: string;
    qnjsbl: string;
    znjss: string;
    znjsbl: string;
    ssxjss: string;
    ssxjsbl: string;
    gzc: string;
    gzcbl: string;
    zjzc: string;
    zjzcbl: string;
    cjzc: string;
    cjzcbl: string;
    qtzc: string;
    qtzcbl: string;
    bsyjs: string;
    bsyjsbl: string;
    ssyjs: string;
    ssyjsbl: string;
    bkxl: string;
    bkxlbl: string;
    zkxl: string;
    zkxlbl: string;
    zkyxxl: string;
    zkyxxlbl: string;
    all_area: string;
    jzmj: string;
}

/**
 * 取得学校明细
 */

export function jbqkschoolDetail(id: string) {
    return axios.post<SchoolDetailItem>(`/lndc/jbqkschoolDetail`, qs.stringify({ id: id }))
        .then(res => res.data);
}


export interface PicParams {
    id?: string;
    type?: string;
}
/**
 * 取得学校明细-折线图
 */

export function jbqkCompare(params: PicParams) {
    return axios.post<[]>(`/lndc/jbqkCompare`, qs.stringify(params))
        .then(res => res.data);
}


//实习数据》wall2》实习签到率
export function signIn() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/practiceSignRate?yearid=${year}`).then(res => res.data.data);
}

//实习数据》wall2》指导教师情况
export function Instructor() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/studentTeacherRate?yearid=${year}`).then(res => res.data.data)
}
//实习数据》wall2》指导教师情况详情
export function InstructorDel() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/teacherStatusDetail?yearid=${year}`).then(res => res.data.data)
}
//实习数据》wall2》计划实习人数 / 实际实习人数 / 指导教师数量
export function NumberPeople() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/schoolOperateStatus?yearid=${year}`).then(res => res.data.data)
}
//实习数据》wall3》实习日志上报率
export function Report() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/practiceLogReportRate?yearid=${year}`).then(res => res.data.data)
}
//实习数据》wall3》实习报告情况
export function Report1() {
    return axios.post<SuccessData<any>>(`/lndc/practiceManage/practiceReportStatus?yearid=${year}`).then(res => res.data.data)
}


export interface SchoolDetail {
    table2: {
        "2017-2018学年": string;
        "2018-2019学年": string;
        "2019-2020学年": string;
        "2020-2021学年": string;
        "2021-2022学年": string;
        "2022-2023学年": string;
        name: string;
    }[],
    table1: {
        举办单位类型: string;
        "全日制普通高职学历教育在校生数（人）": string;
        "其中：中职起点在校生数（人）": string;
        "其中：五年制高职后两年在校生数（人）": string;
        "其中：其他在校生数（人）": string;
        "其中：国（境）外留学生数（人）": string;
        "其中：扩招在校生数（人）": string;
        "其中：普通高中起点在校生数（人）": string;
        "双师素质专任教师比例（%）": string;
        "合作企业接受当年毕业生就业数占应届毕业生的比例（%）": string;
        "合作企业接受当年毕业生就业数（人）": string;
        "学年专业课时总数（学时）": string;
        "学年兼职教师总数（人）": string;
        "学年兼职教师授课课时数占专业课时总数的比例（%）": string;
        "学年兼职教师授课课时数（学时）": string;
        "学年合作企业与学校共同开发教材数（种）": string;
        "学年合作企业与学校共同开发课程数（门）": string;
        "学年合作企业对学校准捐赠设备总值（万元）": string;
        "学年合作企业对学校捐赠设备总值（万元）": string;
        "学年合作企业接受顶岗实习学生数（人）": string;
        "学年合作企业支持学校兼职教师数（人）": string;
        "学年合作企业订单培养数（人）": string;
        "学年学校为企业技术服务年收入（万元）": string;
        "学校为企业年培训员工（人天）": string;
        "学校占地面积（㎡）": string;
        学校名称: string;
        "学校固定资产总值（万元）": string;
        "学校教职工总数（人）": string;
        学校网址: string;
        建校时间: string;
        所在地区: string;
        "接入互联网出口带宽（Mbps）": string;
        "教学、科研仪器设备总值（万元）": string;
        "校内专任教师数（人）": string;
        "校舍总建筑面积（㎡）": string;
        "生均学生宿舍（公寓）面积（㎡/生）": string;
        "生均实验室、实习场所面积（㎡/生）": string;
        "生均教学、科研仪器设备值（元/生）": string;
        "生均教学科研及辅助用房面积（㎡/生）": string;
        "生均纸质图书册数（册/生）": string;
        "生师比（x︰1）": string;
        通讯地址: string;
        邮编: string;
        院校性质: string;
        "非学历培训规模（人日）": string;
    };
    video: string;
    introduction: string;
}

/**
 * 取得学校明细
 */

export function schoolDetail(id: string) {
    return axios.post<SuccessData<SchoolDetail>>(`/lndc/schoolDetail`, qs.stringify({ id: id }))
        .then(res => res.data.data);
}

/**
 * 统一接口
 */
export function  getNormalData<T>(uuid: string, params?: { [key: string]: string }) {
    const year = localStorage.getItem('year');
    return axios.post<SuccessData<T>>(`/lndc/commonInterface`, { yearId: year || Config.yearsArr[1].value, uuid, ...params })
        .then(data => data.data.data);
}

/**
 * 特色理工
 */

export interface SpecialType{
    subject_code: string;
    subject_name: string;
    zybds: string;
}
