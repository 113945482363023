import React from 'react';

import echarts from 'echarts';

import '../../assets/common.scss';
import style from './style.module.scss';
import Province from "./Components/Province/Provice";

import KechengShezhi from './School/KechengShezhi';
import ZhuanyeZhanbi from './School/ZhuanyeZhanbi';
import Shouke from './School/Shouke';

import Teacher from './School/Teacher';
import Widget1 from './School/Widget1';


import ThemeData from '../../assets/walden.project.json';
import MyTheme from '../../assets/myTheme.json';
import cityDatas from "../../assets/datas/cityDatas";
import {Link} from "react-router-dom";
import Nav from "./Components/Nav";

export default function Index() {

    echarts.registerTheme('theme', MyTheme);

    return (
        <>
            <Nav type={3} />
            {/*<div className={style.subHeading}>课程教学</div>*/}
            <div className={style.main}>
            <div className={style.center}>
                <StatisticsThree />
                <div className={style.map}>
                    {/*去掉 思政课 校企合作*/}
                    <Province tableType={3} name='辽宁' width={1200} height={800} data={cityDatas} year={["精品课", "在线课程", "资源共享", "实训课"]}
                              // geoColor='43, 130, 29'
                              geoColor='253, 200, 0'
                    />
                </div>
            </div>

            <div className={style.right}>
                <div className={style.shuxing}>
                    <div style={{fontSize: 24, color: "#347afc",textAlign: "center"}}>课程设置情况</div>
                    <KechengShezhi />
                    <ZhuanyeZhanbi />
                </div>
                <div className={style.shuxing}>
                    <Shouke />
                </div>
            </div>

        </div>
        </>

    )
}

export function StatisticsThree() {
    return (
        <div className={style.statistics}>
            <div className={style.item}>
                <div className={style.label}>课程总数</div>
                <div className={style.number}>23,699</div>
            </div>
            <div className={style.item}>
                <div className={style.label}>精品课程数</div>
                <div className={style.number}>237</div>
            </div>
            <div className={style.item}>
                <div className={style.label}>在线课程数</div>
                <div className={style.number}>3,554</div>
            </div>
            <div className={style.item}>
                <div className={style.label}>资源共享课数</div>
                <div className={style.number}>539</div>
            </div>
            <div className={style.item}>
                <div className={style.label}>实训课数</div>
                <div className={style.number}>9,184</div>
            </div>
        </div>
    )
}
