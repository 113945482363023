import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.scss"
import MyTheme from '../../../assets/myTheme.json';
import echarts from "echarts";
import { Statistics } from "./components/Statistics";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import Chart8 from "./components/Chart8";
import { Col, Row } from "antd";
import Block from "./components/Block";
import FirstScreen from "./components/FirstScreen";
import Chart12 from "./components/Chart12";
import Chart15 from "./components/Chart15";

import Tables from "./components/Table";
import { DatePicker, message, Button, Select, Table } from 'antd';
import Chart14 from "./components/Chart14";
import TableStyle from "../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss";
import useRequest from "../../../lib/useRequest";
import { currentTime, ICurrentTime, IPoint } from "../../../service/chart";
import { getNormalData } from "../../../service/basic";
import useRequestFn from "../../../lib/useRequestFn";
import useDevice from "../../../lib/useDevice";
export default function PageCourseMain () {

    const [data] = useState([1835, 1426, 1023, 850, 800, 750, 666, 600, 550, 400, 303, 201])

    const { Option } = Select;

    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "课程信息" }]);

    echarts.registerTheme('theme', MyTheme);

    // 开始时间
    const [startTime, setStartTime] = useState('')

    // 结束时间
    const [endTime, setEndTime] = useState('')

    // table 显示标记
    const [flag, setFlag] = useState(false);
    // 头部标题
    const [title, setTitle] = useState('')

    const [tabDel, setTabDel] = useRequestFn(getNormalData)

    const [tabData, setTabData] = useState < any > ([{}])
    // 展示方法
    const showTable = async (item: string) => {
        setFlag(true);
        setTitle(item);
        let res = await tabDel("8adb6178-486b-4a04-b00e-dbb0b145ae49", { name: item })
        setTabData(res)
    }
    // const [ tableData ] = useRequest(()=>technicalStatistics(title),{},[title])

    const [sourData] = useState([
        {
            name: "第一节",
            value: "07:15"
        },
        {
            name: "第二节",
            value: "09:00"
        },
        {
            name: "第三节",
            value: "10:11"
        },
        {
            name: "第四节",
            value: "11:30"
        }, {
            name: "第五节",
            value: "13:21"
        },
        {
            name: "第六节",
            value: "14:29"
        }, {
            name: "第七节",
            value: "15:30"
        },
        {
            name: "第八节",
            value: "18:40"
        }
    ])
    const [time, setTime] = useState('');//默认时间

    // const [ tableData ] = useRequest(()=>technicalStatistics(title),{},[title])//职务授课详情统计
    const [tableData] = useRequest(() => getNormalData < any > ('3b347190-1a42-4ae4-9d2d-b95e3b625fa2'))//职业技术职务授课情况统计

    const [curTime] = useRequest(() => currentTime(time), {}, [time]) //获取学期信息

    const [info, setInfo] = useState < ICurrentTime | null > (null)//通过时间获取必要信息

    useEffect(() => {
        setInfo(curTime) //更新info的值
    }, [curTime])

    //课程革命典型案例
    const [GMClass, GMClassData] = useRequestFn < any > (getNormalData)
    const [GMData, setGMData] = useState < any > (null)
    async function GM () {
        let res = await GMClass('6bcf4d9d-12d9-423e-a15d-041f900c1ee4', { term: info?.term })
        let arr: any
        arr = []
        res?.map((item: any) => {
            arr.push([item.school_name, item.course_name, item.teacher_name, item.section, item.url])
        })
        setGMData(arr)
    }

    //兴辽职教金课
    const [LXClass, LXClassData] = useRequestFn < any > (getNormalData)
    const [LXData, setLXData] = useState < any > (null)
    async function LX () {
        let res = await LXClass('63dbffd4-a6c5-4e22-bf02-54d76d60dbf3', { term: info?.term })
        let arr: any
        arr = []
        res?.map((item: any) => {
            arr.push([item.school_name, item.course_name, item.teacher_name, item.section, item.url])
        })
        setLXData(arr)
    }
    useEffect(() => {
        LX()
        GM()
    }, [info])

    const { isMobile } = useDevice()

    let columns = [
        {
            title: '学校',
            dataIndex: 'school_name'
        },
        {
            title: "高级职称人数",
            dataIndex: 'gjrs',
            sorter: {
                compare: (a: any, b: any) => a.gjrs - b.gjrs
            }
        },
        {
            title: "人数",
            dataIndex: "rs",
            sorter: {
                compare: (a: any, b: any) => a.rs - b.rs
            }
        },
        {
            title: "比例",
            dataIndex: 'bl',
            sorter: {
                compare: (a: any, b: any) => a.bl - b.bl
            }
        }
    ]
    let dataSource = tabData?.map((item: any) => {
        return {
            school_name: item.school_name,
            gjrs: item.gjrs,
            rs: item.rs,
            bl: item.rs == 0 ? '0%' : ((item.gjrs / item.rs) * 100).toFixed(2)
        }
    })


    return (
        <div className={style.root}>
            <div className={style.content}>
                <Statistics />
                {/*实时开课分布图*/}
                <Row gutter={[24, 24]}>
                    <Col span={isMobile ? 24 : 16}>
                        <Block title="实时开课分布图" style={{ height: isMobile ? 440 : 680, padding: isMobile ? 0 : '' }}>
                            <div className={`${style.waper} ${style.waper1}`}>
                                <DatePicker placeholder={'开始时间'} onChange={(date: any, dateString: string) => {
                                    // 设置开始时间
                                    setStartTime(dateString)
                                }} />
                                <span className={style.space}>----</span>

                                <Select allowClear style={{ width: 120 }} onChange={(e: any) => {
                                    setEndTime(e)
                                }
                                }>{
                                        sourData.map((item: any, index: number) => {
                                            return <Option key={index} value={item.value}>{item.name}</Option>
                                        })
                                    }
                                </Select>

                                <Button onClick={() => {
                                    if (!startTime)
                                    {
                                        message.error('请选择开始时间');
                                        return;
                                    }
                                    if (!endTime)
                                    {
                                        message.error('请选择节数');
                                        return;
                                    }
                                    setTime(startTime + ' ' + endTime);

                                }}>搜索</Button>
                            </div>
                            <FirstScreen showTimeLine={false}
                                width={isMobile ? 'auto' : 700}
                                height={isMobile ? 350 : 650}
                                info={info} />
                        </Block>
                    </Col>

                    <Col span={isMobile ? 24 : 8}>
                        {/*课程类型情况图表*/}
                        <Block title="课程类型情况">
                            <Chart8 objData={time} />
                        </Block>
                        {/*课程进度情况*/}
                        <Block title="课程进度情况">
                            {/* <Chart12 objData={time} info={info} /> */}
                            <Chart15 uuid="07dc6509-35a6-40c2-983f-39bd39b8b80b" objData={time} info={info} />

                        </Block>
                    </Col>
                </Row>
                {
                    flag && <div className={style.list}>
                        <div className={TableStyle.title}>
                            <div className={TableStyle.tableTitle}>{title + '高级专业技术职务教师授课情况统计'}</div>
                            <div className={style.closeButton} onClick={() => {
                                setFlag(false)
                            }
                            } />
                        </div>
                        <div style={{ height: '450px', overflowY: 'auto' }}>
                            <Table columns={columns} dataSource={dataSource} pagination={false} />

                            {/* <table className={style.table}>
                                <tbody className={style.tableList}>
                                    <tr>
                                        <th style={{ width: "25%" }}>学校</th>
                                        <th style={{ width: "25%" }}>高级职称人数</th>
                                        <th style={{ width: "25%" }}>上课人数</th>
                                        <th style={{ width: "25%" }}>比例</th>
                                    </tr>
                                    {
                                        tabData?.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.school_name}</td>
                                                    <td>{item.gjrs}</td>
                                                    <td>{item.rs}</td>
                                                    {
                                                        <td>{item.gjrs && item.rs == 0 ? '0%' : ((item.gjrs / item.rs) * 100).toFixed(2)}</td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                }
                <Block className={style.mainBox}>
                    {/*2020-2021学年第二学期职业技术职务授课情况统计*/}
                    <div className={style.chart14}>
                        {tableData ? tableData.name[0] : []}
                    </div>
                    <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
                        <Chart14 item={tableData ? tableData.list : []} showTable={showTable} />
                    </div>
                </Block>

                <Block style={{ display: "table", width: '100%', padding: 0, boxSizing: 'border-box' }}>
                    <div style={{ width: isMobile ? '100%' : '50%', float: 'left', }}>
                        <Tables title="兴辽职教金课"
                            data={LXData ? LXData : []}
                            // data={[
                            //   ['辽宁城市建设职业技术学院', '流体输送与传热技术', '陈娆', '3-4节'],
                            //   ['辽宁广告职业学院', '建筑工程计量与计价', '徐秀香', '3-4节'],
                            //   ['辽宁广告职业学院', '地理信息系统技术应用', '刘丹', '3-4节'],
                            // ]}
                            colData={["学校", "课程名称", "教师", "上课节次", "课堂链接"]} />
                    </div>
                    <div style={{ width: isMobile ? '100%' : '50%', float: 'left', boxSizing: 'border-box', marginTop: isMobile ? '10px' : '0' }}>
                        <Tables title="课程革命典型案例"
                            data={GMData ? GMData : []}
                            colData={["学校", "课程名称", "教师", "上课节次", "课堂链接"]}
                            customStyle={{ marginLeft: isMobile ? '0' : '10px' }}
                        />
                    </div>
                </Block>
            </div>
        </div>
    );
}
