//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import echarts, { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import { getShuangGao } from "../../../../service/chart";
import { getNormalData } from "../../../../service/basic";
import { any } from "underscore";
import Popup from "./../../../../components/EChartsUuid/components/Popup";
import style from './style.module.scss';
import useRequestFn from "../../../../lib/useRequestFn";
import { Table } from 'antd'

let colorList2 = [
    "#f77070",
    "#326dce",
    "#468cff",
    "#56cefd",
    "#34ac74",
    "#84cf61",
    "#ffd200",
    "#ffad56",
    "#34ac74",
    "#34ac74",
    "#d7504b",
    "#c6e579",
    "#f4e001",
    "#f0805a",
    "#26c0c0"
];

const Options: EChartOption<EChartOption.Series> = {
    title: {
        text: '高职院校类型分布图',
        left: 'left',
        top: 0,
        textStyle: {
            color: '#347afc'
        }
    },
    series: [
        {
            cursor: 'default',
            type: "pie",
            data: [],
            radius: '65%',
            label: {
                fontSize: 12
            }
        },
    ],
    color: colorList2,
    tooltip: {
        formatter: `{b}大类布点数 {c}个`
    }
};


interface IProps {
    customOption?: (
        opt: EChartOption<EChartOption.Series>
    ) => EChartOption<EChartOption.Series>;
    style?: any;
}

export default function Index ({ customOption }: IProps) {
    const [data] = useRequest(() => getNormalData < { sg: string[], subject: string[], '1x': string[], name: string[] } > ("2fd9e5ca92d511ecba43005056958024")); //

    const [tableTitle, setTableTitle] = useState('');
    const [show, setShow] = useState(false);
    const [schoolList, setSchoolList] = useState < any > ([]);
    const [onList, list] = useRequestFn(getNormalData)
    async function onFn (name: string) {
        let res = await onList < any > ('41a3b0ed-99fe-11ec-ba43-005056958024', { zydl: name })
        if (res)
        {
            setSchoolList(res)
            setTableTitle(`开设专业大类院校数`)
        }
    }

    const onEventsObj = {
        'click': (params: any) => {
            onFn(params.name)
            setShow(true)
        }
    }
    const opt = useMemo(() => {
        // 综合大学整体替换成综合院校
        let arr = data?.name.map((item, index) => {
            return {
                name: item,
                value: data.schoolcount[index]
            }
        })

        return {

            title: {
                text: "开设专业大类院校数",
                left: "left",
                top: 0,
                textStyle: {
                    color: "#347afc",
                }
            },
            color: colorList2,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: "{b} : {c} ({d}%)"
            },
            grid: { //网格大小
                x: 10,
                y: 10,
                x2: 1,
                y2: 10,
                borderWidth: 1,
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: ['30%', '60%'],
                    center: ['50%', '60%'],
                    data: arr,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }, [data]);

    let columns = [
        {
            title: '学校名称',
            dataIndex: 'school_name',
        },
        {
            title: '城市名称',
            dataIndex: 'area_code',
        },
        {
            title: '专业大类',
            dataIndex: 'xz_code',
        },
        {
            title: '学校代码',
            dataIndex: 'school_code',
            sorter: {
                compare: (a: any, b: any) => a.school_code - b.school_code
            }
        },

    ]
    let dataSource = schoolList?.map((item: any) => {
        return {
            school_name: item.school_name,
            area_code: item.area_code,
            xz_code: item.xz_code,
            school_code: item.school_code,
        }
    })
    return <div>
        <ReactEcharts
            option={opt}
            onEvents={onEventsObj}
        />
        <Popup onClick={() => {

            setShow(false);
        }} show={show} width='80vw'>
            <div className={style.tableWrapper}>
                <div
                    className={style.tableTitle}>{tableTitle}</div>
                <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                </div>
            </div>

        </Popup>
    </div>
}
