import { Input, Select, Button, Table, Form } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import style from './list.module.scss'
import useRequest from "../../../lib/useRequest";
import { getAllUrban, getNormalData, jbqkschool, SearchParams } from "../../../service/basic";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { toInt } from "../../../lib/utils";
import useDevice from "../../../lib/useDevice";
import { ColumnsType } from "antd/lib/table/interface";




export default function PageBasicList () {

    const history = useHistory();
    const [form] = Form.useForm();
    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "基本情况" }]);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [areaCode, setAreaCode] = useState('');

    const [data, { loading }] = useRequest(() => getNormalData < any > ('a7cd7986-96cd-43c4-bc46-6dbe96f44869', { code, name, areaCode }), {}, [code, name, areaCode])

    const [cityList] = useRequest(getAllUrban);
    const { isMobile } = useDevice();

    function reset () {
        form.resetFields();
    }
    const columns: ColumnsType<any> = [
        {
            title: '学校代码',
            dataIndex: 'code',
            responsive: ['md'], // 手机分辨率不显示该列
            sorter: {
                compare: (a, b) => a.code - b.code
            }
        },
        {
            title: '学校名称',
            dataIndex: 'name',
            sorter: {
                compare: (a: any, b: any) => a.name.length - b.name.length,
                multiple: 2,
            },
            render: (text: any, data: any) => <a onClick={() => history.push(`/basic/schooldetail?id=${data.id}&name=${text}`)}>{text}</a>,
            width: 200,
            fixed: isMobile ? "left" : false,
        },
        {
            title: '学校属性',
            dataIndex: 'xxsx',
            width: 180,
            filters: [
                {
                    text: '民办',
                    value: '民办'
                },
                {
                    text: '省属公办',
                    value: '省属公办'
                },
                {
                    text: '市属公办',
                    value: '市属公办'
                }
            ],
            onFilter: (value: any, record: any) => record.xxsx.indexOf(value) === 0,
        },
        {
            title: '在校生数',
            dataIndex: 'stu_count',
            render: (count: string) => toInt(count),
            sorter: {
                compare: (a, b) => a.stu_count - b.stu_count
            }
        },
        {
            title: '占地面积(平方米)',
            dataIndex: 'all_area',
            sorter: {
                compare: (a, b) => a.all_area - b.all_area
            }
        },
        {
            title: '建筑面积(平方米)',
            dataIndex: 'jzmj',
            sorter: {
                compare: (a, b) => a.jzmj - b.jzmj
            }

        },
        {
            title: '年度经费投入(万元)',
            dataIndex: 'xxzzc',
            sorter: {
                compare: (a, b) => a.xxzzc - b.xxzzc
            }
        },

    ];

    /**
     * 设置值
     * @param val
     */
    const onFinish = (values: any) => {
        setCode(values.code);
        setName(values.name);
        setAreaCode(values.area_code);
    };
    const [show, setShow] = useState(false);

    const [list] = useRequest(() => getNormalData < any > ('ad8d4c3c-da80-4c26-bc1d-26dbb5a9be99'))//所有学校列表
    return (
        <div className={style.root}>
            <div className={style.header}>
                <Form form={form} onFinish={onFinish}>
                    <div className={style.schoolName}>
                        <Form.Item name="name" label="学校名称" >
                            {/* <Input className={style.searchContent} placeholder="请输入学校名称" /> */}
                            <Select className={style.searchContent} placeholder="请选择学校名称">
                                <Select.Option label="" value="">全部</Select.Option>
                                {list?.map((item: any, index: number) =>
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={style.schoolName}>
                        <Form.Item name="code" label="学校编号" >
                            <Input className={style.searchContent} placeholder="请输入学校编号" />
                        </Form.Item>
                    </div>
                    <div className={style.schoolName}>
                        <Form.Item name="area_code" label="所在城市" >
                            <Select className={style.searchContent} placeholder="请选择所在城市">
                                <Select.Option label="" value="">全部</Select.Option>
                                {cityList?.map((item, index) =>
                                    <Select.Option key={index} value={item.code}>{item.label}</Select.Option>
                                )}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className={style.btns}>
                        <Form.Item>
                            <Button className={style.search} type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                查询
                            </Button>
                            <Button className={style.reset} onClick={() => reset()} icon={<RollbackOutlined />}>
                                重置
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className={style.tableContent}>
                <div className={style.tableTitle}>
                    <h1>学校列表</h1>
                </div>

                <Table
                    loading={loading}
                    className={style.table}
                    columns={columns}
                    dataSource={data || []}
                    pagination={false}
                    scroll={isMobile ? { x: 800 } : undefined}
                    rowClassName={(record, index) => {
                        let className = index % 2 ? style.shallow_gray : style.deep_gray;
                        return className
                    }}
                />
            </div>
        </div>
    );
}
