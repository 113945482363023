import classNames from "classnames/bind";
import {useHistory} from "react-router-dom";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import useRequest from "../../../lib/useRequest";
import {jytj01} from "../../../service/employment";
import useQuery from "../../../lib/useQuery";
import {findSpecialtyInfoById} from "../../../service/major";
const style = classNames.bind(require("./index.module.scss"));

export default function PageEmploymentDetail() {

    const history = useHistory();
    useBreadcrumb([{name: "工作页", url: "/"}, {name: "就业跟踪", url: "/employment/list"}, {name: "详细"}]);

    const {id} = useQuery();

    const [jytj01Data] = useRequest(() => jytj01(id))
    const [special] = useRequest(() => findSpecialtyInfoById(id));

    return (
        <div className="container">

            <div className={style("header")}>
                <div className={style("nav")}>
                    <a href="#" onClick={() => history.goBack()} className={style("back")}>返回 &gt;</a>&nbsp;
                    <span className={style("")}>{special?.schoolName} ({special?.schoolCode})</span>
                </div>

                <h1 className={style("title")}>{special?.subjectName}</h1>
            </div>

            <div className={style("content")}>
                <div style={{paddingTop: 30}}>

                    <div className={style("inner")}>
                        <table className={style("table")}>
                            <tr>
                                <td>毕业学生数量</td>
                                <td colSpan={3} className={style("noBorder")}>{jytj01Data?.stuCount}</td>
                            </tr>

                            <tr>
                                <td style={{width: 250}}>毕业去向-专升本	</td>
                                <td className={style("noBorder")}>{jytj01Data?.jyqx_zsb}</td>
                                <td style={{width: 250}}>毕业去向-就业</td>
                                <td className={style("noBorder")}>{jytj01Data?.jyqx_jy}</td>
                            </tr>

                            <tr>
                                <td>毕业去向-创业</td>
                                <td className={style("noBorder")}>{jytj01Data?.jyqx_cy}</td>
                                <td>本省就业人数</td>
                                <td className={style("noBorder")}>{jytj01Data?.jye}</td>
                            </tr>

                            <tr>
                                <td>起薪线  3000以下</td>
                                <td className={style("noBorder")}>{jytj01Data?.qxx_3}</td>
                                <td>起薪线  3000-5000</td>
                                <td className={style("noBorder")}>{jytj01Data?.qxx_35}</td>
                            </tr>

                            <tr>
                                <td>起薪线  5000以上</td>
                                <td className={style("noBorder")}>{jytj01Data?.qxx_5}</td>
                                <td>对口就业人数</td>
                                <td className={style("noBorder")}>{jytj01Data?.dk_yes}</td>
                            </tr>

                            <tr>
                                <td>国有单位就业人数</td>
                                <td className={style("noBorder")}>{jytj01Data?.jydwxz_gy}</td>
                                <td>独资单位就业人数</td>
                                <td className={style("noBorder")}>{jytj01Data?.jydwxz_dz}</td>
                            </tr>

                            <tr>
                                <td>合资单位就业人数</td>
                                <td className={style("noBorder")}>{jytj01Data?.jydwxz_hz}</td>
                                <td colSpan={2}></td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
