import ReactEcharts from "echarts-for-react";
import { useMemo, useState, useEffect } from "react";
import { any } from "underscore";
import useRequest from "../../../../lib/useRequest";
import { getNormalData } from "../../../../service/basic";
import { teacherAreaHistogram } from "../../../../service/chart";
import useDevice from "../../../../lib/useDevice";
import Style from "../../index.module.scss"

export default function Index () {

  const [data] = useRequest(() => getNormalData < any > ('cb097d7e-ff86-4022-a120-df7088efece2'))
  const { isMobile } = useDevice();

  const options: any = useMemo(() => {
    return {
      title: {
        text: "在校生规模分布图",
        left: "left",
        top: 0,
        textStyle: {
          color: "#347afc",
        }
      },
      grid: { //网格大小
        x: 40,
        y: 65,
        x2: 0,
        y2: isMobile ? 40 : 20,
        borderWidth: 1,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      xAxis: [
        {
          type: 'category',
          data: data ? data.option.series[0].data.map((item: any) => item.name) : [],
          splitLine: {//显示分割线
            show: false,
          },
          grid: {
            borderWidth: 0,
          },
          axisTick: {
            show: false
          },
          axisLine: {// 竖轴方向刻度
            show: false
          },
          axisLabel: { //X轴显示文字完全
            interval: 0,
            rotate: isMobile ? 12 : 0,
            fontSize: 12
          }
        }

      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisTick: {// 横轴方向刻度
            length: 0,
            show: false
          },
          splitLine: { // 网格线
            show: true
          },
          axisLine: {// 竖轴方向刻度
            show: false
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            color: "#8c8c8c"
          }
        },

        {
          type: 'value',
          name: '',

          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: '总量',
          type: 'bar',
          axisTick: {
            show: false
          },
          barWidth: 40,//柱图宽度
          barGap: '40%',/*多个并排柱子设置柱子之间的间距*/
          barCategoryGap: '50%',/*多个并排柱子设置柱子之间的间距*/
          data: data ? data.option.series[0].data.map((item: any) => item.value) : [],
          itemStyle: {
            color: "#4481e6"
          },
        }
      ]
    }
  }, [data])

  return (
    <div >
      <ReactEcharts
        option={options}
      />
    </div>
  );
}
