import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import _ from "lodash";
import useRequest from "../../../../lib/useRequest";
import { todayCourse } from "../../../../service/chart";
import { useMemo } from "react";
import * as Color from "color";
import { getNormalData } from "../../../../service/basic";

const colorList = ["#991f1f", "#8f1f99", "#1f2799", "#1f5e99", "#1f998f", "#1f992a", "#997b1f", "#99411f", "#1f1f99", "#1f9947"];

interface IProps {
  title: string;
  style?: any;
}
export default function Index ({ style }: IProps) {

  // const [ data ] = useRequest(todayCourse)
  const [data] = useRequest(() => getNormalData < any > ('d3bacf6c-1f90-4ef9-b433-f29825f6dda1'))
  const options: EChartOption<EChartOption.Series> = useMemo(() => {
    return {
      barWidth: 30,
      xAxis: {
        type: 'category',
        data: data?.map((item: any) => item.course),
        axisLabel: {
          rotate: 35,
          color: "#ffffff"
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: "#ffffff"
        }
      },
      series: [{
        data: data?.map((item: any) => item.value),
        itemStyle: {
          color: function (params: any) {
            const color = Color(colorList[params.dataIndex]);

            return {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: color.lighten(1).hex() // 0% 处的颜色
              }, {
                offset: 1, color: color.hex() // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        type: 'bar'
      }]
    }
  }, [data])

  return <ReactEcharts theme={"theme"} style={style} option={options} />;
}
Index.defaultProps = {
  title: "",
};
