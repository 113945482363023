import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import Style from "../../index.module.scss"

export default function Chart2 (props: any) {

  const [list, setList] = useState < any > (null)

  useEffect(() => {
    if (props)
    {
      const arr: any = []
      props.flowData?.map((item: any) => {
        arr.push(
          { name: item.province, data1: item.dgrs, data2: item.ggrs }
        )
      })
      setList(arr)
    }
  }, [props])
  // const [data] = useRequest(teacherAreaHistogram);

  const options: echarts.EChartOption<echarts.EChartOption.Series> = useMemo(() => {

    // const list1 = [
    //   { name: "辽宁", data1: 49.1, data2: 43.3 },
    //   { name: "上海", data1: 7.9, data2: 9.6 },
    //   { name: "北京", data1: 6.7, data2: 7.5 },
    //   { name: "浙江", data1: 6.1, data2: 6.7 },
    //   { name: "江苏", data1: 5.8, data2: 5.2 },
    //   { name: "吉林", data1: 3.2, data2: 4.4 },
    //   { name: "河北", data1: 3.1, data2: 4.9 },
    //   { name: "河南", data1: 2.7, data2: 3.6 },

    // ];

    return {
      legend: {
        icon: 'circle',
        itemWidth: 10,
        textStyle: { color: "rgb(179,193,255)" },
      },
      grid: {
        top: "13%",
        left: "10%",
        right: "5%",
        bottom: "25%",
      },
      tooltip: {
        formatter: (value: any) => {
          return `${value.name}地区${value.seriesName}:${Number(value.data.value)}`
        }
      },
      xAxis: {
        type: "category",
        axisTick: { show: false },
        axisLine: { show: false },
        inverse: false,
        offset: 10,
        data: list?.map((item: any) => item.name),
        axisLabel: {
          interval: 0,
          color: "#fff",
          rotate: 50
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: { color: "#fff", },
        axisLine: { show: false },
        axisTick: { show: false },
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 50,
          textStyle: false
        },
        {
          type: 'inside',
          start: 0,
          end: 50,
          textStyle: {
            color: "rgba(4, 28, 52, 1)"
          },
        },

      ],
      series: [
        {
          cursor: 'default',
          name: '顶岗',
          type: 'bar',
          itemStyle: {
            barBorderRadius: 3,
            color: {
              type: "linear",
              y: 0, y2: 1,
              colorStops: [
                {
                  color: "#01bef8",
                  offset: 0
                },
                {
                  color: "#007df8",
                  offset: 1
                }
              ]
            }
          },
          data: list?.map((item: any) => ({ ...item, value: item.data1 }))
        },
        {
          cursor: 'default',
          name: '跟岗',
          type: 'bar',
          itemStyle: {
            barBorderRadius: 3,
            color: {
              type: "linear",
              y: 0, y2: 1,
              colorStops: [
                {
                  color: "#f17e3d",
                  offset: 0
                },
                {
                  color: "#f34729",
                  offset: 1
                }
              ]
            }
          },
          data: list?.map((item: any) => ({ ...item, value: item.data2 }))
        }
      ]
    };

  }, [props])

  return (
    <div className={Style.root}>
      <ReactEcharts
        style={{ height: 200 }}
        option={options}
      />
    </div>
  );
}
