import React, {Children, useEffect, useState} from "react";
import style from "./index.module.scss"
import {Form, Input, Button, Checkbox, Select, Row, Col, Table, Divider} from 'antd';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';
import VocationalRow from "../../../components/VocationalRow";
import {useHistory} from "react-router-dom";
import useRequest from "../../../lib/useRequest";
import { findSchoolListBySpecialtyCode } from "../../../service/major";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import {ColumnsType} from "antd/lib/table/interface";
import PopCol7 from "./components/PopCol7";
import PopCol5 from "../SchoolList/components/PopCol5";
import PopCol6 from "../SchoolList/components/PopCol6";


export default function PagePracticeDetailList() {

    useBreadcrumb([{name: "工作页", url: "/"}, {name: "专业信息", url: "/major/list"}, {name: "学校列表"}]);

    const { subjectCode, name } = useQuery<{subjectCode: string, name: string}>()
    const history = useHistory();

    const columns1: ColumnsType<any> = [
        {
            title: "专业大类",
            dataIndex: "col1",
            align:"center",
            width:180
        },
        {
            title: "基地数量",
            dataIndex: "col2",
            align:"center",
            render:(num: number) => <PopCol7 num={num} />
        },
        {
            title: "工位数",
            align:"center",
            dataIndex: "col3",
        },
        {
            title: "设备台套数",
            align:"center",
            dataIndex: "col4",
        },
        {
            title: "成果转化项目数",
            dataIndex: "col5",
            align:"center",
        },
        {
            title: "国家级基地数",
            dataIndex: "col6",
            align:"center",
        },
        {
            title: "省级基地数",
            dataIndex: "col7",
            align:"center",
        },
        {
            title: "师资人数",
            dataIndex: "col8",
            align:"center",
            width: 100
        },
    ];
    const columns2: ColumnsType<any> = [
        {
            title: "基地名称",
            align:"center",
            dataIndex: "col1",
            render: (text: string, data, index) => {return {children: text, props: {
                rowSpan: data.rowSpan
            }}}
        },
        {
            title: "上年接收人数",
            dataIndex: "col2",
            align:"center",
            render: (text: string, data, index) => {return {children: text, props: {
                rowSpan: data.rowSpan
            }}}
        },
        {
            title: "专业",
            dataIndex: "col3",
            align:"center",
        },
        {
            title: "提供岗位数",
            dataIndex: "col6",
            align:"center",
        },
        {
            title: "在校生数",
            dataIndex: "col7",
            align:"center",
        },
        {
            title: "各专业上年接收人数",
            dataIndex: "col4",
            align:"center",
        },
        {
            title: "详情",
            dataIndex: "col5",
            align:"center",
            render: (text: string, data, index) => {return {children: <a rel="noreferrer" href="http://www.lnve.net/lnvesjpt/subsiteIndexBase.html?id=1&type=3" target="_blank">详细信息</a>, props: {
                rowSpan: data.rowSpan
            }}}
        },
    ];
    const columns3: ColumnsType<any> = [
        {
            title: "专业代码",
            dataIndex: "col1",
            align:"center",
        },
        {
            title: "专业名称",
            dataIndex: "col2",
            align:"center",
        },
        {
            title: "专业类",
            dataIndex: "col3",
            align:"center",
        },
        {
            title: "虚拟仿真项目数",
            dataIndex: "col4",
            align:"center",
            render:(num: number) => <PopCol6 num={num} />
        },
        {
            title: "已服务人数",
            dataIndex: "col5",
            align:"center",
        },
    ];
    const dataSource1: any[] = [
        {col1: "农林牧渔大类", col2: 3, col3: 322,col4:212, col5: 2, col6: 1, col7: 1,col8:8},
        {col1: "资源环境与安全大类", col2: 2, col3: 163,col4:132, col5: 1, col6: 0, col7: 0,col8:3},
        {col1: "能源动力与材料大类", col2: 1, col3: 98,col4:84, col5: 0, col6: 0, col7:1,col8:2},
        {col1: "土木建筑大类", col2: 1, col3: 75,col4:43, col5: 1, col6: 0, col7:0,col8:2},
        {col1: "水利大类", col2: 2, col3: 165,col4:65, col5: 1, col6: 1, col7:0,col8:4},
        {col1: "装备制造大类", col2: 1, col3: 85,col4:47, col5: 0, col6: 0, col7:0,col8:2},
    ];

    const dataSource2: any[] = [
        {rowSpan: 2, col1: "机电工程系校外顶岗实习实训基地", col2: "491", col3: "电气自动化技术", col4:182, col5: 342, col6: 21, col7: 215},
        {rowSpan: 0, col1: "机电工程系校外顶岗实习实训基地", col2: "491", col3: "工业过程自动化技术", col4: 390, col5: 342, col6: 18, col7: 478},
        {rowSpan: 1, col1: "船舶工程系渤海船舶重工有限责任公司校外教学性实训基地", col2: "670", col3: "应用化工技术", col4:670, col5: 278, col6: 45, col7: 901},
        {rowSpan: 3, col1: "动力工程系校外实习实训基地", col2: "233", col3: "精细化工技术", col4:32, col5: 782, col6: 5, col7: 53},
        {rowSpan: 0, col1: "动力工程系校外实习实训基地", col2: "233", col3: "石油化工技术", col4:98, col5: 782, col6: 9, col7: 196},
        {rowSpan: 0, col1: "动力工程系校外实习实训基地", col2: "233", col3: "工业分析技术", col4:103, col5: 782, col6: 12, col7: 288},
        {rowSpan: 1, col1: "招商局重工（江苏）有限公司实习实训基地", col2: "153", col3: "数控技术", col4:153, col5: 123, col6: 15, col7: 302},
        {rowSpan: 2, col1: "电气工程系校外顶岗实习实训基地", col2: "372", col3: "工业自动化仪表", col4:143, col5: 45, col6: 13, col7: 211},
        {rowSpan: 0, col1: "电气工程系校外顶岗实习实训基地", col2: "372", col3: "工业机器人技术", col4:229, col5: 45, col6: 11, col7: 315},
        {rowSpan: 4, col1: "中船澄西船舶修造有限公司实习实训基地", col2: "785", col3: "船舶机械工程技术", col4:139, col5: 251, col6: 8, col7: 206},
        {rowSpan: 0, col1: "中船澄西船舶修造有限公司实习实训基地", col2: "785", col3: "船舶通信与导航", col4:262, col5: 251, col6: 30, col7: 388},
        {rowSpan: 0, col1: "中船澄西船舶修造有限公司实习实训基地", col2: "785", col3: "船舶电气工程技术", col4:184, col5: 251, col6: 16, col7: 279},
        {rowSpan: 0, col1: "中船澄西船舶修造有限公司实习实训基地", col2: "785", col3: "海洋工程技术", col4:200, col5: 251, col6: 19, col7: 298},
        {rowSpan: 1, col1: "中铁宝桥集团有限公司实习实训基地", col2: "134", col3: "公路机械化施工技术", col4:134, col5: 251, col6: 10, col7: 196},
    ];
    const dataSource3: any[] = [
        {col1: "570201", col2: "电气自动化技术", col3: "农业类",col4:2, col5: 326},
        {col1: "570207", col2: "工业过程自动化技术", col3: "石油与天然气类",col4:1, col5: 167},
        {col1: "560302", col2: "应用化工技术", col3: "煤炭类",col4:1, col5: 982},
        {col1: "570204", col2: "精细化工技术", col3: "金属与非金属矿类",col4:1, col5: 164},
        {col1: "570203", col2: "石油化工技术", col3: "资源勘查类",col4:1, col5: 386},
    ];

    return (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.contentHeader}>
                    <span>{name}</span>
                    <div className={style.titlebutton}>
                        <Button onClick={() => history.push("/practice/list")}>返回</Button>
                    </div>
                </div>

                <div className={style.container}>
                    {name === "校内实训基地"  && <Table columns={columns1} dataSource={dataSource1} pagination={false} />}
                    {name === "校外顶岗实习基地"  && <Table bordered columns={columns2} dataSource={dataSource2} pagination={false} />}
                    {name === "虚拟仿真实验教学" && <Table columns={columns3} dataSource={dataSource3} pagination={false} />}
                </div>
            </div>
        </div>
    );
}
