import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts, { EChartOption } from "echarts";
import { inPlan, getNormalData, findOptions } from "../../../../../service/basic";
import useRequest from "../../../../../lib/useRequest";

var color = [
    '#38ba76',
    '#4467f4',
    '#ee167b',
    '#8f3ffd',
    '#6e3ffd',
    '#5757f2',
    '#488bf0',
    '#448ff4',
    '#38baa8',
    '#c7b30d',
    '#e59d27',
    '#e55b26',
];

const Options: EChartOption<EChartOption.Series> = {
    // title: {
    //     text: '2020年辽宁省高职院校就业情况',
    //     left: 'center'
    // },
    color: color,
    tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        },
        // @ts-ignore
        formatter: function (data: any) {
            if (data.length !== 0)
            {
                for (let i = 0; i < data.length; i++)
                {
                    let title = data[i].axisValue + ':';
                    return title + '</br>计划人数:' + data[i].value + '</br>' + '实际执行人数:' + data[i + 1].value;
                }
            }
        }
    },
    legend: {
        top: "3%",
        left: "75%",
        bottom: "0%",
        itemWidth: 8,
        itemHeight: 8,
        show: false,
        itemGap: 12,
        textStyle: {
            color: "#fff",
        }
    },
    grid: {
        top: '3%',
        left: '3%',
        right: '4%',
        bottom: '30',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        axisLine: {
            lineStyle: {
                color: '#fff',
                width: 2
            }
        },
        data: ['顶岗实习', '跟岗实习'],
    },
    yAxis: {
        axisLine: {
            lineStyle: {
                color: '#fff',
                width: 2
            }
        },
        splitLine: { //分割线配置
            lineStyle: {
                color: "#AAAAAA56",
            }
        },
    },
    series: [{
        cursor: 'default',
        name: '计划人数',
        type: 'bar',
        barMaxWidth: 30,
        data: [15432, 11981],
    },
    {
        cursor: 'default',
        name: '实际执行人数',
        type: 'bar',
        barMaxWidth: 30,
        data: [12619, 7076],
    }
    ]
};
interface IProps {
    title: string;
    style?: any;
}
export default function Index ({ title, style }: IProps) {
    const [data] = useRequest(() => inPlan());//实习计划执行情况
    // const [data] = useRequest(() => findOptions("chart_teacher_type_columnar_2020"));
    const opt = useMemo < any > (() => {
        if (data)
        {
            return {
                color: color,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    formatter: "{b} : {c} ({d}%)"
                },

                series: [
                    {
                        type: 'pie',
                        radius: '70%',
                        // center: ['50%', '50%'],
                        width: '100%',
                        data: [
                            { value: data.jhdgrs + data.jhggrs, name: '计划人数' },
                            { value: data.sjdgrs + data.sjggrs, name: '实际执行人数' }
                        ]
                    }
                ]
            }
            //   return {
            //     title: {
            //       text: title,
            //       left: 'center',
            //       top: 0
            //     },
            //     color: color,
            //     ...plan,
            //     legend: { show: false },
            //     tooltip: {
            //       trigger: 'axis',
            //       axisPointer: { // 坐标轴指示器，坐标轴触发有效
            //         type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            //       },
            //       // @ts-ignore
            //       formatter: function (data: any) {
            //         if (data.length !== 0)
            //         {
            //           for (let i = 0; i < data.length; i++)
            //           {
            //             let title = data[i].axisValue + ':';
            //             return title + '</br>计划人数:' + data[i].value + '</br>' + '实际执行人数:' + data[i + 1].value;
            //           }
            //         }
            //       }
            //     },
            //     grid: {
            //       top: '3%',
            //       left: '3%',
            //       right: '4%',
            //       bottom: '30',
            //       containLabel: true
            //     },
            //     series: [{
            //       cursor: 'default',
            //       name: '计划人数',
            //       type: 'bar',
            //       barMaxWidth: 30,
            //       data: [plan.jhdgrs, plan.jhggrs],
            //     },
            //     {
            //       cursor: 'default',
            //       name: '实际执行人数',
            //       type: 'bar',
            //       barMaxWidth: 30,
            //       data: [plan.sjdgrs, plan.sjggrs],
            //     }
            //     ],
            //     xAxis: {
            //       type: 'category',
            //       axisLine: {
            //         lineStyle: {
            //           color: '#fff',
            //           width: 2
            //         }
            //       },
            //       data: ['顶岗实习', '跟岗实习'],
            //     },
            //     yAxis: {
            //       axisLine: {
            //         lineStyle: {
            //           color: '#fff',
            //           width: 2
            //         }
            //       },
            //       splitLine: { //分割线配置
            //         lineStyle: {
            //           color: "#AAAAAA56",
            //         }
            //       },
            //     },
            //   };
            // const xAxis = Object.assign({}, data.option.xAxis, { axisLabel: { rotate: 20 } });
            // return {
            //   title: {
            //     text: title,
            //     left: 'center',
            //     top: 0
            //   },
            //   ...data.option,
            //   ...{
            //     series: data.option.series.map((item: any) => {
            //       return {
            //         ...item,
            //         itemStyle: {
            //           normal: {
            //             color: function (params: any) {
            //               var colorList = ["#3398db", "#434348", "#90ed7d", "#f7a35c", "#61a0a8", "#61a0a8", "#91c7ae", "#2f4554"];
            //               return colorList[params.dataIndex]
            //             }
            //           }
            //         }
            //       }
            //     })
            //   },
            //   legend: { show: false },
            //   xAxis,
            //   tooltip: {
            //     trigger: 'item',
            //     formatter: function (params: any) {
            //       return `${params.seriesName} <br />${params.marker}${params.name}: ${parseInt(params.value)}人`;
            //     }
            //   },
            //   grid: {
            //     ...data.option.grid,
            //     left: '15%'
            //   }

            // };
        } else
        {
            return {};
        }
    }, [data]);
    return (
        <ReactEcharts theme={"theme"} style={style} option={opt} />
    )
}
Index.defaultProps = {
    title: ''
}
