import React, { useEffect, useState, useMemo } from "react";
import Province from "./components/Province";
import {
  areaCourseList, areaCourseListBySchool,
  constMoney, courseAreaMap, getDoubleLevelList,
  getNationalLevelCount,
  getProvincialLevelCount,
  IPoint,
  getSchoolInfoList, IDataTypes,
  INewDataTypes, ISchoolInfo, newStudentCount, schoolCount, secondTable, teacherCount
} from "../../../../../service/chart";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import cityCoordData from "../../../../../assets/cityCoord";
import style from "./index.module.scss";
import { getNormalData } from "../../../../../service/basic";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
  info: any
}

export default function FirstScreen ({ showTimeLine, width, height, info }: IProps) {

  const [cityName, setCityName] = useState(""); // 点击地图返回城市名
  // 第一屏
  const [schoolData] = useRequest(() => schoolCount()); // 学校数量
  const [newStuCountData] = useRequest(() => newStudentCount()); // 招生人数;
  const [teacherData] = useRequest(() => teacherCount()); // 教师数量
  const [constMoneyData] = useRequest(() => constMoney()); // 投入情况
  const [firstData, setFirstData] = useState < INewDataTypes[] > ([]);
  const [schoolList, setSchoolList] = useState < any > (null);

  // const [requestSchoolList] = useRequest(() => areaCourseList(cityName), {}, [cityName]);
  const [requestSchoolList] = useRequest(() => getNormalData < ISchoolInfo[] > ('55023e76-a089-4619-86c0-e93882dde8c8',
  {
    termAbb: info && info.termAbb ? info.termAbb : '',
    zhou: info && info.zhou ? info.zhou : '',
    xingqi: info && info.xingQi ? info.xingQi : '',
    selection: info && info.jie ? info.jie : '',
    name: cityName
  }), {},
    [cityName]); // 点击区域弹出框中的数据

  const [schoolListSpecialFn, { loading, data: schoolListSpecial }] = useRequestFn(getDoubleLevelList);
  const [blueCountData] = useRequest(() => getProvincialLevelCount());

  const [areaName, setAreaName] = useState('')
  const [schoolName, setSchoolName] = useState('')
  const [time, setTime] = useState('')

  // 获取第二个表格的数据
  // const [tableData] = useRequest(() => secondTable(areaName, schoolName, time), {}, [areaName, schoolName, time])
  const [onTableData1, tableData1] = useRequestFn(getNormalData)
  const [tableData, setTableData] = useState < any > (null)
  // 获取第二个表格的数据
  let onTableData = async (val: any) => {
    let res = await onTableData1('f3acbdeb-c77d-4a48-a0cf-e70678f05151', {
      termAbb: info && info.termAbb ? info.termAbb : '',
      zhou: info && info.zhou ? info.zhou : '',
      xingqi: info && info.xingQi ? info.xingQi : '',
      selection: info && info.jie ? info.jie : '',
      name: info && info.name ? info.name : '',
      schoolId: val.schoolId ? val.schoolId : ''
    })
    setTableData(res)
  }

  // 获取红色数字
  // const [redCountData] = useRequest(() => courseAreaMap(objData), {}, [objData]);

  const [redData, redCountData] = useRequestFn(() => getNormalData < IPoint[] > ('3b86d406-4dac-4fac-a2f0-fe8f3e3350da', {
    termAbb: info && info.termAbb ? info.termAbb : '',
    zhou: info && info.zhou ? info.zhou : '',
    xingqi: info && info.xingQi ? info.xingQi : '',
    selection: info && info.jie ? info.jie : ''
  })) //获取地图信息

  useEffect(() => {
    redData()
  }, [info])

  const [areaCourseListData] = useRequest(() => areaCourseListBySchool()); // 实时开课分布图-辽宁地图 弹出列表(学校参数)


  // useEffect(() => {
  // }, [tableData])


  useEffect(() => {
    if (schoolData && newStuCountData && teacherData && constMoneyData)
    {
      const data1 = formatData(schoolData, '学校数量');
      const data2 = formatData(newStuCountData, '招生人数');
      const data3 = formatData(teacherData, '教师人数');
      const data4 = formatData(constMoneyData, '投入情况');
      setFirstData([...data1, ...data2, ...data3, ...data4]);
    }
  }, [schoolData, newStuCountData, teacherData, constMoneyData]);

  function formatData (data: IDataTypes[], name: string) {
    return data.map(item => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseFloat(item.value),
        circleColor: item.circleColor ? item.circleColor : '#f00',
        areaColor: item.areaColor ? item.areaColor : '#f8f8f8',
        year: name
      }
    });
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);

    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  //点击获取详情
  const [del, onDel] = useRequestFn(getNormalData)
  const [delData, setDelData] = useState < any > (null)
  async function getSchoolList (val: string, level: string) {
    let res = await del('1c230bb3-9117-4998-8831-f829a4fdafea', {
      termAbb: info && info.termAbb ? info.termAbb : '',
      zhou: info && info.zhou ? info.zhou : '',
      xingqi: info && info.xingQi ? info.xingQi : '',
      selection: info && info.jie ? info.jie : '',
      name: val
    })
    setSchoolList(res);
    // if (!level) setCityName(val);
    // if (level)
    // {
    //   schoolListSpecialFn(val, level);
    // }
  }

  function onClean () { // 清除弹出层表单数据
    setSchoolList([]);
  }

  // 获取参数
  function getTable (areaName: string, schoolName: string) {
    setAreaName(areaName);
    setSchoolName(schoolName)
    let time = formateDate();
    setTime(time)
  }
  // 初始化参数
  function formateDate () {
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const strDate = date.getDate().toString().padStart(2, '0');
    const starHours = date.getHours().toString().padStart(2, '0');
    const starMinutes = date.getMinutes().toString().padStart(2, '0');
    const starSeconds = date.getSeconds().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${strDate} ${starHours}:${starMinutes}:${starSeconds}`;
  }
  return (
    <div>
      {firstData && (
        <Province tableType={1} name='辽宁' width={width} height={height} data={firstData}
          className={style.chart}
          year={["学校数量", "招生人数", "教师人数", "投入情况"]}
          geoColor='27, 94, 193'
          schoolList={schoolList}
          changeCityName={(val, levelName) => {
            getSchoolList(val, levelName)
          }}
          pointsBlue={blueCountData?.map(item => {
            return {
              areaCode: item.areaCode,
              schCount: item.schCount
            }
          })}
          pointsRed={redCountData && redCountData.data?.map((item: any) => {
            return {
              areaCode: item.areaCode ? item.areaCode : item.name,
              schCount: item.schCount ? item.schCount : item.value
            }
          }) || []}
          schoolListSpecial={schoolListSpecial || []}
          showTimeLine={showTimeLine}
          onClean={onClean}
          getTable={getTable}
          listData={tableData}
          onTableData={(val) => { onTableData(val) }}
          areaCourseListData={areaCourseListData || []}
        />
      )}
    </div>
  )
}

FirstScreen.defaultProps = {
  showTimeLine: true,
  width: 960,
  height: 800
}
