import style from "./index.module.scss";
import { FaRegUser, FaRegUserCircle } from "react-icons/fa";
import { Button, Form, Input, message } from "antd";
import { AiTwotoneLock } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { login, getVerifyCode } from "../../../../../service/login";
import useDevice from "../../../../../lib/useDevice";
import { any } from "underscore";
import { errorMessage } from "../../../../../lib/utils";


export default function LoginForm () {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState();
    const [keys, setDatas] = useState();

    // 登陆验证
    async function verifyCode () {

        try
        {
            let res = await getVerifyCode()
            let data = JSON.parse(res?.message)
            setImg(data.code)
            setDatas(data.key)
        } catch {

        }
    }

    useEffect(() => {
        verifyCode()
    }, [])

    async function onFinish (data: any) {
        if (!data.username)
        {
            return message.error("请输入用户名");
        }

        if (!data.password)
        {
            return message.error("请输入密码");
        }
        try
        {
            let res: any = await login(data.username, data.password, keys, data.code)
            localStorage.setItem("LNDSJ", res)
            if (res)
            {
                setLoading(true);
                setTimeout(() => history.push("/"), 1500);
                message.success('登录成功');
            } else
            {
                message.error(res.message);
            }

        } catch (e)
        {

            // message.error(errorMessage(e));
        }


    }

    //默认获取验证码
    function onLoad () {
        verifyCode()
    }

    return (
        <div className={style.root}>
            <div className={style.pic}>
                <FaRegUser /><br />用户登录
            </div>

            <Form className={style.form} onFinish={onFinish} >
                <Form.Item name="username" initialValue="">
                    <Input prefix={<FaRegUserCircle />} placeholder="请输入用户名称" />
                </Form.Item>

                <Form.Item name="password" initialValue="">
                    <Input type="password" prefix={<AiTwotoneLock />} placeholder="请输入用户密码" />
                </Form.Item>

                <Form.Item style={{ width: '60%' }} name="code" initialValue="">
                    <Input type="code" prefix={<AiTwotoneLock />} placeholder="请输入验证码" />
                </Form.Item>
                <img className={style.codeImg} onClick={() => { onLoad() }} src={img} alt="" />

                <Button type="primary" htmlType="submit" className={style.submit} loading={loading}>
                    登录
                </Button>
            </Form>
        </div>
    );
}
