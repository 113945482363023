import React from 'react';
import style from './style.module.scss';

interface PopupProps{
    show: boolean;
    onClick: (e:React.MouseEvent) => void;
    children?: React.ReactNode;
    width?: string
}

export default function Popup({show=false,onClick, children, width='1300px'}: PopupProps) {
    return (
        <div className={style.root} style={{display: !show ? 'none': 'block'}}>
            <div className={style.childrenWrapper}>
                <div className={style.closeBtn} onClick={(e) => onClick(e)} />
                <div className={style.position} style={{width}}>
                {children}
                </div>
            </div>
        </div>
    )
}
