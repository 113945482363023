import axios from "axios";
import qs from "qs";


/**
 * @name 就业去向统计
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
export function jytj01(subject_id: string) {
    return axios.post<any>(`/lndc/jytj01`, qs.stringify({subject_id}))
        .then(res => res.data);
}


/**
 * @name 就业生人数
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function countGraduateStudent() {
    return axios.post<any>(`/lndc/countGraduateStudent`)
        .then(res => res.data.message);
}

/**
 * @name 对接农业人数
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function countDockingAgriculture() {
    return axios.post<any>(`/lndc/countDockingAgriculture`)
        .then(res => res.data.message);
}

/**
 * @name 对接服务生人数
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function countDockingWaiter() {
    return axios.post<any>(`/lndc/countDockingWaiter`)
        .then(res => res.data.message);
}

/**
 * @name 对接工业人数
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function countIndustry() {
    return axios.post<any>(`/lndc/countIndustry`)
        .then(res => res.data.message);
}

/**
 * @name 产业人才去向柱状图
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function histogramIndustryTalentWhereabouts() {
    return axios.post<any>(`/lndc/histogramIndustryTalentWhereabouts`)
        .then(res => res.data.data);
}


interface pieChartIndustryDockingCompanyData {
    name: string;
    value: string;
}

/**
 * @name 产业对接企业数量分布饼图
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function pieChartIndustryDockingCompany() {
    return axios.post<SuccessData<pieChartIndustryDockingCompanyData[]>>(`/lndc/pieChartIndustryDockingCompany`)
        .then(res => res.data.data);
}

/**
 * @name 三层桑基图
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function sankeyDiagram() {
    return axios.post<any>(`/lndc/sankeyDiagram`)
        .then(res => {
            return res.data.data
        });
}

/**
 * @name 这还有个表
 * @description http://172.16.1.23/project/88/interface/api/5418
 */
 export function hereIsATable(company:string,school:string) {
    return axios.post<any>(`/lndc/hereIsATable`,qs.stringify({company,school}))
        .then(res => res.data.data);
}

export function ipcsTable() {
    return axios.post<any>(`/lndc/ipcsTable`)
        .then(res => res.data.data);
}
