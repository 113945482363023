import ReactEcharts from "echarts-for-react";
import React, { useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { findSingleAxisChart, SingleAxisChart } from "../../../../../service/chart";
import style from "./index.module.scss";
import classNames from "classnames";
import { getNormalData } from "../../../../../service/basic";

// const cities = [
//     "沈阳市",
//     "大连市",
//     "抚顺市",
//     "本溪市",
//     "丹东市",
//     "锦州市",
//     "营口市",
//     "阜新市",
//     "辽阳市",
//     "盘锦市",
//     "铁岭市",
//     "朝阳市",
//     "葫芦岛市",
// ]

export default function Chart2 () {
    // const [ data ] = useRequest(findSingleAxisChart);
    const [data] = useRequest(() => getNormalData < SingleAxisChart > ("a4371ab3-d220-4a4c-9ca6-a56a89ddd427")); // 各专业大类区域分布情况
    const [cities, setCities] = useState(['交通运输大类'])
    const [current, setCurrent] = useState(cities[0])

    // const rows = options?.rows || [];


    // const columns = options?.columns ? options?.columns : [];

    // const data = (options?.dataList || []);

    // const option: any = {
    //     tooltip: {
    //         position: 'top',
    //         formatter: function (params: any) {
    //             const city = columns[params.data[2]];
    //             const subject = params.name;
    //             const num = params.value[1];

    //             return `${city} ${subject}大类专业数: ${num} `
    //         }
    //     },
    //     title: [],
    //     singleAxis: [],
    //     series: [],
    //     xAxis: [],
    // };

    // function findCityIndex(cityName: string) {
    //     return cities.findIndex(item => item === cityName);
    // }

    // columns.forEach(function (city, idx) {

    //     const idx2 = findCityIndex(city);

    //     option.title.push({
    //         textBaseline: 'middle',
    //         top: (((idx2 + 0.5) * 100 / 15) + 5) + '%',
    //         text: city
    //     });

    //     option.singleAxis.push({
    //         left: 150,
    //         type: 'category',
    //         boundaryGap: false,
    //         data: rows,
    //         top: ((idx2 * 100 / 15 + 5) + 5) + '%',
    //         height: (100 / 14 - 10) + '%',
    //         axisLabel: {
    //             show: idx2 === columns.length - 1,
    //             fontSize: 16,
    //             lineHeight: 40
    //         },
    //         axisLine: {
    //             lineStyle: {
    //                 opacity: 0
    //             }
    //         },
    //         splitLine: {
    //             show: false
    //         },
    //         axisTick: false,
    //     });

    //     option.series.push({
    //         singleAxisIndex: idx,
    //         coordinateSystem: 'singleAxis',
    //         type: 'scatter',
    //         data: [],
    //         symbolSize: function (dataItem: any) {
    //             return Math.sqrt(dataItem[1]) * 5;
    //         }
    //     });
    // });

    // data.forEach(function (dataItem) {
    //     option.series[dataItem[0]].data.push([dataItem[1], dataItem[2], dataItem[0]]);
    // });

    const newData = useMemo(() => {
        if (!data) return
        const { columns, dataList, rows }: any = data;
        setCities(columns)
        const list = columns.map((item: any, index: any) => {
            return {
                city: item,
                data: dataList.filter((item: any[]) => item[0] === index).map((item: any[]) => item[2])
            }
        })
        return list

    }, [data])

    const currentData = useMemo(() => {

    }, [])

    const options: any = useMemo(() => {
        return {
            tooltip: {
                formatter: `{b}(专业布点数)：{c}`
            },
            xAxis: {
                type: 'value',
                show: true,
                axisTick: {
                    lineStyle: {
                        color: "#ededed"
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: "#ededed"
                    }
                },
                splitLine: { show: false },
                axisLabel: {
                    color: "#666"
                }
            },
            grid: {
                top: 0,
                left: 150,
                right: 0,
                bottom: 20,
                textAlign:'left'
            },
            yAxis: {
                type: 'category',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#ededed"
                    }
                },
                axisLabel: {
                    color: "#666",
                    show: true,
                    interval: 0,
                    //X轴设置字数限制
                    formatter: function (value: string) {
                        if (value.length > 8)
                        {
                            return value.substring(0, 8) + "...";
                        } else
                        {
                            return value;
                        }
                    }
                },
                splitLine: { show: false },
                axisTick: { show: false },
                inverse: true,
                data: data?.rows
            },
            series: [
                {
                    cursor: 'default',
                    name: '数据',
                    type: 'bar',
                    barWidth: 16,
                    itemStyle: {
                        barBorderRadius: 3,
                        color: {
                            colorStops: [
                                {
                                    color: "#154bff",
                                    offset: 0
                                },
                                {
                                    color: "#6eb8ff",
                                    offset: 1
                                }
                            ]
                        }
                    },
                    backgroundStyle: {
                        color: "#dfe5f1",
                        barBorderRadius: 3
                    },
                    data: newData?.filter((item: { city: any; }) => item.city === current)[0].data
                    // data: []
                }
            ]
        };
    }, [current, data?.rows, newData])

    return (
        <div>
            <div className={style.layout}>
                <div className={style.cities}>
                    {cities.map(item => {
                        return <div key={item} onClick={() => setCurrent(item)} className={classNames(style.city, item === current && style.current)}>{item}</div>
                    })}
                </div>

                <div className={style.chart}>
                    <ReactEcharts option={options} notMerge={true} lazyUpdate={true} style={{ height: '100%' }} />
                </div>
            </div>
        </div>
    );
}
