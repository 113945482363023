import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { Form, Input, Button, Checkbox, Select, Row, Col } from 'antd';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';
import VocationalRow from "../../../components/VocationalRow";
import { useHistory } from "react-router-dom";
import useRequest from "../../../lib/useRequest";
import { findSchoolListBySpecialtyCode } from "../../../service/major";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getDefaultCompilerOptions } from "typescript";
import { getNormalData } from "../../../service/basic";

export default function SchoolList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "专业信息", url: "/major/list" }, { name: "学校列表" }]);
  const { subjectCode, name } = useQuery < { subjectCode: string, name: string } > ()

  const history = useHistory();

  // const [ data ] = useRequest(() => findSchoolListBySpecialtyCode(subjectCode), {}, [subjectCode])
  const [data] = useRequest(() => getNormalData < any > ('57714f6e-8371-45d3-adc0-53b3709c30cc', { subjectCode }))// 专业信息/学校列表

  function gotoPath (id: string) {
    history.push(`/training/special?id=${id}`);
  }

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.contentHeader}>
          <span>{name}（{subjectCode}）</span>
        </div>
        <div className={style.container}>
          <table className={style.customers}>
            <tr>
              <th>高校代码</th>
              <th>高校名称</th>
              <th>专业方向</th>
              <th>学习年限</th>
              <th>入学要求</th>

              <th>查看详情</th>
            </tr>
            {data?.map((item: any) => (
              <tbody key={item.schoolCode}>
                <tr>
                  <td rowSpan={item.child.length + 1}>{item.schoolCode}</td>
                  <td rowSpan={item.child.length + 1} style={{ borderRight: "1px solid #e9e9e9" }}>{item.schoolName}</td>
                </tr>
                {item.child.map((item: any) => (
                  <tr className={style.alt} key={item.id}>
                    <td>{item.subjectDirection}</td>
                    <td>{item.restYear}</td>
                    <td>{item.inRequirementsName}</td>
                    <td><Button type="link" style={{ padding: "0 5px", color: "#6c97d2", height: "auto", fontSize: 12 }} onClick={() => gotoPath(item.id)}>点击查看详情</Button></td>
                  </tr>
                ))}
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}
