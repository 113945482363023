import Menu from "./menu";

const Config = {
  BaseUrl: process.env.REACT_APP_BaseUrl!,
  Menu,
  yearsArr: [
    {
      name: '2020-2021学年',
      value: '1'
    },
    { 
      name: '2021-2022学年',
      value: '2'
    },
  ]
};

export default Config;
