//@ts-nocheck
import React, { useMemo, useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { pieChartIndustryDockingCompany } from "../../../../../service/employment";
import useRequest from "../../../../../lib/useRequest";
import _ from "lodash";
import { findOptions, getShuangGao, todayCourse } from "../../../../../service/chart";
import style from "./index.module.scss";
import { EChartOption } from "echarts";
import { getNormalData } from "../../../../../service/basic";

const data: { name: string, value: string }[] = [
  { name: "金融业", value: "5689" },
  { name: "房地产业", value: "5387" },
  { name: "交通运输、仓储和邮政业", value: "5213" },
  { name: "住宿和餐饮业", value: "4787" },
  { name: "公共管理、社会保障和社会组织", value: "4612" },
  { name: "农、林、牧、渔业", value: "3977" },
  { name: "制造业", value: "3794" },
  { name: "卫生和社会工作", value: "3412" },
  { name: "国际组织", value: "3351" },
  { name: "居民服务、修理和其他服务业", value: "3056" },
  { name: "建筑业", value: "2990" },
  { name: "批发和零售业", value: "2770" },
  { name: "教育", value: "2690" },
  { name: "文化、体育和娱乐业", value: "2652" },
  { name: "水利、环境和公共设施管理业", value: "2587" },
  { name: "电力、热力、燃气及水生产和供应", value: "2412" },
  { name: "科学研究和技术服务业", value: "2387" },
  { name: "租赁和商业服务业", value: "2205" },
  { name: "采矿业", value: "2106" },
];

export default function Chart6 (props) {

  //顶岗实习行业薪资情况
  const [internship] = useRequest(() => getNormalData < any > ('cf8eb911-6f2d-405a-8d85-156ce09d36e2'))
  const [inData, setInData] = useState < any > (null)
  useEffect(() => {
    if (internship)
    {
      let arr: any = []
      internship.map((item: any) => {
        arr.push({
          name: item.belong_hy,
          value: Number(item.value).toFixed(2)
        })
      })
      setInData(arr)
    }
  }, [internship])



  const color = [
    "rgb(238,22,123)",
    "rgb(218,182,6)",
    "rgb(6,218,144)",
    "rgb(68,103,244)",
    "#f2719a",
    "#fca4bb",
    "#f59a8f",
    "#fdb301",
    "#57e7ec",
    "#cf9ef1",
  ];

  const Options: EChartOption<EChartOption.Series> = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      }
    },

    grid: {
      top: 0,
      left: '3%',
      right: '10%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      gridIndex: 0,//x 轴所在的 grid 的索引
      show: false
    },
    yAxis: {
      type: 'category',
      data: inData?.map((item: any) => item.name),
      inverse: true,
      splitLine: 'none',
      axisTick: 'none',
      axisLine: 'none',
      axisLabel: {
        textStyle: {
          color: '#FFFFFF',
          fontSize: '12',
        }
      }
    },
    axisLabel: {
      color: "#000",
      interval: 0,
      formatter: function (value: string) {
        if (value.length > 7)
        {
          return value.substring(0, 7) + "...";
        } else
        {
          return value;
        }
      }
    },
    series: [
      {
        cursor: 'default',
        type: 'bar',
        label: {
          show: true,
          position: 'right',
        },
        barWidth: 15,
        data: inData?.map((item: any) => item.value),
        itemStyle: {
          color: function (params: any) {
            if (params.dataIndex === 0) return color[0];
            return color[params.dataIndex % 10 + 1]
          }
        }
      }
    ]
  };

  return (
    <div className={style.root}>
      <ReactEcharts style={{ height: `${inData ? inData.length * 60 : 400}px` }} option={Options} />
    </div>
  );
}
