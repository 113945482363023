import { useState } from "react"
import useRequest from "../../../../../../../lib/useRequest";
import { getNormalData } from "../../../../../../../service/basic";
import style from "./TitleCount.module.scss"
import schoolCount from "../../../../../../../assets/image/schoolCount.png"
import majorCount from "../../../../../../../assets/image/majorCount.png"
import typeCount from "../../../../../../../assets/image/typeCount.png"
import studentCount from "../../../../../../../assets/image/studentCount.png"

interface TitleProps {
    code: string;
    file: string,
}
export default function TitleCount(props: TitleProps) {

  const {code, file} = props
  const [ffcy,setFfcy] = useState('')

  const [modalData] = useRequest(() => getNormalData < any > ('15d3f21d-c06d-11ec-ba43-005056958024',{code,file,ffcy}),{},[file, code])//产业人才对接统计表
    return (
        <div className={style.titleCount}>
            <div className={style.titleCountEtem}>
                <img src={schoolCount} alt="" />
                <p>学校总数 </p>
                <span>{modalData && modalData[0].count}</span>
            </div>
            <div className={style.rule}></div>
            <div className={style.titleCountEtem}>
                <img src={majorCount} alt="" />
                <p>专业总数</p>
                <span>{modalData && modalData[1].count}</span>
            </div>
            <div className={style.rule}></div>
            <div className={style.titleCountEtem}>
                <img src={typeCount} alt="" />
                <p>专业布点数</p>
                <span>{(modalData && modalData[2].count) || 0}</span>
            </div>
            <div className={style.titleCountEtem}>
                <img src={studentCount} alt="" />
                <p>学生总数</p>
                <span>{(modalData && modalData[3].count) || 0}</span>
            </div>
        </div>
    )
}
