import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactEcharts from 'echarts-for-react';
import {EChartOption, EChartsResponsiveOption} from "echarts";
import 'echarts/map/js/province/liaoning.js';

import style from './index.module.scss';
import Popup from "../../../Components/Popup";
import ThemeData from '../../../../../../src/assets/walden.project.json';
import {ILevel, ISchoolInfo} from "../../../../../service/chart";
import _ from 'lodash';
import doNotReRender from "../../../../../lib/doNotReRender";

// 不会重新渲染的图表组件
const PureReactEcharts = doNotReRender(ReactEcharts);

interface LiaoNingMapProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: number;
    height?: number;
    year: string[];
    tableType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList?: ISchoolInfo[]
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: string; schCount: string }[]; // 国家级院校
    schoolListSpecial?: ILevel[]; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
}

interface DataArrTypes {
    [key: string]: {
        values: number[],
    }
}

export default function LiaoNingMap({
                                     name, data, className, year,
                                     changeCityName, schoolList,
                                     geoColor = '46, 199, 201',
                                     tableType = 1, width = 1920,
                                     height = 1000, special = false,
                                     pointsBlue, pointsRed,
                                     schoolListSpecial,
                                     showTimeLine,
                                     onClean
                                 }: LiaoNingMapProps) {
    const [years, setYears] = useState<string[]>([]);
    const echartsRef = useRef<any>(null);
    const [cityNameArr] = useState<string[]>([
        '沈阳市', '大连市', '鞍山市', '抚顺市', '本溪市', '丹东市',
        '锦州市',
        '营口市', '阜新市', '辽阳市', '盘锦市', '铁岭市', '朝阳市', '葫芦岛市']);
    const [dataArr, setDataArr] = useState<DataArrTypes>({});

    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');

    useEffect(() => {
        if (data) {
            // 城市名称 年份
            const yearArr: string[] = [];
            const geoRegions: any = []; // 城市颜色

            data.map(item => {
                if (!yearArr.includes(item.year)) yearArr.push(item.year);
            });

            cityNameArr.map((city, index) => {

                geoRegions.push({
                    name: city,
                    itemStyle: {
                        normal: {
                            areaColor: `rgba(${geoColor}, 0.8)`
                        }
                    }
                });
            });

            setYears(yearArr);

            initDataArr(cityNameArr);

        }
    }, [data]);

    // 按年份 dataArr数据
    function initDataArr(cityNameArr: string[]) {
        const dataArr: DataArrTypes = {};
        year.map(year => {
            dataArr[year] = {values: []}
            cityNameArr.map(city => {
                data.map(item => {
                    if (item.year === year && item.name === city) {
                        dataArr[year]['values'].push(item.value);
                    }
                })
            });
        });
        setDataArr(dataArr);
    }

    // 柱形及年份线 位置大小调整
    const barOption = {
        left: showTimeLine ? '10%' : '-100%',
        bottom: '0%',
        width: '80%'
    };

    // timeline 文字大小
    const timelineLabel = special ? {
        label: {
            rotate: '20',
            align: 'center',
            color: '#ffffff',
        }
    } : {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                    fontSize: 16
                }
            }
        }
    };

    const timeline = useMemo(() => {
        return {
            title: {
                text: '',
                subtext: ''
            },
            toolbox: {
                show: false,
            },
            animation: true,
            animationDuration: 1000,
            animationEasing: 'cubicInOut',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'cubicInOut',
            timeline: {
                show: showTimeLine ? true : false,
                data: year,
                axisType: 'category',
                autoPlay: showTimeLine ? true : false,
                controlStyle: {
                    showPlayBtn: false,
                    showNextBtn: false,
                    showPrevBtn: false,
                    normal: {
                        color: '#666',
                        borderColor: '#666'
                    },
                    emphasis: {
                        color: '#aaa',
                        borderColor: '#aaa'
                    },
                    symbolSize: 10,
                    lineStyle: {
                        color: '#555'
                    },
                    checkpointStyle: {
                        borderColor: '#777',
                        borderWidth: 2
                    },
                },
                ...timelineLabel,
                // top: '700',
                ...barOption,
                bottom: 0,
                height: '50',
                playInterval: 7000,
            },
            // bar
            grid: {
                // top: '510',
                ...barOption,
                bottom: "100",
                height: '150'
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    color: '#fff',
                    // rotate: 40
                    fontSize: 10
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        width: 100
                    }
                },
                splitLine: {
                    show: false
                },
                data: cityNameArr,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#fff',
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                }
            },
            // bar end
            series: [
                {
                    type: 'bar',
                    data: dataArr[years[0]],
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: ThemeData.color[0]
                    }

                }
            ]
        }
    }, [dataArr, years]);

    const optionsArr = useMemo(() => {
        const optArr: any[] = [];
        years.map((year, index) => {
            optArr.push({
                visualMap: {
                    min: 0,
                    max: _.max(dataArr[year].values),
                    splitNumber: dataArr[year].values.length,
                    color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                    textStyle: {
                        color: '#fff'
                    },
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        rotate: 30,
                        // fontSize: 10
                    },
                    data: cityNameArr,
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    show: false
                },
                series: [
                    {
                        data: dataArr[year]["values"],
                        type: 'bar',
                        itemStyle: {
                            color: ThemeData.color[index]
                        },
                        barWidth: 30,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#fff'
                        },
                        top: 0
                    }
                ]
            })
        });
        return optArr;
    }, [dataArr, years, pointsBlue, pointsRed]);

    const option: any = useMemo(() => {
        return {
            baseOption: timeline,
            options: optionsArr
        }
    }, [timeline, optionsArr]);

    const onEventsObj = {
        'click': (params: any) => {
            const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
            changeCityName?.(params.name, '')
            setPointMark('area');
            setTableTitle(title);
            setShow(true);
            return
            if (params.componentSubType === 'map') changeCityName?.(params.name, '');

            if (cityNameArr.includes(params.name) && params.componentType === "series" && params.componentSubType === "map") {
                const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区高职院校专业情况统计` : `${params.name}课程情况统计`);
                setPointMark('area');
                setTableTitle(title);
                setShow(true);
            }

            if (params.componentType === "series" && params.componentSubType === "scatter") {
                if (params.componentIndex === 2) {
                    changeCityName?.(params.name, '国家级');
                    setPointMark('red');
                    const title = tableType === 1 ? `${params.name}地区双高院校` : (tableType === 2 ? `${params.name}地区双高专业列表` : `${params.name}精品课列表`);
                    setTableTitle(title);
                }
                if (params.componentIndex === 3) {
                    changeCityName?.(params.name, '省级')
                    setPointMark('blue');
                    const title = tableType === 1 ? `${params.name}省级示范性高职院校` : (tableType === 2 ? `${params.name}地区“1+x”专业列表` : `${params.name}在线课程列表`);
                    setTableTitle(title);
                }
                setShow(true);
            }
        },
        'timelinechanged': (index: any) => {

        }
    };

    return (
        <div className={`${style.root} ${className}`}>
            {option.options.length && (
                <PureReactEcharts
                    theme={"theme"}
                    option={option}
                    notMerge={true}
                    lazyUpdate={true}
                    ref={echartsRef}
                    style={{width, height, margin: '0 auto'}}
                    onEvents={onEventsObj}
                />
            )}
        </div>
    )
}

LiaoNingMap.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
