import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { Form, Input, Button, Select, Row, Col, Empty, Spin } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import useRequestFn from "../../../lib/useRequestFn";
import { pyfaZYGXselect, ZhuanyeItem } from "../../../service/training";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";
export default function SchoolMajorList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "培养方案列表" }]);

  const [form] = Form.useForm();

  const { school_id } = useQuery();
  const { name } = useQuery();
  const { code } = useQuery();
  // const [getData, searchResult] = useRequestFn(pyfaZYGXselect);
  const [getData, searchResult] = useRequestFn(() => getNormalData < any > ('e7fd9903-929d-467b-865d-90b613c70c01', { school_id: school_id })) //培养方案列表
  const [data] = useRequestFn(() => getNormalData < any > ('99c6b5bb-b7b2-4916-a25a-2ab965b68522')) //培养方案列表

  useEffect(() => {
    // getData({ year: '2020', school_id: school_id });
    getData();
  }, [])
  const history = useHistory();
  /**
   * 跳转
   * @param values
   */
  const onFinish = (values: any) => {
    const subject_name = values.subject_name ? values.subject_name : "";
    const in_requirements = values.in_requirements ? values.in_requirements : "";
    history.push(`/training/ProfessionList?school_id=${school_id}&subject_name=${subject_name}&in_requirements=${in_requirements}`);
  };

  const { isMobile } = useDevice()

  const renderzy = (item: ZhuanyeItem) => {

    return (
      <>
        {item.zyfx.map((child: any, index: number) => (
          <tr key={`${index}`}>
            {/*<td rowSpan={index === 0 ? item.zyfx.length : 0}>{item.subject_code}</td>*/}
            {/*<td rowSpan={index === 0 ? item.zyfx.length : 0} style={{borderRight: "1px solid #e9e9e9"}}>{item.subject_name}</td>*/}
            {index <= 0 && <td rowSpan={item.zyfx.length > 1 ? item.zyfx.length : undefined}>{item.subject_code}</td>}
            {index <= 0 && <td rowSpan={item.zyfx.length > 1 ? item.zyfx.length : undefined} style={{ borderRight: "1px solid #e9e9e9" }}>{item.subject_name}</td>}
            <td>{child.subject_direction}</td>
            <td>{child.in_requirements}</td>
            <td>{child.rest_year}</td>
            <td>
              <Button className={style.tableButton} style={{ marginRight: 20 }} onClick={() => history.push(`/training/special?id=${child.id}`)}>专业基本情况</Button>
              <Button className={style.tableButton} onClick={() => history.push(`/training/trainPlan?subjectId=${child.id}`)}>培养方案</Button>
            </td>
          </tr>
        ))}

      </>
    )
  }

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Form onFinish={onFinish} form={form} labelCol={{ span: 4 }}>
            <Row gutter={24}>
              <Col span={isMobile ? 24 : 6}>
                <div className={style.title}><span>培养方案列表</span></div>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="subject_name" label="按专业检索" labelCol={{ span: 8 }}>
                  <Col span={22}>
                    <Input placeholder="请输入专业关键词" />
                  </Col>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Form.Item name="in_requirements" label="" labelCol={{ span: 8 }}>
                  <Select placeholder="选择入学类型">
                    <Select.Option value="">全部</Select.Option>
                    <Select.Option value="普通高考学生">普通高考学生</Select.Option>
                    <Select.Option value="对口升学学生">对口升学学生</Select.Option>
                    <Select.Option value="自主招生学生">自主招生学生</Select.Option>
                    <Select.Option value="“三二分段”学生">“三二分段”学生</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Button type="primary" style={{ marginRight: "20px" }} htmlType="submit">
                  搜索
                </Button>
                <Button className={style.blueButton} icon={<RetweetOutlined />} onClick={() => history.push("/training/pyfadb")}>
                  培养方案对比
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className={style.list}>
          <div className={style.listTitle}><span>{name}（{code}）</span></div>
          <div className={style.listIntroduction}><span>本页列出了本高校开设的所有专业，点击链接可查看专业详细信息。</span></div>
          <div className={style.schoolTable}>
            <Spin spinning={searchResult.loading}>
              <table className={style.customers}>
                <tbody>
                  <tr>
                    <th>专业代码</th>
                    <th>专业名称</th>
                    <th>专业方向</th>
                    <th>招生方式</th>
                    <th>学制</th>
                    <th>查看详情</th>
                  </tr>
                  {searchResult.data ? searchResult.data[0].zhuanye.map((item: any) => renderzy(item)) : []}
                </tbody>
              </table>
              {(!searchResult.data?.zhuanye || searchResult.data.zhuanye.length === 0) && <Empty style={{ marginTop: "50px" }} description="暂无数据" />}
            </Spin>
          </div>
        </div>
      </div>

    </div>
  );
}
