import {useState} from "react";

type PromiseValue<T> = T extends Promise<infer D> ? D : unknown;
type FnReturningPromise = (...args: any[]) => Promise<any>;

export default function useRequestFn<T extends FnReturningPromise>(callback: T) {
    type DataType = PromiseValue<ReturnType<T>> | null;

    interface StatusType {
        loading: boolean;
        error: any;
        data: DataType;
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<DataType>(null);

    const loadData: T = (async (...args: Parameters<T>) => {

        setLoading(true);

        try {
            const data = await callback(...args) as DataType;
            setData(data);
            return data;
        } catch (e) {
            setError(e);
            throw e;
        } finally {
            setLoading(false);
        }
    }) as T;

    return [loadData, { loading, error, data }] as [T, StatusType];
}
