//@ts-nocheck
import ReactEcharts from 'echarts-for-react';
import { EChartOption, graphic } from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import { getNormalData } from "../../../../service/basic";
import { useEffect, useState } from 'react';

const Options: EChartOption<EChartOption.Series> = {

  grid: [
    {
      //左侧的柱状图grid
      width: "100%",
      left: '1%',
      top: '0',
      right: '0',
      bottom: '0'
    }
  ],
  xAxis: [
    {
      //左侧柱状图的X轴
      gridIndex: 0,//x 轴所在的 grid 的索引
      show: false
    }],
  yAxis: [
    {
      //左侧柱状图的Y轴
      gridIndex: 0,//y轴所在的 grid 的索引
      splitLine: 'none',
      axisTick: 'none',
      axisLine: 'none',
      axisLabel: {
        verticalAlign: 'bottom',
        align: 'left',
        padding: [0, 0, 15, 15],
        textStyle: {
          color: '#FFFFFF',
          fontSize: '16',
        }
      },
      data: ['农业', '工业', '服务业'],
      inverse: true,
    },
    {
      //左侧柱状图的Y轴
      gridIndex: 0,//y轴所在的 grid 的索引
      splitLine: 'none',
      axisTick: 'none',
      axisLine: 'none',
      data: [1523.5, 3314.3, 1119.0],
      inverse: true,
      axisLabel: {
        show: true,
        verticalAlign: 'bottom',
        align: 'right',
        padding: [0, 160, 15, 0],
        textStyle: {
          color: '#fff',
          fontSize: '16',
        },
        formatter: function (value) {
          return value + '万元'
        },
        rich: {
          y: {
            color: '#befbff',
            fontSize: 16
          },
          x: {
            color: '#f6cf42',
            fontSize: 16
          }
        }
      }
    },
    {
      //左侧柱状图的Y轴
      gridIndex: 0,//y轴所在的 grid 的索引
      splitLine: 'none',
      axisTick: 'none',
      axisLine: 'none',
      data: []
    }
  ],
  series: [{
    name: '农业',
    type: 'bar',
    xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
    yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
    data: [1523.5, ,],
    barWidth: 15,
    itemStyle: {
      normal: {
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          'rgba(68, 180, 255, 0.2)', 'rgba(68, 180, 255, 1)'
        ].map((color, offset) => ({
          color,
          offset
        })))
      },
    },
    z: 2
  }, {
    name: '工业',
    type: 'bar',
    xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
    yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
    data: [, 3314.3,],
    barWidth: 15,
    itemStyle: {
      normal: {
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          'rgba(22, 231, 56, 0.2)', 'rgba(22, 231, 56, 1)'
        ].map((color, offset) => ({
          color,
          offset
        })))
      },
    },
    z: 2
  }, {
    name: '服务业',
    type: 'bar',
    xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
    yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
    data: [, , 1119.0],
    barWidth: 15,
    itemStyle: {
      normal: {
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          'rgba(253, 191, 25, 0.2)', 'rgba(253, 191, 25, 1)'
        ].map((color, offset) => ({
          color,
          offset
        })))
      },
    },
    z: 2
  },
  {
    name: '外框',
    type: 'bar',
    xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
    yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
    barGap: '-100%',
    data: [5956.8, 5956.8, 5956.8],
    barWidth: 15,
    itemStyle: {
      normal: {
        color: 'rgba(255, 255, 255, 0.15)',
        barBorderRadius: 6,
      }
    },
    z: 0
  }, {
    name: '农业',
    type: 'pictorialBar',
    // symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
    // symbolSize: [50, 50],
    // symbolOffset: [20, 0],
    z: 12,
  }, {
    name: '工业',
    type: 'pictorialBar',
    // symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
    // symbolSize: [50, 50],
    // symbolOffset: [20, 0],
    z: 12,
  }, {
    name: '服务业',
    type: 'pictorialBar',
    // symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
    // symbolSize: [50, 50],
    // symbolOffset: [20, 0],
    z: 12,
  }
  ]
};

const colorArr = [
  new graphic.LinearGradient(0, 0, 1, 0, [
    'rgba(253, 191, 25, 0.2)', 'rgba(253, 191, 25, 1)'
  ].map((color, offset) => ({
    color,
    offset
  }))),
  new graphic.LinearGradient(0, 0, 1, 0, [
    'rgba(22, 231, 56, 0.2)', 'rgba(22, 231, 56, 1)'
  ].map((color, offset) => ({
    color,
    offset
  }))),
  new graphic.LinearGradient(0, 0, 1, 0, [
    'rgba(253, 191, 25, 0.2)', 'rgba(253, 191, 25, 1)'
  ].map((color, offset) => ({
    color,
    offset
  })))
];

// const Options: EChartOption<EChartOption.Series> = {
//     series: [
//         {
//             data: [
//                 {name: "", value: },
//                 {name: "", value: },
//                 {name: "", value: }
//             ],
//             type: "pie",
//         },
//     ],
//     tooltip: { formatter: "{b}：{c} 个" },
// };


interface IProps {
  customOption?: (opt: EChartOption<EChartOption.Series>) => EChartOption<EChartOption.Series>,
  style?: any
}

export default function Index ({ customOption, style }: IProps) {
  const history = useHistory();
  const [data] = useRequest(() => getNormalData < { name: string; value: string }[] > ("c589db41-7f69-4b46-8db7-72ed0f0e1ab8")); // 产业成果转化金额
  return (
    <>
      {data && (
        <ReactEcharts
          onEvents={{ "click": () => history.push("/service") }}
          style={style}
          theme={"theme"}
          option={{
            ...Options,
            series: [
              ...data.map((item, index) => ({
                name: item.name,
                type: 'bar',
                xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                data: new Array(data.length).fill('').map((subItem, i) => i === index ? item.value.replace(',', '') : ''),
                barWidth: 15,
                itemStyle: {
                  normal: {
                    color: colorArr[index]
                  },
                },
                z: 2
              })),
              {
                name: '外框',
                type: 'bar',
                xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                barGap: '-100%',
                data: (function () {
                  const sum = data.map(item => item.value.replace(",", '')).reduce(function (prev, next) { return parseFloat(prev) + parseFloat(next) });
                  return new Array(data.length).fill('').map(item => sum);
                })(),
                barWidth: 15,
                itemStyle: {
                  normal: {
                    color: 'rgba(255, 255, 255, 0.15)',
                    barBorderRadius: 6,
                  }
                },
                z: 0
              },
              ...data.map(item => ({
                name: item.name,
                type: 'pictorialBar',
                z: 12
              }))
            ]
          }}
        />
      )}
    </>
  )
}
