import React, {useEffect, useMemo, useState} from "react";
import style from "./index.module.scss";

interface iPops {
    tableData: any,
    title: string,
    thData:any,
    hiddenTable: () => void
}


export default function TabTable3(props: iPops) {
    const [data, setData] = useState([]);
    const [tableTitle, setTableTitle] = useState('');
    const [thData,setThData] = useState([]);
    useMemo(() => {
        setTableTitle(props.title)
    }, [props.title])

    useMemo(() => {
        setData(props.tableData)

    }, [props.tableData])

    useMemo(()=>{
            setThData(props.thData);
    },[props.thData])

    return (
        <div className={style.list}>
            <div className={style.title}>
                <div className={style.tableTitle}>{tableTitle}</div>
                <div className={style.closeButton} onClick={() => {
                        props.hiddenTable();
                }
                }></div>
            </div>
            <div style={{height: '450px', overflowY: 'auto'}}>
                <table className={style.table}>
                    <tbody className={style.tableList}>
                    <tr>
                        {
                            thData.map((item: any, index: number) => {
                                return (
                                    <th key={index}>{item}</th>
                                )
                            })
                        }
                    </tr>
                    {
                        data.map((item: any, index: number) => {
                            return (
                                <tr key={index}>
                                    {
                                        item.table.map((value: any, currentIndex: number) => {
                                            return (
                                                <td key={currentIndex}>
                                                    {value}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>
            </div>
        </div>
    )
}
