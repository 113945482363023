import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption, EChartsResponsiveOption, registerMap } from "echarts";
import 'echarts/map/js/province/liaoning.js';

import style from '../../../../Charts/Components/Province/style.module.scss';
import Popup from "../../../../Charts/Components/Popup";
import ThemeData from '../../../../../../src/assets/walden.project.json';
// import {ILevel, ISchoolInfo} from "../../../../service/chart";
import TableStyle from '../../../../Charts/PageCharts/Components/LiaoNingMap/index.module.scss';
import _ from 'lodash';
import { ILevel, ISchoolInfo } from '../../../../../service/chart';
import mapJson from './map.json';
// import Table from '../../../../Basic/Detail/components/Table';
import { Table } from 'antd'
interface ProvinceProps {
    name: string;
    data: {
        name: string;
        coord: number[],
        value: number,
        circleColor: string,
        areaColor: string,
        year: string
    }[];
    className?: string;
    width?: number;
    height?: number;
    year: string[];
    tableType?: number;
    geoColor?: string;
    special?: boolean;
    schoolList?: any[]
    totalData?: any[]
    changeCityName?: (val: string, levelName: string) => void; // levelName 传值 国家级或省级
    pointsBlue?: { areaCode: string; schCount: string }[]; // 省级院校
    pointsRed?: { areaCode: string; schCount: string }[]; // 国家级院校
    schoolListSpecial?: ILevel[]; // 基本情况 点击红蓝点 弹出的列表数据
    showTimeLine: boolean;
    onClean?: () => void;
    points: {
        name: string;
        value: string;
    }[]
}

interface DataArrTypes {
    [key: string]: {
        values: number[],
    }
}

const geoCoordMap = {
    '台湾': [121.5135, 25.0308],
    '黑龙江': [127.9688, 45.368],
    '内蒙古': [110.3467, 41.4899],
    "吉林": [125.8154, 44.2584],
    '北京': [116.4551, 40.2539],
    "辽宁": [123.1238, 42.1216],
    "河北": [114.4995, 38.1006],
    "天津": [117.4219, 39.4189],
    "山西": [112.3352, 37.9413],
    "陕西": [109.1162, 34.2004],
    "甘肃": [103.5901, 36.3043],
    "宁夏": [106.3586, 38.1775],
    "青海": [101.4038, 36.8207],
    "新疆": [87.9236, 43.5883],
    "西藏": [91.11, 29.97],
    "四川": [103.9526, 30.7617],
    "重庆": [108.384366, 30.439702],
    "山东": [117.1582, 36.8701],
    "河南": [113.4668, 34.6234],
    "江苏": [118.8062, 31.9208],
    "安徽": [117.29, 32.0581],
    "湖北": [114.3896, 30.6628],
    "浙江": [119.5313, 29.8773],
    "福建": [119.4543, 25.9222],
    "江西": [116.0046, 28.6633],
    "湖南": [113.0823, 28.2568],
    "贵州": [106.6992, 26.7682],
    "云南": [102.9199, 25.4663],
    "广东": [113.12244, 23.009505],
    "广西": [108.479, 23.1152],
    "海南": [110.3893, 19.8516],
    '上海': [121.4648, 31.2891],
};

export default function Province ({
    name, data, className, year,
    changeCityName, schoolList, totalData,
    geoColor = '46, 199, 201',
    tableType = 1, width = 1920,
    height = 1000, special = false,
    pointsBlue, pointsRed,
    schoolListSpecial,
    showTimeLine,
    onClean, points
}: ProvinceProps) {
    // const [years, setYears] = useState<string[]>([]);
    const echartsRef = useRef < any > (null);
    // const [dataArr, setDataArr] = useState<DataArrTypes>({});
    // const [geoRegions, setGeoRegions] = useState<any[]>([]);

    const [show, setShow] = useState(false);
    const [tableTitle, setTableTitle] = useState('');
    const [pointMark, setPointMark] = useState('area');

    var convertData = function (data: any) {
        var res = [];
        for (var i = 0; i < data.length; i++)
        {
            // @ts-ignore
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord)
            {
                res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                });
            }
        }
        return res;
    };

    const option: any = useMemo(() => {

        registerMap("china", mapJson)

        return {
            // title: {
            //     text: '辽宁省高职院校2020毕业生就业去向全国分布图',
            //     // left: 'center'
            // },
            visualMap: {
                min: 0,
                max: 50000,
                splitNumber: 500,
                color: [`rgba(${geoColor}, 1)`, `rgba(${geoColor}, 0.4)`],
                textStyle: {
                    color: '#fff'
                },
                show: false,
                seriesIndex: [0],
                pieces: [{
                    lt: 100,
                    color: `rgba(${geoColor}, 0.2)`
                },
                {
                    lt: 500,
                    gte: 100,
                    color: `rgba(${geoColor}, 0.5)`
                },
                {
                    lt: 3000,
                    gte: 300,
                    color: `rgba(${geoColor}, 0.8)`
                },
                {
                    lt: 1000000000000000000000000000000000,
                    gte: 3000,
                    color: `#08c`
                }]
            },
            geo: {
                map: "china",
                label: {
                    normal: {
                        show: false,
                    },
                    emphasis: {
                        show: false,
                    },
                },
                left: 0,
                right: 0,
                // top: 0,
                // bottom: 0,
                itemStyle: {
                    normal: {
                        borderColor: 'rgba(147, 235, 248, 1)',
                        borderWidth: 1,
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.8,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                            }],
                            globalCoord: false // 缺省为 false
                        },
                        shadowColor: 'rgba(128, 217, 248, 1)',
                        shadowOffsetX: -2,
                        shadowOffsetY: 2,
                        shadowBlur: 10
                    },
                    emphasis: {
                        areaColor: `rgba(${geoColor}, 1)`,
                        // areaColor: 'normal' ,
                        borderWidth: 0
                    }
                },
            },
            series: [
                {
                    type: 'map',
                    data: points,
                    geoIndex: 0,
                    map: "china"
                },
                {
                    symbolSize: 5,
                    label: {
                        normal: {
                            formatter: "{b}",
                            position: "right",
                            show: true,
                            textStyle: {
                                color: "#444",
                                fontSize: 14,
                            }
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#fff",
                        },
                    },
                    name: "light",
                    type: "scatter",
                    coordinateSystem: "geo",
                    data: convertData(points),
                },
                {
                    name: "point",
                    type: "scatter",
                    coordinateSystem: "geo",
                    symbol: "pin",
                    symbolSize: (val: any) => {
                        if (parseInt(val[2]) > 10000)
                        {
                            return 90
                        } else
                        {
                            return 55
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 14,
                            },
                            formatter (value: any) {
                                return value.data.value[2];
                            },
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#ff0000", //标志颜色
                        },
                    },
                    data: convertData(points),
                    zlevel: 100,
                },
            ],
        };
    }, [points]);

    const onEventsObj = {
        'click': (params: any) => {
            const title = tableType === 1 ? `${params.name}地区高等职业院校` : (tableType === 2 ? `${params.name}地区毕业生就业去向统计` : `${params.name}课程情况统计`);
            changeCityName?.(params.name, '')
            setPointMark('area');
            setTableTitle(title);
            setShow(true);
            return
        },
    };

    const columns = [
        {
            title: '企业',
            dataIndex: 'company'
        },
        {
            title: '学校',
            dataIndex: 'school'
        },
        {
            title: '专业',
            dataIndex: 'subject'
        },
        {
            title: '人数',
            dataIndex: 'count',
            sorter: {
                compare: (a: any, b: any) => a.count - b.count
            }
        }
    ]
    const dataSource = schoolList?.map((item: any) => {
        return {
            company: item.company,
            school: item.school,
            subject: item.subject,
            count: item.count
        }
    })

    return (
        <div className={`${style.root} ${className}`}>
            <ReactEcharts
                theme={"theme"}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                ref={echartsRef}
                style={{ width, height, margin: '0 auto' }}
                onEvents={onEventsObj}
            />
            <Popup onClick={() => {
                setShow(false);
            }} show={show} width='80vw'>
                <div className={TableStyle.tableWrapper}>
                    <div
                        className={TableStyle.tableTitle}>{tableTitle}{(tableType === 1 && pointMark === 'area') && schoolList && schoolList.length > 0 ? `${schoolList.length}所` : ((tableType === 1 && pointMark === 'red') && schoolListSpecial && schoolListSpecial.length > 0 ? `${schoolListSpecial.length}所` : '')}</div>
                    <div style={{ width: '100%', height: '480px', overflowY: 'scroll' }}>

                        {(tableType === 1 && pointMark === 'area') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校名称</th>
                                    <th>学校属性</th>
                                    <th>在校生数</th>
                                    <th>占地面积</th>
                                    <th>建筑面积</th>
                                    <th>年度经费投入</th>
                                </tr>
                                {schoolList?.map((item, index) => <tr key={index}>
                                    <td>{item.schoolName}</td>
                                    <td>{item.school_sx}</td>
                                    <td>{item.stuCount}</td>
                                    <td>{item.all_area}</td>
                                    <td>{item.jzmj_zj}</td>
                                    <td>{item.inCase}</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/* 2 点击全国地图某一区域列表*/}
                        {(tableType === 2 && pointMark === 'area') &&
                            <Table columns={columns} dataSource={dataSource} pagination={false} />
                            // <table className={TableStyle.table}>
                            //     <tbody className={style.tableList}>
                            //         <tr className={style.tableListTitle}>
                            //             <th style={{ width: '20%' }}>企业</th>
                            //             <th style={{ width: '20%' }}>学校</th>
                            //             <th style={{ width: '20%' }}>专业</th>
                            //             <th style={{ width: '20%' }}>人数</th>
                            //         </tr>
                            //         {schoolList?.filter(item => item.studentCount !== null && item.studentCount !== 0).map(item =>
                            //             <tr>
                            //                 <td>{item.company}</td>
                            //                 <td>{item.school}</td>
                            //                 <td>{item.subject}</td>
                            //                 <td>{item.count}</td>
                            //             </tr>)}

                            //     </tbody>
                            // </table>

                        }

                        {/* <div>
              <table className={TableStyle.table}>
                {
                  totalData?.length === 1 && totalData?.map((item) => {
                    return (
                      <tr className={style.tableListTitle}>
                        <th>企业总数:{item.company}</th>
                        <th>学校总数:{item.school}</th>
                        <th>专业总数:{item.subject}</th>
                        <th>学生总数:{item.count}</th>
                      </tr>
                    )

                  })
                }
              </table>
            </div> */}

                        {/*红点 重点专业列表*/}
                        {(tableType === 2 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody className={style.tableList}>
                                <tr className={style.tableListTitle}>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>级别</th>
                                </tr>
                                {schoolList?.map(item => <tr>
                                    <td>{item.schoolName}</td>
                                    <td>{item.subjectName}</td>
                                    <td>{item.is_sg}</td>
                                </tr>)}
                            </tbody>
                        </table>}
                        {/*蓝点 特殊专业列表*/}

                        {(tableType === 2 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>证书数量</th>
                                </tr>
                                {schoolList?.map(item => <tr>
                                    <td>{item.schoolName}</td>
                                    <td>{item.subjectName}</td>
                                    <td>{item.is_1x}</td>
                                </tr>)}

                            </tbody>
                        </table>}


                        {/* 某某市国家级高级院校列表*/}
                        {(tableType === 1 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>类别</th>
                                    <th>层次</th>
                                </tr>
                                {schoolListSpecial?.map(item => <tr>
                                    <td>{item.name}</td>
                                    <td>{item.xzCode}</td>
                                    <td>省级</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/* 蓝 某某市省级示范性高级院校*/}
                        {(tableType === 1 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>类别</th>
                                </tr>
                                {schoolListSpecial?.map(item => <tr>
                                    <td>{item.name}</td>
                                    <td>{item.xzCode}</td>
                                </tr>)}
                            </tbody>
                        </table>}

                        {/* 3 某某市课程情况统计*/}
                        {(tableType === 3 && pointMark === 'area') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>课程数量</th>
                                    <th>精品课</th>
                                    <th>在线课</th>
                                    <th>资源共享课</th>
                                    <th>实训课</th>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>3346</td>
                                    <td>19</td>
                                    <td>740</td>
                                    <td>560</td>
                                    <td>1002</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>5486</td>
                                    <td>16</td>
                                    <td>650</td>
                                    <td>430</td>
                                    <td>1209</td>
                                </tr>
                                <tr>
                                    <td>辽宁轨道交通技术学院</td>
                                    <td>4892</td>
                                    <td>19</td>
                                    <td>734</td>
                                    <td>467</td>
                                    <td>980</td>
                                </tr>
                                <tr>
                                    <td>辽宁铁道职业技术学院</td>
                                    <td>5643</td>
                                    <td>15</td>
                                    <td>790</td>
                                    <td>667</td>
                                    <td>1032</td>
                                </tr>
                                <tr>
                                    <td>沈阳北软件职业信息技术学院</td>
                                    <td>7654</td>
                                    <td>19</td>
                                    <td>543</td>
                                    <td>675</td>
                                    <td>1902</td>
                                </tr>

                            </tbody>
                        </table>}
                        {/*红点 某某市精品课列表*/}
                        {(tableType === 3 && pointMark === 'red') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>课程</th>
                                    <th>级别</th>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>物联网应用技术</td>
                                    <td>电子商务概论</td>
                                    <td>国家级</td>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>高速铁路客运乘务</td>
                                    <td>中级财务会计</td>
                                    <td>省级</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>环境艺术设计</td>
                                    <td>行政管理学</td>
                                    <td>省级</td>
                                </tr>
                            </tbody>
                        </table>}
                        {/*蓝点 某某市在线课程列表*/}
                        {(tableType === 3 && pointMark === 'blue') && <table className={TableStyle.table}>
                            <tbody>
                                <tr>
                                    <th>学校</th>
                                    <th>专业</th>
                                    <th>课程</th>
                                    <th>签到人数</th>

                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>物联网应用技术</td>
                                    <td>电子商务概论</td>
                                    <td>2356</td>
                                </tr>
                                <tr>
                                    <td>辽宁省交通高等专科学校</td>
                                    <td>高速铁路客运乘务</td>
                                    <td>中级财务会计</td>
                                    <td>5674</td>
                                </tr>
                                <tr>
                                    <td>沈阳职业技术学院</td>
                                    <td>环境艺术设计</td>
                                    <td>行政管理学</td>
                                    <td>7645</td>
                                </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>

            </Popup>
        </div>
    )
}

Province.defaultProps = {
    pointsBlue: [],
    pointsRed: [],
    showTimeLine: true
}
