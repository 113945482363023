import Widget11 from "../../../School/Widget11";
import Widget12 from "../../../School/Widget12";
import ChartCarousel from "../../../Components/ChartCarousel";
import React, {useState} from "react";
import style from "../../index.module.scss";
import Widget10 from "../../../School/Widget10";
import doNotReRender from "../../../../../lib/doNotReRender";
import { useHistory } from "react-router";

const PureWidget11 = doNotReRender(Widget11);
const PureWidget12 = doNotReRender(Widget12);
const PureWidget10 = doNotReRender(Widget10);

export default function BlockRight1() {

    const [title, setTitle] = useState<React.ReactNode>("");
    const history = useHistory()

    return (
        <div>
            <h1 className={style.blockHeader}>{title}</h1>

            <ChartCarousel
            
                speed={5000}
                title={[
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/course/main")}>实时开课状态</div>,
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/course/main")}>开课类型分布图</div>,
                    <div style={{cursor: "pointer"}} onClick={() => window.location.href = "https://liaoning.xybsyw.com/#/dataOverview"}>实训数据分析图</div>,
                ]}
                hideTitle
                onTitleChange={setTitle}
            >
                <div>
                    <PureWidget11 />
                </div>

                <div>
                    <PureWidget12 style={{height: 260}} />
                </div>

                <div>
                    <PureWidget10 />
                </div>
            </ChartCarousel>
        </div>
    );
}
