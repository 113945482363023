import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import useRequest from "../../../../../lib/useRequest";
import { CoursePlanType } from "../../../../../service/training";
import ContentStyle from "./index.module.scss";
import {Pagination} from "antd";

interface CourseIdProps {
    contentDetail:CoursePlanType[];
}


export default function CourseRequire({contentDetail}: CourseIdProps) {

    const [data, setData] = useState<CoursePlanType>();
    const [title, setTitle] = useState("1")
    useEffect(() => {
        setData(contentDetail[0]);
    }, []);

    function itemRender(current: number, type: string, originalElement: any) {
        if (type === 'prev') {
            return <a>上一单元</a>;
        }

        if (type === 'next') {
            return <a>下一单元</a>;
        }

        return originalElement;
    }

    function onChange (page:any,pageSize:any){
        setData(contentDetail[page - 1]);
        setTitle(page);
    }
    return (
        <div className={style.root}>

            <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>第{title}单元</h1>
                </div>

                <div className={style.table}>
                    <table className={ContentStyle.table}>
                        <tr>
                            <td style={{width: 300}}>模块（项目/单元）名称</td>
                            <td>{data?.model_name}</td>
                        </tr>
                        <tr>
                            <td>参考学时-理论</td>
                            <td>{data?.theory}</td>
                        </tr>
                        <tr>
                            <td>参考学时-实践</td>
                            <td>{data?.practice}</td>
                        </tr>
                        <tr>
                            <td>学习目标</td>
                            <td>{data?.study_goal}</td>
                        </tr>
                        <tr>
                            <td>学习内容</td>
                            <td>{data?.study_content}</td>
                        </tr>
                        <tr>
                            <td>学习内容-重点</td>
                            <td>{data?.content_import}</td>
                        </tr>
                        <tr>
                            <td>学习内容-难点</td>
                            <td>{data?.content_difficult}</td>
                        </tr>
                        <tr>
                            <td>教学方法与手段</td>
                            <td>{data?.teach_method}</td>
                        </tr>
                        <tr>
                            <td>教学活动设计</td>
                            <td>{data?.activity_design}</td>
                        </tr>
                        <tr>
                            <td>教学条件</td>
                            <td>{data?.condition}</td>
                        </tr>
                        <tr>
                            <td>考核评价方式</td>
                            <td>{data?.checkStyle}</td>
                        </tr>
                        <tr>
                            <td>考核评价权重</td>
                            <td>{data?.check_weight}</td>
                        </tr>
                        <tr>
                            <td>主要考核-知识</td>
                            <td>{data?.main_check_know}</td>
                        </tr>
                        <tr>
                            <td>主要考核-技能</td>
                            <td>{data?.main_check_tech}</td>
                        </tr>
                        <tr>
                            <td>主要考核-态度</td>
                            <td>{data?.main_check_attitude}</td>
                        </tr>
                        <tr>
                            <td>参考资料及其他说明</td>
                            <td>{data?.check_other_info}</td>
                        </tr>
                    </table>
                </div>

                <div style={{textAlign: "center", paddingTop: 30}}>
                    <Pagination defaultCurrent={1} total={contentDetail.length * 10} itemRender={itemRender} onChange={onChange} showSizeChanger={false} />
                </div>
            </div>
        </div>
    );
}
