import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss"
import { Form, Input, Button, Checkbox, Select, Row, Col, Image, Table, Space, Empty } from 'antd';
import { useHistory } from "react-router-dom";
import useRequestFn from "../../../lib/useRequestFn";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";

export default function ProfessionList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "培养方案搜索" }]);

  const { school_id } = useQuery();
  const { subject_name, in_requirements } = useQuery < { subject_name: string, in_requirements: string } > ()
  const history = useHistory();

  // 创建name
  const [name, setName] = useState < any > (subject_name);
  const [in_req, setReq] = useState < any > (in_requirements);

  const [getData, searchResult]: any = useRequestFn(() => getNormalData < any > ('7c20031f-1081-4d3a-ba12-a52526d318ce', { subject_name: name, in_requirements: in_req }))

  const { isMobile } = useDevice();
  useEffect(() => {
    getData({ year: "2020", school_id: school_id, subject_name: subject_name, in_requirements: in_requirements });
  }, [])

  const columns: any = [
    {
      title: '专业代码',
      dataIndex: 'subject_code',
      width: 90,
      key: 'num',
      responsive: ['md'], // 手机分辨率不显示该列
    },
    {
      title: '专业名称',
      dataIndex: 'subject_name',
      sorter: {
        compare: (a: any, b: any) => a.subject_name.length - b.subject_name.length,
        multiple: 3,
      },
      key: 'name',
      fixed: isMobile ? "left" : false,
    },
    {
      title: '院校',
      dataIndex: 'school_name',
      sorter: {
        compare: (a: any, b: any) => a.school_name.length - b.school_name.length,
        multiple: 2,
      },
      key: 'school'
    },
    {
      title: '入学类型',
      dataIndex: 'in_requirements',
      sorter: {
        compare: (a: any, b: any) => a.in_requirements.length - b.in_requirements.length,
        multiple: 2,
      },
      key: 'type'
    },
    {
      title: '学习年限',
      dataIndex: 'rest_year',
      sorter: {
        compare: (a: any, b: any) => a.rest_year - b.rest_year,
        multiple: 2,
      },
      render: (value: string) => `${value}年`,
      key: 'year'
    },
    {
      title: '查看详情',
      key: 'del',
      render: (text: any, value: any) => <Space size="middle">
        <Button className={style.tableButton} onClick={() => history.push(`/training/special?id=${value.id}`)}>
          详情
        </Button>
        <Button className={style.tableButton} onClick={() => history.push(`/training/trainPlan?subjectId=${value.id}`)}>
          培养方案
        </Button>
      </Space>,
    },
  ];
  //点击搜索
  const onFinish = () => {
    getData({ subject_name: name, in_requirements: in_req });
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Form labelCol={{ span: isMobile ? 24 : 4 }}>
            <Row gutter={[16, 16]}>
              <Col span={isMobile ? 24 : 4}>
                <div className={style.title}><span>培养方案列表</span></div>
              </Col>
              <Col span={isMobile ? 24 : 8} >
                <Input placeholder="请输入专业关键词" onChange={(e) => setName(e.target.value)} defaultValue={subject_name} />
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="选择入学类型"
                  onChange={(value) => {
                    setReq(value)
                  }}
                  defaultValue={in_req}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="普通高考学生">普通高考学生</Select.Option>
                  <Select.Option value="对口升学学生">对口升学学生</Select.Option>
                  <Select.Option value="自主招生学生">自主招生学生</Select.Option>
                  <Select.Option value="“三二分段”学生">“三二分段”学生</Select.Option>
                </Select>
                {/* </Form.Item> */}
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Button type="primary" style={{ marginRight: "20px" }} onClick={onFinish} htmlType="submit" >
                  搜索
                </Button>
                <Button className={style.blueButton} onClick={() => history.push("/training/pyfadb")}>
                  PK 培养方案对比
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className={style.list}>
          <Table
            loading={searchResult.loading}
            className={style.tableContent}
            columns={columns}
            dataSource={searchResult.data ? searchResult.data : []}
            scroll={isMobile ? { x: 1000 } : undefined}
          />
        </div>
      </div>
    </div>
  );
}
function setCode (subject_name: string) {
  throw new Error("Function not implemented.");
}

