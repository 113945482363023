import React from "react";
import style from "../../style.module.scss";

export default function NavItem({type, className, onClick}: {type?: number, className?: string, onClick: (type: number) => void}) {
    return(
        <div className={`${style.tabs} ${className ? className : ''}`}>
            <div className={`${style.tab} ${type === 1 ? style.active : ''}`} onClick={() => onClick(1)}>基本情况</div>
            <div className={`${style.tab} ${type === 2 ? style.active : ''}`} onClick={() => onClick(2)}>专业布局</div>
        </div>
    )
}
