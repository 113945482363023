import React, { useState } from "react";
import { Button, Row, Col, Spin } from 'antd';
import style from './index.module.scss'
import BasicRule from "./components/basic";
import DemandAnalysis from "./components/demand";
import Educate from "./components/educate";
import Course from "./components/course";
import Ensure from "./components/ensure";
import Graduate from "./components/graduate";
import Appendix from "./components/appendix";
import { useHistory } from "react-router-dom";
import useRequest from "../../../lib/useRequest";
import {
  findCourseSyses,
  findMajorAbility,
  findSubjectCoreCourses,
  findSubjectTrainBaseInfo, findTeacherTeams,
  findVocationLevelCerts, ohourseScorehz, schoolInTrains, schoolOutTrains
} from "../../../service/training";
import useQuery from "../../../lib/useQuery";
import { findSpecialtyInfoById } from "../../../service/major";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";


export default function PageTrainPlan () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "培养方案详细" }]);
  const { isMobile } = useDevice()
  const { subjectId, tab = '1' } = useQuery();
  const [current, setCurrent] = useState(tab);
  const history = useHistory();

  // const [baseInfo] = useRequest(() => findSubjectTrainBaseInfo(subjectId));
  // const [abilityList] = useRequest(() => findMajorAbility(subjectId));
  // const [levelCertsList] = useRequest(() => findVocationLevelCerts(subjectId));
  // const [TeacherTeamList] = useRequest(() => findTeacherTeams(subjectId));
  // const [TrainsList] = useRequest(() => schoolInTrains(subjectId));
  // const [TrainsOutList] = useRequest(() => schoolOutTrains(subjectId));
  // const [courseSysList] = useRequest(() => findCourseSyses(subjectId));
  // const [coreCourseList] = useRequest(() => findSubjectCoreCourses(subjectId));
  // const [hourCollectList] = useRequest(() => ohourseScorehz(subjectId));
  // const [specialtyInfo] = useRequest(() => findSpecialtyInfoById(subjectId));

  const [baseInfo] = useRequest(() => getNormalData < any > ('2b1aa353-b7e4-4d02-a377-786a129035bf', { subjectId: subjectId })) // 培养方案
  const [abilityList] = useRequest(() => getNormalData < any > ('ef94bae8-5ceb-450d-8d6e-cdaf70899a00', { subjectId: subjectId })) // 培养方案
  const [levelCertsList] = useRequest(() => getNormalData < any > ('81892e2f-8bfc-477d-b108-b418d8263b2d', { subjectId: subjectId }))// 培养方案
  const [TeacherTeamList] = useRequest(() => getNormalData < any > ('f8dde81c-1adf-48cb-bae1-74fbbac73156', { subjectId: subjectId }))// 培养方案
  const [TrainsList] = useRequest(() => getNormalData < any > ('e7cb5ce6-234c-49f4-81bf-955c6fc4c151', { subjectId: subjectId }))// 培养方案
  const [TrainsOutList] = useRequest(() => getNormalData < any > ('a68032da-930f-4cff-973f-e4c1a98f87fe', { subjectId: subjectId }))//培养方案(schoolInTrains
  const [courseSysList] = useRequest(() => getNormalData < any > ('4126e493-03c0-4180-905e-710dfaa38719', { subjectId: subjectId }))//培养方案(findCourseSyses)
  const [coreCourseList] = useRequest(() => getNormalData < any > ('ec897d64-7b7a-4df5-880a-02796a1aabb7', { subjectId: subjectId }))//培养方案(findSubjectCoreCourses)
  const [hourCollectList] = useRequest(() => getNormalData < any > ('238f6a8c-468a-4fc4-a5f3-c94305957dbd', { subjectId: subjectId }))//培养方案(ohourseScorehz)
  const [specialtyInfo] = useRequest(() => getNormalData < any > ('f83d94ed-9e14-42c6-aad4-24d1a64efae9', { id: subjectId }))//专业详情页(findSpecialtyInfoById

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.title}>
          <div className={style.school}>
            {baseInfo?.subjectTrainBaseInfo.schoolName}({baseInfo?.subjectTrainBaseInfo.schoolCode})
          </div>

          <div className={style.professionaltitle}>{baseInfo?.subjectTrainBaseInfo.subectName}
            {baseInfo?.subjectTrainBaseInfo.subjectDirection && <span>({baseInfo?.subjectTrainBaseInfo.subjectDirection})</span>} - 培养方案
          </div>
          <div className={style.titlebutton}>
            <Button onClick={() => history.push(`/training/pyfadb?subjectId=${subjectId}&school=${baseInfo?.subjectTrainBaseInfo.schoolName}`)}>PK 培养方案对比</Button>
          </div>
        </div>
        <div className={style.tabs}>
          <Row gutter={isMobile ? 24 : 10}>
            <Col className="gutter-row" span={isMobile ? 12 : 4} >
              <div className={`${current === '1' && style.current} ${style.tab}`} onClick={() => { setCurrent('1') }}>基本规范</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 12 : 4}>
              <div className={`${current === '2' && style.current} ${style.tab}`} onClick={() => { setCurrent('2') }}>专业人才需求分析</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 12 : 4}>
              <div className={`${current === '3' && style.current} ${style.tab}`} onClick={() => { setCurrent('3') }}>培养目标和职业面向</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 12 : 4}>
              <div className={`${current === '4' && style.current} ${style.tab}`} onClick={() => { setCurrent('4') }}>课程体系</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 12 : 4}>
              <div className={`${current === '5' && style.current} ${style.tab}`} onClick={() => { setCurrent('5') }}>实施保障</div>
            </Col>
            <Col className="gutter-row" span={isMobile ? 12 : 4}>
              <div className={`${current === '6' && style.current} ${style.tab}`} onClick={() => { setCurrent('6') }}>毕业要求</div>
            </Col>
          </Row>
        </div>
      </div>
      <Spin tip="Loading..." spinning={baseInfo ? false : true}>
        <div className={style.tabContent}>
          {/* 基本规范 */}
          {current === '1' && <BasicRule
            data={baseInfo?.subjectTrainBaseInfo}
            abilityList={abilityList?.majorAbilitys}
            levelCertsList={levelCertsList?.vocationLevelCerts}
          />}
          {/* 专业人才需求分析                */}
          {current === '2' && <DemandAnalysis
            data={baseInfo?.subjectTrainBaseInfo}
          />}
          {/* 培养目标和职业面向 */}
          {current === '3' && <Educate
            data={baseInfo?.subjectTrainBaseInfo}
            levelCertsList={levelCertsList?.vocationLevelCerts}
            abilityList={abilityList?.majorAbilitys}
          />}
          {/* 课程体系 */}
          {current === '4' && <Course
            data={baseInfo?.subjectTrainBaseInfo}
            TeacherTeamList={TeacherTeamList?.teacherTeams}
            courseSysList={courseSysList?.findCourseSyses}
            coreCourseList={coreCourseList?.subjectCoreCourses}
            hourCollectList={hourCollectList?.jjsjk}
          />}
          {/* 实施保障 */}
          {current === '5' && <Ensure
            data={baseInfo?.subjectTrainBaseInfo}
            TrainsList={TrainsList?.schoolInTrains}
            TeacherTeamList={TeacherTeamList?.teacherTeams}
            TrainsOutList={TrainsOutList?.schoolOutTrains}
          />}
          {/* 毕业要求 */}
          {current === '6' && <Graduate data={baseInfo?.subjectTrainBaseInfo} />}
          {current === '7' && <Appendix data={courseSysList?.findCourseSyses} />}
        </div>
      </Spin>
    </div>
  );
}
