import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import { getCourseTrainingProgram } from "../../../../../service/training";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";

interface CourseIdProps {
  datas: any
}

export default function BaseInfo ({ datas }: CourseIdProps) {


  let dataDetail = datas

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>课程名称</div>
              <div className={style.content}>{dataDetail ? dataDetail.courseBase?.course_name : ''}</div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>课程类型</div>
              <div className={style.content}>{dataDetail?.courseBase?.course_type}</div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>适用专业</div>
              <div className={style.content}>
                {dataDetail?.courseBase?.subject_name}
                {dataDetail?.courseBase?.subject_code && `-${dataDetail?.courseBase?.subject_code}`}
                {dataDetail?.courseBase?.subject_direction && `(${dataDetail?.courseBase?.subject_direction})`}
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>开学学期</div>
              <div className={style.content}>{dataDetail?.courseBase?.term}</div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>总学时</div>
              <div className={style.content}>{dataDetail?.courseBase?.all_time}</div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>学分数</div>
              <div className={style.content}>{dataDetail?.courseBase?.xfs}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
