import ReactEcharts from "echarts-for-react";
import useRequest from "../../../../../lib/useRequest";
import { sankeyDiagramIndustryToSubject, sankeyDiagramIndustryToSubject2 } from "../../../../../service/major";

const colors = [
  '#78A3CE',
  '#FBC2EB',
  '#A18CD1',
  '#C2E9FB',
  '#A1C4FD',
  '#D4FC79',
  '#96E6A1',
  '#FCC687',
  '#F28D86',
  '#F286A0',
  '#33876A',
  '#B5BF6E',
  '#2979F1',
  '#F08F1B',
  '#57B956',
  '#ABA5EA',
  '#9BC46C',
  '#30E0E0',
  '#F286CA',
  '#A6F286',
  '#D66161',
  '#8FF379',
  '#1CBDB4',
  '#EEF2F3',
  '#8E9EAB',
  '#BB9BF1',
  '#887BF2',
  '#7FFED8',
  '#09BDFE',
  '#F2E786',
]

export default function DetailChart ({ name, type, datas, links }: { name: string, type: "1" | "2", datas: any, links: any }) {
  const [options] = useRequest(() => type === "1" ? sankeyDiagramIndustryToSubject(name) : sankeyDiagramIndustryToSubject2(name));

  const option: any = {
    backgroundColor: '#FFFFFF',
    series: [
      {
        type: 'sankey',
        left: 50.0,
        top: 40.0,
        right: 150.0,
        bottom: 25.0,
        data: datas,
        links: links,
        // data: options?.data.map((item, index) =>({
        //     ...item,
        //     itemStyle: {
        //         color: colors[index%20]
        //     }
        // })) || [],
        // links: options?.links.map(item => ({
        //     ...item,
        //     source: item.target,
        //     target: item.source,
        //     lineStyle: {normal: {opacity: 0.05}}
        // })) || [],
        lineStyle: {
          curveness: 0.5
        },
        label: {
          color: 'rgba(0,0,0,0.7)',
          fontFamily: 'Arial',
          fontSize: 10
        }
      }],

    tooltip: {
      formatter: (params: any) => {
        return `专业布点数：${params.data.value || params.value}<br/>提供人数：${params.data.people}`
      }
    },
  };

  return (
    <div>
      <ReactEcharts option={option} notMerge={true} lazyUpdate={true} style={{ height: 600 }} />
    </div>
  );
}
