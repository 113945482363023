import { Col, Row } from "antd";
import React, { useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import {ISubjectTrainBaseInfo} from "../../../../../service/training";

interface schoolOutProps {
    data?:ISubjectTrainBaseInfo;
}

export default function Graduate({data}:schoolOutProps) {

    return (
        <div className={style.root}>
                <div className={style.container}>
                <div className={style.headTitle}>
                    <div className={style.blueline} />
                    <h1>毕业要求</h1>
                </div>
                <div className={style.main}>
                    <Row gutter={[60, 10]}>
                        <Col className="gutter-row" span={24}>
                            <div className={`${style.content} ${style.largecontent}`}>{data?.byReq}</div>
                        </Col>
                    </Row>
                </div> 
            </div>
            </div>
    );
}
