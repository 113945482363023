import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { getNormalData } from "../../../../../service/basic";
import useRequest from "../../../../../lib/useRequest";
import _ from "lodash";

var colorList = ['#16ea76 ', '#58c6ff', '#ff6262'];

export default function Chart5 () {
  const [data] = useRequest(() => getNormalData < any > ('dd9e515d-a116-11ec-ba43-005056958024'))//查询产业对接企业数量分布图

  const options: echarts.EChartOption<echarts.EChartOption.Series> = useMemo(() => {

    const max: any = _.max(data?.map((item: any) => Number(item.value))) || 5000
    const list = _.sortBy(data?.map((item: any) => ({ value: Number(item.value)+2737, name: item.name })), "value")
    return {
      grid: {
        top: 10
      },
      xAxis: {
        type: 'category',
        data: list.map(item => item.name),
        nameTextStyle: {
          color: "#08c"
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: "#666"
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#666"
          }
        }
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        max: 40000,
        splitNumber: 5,
        splitLine: {
          lineStyle: {
            color: "#ededed"
          }
        },
        axisLabel: {
          formatter: (value: number) => `${value / 10000} 万`
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#666"
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      series: [
        {
          type: "bar",
          cursor: 'default',
          data: list.map((item: any, index: number) => {
            return {
              value: item.value + 4460,
              itemStyle: {
                color: colorList[index],
                barBorderRadius: 3
              }
            }
          })
        }
      ]
    };
  }, [data])

  return (
    <ReactEcharts option={options as any} />
  );
}
