import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.scss"
import { Card, Space, Table } from 'antd';
import { useHistory } from "react-router-dom";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";
import MockTable3 from "./components/MockTable3";
import FirstScreen from "./components/map/map";
import useRequestFn from "../../../lib/useRequestFn";
import { getNormalData } from "../../../service/basic";
import useDevice from "../../../lib/useDevice";

export default function PagePracticeList () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "实习实训" }]);
  const history = useHistory();

  const [showTable1, setShowTable1] = useState(false);

  const [onDList, list] = useRequestFn(getNormalData)

  const [dataSource, setRequestSchoolList] = useState < any > (null)

  const { isMobile } = useDevice()

  async function onList () {
    let res: any = await onDList('ad6331d3-bd26-49c6-b293-06ac87e0f79a')
    let arr: any = []
    if (res)
    {
      res.map((item: any) => {
        arr.push(
          { col1: item.subject, col2: item.totalNum, col3: item.prictiseBaseNum, col4: item.schoolNum, col5: item.dgrs, col6: item.ggrs, col7: item.totalrs }
        )
      })
    }
    setRequestSchoolList(arr)
  }

  useEffect(() => {
    onList()
  }, [])

  //点击承载总人数
  const [name1, setName1] = useState < any > ('')
  let num1 = (val: any) => {
    setName1(val.col1)
  }
  //点击基地数量
  const [name2, setName2] = useState < any > ('')
  let num2 = (val: any) => {
    setName2(val.col1)
  }

  const [page, setPage] = useState < any > (1)

  const dataSource1: any[] = [
    { col1: "供用电技术", col2: 3412, col3: 17, col4: 3, col5: 2432, col6: 772, col7: 3204 },
    { col1: "电力系统自动化技术", col2: 2196, col3: 10, col4: 5, col5: 1132, col6: 971, col7: 2103 },
    { col1: "分布式发电与微电网技术", col2: 3197, col3: 8, col4: 2, col5: 1934, col6: 1132, col7: 3459 },
    { col1: "电厂热能动力装置", col2: 1052, col3: 4, col4: 1, col5: 721, col6: 221, col7: 932 },
    { col1: "风力发电工程技术", col2: 1143, col3: 2, col4: 1, col5: 632, col6: 219, col7: 849 },
    { col1: "黑色冶金技术 ", col2: 3891, col3: 3, col4: 5, col5: 1936, col6: 1822, col7: 3758 },
    { col1: "轧钢工程技术", col2: 6312, col3: 8, col4: 19, col5: 4318, col6: 1954, col7: 6272 },
    { col1: "材料工程技术", col2: 1395, col3: 3, col4: 2, col5: 883, col6: 351, col7: 1134 },
    { col1: "高分子材料工程技术", col2: 791, col3: 2, col4: 1, col5: 518, col6: 116, col7: 634 },
    { col1: "光伏材料制备技术", col2: 2315, col3: 4, col4: 3, col5: 1631, col6: 662, col7: 2292 },
    { col1: "建筑材料检测技术", col2: 6124, col3: 9, col4: 12, col5: 4187, col6: 1948, col7: 6135 },
  ];

  return (
    <div className={style.root}>

      <div style={{ height: isMobile ? '350px' : '650px' }}>
        <span className={style.mapTitle}>实习实训基地分布图</span>
        <FirstScreen
          showTimeLine={false}
          width={isMobile ? 'auto' : 780}
          height={isMobile ? 350 : 800} />
      </div>

      <div className={style.content}>
        <Space size="large" style={{ width: isMobile ? 'auto' : "100%" }} direction="vertical">
          {/*<Card title="实训条件统计">*/}
          {/*    <Row gutter={[15, 15]}>*/}
          {/*        <Col style={{width: "33.3333%"}}><Statistic top={<>258<span style={{fontSize: 16}}>个</span></>} bottom="校内实训基地" color={3} /></Col>*/}
          {/*        <Col style={{width: "33.3333%"}}><Statistic top={<>134<span style={{fontSize: 16}}>个</span></>} bottom="校外顶岗实习基地" color={1} /></Col>*/}
          {/*        <Col style={{width: "33.3333%"}}><Statistic top={<>352<span style={{fontSize: 16}}>个</span></>} bottom="虚拟仿真项目" color={4} /></Col>*/}
          {/*    </Row>*/}
          {/*</Card>*/}

          <Card title="" >
            <div className={style.table}>
              {dataSource && <Table dataSource={dataSource} pagination={false} >
                <Column title="专业" dataIndex="col1" key="col1" width={isMobile ? 100 : 180} fixed={isMobile ? "left" : false} />
                <Column title="承载总人数" dataIndex="col2" key="col2" align="center" render={(text, record) => (
                  <Space size="middle">
                    <a onClick={() => {
                      setShowTable1(true)
                      setPage(1)
                      num1(record)
                    }}>{text}</a>
                  </Space>
                )} />
                <Column title="基地数量" dataIndex="col3" key="col3" align="center" render={(text, record) => (
                  <Space size="middle">
                    <a onClick={() => {
                      setShowTable1(true)
                      setPage(2)
                      num2(record)
                    }}>{text}</a>
                  </Space>
                )} />
                <Column title="专业布点数" dataIndex="col4" key="col4" align="center" />
                <Column title="总数" dataIndex="col7" key="col7" align="center" />
                {/* <ColumnGroup title="接收人数">
                  <Column title="顶岗" dataIndex="col5" key="col5" align="center" />
                  <Column title="跟岗" dataIndex="col6" key="col6" align="center" />
                  <Column title="总数" dataIndex="col7" key="col7" align="center" />
                </ColumnGroup> */}
              </Table>}
            </div>
          </Card>
        </Space>
      </div>

      <MockTable3 visible={showTable1} onClose={() => setShowTable1(false)} name={page == 1 ? name1 : name2} />
    </div>
  );
}
