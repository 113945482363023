import React, { useEffect, useState } from "react";
import { Button, Row, Col, Spin } from 'antd';
import style from './index.module.scss'
import BaseInfo from "./components/BaseInfo";
import Goal from "./components/Goal";
import Content from "./components/Content";
import { useHistory } from "react-router-dom"
import useQuery from "../../../lib/useQuery";
import useRequest from "../../../lib/useRequest";
import { getCourseTrainingProgram } from "../../../service/training";
import CourseQuality from "./components/CourseQuality";
import CourseMentality from "./components/CourseMentality";
import CourseRequire from "./components/CourseRequire";
import CoursePropose from "./components/CoursePropose";
import CourseAppraise from "./components/CourseAppraise";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import useRequestFn from "../../../lib/useRequestFn";
import { getNormalData } from "../../../service/basic";

export default function CourseDetail () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "详细" }]);
  const [current, setCurrent] = useState('1');
  const history = useHistory();
  const { courseId } = useQuery();
  const [datas, setData] = useState()
  // const [ dataDetail ] = useRequest(() => getCourseTrainingProgram(courseId));
  const [fuDelData, data] = useRequestFn(() => getNormalData < any > ('9d7cff83-3dfc-477c-b46b-f9254e478233', { courseId: courseId }))
  useEffect(() => {
    fuDelData()
  }, [])

  const dataDetail = data.data

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.title}>
          <div className={style.professionaltitle}>
            <div className={style.school}>
              {dataDetail?.courseBase.school_name}({dataDetail?.courseBase.school_code})
            </div>

            <div className={style.major}>
              {dataDetail?.courseBase.subject_name}({dataDetail?.courseBase.subject_code})
            </div>

            {dataDetail?.courseBase?.course_name}
          </div>
          <div className={style.titlebutton}>
            <Button onClick={() => history.push(`/training/CoursesCompare?courseId=${courseId}&school=${dataDetail?.courseBase.school_name}&major=${dataDetail?.courseBase.subject_name}`)}>PK 课程对比</Button>
          </div>
        </div>
        <div className={style.tabs}>
          <Row gutter={10}>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '1' && style.current} ${style.tab} ${style.tab_one}`} onClick={() => { setCurrent('1') }}>基本信息</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '2' && style.current} ${style.tab} ${style.tab_one}`} onClick={() => { setCurrent('2') }}>课程性质</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '3' && style.current} ${style.tab}`} onClick={() => { setCurrent('3') }}>课程设计思路</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '4' && style.current} ${style.tab} ${style.tab_one}`} onClick={() => { setCurrent('4') }}>课程目标</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '5' && style.current} ${style.tab} ${style.tab_line}`} onClick={() => { setCurrent('5') }}>课程内容组织与安排</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '6' && style.current} ${style.tab} ${style.tab_line}`} onClick={() => { setCurrent('6') }}>课程内容与教学要求</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '7' && style.current} ${style.tab}`} onClick={() => { setCurrent('7') }}>课程教学实施建议</div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div className={`${current === '8' && style.current} ${style.tab} ${style.tab_one}`} onClick={() => { setCurrent('8') }}>考核评价</div>
            </Col>
          </Row>
        </div>
      </div>
      <Spin tip="Loading..." spinning={dataDetail ? false : true}>
        <div className={style.tabContent}>
          {current === '1' && <BaseInfo datas={dataDetail} />}
          {current === '2' && <CourseQuality datas={dataDetail} />}
          {current === '3' && <CourseMentality datas={dataDetail} />}
          {current === '4' && <Goal goalDetail={dataDetail?.courseGoal || []} />}
          {current === '5' && <Content contentDetail={dataDetail?.coursePlan || []} />}
          {current === '6' && <CourseRequire contentDetail={dataDetail?.coursePlan || []} />}
          {current === '7' && <CoursePropose datas={dataDetail} />}
          {current === '8' && <CourseAppraise datas={dataDetail} />}
        </div>
      </Spin>
    </div>
  );
}
