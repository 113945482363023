import React, { useEffect, useState } from "react";
import style from '../../../Special/components/basic/index.module.scss'
import { Col, Row } from "antd";
import { getCourseTrainingProgram } from "../../../../../service/training";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import { getNormalData } from "../../../../../service/basic";

interface CourseIdProps {
  datas: any
}

export default function Coursequality ({ datas }: CourseIdProps) {


  let dataDetail = datas
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.main}>
          <Row gutter={[60, 10]}>
            <Col className="gutter-row" span={8}>
              <div className={style.title}>课程性质</div>
              <div className={style.content}>{dataDetail?.courseBase?.course_quality}</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className={style.title}>课程性质描述</div>
              <div className={`${style.content} ${style.largecontent}`}>{dataDetail?.courseBase?.kcxz_ms}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
