import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './index.module.scss';
import close from "./images/close.png";
interface StatisticProps {
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  text?: React.ReactNode;
  color?: 0 | 1 | 2 | 3 | 4 | 5;
  align?: "flex-start" | "center" | "flex-end";
  onClick?: () => void;
  onRemove?: () => void;
}

const Statistic = ({ top, bottom, text, align = "flex-start", color = 1, onClick, onRemove }: StatisticProps) => {
  return (
    <div className={styles.root}>
      <div
        onClick={onClick}
        style={{ alignItems: align }} className={`${styles.statistic} ${styles[`color${color}`]} ${onClick ? styles.pointer : ""}`}>
        {top && <div className={styles.number} >{top}</div>}
        {text && <div className={styles.text} >{text}</div>}
        {bottom && <div className={styles.desc}>{bottom}</div>}
      </div>

      {onRemove && <div onClick={onRemove} className={styles.remove}>
        {/* <CloseOutlined /> */}
        <img src={close} alt="" />
      </div>}
    </div>
  )
}

export default Statistic;