import ReactEcharts from "echarts-for-react";
import { useHistory } from "react-router";
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";

var colorList = ['#74FFFB', '#FFA881', '#469AF0', '#8D82F3', '#83A6FE', '#1EBABC', '#82E3A2', '#F5BE5C']
var colorList2 = ['#17b6d3', '#0090ba', '#0084e9', '#0841bc', '#5e62ff', '#a48bff', '#5ebbef']

var option = {
    color: colorList,
    title: {
        text: '',
        subtext: '',
        left: 'center',
        textStyle: {
            color: '#fff',
            fontSize: 18
        }
    },
    series: [
        {
            name: '三级指标',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['60%', '80%'],
            itemStyle: {
                normal: {
                    // 设置扇形的阴影
                    shadowBlur: 10,
                    shadowColor: '#666'
                }
            },
            label: {
                normal: {
                    position: 'outer'
                }
            },
            data: [
                { name: "国家级", value: 9 },
                { name: "省级", value: 27 },
                { name: "其他", value: 6 },
            ],
        },
        {
            name: '二级指标',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '50%'],
            color: colorList2,
            label: {
                normal: {
                    position: 'inner'
                }
            },
            itemStyle: {
                normal: {
                    // 设置扇形的阴影
                    shadowBlur: 10,
                    shadowColor: '#666'

                }
            },
            data: [
                { name: "市属公办", value: 16 },
                { name: "民办", value: 8 },
                { name: "省属公办", value: 19 },
            ],
        }
    ]
}

interface IProps {
    title: string;
    style?: any;
}
export default function Index({ style }: IProps) {
    const history = useHistory()
    const [data] = useRequest(() => getNormalData<{data1: any, data2: any}>("5fd20394-7caa-4046-8cbf-1928b31fa906")); // 院校办学情况分布图

    return (
        <>
            {data && (
                <ReactEcharts
                    onEvents={{"click": () => history.push("/basic/main")}}
                    theme={"theme"}
                    style={style}
                    option={{
                        ...option,
                        series: [
                            {
                                ...option.series[0],
                                data: data.data1
                            },
                            {
                                ...option.series[1],
                                data: data.data2
                            },
                        ]
                    }}
                />
            )}
        </>
    );
}
Index.defaultProps = {
    title: "",
};
