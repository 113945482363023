import React, { useState } from "react";
import style from './school.module.scss'
import BasicTable from "./components/BasicTable";
import ProfessionalInfo from "./components/ProfessionalInfo";
import TrainingList from "./components/TrainingList";
import useQuery from "../../../lib/useQuery";
import CourseListTable from "../../Course/List/components/CourseListTable";
import PracticeListTable from "../../Practice/List/components/PracticeListTable";
import EmploymentListTable from "../../Employment/List/components/EmploymentListTable";
import ServiceListTable from "../../Service/List/components/ServiceListTable";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import Button from "antd/lib/button/button";
import { Typography } from "antd";

export default function PageSchoolDetail () {

  const { id, tab = "1" } = useQuery();
  const { name } = useQuery();

  const [current, setCurrent] = useState(tab);

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "基本情况", url: "/basic/list" }, { name: "专业信息" }]);

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.title}>
          <div className={style.header}>
            <h1>{name}</h1>
            {/*<Button>PK培养方案对比</Button>*/}
          </div>
        </div>
        {/*简介不要start*/}
        {/*<div>*/}
        {/*    <div style={{padding: 0}} className={style.title}>*/}
        {/*        <h2>一、学校简介</h2>*/}
        {/*    </div>*/}
        {/*    <div className={style.schoolDetail}>*/}
        {/*        <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '展开' }}>*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*            asdfasdfasjdflkajsflkasj;flkasjf;laskdjfasl;kfjaskl;dfjas;lkfjaslk;fjasklfjaslkfjaslkfjasl;fjsa;dlkfjas;lkfjas;ldk*/}
        {/*        </Typography.Paragraph>*/}
        {/*    </div>*/}
        {/*    <div style={{padding: 0}} className={style.title}>*/}
        {/*        <h2>二、专业信息</h2>*/}
        {/*    </div>*/}
        {/*    <div></div>*/}
        {/*</div>*/}
        {/*简介不要end*/}

        {/* <div className={style.tabs}>
                    <div className={`${current === '1'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('1')}}>基本情况</div>
                    <div className={`${current === '2'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('2')}}>专业信息</div>
                    <div className={`${current === '3'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('3')}}>培养方案</div>
                    <div className={`${current === '4'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('4')}}>课程信息</div>
                    <div className={`${current === '5'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('5')}}>实习实训</div>
                    <div className={`${current === '6'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('6')}}>就业跟踪</div>
                    <div className={`${current === '7'&& style.current} ${style.tab}`} onClick={() =>{setCurrent('7')}}>社会服务</div>
                </div> */}
      </div>

      <div className={style.tabContent} style={{ paddingRight: 60 }}>
        <ProfessionalInfo id={id} />
        {/* {current === '1'&& <BasicTable id={id} />}
                {current === '2' && <ProfessionalInfo />}
                {current === '3' && <TrainingList />}
                {current === '4' && <CourseListTable hideSchool />}
                {current === '5' && <PracticeListTable />}
                {current === '6' && <EmploymentListTable />}
                {current === '7' && <ServiceListTable hideSchool />} */}
      </div>
    </div>
  );
}
