import React, { useEffect, useState } from "react";
import style from "./index.module.scss"
import { RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Select, Row, Col, Image, Table, Space } from 'antd';
import { useHistory } from "react-router-dom";
import useQuery from "../../../lib/useQuery";
import useRequest from "../../../lib/useRequest";
import { findSpecialtyListByName } from "../../../service/major";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import useDevice from "../../../lib/useDevice";

export default function PageEmploymentSearch () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "就业跟踪", url: "/employment/list" }, { name: "搜索" }]);
  const { name, school } = useQuery < { name: string, school: string } > ()

  const history = useHistory();
  const [form] = Form.useForm();

  const [list, { loading }] = useRequest(() => findSpecialtyListByName(name, school), {}, [name, school])

  useEffect(() => {
    form.setFieldsValue({ name, school })
  }, [name, school])

  const onSearch = ({ name, school }: { name?: string, school?: string }) => {
    history.replace(`/employment/search?name=${name || ""}&school=${school || ""}`)
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 70
    },
    {
      title: '专业名称',
      dataIndex: 'subjectName',
    },
    {
      title: '学校名称',
      dataIndex: 'schoolName',
    },
    {
      title: '专业方向',
      dataIndex: 'subjectDirection',
    },
    {
      title: '入学要求',
      dataIndex: 'inRequirements',
    },
    {
      title: '学习年限',
      dataIndex: 'restYear',
      sorter: {
        compare: (a: any, b: any) => parseInt(a.restYear) - parseInt(b.restYear),
      },
      render: (year: string) => `${year}年`
    },
    {
      title: '查看详情',
      render: (text: any, { id }: { id: string; }) => <Space size="middle">
        <Button className={style.tableButton} onClick={() => history.push(`/employment/detail?id=${id}`)}>
          详细
        </Button>
      </Space>,
    },
  ];

  const { isMobile } = useDevice();

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.search}>
          <Form onFinish={onSearch} form={form} labelCol={{ span: 4 }}>
            <Row gutter={24}>
              <Col span={isMobile ? 24 : 6}>
                <div className={style.title}><span>搜索结果</span></div>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Col span={22}>
                  <Form.Item initialValue={name} name="name" label="专业名称" labelCol={{ span: 8 }}>
                    <Input placeholder="请输入专业名称" />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <Col span={22}>
                  <Form.Item name="school" initialValue={school} label="学校名称" labelCol={{ span: 8 }}>
                    <Input placeholder="请输入学校名称" />
                  </Form.Item>
                </Col>
              </Col>

              <Col span={isMobile ? 24 : 6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginRight: "20px" }}>
                  查询
                </Button>
                <Button icon={<RetweetOutlined />} onClick={() => { form.resetFields(); form.setFieldsValue({ name: "", school: "" }) }}>
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className={style.list}>
          <Table
            rowKey="id"
            loading={loading}
            className={style.tableContent}
            columns={columns}
            dataSource={list || []}
          />
        </div>


      </div>

    </div>
  );
}
