import React, {useContext, useState} from "react";
import {Breadcrumb, Button, Drawer, Layout, Menu} from "antd";
import style from "./index.module.scss";
import Config from "../../config";
import {SelectInfo} from "rc-menu/lib/interface";
import {Link, useHistory} from "react-router-dom";
import HeaderRight from "./components/HeaderRight";
import ToggleBtn from "./components/ToggleBtn";
import {FaServer} from "react-icons/fa";
import {AdminContext} from "../../AdminContext";
import Banner from "./components/Banner";
import useDevice from "../../lib/useDevice";
import HeaderMobile from "./components/HeaderMobile";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface BaseLayoutProps {
    children: React.ReactNode;
}

export default function BaseLayout({children}: BaseLayoutProps) {

    const history = useHistory();
    const {breadcrumb} = useContext(AdminContext);
    const selectMenu = history.location.pathname;
    const {isPC} = useDevice();
    const [showMenu, setShowMenu] = useState(false);

    function renderMenu(menu: any) {
        if(!menu.name) return ;
        return <Menu.Item key={menu.path} icon={<menu.icon />}>{menu.name}</Menu.Item>
    }

    function renderRootMenu() {
        return (
            <Menu mode="inline"
                  defaultSelectedKeys={[selectMenu]}
                  defaultOpenKeys={getPaths()}
                  style={{ height: '100%', borderRight: 0 }}
                  onClick={(e:any) => onMenuSelect(e)}

            >
                {Config.Menu.map(menu => (
                    menu.component ? renderMenu(menu) : renderSubMenu(menu)
                ))}
            </Menu>
        )
    }

    function renderSubMenu(menu: any) {
        return (
            <SubMenu key={menu.path} icon={<menu.icon />} title={menu.name}>
                {menu.routes.map((subMenu: any) => (
                    subMenu.name && <Menu.Item key={`${!isOutSite(subMenu.path) ? menu.path : ""}${subMenu.path}`}>{subMenu.name}</Menu.Item>
                ))}
            </SubMenu>
        );
    }

    // 判断是否外站链接
    function isOutSite(url: string) {
        return url.substr(0, 4) === "http";
    }

    // 假的, 先凑合着
    function getPaths() {
        return [`/${selectMenu.split("/")[1]}`];
    }

    function onMenuSelect(info: SelectInfo) {
        setShowMenu(false);
        const url = info.key.toString();

        if(isOutSite(url)) {
            window.open(url);
        } else {
            history.push(url);
        }
    }

    return (
        <Layout className={style.root}>
            {isPC && <Banner />}

            {isPC && (
                <Header className={style.header}>
                    <div className={style.logo}>
                        辽宁省职业教育大数据平台
                    </div>

                    <ToggleBtn />
                    <HeaderRight />
                </Header>
            )}

            <Layout>
                {isPC && (
                    <Sider width={225} className={style.Sider} breakpoint="xs" collapsedWidth="0">
                        {renderRootMenu()}
                    </Sider>
                )}

                {!isPC && (
                    <HeaderMobile onMenu={() => setShowMenu(true)}/>
                )}

                {!isPC && (
                    <Drawer className={style.drawer} placement="left" onClose={() => setShowMenu(false)} visible={showMenu} closable={false}>
                        {renderRootMenu()}
                    </Drawer>
                )}

                <Layout className={style.content}>
                    {breadcrumb.length && (
                        <Breadcrumb className={style.Breadcrumb}>
                            {breadcrumb.map(item => (
                                <Breadcrumb.Item key={item.name}>
                                    {item.url && <Link to={item.url}>{item.name}</Link>}
                                    {!item.url && <span>{item.name}</span>}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    )}

                    <Content>
                        {children}

                        {/*<div style={{textAlign: "center"}}>*/}
                        {/*    <div>Copyright © 辽宁省教育厅高职管理中心 All Rights Reserved.</div>*/}
                        {/*    <div>技术支持：辽宁省教育厅高职管理中心</div>*/}
                        {/*</div>*/}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}


