import React, { useEffect } from "react";
import style from './index.module.scss'
import useRequest from "../../../../../lib/useRequest";
import { findSpecialtyListBySchoolId, SpecialtyListItem } from "../../../../../service/major";
import useQuery from "../../../../../lib/useQuery";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { getNormalData } from "../../../../../service/basic";
import useDevice from "../../../../../lib/useDevice";

interface ProfessionalProp {
  id: string
}

export default function ProfessionalInfo ({ id }: ProfessionalProp) {
  const { isMobile } = useDevice()

  const history = useHistory();
  // const [data] = useRequest(() => findSpecialtyListBySchoolId(id));
  const [data] = useRequest(() => getNormalData < any > ('2034f855-442c-4853-a745-a419b46e3558', { schoolId: id }))//专业信息-跳转列表(

  useEffect(() => {

  }, [data])
  function gotoPath (zyid: string) {
    history.push(`/training/special?id=${zyid}`);
  }

  function renderData (data: SpecialtyListItem) {

    return (
      <>
        {data.child.map((item: any, index) => (
          <tr className={style.alt}>

            {index === 0 && <td rowSpan={data.child.length}>{data.subjectCode}</td>}
            {index === 0 && <td rowSpan={data.child.length} style={{ borderRight: "1px solid #e9e9e9" }}>{data.subjectName}</td>}

            <td>{item.zyfx}</td>
            <td>{item.enrollType}</td>
            <td>{item.restYear}</td>
            <td>{item.xjpgResult || "评估进行中"}</td>
            <td><Button type="link" style={{ padding: "0 5px", color: "#6c97d2", height: "auto", fontSize: 12 }} onClick={() => gotoPath(item.id)}>点击查看详情</Button></td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <div style={{ overflow: isMobile ? 'auto' : 'none' }}>
      <div className={style.infoTitle}>
        <div className={style.blueline} />
        <h1>专业信息</h1>
      </div>

      <table className={style.customers}>
        <tr>
          <th>专业代码</th>
          <th>专业名称</th>
          <th>专业方向</th>
          <th>招生方式</th>
          <th>学制</th>
          <th>星级评估结果</th>
          <th>查看详情</th>
        </tr>
        {(data?.data || []).map((item: SpecialtyListItem) => renderData(item))}
      </table>
    </div>
  );
}
