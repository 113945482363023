import React, { useEffect, useState } from 'react';

import { UnorderedListOutlined, ColumnHeightOutlined, ColumnWidthOutlined, FilterOutlined, TableOutlined } from '@ant-design/icons';
import { Modal, Select, Row, Col, Button, Checkbox, Input, DatePicker, Radio, message, Space, Form } from 'antd';
import moment from 'moment';

import { ReactSortable, Sortable } from "react-sortablejs";
import locale from 'antd/es/date-picker/locale/zh_CN';

import 'moment/locale/zh-cn';
import style from './index.module.scss';
import useRequest from '../../lib/useRequest';
import Empty from 'antd/es/empty';
import { FieldType, findCustomCubeList, findReportFieldsByCubeId, saveReportDesign } from '../../service/report';
import useRequestFn from '../../lib/useRequestFn';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import _ from 'lodash';

interface Option {
  title: string;
  dataIndex: string;
}
interface Field {
  id: string;
  dataIndex: string;
  title: string;
  type: FieldType;
  list?: Option[];
}
interface Measure {
  dataIndex: string;
  title: string;
}
interface DragTransferProps {
  visible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  needField?: any
}


const DragTransfer = ({ visible, onCancel, onOk, needField = true }: DragTransferProps) => {

  const [form] = Form.useForm < { [key: string]: string| [moment.Moment | undefined, moment.Moment | undefined]
}> ()

const [cubeId, setCubeId] = useState("")
const [title, setTitle] = useState("")

const [allFields, setAllFields] = useState < Field[] > ([])

const [fields, setFields] = useState < Field[] > ([])
const [columns, setColumns] = useState < Field[] > ([])
const [rows, setRows] = useState < Field[] > ([])
const [measures, setMeasures] = useState < Measure[] > ([])
const [filters, setFilter] = useState < Field[] > ([])
const [measureValue, setMeasureValue] = useState("")

const [cubeList] = useRequest(findCustomCubeList)
const [getSettings, { data: designData, loading }] = useRequestFn(findReportFieldsByCubeId)
const [save, { loading: saveLoading }] = useRequestFn(saveReportDesign)



useEffect(() => {
  if (!designData) return
  setFields(designData.fields)
  setMeasures(designData.measures)
  setAllFields(designData.fields)
  setColumns([])
  setRows([])
  setMeasureValue("")
  setFilter([])
  form.resetFields()
}, [designData])


const handleOk = () => {

  if (!title)
  {
    message.error("名称不能为空！")
    return;
  }

  if (!cubeId)
  {
    message.error("立方体必选！")
    return;
  }

  if (columns.length === 0 || rows.length === 0)
  {
    message.error("行和列不能为空！")
    return;
  }

  if (!measureValue)
  {
    message.error("度量必选！")
    return;
  }

  const filters = _.map(form.getFieldsValue(), (value, key) => {
    if (_.isArray(value))
    {
      const data = value.filter(item => item)
      return { dataIndex: key, value: data.map(item => moment(item).format("YYYY-MM-DD")).join(",") }
    }
    return { dataIndex: key, value: value }
  })

  save({
    name: title, tjCubeId: cubeId,
    columns: columns.map(item => item.dataIndex).join(","),
    rows: rows.map(item => item.dataIndex).join(","),
    measure: measureValue,
    filters: JSON.stringify(filters)
  }).then(() => {
    message.success("创建成功！")
    onOk?.()
  })
}


const handleFilter = (values: CheckboxValueType[]) => {
  const filterlist = values.map(dataIndex => {
    return filters.find(filter => filter.dataIndex === dataIndex) || allFields.find(fields => fields.dataIndex === dataIndex)!
  })
  setFilter(filterlist)
}


const handleOnCubeChange = (id: string) => {
  setCubeId(id)
  getSettings(id)
}

return (
  <Modal width={needField ? 1062 : 635} className={style.modal} title="新建" onCancel={onCancel} visible={visible}

    footer={[
      <Button key="back" onClick={onCancel}>取消</Button>,
      <Button key="submit" type="primary" loading={saveLoading} onClick={handleOk}>保存</Button>
    ]}
  >
    <Space style={{ width: "100%" }} direction="vertical" size="middle">
      <Row gutter={[15, 15]}>
        <Col span={16}>
          <Input value={title} onChange={e => setTitle(e.target.value)} placeholder="请输入统计名称" />
        </Col>
        <Col span={8}>
          <Select onChange={handleOnCubeChange} style={{ width: "100%" }}>
            {cubeList?.map(cube => <Select.Option key={cube.id} value={cube.id}>{cube.name}</Select.Option>)}
          </Select>
        </Col>
      </Row>
      {!designData && <Empty description="请先选择数据模型" />}
      {designData && <Row gutter={[14, 15]}>
        <Col>
          <div className={style.dragTransferFields}>
            <div className={style.title}><UnorderedListOutlined /> 字段</div>
            <ReactSortable animation={200} className={style.transferContent} group={{ name: "drag" }} list={fields} setList={setFields} >
              {fields.map(item => (
                <div key={`field-${item.dataIndex}`} className={style.dragTransferItem}>{item.title}</div>
              ))}
            </ReactSortable>
          </div>
        </Col>
        <Col>
          <div className={style.dragTransfer}>
            <div className={style.title}><ColumnHeightOutlined /> 列</div>
            <ReactSortable animation={200} className={style.transferContent} group={{ name: "drag" }} list={columns} setList={setColumns} >
              {columns.map(item => (
                <div key={`column-${item.dataIndex}`} className={style.dragTransferItem}>{item.title}</div>
              ))}
            </ReactSortable>
          </div>
          <div className={style.dragTransfer}>
            <div className={style.title}><ColumnWidthOutlined /> 行</div>
            <ReactSortable animation={200} className={style.transferContent} group={{ name: "drag" }} list={rows} setList={setRows} >
              {rows.map(item => (
                <div key={`row-${item.dataIndex}`} className={style.dragTransferItem}>{item.title}</div>
              ))}
            </ReactSortable>
          </div>
        </Col>
        <Col>
          <div className={style.dragTransfer}>
            <div className={style.title}><TableOutlined /> 度量</div>
            <Radio.Group className={style.transferContent} onChange={e => setMeasureValue(e.target.value)} value={measureValue}>
              {measures.map(item => {
                return <div key={`measure-${item.dataIndex}`} className={style.dragTransferItem}><Radio value={item.dataIndex}>{item.title}</Radio></div>
              })}
            </Radio.Group>
          </div>
          <div className={style.dragTransfer}>
            <div className={style.title}><FilterOutlined /> 筛选</div>
            <div className={style.transferContent}>
              <Checkbox.Group style={{ width: "100%" }} value={filters.map(item => item.dataIndex)} onChange={handleFilter}>
                {allFields.map(field => {
                  return <div key={`filter-${field.dataIndex}`} title={`filter-${field.dataIndex}`} className={style.dragTransferItem}><Checkbox style={{ width: "100%" }} value={field.dataIndex}>{field.title}</Checkbox></div>
                })}
              </Checkbox.Group>
            </div>
          </div>
        </Col>
        <Col className={style.filterList}>
          {filters.length === 0 && <Empty description="暂无筛选" />}
          <Form preserve={false} form={form}>
            {filters.map(newItem => {
              // return (
              //         <Form.Item label={newItem.title} key={`filterlist-${newItem.dataIndex}`} name={newItem.dataIndex}>
              //             <DatePicker.RangePicker allowEmpty={[true, true]} allowClear={true} locale={locale}/>
              //         </Form.Item>
              // )
              if (newItem.type === FieldType.Input)
              {
                return (
                  <Form.Item label={newItem.title} key={`filterlist-${newItem.dataIndex}`} name={newItem.dataIndex}>
                    <Input />
                  </Form.Item>
                )
              }
              if (newItem.type === FieldType.DateTime)
              {
                return (
                  <Form.Item label={newItem.title} key={`filterlist-${newItem.dataIndex}`} name={newItem.dataIndex}>
                    <DatePicker.RangePicker allowEmpty={[true, true]} allowClear={true} locale={locale} />
                  </Form.Item>
                )
              }
              if (newItem.type === FieldType.Select)
              {
                return (
                  <Form.Item label={newItem.title} key={`filterlist-${newItem.dataIndex}`} name={newItem.dataIndex}>
                    <Select mode="multiple">
                      {newItem.list?.map(option => <Select.Option key={`option-${newItem.dataIndex}-${option.dataIndex}`} value={option.dataIndex}>{option.title}</Select.Option>)}
                    </Select>
                  </Form.Item>
                )
              }
            })}
          </Form>

        </Col>
      </Row>}
    </Space>
  </Modal>
)
}

export default DragTransfer;