import ReactEcharts from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import useRequest from "../../../../../lib/useRequest";
import { getNormalData } from "../../../../../service/basic";
import { teacherAreaHistogram } from "../../../../../service/chart";

import Style from "../../index.module.scss"
interface iPops {
  flowData: any,
}
let colorList = [
  "#f77070",
  "#326dce",
  "#468cff",
  "#56cefd",
  "#84cf61",
  "#ffad56",
  "#9bca63",

];
export default function Index (props: any) {
  const [data] = useRequest(() => getNormalData('ad2bd0b4-b56c-4e5e-80d6-215d1b2665ca'))

  const options: any = useMemo(() => {

    return {
      color: colorList,
      tooltip: {
        trigger: 'item',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'line' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: "{b} : {c} ({d}%)"
      },
      grid: { //网格大小
        x: 150,
        y: 15,
        x2: 1,
        y2: 20,
        borderWidth: 1,
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          data: data ? data : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }
  }, [data])

  return (
    <div >
      <ReactEcharts
        style={{ height: '250px' }}
        option={options}
      />
    </div>
  );
}
