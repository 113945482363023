import React, { useEffect, useMemo, useState } from "react";
import Province from "../Province/index";
import {
  constMoney, getDoubleLevelList,
  getNationalLevelCount,
  getProvincialLevelCount,
  getSchoolInfoList, IDataTypes,
  INewDataTypes, ISchoolInfo, newStudentCount, schoolCount, teacherCount
} from "../../../../../service/chart";
import useRequest from "../../../../../lib/useRequest";
import useRequestFn from "../../../../../lib/useRequestFn";
import cityCoordData from "../../../../../assets/cityCoord";
import { getNormalData } from "../../../../../service/basic";

interface IProps {
  showTimeLine: boolean;
  width: any;
  height: number;
}

export default function Map ({ showTimeLine, width, height }: IProps) {

  const [cityName, setCityName] = useState(""); // 点击地图返回城市名

  // 第一屏
  const [schoolData] = useRequest(() => schoolCount()); // 学校数量
  const [newStuCountData] = useRequest(() => newStudentCount()); // 招生人数;
  const [teacherData] = useRequest(() => teacherCount()); // 教师数量
  const [constMoneyData] = useRequest(() => constMoney()); // 投入情况
  const [firstData, setFirstData] = useState < INewDataTypes[] > ([]);

  //
  const [schoolList, setSchoolList] = useState < any > (null);
  const [requestSchoolList] = useRequest(() => getSchoolInfoList(cityName), {}, [cityName]);
  const [schoolListSpecialFn, { loading, data: schoolListSpecial }] = useRequestFn(getDoubleLevelList);

  //各省实习院校实习情况
  const [data] = useRequest(() => getNormalData < any > ("6ae61453-006a-417c-a547-d6508f6485fd"));
  // const [datas] = useRequest(() => getNormalData < any > ("1e71289e-a00c-4473-8ec9-a4274b2a26f6"));
  const datas: any = [];

  //各省实习院校实习情况(弹出列表)
  const [onDel, del] = useRequestFn(getNormalData)
  async function onFn (val: any) {
    let res = await onDel < any > ('4708d0fc-4a56-4574-af11-88973cabb1e5', { area_code: val })
    setSchoolList(res)
  }

  const blueCountData = [
    {
      areaCode: "沈阳市",
      areaColor: "#f8f8f8",
      schCount: '7982'
    },
    {
      areaCode: "大连市",
      areaColor: "#f8f8f8",
      schCount: '4081'
    },
    {
      areaCode: "鞍山市",
      areaColor: "#f8f8f8",
      schCount: '0'
    },
    {
      areaCode: "抚顺市",
      areaColor: "#f8f8f8",
      schCount: '1025'
    },
    {
      areaCode: "铁岭市",
      areaColor: "#f8f8f8",
      schCount: '2199'
    },
    {
      areaCode: "丹东市",
      areaColor: "#f8f8f8",
      schCount: '1421'
    },
    {
      areaCode: "本溪市",
      areaColor: "#f8f8f8",
      schCount: '725'
    },
    {
      areaCode: "辽阳市",
      areaColor: "#f8f8f8",
      schCount: '1123'
    },
    {
      areaCode: "营口市",
      areaColor: "#f8f8f8",
      schCount: '1298'
    },
    {
      areaCode: "盘锦市",
      areaColor: "#f8f8f8",
      schCount: '663'
    },
    {
      areaCode: "阜新市",
      areaColor: "#f8f8f8",
      schCount: '532'
    },
    {
      areaCode: "锦州市",
      areaColor: "#f8f8f8",
      schCount: '2578'
    },
    {
      areaCode: "朝阳市",
      areaColor: "#f8f8f8",
      schCount: '762'
    },
    {
      areaCode: "葫芦岛市",
      areaColor: "#f8f8f8",
      schCount: '390'
    },
  ];
  const [redCountData] = useRequest(() => getNationalLevelCount());

  //默认数据接口
  // useEffect(() => {
  //   if (requestSchoolList)
  //   {
  //     setSchoolList(requestSchoolList);
  //   }
  // }, [requestSchoolList]);

  useEffect(() => {
    if (schoolData && newStuCountData && teacherData && constMoneyData)
    {
      const data1 = formatData(schoolData, '学校数量');
      const data2 = formatData(newStuCountData, '招生人数');
      const data3 = formatData(teacherData, '教师人数');
      const data4 = formatData(constMoneyData, '投入情况');
      setFirstData([...data1, ...data2, ...data3, ...data4]);
    }
  }, [schoolData, newStuCountData, teacherData, constMoneyData]);

  function formatData (data: IDataTypes[], name: string) {
    return data.map(item => {
      return {
        name: item.name,
        coord: cityCoord(item.name),
        value: parseFloat(item.value),
        circleColor: item.circleColor ? item.circleColor : '#f00',
        areaColor: item.areaColor ? item.areaColor : '#f8f8f8',
        year: name
      }
    });
  }

  function cityCoord (cityName: string) {
    const cityInfo = cityCoordData.filter(item => item.name === cityName);
    if (cityInfo.length === 0) return [];
    return cityInfo[0].coord;
  }

  function getSchoolList (val: string, level: string) {
    onFn(val)
    setCityName(val)
    // if (!level) setCityName(val);
    // if (level)
    // {
    //   schoolListSpecialFn(val, level);
    // }
  }

  function onClean () { // 清除弹出层表单数据
    setSchoolList([]);
  }

  return <div style={{ paddingTop: 10 }}>
    {firstData && (
      <Province tableType={1} name='辽宁' width={width} height={height} data={firstData}
        year={["学校数量", "招生人数", "教师人数", "投入情况"]}
        geoColor='27, 94, 193'
        schoolList={schoolList}
        changeCityName={(val, levelName) => {
          getSchoolList(val, levelName)
        }}
        pointsBlue={datas?.map((item: any) => {
          return {
            areaCode: item.label + '市',
            schCount: item.companyNum
          }
        })}
        pointsRed={data?.map((item: any) => {
          return {
            areaCode: item.area_code,
            schCount: item.subjectNum
          }
        })}
        schoolListSpecial={schoolListSpecial || []}
        showTimeLine={showTimeLine}
        onClean={onClean}
      />
    )}
  </div>
}

Map.defaultProps = {
  showTimeLine: true,
  width: 760,
  height: 650
}
