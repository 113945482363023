/**
 * 特色理工： 专业总数，专业布点数 、学生总数
 */
import useRequest from "../../../../../../../../lib/useRequest";
import { getNormalData } from "../../../../../../../../service/basic";
import style from "./TitleCount.module.scss"
import majorCount from "./images/majorCount.png";
import studentCount from "./images/studentCount.png";
import typeCount from "./images/typeCount.png";
import { Input } from "antd";

interface Props{
    onClick: (key: string) => void;
}

export default function TitleCount({onClick}: Props) {
    const { Search } = Input;
    const [modalData] = useRequest(() => getNormalData < any > ('0f7784c6-d1b7-11ec-ba43-005056958024',{}))//产业人才对接统计表

    return (
        <div className={style.titleCount}>
            <div className={style.titleCountEtem}>
                <img src={majorCount} alt="" />
                <p>专业总数 </p>
                <span>{modalData && modalData[0].count}</span>
            </div>
            <div className={style.rule}></div>
            <div className={style.titleCountEtem}>
                <img src={typeCount} alt="" />
                <p>专业布点数</p>
                <span>{modalData && modalData[1].count}</span>
            </div>
            <div className={style.rule}></div>
            <div className={style.titleCountEtem}>
                <img src={studentCount} alt="" />
                <p>学生总数</p>
                <span>{(modalData && modalData[2].count) || 0}</span>
            </div>
            <div className={style.searchWrapper}>
                <Search className={style.search} placeholder="请输入要查询的专业" onSearch={onClick} />
            </div>

        </div>
    )
}
