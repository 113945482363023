import axios from "axios";
import qs from "qs";
import Config from "../config";


/**
* 统计项接口
*/
export function findCountValue(guid: string) {
  return axios.post<SuccessData<{ countValue: string }>>(`/olap/dynamicsCount/findCountValue`, qs.stringify({ guid }))
    .then(res => res.data.data);
}

export function getSg2Num() {
  return axios.post<SuccessData<any>>(`/lndc/getSg2Num`)
    .then(res => res.data.data);
}




interface ReportDefine {
  guid: string;
  name: string;
}

/**
 * 取得所有用户自定义多维报表信息
 */
export function findCustomTableList() {
  return axios.post<SuccessData<{ reportDefineList: ReportDefine[] }>>(`/olap/reportDefine/findCustomTableList`)
    .then(res => res.data.data.reportDefineList);
}

export function getSubjectCountNum(subjectName: string) {
  return axios.post<SuccessData<any>>(`/lndc/getSubjectCountNum`, qs.stringify({ subjectName }))
    .then(res => res.data.data);
}



interface Cube {
  id: string;
  guid: string;
  name: string;
}

/**
 * 取得自定义用立方体列表
 * @description http://yapi.dufe.tech/project/88/interface/api/4815
 */
export function findCustomCubeList() {
  return axios.post<SuccessData<{ cubeList: Cube[] }>>(`/olap/reportDefine/findCustomCubeList`)
    .then(res => res.data.data.cubeList);
}

export interface ReportDataField {
  children: ReportDataField[];
  colSpan: number;
  dataIndex: string;
  isHead: boolean;
  rowSpan: number;
  title: string;
}

interface ReportData {
  columns: ReportDataField[];
  rows: ReportDataField[];
  data: { [key: string]: string; }[]
}

/**
 * 取得报表展现数据
 * @description http://yapi.dufe.tech/project/88/interface/api/4833
 */
const year = localStorage.getItem('year')
export function findReportData(guid: string) {
  return axios.post<SuccessData<ReportData>>(`/olap/reportDefine/findReportData`, qs.stringify({ guid, yearId: year }))
    .then(res => res.data.data);
}


/**
 * 删除报表
 * @description http://yapi.dufe.tech/project/88/interface/api/4860
 */
export function deleteReportDesign(guid: string) {
  return axios.post<SuccessData<{}>>(`/olap/reportDefine/deleteReportDesign`, qs.stringify({ guid }))
    .then(res => res.data.data);
}

export enum FieldType {
  Input = "1",
  Select = "2",
  DateTime = "3"
}

interface ReportField {
  id: string;
  dataIndex: string;
  title: string;
  type: FieldType;
  list?: {
    title: string;
    dataIndex: string;
  }[];
}

interface ReportFields {
  measures: ReportField[];
  fields: ReportField[];
}

/**
 * 取得报表设计数据
 * @description http://yapi.dufe.tech/project/88/interface/api/4914
 */
export function findReportFieldsByCubeId(tjCubeId: string) {
  return axios.post<SuccessData<ReportFields>>(`/olap/reportDefine/findReportFieldsByCubeId`, qs.stringify({ tjCubeId }))
    .then(res => res.data.data);
}


/**
 * 报表导出
 * @description http://yapi.dufe.tech/project/88/interface/api/4923
 */
export function exportReport(guid: string) {
  return axios.post(`/olap/reportDefine/export`, qs.stringify({ guid }), { responseType: "blob" })
    .then(res => {
      const fileName = decodeURIComponent(res.headers['content-disposition'].split("filename=")[1])
      const blob = res.data
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = e => {
        const tagA = document.createElement('a')
        tagA.download = fileName
        tagA.href = e.target?.result as string
        document.body.appendChild(tagA)
        tagA.click()
        document.body.removeChild(tagA)
      }
    });
}

interface ReportDesignParams {
  name: string;
  tjCubeId: string;
  rows: string;
  columns: string;
  measure: string;
  filters: string;
}

/**
 * 保存报表设计（新增或修改）
 * @param guid
 * @description http://yapi.dufe.tech/project/88/interface/api/4851
 */
export function saveReportDesign(params: ReportDesignParams) {
  return axios.post<SuccessData<{}>>(`/olap/reportDefine/saveReportDesign`, qs.stringify(params))
}

