import ChartCarousel from "../../../Components/ChartCarousel";
import React, {useState} from "react";
import style from "../../index.module.scss";
import Radar from "../../../../Employment/List/components/Radar/Radar";
import RadarChart from "../../../Components/RadarChart";
import {useHistory} from "react-router-dom";
import doNotReRender from "../../../../../lib/doNotReRender";
import RadarChartUuid from "../../../../../components/RadarChartUuid";

const PureRadar = doNotReRender(RadarChartUuid);
const PureRadarChart = doNotReRender(RadarChart);

export default function BlockRight3() {

    const history = useHistory()
    const [title, setTitle] = useState<React.ReactNode>("");

    return (
        <div>
            <h1 className={style.blockHeader}>{title}</h1>

            <ChartCarousel
                title={[
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/employment/list")}>高职院校毕业生就业质量和职业前景情况</div>,
                    <div style={{cursor: "pointer"}} onClick={() => history.push("/basic/main")}>辽宁省高职院校在校生数规模示意图</div>,
                ]}
                hideTitle
                onTitleChange={setTitle}
            >
                <div>
                    <PureRadar
                        onEvents={{"click": () => history.push("/service")}}
                        title="" style={{height: 260}}
                        customOption={(opt: any) => {
                            return {
                                ...opt,
                                radar: {
                                    ...opt.radar,
                                    axisLabel: {
                                        show: true,
                                    },
                                    splitArea: {
                                        areaStyle: {
                                            color: [
                                                'rgba(0,96,208, 0.1)', 'rgba(0,96,208, 0.2)',
                                                'rgba(0,96,208, 0.4)', 'rgba(0,96,208, 0.6)',
                                                'rgba(0,96,208, 0.8)', 'rgba(0,96,208, 1)'
                                            ].reverse()
                                        }
                                    },
                                    axisLine: {
                                        show: true,
                                        lineStyle: {
                                            color: "transparent"
                                        }
                                    },
                                    splitLine: {
                                        show: true,
                                        lineStyle: {
                                            color: "transparent"
                                        }
                                    },
                                    name:{
                                        textStyle: {
                                            color: "#fff"
                                        }
                                    }
                                },
                                series: opt.series?.map((item: any) => ({
                                    type: "radar",
                                    symbol: "circle",
                                    symbolSize: 7,
                                    tooltip: {
                                        position: [100, 100],
                                        formatter: (item: any) => {
                                            return (opt.radar as any).indicator?.map((ra: any, idx: number) => `${ra.name}: ${item.data.value[idx]}`).join("<br/>");
                                        }
                                    },
                                    lineStyle: {
                                        color: "rgba(247,156,25, 0.5)"
                                    },
                                    areaStyle: {
                                        color: "rgba(247,156,25, 0.5)"
                                    },
                                    itemStyle: {
                                        color: 'rgba(247,156,25, 0.5)',
                                        borderColor: 'rgba(247,156,25, 1)',
                                        borderWidth: 1,
                                    },
                                    data: item.data
                                })),

                            } as any
                        }} />
                </div>

                <div>
                    <PureRadarChart style={{height: 260}} />
                </div>
            </ChartCarousel>
        </div>
    );
}
