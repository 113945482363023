import style from "./index.module.scss";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

export default function Footer () {

  const history = useHistory();

  const [, , id] = history.location.pathname.split("/");

  function onClick (id: string) {
    history.push(`/wall/${id}`);
  }

  return (
    <div className={style.root}>
      <ul>
        <li className={classNames(style.item, { [style.on]: id === "1" })} onClick={() => onClick("1")}>数据概览</li>
        <li className={classNames(style.item, { [style.on]: id === "2" })} onClick={() => onClick("2")}>院校运行情况</li>
        <li className={classNames(style.item, { [style.on]: id === "3" })} onClick={() => onClick("3")}>学生实习数据</li>
      </ul>
    </div>
  );
}
