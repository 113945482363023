import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.scss"
import MyTheme from '../../../assets/myTheme.json';
import echarts from "echarts";
import { Carousel, Col, Row, Modal, Input, Form, Button, Select } from "antd";
import { StatisticsService } from "../../Charts/Components/Statistics";
import ServiceScreen from "../../Charts/Components/ServiceScreen";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import styles from './index.module.scss';
import { shfwxmDetail2 } from "../../../service/service";
import useRequest from "../../../lib/useRequest";
import useDevice from "../../../lib/useDevice";
import { FilterOutlined } from "@ant-design/icons";
import useRequestFn from "../../../lib/useRequestFn";
import { getNormalData } from "../../../service/basic";
import WorldMap from "../../Charts/Components/World";
import worldImg from "./images/world.jpg";

export default function PageServiceMain () {

    useBreadcrumb([{ name: "工作页", url: "/" }, { name: "社会服务" }]);
    echarts.registerTheme('theme', MyTheme);
    // 对话框显示或者隐藏标志
    const [isModalVisible, setIsModalVisible] = useState(false);

    // 对话框显示或者隐藏标志
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    // 企业中间变量,select变化先存起来,只有确定的时候再重新请求
    const [middleCompanyName, setmiddleCompanyName] = useState('');

    // 学校中间变量,select变化先存起来,只有确定的时候再重新请求
    const [middleSchoolName, setmiddleSchoolName] = useState('');

    // 单位名
    const [companyName, setCompanyName] = useState('')

    // 学校名
    const [schoolName, setSchoolName] = useState('');

    // 数据源
    // const [temp1] = useRequest(() => shfwxmDetail2(companyName, schoolName), {}, [companyName, schoolName]);
    //社会服务地图明细
    const [temp1] = useRequest(() => getNormalData < any > ('e9685db4-2e40-4afc-8a62-23928d321e76')); // 详情

    const [onData, datas] = useRequestFn(getNormalData); // 详情

    const [temp, setSchoolList] = useState < any > ([]);
    const [area, setArea] = useState < any > ('')
    const [qy_name, setQy_name] = useState < any > ('')
    const [school_name, setSchool_name] = useState < any > ('')

    async function list (area: string, qy_name: string, school_name: string) {
        let res: any = await onData("e9685db4-2e40-4afc-8a62-23928d321e76", { area: area, qy_name: qy_name, school_name: school_name })
        setSchoolList(res)
    }

    useEffect(() => {
        list('', '', '')
    }, [qy_name])
    // 企业名称的下拉列表
    const [keys, setKeys]: any = useState([]);

    // 学校下拉列表
    const [schoolKeys, setSchoolKeys]: any = useState([]);

    // 服务本省/共建一带一路
    const [tagIndex, setTagIndex] = useState(1);

    // 获取下拉框的值
    const getOptions = () => {
        const options: any = [];
        temp1?.forEach((item: any) => {
            if (!options.includes(item.qy_name))
            {
                options.push(item.qy_name)
            }
        })
        setKeys(options);
    }

    // 获取学校下拉框的值
    const getSchoolOptions = () => {
        const options: any = [];
        temp1?.forEach((item: any) => {
            if (!options.includes(item.school_name))
            {
                options.push(item.school_name);
            }
        })

        setSchoolKeys(options);
    }

    // 当企业名字改变
    function onChangeCompany (value: string) {
        setmiddleCompanyName(value);
    }

    // 当学校名字改变
    function onChangeSchoolName (value: string) {
        setmiddleSchoolName(value);
    }

    // 筛选企业
    function submitCompany () {

        setIsModalVisible(false);
        setCompanyName(middleCompanyName);
        list('', middleCompanyName, middleSchoolName)
    }

    // 筛选学校
    function submitSchool () {
        setIsModalVisible2(false);
        setSchoolName(middleSchoolName);
        list('', middleCompanyName, middleSchoolName)

    }

    // 下拉列表
    const { Option } = Select;

    // 初始化下拉框
    useMemo(() => {
        getOptions();
        getSchoolOptions();
    }, [temp])

    // 海外合作项目
    const projectList = [
        {
            school: '辽宁建筑职业学院',
            country: '马来西亚',
            project: '海外鲁班工坊',
            result: '东北地区首个'
        },
        {
            school: '辽宁建筑职业学院',
            country: '马来西亚',
            project: '中国—东盟高职院校特色合作项目',
            result: '成功入选第四批“中国—东盟高职院校特色合作项目”'
        },
        {
            school: '辽宁省交通高等专科学校',
            country: '泰国',
            project: '鲁班学堂',
            result: '获批教育部2020年度教育援外项目立项'
        },
        {
            school: '辽宁省交通高等专科学校',
            country: '加拿大',
            project: '百年应用文理与技术学院合作项目',
            result: '获批教育部2019年度教育援外项目立项'
        },
        {
            school: '辽宁机电职业技术学院',
            country: '柬埔寨',
            project: '亚龙丝路学院',
            result: '完成首批招生工作'
        },
        {
            school: '辽宁石化职业技术学院',
            country: '泰国',
            project: '‘互联网+’中泰国际学院',
            result: '完成首批招生工作'
        },
        {
            school: <span>辽宁轨道交通职业学院<br />辽宁铁道职业技术学院</span>,
            country: '肯尼亚',
            project: '肯尼亚蒙内铁路项目',
            result: '培训328人'
        },
        {
            school: '辽宁铁道职业技术学院',
            country: '泰国',
            project: '中外合作办学项目',
            result: '每个项目年招生300人，学制3年。对接轨道交通领域，着力培养具有扎实专业基础、跨国文化交流能力和开阔国际视野的专业型人才'
        },
    ];

    const { isMobile } = useDevice()
    return (
        <div className={style.root}>
            <div className={style.pageTabs}>
                <p className={`${style.itemTab} ${tagIndex === 1 ? style.action : ''}`} onClick={() => setTagIndex(1)}>共建一带一路</p>
                <p> / </p>
                <p className={`${style.itemTab} ${tagIndex === 2 ? style.action : ''}`} onClick={() => setTagIndex(2)}>服务国内</p>
            </div>
            {tagIndex === 1 && (
                <div>
                    {/*                   <div className={style.picLeft} style={{display: "flex"}}>
                        <div style={{flex: 1, width: "100%"}}>
                            <WorldMap />
                        </div>
                    </div>*/}
                    <div className={style.picLeft}>
                        <img src={worldImg} style={{ width: 1300 }} alt="" />
                    </div>
                    <Row justify="center" gutter={[24, 24]}>
                        <Col span={24} className={style.picShow}>
                            {/* <div className={style.title} style={{flex: 1, width: "100%"}}>
                    高等职业院校服务百家企业项目对接进展情况
                </div> */}
                            <div className={styles.header} style={{ marginTop: "30px", background: '#367bfc', color: '#ffffff' }}>
                                <div style={{ width: "25%", textAlign: "center" }}>院校名称</div>
                                <div style={{ width: "15%", textAlign: "center" }}>合作国家</div>
                                <div style={{ width: "25%", textAlign: "center" }}>合作项目</div>
                                <div style={{ width: "45%", textAlign: "center" }}>取得成果</div>
                            </div>
                            <div className={styles.table}>
                                {projectList?.map((item: any) => {
                                    return (
                                        <div>
                                            <div className={styles.col}>
                                                <div style={{
                                                    width: "25%",
                                                    borderRight: "1px solid #f0f0f0",
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ flex: 1 }}>
                                                        {item.school}
                                                    </span>
                                                </div>
                                                <div style={{
                                                    width: "15%",
                                                    borderRight: "1px solid #f0f0f0",
                                                    display: 'flex',
                                                    alignItems: 'center',

                                                }}>
                                                    <span style={{ flex: 1 }}>
                                                        {item.country}
                                                    </span>
                                                </div>
                                                <div style={{
                                                    width: "25%",
                                                    borderRight: "1px solid #f0f0f0",
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ flex: 1 }}>
                                                        {item.project}
                                                    </span>
                                                </div>
                                                <div style={{
                                                    width: "45%",
                                                    borderRight: "1px solid #f0f0f0",
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ flex: 1 }}>
                                                        {item.result}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}

            {(
                <div style={{ display: tagIndex === 2 ? "block" : 'none' }}>
                    <div style={{ margin: isMobile ? '0' : "0 -20px 40px" }}>
                        <StatisticsService type="major" />
                    </div>
                    <Modal title="检索企业" visible={isModalVisible}
                        onCancel={() => {
                            setIsModalVisible(false)
                        }}
                        footer={
                            [
                                <Button key="back" onClick={() => {
                                    setIsModalVisible(false)
                                }}>
                                    返回
                                </Button>,
                                <Button key="clear" type="primary" onClick={() => {
                                    submitCompany()
                                }}>
                                    确定
                                </Button>
                            ]
                        }
                    >
                        <p>
                            <Form.Item
                                label="企业"
                            >
                                <Select
                                    showSearch
                                    size={"large"}
                                    placeholder="请输入企业名"
                                    allowClear
                                    style={{ width: 400, textAlign: "left" }}
                                    onChange={onChangeCompany}
                                >
                                    {keys?.map((item: any, index: number) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </p>
                    </Modal>

                    <Modal title="检索学校" visible={isModalVisible2}
                        onCancel={() => {
                            setIsModalVisible2(false)
                        }}
                        footer={
                            [
                                <Button key="back" onClick={() => {
                                    setIsModalVisible2(false)
                                }}>
                                    返回
                                </Button>,
                                <Button key="clear" type="primary" onClick={() => {
                                    submitSchool()
                                }}>
                                    确定
                                </Button>
                            ]

                        }
                    >
                        <p>
                            <Form.Item
                                label="学校"
                            >
                                <Select
                                    showSearch
                                    size={"large"}
                                    placeholder="请输入学校名"
                                    allowClear
                                    style={{ width: 400, textAlign: "left" }}
                                    onChange={onChangeSchoolName}
                                >
                                    {schoolKeys?.map((item: any, index: number) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </p>
                    </Modal>

                    <div className={style.picLeft} style={{ display: "flex" }}>
                        <div style={{ flex: 1, width: "100%" }}>
                            <ServiceScreen showTimeLine={false} width={isMobile ? 'auto' : 1100} height={isMobile ? 460 : 900} />
                        </div>
                    </div>
                    <Row justify="center" gutter={[24, 24]}>
                        <Col span={24} className={style.picShow}>
                            {/* <div className={style.title} style={{flex: 1, width: "100%"}}>
                    高等职业院校服务百家企业项目对接进展情况
                </div> */}
                            <div className={styles.header} style={{ marginTop: "30px" }}>
                                <div style={{ width: "17.5%", textAlign: "center" }}>
                                    <span>企业名称</span>
                                    <FilterOutlined onClick={() => { setIsModalVisible(true) }} />
                                </div>
                                <div style={{ width: "17.5%", textAlign: "center" }}>
                                    <span> 学校名称</span>
                                    <FilterOutlined onClick={() => { setIsModalVisible2(true) }} />
                                </div>
                                <div style={{ width: "8%", textAlign: "center" }}>产业类别</div>
                                <div style={{ width: "8%", textAlign: "center" }}>所属行业</div>
                                <div style={{ width: "8%", textAlign: "center" }}>产业属性</div>
                                <div style={{ width: "8%", textAlign: "center" }}>需求类别</div>
                                <div style={{ width: "9%", textAlign: "center" }}>需求所属战略</div>
                                <div style={{ width: "8%", textAlign: "center" }}>成果转化金额(万元)</div>
                                <div style={{ width: "8%", textAlign: "center" }}>员工培训人数</div>
                                <div style={{ width: "8%", textAlign: "center" }}>订单定制培养人数</div>
                            </div>
                            <div className={styles.table}>
                                <Carousel autoplay={true} dots={false} dotPosition="left" autoplaySpeed={3000}
                                    slidesToScroll={1} slidesToShow={10} pauseOnHover={false}>
                                    {temp?.map((item: any) => {
                                        return (
                                            <div>
                                                <div className={styles.col}>
                                                    <div style={{
                                                        width: "17.5%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.qy_name}</div>
                                                    <div style={{
                                                        width: "17.5%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.school_name}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.cy_type}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.sshy}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.cysx}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.xqlb}</div>
                                                    <div style={{
                                                        width: "9%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.xqsszl}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.cgzhje}</div>
                                                    <div style={{
                                                        width: "8%",
                                                        borderRight: "1px solid #f0f0f0"
                                                    }}>{item.ygpxrs}</div>
                                                    <div style={{ width: "8%", }}>{item.ddzdpyrs}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}
