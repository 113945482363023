import React, {useState} from "react";

export interface BreadcrumbItem {
    name: string;
    url?: string;
}

interface AdminContextProps {
    breadcrumb: BreadcrumbItem[];
    setBreadcrumb: (node: BreadcrumbItem[]) => void;
}

const defaultContext: AdminContextProps = {
    breadcrumb: [],
    setBreadcrumb: () => null,
};

export const AdminContext = React.createContext<AdminContextProps>(defaultContext);


export const AdminContextProvider = ({children}: {children: React.ReactNode}) => {

    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([]);

    return (
        <AdminContext.Provider value={{breadcrumb, setBreadcrumb}}>
            {children}
        </AdminContext.Provider>
    );
}
