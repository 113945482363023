// @ts-nocheck
import ReactEcharts from "echarts-for-react";
import { EChartOption } from "echarts";
import { useHistory } from 'react-router-dom';
import useRequest from "../../../../lib/useRequest";
import {getNormalData} from "../../../../service/basic";
const data = [
    {
        name: "农业",
        value: 38,
    },
    {
        name: "工业",
        value: 346,
    },
    {
        name: "服务业",
        value: 387,
    },
];
const xAxisData = [];
const seriesData1 = [];
let sum = 0;
const barTopColor = ["#02c3f1", "#53e568", "#a154e9"];

data.forEach((item) => {
    xAxisData.push(item.name);
    seriesData1.push(item.value);
    sum += item.value;
});
const Options: EChartOption<EChartOption.Series> = {
    tooltip: {
        formatter: "{b}对接企业数 {c} 个"
    },
    grid: {
        top: "25%",
        bottom: "15%",
    },
    xAxis: {
        data: xAxisData,
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
        axisLabel: {
            show: true,
            margin: 25,
            align: "center",
            textStyle: {
                fontSize: 14,
                color: "#ffffff",
                rich: {
                    a: {
                        fontSize: 12,
                        color: "#ffffff",
                    },
                    b: {
                        height: 20,
                        fontSize: 14,
                        color: "#ffffff",
                    },
                },
            },
        },
        interval: 0,
    },
    yAxis: {
        splitLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
        axisLabel: {
            show: false,
        },
    },
    series: [
        {
            name: "柱顶部",
            type: "pictorialBar",
            symbolSize: [26, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
                normal: {
                    color: function (params) {
                        return barTopColor[params.dataIndex];
                    },
                },
            },
            label: {
                show: true,
                position: "top",
                fontSize: 16,
            },
            symbolPosition: "end",
            data: seriesData1,
        },
        {
            name: "柱底部",
            type: "pictorialBar",
            symbolSize: [26, 10],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
                normal: {
                    color: function (params) {
                        return barTopColor[params.dataIndex];
                    },
                },
            },
            data: seriesData1,
        },
        {
            name: "第一圈",
            type: "pictorialBar",
            symbolSize: [47, 16],
            symbolOffset: [0, 11],
            z: 11,
            itemStyle: {
                normal: {
                    color: "transparent",
                    borderColor: "#3ACDC5",
                    borderWidth: 2,
                },
            },
            data: seriesData1,
        },
        {
            name: "第二圈",
            type: "pictorialBar",
            symbolSize: [62, 22],
            symbolOffset: [0, 17],
            z: 10,
            itemStyle: {
                normal: {
                    color: "transparent",
                    borderColor: barTopColor[0],
                    borderWidth: 2,
                },
            },
            data: seriesData1,
        },
        {
            type: "bar",
            tooltip: {
                formatter: "{b}对接企业数 {c} 个"
            },
            z: 16,
            silent: false,
            barWidth: 26,
            barGap: "-100%", // Make series be overlap
            data: seriesData1,
        },
    ],
};

interface IProps {
    customOption?: (
        opt: EChartOption<EChartOption.Series>
    ) => EChartOption<EChartOption.Series>;
    style?: any;
}

export default function Index({ customOption, style }: IProps) {
    const history = useHistory()
    const [data] = useRequest(() => getNormalData<{name: string; value: string}[]>("7f1f0649-fc42-4a7f-b742-98c0628aeadb")); // 社会服务对接企业成果转化金额分布

    return (
        <>
            {data && (
                <ReactEcharts
                    onEvents={{"click": () => history.push("/service")}}
                    style={style}
                    theme={"theme"}
                    option={{
                        ...Options,
                        series: Options.series.map(item => ({
                            ...item,
                            data: data.map(o => o.value)
                        }))
                    }}
                />
            )}
        </>
    );
}
