import { flattenRoute } from "../../lib/utils";
import Config from "../../config";
import { HashRouter, BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import GuestLayout from "../../components/GuestLayout";
import ScrollToTop from "../../components/ScrollToTop";
import React from "react";

export default function Guest () {

  const routes = flattenRoute(Config.Menu);

  const history = useHistory();

  return (
    <BrowserRouter basename="/guest">

      <GuestLayout>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} exact />
          ))}
        </Switch>
      </GuestLayout>

      <ScrollToTop />
    </BrowserRouter >
  );
}
