import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import Img from "./images/img.png";
import plusImg from "./images/plus.png";
import closeImg from "./images/close.png";
import { Button, Spin } from "antd";
import FangAnSelector from "./components/FangAnSelector";
import useRequestFn from "../../../lib/useRequestFn";
import { CourseDetailItem2, CourseTrainingProgram2Ret, getCourseTrainingProgram2 } from "../../../service/training";
import ShowText from "./components/ShowText";
import ShowItems from "./components/ShowItems";
import useQuery from "../../../lib/useQuery";
import useBreadcrumb from "../../../lib/useBreadcrumb";
import { Link } from "react-router-dom";
import { get } from "sortablejs";
import { getNormalData } from "../../../service/basic";
interface SelectItem extends CourseTrainingProgram2Ret {
  other: any;
}

export default function CoursesCompare () {

  useBreadcrumb([{ name: "工作页", url: "/" }, { name: "培养方案", url: "/training" }, { name: "课程对比" }]);

  const { courseId, school, major } = useQuery < any > ();
  const [data, setData] = useState < (SelectItem | null)[] > ([null, null, null, null]);
  const [index, setIndex] = useState(-1);
  const [loading, setLoading] = useState(false)
  // const [getData] = useRequestFn(getCourseTrainingProgram2);
  const [getData] = useRequestFn(getNormalData)

  useEffect(() => {
    if (!courseId) return;
    onAdd({ courseId: courseId, school: school, major: major }, 0);
    // onAdd({ courseId: "1366", school: "沈阳职业技术学院", major: "物流管理" }, 1);
  }, []);

  function onClose (index: number) {
    if (data.length === 4)
    {
      data[index] = null;
      setData([...data]);
    } else
    {
      data.splice(index, 1);
      setData([...data]);
    }
  }

  function queueIndex () {
    return data.findIndex((item, index) => {
      return item === null;
    });
  }
  // //获取课程详情

  async function onAdd (select: any, index: number) {
    setLoading(true)
    let res = await getData < any > ("fc2fb793-0ded-4db7-93fc-75976de456f7", { courseId: select.courseId ? select.courseId : select.subjectId })
    if (res)
    {

      data[index] = { ...res, other: select };
      setData([...data]);
    }
    if (res || res == null)
    {
      setLoading(false)
    } else
    {
      setLoading(true)
    }
  }

  const [showPop, setShowPop] = useState(false);

  function onClick (index?: number) {
    if (typeof index === "number")
    {
      setIndex(index);
    } else
    {
      const res = queueIndex();
      if (res === -1)
      {
        setIndex(data.length);
        setData([...data, null]);
      } else
      {
        setIndex(res);
      }
    }
    setShowPop(true);
  }

  return <div className={style.root}>
    <div className={style.main}>
      <div className={style.wrapper} style={{ width: `${(data.length + 1) * 221}px` }}>
        <div className={`${style.item} ${style.itemSpecial}`}>
          <div className={style.header}>课程信息</div>
          <div className={style.description}>对比信息</div>
        </div>
        {data.map((item, index) => (
          <div key={index} className={`${style.item} ${item ? '' : style.noInfo}`}>
            {item !== null && <>
              <div className={style.school}>
                {item?.other?.school}
              </div>
              <div className={style.major}>
                {item?.other?.major}
              </div>
              <div className={style.course}>
                <Link to={`/training/CourseDetail?courseId=${item?.courseId}`}>
                  {item?.courseBase?.course_name}
                </Link>
              </div>
            </>}
            {item === null && <img src={Img} alt="" />}
            <div className={style.btnWrapper}>
              <Button className={style.selectBtn} onClick={() => onClick(index)}>{item === null ? "选择课程" : "换课程"}</Button>
            </div>
            {item !== null && <div className={style.closeBtn} onClick={() => onClose(index)}>
              <img src={closeImg} alt="" />
            </div>}
          </div>))}
      </div>

      {/* 基本情况 */}
      <Spin tip="Loading..." spinning={loading}>
        <table className={style.table} style={{ width: `${(data.length + 1) * 221}px` }}>
          <tbody>
            <tr className={style.titleWrapper}>
              <td colSpan={data.length + 1}>
                <div className={style.title}>
                  基本情况
                </div>
              </td>
            </tr>
            <tr>
              <td className={`${style.TName}`}>课程名称</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.course_name}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>课程类型</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.course_type}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>课程性质</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.course_quality}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>总学时</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.all_time}</td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>学分数</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.xfs}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>学年</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.new_year}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>学期</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.term}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>考核方式</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.check_style}</td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>课程设计依据</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.course_design || ""} />
                </td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>课程设计思路</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.course_idea || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>学期目标概述</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.xxmb || ""} />
                </td>
              })}
            </tr>
            <tr>
              <td className={`${style.TName}`}>学期内容概述</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.xxnr || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>其他内容</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.other_content || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>职业能力要求</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.zynlyq || ""} />
                </td>
              })}
            </tr>


            <tr>
              <td className={`${style.TName}`}>课程介绍</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.course_info || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>教学条件要求</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.teach_condition || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>教材与参考资料</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.teach_reference || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>课程资源开发与应用建议</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.course_open_advice || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>评价标准</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem}`}>
                  <ShowText text={item?.courseBase?.assess_target || ""} />
                </td>
              })}
            </tr>

            <tr>
              <td className={`${style.TName}`}>周学时</td>
              {data.map((item, index) => {
                return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.courseBase?.week_time}</td>
              })}
            </tr>

          </tbody>
        </table>
      </Spin>,
      {/* end 基本情况 */}

      {/* 课程目标 */}
      <table className={style.table} style={{ width: `${(data.length + 1) * 221}px` }}>
        <tbody>
          <tr className={style.titleWrapper}>
            <td colSpan={data.length + 1}>
              <div className={style.title}>
                课程目标
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${style.TName}`}>总体目标</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle2}`}>
                <ShowText text={item?.courseGoal.ztmb?.goal_name ? `${item?.courseGoal.ztmb.goal_name}: ${item?.courseGoal.ztmb.goal_mark}` : ""} />
              </td>
            })}
          </tr>
          <tr>
            <td className={`${style.TName}`}>具体目标</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle2}`}>
                <ShowItems data={item?.courseGoal.jtmb || []} />
              </td>
            })}
          </tr>
        </tbody>
      </table>
      {/* end 课程目标 */}

      {/* 内容组织与安排 */}
      <table className={style.table} style={{ width: `${(data.length + 1) * 221}px` }}>
        <tbody>
          <tr className={style.titleWrapper}>
            <td colSpan={data.length + 1}>
              <div className={style.title}>
                内容组织与安排
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${style.TName}`}>模块（项目/单元）名称模块数量</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle2}`}>{item?.coursePlan?.model_name_count}</td>
            })}
          </tr>
          <tr>
            <td className={`${style.TName}`}>理论学时占比</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.coursePlan?.theory_count}</td>
            })}
          </tr>
          <tr>
            <td className={`${style.TName}`}>实践学时占比</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.coursePlan?.practice_count}</td>
            })}
          </tr>
        </tbody>
      </table>
      {/* end 内容组织与安排 */}

      {/* 师资组成表 */}
      <table className={style.table} style={{ width: `${(data.length + 1) * 221}px` }}>
        <tbody>
          <tr className={style.titleWrapper}>
            <td colSpan={data.length + 1}>
              <div className={style.title}>
                师资组成表
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${style.TName}`}>课程负责人数量</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.teacher.teacherCount}</td>
            })}
          </tr>
          <tr>
            <td className={`${style.TName}`}>主讲教师</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.teacher.zjjs}</td>
            })}
          </tr>
          <tr>
            <td className={`${style.TName}`}>外聘教师</td>
            {data.map((item, index) => {
              return <td key={index} className={`${style.TItem} ${style.fontStyle}`}>{item?.teacher.wpjs}</td>
            })}
          </tr>
        </tbody>
      </table>
      {/* end 师资组成表 */}
    </div>

    <div className={style.addBtn} onClick={() => onClick()} >
      <img src={plusImg} alt="" />添加课程
    </div>

    <FangAnSelector visible={showPop} onSelect={(val) => onAdd(val, index)} onClose={() => setShowPop(false)} />

  </div>
}
