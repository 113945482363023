import style from "../../index.module.scss";
import { Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import useRequest from "../../../../../lib/useRequest";
import { hereIsATable, ipcsTable } from "../../../../../service/employment";
import _ from "lodash";
import React, { useState } from "react";
import DetailChart from "../Chart3/detail";
import { getNormalData } from "../../../../../service/basic";

interface CourseListTableProps {
}

export default function Table1 ({ }: CourseListTableProps) {

  //const [data] = useRequest(ipcsTable)
  const [data] = useRequest(() => getNormalData < any > ('8dbf304a-f892-4ac2-9af0-4c08cf9b02ea'))//产业人才对接统计表
  const [modalId, setModalId] = useState(null)

  const columns: ColumnsType<any> = [
    {
      title: "行业",
      dataIndex: "industry",
      render: (string) => <a href="#" onClick={() => setModalId(string)}>{string}</a>
    },
    {
      title: "人数",
      dataIndex: "peopleNum",
    },
    {
      title: "专业布点数",
      dataIndex: "subjectNum",
    },
    {
      title: "学校数",
      dataIndex: "schoolNum",
    },
  ]

  return (
    <div>
      <Modal bodyStyle={{ height: "600px", overflow: "auto" }} destroyOnClose width={1200} onCancel={() => setModalId(null)} footer={null} visible={!!modalId} title={`${modalId}行业对应一览表`}>
        <DetailChart name={modalId || ""} />
      </Modal>
      <Table bordered title={() => <div style={{ textAlign: "center", color: "#347afc", fontSize: "20px", fontWeight: "bold" }}>产业人才对接统计表</div>} className={style.table} rowKey="industry" columns={columns} pagination={false} dataSource={data || []} />
    </div>
  );
}
