import PageHome from "../pages/Home";
import PageBasicMain from "../pages/Basic/Main";
import PageBasicList from "../pages/Basic/List";
import ReachStandard from "../pages/Basic/Reach";
import PageMajorMain from "../pages/Major/Main";
import PageMajorDetail from "../pages/Major/Detail";
import PageMajorList from "../pages/Major/List";
import SchoolList from "../pages/Major/SchoolList";
import SchoolDetail from "../pages/Major/SchoolDetail";
import PageTrainingMain from "../pages/Training/Main";
import PageSchoolDetail from "../pages/Basic/Detail";
import PageReportDetail from '../pages/Report/index';
import PageSpecialDetail from "../pages/Training/Special";
import ProfessionList from "../pages/Training/ProfessionList";
import PeiyangfanganCompare from "../pages/Training/PeiyangfanganCompare";
import PageTrainPlan from "../pages/Training/Plan"
import SchoolMajorList from "../pages/Training/SchoolMajorList";
import CourseList from "../pages/Training/CourseList";
import CourseDetail from "../pages/Training/CourseDetail";
import {CreditCardOutlined, HomeOutlined} from "@ant-design/icons";
import ProfessionSearch from "../pages/Major/ProfessionSearch";
import CoursesCompare from "../pages/Training/CoursesCompare";
import PageEmploymentMain from "../pages/Employment/Main";
import PageEmploymentList from "../pages/Employment/List";
import PageServiceMain from "../pages/Service/Main";
import PageServiceList from "../pages/Service/List";
import PagePracticeList from "../pages/Practice/List";
import PageServiceDetail from "../pages/Service/Detail";
import PageEmploymentSearch from "../pages/Employment/Search";
import PageEmploymentDetail from "../pages/Employment/Detail";
import PageEmploymentSchoolList from "../pages/Employment/SchoolList";
import React from "react";
import PagePracticeProcess from "../pages/Practice/Process";
import PagePracticeSchoolList from "../pages/Practice/SchoolList";
import PagePracticeDetailList from "../pages/Practice/DetailList";
import PageCourseMain from "../pages/Course/Main";

const MenuConfig = [
    {
        name: "工作页",
        path: "/main",
        component: PageHome,
        icon: HomeOutlined,
    },
    {
        path: "/report/:guid",
        component: PageReportDetail
    },
    {
        name: "院校状态",
        path: "/basic",
        icon: CreditCardOutlined,
        routes: [
            {
                name: "数据展示",
                path: "/main",
                component: PageBasicMain
            },
            {
                name: "信息汇总",
                path: "/list",
                component: PageBasicList
            },
            {
                name: "达标情况",
                path: "/reach",
                component: ReachStandard
            },
            {
                path: "/schooldetail",
                component: PageSchoolDetail
            },
        ]
    },
    {
        name: "专业状态",
        path: "/major",
        icon: CreditCardOutlined,
        routes: [
            {
                name: "数据展示",
                path: "/main",
                component: PageMajorMain
            },
            {
                name: "信息汇总",
                path: "/list",
                component: PageMajorList
            },
            {
                path: "/SchoolList",
                component: SchoolList
            },
            {
                path: "/SchoolDetail",
                component: SchoolDetail
            },
            {
                path: "/search",
                component: ProfessionSearch
            },
            {
                path: "/detail",
                component: PageMajorDetail
            },
        ]
    },
    {
        name: "人才培养方案",
        path: "/training",
        icon: CreditCardOutlined,
        component: PageTrainingMain,
        routes: [
            {
                name: "数据展示",
                path: "/main",
                component: PageTrainingMain
            },
            {
                name: "专业详细",
                path: "/special",
                component: PageSpecialDetail
            },
            {
                name: "培养方案",
                path: "/trainPlan",
                component: PageTrainPlan
            },
            {
                name: "培养方案对比",
                path: "/pyfadb",
                component: PeiyangfanganCompare

            },
            {
                name: "课程对比",
                path: "/CoursesCompare",
                component: CoursesCompare

            },
            {
                name: "专业列表",
                path: "/ProfessionList",
                component: ProfessionList
            },
            {
                name: "课程列表",
                path: "/CourseList",
                component: CourseList
            },
            {
                name: "学校-专业列表",
                path: "/SchoolMajorList",
                component: SchoolMajorList
            },
            {
                name: "课程详细",
                path: "/CourseDetail",
                component: CourseDetail
            },
        ]
    },
    {
        name: "课程管理",
        path: "/course",
        icon: CreditCardOutlined,
        routes: [
            {
                name: "实时开课状态",
                path: "/main",
                component: PageCourseMain
            },
            {
                name: "精品在线课程平台",
                path: "https://courses.lnve.net/",
                component: () => React.createElement("div")
            },
        ]
    },
    {
        name: "实习实训",
        path: "/practice",
        icon: CreditCardOutlined,
        routes: [
            {
                name: "实习数据",
                path: `${window.location.protocol}//${window.location.host}/wall/1`,
                component: PagePracticeProcess
            },
            {
                name: "实习实训基地",
                path: "/list",
                component: PagePracticeList
            },
            {
                path: "/SchoolList",
                component: PagePracticeSchoolList
            },
            {
                path: "/DetailList",
                component: PagePracticeDetailList
            },
        ]
    },
    {
        name: "产教融合",
        path: "/employment",
        icon: CreditCardOutlined,
        routes: [
            {
                name: "产业人才对接数据平台",
                path: "/main",
                component: PageEmploymentMain
            },
            {
                name: "毕业生跟踪调查",
                path: "/list",
                component: PageEmploymentList
            },
            {
                path: "/search",
                component: PageEmploymentSearch
            },
            {
                path: "/SchoolList",
                component: PageEmploymentSchoolList
            },
            {
                path: "/detail",
                component: PageEmploymentDetail
            },
        ]
    },
    {
        name: "社会服务",
        path: "/service",
        icon: CreditCardOutlined,
        component: PageServiceMain
    },
    // {
    //     name: "培养方案2",
    //     path: "/training",
    //     icon: CreditCardOutlined,
    //     routes: [
    //         {
    //             name: "数据展示",
    //             path: "/main",
    //             component: PageTrainingMain
    //         },
    //         {
    //             name: "专业详细",
    //             path: "/special",
    //             component: PageSpecialDetail
    //         },
    //         {
    //             name: "培养方案",
    //             path: "/trainPlan",
    //             component: PageTrainPlan
    //         },
    //         {
    //             name: "培养方案对比",
    //             path: "/pyfadb",
    //             component: PeiyangfanganCompare
    //
    //         },
    //         {
    //             name: "课程对比",
    //             path: "/CoursesCompare",
    //             component: CoursesCompare
    //
    //         },
    //         {
    //             name: "专业列表",
    //             path: "/ProfessionList",
    //             component: ProfessionList
    //         },
    //         {
    //             name: "课程列表",
    //             path: "/CourseList",
    //             component: CourseList
    //         },
    //         {
    //             name: "学校-专业列表",
    //             path: "/SchoolMajorList",
    //             component: SchoolMajorList
    //         },
    //         {
    //             name: "课程详细",
    //             path: "/CourseDetail",
    //             component: CourseDetail
    //         },
    //     ]
    // }
];

export default MenuConfig;
