import React from "react";
import style from "../index.module.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import innerStyle from "./index.module.scss"

export default function Wall4() {
    return (
        <div className={style.root}>
            <Header />

            <div className={innerStyle.title}>
                栏目建设中...
            </div>

            <Footer />
        </div>
    )
}
