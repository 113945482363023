import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption } from "echarts";
import useRequest from "../../../../lib/useRequest";
import { getCourseType, getCourseProperty, ICourseType } from "../../../../service/chart";
interface ICircleType {
  inner: any[],
  outer: any[]
}

interface IProps {
  title: string;
  style?: any;
}

export default function Index ({ title, style }: IProps) {

  const [dataOut] = useRequest(() => getCourseProperty());
  const [dataInner] = useRequest(() => getCourseType());

  const [echartData, setEchartData] = useState < ICircleType > ({
    inner: [],
    outer: []
  });

  useEffect(() => {
    if (dataOut && dataInner)
    {
      const dataOutRes = formatDataFnForOut(dataOut, '门');
      const dataInnerRes = formatDataFn(dataInner, "个");
      setEchartData({
        inner: dataInnerRes,
        outer: dataOutRes
      });
    }
  }, [dataOut, dataInner]);

  function formatDataFn (data: ICourseType[], unit = "") {
    return data.map(item => {
      return {
        name: item.kclx,
        unit: unit,
        value: item.kclxCount
      }
    }).filter(item => item.name === 'A类' || item.name === 'B类' || item.name === 'C类');
  }

  function formatDataFnForOut (data: ICourseType[], unit = "") {
    return data.map(item => {
      return {
        name: item.kcsx,
        unit: unit,
        value: item.kcsxCount
      }
    }).filter(item => item.name === '公共课' || item.name === '专业课' || item.name === '专业基础课');
  }

  const Options: EChartOption<EChartOption.Series> = {
    title: {
      text: title,
      left: 'center',
      top: 15,
      textStyle: {
        color: '#347afc'
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        const des = params.name === "A类" ? "（理论课）" : (params.name === "B类" ? "（理论+实践课）" : (params.name === "C类" ? "（实践课）" : ''));
        return `${params.name}${des}: ${params.value}门 (${params.percent})`;
      }
    },
    series: [{
      name: '访问来源',
      type: 'pie',
      radius: [0, '40%'],
      itemStyle: {
        normal: {
          borderWidth: 1
        }
      },
      label: {
        normal: {
          position: 'inner'
        }
      },
      labelLine: {
        normal: {
          show: false
        }
      },
      data: echartData.inner,
      top: 30,
      bottom: 20
    },
    {
      name: '访问来源',
      type: 'pie',
      radius: ['70%', '55%'],
      data: echartData.outer,
      labelLine: {
        normal: {
          length: 20,
          length2: 70,
        }
      },
      label: {
        normal: {
          formatter: (params: any) => {
            return (
              '{icon|●}{name|' + params.name + '}'
            );
          },
          padding: [0, -50, 25, -60],
          rich: {
            icon: {
              fontSize: 16
            },
            name: {
              fontSize: 14,
              padding: [0, 5, 0, 5],
            },
            percent: {
              padding: [0, 5, 0, 0],
            },
          }
        }
      },
      top: 30,
      bottom: 20
    }
    ]
  };

  return (
    <ReactEcharts theme={"theme"} style={style} option={Options} />
  )
}

Index.defaultProps = {
  title: '辽宁省高职院校授课情况'
};
