const cityCoordData = [{
    name: "沈阳市",
    coord: [123.429096,
        41.796767]
}, {
    name: "大连市",
    coord: [121.618622,
        38.91459]
}, {
    name: "铁岭市",
    coord: [
        123.844279,
        42.290585
    ]
}, {
    coord: [
        123.921109,
        41.875956
    ],
    name: "抚顺市"
}, {
    coord: [
        123.770519,
        41.297909
    ],
    name: "本溪市"
},{
    coord: [
        124.383044,40.124296
    ],
    name: "丹东市"
},{
    coord: [
        122.995632,41.110626
    ],
    name: "鞍山市"
},{
    coord: [
        123.18152,41.269402
    ],
    name: "辽阳市"
},{
    coord: [
        122.235151,40.667432
    ],
    name: "营口市"
},{
    coord: [
        122.06957,41.124484
    ],
    name: "盘锦市"
},{
    coord: [
        121.135742,41.119269
    ],
    name: "锦州市"
},{
    coord: [
        121.648962,42.011796
    ],
    name: "阜新市"
},{
    coord: [
        120.451176,41.576758
    ],
    name: "朝阳市"
},{
    coord: [
        120.856394,40.755572
    ],
    name: "葫芦岛市"
}
];

export default cityCoordData;
