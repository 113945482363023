import React from "react";
import style from "../../../../Charts/style.module.scss";
import useRequest from "../../../../../lib/useRequest";
import {
  countCourse002,
  countCourse006,
  countCurrentPracticeCourse,
  countCurrentSchool,
  countCurrentStudent, countCurrentTeacher,
  countCurrentTheoryCourse
} from "../../../../../service/course";
import {
  countDockingAgriculture,
  countDockingWaiter,
  countGraduateStudent,
  countIndustry
} from "../../../../../service/employment";
import styles from "../../../../Employment/Main/components/Statistics/style.module.scss";
import { getNormalData } from "../../../../../service/basic";

export function Statistics () {

  // const [count1] = useRequest(countCurrentSchool);
  // const [count2] = useRequest(countCurrentTeacher);
  // const [count3] = useRequest(countCurrentStudent);
  // const [count4] = useRequest(countCourse002);
  // const [count5] = useRequest(countCourse006);
  const [count1] = useRequest(() => getNormalData < any > ('68c9215e-cb0c-4562-b241-0e55cff31ed1'))//开课学校数
  const [count2] = useRequest(() => getNormalData < any > ('2cae09af-f4df-4ee2-8ecf-428a7e1e152b'))//开课专业数
  const [count3] = useRequest(() => getNormalData < any > ('06686aab-1f8b-47f7-8869-4368ede6f029'))//开课门数
  const [count4] = useRequest(() => getNormalData < any > ('49dd30a0-4001-492c-a21e-78b9f0917cdd'))//授课教师数
  const [count5] = useRequest(() => getNormalData < any > ('720c80c3-d711-46f5-b505-a50ab4206801'))//上课学生数

  return (
    <div className={styles.wrap}>
      <div className={`${styles.block6}`}>
        <div className={styles.label}>开课院校数</div>
        <div className={styles.number} style={{ fontSize: 34 }}>{count1}所</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block5}`}>
        <div className={styles.label}>开课专业数</div>
        <div className={styles.number} style={{ fontSize: 34 }}>{count2}</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block7}`}>
        <div className={styles.label}>开课门数</div>
        <div className={styles.number} style={{ fontSize: 34 }}>{count3}门</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block8}`}>
        <div className={styles.label}>授课教师数</div>
        <div className={styles.number} style={{ fontSize: 34 }}>{count4}人</div>
        <div className={styles.shadow} />
      </div>
      <div className={`${styles.block9}`}>
        <div className={styles.label}>上课学生数</div>
        <div className={styles.number} style={{ fontSize: 34 }}>{count5}人</div>
        <div className={styles.shadow} />
      </div>
    </div>
  )
}
