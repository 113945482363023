import React from "react";
import style from "./index.module.scss";
import {RouteComponentProps} from 'react-router-dom';

interface IProps{
    children: React.ReactNode;
    showBorder: boolean;
    customStyle: {};
}
export default function Block ({children, customStyle, showBorder}: IProps) {
    return <div className={`${style.root}`} style={customStyle}>
        {showBorder && <div className={style.top}></div>}
        {showBorder && <div className={style.left}></div>}
        {showBorder && <div className={style.right}></div>}
        {showBorder && <div className={style.bottom}></div>}
        {children}
    </div>
}

Block.defaultProps = {
    showBorder: true,
    customStyle: {}
}
