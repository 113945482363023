import style from "./index.module.scss";
import { Modal, Table ,Popover } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import useRequest from "../../../../../../../lib/useRequest";
// import { hereIsATable, ipcsTable } from "../../../../../service/employment";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
// import DetailChart from "../Chart3/detail";
import { getNormalData } from "../../../../../../../service/basic";
import { CloseOutlined } from "@ant-design/icons"
import InfoCard from "../InfoCard";
// interface CourseListTableProps {
// }

export default function Table1 (props: any) {
  const t = useRef()
  const {code, file, list} = props
  // const [code,setCode] = useState('A-1')
  // const [file,setFile] = useState('page_3')
  const [ffcy,setFfcy] = useState('')

  const [showNum,setShowNum] = useState<number>(-1)
  //const [data] = useRequest(ipcsTable)
  const [data] = useRequest(() => getNormalData < any > ('4f895fd7-c132-11ec-ba43-005056958024',{code,file,ffcy}),{},[ffcy])//产业人才对接统计表
  const [modalData] = useRequest(() => getNormalData < any > ('15d3f21d-c06d-11ec-ba43-005056958024',{code,file,ffcy}),{},[ffcy])//产业人才对接统计表
  const [isModalVisible, setModal] = useState(false)
  const popoverInfo = (a: any) =>{
    // console.log(modalData)
    return(
      <div className={style.popoverContent}>
        <div className={style.title}>
          <span>详情</span>
          <CloseOutlined style={{cursor:'pointer'}} onClick={cancelPopover}/>
        </div>
        {
          modalData&&modalData.map((el: any)=>{
            return(
              <p key={el.name}>{el.name}&nbsp;&nbsp;&nbsp;{el.count||0}</p>
            )
          })
        }
        <p className={style.more} onClick={()=>contentDetail(a)}>查看更多&#62;&#62;&#62;</p>

      </div>
    )
  }
  const visibleChange = (text: string, index: number) =>{
    setFfcy(text)
    setShowNum(index)
    // console.log(ffcy)
  }
  const contentDetail = (b: any) => {
      setFfcy(b.ffcy)
    setModal(true)
    setShowNum(-1)
  }
  const handleOk = () =>{
    setModal(false)
  }
  const cancelPopover = () =>{
    setShowNum(-1)
  }
  const handleCancel = () =>{
    setModal(false)
  }
  const columns: ColumnsType<any> = [
    {
      title: "服务行业",
      dataIndex: "ffcy",
      align:'center',
      render: (text, record, index) => <Popover  content={()=>popoverInfo(record)}
                                                  visible={showNum==index}
                                                  onVisibleChange={()=>visibleChange(text,index)}
                                                  trigger="click">
                                          <p className={style.popoverColumns}>{text}</p>
                                      </Popover>
    },
  ]

  const colums2 :any =[
    {
      title:'学校名称',
      dataIndex:'school_name'
    },
    {
      title:'专业名称',
      dataIndex:'subject_name'
    },
    {
      title:'学生数',
      dataIndex:'bysrs'
    }
  ]

  return (
    <div className={`${file}_${code} ${style.table3}`}>
      <Modal width={700} title={ffcy} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Table className={style.table2} scroll={{ y: 240 }}  rowKey="ffcy" columns={colums2} pagination={false} dataSource={data || []} />
      </Modal>

        {list?.map((item: any) => <InfoCard key={item.ffcy} code={code} file={file} list={list} ffcy={item.ffcy} onClick={() => contentDetail(item)} />)}
      {/*<Table   className={style.table} rowKey="ffcy" columns={columns} pagination={false} dataSource={list || []} />*/}
    </div>
  );
}
